import { graphql } from '../../gql';
export const DELETE_INSURER_QUOTES = graphql(`
  mutation deleteInsurerQuotes(
    $parentQuoteUuid: UUID!
    $insurerQuoteIds: [ID!]!
  ) {
    deleteInsurerQuotes(
      parentQuoteUuid: $parentQuoteUuid
      insurerQuoteIds: $insurerQuoteIds
    ) {
      ok
      deletedInsurerQuotes {
        insurerQuoteIdentifier
        llmResponseBlob
        pdfFilePath
      }
    }
  }
`);
