import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useLazyQuery } from '@apollo/client';

import { BusinessType, Policy } from '../../../../../../Typescript';
import { AVAILABLE_FORM_POLICIES } from '../../../../../../queries';
import { useAuth, determineBaseURL } from '@calefy-inc/authentication';
import { copyTextToClipboard } from '@calefy-inc/utility';
import { generateGenerateNavigationDestination } from '../../../../../Iframe/utility';
import { PATH_TO_CONTACT_INFO_TO_ANY_BUSINESS } from '../../../../../Iframe';

// types and classes
import type { Dispatch, SetStateAction } from 'react';

interface GenerateIframeLinkProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  business: BusinessType;
}
export const GenerateIframeLink = ({
  open,
  setOpen,
  onClose,
  business,
}: GenerateIframeLinkProps) => {
  const { token } = useAuth();
  const [requestedPolicyInternalNames, setRequestedPolicyInternalNames] =
    useState<Array<Policy['internalName']>>([]);
  const [availablePolicies, setAvailablePolicies] = useState<
    Array<Pick<Policy, 'id' | 'internalName' | 'displayName'>>
  >([]);
  const [url, setUrl] = useState<string>('');
  const [copyButtonTitle, setCopyButtonTitle] = useState<string>('Copy');
  const [copyError, setCopyError] = useState<Error | undefined>();

  // @ts-expect-error
  const [getAvailableFormPolicies, { loading, error, data }] = useLazyQuery(
    AVAILABLE_FORM_POLICIES,
  );

  // get the list of available policies
  useEffect(() => {
    if (business && token) {
      getAvailableFormPolicies({
        variables: {
          businessId: business.id,
          token,
        },
      });
    }
  }, [token, business]);

  // set the policies once we have the data
  useEffect(() => {
    if (data?.availableFormPolicies) {
      //console.log({ data });
      // @ts-expect-error
      setAvailablePolicies(data.availableFormPolicies);
    }
  }, [data]);

  // whenever policies are added / removed, update the url
  useEffect(() => {
    const baseUrl = `${determineBaseURL()}/insurtech/iframe/${PATH_TO_CONTACT_INFO_TO_ANY_BUSINESS}`;
    const generateDestination = generateGenerateNavigationDestination(baseUrl);
    const destination = generateDestination({
      businessId: business.internalName,
      policies: [...requestedPolicyInternalNames].filter(
        (name): name is string => !!name,
      ),
      toBusiness: false,
    });
    setUrl(destination);
  }, [requestedPolicyInternalNames, business]);

  return (
    <Dialog open={open} onClose={onClose} data-testid='generate-iframe-link'>
      <DialogTitle>
        Generate <code>iframe</code> Link for {business.displayName}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          This links to a page which asks basic contact information questions
          and then routes the user to complete the rest of the application
          (specific to a business type). It is suitable for embedding in an{' '}
          <code>iframe</code>.
        </Typography>
        <Typography>Which coverages should be included?</Typography>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            ...(availablePolicies.length > 5 ? { columns: 2 } : {}),
          }}
        >
          {availablePolicies.map((policy) => (
            <FormControlLabel
              sx={{ display: 'block', marginLeft: 0 }}
              label={policy.displayName}
              control={
                <Checkbox
                  color='primary'
                  onClick={(e) => {
                    //console.log({ e });
                    // @ts-expect-error
                    if (e.target.checked) {
                      setRequestedPolicyInternalNames((oldNames) => [
                        ...oldNames,
                        policy.internalName,
                      ]);
                    } else {
                      setRequestedPolicyInternalNames((oldNames) =>
                        [...oldNames].filter(
                          (name) => name !== policy.internalName,
                        ),
                      );
                    }
                  }}
                />
              }
            />
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'monospace',
              flexGrow: 1,
              wordBreak: 'break-all',
            }}
          >
            {url}
          </Typography>
          <Tooltip title={copyButtonTitle}>
            <Button
              variant='text'
              onClick={async () => {
                await copyTextToClipboard(
                  url,
                  () => {
                    setCopyButtonTitle('Copied!');
                    setTimeout(() => setCopyButtonTitle('Copy'), 2000);
                  },
                  (error) => {
                    setCopyError(error);
                  },
                );
              }}
              sx={{ flexGrow: 0 }}
            >
              Copy
              <ContentCopyIcon />
            </Button>
          </Tooltip>
        </div>
        {copyError ? (
          <Typography color='error.main'>
            Error copying URL to clipboard - please copy manually
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
