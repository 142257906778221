import {
  determineBaseURL,
  generateAuthProvider,
} from '@calefy-inc/authentication';

type Auth0Parameters = {
  domain: string;
  clientId: string;
  redirectUri: string;
};
/**
 * Determine which client ID should be used, based on whether it is the development or staging / production environment
 * @return - the parameters necessary to initialize the Auth0 provider
 */
export const determineAuth0Parameters: () => Auth0Parameters = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      domain: 'dev-1iz7g-mu.us.auth0.com',
      clientId: 'JQHGHnWb4hnvQ5CE8P2DOLhvcSjgMHmu',
      redirectUri: determineBaseURL() + '/insurtech/login',
    };
  }
  return {
    domain: 'production-calefy.us.auth0.com',
    clientId: 'Vli0U3V11LYN8bDS0G0yMDzwTM3lfJvS',
    redirectUri: determineBaseURL() + '/insurtech/login',
  };
};

export const AuthProvider = generateAuthProvider(determineAuth0Parameters());
