import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Form } from 'informed';
import RenderedQuestionInstance from '../../../../../../../common/QuoteComponents/RenderedQuestionInstance';

// types and classes
import { ProgramBuilderQuestionInstance } from '../../../../../../../FormManager/classes';
import { Language } from '../../../../../../../../Typescript/classes';
import {
  withFormStyles,
  WithFormStyles,
} from '../../../../../../../../util/withFormStyles';

interface UnthemedQuestionPreviewDialogProps extends WithFormStyles {
  node: ProgramBuilderQuestionInstance;
  open: boolean;
  onClose: () => void;
}
function UnstyledQuestionPreviewDialog({
  node,
  open,
  classes,
  onClose,
}: UnthemedQuestionPreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} data-testid='question-preview-modal'>
      <DialogTitle sx={{ textAlign: 'center' }}>Question Preview</DialogTitle>
      <DialogContent sx={{ minWidth: '600px' }}>
        <Form className={classes.form}>
          <RenderedQuestionInstance
            questionInstance={node.toQuoteWizardQuestionInstance(
              Language.english,
            )}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const QuestionPreviewDialog = withFormStyles()(
  UnstyledQuestionPreviewDialog,
);
