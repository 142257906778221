import { useState, useEffect, useRef, Dispatch as ReactDispatch } from 'react';
import { withStyles } from '@mui/styles';
import { Button, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';

// connecting to the state for submitting the partially completed quote
import { connect, useSelector, useDispatch } from 'react-redux';
import { QuoteWizardState } from '../../../store';
import { QuoteWizardState as QuoteWizardStateType } from '../../../store/QuoteWizardState';
import { updateErrors } from '../../../store/QuoteWizardState';

// for the dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// for the dialog form
import { Form, FormValues } from 'informed';
import { EmailInput, TextField, TextArea } from '@calefy-inc/informedMaterial';

// in case of errors
import { IncompleteQuoteSubmissionError } from '../ErrorHandling';

// for adding in placeholder values for the unanswered questions
import { hybridAnswers } from '../../util/Quote';

// types and classes
import type { $TSFixMe, OrNullish } from '@calefy-inc/utilityTypes';
import { QuoteWizardAnswerInstance } from '../classes';
import { Dispatch, StoreState } from '../../../store';
import type { QuoteStatus } from '../../../Typescript/backend/types/QuoteStatus';
import { useWalnutGoogleAnalytics } from '../../../hooks';
import {
  EventActions,
  GAEventNames,
} from '../../../hooks/useWalnutGoogleAnalytics';

interface EmailDialogProps {
  open: boolean;
  setOpen: ReactDispatch<boolean>;
  handleClose: $TSFixMe;
  onOk: $TSFixMe;
  clientEmail: $TSFixMe;
  setClientEmail: $TSFixMe;
  clientName: $TSFixMe;
  setClientName: $TSFixMe;
  message: $TSFixMe;
  setMessage: $TSFixMe;
  quoteSubmitted: $TSFixMe;
  submissionInProgress: $TSFixMe;
  classes: $TSFixMe;
}
/**
 * Dialog to ask the user for an email to send the partially completed form to
 */
const UnthemedEmailDialog = ({
  open,
  setOpen,
  handleClose,
  onOk,
  clientEmail,
  setClientEmail,
  clientName,
  setClientName,
  message,
  setMessage,
  quoteSubmitted,
  submissionInProgress,
  classes = {},
}: EmailDialogProps) => {
  // whether the email is valid -> we can use the OK button
  const [pending, setPending] = useState<boolean>(false);
  const formApiRef = useRef<$TSFixMe>();
  const dispatch = useDispatch();
  const walnutGoogleAnalytics = useWalnutGoogleAnalytics();

  const { goToStep, componentMapping, currentStep } = useSelector(
    (state: StoreState) => state.quoteWizard.stepWizard,
  );

  useEffect(() => {
    if (quoteSubmitted) {
      setOpen(false);
      setPending(false);
      sendSaveWalnutGoogleAnalytics({
        googleAnalytics: walnutGoogleAnalytics,
        componentMapping,
        currentStep,
      });
      if (goToStep && componentMapping['done']) {
        /*console.log('GoToStep in savequotebutton'); */
        goToStep(componentMapping['done']);
      } else {
        dispatch(
          updateErrors({
            incompleteQuoteSubmission: 'No Done Component found',
          }),
        );
      }
    }
  }, [quoteSubmitted, setOpen, componentMapping, dispatch, goToStep]);

  useEffect(() => {
    if (!submissionInProgress) {
      setPending(false);
    }
  }, [submissionInProgress]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        className={classes.formModal}
      >
        <div className={classes.formModalContents}>
          <Typography
            variant='h6'
            id='form-dialog-title'
            className={classes.title}
            color='primary'
          >
            Save and Send Application
          </Typography>
          <DialogContent>
            <Typography variant='body2' className={classes.instructionText}>
              Please enter your email below if you wish to save and complete
              your application at a later time.
            </Typography>
            <Form
              initialValues={{ clientName, email: clientEmail, message }}
              getApi={(formApi) => (formApiRef.current = formApi)}
              onSubmit={(values: FormValues) => {
                setPending(true);
                onOk(values);
              }}
              className={classes.form}
            >
              {({ formApi }) => {
                return (
                  <>
                    <TextField
                      field='clientName'
                      label='(Optional) Enter recipient name'
                      fullWidth
                      value={clientName}
                      onChange={(e: $TSFixMe) => {
                        setClientName(e.target.value);
                      }}
                      className={classes.textField}
                      variant='standard'
                    />
                    <EmailInput
                      field='email'
                      label='Enter email'
                      value={clientEmail}
                      onChange={(e: $TSFixMe) => {
                        setClientEmail(e.target.value);
                      }}
                      required={true}
                      fullWidth
                      validateOnChange={true}
                      className={classes.textField}
                    />
                    <TextArea
                      field='message'
                      label='(Optional) Enter a short message for the recipient'
                      fullWidth
                      value={message}
                      onChange={(e: $TSFixMe) => {
                        setMessage(e.target.value);
                      }}
                      className={classes.textarea}
                    />
                    <div className={classes.buttonGroup}>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button
                        disabled={!formApiRef.current || pending}
                        onClick={() => formApi.submitForm()}
                        variant='contained'
                      >
                        {!pending ? 'OK' : 'Saving...'}
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
          </DialogContent>
        </div>
        <IncompleteQuoteSubmissionError />
      </Dialog>
    </>
  );
};

/* Connect to Store */
const mapStateToDialogProps = (_state: $TSFixMe) => {
  return {};
};
const ConnectedEmailDialog = connect(
  mapStateToDialogProps,
  null,
)((props) => <UnthemedEmailDialog {...props} />);

/* Theming */
const EmailDialog = withStyles((theme: $TSFixMe) => ({
  title: {
    textAlign: 'center',
  },
  instructionText: {
    marginBottom: 0,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
  },
  textarea: {
    marginTop: theme.spacing(4),
    marginBottom: 0,
  },
  formModal: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formModalContents: {
    maxHeight: '90vh',
    maxWidth: '50rem',
    width: '100%',
    //backgroundColor: theme.palette.grey[200],
    padding: `${theme.spacing(1.5)} 0`,
    position: 'relative',
    '& > h1': {
      marginTop: 0,
    },
  },
  // @ts-expect-error
}))((props) => <ConnectedEmailDialog {...props} />);

interface SaveQuoteButtonProps {
  classes: $TSFixMe;
  disabled: boolean;
  onSaveQuote: $TSFixMe;
  selectedBusinessType: $TSFixMe;
  submissionInProgress: $TSFixMe;
  okToSubmitIncompleteQuote: $TSFixMe;
  setOkToSubmitIncompleteQuote: $TSFixMe;
  currentQuoteUUID: $TSFixMe;
  contactEmail: $TSFixMe;
  contactName: $TSFixMe;
  quoteSubmitted: $TSFixMe;
}
/**
 * Button to save the current quote to the backend in a partially completed state in such a way that someone else can come along and complete it later
 * @param classes - theming classes
 * @param reconcileState - Function which should be called to reconcile the current state of the store with whatever information is currently entered into the tab on which this button is rendered. This should only deal with that portion of the state - appropriate actions to actually save the state will be taken elsewhere in the component
 */
const UnthemedSaveQuoteButton = ({
  classes = {},
  disabled = false,
  onSaveQuote,
  // @ts-expect-error
  selectedBusinessType,
  submissionInProgress,
  okToSubmitIncompleteQuote,
  setOkToSubmitIncompleteQuote,
  // @ts-expect-error
  currentQuoteUUID,
  contactEmail,
  contactName,
  quoteSubmitted,
}: SaveQuoteButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [clientEmail, setClientEmail] = useState<string | undefined>(
    contactEmail,
  );
  const [clientName, setClientName] = useState<string | undefined>(contactName);
  const [message, setMessage] = useState<string | undefined>();

  // Get Save button state
  const visible = useSelector(
    (state: StoreState) => state.quoteWizard.saveButton.visible,
  );
  const reconcileState = useSelector(
    (state: StoreState) => state.quoteWizard.saveButton.reconcileState,
  );

  // if the state has been reconciled, then it is ok to submit the quote - but then immediately shut the door behind you!
  if (okToSubmitIncompleteQuote) {
    onSaveQuote(clientEmail, clientName, message);
    setOkToSubmitIncompleteQuote(false);
  }

  return visible ? (
    <>
      <Tooltip
        enterDelay={100}
        leaveDelay={100}
        title='Use this to save forms for later completion or sending to a trusted party'
      >
        <Button
          onClick={() => {
            reconcileState();
            setDialogOpen(true);
          }}
          disabled={disabled}
          variant='outlined'
          className={classes.button}
          startIcon={<SaveIcon />}
        >
          Save For Later
        </Button>
      </Tooltip>
      <EmailDialog
        /*
        // @ts-expect-error */
        open={dialogOpen}
        setOpen={setDialogOpen}
        handleClose={() => {
          setDialogOpen(false);
        }}
        onOk={() => {
          reconcileState();
          setOkToSubmitIncompleteQuote(true);
        }}
        clientEmail={clientEmail}
        setClientEmail={setClientEmail}
        clientName={clientName}
        setClientName={setClientName}
        message={message}
        setMessage={setMessage}
        quoteSubmitted={quoteSubmitted}
        submissionInProgress={submissionInProgress}
      />
    </>
  ) : null;
};

/* Connect Layer */

const findEmailInAnswerList = (
  answerList: Array<QuoteWizardAnswerInstance>,
): QuoteWizardAnswerInstance | undefined => {
  if (!answerList || answerList.length === 0) {
    return undefined;
  }
  for (let answer of answerList) {
    if (!answer) {
      continue;
    }
    /*console.log('About to match name for', answer, 'to email'); */
    /*console.log('findEmailInAnswerList question:', answer.questionInstance); */
    if (answer.questionInstance.apiName.match(/email/i)) {
      return answer.value;
    } else {
      const foundAnswerInSubAnswer = findEmailInAnswerList(answer.subAnswers);
      if (foundAnswerInSubAnswer) {
        return foundAnswerInSubAnswer;
      }
    }
  }
  return undefined;
};

const findPrimaryContactNameInAnswerList = (
  answerList: Array<QuoteWizardAnswerInstance>,
): QuoteWizardAnswerInstance | undefined => {
  if (!answerList || answerList.length === 0) {
    return;
  }
  for (let answer of answerList) {
    if (!answer) {
      continue;
    }
    if (answer.questionInstance.apiName.match(/client contact name/i)) {
      return answer.value;
    } else {
      const foundAnswerInSubAnswer = findPrimaryContactNameInAnswerList(
        answer.subAnswers,
      );
      if (foundAnswerInSubAnswer) {
        return foundAnswerInSubAnswer;
      }
    }
  }
  return;
};

function mapStateToProps(state: StoreState) {
  const answersByFormId = state.quoteWizard.formAnswers;
  const selectedBusinessType = state.quoteWizard.selectedBusinessType;

  const allSelectedPolicyIds = state.quoteWizard.selectedPolicies.map(
    (fullPolicy) => fullPolicy.id,
  );
  const formsWithAnswers = Object.values(answersByFormId).filter(({ form }) => {
    const businessId = !selectedBusinessType
      ? undefined
      : 'id' in selectedBusinessType
      ? selectedBusinessType.id
      : selectedBusinessType?.original.id;
    return (
      ((!form.policy ||
        allSelectedPolicyIds.some((id) => form?.policy?.id === id)) &&
        form.businessLine &&
        selectedBusinessType &&
        form.businessLine.id === businessId) ||
      form.businessLine.internalName === 'generic'
    );
  });

  const submissionInProgress = formsWithAnswers.find(
    (formWithAnswer) => formWithAnswer.inProgress,
  );

  const submittedQuoteForBusinessType = selectedBusinessType
    ? state.quoteWizard.submittedQuotes.find(
        // @ts-expect-error
        (quote) => quote.businessLine.id === selectedBusinessType.id,
      )
    : undefined;

  // @ts-expect-error
  const businessInformationFormId = state.quoteWizard.businessForm.id;
  const businessFormAnswers = state.quoteWizard.formAnswers[
    businessInformationFormId
  ]
    ? state.quoteWizard.formAnswers[businessInformationFormId].answers
    : undefined;
  // @ts-expect-error
  const contactEmail = findEmailInAnswerList(businessFormAnswers);
  // @ts-expect-error
  const contactName = findPrimaryContactNameInAnswerList(businessFormAnswers);

  const allRelevantForms = [
    state.quoteWizard.businessForm,
    ...state.quoteWizard.policyForms,
  ].filter(
    // @ts-expect-error
    (form) => !form.policy || allSelectedPolicyIds.includes(form.policy.id),
  );

  /*console.log( */
  /*   `in mapDispatchToProps with currentQuoteUUID=${state.quoteWizard.currentQuoteUUID}`, */
  /* ); */
  return {
    formsWithAnswers,
    allRelevantForms,
    selectedBusinessType,
    submissionInProgress,
    submittedQuoteForBusinessType,
    okToSubmitIncompleteQuote: state.quoteWizard.okToSubmitIncompleteQuote,
    currentQuoteUUID: state.quoteWizard.currentQuoteUUID,
    contactEmail,
    contactName,
    quoteSubmitted:
      state.quoteWizard.submittedQuotes.length > 0 &&
      state.quoteWizard.submittedQuotes.find((quote) =>
        [
          'INCOMPLETE',
          'INCOMPLETE_RENEWAL',
          'INCOMPLETE_CONFIRMATION',
        ].includes(quote.status),
      ),
    renewalInProgress: state.quoteWizard.renewalInProgress,
    confirmationInProgress: state.quoteWizard.confirmationInProgress,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const { confirmAnswersForForms, setOkToSubmitIncompleteQuote } =
    QuoteWizardState.actions;

  interface OnSaveQuoteArgument {
    formsWithAnswers: $TSFixMe;
    businessType: $TSFixMe;
    clientEmail: string;
    clientName: $TSFixMe;
    message: $TSFixMe;
    status: QuoteStatus;
    currentQuoteUUID: StoreState['quoteWizard']['currentQuoteUUID'];
    renewalInProgress: StoreState['quoteWizard']['renewalInProgress'];
  }
  function onSaveQuote({
    formsWithAnswers,
    businessType,
    clientEmail,
    clientName,
    message,
    status,
    currentQuoteUUID,
    renewalInProgress,
  }: OnSaveQuoteArgument) {
    return dispatch(
      // @ts-expect-error
      confirmAnswersForForms({
        businessType,
        formsWithAnswers,
        clientEmail,
        clientName,
        message,
        status,
        currentQuoteUUID,
        renewalInProgress,
      }),
    );
  }

  return {
    onSaveQuote,
    setOkToSubmitIncompleteQuote: (newValue: boolean) => {
      dispatch(
        setOkToSubmitIncompleteQuote({ okToSubmitIncompleteQuote: newValue }),
      );
    },
  };
}

function mergeProps(
  mappedState: ReturnType<typeof mapStateToProps>,
  mappedDispatch: ReturnType<typeof mapDispatchToProps>,
  ownProps: $TSFixMe,
) {
  const onSaveQuote = (
    clientEmail: OrNullish<string>,
    clientName: OrNullish<string>,
    message: OrNullish<string>,
  ) => {
    return mappedDispatch.onSaveQuote({
      businessType: mappedState.selectedBusinessType,
      formsWithAnswers: hybridAnswers(
        mappedState.formsWithAnswers,
        // @ts-expect-error
        mappedState.allRelevantForms,
      ),
      clientEmail,
      clientName,
      message,
      status: mappedState.renewalInProgress
        ? 'INCOMPLETE_RENEWAL'
        : mappedState.confirmationInProgress
        ? 'INCOMPLETE_CONFIRMATION'
        : 'INCOMPLETE',
      currentQuoteUUID: mappedState.currentQuoteUUID,
      renewalInProgress: mappedState.renewalInProgress,
    });
  };

  return {
    ...mappedState,
    ...mappedDispatch,
    ...ownProps,
    onSaveQuote,
  };
}

const ConnectedSaveQuoteButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)((mappedState) => <UnthemedSaveQuoteButton {...mappedState} />);

/* Theming Layer */
export const SaveQuoteButton = withStyles((_theme: $TSFixMe) => ({
  button: {
    alignSelf: 'center',
  },
}))((props) => <ConnectedSaveQuoteButton {...props} />);

const sendSaveWalnutGoogleAnalytics = ({
  googleAnalytics,
  componentMapping,
  currentStep,
}: {
  googleAnalytics: ReturnType<typeof useWalnutGoogleAnalytics>;
  componentMapping: QuoteWizardStateType['stepWizard']['componentMapping'];
  currentStep: QuoteWizardStateType['stepWizard']['currentStep'];
}) => {
  if (!googleAnalytics || !componentMapping || !currentStep) {
    return;
  }
  const { stepName } = Object.entries(componentMapping).reduce(
    (acc, [key, step]) => {
      const { stepNumber } = acc;
      if (step <= currentStep && step > stepNumber) {
        return {
          stepNumber: step,
          stepName: key,
        };
      }
      return acc;
    },
    { stepNumber: 0, stepName: '' },
  );
  let eventName: GAEventNames;
  switch (stepName) {
    case 'business':
      eventName = GAEventNames.Business;
      break;
    case 'policy':
      eventName = GAEventNames.Policy;
      break;
    case 'General Information':
      eventName = GAEventNames.GeneralInformation;
      break;
    case 'General Liability':
      eventName = GAEventNames.GeneralLiability;
      break;
    case 'Property':
      eventName = GAEventNames.Property;
      break;
    case 'review':
      eventName = GAEventNames.Review;
      break;
    default:
      eventName = GAEventNames.Success;
  }
  googleAnalytics.triggerEvent(eventName, EventActions.Saved, stepName);
};
