const recursiveTopLevel = (
  questionInstance,
  questionInstances,
  topLevelQuestion,
) => {
  for (let i = 0; i < questionInstances.length; i++) {
    const question = questionInstances[i];
    if (
      (question.id && question.id === questionInstance.id) ||
      (question.clientId && question.clientId === questionInstance.clientId)
    ) {
      return topLevelQuestion || question;
    }
    if (question.subQuestions && question.subQuestions.length > 0) {
      const deep_check = recursiveTopLevel(
        questionInstance,
        question.subQuestions,
        topLevelQuestion || question,
      );
      if (deep_check) {
        return deep_check;
      }
    }
  }
  return null;
};

export default function getTopLevelQuestion(
  questionInstance,
  questionInstances,
) {
  // debugger;
  if (!questionInstance) {
    return null;
  }
  return recursiveTopLevel(questionInstance, questionInstances, null);
  // debugger;
}
