export const propertyAnswers = [
  {
    questionInstance: {
      id: '1090',
      apiName: 'head_office_details',
      displayName: 'Head Office Details',
      label: 'What is the location of the business?',
      helpText: null,
      component: 'location',
      dataType: 'nested',
      propsBlob: {
        repeatable: true,
      },
      required: true,
      subQuestions: [
        {
          id: '44232',
          apiName: 'province',
          displayName: 'province',
          label: 'Province',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        {
          id: '44233',
          apiName: 'postal',
          displayName: 'postal',
          label: 'Postal Code',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        {
          id: '44234',
          apiName: 'address',
          displayName: 'address',
          label: 'Address',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        {
          id: '44235',
          apiName: 'city',
          displayName: 'city',
          label: 'City',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        {
          id: '44236',
          apiName: 'risk_management_measures',
          displayName: 'Risk Management Measures',
          label:
            'Describe security and any other risk management measures in place at this location.',
          helpText: null,
          component: 'textarea',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        {
          id: '44237',
          apiName: 'year_built_check',
          displayName: 'Year built check',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'lesser',
            condition: '2000',
          },
          required: false,
          subQuestions: [
            {
              id: '44238',
              apiName: 'year_built',
              displayName: 'Year Built',
              label: 'What year was this location built?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44239',
              apiName: 'heating_updates',
              displayName: 'Heating Updates',
              label: 'When was the heating last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44240',
              apiName: 'electrical_updates',
              displayName: 'Electrical Updates',
              label: 'When was the electrical wiring last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44241',
              apiName: 'plumbing_updates',
              displayName: 'Plumbing Updates',
              label: 'When was the plumbing last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44242',
              apiName: 'roofing_update',
              displayName: 'Roofing Update',
              label: 'When was the roofing last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        {
          id: '44243',
          apiName: 'square_foot',
          displayName: 'Square Foot',
          label:
            'What is the approximate square footage your business occupies at this location?',
          helpText: null,
          component: 'numberinput',
          dataType: 'number',
          propsBlob: {
            dataType: 'ft²',
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '44244',
          apiName: 'construction_type',
          displayName: 'Construction Type',
          label: 'What type of material is this building constructed out of?',
          helpText:
            'Fire Resistive: Steel and Concrete\nNon-Combustible: Hollow Concrete Block (HCB)\nSteel: Steel frame and Siding\nMasonry: Brick exterior with frame interior\nWood Frame: Wooden framing with combustible exterior',
          component: 'InputToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'Fire Resistive',
                label: 'Fire Resistive',
              },
              {
                value: 'Non-Combustible',
                label: 'Non-Combustible',
              },
              {
                value: 'Steel',
                label: 'Steel',
              },
              {
                value: 'Masonry',
                label: 'Masonry',
              },
              {
                value: 'Wood Frame',
                label: 'Wood Frame',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '44245',
          apiName: 'sprinklers',
          displayName: 'Sprinklers',
          label: 'Is there a functioning sprinkler system in place?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: false,
          subQuestions: [],
        },
        {
          id: '44246',
          apiName: 'building_coverage',
          displayName: 'Building Coverage',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44247',
              apiName: 'building',
              displayName: 'Building',
              label: 'Does this location require Building coverage?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            {
              id: '44248',
              apiName: 'building_slid',
              displayName: 'Building Slid',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 10000000,
              },
              required: false,
              subQuestions: [],
            },
            {
              id: '44249',
              apiName: 'property_deductible',
              displayName: 'Property Deductible',
              label: 'What would you like your deductible to be?',
              helpText:
                'Deductible costs are the amount that is paid out of pocket before the insurance coverage takes effect. A higher deductible will reduce the premium paid. ',
              component: 'InputToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: '0',
                    label: 'No Deductible',
                  },
                  {
                    value: '1000',
                    label: '$1,000',
                  },
                  {
                    value: '2500',
                    label: '$2,500',
                  },
                  {
                    value: '5000',
                    label: '$5,000',
                  },
                  {
                    value: '10000',
                    label: '$10,000',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
          ],
        },
        {
          id: '44250',
          apiName: 'business_property_coverage',
          displayName: 'Business Property Coverage',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44251',
              apiName: 'business_contents',
              displayName: 'Business Contents',
              label:
                'Does this location require coverage for equipment including computers and permanent fixtures?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44252',
              apiName: 'business_property_limits',
              displayName: 'Business Property Limits',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        {
          id: '44253',
          apiName: 'business_interruption',
          displayName: 'Business Interruption',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44254',
              apiName: 'bi',
              displayName: 'BI',
              label:
                'Does this location require business interruption coverage? ',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            {
              id: '44255',
              apiName: 'business_interruption_coverage',
              displayName: 'Business Interruption Coverage',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: false,
              subQuestions: [],
            },
          ],
        },
        {
          id: '44256',
          apiName: 'portable_contents_coverage',
          displayName: 'Portable Contents Coverage',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44257',
              apiName: 'portable_contents',
              displayName: 'Portable Contents',
              label:
                'Does this location require portable contents and tools coverage?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44258',
              apiName: 'portable_contents_limits',
              displayName: 'Portable Contents Limits',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        {
          id: '44259',
          apiName: 'other_contents',
          displayName: 'Other Contents',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44260',
              apiName: 'other_contents_coverage',
              displayName: 'Other contents coverage',
              label:
                'Does this location require coverage for any other contents not listed?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44261',
              apiName: 'other_contents_limits',
              displayName: 'Other Contents Limits',
              label: 'How much coverage do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '44232',
          apiName: 'province',
          displayName: 'province',
          label: 'Province',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        value: 'Alberta',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44233',
          apiName: 'postal',
          displayName: 'postal',
          label: 'Postal Code',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        value: 'T2G 1W5',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44234',
          apiName: 'address',
          displayName: 'address',
          label: 'Address',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        value: '557 36 Avenue Southeast',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44235',
          apiName: 'city',
          displayName: 'city',
          label: 'City',
          helpText: null,
          component: 'textField',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        value: 'Calgary',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44236',
          apiName: 'risk_management_measures',
          displayName: 'Risk Management Measures',
          label:
            'Describe security and any other risk management measures in place at this location.',
          helpText: null,
          component: 'textarea',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
        value: 'We have an alarm system',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44237',
          apiName: 'year_built_check',
          displayName: 'Year built check',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'lesser',
            condition: '2000',
          },
          required: false,
          subQuestions: [
            {
              id: '44238',
              apiName: 'year_built',
              displayName: 'Year Built',
              label: 'What year was this location built?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44239',
              apiName: 'heating_updates',
              displayName: 'Heating Updates',
              label: 'When was the heating last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44240',
              apiName: 'electrical_updates',
              displayName: 'Electrical Updates',
              label: 'When was the electrical wiring last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44241',
              apiName: 'plumbing_updates',
              displayName: 'Plumbing Updates',
              label: 'When was the plumbing last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44242',
              apiName: 'roofing_update',
              displayName: 'Roofing Update',
              label: 'When was the roofing last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '44238',
              apiName: 'year_built',
              displayName: 'Year Built',
              label: 'What year was this location built?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            value: '1990',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44239',
              apiName: 'heating_updates',
              displayName: 'Heating Updates',
              label: 'When was the heating last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            value: '2009',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44240',
              apiName: 'electrical_updates',
              displayName: 'Electrical Updates',
              label: 'When was the electrical wiring last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            value: '2003',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44241',
              apiName: 'plumbing_updates',
              displayName: 'Plumbing Updates',
              label: 'When was the plumbing last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            value: '2011',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44242',
              apiName: 'roofing_update',
              displayName: 'Roofing Update',
              label: 'When was the roofing last updated?',
              helpText: null,
              component: 'yearPicker',
              dataType: 'number',
              propsBlob: {
                include: true,
                yearsAfter: 0,
                yearsBefore: 150,
              },
              required: true,
              subQuestions: [],
            },
            value: '2006',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44243',
          apiName: 'square_foot',
          displayName: 'Square Foot',
          label:
            'What is the approximate square footage your business occupies at this location?',
          helpText: null,
          component: 'numberinput',
          dataType: 'number',
          propsBlob: {
            dataType: 'ft²',
          },
          required: true,
          subQuestions: [],
        },
        value: '8548',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44244',
          apiName: 'construction_type',
          displayName: 'Construction Type',
          label: 'What type of material is this building constructed out of?',
          helpText:
            'Fire Resistive: Steel and Concrete\nNon-Combustible: Hollow Concrete Block (HCB)\nSteel: Steel frame and Siding\nMasonry: Brick exterior with frame interior\nWood Frame: Wooden framing with combustible exterior',
          component: 'InputToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'Fire Resistive',
                label: 'Fire Resistive',
              },
              {
                value: 'Non-Combustible',
                label: 'Non-Combustible',
              },
              {
                value: 'Steel',
                label: 'Steel',
              },
              {
                value: 'Masonry',
                label: 'Masonry',
              },
              {
                value: 'Wood Frame',
                label: 'Wood Frame',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        value: 'Fire Resistive',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44245',
          apiName: 'sprinklers',
          displayName: 'Sprinklers',
          label: 'Is there a functioning sprinkler system in place?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: false,
          subQuestions: [],
        },
        value: 'yes',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44246',
          apiName: 'building_coverage',
          displayName: 'Building Coverage',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44247',
              apiName: 'building',
              displayName: 'Building',
              label: 'Does this location require Building coverage?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            {
              id: '44248',
              apiName: 'building_slid',
              displayName: 'Building Slid',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 10000000,
              },
              required: false,
              subQuestions: [],
            },
            {
              id: '44249',
              apiName: 'property_deductible',
              displayName: 'Property Deductible',
              label: 'What would you like your deductible to be?',
              helpText:
                'Deductible costs are the amount that is paid out of pocket before the insurance coverage takes effect. A higher deductible will reduce the premium paid. ',
              component: 'InputToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: '0',
                    label: 'No Deductible',
                  },
                  {
                    value: '1000',
                    label: '$1,000',
                  },
                  {
                    value: '2500',
                    label: '$2,500',
                  },
                  {
                    value: '5000',
                    label: '$5,000',
                  },
                  {
                    value: '10000',
                    label: '$10,000',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '44247',
              apiName: 'building',
              displayName: 'Building',
              label: 'Does this location require Building coverage?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            value: 'yes',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44248',
              apiName: 'building_slid',
              displayName: 'Building Slid',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 10000000,
              },
              required: false,
              subQuestions: [],
            },
            value: '950000',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44249',
              apiName: 'property_deductible',
              displayName: 'Property Deductible',
              label: 'What would you like your deductible to be?',
              helpText:
                'Deductible costs are the amount that is paid out of pocket before the insurance coverage takes effect. A higher deductible will reduce the premium paid. ',
              component: 'InputToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: '0',
                    label: 'No Deductible',
                  },
                  {
                    value: '1000',
                    label: '$1,000',
                  },
                  {
                    value: '2500',
                    label: '$2,500',
                  },
                  {
                    value: '5000',
                    label: '$5,000',
                  },
                  {
                    value: '10000',
                    label: '$10,000',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            value: '5000',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44250',
          apiName: 'business_property_coverage',
          displayName: 'Business Property Coverage',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44251',
              apiName: 'business_contents',
              displayName: 'Business Contents',
              label:
                'Does this location require coverage for equipment including computers and permanent fixtures?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44252',
              apiName: 'business_property_limits',
              displayName: 'Business Property Limits',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '44251',
              apiName: 'business_contents',
              displayName: 'Business Contents',
              label:
                'Does this location require coverage for equipment including computers and permanent fixtures?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            value: 'no',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44253',
          apiName: 'business_interruption',
          displayName: 'Business Interruption',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44254',
              apiName: 'bi',
              displayName: 'BI',
              label:
                'Does this location require business interruption coverage? ',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            {
              id: '44255',
              apiName: 'business_interruption_coverage',
              displayName: 'Business Interruption Coverage',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: false,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '44254',
              apiName: 'bi',
              displayName: 'BI',
              label:
                'Does this location require business interruption coverage? ',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: false,
              subQuestions: [],
            },
            value: 'yes',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '44255',
              apiName: 'business_interruption_coverage',
              displayName: 'Business Interruption Coverage',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: false,
              subQuestions: [],
            },
            value: '1000000',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44256',
          apiName: 'portable_contents_coverage',
          displayName: 'Portable Contents Coverage',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44257',
              apiName: 'portable_contents',
              displayName: 'Portable Contents',
              label:
                'Does this location require portable contents and tools coverage?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44258',
              apiName: 'portable_contents_limits',
              displayName: 'Portable Contents Limits',
              label: 'How much do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '44257',
              apiName: 'portable_contents',
              displayName: 'Portable Contents',
              label:
                'Does this location require portable contents and tools coverage?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            value: 'no',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44259',
          apiName: 'other_contents',
          displayName: 'Other Contents',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '44260',
              apiName: 'other_contents_coverage',
              displayName: 'Other contents coverage',
              label:
                'Does this location require coverage for any other contents not listed?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '44261',
              apiName: 'other_contents_limits',
              displayName: 'Other Contents Limits',
              label: 'How much coverage do you require?',
              helpText: null,
              component: 'slider',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
                slider_max: 1000000,
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '44260',
              apiName: 'other_contents_coverage',
              displayName: 'Other contents coverage',
              label:
                'Does this location require coverage for any other contents not listed?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
            },
            value: 'no',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
];
