import { DisplayFormattedQuestionIdentifier } from '../common';
import { useTheme } from '@mui/material';
//types
import { ProgramBuilderQuestionInstance } from '../../../FormManager/classes';

interface DisplaySingleQuestionInstanceProps {
  questionInstance?: ProgramBuilderQuestionInstance;
  fallback?: string;
}
/**
 * Display a single question instance in a aesthetically pleasing way
 */
function DisplaySingleQuestionInstance({
  questionInstance,
  fallback,
}: DisplaySingleQuestionInstanceProps) {
  return (
    <>
      <li
        key={
          questionInstance ? questionInstance.generateDisplayName() : fallback
        }
        aria-label={
          questionInstance ? questionInstance.generateDisplayName() : fallback
        }
      >
        <DisplayFormattedQuestionIdentifier
          questionInstance={questionInstance}
          fallback={fallback}
        />
        {questionInstance && questionInstance.subQuestions.length > 0 ? (
          <QuestionInstanceTreeDisplay
            questionInstances={questionInstance.subQuestions}
          />
        ) : null}
      </li>
    </>
  );
}

interface QuestionInstanceTreeDisplayProps {
  questionInstances?: Array<ProgramBuilderQuestionInstance>;
  fallback?: string;
}
/**
 * Display a list of questionInstances as a list, with subquestions recursively listed under their question.
 * @param questionInstances - Undefined, or a list of the questionInstances to display
 * @param fallback - to be displayed if the passed in questionInstances is undefined or an empty list
 */
export function QuestionInstanceTreeDisplay({
  questionInstances,
  fallback,
}: QuestionInstanceTreeDisplayProps) {
  const theme = useTheme();
  const listStyle = {
    marginLeft: theme.spacing(2),
    listStyleType: 'disc',
  };
  if (!questionInstances || questionInstances.length === 0) {
    if (fallback) {
      return (
        <ul style={listStyle}>
          <DisplaySingleQuestionInstance
            questionInstance={undefined}
            fallback={fallback}
          />{' '}
        </ul>
      );
    }
    return null;
  }
  return (
    <ul style={listStyle}>
      {questionInstances.map((questionInstance) =>
        DisplaySingleQuestionInstance({ questionInstance, fallback }),
      )}
    </ul>
  );
}
