import { gql } from '@apollo/client';

export const QUERY_PROSPECTS = gql`
  query(
    $token: String!
    $queries: [String!]!
    $email: String!
    $searchCircles: [ProspectSearchCircleInput!]!
  ) {
    prospects(
      token: $token
      queries: $queries
      email: $email
      searchCircles: $searchCircles
    ) {
      name
      phoneNumber
      addressComponents {
        floor
        streetNumber
        streetName
        city
        province
        country
        postalCode
      }
      formattedAddress
      website
      contacts
      provenance
    }
  }
`;

export const QUERY_MUNICIPALITIES = gql`
  query($province: String!) {
    municipalities(province: $province) {
      province
      municipalities
    }
  }
`;

export const QUERY_SHALLOW_PROSPECTS = gql`
  query($query: String!, $province: String!, $municipalities: [String]) {
    shallowProspects(
      query: $query
      province: $province
      municipalities: $municipalities
    ) {
      results
    }
  }
`;
