import { useState, useEffect } from 'react';
import { Card, Grid, CardHeader, CardContent, Typography } from '@mui/material';

import {
  IclarifyCommercialConstructionProduct,
  IclarifyCommercialConstructionHighlights,
} from './classes';

import { DetailsCard } from './commonComponents';

import { NO_INFO_STRING } from './constants';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
const COLUMN_WIDTH = '250px';
const COLUMN_STYLING = `${COLUMN_WIDTH} auto`;

interface DisplayIclarifyCommercialConstructionHighlightsProps {
  iClarifyCommercialConstructionProduct: $TSFixMe;
}
const DisplayIclarifyCommercialConstructionHighlights = ({
  iClarifyCommercialConstructionProduct,
}: DisplayIclarifyCommercialConstructionHighlightsProps) => {
  const [highlights, setHighlights] = useState(
    new IclarifyCommercialConstructionHighlights(
      iClarifyCommercialConstructionProduct,
    ),
  );

  useEffect(() => {
    setHighlights(
      new IclarifyCommercialConstructionHighlights(
        iClarifyCommercialConstructionProduct,
      ),
    );
  }, [iClarifyCommercialConstructionProduct]);

  if (Object.values(highlights).every((elem) => elem === null)) {
    return 'No information';
  }

  const toDisplay = highlights.generateHighlightsToDisplay();

  if (!toDisplay || Object.keys(toDisplay).length === 0) {
    return 'No highlights';
  }
  return (
    <Grid
      container
      sx={{
        '& li': {
          breakInside: 'avoid',
          py: 1,
        },
        '& li:before': {
          content: '"-"',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
        '& ul': {
          paddingLeft: '1em',
          listStyle: 'none',
        },
      }}
      spacing={3}
    >
      {Object.keys(toDisplay).map((buildingId, index, allKeys) => (
        <Grid item xs={12} key={'building_' + buildingId + '_' + index}>
          <DetailsCard
            heading={allKeys.length > 0 ? `Building ${buildingId}` : null}
            childrenWrapperStyle={{ columns: COLUMN_STYLING }}
          >
            <ul>
              {
                // @ts-expect-error
                toDisplay[buildingId].map((highlight, index) => (
                  <li key={highlight + '_' + index}>{highlight}</li>
                ))
              }
            </ul>
          </DetailsCard>
        </Grid>
      ))}
    </Grid>
  );
};

interface DisplayIclarifyCommercialConstructionDataProps {
  details: $TSFixMe;
  setLocationData?: $TSFixMe;
  loadQuotePdf?: $TSFixMe;
}
/**
 * Display the CommercialConstruction data for the given location
 */
export const DisplayIclarifyCommercialConstructionData = ({
  details,
}: DisplayIclarifyCommercialConstructionDataProps) => {
  const [
    iClarifyCommercialConstructionProduct,
    setIclarifyCommercialConstructionProduct,
  ] = useState<null | IclarifyCommercialConstructionProduct>(
    // @ts-expect-error
    new IclarifyCommercialConstructionProduct(),
  );

  useEffect(() => {
    if (
      details &&
      details.opta &&
      details.opta.optaIclarifyCommercialConstructionData
    ) {
      setIclarifyCommercialConstructionProduct(
        IclarifyCommercialConstructionProduct.createFromJson(
          details.opta.optaIclarifyCommercialConstructionData,
        ),
      );
    } else {
      setIclarifyCommercialConstructionProduct(null);
    }
  }, [
    details,
    details.opta,
    details.opta.optaIclarifyCommercialConstructionData,
  ]);

  if (
    !details ||
    !details.opta ||
    !details.opta.optaIclarifyCommercialConstructionData
  ) {
    return null;
  }
  return (
    <>
      <Typography
        variant='h6'
        align='left'
        color='textSecondary'
        sx={{ mt: 4, mb: 2 }}
      >
        iClarify Commercial Construction Data
      </Typography>

      <Grid container spacing={3} sx={{ textAlign: 'left' }}>
        <Grid item xs={12} key='highlights'>
          <Card sx={{ backgroundColor: 'background.neutral' }}>
            <CardHeader
              title='Highlights'
              titleTypographyProps={{
                variant: 'h6',
                align: 'center',
                color: 'textSecondary',
              }}
            />
            <CardContent>
              {/*
                // @ts-expect-error */}
              <DisplayIclarifyCommercialConstructionHighlights
                iClarifyCommercialConstructionProduct={
                  iClarifyCommercialConstructionProduct
                }
              />
            </CardContent>
          </Card>
        </Grid>
        {iClarifyCommercialConstructionProduct &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Messages &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Messages.Message &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Messages.Message.length > 0 ? (
          <Grid item xs={12} key='messages'>
            <DetailsCard heading='Messages'>
              {
                // @ts-expect-error
                iClarifyCommercialConstructionProduct.Messages.Message
                  .length === 1 ? (
                  <Typography variant='body1'>
                    {
                      // @ts-expect-error
                      iClarifyCommercialConstructionProduct.Messages.Message[0]
                    }
                  </Typography>
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      iClarifyCommercialConstructionProduct.Messages.Message.map(
                        // @ts-expect-error
                        (message, index) => (
                          <li key={index}>{message}</li>
                        ),
                      )
                    }
                  </ul>
                )
              }
            </DetailsCard>
          </Grid>
        ) : null}
        {iClarifyCommercialConstructionProduct &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property ? (
          <Grid item xs={12}>
            <DetailsCard
              heading='Lot'
              childrenWrapperStyle={{ columns: COLUMN_STYLING }}
            >
              {
                // @ts-expect-error
                iClarifyCommercialConstructionProduct.Property.displayAsJsx({
                  key: '0',
                })
              }
            </DetailsCard>
          </Grid>
        ) : null}
        {iClarifyCommercialConstructionProduct &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property.Buildings &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property.Buildings.Building &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property.Buildings.Building
          .length > 0
          ? // @ts-expect-error
            iClarifyCommercialConstructionProduct.Property.Buildings.Building.map(
              // @ts-expect-error
              (building, index, buildings) => (
                <Grid item xs={12} key={index}>
                  <DetailsCard
                    heading={`Building${
                      buildings.length > 1 && building.StructureID
                        ? ' ' + building.StructureID
                        : ''
                    }`}
                  >
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={12} md={7}>
                        <DetailsCard
                          heading='Construction Features'
                          childrenWrapperStyle={{ columns: COLUMN_STYLING }}
                        >
                          {building.ConstructionFeatures
                            ? building.ConstructionFeatures.displayAsJsx()
                            : NO_INFO_STRING}
                        </DetailsCard>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <DetailsCard heading='Protection'>
                          {building.Protection
                            ? building.Protection.displayAsJsx()
                            : NO_INFO_STRING}
                        </DetailsCard>
                      </Grid>
                    </Grid>
                  </DetailsCard>
                </Grid>
              ),
            )
          : null}
      </Grid>
    </>
  );
};
