// types and classes
import { UnifiedAnswerInstance } from '../../classes';

export function extractAllAnsweredLocationAnswers(
  answersList: Array<UnifiedAnswerInstance>,
) {
  const locationAnswers: Array<UnifiedAnswerInstance> = [];
  answersList.forEach((answer) => {
    if (answer.isAnsweredLocation()) {
      locationAnswers.push(answer);
    }
    locationAnswers.push(
      ...extractAllAnsweredLocationAnswers(answer.subAnswers),
    );
  });
  return locationAnswers;
}
