import { OptaAddress } from './OptaCommonClasses';
import {
  convertMeasurementStringToHumanReadable,
  formatNumber,
  formatBooleanWithUnknownToString,
  returnSelfOrNoInfo,
  displayAsSingleItemOrList,
} from './classUtility';
import { NO_INFO_STRING } from '../constants';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../../../util';

export class Messages {
  Message: $TSFixMe;

  constructor(MessageInput: $TSFixMe) {
    this.Message = MessageInput;
  }
  static createFromJson(response: $TSFixMe) {
    try {
      if (!response) {
        return null;
      }
      return new Messages(response.Message);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new Messages from json', response, e);
      return null;
    }
  }
}

export class BusinessIndustry {
  IBCCode: $TSFixMe;
  IBCIndustryDescription: $TSFixMe;
  SICCode: $TSFixMe;
  SICIndustryDescription: $TSFixMe;

  constructor(
    IBCCodeInput: $TSFixMe,
    IBCIndustryDescriptionInput: $TSFixMe,
    SICCodeInput: $TSFixMe,
    SICIndustryDescriptionInput: $TSFixMe,
  ) {
    this.IBCCode = IBCCodeInput;
    this.IBCIndustryDescription = IBCIndustryDescriptionInput;
    this.SICCode = SICCodeInput;
    this.SICIndustryDescription = SICIndustryDescriptionInput;
  }
  static createFromJson(response: $TSFixMe) {
    try {
      if (!response) {
        return null;
      }
      return new BusinessIndustry(
        response.IBCCode,
        response.IBCIndustryDescription,
        response.SICCode,
        response.SICIndustryDescription,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new BusinessIndustry from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='IBC'>
            IBC:{' '}
            {this.IBCCode && this.IBCIndustryDescription
              ? `${this.IBCIndustryDescription} (${this.IBCCode})`
              : 'No information'}
          </li>
          <li key='SIC'>
            SIC:{' '}
            {this.SICCode && this.SICIndustryDescription
              ? `${this.SICIndustryDescription} (${this.SICCode})`
              : 'No information'}
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying new BusinessIndustry`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialParkingTypes {
  // @ts-expect-error
  constructor(ParkingTypeInput) {
    // @ts-expect-error
    this.ParkingType = ParkingTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialParkingTypes(response.ParkingType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialParkingTypes from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.ParkingType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error displaying new CommercialParkingTypes', e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialParking {
  // @ts-expect-error
  constructor(ParkingTypesInput, ParkingPresentInput) {
    // @ts-expect-error
    this.ParkingTypes =
      CommercialParkingTypes.createFromJson(ParkingTypesInput);
    // @ts-expect-error
    this.ParkingPresent = ParkingPresentInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialParking(
        response.ParkingTypes,
        response.ParkingPresent,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialParking from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      if (this.ParkingPresent === false) {
        return 'Not present';
      }
      // @ts-expect-error
      if (this.ParkingPresent === undefined || this.ParkingPresent === null) {
        return NO_INFO_STRING;
      }
      // @ts-expect-error
      return this.ParkingTypes.displayAsJsx();
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialParking`, e);
      return NO_INFO_STRING;
    }
  }
}

export class Elevator {
  // @ts-expect-error
  constructor(ElevatorCountInput, ElevatorPresentInput) {
    // @ts-expect-error
    this.ElevatorCount = ElevatorCountInput;
    // @ts-expect-error
    this.ElevatorPresent = ElevatorPresentInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new Elevator(response.ElevatorCount, response.ElevatorPresent);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new Elevator from json', response, e);
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      if (this.ElevatorPresent === false) {
        return 'Not present';
      }
      // @ts-expect-error
      if (this.ElevatorPresent === undefined || this.ElevatorPresent === null) {
        return NO_INFO_STRING;
      }
      // @ts-expect-error
      if (this.ElevatorCount) {
        // @ts-expect-error
        return this.ElevatorCount;
      }
      return NO_INFO_STRING;
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying Elevator`, e);
      return NO_INFO_STRING;
    }
  }
}
export class CommercialWiringTypes {
  // @ts-expect-error
  constructor(WiringTypeInput) {
    // @ts-expect-error
    this.WiringType = WiringTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialWiringTypes(response.WiringType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialWiringTypes from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.WiringType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displayer CommercialWiringTypes`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialWiringPanelTypes {
  // @ts-expect-error
  constructor(WiringPanelTypeInput) {
    // @ts-expect-error
    this.WiringPanelType = WiringPanelTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialWiringPanelTypes(response.WiringPanelType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialWiringPanelTypes from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.WiringPanelType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialWiringPanelTypes`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialWiring {
  // @ts-expect-error
  constructor(WiringTypesInput, WiringPanelTypesInput) {
    // @ts-expect-error
    this.WiringTypes = CommercialWiringTypes.createFromJson(WiringTypesInput);
    // @ts-expect-error
    this.WiringPanelTypes = CommercialWiringPanelTypes.createFromJson(
      WiringPanelTypesInput,
    );
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialWiring(
        response.WiringTypes,
        response.WiringPanelTypes,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialWiring from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='WiringType'>
            Wiring Types:{' '}
            {
              // @ts-expect-error
              this.WiringTypes
                ? // @ts-expect-error
                  this.WiringTypes.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='WiringPanelType'>
            Wiring Panel Types:{' '}
            {
              // @ts-expect-error
              this.WiringPanelTypes
                ? // @ts-expect-error
                  this.WiringPanelTypes.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialWiring`, e);
      return NO_INFO_STRING;
    }
  }
}
export class CommercialWaterHeater {
  // @ts-expect-error
  constructor(WaterHeaterTypeInput, WaterHeaterFuelTypeInput) {
    // @ts-expect-error
    this.WaterHeaterType = WaterHeaterTypeInput;
    // @ts-expect-error
    this.WaterHeaterFuelType = WaterHeaterFuelTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialWaterHeater(
        response.WaterHeaterType,
        response.WaterHeaterFuelType,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialWaterHeater from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='WaterHeaterType'>
            Water Heater Types:{' '}
            {
              // @ts-expect-error
              displayAsSingleItemOrList(this.WaterHeaterType)
            }
          </li>
          <li key='WaterHeaterFuelType'>
            Water Heater Fuel Types:{' '}
            {
              // @ts-expect-error
              displayAsSingleItemOrList(this.WaterHeaterFuelType)
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialWaterHeater`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialHeatingTypes {
  // @ts-expect-error
  constructor(HeatingTypeInput) {
    // @ts-expect-error
    this.HeatingType = HeatingTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialHeatingTypes(response.HeatingType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialHeatingTypes from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.HeatingType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialHeatingTypes`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialHeatingFuelSources {
  // @ts-expect-error
  constructor(HeatingFuelSourceInput) {
    // @ts-expect-error
    this.HeatingFuelSource = HeatingFuelSourceInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialHeatingFuelSources(response.HeatingFuelSource);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialHeatingFuelSources from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.HeatingFuelSource);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialHeatingFuelSources`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialHeating {
  // @ts-expect-error
  constructor(HeatingTypesInput, HeatingFuelSourcesInput) {
    // @ts-expect-error
    this.HeatingTypes =
      CommercialHeatingTypes.createFromJson(HeatingTypesInput);
    // @ts-expect-error
    this.HeatingFuelSources = CommercialHeatingFuelSources.createFromJson(
      HeatingFuelSourcesInput,
    );
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialHeating(
        response.HeatingTypes,
        response.HeatingFuelSources,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialHeating from json',
        response,
        e,
      );
      return null;
    }
  }

  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='HeatingTypes'>
            Heating Types:{' '}
            {
              // @ts-expect-error
              this.HeatingTypes
                ? // @ts-expect-error
                  this.HeatingTypes.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='HeatingFuelSources'>
            Heating Fuel Sources:{' '}
            {
              // @ts-expect-error
              this.HeatingFuelSources
                ? // @ts-expect-error
                  this.HeatingFuelSources.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialHeating`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialPlumbing {
  // @ts-expect-error
  constructor(PlumbingTypeInput) {
    // @ts-expect-error
    this.PlumbingType = PlumbingTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialPlumbing(response.PlumbingType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialPlumbing from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.PlumbingType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialPlumbing`, e);
      return NO_INFO_STRING;
    }
  }
}

export class RoofConstructions {
  // @ts-expect-error
  constructor(RoofConstructionInput) {
    // @ts-expect-error
    this.RoofConstruction = RoofConstructionInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new RoofConstructions(response.RoofConstruction);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new RoofConstructions from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.RoofConstruction);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying RoofConstructions`, e);
      return NO_INFO_STRING;
    }
  }
}

export class RoofSurfaces {
  // @ts-expect-error
  constructor(RoofSurfaceInput) {
    // @ts-expect-error
    this.RoofSurface = RoofSurfaceInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new RoofSurfaces(response.RoofSurface);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new RoofSurfaces from json', response, e);
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.RoofSurface);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying RoofSurfaces`, e);
      return NO_INFO_STRING;
    }
  }
}

export class RoofTypes {
  // @ts-expect-error
  constructor(RoofTypeInput) {
    // @ts-expect-error
    this.RoofType = RoofTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new RoofTypes(response.RoofType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new RoofTypes from json', response, e);
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return displayAsSingleItemOrList(this.RoofType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying RoofTypes`, e);
      return NO_INFO_STRING;
    }
  }
}

export class RoofAttributes {
  // @ts-expect-error
  constructor(RoofConstructionsInput, RoofSurfacesInput, RoofTypesInput) {
    // @ts-expect-error
    this.RoofConstructions = RoofConstructions.createFromJson(
      RoofConstructionsInput,
    );
    // @ts-expect-error
    this.RoofSurfaces = RoofSurfaces.createFromJson(RoofSurfacesInput);
    // @ts-expect-error
    this.RoofTypes = RoofTypes.createFromJson(RoofTypesInput);
  }

  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new RoofAttributes(
        response.RoofConstructions,
        response.RoofSurfaces,
        response.RoofTypes,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new RoofAttributes from json', response, e);
      return null;
    }
  }
  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='Constructions'>
            Construction:{' '}
            {
              // @ts-expect-error
              this.RoofConstructions
                ? // @ts-expect-error
                  this.RoofConstructions.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='Surfaces'>
            Surfaces:{' '}
            {
              // @ts-expect-error
              this.RoofSurfaces
                ? // @ts-expect-error
                  this.RoofSurfaces.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='Types'>
            Types:{' '}
            {
              // @ts-expect-error
              this.RoofTypes ? this.RoofTypes.displayAsJsx() : NO_INFO_STRING
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying RoofAttributes`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialExteriorWallTypes {
  ExteriorWallType: $TSFixMe;

  constructor(ExteriorWallTypeInput: $TSFixMe) {
    this.ExteriorWallType = ExteriorWallTypeInput;
  }

  static createFromJson(response: $TSFixMe) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialExteriorWallTypes(response.ExteriorWallType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialExteriorWallTypes from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      return displayAsSingleItemOrList(this.ExteriorWallType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialExteriorWallTypes`, e);
      return NO_INFO_STRING;
    }
  }
}
export class CommercialBasement {
  constructor(
    // @ts-expect-error
    BasementPresentInput,
    // @ts-expect-error
    BasementAreaInput,
    // @ts-expect-error
    BasementAreaUnitOfMeasurementInput,
  ) {
    // @ts-expect-error
    this.BasementPresent = BasementPresentInput;
    // @ts-expect-error
    this.BasementArea = BasementAreaInput;
    // @ts-expect-error
    this.BasementAreaUnitOfMeasurement = BasementAreaUnitOfMeasurementInput;
  }

  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialBasement(
        response.BasementPresent,
        response.BasementArea,
        response.BasementAreaUnitOfMeasurement,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialBasement from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      if (!this.BasementPresent) {
        return 'Not present';
      }
      // @ts-expect-error
      if (this.BasementArea) {
        // @ts-expect-error
        return `${formatNumber(this.BasementArea)}${
          // @ts-expect-error
          this.BasementAreaUnitOfMeasurement
            ? ' ' +
              convertMeasurementStringToHumanReadable(
                // @ts-expect-error
                this.BasementAreaUnitOfMeasurement,
              )
            : ''
        }`;
      }
      return NO_INFO_STRING;
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialBasement`, e);
      return NO_INFO_STRING;
    }
  }
}

export class BuildingUpdates {
  // @ts-expect-error
  constructor(BuildingUpdateInput) {
    // @ts-expect-error
    this.BuildingUpdate = BuildingUpdateInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new BuildingUpdates(response.BuildingUpdate);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new BuildingUpdates from json',
        response,
        e,
      );
      return null;
    }
  }
}

export class CommercialConstructionFeatures {
  constructor(
    // @ts-expect-error
    YearBuiltInput,
    // @ts-expect-error
    BuildingUpdatesInput,
    // @ts-expect-error
    TotalFloorAreaInput,
    // @ts-expect-error
    TotalFloorAreaUnitOfMeasurementInput,
    // @ts-expect-error
    NumberOfStoreysInput,
    // @ts-expect-error
    FloorHeightInput,
    // @ts-expect-error
    FloorHeightUnitOfMeasurementInput,
    // @ts-expect-error
    ConstructionClassInput,
    // @ts-expect-error
    CombustibilityClassInput,
    // @ts-expect-error
    BasementInput,
    // @ts-expect-error
    NumberOfUnitsInput,
    // @ts-expect-error
    ExteriorWallTypesInput,
    // @ts-expect-error
    RoofAttributesInput,
    // @ts-expect-error
    PlumbingTypesInput,
    // @ts-expect-error
    HeatingInput,
    // @ts-expect-error
    WaterHeaterInput,
    // @ts-expect-error
    WiringInput,
    // @ts-expect-error
    ElevatorInput,
    // @ts-expect-error
    ParkingInput,
    // @ts-expect-error
    LoadingDockPresentInput,
  ) {
    // @ts-expect-error
    this.YearBuilt = YearBuiltInput;
    // @ts-expect-error
    this.BuildingUpdates = BuildingUpdates.createFromJson(BuildingUpdatesInput);
    // @ts-expect-error
    this.TotalFloorArea = TotalFloorAreaInput;
    // @ts-expect-error
    this.TotalFloorAreaUnitOfMeasurement = TotalFloorAreaUnitOfMeasurementInput;
    // @ts-expect-error
    this.NumberOfStoreys = NumberOfStoreysInput;
    // @ts-expect-error
    this.FloorHeight = FloorHeightInput;
    // @ts-expect-error
    this.FloorHeightUnitOfMeasurement = FloorHeightUnitOfMeasurementInput;
    // @ts-expect-error
    this.ConstructionClass = ConstructionClassInput;
    // @ts-expect-error
    this.CombustibilityClass = CombustibilityClassInput;
    // @ts-expect-error
    this.Basement = CommercialBasement.createFromJson(BasementInput);
    // @ts-expect-error
    this.NumberOfUnits = NumberOfUnitsInput;
    // @ts-expect-error
    this.ExteriorWallTypes = CommercialExteriorWallTypes.createFromJson(
      ExteriorWallTypesInput,
    );
    // @ts-expect-error
    this.RoofAttributes = RoofAttributes.createFromJson(RoofAttributesInput);
    // @ts-expect-error
    this.PlumbingTypes = CommercialPlumbing.createFromJson(PlumbingTypesInput);
    // @ts-expect-error
    this.Heating = CommercialHeating.createFromJson(HeatingInput);
    // @ts-expect-error
    this.WaterHeater = CommercialWaterHeater.createFromJson(WaterHeaterInput);
    // @ts-expect-error
    this.Wiring = CommercialWiring.createFromJson(WiringInput);
    // @ts-expect-error
    this.Elevator = Elevator.createFromJson(ElevatorInput);
    // @ts-expect-error
    this.Parking = CommercialParking.createFromJson(ParkingInput);
    // @ts-expect-error
    this.LoadingDockPresent = LoadingDockPresentInput;
  }

  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialConstructionFeatures(
        response.YearBuilt,
        response.BuildingUpdates,
        response.TotalFloorArea,
        response.TotalFloorAreaUnitOfMeasurement,
        response.NumberOfStoreys,
        response.FloorHeight,
        response.FloorHeightUnitOfMeasurement,
        response.ConstructionClass,
        response.CombustibilityClass,
        response.Basement,
        response.NumberOfUnits,
        response.ExteriorWallTypes,
        response.RoofAttributes,
        response.PlumbingTypes,
        response.Heating,
        response.WaterHeater,
        response.Wiring,
        response.Elevator,
        response.Parking,
        response.LoadingDockPresent,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialConstructionFeatures from json',
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='YearBuilt'>
            Year Built:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.YearBuilt)
            }
          </li>
          <li key='BuildingUpdates'>
            Building Updates:{' '}
            {
              // @ts-expect-error
              this.BuildingUpdates &&
              // @ts-expect-error
              this.BuildingUpdates.BuildingUpdate &&
              // @ts-expect-error
              this.BuildingUpdates.BuildingUpdate.length > 0 ? (
                // @ts-expect-error
                this.BuildingUpdates.BuildingUpdate.length === 1 ? (
                  // @ts-expect-error
                  this.BuildingUpdates.BuildingUpdate[0]
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      this.BuildingUpdates.BuildingUpdate.map((updateYear) => (
                        <li key={updateYear}>{updateYear}</li>
                      ))
                    }
                  </ul>
                )
              ) : (
                NO_INFO_STRING
              )
            }
          </li>
          <li key='TotalFloorArea'>
            Total Floor Area:{' '}
            {
              // @ts-expect-error
              this.TotalFloorArea
                ? // @ts-expect-error
                  `${formatNumber(this.TotalFloorArea)}${
                    // @ts-expect-error
                    this.TotalFloorAreaUnitOfMeasurement
                      ? ' ' +
                        convertMeasurementStringToHumanReadable(
                          // @ts-expect-error
                          this.TotalFloorAreaUnitOfMeasurement,
                        )
                      : ''
                  }`
                : NO_INFO_STRING
            }
          </li>
          <li key='NumberOfStoreys'>
            Storeys:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.NumberOfStoreys)
            }
          </li>
          <li key='FloorHeight'>
            Floor Height:{' '}
            {
              // @ts-expect-error
              this.FloorHeight
                ? // @ts-expect-error
                  `${formatNumber(this.FloorHeight)}${
                    // @ts-expect-error
                    this.FloorHeightUnitOfMeasurement
                      ? ' ' +
                        convertMeasurementStringToHumanReadable(
                          // @ts-expect-error
                          this.FloorHeightUnitOfMeasurement,
                        )
                      : ''
                  }`
                : NO_INFO_STRING
            }
          </li>
          <li key='ConstructionClass'>
            Construction Class:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.ConstructionClass)
            }
          </li>
          <li key='CombustibilityClass'>
            Combustibility Class:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.CombustibilityClass)
            }
          </li>
          <li key='Basement'>
            Basement:{' '}
            {
              // @ts-expect-error
              this.Basement ? this.Basement.displayAsJsx() : NO_INFO_STRING
            }
          </li>
          <li key='NumberOfUnits'>
            Number of Units:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.NumberOfUnits)
            }
          </li>
          <li key='ExteriorWallTypes'>
            Exterior Wall Types:{' '}
            {
              // @ts-expect-error
              this.ExteriorWallTypes
                ? // @ts-expect-error
                  this.ExteriorWallTypes.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='RoofAttributes'>
            Roof Attributes:{' '}
            {
              // @ts-expect-error
              this.RoofAttributes
                ? // @ts-expect-error
                  this.RoofAttributes.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='PlumbingTypes'>
            Plumbing Types:{' '}
            {
              // @ts-expect-error
              this.PlumbingTypes
                ? // @ts-expect-error
                  this.PlumbingTypes.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='Heating'>
            Heating:
            {
              // @ts-expect-error
              this.Heating ? this.Heating.displayAsJsx() : NO_INFO_STRING
            }
          </li>
          <li key='WaterHeater'>
            Water Heater:{' '}
            {
              // @ts-expect-error
              this.WaterHeater
                ? // @ts-expect-error
                  this.WaterHeater.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='Wiring'>
            Wiring:{' '}
            {
              // @ts-expect-error
              this.Wiring ? this.Wiring.displayAsJsx() : NO_INFO_STRING
            }
          </li>
          <li key='Elevator'>
            Elevator:{' '}
            {
              // @ts-expect-error
              this.Elevator ? this.Elevator.displayAsJsx() : NO_INFO_STRING
            }
          </li>
          <li key='Parking'>
            Parking:{' '}
            {
              // @ts-expect-error
              this.Parking ? this.Parking.displayAsJsx() : NO_INFO_STRING
            }
          </li>
          <li key='LoadingDockPresent'>
            Loading Dock Present:{' '}
            {
              // @ts-expect-error
              formatBooleanWithUnknownToString(this.LoadingDockPresent)
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error displaying ConstructionFeatures', e);
      return NO_INFO_STRING;
    }
  }
}

export class PhysicalSecurityTypes {
  // @ts-expect-error
  constructor(PhysicalSecurityTypeInput) {
    // @ts-expect-error
    this.PhysicalSecurityType = PhysicalSecurityTypeInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new PhysicalSecurityTypes(response.PhysicalSecurityType);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new PhysicalSecurityTypes from json',
        response,
        e,
      );
      return null;
    }
  }
}

export class Protection {
  constructor(
    // @ts-expect-error
    SprinklerPresentInput,
    // @ts-expect-error
    SprinklerTypeCodeInput,
    // @ts-expect-error
    FireAlarmPresentInput,
    // @ts-expect-error
    FireAlarmTypeInput,
    // @ts-expect-error
    SecurityAlarmPresentInput,
    // @ts-expect-error
    SecurityAlarmTypeInput,
    // @ts-expect-error
    PhysicalSecurityPresentInput,
    // @ts-expect-error
    PhysicalSecurityTypesInput,
  ) {
    // @ts-expect-error
    this.SprinklerPresent = SprinklerPresentInput;
    // @ts-expect-error
    this.SprinklerTypeCode = SprinklerTypeCodeInput;
    // @ts-expect-error
    this.FireAlarmPresent = FireAlarmPresentInput;
    // @ts-expect-error
    this.FireAlarmType = FireAlarmTypeInput;
    // @ts-expect-error
    this.SecurityAlarmPresent = SecurityAlarmPresentInput;
    // @ts-expect-error
    this.SecurityAlarmType = SecurityAlarmTypeInput;
    // @ts-expect-error
    this.PhysicalSecurityPresent = PhysicalSecurityPresentInput;
    // @ts-expect-error
    this.PhysicalSecurityTypes = PhysicalSecurityTypes.createFromJson(
      PhysicalSecurityTypesInput,
    );
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new Protection(
        response.SprinklerPresent,
        response.SprinklerTypeCode,
        response.FireAlarmPresent,
        response.FireAlarmType,
        response.SecurityAlarmPresent,
        response.SecurityAlarmType,
        response.PhysicalSecurityPresent,
        response.PhysicalSecurityTypes,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new Protection from json', response, e);
      return null;
    }
  }
  displayAsJsx() {
    try {
      return (
        <ul>
          <li key='sprinkler'>
            Sprinkler:{' '}
            {
              // @ts-expect-error
              this.SprinklerPresent && this.SprinklerTypeCode
                ? // @ts-expect-error
                  this.SprinklerTypeCode
                : // @ts-expect-error
                  formatBooleanWithUnknownToString(this.SprinklerPresent)
            }
          </li>
          <li key='FireAlarm'>
            Fire Alarm:{' '}
            {
              // @ts-expect-error
              this.FireAlarmPresent && this.FireAlarmType
                ? // @ts-expect-error
                  this.FireAlarmType
                : // @ts-expect-error
                  formatBooleanWithUnknownToString(this.FireAlarmPresent)
            }
          </li>
          <li key='SecurityAlarm'>
            Security Alarm:{' '}
            {
              // @ts-expect-error
              this.SecurityAlarmPresent && this.SecurityAlarmType
                ? // @ts-expect-error
                  this.SecurityAlarmType
                : // @ts-expect-error
                  formatBooleanWithUnknownToString(this.SecurityAlarmPresent)
            }
          </li>
          <li key='PhysicalSecurity'>
            Physical Security:{' '}
            {
              // @ts-expect-error
              this.PhysicalSecurityPresent && this.PhysicalSecurityTypes ? (
                // @ts-expect-error
                this.PhysicalSecurityTypes.PhysicalSecurityType.length === 1 ? (
                  // @ts-expect-error
                  this.PhysicalSecurityTypes.PhysicalSecurityType[0]
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      this.PhysicalSecurityTypes.PhysicalSecurityType.map(
                        // @ts-expect-error
                        (type) => (
                          <li key={type}>{type}</li>
                        ),
                      )
                    }
                  </ul>
                )
              ) : (
                // @ts-expect-error
                formatBooleanWithUnknownToString(this.PhysicalSecurityPresent)
              )
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error displaying Protection', e);
      return null;
    }
  }
}

export class Building {
  // @ts-expect-error
  constructor(StructureIDInput, ConstructionFeaturesInput, ProtectionInput) {
    // @ts-expect-error
    this.StructureID = StructureIDInput;
    // @ts-expect-error
    this.ConstructionFeatures = CommercialConstructionFeatures.createFromJson(
      ConstructionFeaturesInput,
    );
    // @ts-expect-error
    this.Protection = Protection.createFromJson(ProtectionInput);
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new Building(
        response.StructureID,
        response.ConstructionFeatures,
        response.Protection,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new Building from json', response, e);
      return null;
    }
  }
}

export class Buildings {
  // @ts-expect-error
  constructor(BuildingInput) {
    if (BuildingInput && BuildingInput.length > 0) {
      // @ts-expect-error
      this.Building = BuildingInput.map((building) =>
        Building.createFromJson(building),
      );
    } else {
      // @ts-expect-error
      this.Building = null;
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new Buildings(response.Building);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new Buildings from json', response, e);
      return null;
    }
  }
}

export class CommercialProperty {
  constructor(
    // @ts-expect-error
    PropertyTypeInput,
    // @ts-expect-error
    IndustryInput,
    // @ts-expect-error
    LotSizeInput,
    // @ts-expect-error
    LotSizeUnitOfMeasurementInput,
    // @ts-expect-error
    BuildingsInput,
  ) {
    // @ts-expect-error
    this.PropertyType = PropertyTypeInput;
    // @ts-expect-error
    this.Industry = BusinessIndustry.createFromJson(IndustryInput);
    // @ts-expect-error
    this.LotSize = LotSizeInput;
    // @ts-expect-error
    this.LotSizeUnitOfMeasurement = LotSizeUnitOfMeasurementInput;
    // @ts-expect-error
    this.Buildings = Buildings.createFromJson(BuildingsInput);
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialProperty(
        response.PropertyType,
        response.Industry,
        response.LotSize,
        response.LotSizeUnitOfMeasurement,
        response.Buildings,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new CommercialProperty from json',
        response,
        e,
      );
      return NO_INFO_STRING;
    }
  }
  displayAsJsx({ key } = { key: '0' }) {
    try {
      return (
        <ul key={key}>
          <li key='propertyType'>
            Property Type:{' '}
            {
              // @ts-expect-error
              this.PropertyType || 'No information'
            }
          </li>
          <li key='lotSize'>
            Lot Size:{' '}
            {
              // @ts-expect-error
              this.LotSize
                ? `${formatNumber(
                    // @ts-expect-error
                    this.LotSize,
                  )} ${convertMeasurementStringToHumanReadable(
                    // @ts-expect-error
                    this.LotSizeUnitOfMeasurement,
                  )}`
                : 'No information'
            }
          </li>
          <li key='industry'>
            Industry:{' '}
            {
              // @ts-expect-error
              this.Industry ? this.Industry.displayAsJsx() : 'No information'
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error displaying Property', e);
      return NO_INFO_STRING;
    }
  }
}

export class IclarifyCommercialConstructionProduct {
  // @ts-expect-error
  constructor(Address, MessageInput, PropertyInput) {
    try {
      // @ts-expect-error
      this.Address = OptaAddress.createFromJson(Address);
      // @ts-expect-error
      this.Messages = Messages.createFromJson(MessageInput);
      // @ts-expect-error
      this.Property = CommercialProperty.createFromJson(PropertyInput);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new IclarifyCommercialConstructionProduct',
        e,
      );
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    // response = fakeData;
    try {
      if (!response) {
        return null;
      }
      return new IclarifyCommercialConstructionProduct(
        response.Address,
        response.Messages,
        response.Property,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        'Error creating new IclarifyCommercialConstructionProduct from json',
        response,
        e,
      );
      return null;
    }
  }
}

export class IclarifyCommercialConstructionHighlights {
  // @ts-expect-error
  constructor(iClarifyCommercialConstructionProductInput) {
    // @ts-expect-error
    this.product = iClarifyCommercialConstructionProductInput;
  }
  generateHighlightsToDisplay() {
    try {
      const toDisplay = {};

      let building = null;
      if (
        // @ts-expect-error
        this.product &&
        // @ts-expect-error
        this.product.Property &&
        // @ts-expect-error
        this.product.Property.Buildings &&
        // @ts-expect-error
        this.product.Property.Buildings.Building &&
        // @ts-expect-error
        this.product.Property.Buildings.Building.length > 0
      ) {
        // @ts-expect-error
        building = [...this.product.Property.Buildings.Building];
      }
      if (!building) {
        return null;
      }

      // @ts-expect-error
      building.forEach((build, index, building) => {
        const buildingHighlights = [];
        const buildingId = build.StructureID || index;
        const constructionFeatures = build.ConstructionFeatures;
        const protection = build.Protection;
        if (constructionFeatures) {
          if (constructionFeatures.YearBuilt) {
            let yearsUpdated = null;
            if (
              constructionFeatures.BuildingUpdates &&
              constructionFeatures.BuildingUpdates.BuildingUpdate &&
              constructionFeatures.BuildingUpdates.BuildingUpdate.length > 0
            ) {
              yearsUpdated = [
                ...constructionFeatures.BuildingUpdates.BuildingUpdate,
              ];
            }
            const highlightString = `Year Built: ${
              constructionFeatures.YearBuilt
            }${yearsUpdated ? ` (Updated ${yearsUpdated.join(', ')})` : ''}`;
            buildingHighlights.push(highlightString);
          }

          if (constructionFeatures.CombustibilityClass) {
            buildingHighlights.push(
              `Combustibility Class: ${constructionFeatures.CombustibilityClass}`,
            );
          }

          if (constructionFeatures.RoofAttributes) {
            if (constructionFeatures.RoofAttributes.RoofConstructions) {
              buildingHighlights.push(
                <>
                  Roof Construction:{' '}
                  {constructionFeatures.RoofAttributes.RoofConstructions.displayAsJsx()}
                </>,
              );
            }
            if (constructionFeatures.RoofAttributes.RoofSurfaces) {
              buildingHighlights.push(
                <>
                  Roof Surfaces:{' '}
                  {constructionFeatures.RoofAttributes.RoofSurfaces.displayAsJsx()}
                </>,
              );
            }
            if (constructionFeatures.RoofAttributes.RoofTypes) {
              buildingHighlights.push(
                <>
                  Roof Type:{' '}
                  {constructionFeatures.RoofAttributes.RoofTypes.displayAsJsx()}
                </>,
              );
            }
          }

          if (constructionFeatures.TotalFloorArea) {
            const floorAreaString = `Total Floor Area: ${formatNumber(
              constructionFeatures.TotalFloorArea,
            )}${
              constructionFeatures.TotalFloorAreaUnitOfMeasurement
                ? ' ' +
                  convertMeasurementStringToHumanReadable(
                    constructionFeatures.TotalFloorAreaUnitOfMeasurement,
                  )
                : ''
            }`;
            buildingHighlights.push(floorAreaString);

            let basementAreaString = null;
            if (
              constructionFeatures.Basement &&
              constructionFeatures.Basement.BasementPresent &&
              constructionFeatures.Basement.BasementArea
            ) {
              const basement = constructionFeatures.Basement;
              basementAreaString = `Basement Area: ${formatNumber(
                basement.BasementArea,
              )}${
                basement.BasementAreaUnitOfMeasurement
                  ? ' ' +
                    convertMeasurementStringToHumanReadable(
                      basement.BasementAreaUnitOfMeasurement,
                    )
                  : ''
              }`;
              buildingHighlights.push(basementAreaString);
            }
          }
        }

        if (protection) {
          if (protection.SprinklerPresent) {
            const highlightString = `Sprinkler: ${
              protection.SprinklerTypeCode
                ? protection.SprinklerTypeCode
                : 'Present'
            }`;
            buildingHighlights.push(highlightString);
          }
        }
        if (buildingHighlights.length !== 0) {
          // @ts-expect-error
          toDisplay[buildingId] = buildingHighlights;
        }
      });

      return toDisplay;
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error creating highlights to display:`, e);
      return null;
    }
  }
}
