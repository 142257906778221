import { graphql } from '../../gql';

export const QUESTIONS_TABLE_QUESTIONS = graphql(`
  query questionsTableQuestions(
    $token: String!
    $fuzzy: Boolean!
    $search: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    questionsTableQuestions(
      token: $token
      fuzzy: $fuzzy
      search: $search
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          ...QuestionInstanceFields
          subQuestions {
            ...QuestionInstanceFields
            subQuestions {
              ...QuestionInstanceFields
              subQuestions {
                ...QuestionInstanceFields
                subQuestions {
                  ...QuestionInstanceFields
                  subQuestions {
                    ...QuestionInstanceFields
                    subQuestions {
                      ...QuestionInstanceFields
                      subQuestions {
                        ...QuestionInstanceFields
                        subQuestions {
                          ...QuestionInstanceFields
                          subQuestions {
                            ...QuestionInstanceFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);
