import { graphql } from '../../gql';

export const VAGO_IBC_LIST = graphql(`
  query vagoIbcList($token: String!) {
    vagoIbcList(token: $token) {
      id
      displayName
      sicCode
    }
  }
`);
