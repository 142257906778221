import type { ReactNode } from 'react';
import {
  Redirect,
  // useLocation
} from '@reach/router';
import { useAuth } from '@calefy-inc/authentication';
// import { isJWTLazilyValid } from './utility';
import { isJWTLazilyValid } from '@calefy-inc/authentication';

interface AuthenticatedProps<PermissionsType extends string> {
  children: ReactNode;
  whitelist?: PermissionsType | Array<PermissionsType>;
  invalidPermissionsFallback?: ReactNode;
}

/**
 * Authentication component - if the user is authenticated, let them through; otherwise, redirect them to the 'login' page
 *
 * TODO: Because the useToken hook relies on a useEffect to set (or get) the token, there is a brief moment where even though the user is logged in, the token hasn't been set yet. In that case, the token is undefined -> return null (basically to wait for the token to arrive). When the useToken is finished, the token will either have a value (good -> display children) OR the token will be found to have been bad and set to null -> redirect to `login`
 *
 * @param children - the elements to display (if authorized)
 * @param whitelist - An optional permission or list of permissions. If provided, the component will only be displayed if there is a valid token and it has at least one of the required permissions in the whitelist NB if you pass in an empty list, it will block everyone except admin
 * @param invalidPermissionsFallback: if the user has a token but invalid permissions for this component, then display the fallback instead. Will default to null.
 */
const Authenticated = <PermissionsType extends string>({
  whitelist,
  children,
  invalidPermissionsFallback,
}: AuthenticatedProps<PermissionsType>) => {
  const { token, useAllowByPermissionsGenerator } = useAuth();
  const hasWhitelistPermissions =
    useAllowByPermissionsGenerator<PermissionsType>(whitelist ? whitelist : []);

  /* const location = useLocation(); */
  /* const initial = '/insurtech/'; */
  /* const relativePath = location.pathname.slice( */
  /*   location.pathname.indexOf(initial) + initial.length, */
  /* ); */
  if (isJWTLazilyValid(token) && (!whitelist || hasWhitelistPermissions)) {
    //console.log('In Authenticated: returning children');
    return children;
  } else if (isJWTLazilyValid(token) && !hasWhitelistPermissions) {
    //console.log('In Authenticated: returning invalid permissions fallback');
    return invalidPermissionsFallback || null;
  } else if (token === undefined) {
    //console.log('In Authenticated: no token, so returning null');
    return null;
  } else if (token === null) {
    /* const to = '/insurtech/login/' + relativePath; */
    // I think that this is the issue
    const to = '/insurtech/login' + `?to=${window.location.href}`;
    //console.log('In Authenticated; about to navigate to', to);
    return <Redirect to={to} noThrow />;
  }
  return null;
};

/* export default Authenticated; */
export { Authenticated }
