import { graphql } from '../../gql';
export const CREATE_INSURER_QUOTE = graphql(`
  mutation createInsurerQuote(
    $quoteUuid: String!
    $insurerQuoteIdentifier: String!
    $pdfFile: String!
  ) {
    createInsurerQuote(
      quoteUuid: $quoteUuid
      insurerQuoteIdentifier: $insurerQuoteIdentifier
      pdfFile: $pdfFile
    ) {
      ok
      insurerQuote {
        insurerQuoteIdentifier
        llmResponseBlob
        pdfFilePath
        isValid
      }
    }
  }
`);
