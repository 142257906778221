// material
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import { NewProgramBuilder } from '../../../../components/ProgramBuilder';
import { EditFormByIdManager } from './EditFormByIdManager';

// types and classes
import type { RouteComponentProps } from '@reach/router';

// ----------------------------------------------------------------------

interface FormEditByIdProps extends RouteComponentProps {}
export function FormEditById(_props: FormEditByIdProps) {
  const { themeStretch } = useSettings();
  // const {formId} = useParams();

  return (
    <>
      <Page title={'Forms: Edit Form | Manager'}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={'Edit form'}
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Edit form' },
            ]}
          />
          <EditFormByIdManager />
        </Container>
      </Page>
    </>
  );
}
