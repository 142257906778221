import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import type { $TSFixMe } from '@calefy-inc/utilityTypes';

type EditAllDialogueProps = {
  classes: $TSFixMe;
  open: $TSFixMe;
  setOpen: $TSFixMe;
  editAllSiblingFormCount: $TSFixMe;
  setUserEditAllResponse: $TSFixMe;
};
export default function EditAllDialogue({
  classes,
  open,
  setOpen,
  editAllSiblingFormCount,
  setUserEditAllResponse,
}: EditAllDialogueProps) {
  const setAll = () => {
    setUserEditAllResponse(true);
    setOpen(false);
  };
  const setOne = () => {
    setUserEditAllResponse(false);
    setOpen(false);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        data-testid='confirmation-dialog'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id='alert-dialog-title'>
          Multiple Questions Alert
          <IconButton
            className={classes.closeButton}
            aria-label='close'
            onClick={onClose}
            size='large'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ m: 0, p: 2 }}>
          <DialogContentText id='alert-dialog-description'>
            This question exists on {editAllSiblingFormCount} other forms. Would
            you like to edit all {editAllSiblingFormCount} of the questions? Or
            just this one?
          </DialogContentText>
          <DialogActions>
            <Button onClick={setAll}>Edit All </Button>
            <Button variant='outlined' onClick={setOne}>
              Edit just this question
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
