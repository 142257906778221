import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { formatListForDisplay } from '../../util';

const IMPLIED_RATE_TOLERANCE = 1e-5; // tolerance for the rate and implied rate to be considered different
const IMPLIED_PREMIUM_TOLERANCE = 1e-2; // tolerane for the premium and implied premium to be considered different (to the cent level is probably fine)

interface WithIdInput {
  id: string;
}
class WithId {
  id: string;

  constructor({ id }: WithIdInput) {
    this.id = id;
  }
}

interface InsurancePolicyInput extends WithIdInput {
  id: string;
  description: string;
  policyEffectiveDate: Date;
  policyExpiryDate: Date;
  coverages: Array<Coverage>;
}
export class InsurancePolicy extends WithId {
  description: string;
  policyEffectiveDate: Date;
  policyExpiryDate: Date;
  coverages: Array<Coverage>;

  constructor({
    id,
    description,
    policyExpiryDate,
    policyEffectiveDate,
    coverages,
  }: InsurancePolicyInput) {
    super({ id });

    this.description = description;
    this.policyExpiryDate = policyExpiryDate;
    this.policyEffectiveDate = policyEffectiveDate;
    this.coverages = coverages;
  }

  static createFromBackendResponse(backend: $TSFixMe) {
    return new InsurancePolicy({
      ...backend,
      // @ts-expect-error
      coverages: (backend.coverages || []).map((backendCoverage) =>
        Coverage.createFromBackendResponse(backendCoverage),
      ),
    });
  }
}

interface CoverageInput extends WithIdInput {
  id: string;
  name: string;
  perils: Array<Peril>;
  coveredLocations: Array<CoveredLocation>;
}
export class Coverage extends WithId {
  name: string;
  perils: Array<Peril>;
  coveredLocations: Array<CoveredLocation>;

  constructor({ id, name, perils, coveredLocations }: CoverageInput) {
    super({ id });

    this.name = name;
    this.perils = perils;
    this.coveredLocations = coveredLocations;
  }

  getDisplayName() {
    if (this.name === 'Property' && this.coveredLocations.length > 0) {
      return `${this.name} - ${formatListForDisplay(
        this.coveredLocations.map((coveredLocation) =>
          coveredLocation.getDisplayAddress(),
        ),
      )}`;
    }
    return this.name;
  }

  getAllCoveredLocationsString() {
    return formatListForDisplay(
      this.coveredLocations.map((loc) => loc.getDisplayAddress()),
    );
  }

  /**
   * Static Functions
   */
  static createFromBackendResponse(backendResponse: $TSFixMe) {
    return new Coverage({
      ...backendResponse,
      // @ts-expect-error
      perils: (backendResponse.perils || []).map((backendPeril) =>
        Peril.createFromBackendResponse(backendPeril),
      ),
      coveredLocations: (backendResponse.coveredLocations || []).map(
        // @ts-expect-error
        (backendCoveredLocation) =>
          CoveredLocation.createFromBackendResponse(backendCoveredLocation),
      ),
    });
  }
}

export interface PerilInput extends WithIdInput {
  id: string;
  name: string;
  limit: number;
  deductible: number;
  premium: number | string;
  rate: number;
  override?: boolean;
  displayName?: string;
}
export class Peril extends WithId {
  name: string;
  limit: number;
  deductible: number;
  premium: number;
  rate: number;
  override: boolean;
  displayName?: string; // an optional override to the display name from the backend

  constructor({
    id,
    name,
    limit,
    deductible,
    premium,
    rate,
    override,
    displayName,
  }: PerilInput) {
    super({ id });

    this.name = name;
    this.limit = limit;
    this.deductible = deductible;
    this.premium = Number(premium);
    this.rate = rate;
    this.override = !!override;
    this.displayName = displayName;
  }

  getImpliedRate() {
    return this.premium / this.limit;
  }

  getImpliedPremium() {
    return this.limit * this.rate;
  }

  impliedRateDifferent(tolerance: number = IMPLIED_RATE_TOLERANCE) {
    return Math.abs(this.getImpliedRate() - this.rate) > tolerance;
  }

  impliedPremiumDifferent(tolerance: number = IMPLIED_PREMIUM_TOLERANCE) {
    return Math.abs(this.getImpliedPremium() - this.premium) > tolerance;
  }

  /**
   * Determine if another peril matches this one (done by checking the attributes)
   */
  match(other: Peril): boolean {
    const keysToCheck: Array<keyof Peril> = [
      'id',
      'name',
      'limit',
      'deductible',
      'premium',
      'rate',
    ];
    return keysToCheck.every((key) => {
      const match = this[key] === other[key];
      console.log(
        `${key} - Comparing ${this[key]} against ${other[key]} - ${match}`,
      );
      return match;
    });
  }

  copyWithAmendments(input: Partial<PerilInput>) {
    return new Peril({ ...this, ...input });
  }

  copy() {
    return this.copyWithAmendments({});
  }

  static createFromBackendResponse(backend: $TSFixMe) {
    const peril = new Peril({ ...backend });
    return peril;
  }
}

interface CoveredLocationInput extends WithIdInput {
  description: string;
  address: string;
  city: string;
  province: string;
  postal: string;
}
export class CoveredLocation extends WithId {
  description: string;
  address: string;
  city: string;
  province: string;
  postal: string;

  constructor({
    id,
    description,
    address,
    city,
    province,
    postal,
  }: CoveredLocationInput) {
    super({ id });

    this.description = description;
    this.address = address;
    this.city = city;
    this.province = province;
    this.postal = postal;
  }

  getDisplayAddress() {
    return [this.address, this.city, this.province, this.postal]
      .filter((elem) => !!elem)
      .join(', ');
  }

  /**
   * Static Functions
   */
  static createFromBackendResponse(backend: $TSFixMe) {
    return new CoveredLocation({ ...backend });
  }
}
