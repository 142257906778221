// @ts-expect-error
import { FormState, Relevant } from 'informed';
import { InputToggle, YesNoToggle } from '@calefy-inc/informedMaterial';

import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';

// types and classes
import { FormValues } from 'informed';
import { QuoteComponentProps, GatherSubQuestions } from './types';
import { Component } from './classes';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';
import { LanguageAwareString, Language } from '../../../Typescript/classes';

export const defaultYesNoToggleOptions = [
  {
    value: 'yes',
    labels: [
      new LanguageAwareString('Yes', Language.english),
      new LanguageAwareString('Oui', Language.french),
    ],
  },
  {
    value: 'no',
    labels: [
      new LanguageAwareString('No', Language.english),
      new LanguageAwareString('Non', Language.french),
    ],
  },
];

const ProgramBuilderComponent = () => {
  return (
    <>
      <YesNoToggle
        field='default_boolean'
        label='Would you like to set a default value?'
      />
      <Relevant
        when={(formState: FormState) => {
          return formState.values['default_boolean'] === 'yes';
        }}
      >
        <YesNoToggle field='defaultValue' label='Default Value' />
      </Relevant>
    </>
  );
};

const quoteComponent = function ({
  questionInstance,
  classes,
  ...props
}: QuoteComponentProps) {
  const { options } = questionInstance.propsBlob;

  return (
    <InputToggle
      field={questionInstance.generateFieldName()}
      label={questionInstance.label}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      classes={classes}
      className={classes && classes.yesNoToggle ? classes.yesNoToggle : ''}
      options={options}
      {...props}
    />
  );
};

const gatherSubQuestions: GatherSubQuestions = (
  _formValues: FormValues,
  questionInstance: ProgramBuilderQuestionInstance,
) => {
  /**
   * WARNING WARNING WARNING WARNING WARNING
   *
   * This propsBlob will be overwritten by the backend on save - this exists here *only* so that the form preview will work!
   */
  questionInstance.propsBlob.options = defaultYesNoToggleOptions;
  return questionInstance;
};

const IconComponent = () => {
  return <ToggleOnRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'YesNoToggle',
    typeLabel: 'Yes/No Toggle',
    quoteComponent,
    programBuilderComponent: ProgramBuilderComponent,
    dataType: 'string',
    gatherSubQuestions,
    icon: IconComponent,
  });
}

export { quoteComponent };
