import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Bugsnag from '@bugsnag/browser';

import type { $TSFixMe } from '@calefy-inc/utilityTypes';

type BaseDisconnectionErrorProps = {
  classes: $TSFixMe;
  reset?: () => void;
};
export const BaseDisconnectionError = ({
  classes = {},
  reset,
}: BaseDisconnectionErrorProps) => {
  // determine where the 'quote' location is

  Bugsnag.notify(new Error('Unknown disconnection error'));
  return (
    <div className={classes.outerContainer} data-testid='DisconnectionError'>
      <div className={classes.main}>
        <Typography
          variant='h5'
          component='h2'
          color='primary'
          className={classes.title}
        >
          Error Connecting to Server
        </Typography>

        <Typography
          variant='body1'
          className={classes.content}
          color='textSecondary'
        >
          We are currently having trouble connecting to the server! Please try
          again in a few minutes. If you believe that you are receiving this
          message in error, please contact us at{' '}
          <a href={`mailto:support@calefy.ca?subject=Server disconnection`}>
            support@calefy.ca
          </a>
          .
        </Typography>

        {reset ? (
          <Button variant='contained' onClick={() => reset()} sx={{ mx: 2 }}>
            Try Again
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const DisconnectionError = withStyles((theme) => ({
  outerContainer: {
    textAlign: 'center',
    minHeight: '75vh',
  },
  main: {
    display: 'inline-block',
    margin: theme.spacing(10, 0, 0, 0),
    maxWidth: 'max(50%, 35rem)',
    ' & button': {
      flexGrow: '0',
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <BaseDisconnectionError {...props} />);
