import { Box, Card, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TopBusinessesSkeleton() {
  return (
    <Card>
      <Skeleton width='30%' sx={{ mx: 3, mt: 3 }} />
      <Skeleton width='20%' sx={{ mx: 3 }} />

      <Box sx={{ mx: 3 }} dir='ltr'>
        <Skeleton variant='rectangular' height='364px' sx={{ mb: 3, mt: 3 }} />
      </Box>
    </Card>
  );
}
