import { graphql } from '../gql';

export const triggerQuestionDeduplication = graphql(`
  query triggerQuestionDeduplication($token: String!) {
    triggerQuestionDeduplication(token: $token) {
      ok
      questionsDeduped
      error
    }
  }
`);
