import { useEffect, useState } from 'react';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { formStore } from '../../../../../../store';
import { v4 } from 'uuid';
import { Icon } from '@iconify/react';
import fileAddFill from '@iconify/icons-eva/file-add-fill';
import copyFill from '@iconify/icons-eva/copy-fill';
// material
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
// error handler
import Bugsnag from '@bugsnag/js';
// queries
import {
  allBusinessTypes as allBusinessTypesQuery,
  GET_MISSING_POLICIES,
} from '../../../../../../queries';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../../hooks';
// utils
import LoadingScreen from '../../../LoadingScreen';
// pages
import ErrorPage from '../../../../pages/Page500';
// components
import { CopyExistingFormButton } from '../../../../../FormManager/components/CopyExistingFormButton';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { InsufficientPermissionsErrorPage } from '../../../../../common/ErrorHandling';

// ----------------------------------------------------------------------

export default function FormNewForm() {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const hasFormEditPermissions = useAllowByPermission('edit:forms');
  const { addPolicy, setBusinessType, addGeneralForm } = formStore.actions;
  const [businessOptions, setBusinessOptions] = useState<Array<$TSFixMe>>([]);
  const [policyOptions, setPolicyOptions] = useState<Array<$TSFixMe>>([]);
  const [business, setBusiness] = useState<$TSFixMe>();
  const [policyId, setPolicyId] = useState<$TSFixMe>();
  const [policy, setPolicy] = useState<$TSFixMe>();

  // queries
  const [getAllBusinessTypes, { loading, data, error }] = useLazyQuery(
    allBusinessTypesQuery,
  );

  const [
    getEmptyPolicies,
    { loading: policiesLoading, data: policiesData, error: policiesError },
  ] = useLazyQuery(GET_MISSING_POLICIES, { fetchPolicy: 'no-cache' });

  // effects
  useEffect(() => {
    getAllBusinessTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (business) {
      getEmptyPolicies({
        variables: {
          businessId: business.id,
          token,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  useEffect(() => {
    if (data && data.allBusinessTypes) {
      setBusinessOptions(
        data.allBusinessTypes.map((businessType: $TSFixMe) => ({
          value: businessType.id,
          label: businessType.displayName,
          businessType,
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (policiesData) {
      const newGeneralInformation = !policiesData.doesGeneralInfoExist
        ? [{ value: '0', label: 'General Information' }]
        : [];
      const newPolicyOptions =
        // @ts-expect-error
        policiesData.allEmptyBusinessSpecificPolicies.map(
          (policy: $TSFixMe) => ({
            value: policy.id,
            label: policy.displayName,
            policy,
          }),
        );

      setPolicyOptions([...newGeneralInformation, ...newPolicyOptions]);
    }
  }, [policiesData]);

  useEffect(() => {
    if (policyId) {
      if (policyId !== '0') {
        const newPolicy = policyOptions.find(
          (policy) => policy.value === policyId,
        );
        setPolicy(newPolicy.policy);
      } else {
        setPolicy(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  // handlers
  const handleChange = (event: $TSFixMe) => {
    setPolicyId(event.target.value);
  };

  // status
  if (loading) {
    return <LoadingScreen />;
  }
  if (error || policiesError) {
    const anyError = error || policiesError;
    console.error('Error:', anyError);
    // @ts-expect-error
    Bugsnag.notify(anyError);
    return <ErrorPage />;
  }
  if (hasFormEditPermissions === false) {
    return (
      <InsufficientPermissionsErrorPage deniedIdentifier='Form Creation page' />
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Autocomplete
          options={businessOptions}
          placeholder='Start typing your line of business'
          isDisabled={!businessOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Select a business'
              variant='outlined'
            />
          )}
          sx={{
            '& input': {
              fontSize: '0.9rem',
            },
          }}
          ListboxProps={{
            // @ts-expect-error
            sx: {
              '& .MuiAutocomplete-option': {
                fontSize: '0.9rem',
                py: 2,
              },
            },
          }}
          onChange={(_event, value) => {
            setPolicyId(null);
            setBusiness(value ? value.businessType : null);
          }}
        />

        {!policiesLoading ? (
          business && (
            <FormControl>
              <FormLabel>Select a policy</FormLabel>
              <RadioGroup value={policyId} onChange={handleChange}>
                <Grid container spacing={0} direction='row'>
                  {policyOptions.map((policy) => (
                    <Grid item xs={12} sm={4} md={3}>
                      <FormControlLabel
                        value={policy.value}
                        control={<Radio />}
                        label={policy.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          )
        ) : (
          <>
            <Grid container spacing={1} direction='row'>
              {Array(16)
                .fill(0)
                .map(() => (
                  <Grid item xs={12} sm={4} md={3}>
                    <Skeleton width='90%' />
                  </Grid>
                ))}
            </Grid>
          </>
        )}

        <Stack
          direction={{ xs: 'column', sm: 'row-reverse' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <Button
            variant='contained'
            disabled={!business || !policyId}
            component={RouterLink}
            endIcon={<Icon icon={fileAddFill} />}
            to={
              business
                ? `/insurtech/manager/forms/edit/${business.id}/${
                    policy ? policy.id : '0'
                  }`
                : ``
            }
            onClick={() => {
              dispatch(setBusinessType({ selectedBusinessType: business }));
              if (policy) {
                dispatch(addPolicy({ businessLine: business, policy: policy }));
              } else {
                dispatch(
                  addGeneralForm({
                    businessLine: business,
                  }),
                );
              }
            }}
          >
            New Form
          </Button>

          {!business || !policyId ? (
            <Button
              disabled
              variant='contained'
              endIcon={<Icon icon={copyFill} />}
            >
              Copy Form
            </Button>
          ) : (
            <CopyExistingFormButton
              currentlyEditingForm={{
                id: v4(),
                businessLine: business,
                policy: policy,
                new: true,
              }}
              disabled={!business || !policyId}
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
