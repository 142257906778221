import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { FORM_MODIFIER_RELEVANT_FORMS } from '../../../../../../../queries';
import { ALL_SIGNIFIER } from '../../classes/FinalFormModifier';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { ErrorView } from '../../../../../../common/ErrorView';

// types and classes
import { FinalFormModifier } from '../../classes';
import type { StoreState } from '../../../../../../../store';
import { Query } from '../../../../../../../gql/graphql';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface DisplayQuestionsToDeleteProps {
  form: FinalFormModifier;
}
export const DisplayQuestionsToDelete = ({
  form,
}: DisplayQuestionsToDeleteProps) => {
  const [getRelevantForms, { loading, error, data }] = useLazyQuery(
    FORM_MODIFIER_RELEVANT_FORMS,
  );
  useEffect(() => {
    getRelevantForms({
      variables: {
        businessId:
          form.business === ALL_SIGNIFIER ? undefined : form.business.id,
        policyId: form.policy ? form.policy.id : undefined,
      },
    });
  }, [form.business, form.policy]);
  //console.log({ loading, error, data });
  return (
    <div style={{ padding: '8px' }}>
      <Typography variant='subtitle1' textAlign='center' gutterBottom>
        Questions to Remove
      </Typography>
      {loading ? <LoadingScreen /> : null}
      {error ? <ErrorView /> : null}
      {data?.formModifierRelevantFinalForms ? (
        // @ts-expect-error
        <DisplayRelevantForms formData={data.formModifierRelevantFinalForms} />
      ) : null}
    </div>
  );
};

const getApiNamesForRemoval = (state: StoreState) =>
  state.formModifierStore.currentlyEditingFinalFormModifier
    ?.apiNamesForRemoval || [];

interface DisplayRelevantFormsProps {
  formData: Query['formModifierRelevantFinalForms'];
}
const DisplayRelevantForms = ({ formData }: DisplayRelevantFormsProps) => {
  const apiNamesForRemoval = useSelector(getApiNamesForRemoval);
  const [modifiedForms, setModifiedForms] = useState<
    NonNullable<typeof formData>
  >([]);

  useEffect(() => {
    setModifiedForms(
      (formData || []).reduce((acc: Array<$TSFixMe>, form) => {
        if (
          form?.questionInstances
            .map((q) => q.apiName)
            .some((apiName) =>
              apiNamesForRemoval.includes(
                // @ts-expect-error
                apiName,
              ),
            )
        ) {
          return [
            ...acc,
            {
              ...form,
              questionInstances: form.questionInstances.filter((q) =>
                apiNamesForRemoval.includes(
                  // @ts-expect-error
                  q.apiName,
                ),
              ),
            },
          ];
        }
        return acc;
      }, []),
    );
  }, [formData, apiNamesForRemoval]);

  if (!formData) {
    return null;
  }
  if (modifiedForms.length === 0) {
    return (
      <>
        <Typography variant='subtitle1'>No Deleted Questions</Typography>
        <Typography variant='body1'>
          There are no questions that would be removed by this Form Modifier.
        </Typography>
      </>
    );
  }
  return (
    <>
      {modifiedForms.map((form, index, allForms) => (
        <>
          <section
            key={
              // @ts-expect-error
              form.id
            }
          >
            <Typography variant='subtitle2'>{`${
              // @ts-expect-error
              form.businessLine?.displayName
            } - ${
              // @ts-expect-error
              formData.policy
                ? // @ts-expect-error
                  formData.policy.displayName
                : 'General Information'
            }`}</Typography>

            <List>
              {
                // @ts-expect-error
                form.questionInstances
                  .filter((q) =>
                    apiNamesForRemoval.includes(
                      // @ts-expect-error
                      q.apiName,
                    ),
                  )
                  .map((question) => (
                    <ListItem key={question.id}>
                      {question.labels[0].value}
                    </ListItem>
                  ))
              }
            </List>
          </section>
          {index != allForms.length - 1 ? <Divider /> : null}
        </>
      ))}
    </>
  );
};
