import { useSettings } from './useSettings/useSettings';
import { WebsiteSettings } from './useSettings/WebsiteSettings';

export const generateUseUseSettings = (
  settings: WebsiteSettings | Array<WebsiteSettings>,
) => {
  const useUseSettings = () => {
    // for development only!
    const currentSettings = useSettings();
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    const relevantSettings = Array.isArray(settings) ? settings : [settings];
    return relevantSettings.some(
      (potentialSettings) => potentialSettings === currentSettings,
    );
  };
  return useUseSettings;
};
