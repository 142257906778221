import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Assembler } from './Assembler';
import { QUOTE_FROM_HASH } from '../../../queries';

import { Quote } from '../../../Typescript/backend/classes';
import { RouteComponentProps } from '@reach/router';

interface QuotePdfProps extends RouteComponentProps {
  hash?: string;
}
/**
 * Quote generator which structures the quote data received from the server
 * @param hash - hash that will be used to get the quote from the backend.
 * this hash is passed in as a url param.
 * @returns Assembler containing the requested components.
 */
export const QuotePdf = ({ hash }: QuotePdfProps) => {
  const [quote, setQuote] = useState<Quote | null>(null);
  const [getQuoteFromHash, { error, data }] = useLazyQuery(QUOTE_FROM_HASH);

  useEffect(() => {
    if (hash) {
      getQuoteFromHash({
        variables: {
          hash,
          delete: true,
        },
      });
    }
  }, [hash, getQuoteFromHash]);

  useEffect(() => {
    if (data) {
      setQuote(Quote.generateFromBackendResponse(data.quoteFromHash));
    }
  }, [data]);

  if (!quote) {
    return null;
  }

  if (error) {
    return null;
  }

  return <Assembler quote={quote} />;
};
