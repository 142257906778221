// TODO Update and submit forms -> validate the form state
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as createId } from 'uuid';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { WithId, BusinessType, Policy } from '../Typescript';
import {
  ProgramBuilderQuestionInstance,
  ProgramBuilderForm,
} from '../components/FormManager/classes';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../util';
import { recursiveSearchAndCopy } from './utility';

/* Utility Functions */
// TODO Check - does this do anything useful? I've basically just invalidated a lot of what it does. Does any component actually use the `name` attribute here?
function _createIdNameFragment(entity: $TSFixMe) {
  const { id, name } = entity;
  return { id, name };
}

function _findMatchingPolicyForm(
  forms: Array<ProgramBuilderForm>,
  businessLine: BusinessType,
  policy: Policy,
) {
  return forms.find((form) => {
    if (!form.policy) {
      return false;
    }

    if (form.businessLine.id !== businessLine.id) {
      return false;
    }
    return form.policy.id === policy.id;
  });
}

function _findMatchingGeneralForm(
  forms: Array<ProgramBuilderForm>,
  businessLine: WithId,
) {
  return forms.find((form) => {
    if (!form.businessLine) {
      return false;
    }

    if (form.businessLine.id !== businessLine.id) {
      return false;
    }

    return form.policy === null;
  });
}

function _findMatchingQuestionInstance(
  questionInstances: Array<ProgramBuilderQuestionInstance>,
  questionInstanceId: ProgramBuilderQuestionInstance['id'],
) {
  return questionInstances.findIndex(
    (existingQuestionInstance) =>
      existingQuestionInstance.clientId === questionInstanceId,
  );
}

/* The Store Proper */

/* The Store
 *
 * Types
 * =====
 * BusinessLine: {
 *   	id: str (convertible to int),
 *    	displayName: str,
 *     	industry: Industry
 *
 * Form: {
 *  	id: str (convertible to int)
 *     	clientId?: uuidv4 - sometimes gets this?
 *     	new: bool,
 *     	name: str (corresponds to BusinessLine displayName),
 *     	businessLine: { id: str (convertible to int) - corresponds to BusinessLine id  },
 *     	removed: bool,
 *     	businessType: BusinessLine,
 *     	questionInstances: [QuestionInstance]
 *     	policy: null | Policy
 * }
 *
 * Industry: {
 * 		id: str (convertible to int),
 *     	displayName: str,
 * }
 *
 * Policy: {
 *    	id: int (convertible to string)
 *     	displayName: str,
 *    	requested?: ????
 * }
 *
 * QuestionInstance: {
 *    	id: str (convertible to int),
 *     	name: str,
 * 		label: str,
 *     	helpText: str,
 *     	component: str,
 *     	dataType: 'default' | 'nestable',
 *     	required: bool,
 * 		propsBlob: str,
 *     	subQuestions: [QuestionInstance],
 *     	title?: str, -- related to ReactSortableTree ?
 *     	children?: [???] -- related to ReactSortableTree ?
 *     	expanded?: bool, -- related to ReactSortableTree ?
 *     	clientId: uuidv4,
 * }
 *
 * The Store Itself
 * ================
 *
 * @param {[Form]} draft_forms - a list of draft forms
 * @param {[Form]} forms - the forms?
 * @param {[QuestionInstance]} questionInstances - a list of question instances - the ones that belong to the form or to ones that we're dealing with
 * @param {[QuestionInstance]} questionsDatabase - another list of qustion instances? - All of the question instances
 */

interface IFormStoreState {
  draft_forms: Array<ProgramBuilderForm>;
  forms: Array<ProgramBuilderForm>;
  questionInstances: Array<ProgramBuilderQuestionInstance>;
  questionsDatabase: Array<ProgramBuilderQuestionInstance>;
}
export const defaultInitialState: IFormStoreState = {
  draft_forms: [],
  forms: [],
  questionInstances: [],
  questionsDatabase: [],
};

const slice = createSlice({
  name: 'form',
  initialState: defaultInitialState,
  reducers: {
    /**
     * Mark a given form as having been saved
     * @param  state - State
     * @param  action - action. Should have payload {form: Form}, the form to mark as saved
     */
    markFormAsSaved: (
      state,
      action: PayloadAction<{ form: ProgramBuilderForm }>,
    ) => {
      //console.log('FormStore: in markFormAsSaved');
      const { form } = action.payload;

      const existingFormIndex = state.forms.findIndex((stateForm) =>
        stateForm.matchById(form),
      );

      if (existingFormIndex < 0) {
        console.error('Unable to find form in', state.forms, 'matching', form);
        return;
      } else {
        //console.log(
        // 'Found matching form for',
        // form,
        // state.forms[existingFormIndex],
        // );
      }

      state.forms[existingFormIndex] = new ProgramBuilderForm({
        ...form,
        new: false,
      });
    },
    setEditAll: (
      state,
      action: PayloadAction<{
        form: ProgramBuilderForm;
        question: ProgramBuilderQuestionInstance;
        editAllFlag: boolean;
      }>,
    ) => {
      // debugger;
      const { form, question, editAllFlag } = action.payload;
      const the_form = state.forms.find(
        (stateForm) =>
          (form.clientId && stateForm.clientId === form.clientId) ||
          (form.id && stateForm.id === form.id),
      );
      if (!the_form) {
        return;
      }
      let result = the_form.questionInstances.findIndex(
        (ele) => ele.id === question.id,
      );
      if (result >= 0) {
        const relevantQuestion = the_form.questionInstances[result];
        the_form.questionInstances[result] = new ProgramBuilderQuestionInstance(
          {
            ...relevantQuestion,
            options: {
              ...relevantQuestion.options,
              editAll: editAllFlag,
            },
          },
        );
      }
    },
    /**
     * Remove all questions from a given form. Note that identification is done by clientId and id
     * @param state - state
     * @param action - action. Payload should be {form: Form}, the form to remove all questions from
     */
    clearQuestionsFromForm: (
      state,
      action: PayloadAction<{ form: ProgramBuilderForm }>,
    ) => {
      const { form } = action.payload;
      state.forms.forEach((stateForm: ProgramBuilderForm) => {
        if (form.matchById(stateForm)) {
          stateForm.questionInstances = [];
        }
      });
    },

    // TODO pass in the parentForm as a parameter to the action ( as seems to be the case with most of these ) rather than as an attribute of the questionInstance
    /**
     * Creates a new QuestionInstance and appends it to a form's list of question instances
     * @param  state - state
     * @param  action - Should have payload {questionInstance: QuestionInstance but with a form: Form attribute representing the form to which the question is to be appended}
     */
    createQuestionInstance: (
      state,
      action: PayloadAction<{
        questionInstance: ProgramBuilderQuestionInstance;
      }>,
    ) => {
      //console.log(
      //   `Entering create question instance with the following forms: `,
      // );
      // state.forms.forEach((form) => {
      //  //console.log({ ...form });
      // });

      let { questionInstance } = action.payload;
      //console.log(
      //   'just entered createQuestionInstance with questionInstance',
      //   questionInstance,
      // );
      let { form } = questionInstance.ancillary;

      // let newQuestionInstances = form.questionInstances
      //   ? form.questionInstances.slice()
      //   : [];
      // newQuestionInstances.push(questionInstance);

      let forms = state.forms;

      // debugger;

      if (!form) {
        throw new Error(
          `Error when calling createQuestionInstance with ${JSON.stringify(
            questionInstance,
            null,
            4,
          )} - no form found in ancillary`,
        );
      } else {
        // TODO this can be cleaned up - first find the form, then replace it
        for (let i = 0; i < forms.length; i++) {
          if (
            (form.clientId && forms[i].clientId === form.clientId) ||
            (form.id && forms[i].id === form.id)
          ) {
            let newForm = new ProgramBuilderForm({ ...forms[i] });
            newForm.questionInstances.push(questionInstance);
            //console.log(
            //   'Replacing these questions ',
            //   [...forms[i].questionInstances],
            //   ' with these questions',
            //   [...newForm.questionInstances],
            // );
            // debugger;
            forms[i] = newForm;
            //console.log(
            //   `Exiting create question instance with the following forms: `,
            // );
            // state.forms.forEach((form) => {
            //console.log({ ...form });
            // });
            return;
          }
        }
      }

      // state.questionInstances.push({
      //   ...questionInstance,
      //   form: {
      //     clientId: questionInstance.form.clientId,
      //     name: questionInstance.form.name,
      //   },
      // });

      //console.log('No matching form found for ' + JSON.stringify(form));
    },
    // TODO The recursive search / replace function should be yanked out into its own utility function ???
    /**
     * Updates a question in a form by replacing it with the one in the payload. Matching is done by id / clientId
     * @param state - state
     * @param action - action. Payload should be {parentForm: Form, node: ???, path: ???, questionInstance: QuestionInstance - this is the new one, topLevelQuestion: questionInstance
     *
     */
    updateQuestionInstance: (
      state,
      action: PayloadAction<{
        parentForm: ProgramBuilderForm;
        node: $TSFixMe;
        path: $TSFixMe;
        questionInstance: ProgramBuilderQuestionInstance;
        topLevelQuestion: ProgramBuilderQuestionInstance;
      }>,
    ) => {
      const { parentForm, questionInstance, topLevelQuestion } = action.payload;
      // debugger;
      // let match = false;

      //console.log('In updateQuestionInstance with', {
      //   ...action.payload,
      // });

      const matchingIndex = state.forms.findIndex((stateForm) => {
        //console.log('in formStore - stateForm', stateForm);
        return stateForm.matchById(parentForm);
      });
      if (matchingIndex === undefined) {
        throw new Error(
          `In updateQuestionInstance reducer - unable to find matching form for ${JSON.stringify(
            parentForm,
            null,
            4,
          )}`,
        );
      }

      const replaceQuestionsInList = (
        questionsList: Array<ProgramBuilderQuestionInstance>,
        topLevelQuestion: ProgramBuilderQuestionInstance | null,
        questionInstance: ProgramBuilderQuestionInstance | null,
      ): Array<ProgramBuilderQuestionInstance> => {
        return questionsList.map((originalQuestion) => {
          let mappedQuestion: ProgramBuilderQuestionInstance;
          if (
            questionInstance &&
            questionInstance.matchById(originalQuestion)
          ) {
            mappedQuestion = questionInstance;
          } else if (
            topLevelQuestion &&
            topLevelQuestion.matchById(originalQuestion)
          ) {
            mappedQuestion = originalQuestion.copy();
          } else {
            mappedQuestion = originalQuestion.copy();
          }
          mappedQuestion.subQuestions = replaceQuestionsInList(
            mappedQuestion.subQuestions,
            topLevelQuestion,
            questionInstance,
          );
          return mappedQuestion;
        });
      };

      const newForm = state.forms[matchingIndex].copy();
      newForm.questionInstances = replaceQuestionsInList(
        newForm.questionInstances,
        topLevelQuestion,
        questionInstance,
      );

      state.forms[matchingIndex] = newForm;

      // TODO this should really be structured as 1) find the matching form, 2) replace the question in that form
      // for (let i = 0; i < state.forms.length; i++) {
      //   if (
      //     parentForm.matchById(state.forms[i])
      //     // (parentForm.clientId &&
      //     //   parentForm.clientId === state.forms[i].clientId) ||
      //     // (parentForm.id && parentForm.id === state.forms[i].id)
      //   ) {
      //     // eslint-disable-next-line
      //     const recursiveSearch = (
      //       list: Array<ProgramBuilderQuestionInstance>,
      //     ) => {
      //       for (let j = 0; j < list.length; j++) {
      //         let ele = list[j];
      //         if (
      //           topLevelQuestion &&
      //           (ele.id === topLevelQuestion.id ||
      //             (ele.clientId && topLevelQuestion))
      //         ) {
      //           list[j] = ele.copy();
      //         }
      //         if (questionInstance.matchById(ele)) {
      //           match = true;
      //           // debugger;
      //           list[j] = questionInstance;
      //           return;
      //         }
      //         if (ele.subQuestions) {
      //           recursiveSearch(ele.subQuestions);
      //         }
      //       }
      //     };
      //     recursiveSearch(state.forms[i].questionInstances);
      //     state.forms[i] = state.forms[i].copy();
      //     continue;
      //   }
      // }
      // if (!match) {
      //console.log('No matching question found');
      // }

      // const existingIndex = _findMatchingQuestionInstance(
      //   state.questionInstances,
      //   questionInstance.clientId,
      // );

      // if (existingIndex < 0) {
      //   return;
      // }

      // state.questionInstances[existingIndex] = questionInstance;
    },
    /**
     * Creates a copy of the passed in QuestionInstance and inserts it into the form's list of question instances after the passed in QuestionInstance
     * @param  state - state
     * @param  action - Should have payload {questionInstance: QuestionInstance, parentForm: Form}
     */
    copyQuestionInstance: (
      state,
      action: PayloadAction<{
        questionInstance: ProgramBuilderQuestionInstance;
        parentForm: ProgramBuilderForm;
      }>,
    ) => {
      let { questionInstance, parentForm } = action.payload;
      let forms = state.forms;

      if (!parentForm) {
        throw new Error(
          `Error when calling copyQuestionInstance in FormStore with ${JSON.stringify(
            questionInstance,
            null,
            4,
          )} - parent form does not exist`,
        );
      } else {
        const foundFormIndex = forms.findIndex(
          (formInForms) =>
            (parentForm.clientId &&
              formInForms.clientId === parentForm.clientId) ||
            (parentForm.id && formInForms.id === parentForm.id),
        );
        if (foundFormIndex != -1) {
          const newForm = new ProgramBuilderForm({ ...forms[foundFormIndex] });
          recursiveSearchAndCopy(newForm.questionInstances, questionInstance);
          forms[foundFormIndex] = newForm;
          return;
        } else {
          //console.log(
          //   'No matching form found for ' + JSON.stringify(parentForm),
          // );
          throw new Error(
            `Error when calling copyQuestionInstance with ${JSON.stringify(
              questionInstance,
              null,
              4,
            )} - no matching form found`,
          );
        }
      }
    },
    // TODO there are two ways to specify the form: as a paremeter in the action or as an attribute of the questionInstance. We should standardize on one of these ways
    // TODO yank out the recursiveSearch function and move it to with the rest of the utility function
    // TODO What should happen if it can't find the form? Do nothing + log or throw error? In the addQualifyingQuestion reducer, it just returns -> does nothing...
    /**
     * Remove a question instance from a form
     * @param state - state
     * @param action - action. Payload should have { questionInstance: QuestionInstance + optionally {form: Form} - the one to remove, parentForm: Form }
     */
    removeQuestionInstance: (
      state,
      action: PayloadAction<{
        questionInstance: ProgramBuilderQuestionInstance;
        parentForm: ProgramBuilderForm;
      }>,
    ) => {
      // debugger;
      //console.log(`in removeQuestionInstance`);
      let { questionInstance, parentForm } = action.payload;
      if (!parentForm) {
        parentForm = questionInstance.ancillary.form;
      }

      let form_index;

      for (let i = 0; i < state.forms.length; i++) {
        if (
          (parentForm.clientId &&
            parentForm.clientId === state.forms[i].clientId) ||
          (parentForm.id && state.forms[i].id === parentForm.id)
        ) {
          form_index = i;
          break;
        }
      }
      if (!form_index) {
        throw new Error(
          'Unable to find matching form in removeQuestionInstance',
        );
      }

      // what happens here if form_index is undefined? -> couldn't find the matching form?

      const recursiveSearch = (list: Array<ProgramBuilderQuestionInstance>) => {
        if (!list || list.length === 0) return;
        for (let j = 0; j < list.length; j++) {
          let ele = list[j];
          if (
            (questionInstance.clientId &&
              ele.clientId === questionInstance.clientId) ||
            (questionInstance.id && ele.id === questionInstance.id)
          ) {
            // debugger;
            //console.log(`Deleting question. Found match at index ${j}:`, ele);
            list.splice(j, 1);
            return;
          }
          recursiveSearch(ele.subQuestions);
        }
        //console.log(`No match found for `);
        //console.log(questionInstance);
      };

      recursiveSearch(state.forms[form_index].questionInstances);
    },
    /**
     * Attempts to create a new general info form with the given business line. If one already exists, it ensures that it is marked as active
     * @param state - state
     * @param action - action. Should have payload { businessLine: BusinessLine}
     */
    addGeneralForm: (
      state,
      action: PayloadAction<{ businessLine: WithId }>,
    ) => {
      //console.log('Entering addGeneralForm');
      const { forms } = state;
      const { businessLine } = action.payload;

      if (!businessLine) {
        return;
      }

      const matchingForm = _findMatchingGeneralForm(forms, businessLine);

      if (matchingForm) {
        matchingForm.removed = false;
        return;
      }

      const newForm = new ProgramBuilderForm({
        clientId: createId(),
        new: true,
        name: 'General Information',
        policy: null,
        removed: false,
        // @ts-expect-error
        businessType: businessLine,
        businessLine: {
          ...businessLine,
          ..._createIdNameFragment(businessLine),
        },
        questionInstances: [],
        required: false,
      });
      forms.push(newForm);
    },
    /**
     * Attempts to create a new form with the given policy and business line. If one already exists, it ensures that it is marked as active
     * @param state - state
     * @param action - action. Should have payload {policy: Policy, businessLine: BusinessLine}
     */
    addPolicy: (
      state,
      action: PayloadAction<{ policy: Policy; businessLine: BusinessType }>,
    ) => {
      //console.log('Entering the addPolicy reducer');
      const { forms } = state;
      const { policy, businessLine } = action.payload;

      if (!policy || !businessLine) {
        return;
      }

      const matchingForm = _findMatchingPolicyForm(forms, businessLine, policy);

      if (matchingForm) {
        matchingForm.removed = false;
        return;
      }

      const newForm = new ProgramBuilderForm({
        clientId: createId(),
        new: true,
        name: policy.displayName,
        policy: { ...policy, ..._createIdNameFragment(policy) },
        removed: false,
        businessType: {
          ...businessLine,
        },
        businessLine: {
          ...businessLine,
          ..._createIdNameFragment(businessLine),
        },
        questionInstances: [],
        required: false,
      });
      forms.push(newForm);
    },

    /**
     * Adds a new form to the end of the list of forms
     * @param state - state
     * @param  action - action. Should have payload form
     */
    addForm: (state, action: PayloadAction<{ form: ProgramBuilderForm }>) => {
      const { forms } = state;
      const { form } = action.payload;

      if (!form) {
        return;
      }

      forms.push(form);
    },

    /**
     * Remove a form for a given policy and business line by setting the 'removed' attribute to true, if one exists
     * @param state - state
     * @param action - action. Payload should be {policy: Policy, businessLine: BusinessLine}.
     */
    removePolicy: (
      state,
      action: PayloadAction<{ policy: Policy; businessLine: BusinessType }>,
    ) => {
      const { forms } = state;
      const { policy, businessLine } = action.payload;

      if (!policy || !businessLine) {
        return;
      }

      const matchingForm = _findMatchingPolicyForm(forms, businessLine, policy);
      if (!matchingForm) {
        return state;
      }

      matchingForm.removed = true;
    },
    /**
     * Sets the business type by removing any forms whose business line is not the one specified. If there is no general information form for that business already defined, it creates one.
     * @param state - state
     * @param action - action. Payload should be {selectedBusinessType: BusinessLine}
     */
    setBusinessType: (
      state,
      action: PayloadAction<{ selectedBusinessType: BusinessType }>,
    ) => {
      const { forms } = state;
      const { selectedBusinessType } = action.payload;

      for (const existingBusinessForm of forms) {
        existingBusinessForm.removed = true;
      }

      // TODO, right now we allow the action to send an undefined business type to 'clear' the section. Perhaps we should explicitly send a removeBusinessType?
      if (!selectedBusinessType) {
        return;
      }

      // debugger;
      const existingBusinessFormsOfSameType = forms.filter(
        (form) => form.businessLine.id === selectedBusinessType.id,
      );

      const businessInfoCollectionForm = existingBusinessFormsOfSameType.find(
        (form) => !form.policy,
      );

      if (!businessInfoCollectionForm) {
        forms.push(
          new ProgramBuilderForm({
            clientId: createId(),
            new: true,
            name: selectedBusinessType.displayName,
            businessType: {
              ...selectedBusinessType,
            },
            businessLine: {
              ...selectedBusinessType,
              ..._createIdNameFragment(selectedBusinessType),
            },
            removed: false,
            questionInstances: [],
            required: true,
          }),
        );
      }

      for (const form of existingBusinessFormsOfSameType) {
        form.removed = false;
      }
    },
    setQualifyingQuestion: (
      state,
      action: PayloadAction<{
        qualifyingQuestion: ProgramBuilderQuestionInstance;
      }>,
    ) => {
      const { qualifyingQuestion } = action.payload;
      //console.log(
      //   'In addQualifyingQuestion - qualifyingQuestion',
      //   qualifyingQuestion,
      // );
      // WHY ARE WE STRIPPING OUT THE CLIENTID??
      // const { clientId, ...question } = qualifyingQuestion;
      const { form } = qualifyingQuestion.ancillary;

      const existingFormIndex = state.forms.findIndex(
        ({ clientId }) => clientId === form.clientId,
      );

      if (existingFormIndex < 0) {
        return;
      }

      state.forms[existingFormIndex] = new ProgramBuilderForm({
        ...form,
        qualifyingQuestion: qualifyingQuestion.copy(),
        // qualifyingQuestion: new ProgramBuilderQuestionInstance(question),
      });
      //console.log(
      //   'At end of addQualifyingQuestion: qualifying question',
      //   state.forms[existingFormIndex].qualifyingQuestion,
      // );
    },

    //// TODO Throw an error ( or something ) if the form already has a qualifying question
    ///**
    // * Add a qualifying question to a form. A qualifying question is used to determine whether a particular form should be displayed; e.g. 'Do you make lots of errors?' -> display the `Errors and Omissions` form
    // * @param state - state
    // * @param action - action. Should have payload {qualifyingQuestion: QuestionInstance + {form: Form - the form to which the qualifying question should be added }}
    // */
    //addQualifyingQuestion: (
    //  state,
    //  action: PayloadAction<{
    //    qualifyingQuestion: ProgramBuilderQuestionInstance;
    //  }>,
    //) => {
    //  const { qualifyingQuestion } = action.payload;
    //  //console.log(
    //  //   'In addQualifyingQuestion - qualifyingQuestion',
    //  //   qualifyingQuestion,
    //  // );
    //  // WHY ARE WE STRIPPING OUT THE CLIENTID??
    //  // const { clientId, ...question } = qualifyingQuestion;
    //  const { form } = qualifyingQuestion.ancillary;

    //  const existingFormIndex = state.forms.findIndex(
    //    ({ clientId }) => clientId === form.clientId,
    //  );

    //  if (existingFormIndex < 0) {
    //    return;
    //  }

    //  state.forms[existingFormIndex] = new ProgramBuilderForm({
    //    ...form,
    //    qualifyingQuestion: qualifyingQuestion.copy(),
    //    // qualifyingQuestion: new ProgramBuilderQuestionInstance(question),
    //  });
    //  //console.log(
    //  //   'At end of addQualifyingQuestion: qualifying question',
    //  //   state.forms[existingFormIndex].qualifyingQuestion,
    //  // );
    //},
    //// TODO Form matching should be done on the basis of id and clientId, not just the one. See earlier examples...
    //// TODO this does the same thing as above. Should they be merged into a `setQualifyingQuestion` reducer?
    ///**
    // * Update the qualifying question for a form that (presumably) already has one
    // * @param state - state
    // * @param action - action. Payload should be { qualifyingQuestion: QuestionInstance + { form: Form - the form whose qq is to be updated } }
    // */
    //updateQualifyingQuestion: (
    //  state,
    //  action: PayloadAction<{
    //    qualifyingQuestion: ProgramBuilderQuestionInstance;
    //  }>,
    //) => {
    //  const { qualifyingQuestion } = action.payload;
    //  // const { ...question } = qualifyingQuestion;
    //  const { form } = qualifyingQuestion.ancillary;

    //  const existingFormIndex = state.forms.findIndex(
    //    ({ clientId }) =>
    //      clientId && form.clientId && clientId === form.clientId,
    //  );

    //  if (existingFormIndex < 0) {
    //    return;
    //  }

    //  state.forms[existingFormIndex] = new ProgramBuilderForm({
    //    ...form,
    //    qualifyingQuestion: qualifyingQuestion.copy(),
    //  });
    //},

    /**
     * Create extensions for a form that doesn't have any
     * @param state - state
     * @param action - action. Payload should be { extensions: extensions + { form: Form - the form who extensions is to be added }}
     */
    createExtensions: (
      state,
      action: PayloadAction<{
        extensionList: $TSFixMe;
      }>,
    ) => {
      const { extensionList } = action.payload;
      const { form, clientId, ...extensions } = extensionList;

      let extensionsArr = Object.values(extensions);

      const existingFormIndex = state.forms.findIndex(
        ({ id, clientId }) =>
          (id && id === form.id) || (clientId && clientId === form.clientId),
      );

      if (existingFormIndex < 0) {
        return;
      }

      // @ts-expect-error
      state.forms[existingFormIndex].policyExtensions = extensionsArr;
      // state.forms[existingFormIndex] = {
      //   ...form,
      //   policyExtension: extensionsArr,
      // };
    },

    /**
     * Update extensions for a form that (presumable) already has one
     * @param state - state
     * @param action - action. payload should be { extensions: extensions + { form: Form - the form whose extensions is to be updated }}
     */
    updateExtensions: (
      state,
      action: PayloadAction<{
        extensionList: {
          form: ProgramBuilderQuestionInstance;
          [k: string]: $TSFixMe;
        };
      }>,
    ) => {
      const { extensionList } = action.payload;
      const { form, ...extensions } = extensionList;

      let extensionsArr = Object.keys(extensions).map((k) => extensions[k]);

      const existingFormIndex = state.forms.findIndex(
        ({ clientId }) =>
          clientId && form.clientId && clientId === form.clientId,
      );

      if (existingFormIndex < 0) {
        return;
      }

      // this is preventing changes that occur before this - need to update!
      state.forms[existingFormIndex].policyExtension = extensionsArr;
      // state.forms[existingFormIndex] = {
      //   ...form,
      //   policyExtension: extensionsArr,
      // };
    },

    // TODO Yank out `recursiveMap` and treat it as a regular utility function
    // TODO Refactor `recursiveMap` so that it returns an empty array on no subquestions
    // TODO comparison should be by both id and clientId
    /**
     * Updates a single form in the Store. It has something to do with sortable-tree; the children, &c. are part of that. Basically, it converts from the tree form (children) to the ... other form (subQuestions). This is the tree -> state coversion.
     * @param state - state
     * @param action - action. Payload should be {form: Form - the form to update in the store}
     */
    updateTreeStructure: (
      state,
      action: PayloadAction<{
        form: ProgramBuilderForm;
      }>,
    ) => {
      const { form } = action.payload;
      const { forms } = state;
      //console.log('in updateTreeStructure with form', form);

      if (!form) {
        throw new Error('No form provided');
      } else {
        for (let i = 0; i < forms.length; i++) {
          if (
            (form.id && forms[i].id && form.id === forms[i].id) ||
            (form.clientId &&
              forms[i].clientId &&
              form.clientId === forms[i].clientId)
            // form.matchById(forms[i])
          ) {
            //console.log('Found matching form to ', form, forms[i]);
            //console.log(
            //   'Replacing this form ',
            //   forms[i],
            //   ' with this form ',
            //   form,
            // );
            const recursiveMap = (
              questions: Array<ProgramBuilderQuestionInstance>,
              parent: null | ProgramBuilderQuestionInstance = null,
            ): Array<ProgramBuilderQuestionInstance> => {
              //console.log('in formStore recursiveMap. questions:', questions);
              return questions.map((ele) => {
                if (
                  ele.children &&
                  (ele.children.length !== 0 || ele.children.length === 0)
                ) {
                  return new ProgramBuilderQuestionInstance({
                    ...ele,
                    subQuestions: recursiveMap(ele.children, ele),
                  });
                } else {
                  // debugger;
                  //console.log(
                  //   'About to process',
                  //   ele,
                  //   'updateTreeStructure 858',
                  // );
                  const copy = ele.copy();
                  //console.log(
                  //   'in updateTreeStructure; about to return copy',
                  //   copy,
                  // );
                  return parent ? copy : copy;
                }
              });
            };
            let withSubQuestionsSet = recursiveMap(form.questionInstances);
            // debugger;
            state.forms[i] = new ProgramBuilderForm({
              ...form,
              questionInstances: withSubQuestionsSet,
            });
            // This splice absolutely breaks everything
            // I think what's happening is that the splice triggers a rerender on everything that touches
            // forms and the code above (state.forms[i] = Etcetera) only changes the array at some deeper level
            // and doesn't cause the entire program builder to re-render. Probably has to do with immer.
            // good question for Stephan.

            // forms.splice(i, 1, {
            //   ...form,
            //   questionInstances: withSubQuestionsSet,
            // });
            return;
          }
        }
      }
    },
    /**
     * Clears the state of all forms (basically: just clear them)
     * @param object state - state
     * @param action - action. Not relevant to this.
     */
    resetAnswers: (state) => {
      state.forms = [];
    },
    /**
     * Replaces the existing questions database with a new one
     * @param action - action
     * @param action - action. Payload should be { questionsDatabase: [ QuestionInstance ]}
     */
    updateQuestionsDatabase: (
      state,
      action: PayloadAction<{
        questionsDatabase: Array<ProgramBuilderQuestionInstance>;
      }>,
    ) => {
      const { questionsDatabase } = action.payload;
      state.questionsDatabase = questionsDatabase;
    },
    // TODO change it so that even if the one of the forms exists, it still throws all of its questions into the questionInstances attribute of state
    // TODO with the above, make it so that it first checks if a question instance exists and only then updates it
    /**
     * Loads a bunch of forms from somwhere (???), and check for their existence in the form store. If they're not there it adds them, and adds all of their questions to the questionInstances part of state. It also syncs the id and clientId of the forms (set clientId to id), adds the {form: parentForm} to each of the question instances, and set the `removed` and `new` attributes on the form
     * @param state - state
     * @param action - action. Payload should be { loadedForms: [ Form ] }.
     */
    loadExistingForms: (
      state,
      action: PayloadAction<{
        loadedForms: Array<ProgramBuilderForm>;
      }>,
    ) => {
      // debugger;
      //console.log(
      //   'entering loadExistingForms reducer with existing forms',
      //   action.payload.loadedForms,
      // );
      try {
        const { forms, questionInstances } = state;
        const { loadedForms } = action.payload;

        for (const loadedForm of loadedForms) {
          const existingFormOfSameType = forms.find((form) =>
            form.matchById(loadedForm),
          );

          // this should be changed so that it still adds all of the question instances
          if (existingFormOfSameType) {
            continue;
          }

          forms.push(
            new ProgramBuilderForm({
              ...loadedForm,
              clientId: loadedForm.id,
              questionInstances: loadedForm.questionInstances
                ? loadedForm.questionInstances.map(
                    (questionInstance) =>
                      new ProgramBuilderQuestionInstance({
                        ...questionInstance,
                        clientId: questionInstance.id,
                        ancillary: {
                          ...questionInstance.ancillary,
                          form: loadedForm.copy(),
                        },
                        // form: {
                        //   clientId: loadedForm.id,
                        //   name: loadedForm.name,
                        // },
                      }),
                  )
                : [],
              removed: true,
              // new: false,
            }),
          );

          // change this to first search for the question, and only then add it
          let questionInstanceReduxModels: Array<ProgramBuilderQuestionInstance> =
            [];
          if (loadedForm.questionInstances) {
            questionInstanceReduxModels = loadedForm.questionInstances.map(
              (questionInstance) =>
                new ProgramBuilderQuestionInstance({
                  ...questionInstance,
                  clientId: questionInstance.id,
                  ancillary: {
                    ...questionInstance.ancillary,
                    form: loadedForm,
                  },
                }),
            );
          }
          questionInstances.push(...questionInstanceReduxModels);
        }
      } catch (e) {
        Bugsnag.notify(errorify(e));
        console.error(`Error in loadExistingForms: `, e);
      }
    },

    addRepeatableQuestion: (
      state,
      action: PayloadAction<{
        questionInstance: ProgramBuilderQuestionInstance;
        isEditing: boolean;
        formState: {
          subQuestions: Array<ProgramBuilderQuestionInstance>;
        };
      }>,
    ) => {
      const { questionInstance, isEditing } = action.payload;
      const subQuestions = action.payload.formState.subQuestions;
      const { questionInstances } = state;
      // debugger;
      // for (let i = 0; i < questionInstances.length; i++) {
      //   if (questionInstances[i].name === questionInstance.name) {
      //     questionInstances.forEach((question, idx) => {
      //       if (children.find(ele => ele === question.name)) {
      //console.log(
      //           'Found! Moving ' +
      //             question.name +
      //             ' from ' +
      //             idx +
      //             ' to ' +
      //             i +
      //             question,
      //         );
      //         questionInstances.splice(idx, 1);
      //         questionInstances.splice(i + 1, 0, question);
      //       }
      //     });
      //   }
      // }

      // questionInstances.forEach((ele, idx) => {
      //   if (children.find(subQuestion => subQuestion === ele)) {
      //     questionInstances.splice(idx, 1);
      //   }
      // });
      // debugger;

      //The code below here works if the "subQuestions" are an array of questionInstance.name
      //and if SubQuestions are put into "children"

      // let child_indeces = [];
      // let child_instances = [];
      // questionInstances.forEach((ele, idx) => {
      //   if (children.find(child_ele => child_ele === ele.name)) {
      //     child_indeces.push(idx);
      //     child_instances.push(ele);
      //   }
      // });
      // child_indeces.sort((a, b) => b - a);
      // child_indeces.forEach(idx => questionInstances.splice(idx, 1)[0]);
      // let parent_idx = _findMatchingQuestionInstanceByName(
      //   questionInstances,
      //   questionInstance.name,
      // );
      // questionInstance.subQuestions = child_instances;
      state.questionInstances = questionInstances.filter((ele) =>
        subQuestions.find((sub_q) => sub_q === ele),
      );

      // debugger;
      // debugger;

      if (isEditing) {
        const existingIndex = _findMatchingQuestionInstance(
          state.questionInstances,
          questionInstance.clientId,
        );

        if (existingIndex < 0) {
          //console.log('Instance does not exist');
          return;
        }
        // questionInstance.subQuestions = child_instances;
        state.questionInstances[existingIndex] = questionInstance;
      } else {
        state.questionInstances.push(
          new ProgramBuilderQuestionInstance({
            ...questionInstance,
            ancillary: {
              ...questionInstance.ancillary,
              form: questionInstance.ancillary.form,
            },
          }),
        );
      }
    },

    /**
     * Toggle the `required` attribute on a form
     */
    toggleFormRequired: (
      state,
      action: PayloadAction<{
        form: ProgramBuilderForm;
      }>,
    ) => {
      const { form } = action.payload;
      const matchingIndex = state.forms.findIndex((stateForm) =>
        stateForm.matchById(form),
      );
      if (matchingIndex < 0) {
        throw new Error(
          `Unable to find form matching ${form.businessLine}, ${form.policy}, ${form.id} in formStore.`,
        );
      }
      // We do it this way to force the re-render - for some reason it wasn't happening when we just change the required field in the form
      const matchingForm = state.forms[matchingIndex];
      state.forms[matchingIndex] = matchingForm.copyWithAmendments({
        required: !matchingForm.required,
      });
      // matchingForm.required = !matchingForm.required;

      // const state_form = state.forms.find(
      //   (ele) =>
      //     ele &&
      //     ((ele.id !== undefined && ele.id === form.id) ||
      //       (ele.clientId !== undefined && ele.clientId === form.clientId)),
      // );
      // if (!state_form) {
      //   return;
      // }
      // state_form.required = !state_form.required;
    },
    /**
     * Updates an existing form - find one with the same business type and policy, overwrite any fields that need to be, set 'removed' to false and 'new' to true
     * @param state - state
     * @param action - action. Has payload {form: same format as in the forms attribute in state, but possibly missing some attributes (the ones that shouldn't be updated)}. The form in payload is the one to copy over an existing one
     */
    updateExistingForm: (
      state,
      action: PayloadAction<{
        form: ProgramBuilderForm;
      }>,
    ) => {
      const { form } = action.payload;
      const idToMatchBy = form.id ? 'id' : 'clientId';
      const { forms } = state;

      // TODO This can be cleaned up to use Array.find()
      let matchFound = false;
      for (let i = 0; i < forms.length; i++) {
        const potentialMatchingForm = forms[i];
        // check - same business type?
        if (
          potentialMatchingForm[idToMatchBy] &&
          form[idToMatchBy] === potentialMatchingForm[idToMatchBy]
        ) {
          matchFound = true;
          forms[i] = new ProgramBuilderForm({
            ...potentialMatchingForm,
            ...form,
            new: potentialMatchingForm.new, // because we want this to be the same as the original
          });
          break;
        }
      }

      if (!matchFound) {
        throw new Error(`No match found for form ${form}`);
      }
    },
    // editRepeatableQuestion: (state, action) => {},
  },
});

interface IStoreState {
  formStore: IFormStoreState;
}

/**
 * Returns the single extant general business information form in state
 * @param state - the entire application state
 * @return - the single general business information form (or undefined if none exists)
 */
export function getSelectedBusinessForm(state: IStoreState) {
  const { forms } = state.formStore;

  return forms
    .filter((form) => !form.removed)
    .filter((form) => !form.policy)
    .find((form) => !!form.businessLine);
}

/**
 * Return all of the current policy forms in state
 * @param state - the entire application state
 * @return - a list of all of the non-removed policy forms in state
 */
export function getSelectedPolicyForms(state: IStoreState) {
  const { forms } = state.formStore;

  return forms.filter((form) => !form.removed).filter((form) => !!form.policy);
}

/**
 * Get a flat list of all of the question instances in the general business information form
 * @param state - the entire application state
 * @return - a list of the questions in the general business information form
 */
export function getSelectedFormQuestionInstances(state: IStoreState) {
  const form = getSelectedBusinessForm(state);
  if (!form) {
    //console.log(
    //   'in getSelectedFormQuestionInstances: unable to find matching general information form',
    // );
    return;
  }
  // debugger;
  return getFormQuestionInstances(state, form);
}

/**
 * Gets a flat list of all of the top-level questions in a given form
 * @param state - the entire application state
 * @param form - The form for from which to grab the questions
 * @return - a list of all of the top-level question instances in the given form
 */
export function getFormQuestionInstances(
  state: IStoreState,
  form: ProgramBuilderForm,
) {
  // debugger;
  if (state === undefined || state === null) {
    throw new Error(
      `No 'state' parameter found in call to 'getFormQuestionInstances'`,
    );
  }
  if (form === undefined || form === null) {
    throw new Error(
      `No 'form' parameter found in call to 'getFormQuestionInstances'`,
    );
  }
  const { questionInstances } = state.formStore;

  return questionInstances.filter(
    (questionInstance) =>
      questionInstance.ancillary.form &&
      questionInstance.ancillary.form.clientId &&
      questionInstance.ancillary.form.clientId === form.clientId,
  );
}

export default slice;

/* Action Creators */
export const {
  createExtensions,
  updateExtensions,
  resetAnswers,
  copyQuestionInstance,
} = slice.actions;
