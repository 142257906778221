import { graphql } from '../gql';

export const WHOAMI_ORGANIZATION = graphql(`
  query whoamiOrganization($token: String!) {
    whoamiOrganization(token: $token) {
      name
      category
      id
      quoteEmails
      quoteFromEmail
    }
  }
`);
