import Bugsnag from '@bugsnag/js';
import Typography from '@mui/material/Typography';
import { errorify } from '../../../../../util';
import { titleCase, formatPostalCodeOrIgnore } from './classUtility';

export class Content {
  // @ts-expect-error
  constructor(ContentURLInput, MIMETypeInput, ExpiryInput) {
    // @ts-expect-error
    this.ContentURL = ContentURLInput;
    // @ts-expect-error
    this.MIMEType = MIMETypeInput;
    // @ts-expect-error
    this.Expiry = ExpiryInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new Content(response.ContentURL, response.MIMEType, response.Expiry);
  }
}

export class LegalDescription {
  // @ts-expect-error
  constructor(Quarter, LegalSubdivision, Section, Township, Range, Meridian) {
    // @ts-expect-error
    this.Quarter = Quarter;
    // @ts-expect-error
    this.LegalSubdivision = LegalSubdivision;
    // @ts-expect-error
    this.Section = Section;
    // @ts-expect-error
    this.Township = Township;
    // @ts-expect-error
    this.Range = Range;
    // @ts-expect-error
    this.Meridian = Meridian;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response || typeof response !== 'object') {
        return null;
      }
      return new LegalDescription(
        response.Quarter,
        response.LegalSubdivision,
        response.Section,
        response.Township,
        response.Range,
        response.Meridian,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        `Error creating new LegalDescription from response`,
        response,
        e,
      );
      return null;
    }
  }
  toString() {
    let toDisplay = [];
    // @ts-expect-error
    if (this.Quarter) {
      // @ts-expect-error
      toDisplay.push(`Quarter: ${titleCase(this.Quarter)}`);
    }
    // @ts-expect-error
    if (this.LegalSubdivision) {
      // @ts-expect-error
      toDisplay.push(`Legal Subdivision: ${titleCase(this.LegalSubdivision)}`);
    }
    // @ts-expect-error
    if (this.Section) {
      // @ts-expect-error
      toDisplay.push(`Section: ${titleCase(this.Section)}`);
    }
    // @ts-expect-error
    if (this.Township) {
      // @ts-expect-error
      toDisplay.push(`Township: ${titleCase(this.Township)}`);
    }
    // @ts-expect-error
    if (this.Range) {
      // @ts-expect-error
      toDisplay.push(`Range: ${titleCase(this.Range)}`);
    }
    // @ts-expect-error
    if (this.Meridian) {
      // @ts-expect-error
      toDisplay.push(`Meridian: ${titleCase(this.Meridian)}`);
    }
    return toDisplay.join('; ');
  }
}

export class OptaAddress {
  constructor(
    // @ts-expect-error
    StreetAddress,
    // @ts-expect-error
    StreetNumber,
    // @ts-expect-error
    StreetName,
    // @ts-expect-error
    StreetType,
    // @ts-expect-error
    StreetDirection,
    // @ts-expect-error
    UnitNumber,
    // @ts-expect-error
    LegalDescriptionInput,
    // @ts-expect-error
    Municipality,
    // @ts-expect-error
    Province,
    // @ts-expect-error
    PostalCode,
  ) {
    // @ts-expect-error
    this.StreetAddress = StreetAddress;
    // @ts-expect-error
    this.StreetNumber = StreetNumber;
    // @ts-expect-error
    this.StreetName = StreetName;
    // @ts-expect-error
    this.StreetType = StreetType;
    // @ts-expect-error
    this.StreetDirection = StreetDirection;
    // @ts-expect-error
    this.UnitNumber = UnitNumber;
    // @ts-expect-error
    this.LegalDescription = LegalDescription.createFromJson(
      LegalDescriptionInput,
    );
    // @ts-expect-error
    this.Municipality = Municipality;
    // @ts-expect-error
    this.Province = Province;
    // @ts-expect-error
    this.PostalCode = PostalCode;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response || typeof response !== 'object') {
        return null;
      }
      return new OptaAddress(
        response.StreetAddress,
        response.StreetNumber,
        response.StreetName,
        response.StreetType,
        response.StreetDirection,
        response.UnitNumber,
        response.LegalDescription,
        response.Municipality,
        response.Province,
        response.PostalCode,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error generating OptaAddress from response`, response, e);
      return null;
    }
  }
  toString() {
    // @ts-expect-error
    if (this.StreetAddress) {
      return [
        // @ts-expect-error
        this.StreetAddress,
        // @ts-expect-error
        this.Municipality,
        // @ts-expect-error
        this.Province,
        // @ts-expect-error
        formatPostalCodeOrIgnore(this.PostalCode),
      ]
        .filter((elem) => !!elem)
        .join(', ');
    }
    // @ts-expect-error
    if (this.LegalDescription) {
      return [
        // @ts-expect-error
        this.LegalDescription.toString(),
        // @ts-expect-error
        titleCase(this.Municipality),
        // @ts-expect-error
        this.Province,
        // @ts-expect-error
        this.PostalCode,
      ]
        .filter((elem) => !!elem)
        .join(', ');
    }
    let streetString = '';
    // @ts-expect-error
    if (this.UnitNumber) {
      // @ts-expect-error
      streetString += `#${this.UnitNumber} `;
    }
    // @ts-expect-error
    if (this.StreetNumber) {
      // @ts-expect-error
      streetString += this.StreetNumber + ' ';
    }
    // @ts-expect-error
    if (this.StreetName) {
      // @ts-expect-error
      streetString += titleCase(this.StreetName) + ' ';
    }
    // @ts-expect-error
    if (this.StreetType) {
      // @ts-expect-error
      streetString += titleCase(this.StreetType) + ' ';
    }
    // @ts-expect-error
    if (this.StreetDirection) {
      // @ts-expect-error
      streetString += this.StreetDirection + ' ';
    }

    return [
      streetString.trim(),
      // @ts-expect-error
      titleCase(this.Municipality),
      // @ts-expect-error
      this.Province,
      // @ts-expect-error
      formatPostalCodeOrIgnore(this.PostalCode),
    ]
      .filter((elem) => !!elem)
      .join(', ');
  }
  displayAsJsx() {
    return (
      <Typography variant='h3' style={{ textAlign: 'center' }} gutterBottom>
        {this.toString()}
      </Typography>
    );
  }
}
