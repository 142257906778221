import { graphql } from '../gql';

export const UPDATE_ORGANIZATION = graphql(`
  mutation updateOrganization(
    $id: ID!
    $token: String!
    $quoteEmails: [String]!
    $quoteFromEmail: String
  ) {
    updateOrganization(
      id: $id
      token: $token
      quoteEmails: $quoteEmails
      quoteFromEmail: $quoteFromEmail
    ) {
      organization {
        name
        id
        quoteFromEmail
        quoteEmails
      }
    }
  }
`);
