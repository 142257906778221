import { useFormApi } from 'informed';
import { TextField } from '@calefy-inc/informedMaterial';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { mergeExistingKeywordsWithNew } from './utility';

// types and classes
import type { Dispatch, SetStateAction } from 'react';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface BusinessTypeKeywordsProps {
  keywords: Array<string>;
  setKeywords: Dispatch<SetStateAction<Array<string>>>;
}
export const BusinessTypeKeywords = ({
  keywords,
  setKeywords,
}: BusinessTypeKeywordsProps) => {
  const formApi = useFormApi();
  return (
    <>
      <TextField
        field='keywords'
        label='Keywords (comma-separated list of additional keywords)'
        placeholder='Please enter a comma-separated list of additional keywords for this business type'
        fullWidth
        variant='outlined'
        onBlur={(e: $TSFixMe) => {
          formApi.setValue('keywords', '');
          setKeywords((oldKeywords) =>
            mergeExistingKeywordsWithNew(oldKeywords, e.target.value),
          );
        }}
      />
      {keywords.length > 0 ? (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          {keywords.map((keyword) => (
            <Chip
              key={keyword}
              label={keyword}
              variant='outlined'
              onDelete={() =>
                setKeywords((oldKeywords) =>
                  oldKeywords.filter((oldKeyword) => oldKeyword !== keyword),
                )
              }
            />
          ))}
        </Stack>
      ) : null}
    </>
  );
};
