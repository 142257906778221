import { useState } from 'react';
import PropTypes from 'prop-types';
import { copyQuestionInstance } from '../../../../../store/FormStore';
import { useDispatch } from 'react-redux';

import DragIcon from './DragIcon';

import useStyles from './node-content-renderer-style';

import { IconButton, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FormModal } from '../../../components/FormModal';
import QuestionInstanceForm from '../../../components/CreateQuestionInstance/QuestionInstanceForm';
import { removeNodeAtPath } from 'react-sortable-tree';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Tooltip from '@mui/material/Tooltip';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderQuestionInstance } from '../../../classes';

import componentRegistry from '../../../../common/QuoteComponents';
// import { useCanEditQuestion } from '../../../../../hooks';

function isDescendant(older: $TSFixMe, younger: $TSFixMe) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      (child: $TSFixMe) => child === younger || isDescendant(child, younger),
    )
  );
}

interface FileThemeNodeContentRendererProps {
  scaffoldBlockPxWidth: $TSFixMe;
  toggleChildrenVisibility: $TSFixMe;
  connectDragPreview: $TSFixMe;
  connectDragSource: $TSFixMe;
  isDragging: $TSFixMe;
  canDrop: $TSFixMe;
  canDrag: $TSFixMe;
  node: ProgramBuilderQuestionInstance;
  title: $TSFixMe;
  draggedNode: $TSFixMe;
  path: $TSFixMe;
  treeIndex: $TSFixMe;
  isSearchMatch: $TSFixMe;
  isSearchFocus: $TSFixMe;
  icons: $TSFixMe;
  buttons: $TSFixMe;
  className: $TSFixMe;
  style: $TSFixMe;
  didDrop: $TSFixMe;
  lowerSiblingCounts: $TSFixMe;
  listIndex: $TSFixMe;
  swapFrom: $TSFixMe;
  swapLength: $TSFixMe;
  swapDepth: $TSFixMe;
  setEditAllDialogueOpen: $TSFixMe;
  setPendingTreeData: $TSFixMe;
  setTopLevelQuestion: $TSFixMe;
  treeId?: $TSFixMe;
  isOver?: $TSFixMe;
  parentNode?: $TSFixMe;
  rowDirection?: $TSFixMe;
  canEditQuestion: boolean;
  hasGlobalQuestionEditPermissions: boolean;
}
function FileThemeNodeContentRenderer(
  props: FileThemeNodeContentRendererProps,
) {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node: actualNode,
    /* title, */
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    // icons,
    buttons,
    className,
    style,
    didDrop,
    lowerSiblingCounts,
    listIndex,
    swapFrom,
    swapLength,
    swapDepth,
    setEditAllDialogueOpen,
    setPendingTreeData,
    setTopLevelQuestion,
    // treeId // Not needed, but preserved for other renderers
    // isOver, // Not needed, but preserved for other renderers
    // parentNode, // Needed for dndManager
    // rowDirection
    hasGlobalQuestionEditPermissions,
    canEditQuestion,
  } = props;
  // TODO This is bad - need to figure out where the actual destructuring is happening that results in a non-ProgramBuilderQuestionInstance being passed in
  const node = new ProgramBuilderQuestionInstance(actualNode);

  // const { allowed: canEditQuestion, global: hasGlobalQuestionEditPermissions } =
  //   useCanEditQuestion(node);
  /*console.log('node-content-renderer with props', props); */
  const classes = useStyles();

  const [addPendingQuestionData, treeData, requestUpdateForm, form] = buttons;
  const [qInstanceOpen, setQInstanceOpen] = useState<boolean>(false);
  const [qInstanceSubOpen, setQInstanceSubOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const handleClick = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleQuestionInstanceClose = () => {
    setQInstanceOpen(false);
  };
  const handleQuestionInstanceSubClose = () => {
    setQInstanceSubOpen(false);
  };

  const duplicateQuestion = (
    questionInstance: ProgramBuilderQuestionInstance,
  ) => {
    dispatch(
      copyQuestionInstance({
        questionInstance,
        parentForm: form,
      }),
    );
  };

  let nodeTitle: string = '';
  let nodeLabel: string = '';
  try {
    nodeTitle = node.generateDisplayName();
    nodeLabel = node.generateLabel();
  } catch (e) {
    console.error('NODE-CONTENT-RENDERER:', e, node);
    throw e;
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  // Construct the scaffold representing the structure of the tree
  const scaffold: Array<$TSFixMe> = [];

  lowerSiblingCounts.forEach((_lowerSiblingCount: $TSFixMe, i: $TSFixMe) => {
    if (i > 0) {
      scaffold.push(
        <div
          className={classes.lineBlock}
          key={`pre_${1 + i}`}
          style={{ width: scaffoldBlockPxWidth }}
        />,
      );

      if (treeIndex !== listIndex && i === swapDepth) {
        // This row has been shifted, and is at the depth of
        // the line pointing to the new destination
        let highlightLineClass = '';

        if (listIndex === swapFrom + swapLength - 1)
          // This block is on the bottom (target) line
          // This block points at the target block (where the row will go when released)
          highlightLineClass = classes.highlightBottomLeftCorner;
        else if (treeIndex === swapFrom)
          // This block is on the top (source) line
          highlightLineClass = classes.highlightTopLeftCorner;
        // This block is between the bottom and top
        else highlightLineClass = classes.highlightLineVertical;

        scaffold.push(
          <div
            className={`${classes.absoluteLineBlock} ${highlightLineClass}`}
            key={`highlight_${1 + i}`}
            style={{
              left: scaffoldBlockPxWidth * i,
              width: scaffoldBlockPxWidth,
            }}
          />,
        );
      }
    }
  });

  const IconComponent = componentRegistry.getIcon(node.component);

  /* const nodeContent = ( */
  return (
    <div className={classes.nodeContent}>
      <div
        className={
          classes.rowWrapper +
          (!canDrag ? ` ${classes.rowWrapperDragDisabled}` : '')
        }
      >
        {scaffold}
        {connectDragPreview(
          <div
            className={
              classes.row +
              (isLandingPadActive ? ` ${classes.rowLandingPad}` : '') +
              (isLandingPadActive && !canDrop
                ? ` ${classes.rowCancelPad}`
                : '') +
              (isSearchMatch && !isSearchFocus
                ? ` ${classes.rowSearchMatch}`
                : '') +
              (isSearchFocus ? ` ${classes.rowSearchFocus}` : '') +
              (className ? ` ${className}` : '')
            }
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            <div
              className={
                classes.rowContents +
                // @ts-expect-error
                (!canDrag ? ` ${classes.rowContentsDragDisabled}` : '') +
                (!canEditQuestion ? ` ${classes.disabledRow}` : '')
              }
            >
              {connectDragSource(
                <div style={{ lineHeight: 1 }}>
                  <DragIcon />
                </div>,
              )}
              {IconComponent ? (
                <Tooltip
                  title={
                    componentRegistry.getTypeLabel(node.component) || 'N/A'
                  }
                  placement='top'
                >
                  <div style={{ lineHeight: 1 }}>
                    <IconComponent />
                  </div>
                </Tooltip>
              ) : null}
              {/* <div className={classes.rowIcon}>{icons}</div> */}
              <div className={classes.rowLabel}>
                <span className={classes.rowTitle}>
                  {node.required ? (
                    <Tooltip title='Required'>
                      <Brightness1Icon color='error' sx={{ height: '0.5em' }} />
                    </Tooltip>
                  ) : (
                    ''
                  )}{' '}
                  <Tooltip title={node.apiName} placement='top'>
                    <span>{nodeTitle}</span>
                  </Tooltip>
                </span>
                <span className={classes.rowQuestion}>{nodeLabel}</span>
              </div>

              <div className={classes.rowToolbar}>
                {canEditQuestion && node.dataType === 'nested' ? (
                  <Button
                    variant='text'
                    size='small'
                    onClick={() => {
                      setQInstanceSubOpen(true);
                      addPendingQuestionData(path, node);
                    }}
                  >
                    Add
                  </Button>
                ) : null}
                {node.children.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      toggleChildrenVisibility({
                        node: node,
                        path,
                        treeIndex,
                      });
                    }}
                  >
                    {node.expanded ? (
                      <ExpandLessRoundedIcon />
                    ) : (
                      <ExpandMoreRoundedIcon />
                    )}
                  </IconButton>
                ) : null}
                {canEditQuestion ? (
                  <IconButton
                    // key={`row-${1 + rowInfo.treeIndex}`}
                    onClick={handleClick}
                    size='large'
                  >
                    <MoreVertIcon />
                  </IconButton>
                ) : null}
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    disabled={!hasGlobalQuestionEditPermissions}
                    onClick={() => {
                      handleClose();
                      duplicateQuestion(node);
                    }}
                  >
                    Duplicate
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setQInstanceOpen(true);
                    }}
                  >
                    Edit
                  </MenuItem>

                  <MenuItem
                    disabled={!hasGlobalQuestionEditPermissions}
                    onClick={() => {
                      requestUpdateForm(
                        form,
                        removeNodeAtPath({
                          treeData: treeData,
                          path,
                          getNodeKey: ({ treeIndex }) => treeIndex,
                        }),
                        setEditAllDialogueOpen,
                        setPendingTreeData,
                        setTopLevelQuestion,
                      );
                      handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <FormModal
                  open={qInstanceOpen}
                  handleClose={handleQuestionInstanceClose}
                  FormComponent={(props: $TSFixMe) => (
                    <QuestionInstanceForm
                      questionInstance={
                        new ProgramBuilderQuestionInstance(node)
                      }
                      parentForm={form}
                      isEditing={true}
                      {...props}
                    />
                  )}
                  title='Edit Question Properties'
                />
                <FormModal
                  open={qInstanceSubOpen}
                  handleClose={handleQuestionInstanceSubClose}
                  FormComponent={(props: $TSFixMe) => (
                    <QuestionInstanceForm
                      parentForm={form}
                      isEditing={false}
                      {...props}
                    />
                  )}
                  title='Create Question Properties'
                />
              </div>
            </div>
          </div>,
        )}
      </div>
    </div>
  );

  // return canDrag ?
  //   connectDragSource(nodeContent, { dropEffect: 'copy' }) :
  //   nodeContent

  /* return nodeContent; */
}

FileThemeNodeContentRenderer.defaultProps = {
  buttons: null,
  canDrag: false,
  canDrop: false,
  className: '',
  draggedNode: null,
  icons: null,
  isSearchFocus: false,
  isSearchMatch: false,
  parentNode: null,
  style: {},
  swapDepth: null,
  swapFrom: null,
  swapLength: null,
  title: null,
  toggleChildrenVisibility: null,
};

FileThemeNodeContentRenderer.propTypes = {
  buttons: PropTypes.node,
  canDrag: PropTypes.bool,
  canDrop: PropTypes.bool,
  className: PropTypes.string,
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  icons: PropTypes.node,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  isSearchFocus: PropTypes.bool,
  isSearchMatch: PropTypes.bool,
  listIndex: PropTypes.number.isRequired,
  lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  node: PropTypes.shape({}).isRequired,
  parentNode: PropTypes.shape({}),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  rowDirection: PropTypes.string.isRequired,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,

  // Drag and drop API functions
  // Drag source
  style: PropTypes.shape({}),
  swapDepth: PropTypes.number,
  swapFrom: PropTypes.number,
  swapLength: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  toggleChildrenVisibility: PropTypes.func, // Needed for dndManager
  // Drop target
  treeId: PropTypes.string.isRequired,
  treeIndex: PropTypes.number.isRequired,
};

export default FileThemeNodeContentRenderer;
