import { Language, LanguageInterface } from './Language';

export interface ILanguageAwareString {
  value: string;
  language: LanguageInterface;
}

export class LanguageAwareString implements ILanguageAwareString {
  value: string;
  language: Language;

  constructor(value: string, language: Language) {
    this.value = value;
    this.language = language;
  }

  /**
   * Returns a copy of the object
   */
  copy() {
    return new LanguageAwareString(this.value, this.language.copy());
  }

  /**
   * Determines whether two LanguageAwareStrings are equal
   */
  equals(other: LanguageAwareString) {
    return this.value === other.value && this.language.equals(other.language);
  }

  /**
   * Transform so that it can be sent back to the backend
   */
  toLanguageAwareStringInput() {
    return {
      value: this.value,
      language: this.language.toLanguageInput(),
    };
  }

  /**
   * Create a language-aware string from an object with the correct properties (largely with a result from the backend)
   */
  static createFromObject(obj: ILanguageAwareString) {
    const { value, language: languageInput } = obj;
    const language = Language.createFromObject(languageInput);
    return new LanguageAwareString(value, language);
  }
}
