// types and classes
import {
  UnifiedAnswerInstance,
  UnifiedCompletedForm,
} from '../../../Typescript/classes';
import {
  QuoteWizardForm,
  QuoteWizardAnsweredForm,
  QuoteWizardQuestionInstance,
} from '../../QuoteWizard/classes';

/**
 * Given a list of answers and a list of questions, ensure that there is at least one answer for each question, creating a fake 'default' answer if necessary
 */
export function amendAnswersListWithDefaultAnswers(
  answersList: Array<UnifiedAnswerInstance>,
  questionsList: Array<QuoteWizardQuestionInstance>,
) {
  //console.log(
  //   `In amendAnswersListWithDefaultAnswers with answers\n
  //   ${JSON.stringify(answersList, null, 4)}\n
  //   questions:\n,
  //   ${JSON.stringify(questionsList, null, 4)}`,
  // );
  if (!answersList) {
    answersList = [];
  }
  if (!questionsList) {
    questionsList = [];
  }
  const newAnswersList: Array<UnifiedAnswerInstance> = [];

  questionsList.forEach((question) => {
    let matchingAnswers = answersList.filter((answer) =>
      question.matchAnswerInstance(answer),
    );
    //console.log(
    //   `Found matching answers for\nquestion: ${JSON.stringify(
    //     question,
    //     null,
    //     4,
    //   )}\n
    //   matchingAnswers: ${JSON.stringify(matchingAnswers, null, 4)}\nin\n
    //   ${JSON.stringify(questionsList, null, 4)}`,
    // );
    if (matchingAnswers.length === 0) {
      const defaultQuestion = question.toDefaultUnifiedAnswerInstance();
      //console.log(
      //   'Unable to find matching answer for',
      //   question,
      //   'in',
      //   answersList,
      //   '; pushing a default question',
      //   defaultQuestion,
      // );
      matchingAnswers.push(defaultQuestion);
    }
    matchingAnswers.forEach((answer) => {
      //console.log(
      //   'About to recurse with',
      //   answer.subAnswers,
      //   question.subQuestions,
      // );
      answer.subAnswers = amendAnswersListWithDefaultAnswers(
        answer.subAnswers,
        question.subQuestions,
      );
    });
    newAnswersList.push(...matchingAnswers);
  });

  //console.log({ newAnswersList });
  return newAnswersList;
}

/**
 * For any answer whose value is an empty string, replace it with the default value
 */
const replaceEmptyStringWithDefault = (
  answersList: Array<UnifiedAnswerInstance>,
): Array<UnifiedAnswerInstance> => {
  //console.log('In replaceEmptyStringWithDefault with', answersList);
  if (!answersList) {
    //console.log(`answersList was empty ( ${answersList} ) - returning [] `);
    return [];
  }
  const newAnswersList = answersList.map((answer) => {
    const newAnswer: UnifiedAnswerInstance = answer.copy();
    newAnswer.value =
      newAnswer.value === ''
        ? newAnswer.generateDefaultValue()
        : newAnswer.value;
    newAnswer.subAnswers = replaceEmptyStringWithDefault(answer.subAnswers);

    return newAnswer;
  });

  //console.log(
  //   `In replaceEmptyStringWithDefault - about to return`,
  //   newAnswersList,
  // );
  return newAnswersList;
};

/**
 * Given a (partially) answered form and the form from which it was created, ensure that every question in the original form has a corresponding answer, even if we just generate the default value for it
 * @param formsWithAnswers - All forms that have been submitted with answers
 * @param allForms - The unanswered forms
 */
export function hybridAnswers(
  formsWithAnswers: Array<QuoteWizardAnsweredForm>,
  allForms: Array<QuoteWizardForm>,
): Array<UnifiedCompletedForm> {
  const clonedFormsWithAnswers = formsWithAnswers.map((form) => form.copy());
  //console.log(
  //   JSON.stringify(
  //     { formsWithAnswers, clonedFormsWithAnswers, allForms },
  //     null,
  //     4,
  //   ),
  // );

  const newClonedFormsWithAnswers: Array<UnifiedCompletedForm> =
    clonedFormsWithAnswers.map((answeredForm) => {
      return new UnifiedCompletedForm({
        ...answeredForm,
        answers: replaceEmptyStringWithDefault(
          answeredForm.answers.map((answer) =>
            answer.toUnifiedAnswerInstance(),
          ),
        ),
      });
    });

  //console.log({ newClonedFormsWithAnswers });

  allForms.forEach((originalForm) => {
    let matchingFormWithAnswers = newClonedFormsWithAnswers.find((answerForm) =>
      answerForm.matchQuoteWizardFormByPolicy(originalForm),
    );
    //console.log(JSON.stringify({ matchingFormWithAnswers }, null, 4));

    // if we can't find one, create a new fake one and then muck about with that one!
    if (matchingFormWithAnswers === undefined) {
      //console.log('Unable to find matching form with answers');
      matchingFormWithAnswers = originalForm.toDefaultUnifiedCompletedForm();
      matchingFormWithAnswers.answers = amendAnswersListWithDefaultAnswers(
        matchingFormWithAnswers.answers,
        originalForm.questionInstances,
      );
      newClonedFormsWithAnswers.push(matchingFormWithAnswers); // if the matching form with answers was undefined, couldn't find a matching answered form -> it won't be in newClonedFormsWithAnswers
    } else {
      //console.log('Found matching form with answers');
      matchingFormWithAnswers.answers = amendAnswersListWithDefaultAnswers(
        matchingFormWithAnswers.answers,
        originalForm.questionInstances,
      );
    }
    //console.log(JSON.stringify(matchingFormWithAnswers, null, 4));

    matchingFormWithAnswers.answers = reorderQuestions(
      originalForm.questionInstances,
      matchingFormWithAnswers.answers,
    );
    matchingFormWithAnswers.answers = replaceEmptyStringWithDefault(
      matchingFormWithAnswers.answers,
    );
  });

  //console.log({ newClonedFormsWithAnswers });
  return newClonedFormsWithAnswers;
}

/**
 * Reorder answers based on the order in which their parent question appears in the list
 */
export const reorderQuestions = (
  questions: Array<QuoteWizardQuestionInstance>,
  answers: Array<UnifiedAnswerInstance>,
) => {
  if (!questions || questions.length === 0) {
    return [];
  }
  const orderedAnswers: Array<UnifiedAnswerInstance> = [];

  questions.forEach((question) => {
    const relevantAnswers = answers
      .filter((answer) => question.matchAnswerInstance(answer))
      .map((answer) => {
        return new UnifiedAnswerInstance({
          ...answer,
          subAnswers: reorderQuestions(
            question.subQuestions,
            answer.subAnswers,
          ),
        });
      });
    orderedAnswers.push(...relevantAnswers);
  });

  return orderedAnswers;
};
