import theme from './theme';
import logo from './assets/MainLogo.svg';
import squareLogo from './assets/squareLogo.svg';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../../hooks/useSettings/WebsiteSettings';
import { WalnutPartnersFooter } from '../Footer';

// Walnut (commercial) Config
export const WalnutCommercialSettings = new WebsiteSettings({
  slug: 'walnutcommercial',
  host: 'commercial.gowalnut.com',
  companyName: 'Walnut',
  // hours: '8:30am - 5:30pm',
  phone: '1-888-966-5308',
  email: 'contact@gowalnut.com',
  // chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo,
  squareLogo,
  title: 'Walnut | Unlock embedded insurance',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: true,
  contactInformationFirst: true,
  Footer: WalnutPartnersFooter,
  // personalInsurance: true,
});
