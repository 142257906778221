import { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { Pagebreak } from '../utils';
import {
  dayNumberToString,
  convertIsoTimeToReadable,
} from '../../../QuoteWizard/QuoteReview/utility';
/* import { extractLocationsFromAnswerList as extractLocationAnswersFromAnswersList } from '../../QuoteLoader/utility'; */
import { OptaDetailsForSingleLocation } from '../Opta';
// ---------------------------------------------------------------------

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { UnifiedAnswerInstance } from '../../../../Typescript/classes';
import { BackendImage } from '../../../../Typescript/backend/types';
import type { QuotePdfComponentPageProps } from './types';
// import { useUsingVagoSettings } from '../../../../WhiteLabelSettings/VagoAssure/settings';
import { useUsingVagoSettings } from '../../../../hooks';

export const LocationsPage = ({ quote }: QuotePdfComponentPageProps) => {
  const onVago = useUsingVagoSettings();
  const [allLocations, setAllLocations] = useState<
    Array<UnifiedAnswerInstance>
  >([]);

  useEffect(() => {
    if (quote.completedForms.length > 0) {
      // const filter = onVago ? { includedApiNames: [''] } : {};
      const filter = onVago ? { includedApiNames: ['risk_address'] } : {};
      let locations = quote.allUniqueAnsweredLocationAnswers(filter);
      setAllLocations(locations);
    }
  }, [quote, onVago]);

  if (!allLocations || allLocations.length === 0) {
    return null;
  }

  return (
    <>
      {allLocations
        .filter((location) => location && location.details)
        .map((location) => {
          /* if (!location || !location.details) { */
          /*   return null; */
          /* } */

          const { streetAddress } = extractLocationAddressParameters(location);

          return (
            <Box>
              <Typography variant='subtitle1' color='textSecondary'>
                Location - {streetAddress}
              </Typography>
              <Divider sx={{ my: 2 }} />
              {/*
              // @ts-expect-error */}
              <DisplayImages location={location} />
              <LocationAdditionalDetails location={location} />
              <Pagebreak />
              <OptaDetailsForSingleLocation location={location} />
              <Pagebreak />
            </Box>
          );
        })}
    </>
  );
};

//--------------------------------------------------------------------------------
// Components

interface LocationAdditionalDetailsProps {
  location: UnifiedAnswerInstance | null;
}
/**
 * Display the additional textual details associated with the location
 * @param {object} location - The location. Shape: {
 *     value
 *     details
 *     questionInstance
 *     subAnswers
 * }
 */
function LocationAdditionalDetails({
  location,
}: LocationAdditionalDetailsProps) {
  if (!location || !location.details) {
    return null;
  }
  const { streetAddress, city, province, postalCode } =
    extractLocationAddressParameters(location);
  const addressString = [streetAddress, city, province, postalCode]
    .filter((elem) => elem && elem !== 'N/A')
    .join(' ');

  let ratingString;
  if (location.details && location.details.google) {
    const { rating, numberOfRatings } = location.details.google;
    ratingString = rating
      ? `Rating: ${rating} / 5` +
        (numberOfRatings ? ` (${numberOfRatings} ratings)` : '')
      : null;
  }

  // @ts-expect-error
  const placeId =
    location.details &&
    location.details.google &&
    location.details.google.placeId
      ? location.details.google.placeId
      : null;
  const googleMapsLink = encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${addressString}`,
    //+ (placeId ? `&query_place_id=${placeId}` : ''),
  );

  return (
    <Box>
      <Typography variant='subtitle2' color='textSecondary'>
        Additional Information
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        <a href={googleMapsLink}>View on Google Maps</a>
      </Typography>
      {
        // only display on lastname
        /secure\.lastnameinsurance\.ca/i.test(window.location.host) ? (
          <>
            {ratingString ? (
              <Typography variant='body2' color='textSecondary'>
                {ratingString}
              </Typography>
            ) : null}
            {location.details.openingHours ? (
              <DisplayOpeningHours hours={location.details.openingHours} />
            ) : null}
          </>
        ) : null
      }
    </Box>
  );
}

interface DisplayImagesProps {
  location: UnifiedAnswerInstance | null;
}
/**
 * Display the images in some sort of aesthetically pleasing fashion
 * @param {object} location - The location. Shape:
 * {
 *    value
 *    questionInstance
 *    subAnswers
 *    details: {
 *    	images: [{category: string, description: string, data: base64string of the image}]
 *    	...
 *    }
 * }
 */
function DisplayImages({ location }: DisplayImagesProps) {
  if (!location) {
    return null;
  }
  const images =
    location.details && location.details.images
      ? location.details.images
      : null;
  if (images === null) {
    return null;
  }

  // split into pairs of images to display next to each other
  const imagePairs: Array<[BackendImage, BackendImage] | [BackendImage]> = [];
  images.forEach((image, index) => {
    if (imagePairs[Math.floor(index / 2)]) {
      imagePairs[Math.floor(index / 2)].push(image);
    } else {
      imagePairs.push([image]);
    }
  });

  return imagePairs.map((imagePair) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '10px',
      }}
    >
      {imagePair.map((image, idx) => {
        let actualDescription;
        if (image.shortDescription) {
          actualDescription = image.shortDescription;
        } else if (image.description) {
          actualDescription = image.description;
        }
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {actualDescription ? (
              <>
                <Typography
                  color={'textSecondary'}
                  variant='caption'
                  align='center'
                >
                  {actualDescription}
                </Typography>
              </>
            ) : null}
            <Box
              component='img'
              key={image.description + '_' + idx}
              src={image.data}
              sx={{
                height: '300px',
                width: '300px',
                m: '10px',
                borderRadius: '10px',
              }}
            />
          </Box>
        );
      })}
    </Box>
  ));
}

//--------------------------------------------------------------------------------
// Utility functions

export interface LocationComponents {
  streetAddress?: string;
  city?: string;
  province?: string;
  postalCode?: string;
}

const extractLocationAddressParameters = (
  location: UnifiedAnswerInstance,
): LocationComponents => {
  if (!location) {
    return {};
  }

  const streetAddressAnswerInstance = location.subAnswers.find(
    (answer) => answer.apiName === 'address',
  );
  const cityAnswerInstance = location.subAnswers.find(
    (answer) => answer.apiName === 'city',
  );
  const provinceAnswerInstance = location.subAnswers.find(
    (answer) => answer.apiName === 'province',
  );
  const postalCodeAnswerInstance = location.subAnswers.find(
    (answer) => answer.apiName === 'postal',
  );

  const streetAddress =
    streetAddressAnswerInstance &&
    (streetAddressAnswerInstance.value as string);
  const city = cityAnswerInstance && (cityAnswerInstance.value as string);
  const province =
    provinceAnswerInstance && (provinceAnswerInstance.value as string);
  const postalCode =
    postalCodeAnswerInstance && (postalCodeAnswerInstance.value as string);

  return { streetAddress, city, province, postalCode };
};

/**
 * Are two representations of a province referring to the same one? E.g. AB, A.B., ab, Alberta should all match each other
 */
const areProvincesTheSame = (
  p1: string | undefined,
  p2: string | undefined,
): boolean => {
  if (p1 === p2) {
    return true;
  }
  if (p1 === undefined || p2 === undefined) {
    return false;
  }
  if (p1.toLowerCase() === p2.toLowerCase()) {
    return true;
  }

  const provinceAbbrevs = {
    nl: 'Newfoundland and Labrador',
    pe: 'Prince Edward Island',
    ns: 'Nova Scotia',
    nb: 'New Brunswick',
    qc: 'Quebec',
    on: 'Ontario',
    mb: 'Manitoba',
    sk: 'Saskatchewan',
    ab: 'Alberta',
    bc: 'British Columbia',
    yt: 'Yukon',
    nt: 'Northwest Territories',
    nu: 'Nunavut',
  };

  const modifiedP1 = p1.replace(/[^\w]/g, '').toLowerCase();
  const modifiedP2 = p2.replace(/[^\w]/g, '').toLowerCase();

  const translatedP1 =
    // @ts-expect-error
    modifiedP1 in provinceAbbrevs ? provinceAbbrevs[modifiedP1] : modifiedP1;
  const translatedP2 =
    // @ts-expect-error
    modifiedP2 in provinceAbbrevs ? provinceAbbrevs[modifiedP2] : modifiedP2;

  return translatedP1 === translatedP2;
};

export const filterDuplicateLocations = (
  locationsArray: Array<UnifiedAnswerInstance>,
) => {
  if (!locationsArray || locationsArray.length === 0) {
    return [];
  }

  const uniqueLocations = [locationsArray[0]];

  for (let location of locationsArray.slice(1)) {
    const locationComponents = extractLocationAddressParameters(location);

    if (
      uniqueLocations.every((uniqueLocation) => {
        const uniqueLocationComponents: $TSFixMe =
          extractLocationAddressParameters(uniqueLocation);
        /*console.log( */
        /*   'Comparing two location components:', */
        /*   locationComponents, */
        /*   uniqueLocationComponents, */
        /* ); */
        const components: Array<keyof LocationComponents> = [
          'streetAddress',
          'city',
          'province',
          'postalCode',
        ];
        for (let component of components) {
          if (
            (locationComponents[component] || '').toLowerCase() !==
            (uniqueLocationComponents[component] || '').toLowerCase()
          ) {
            if (component === 'province') {
              return !areProvincesTheSame(
                locationComponents[component],
                uniqueLocationComponents[component],
              );
            } else {
              return true;
            }
          }
        }
        return false;
      })
    ) {
      uniqueLocations.push(location);
    }
  }

  /*console.log(`About to return uniqueLocation:`, uniqueLocations); */
  return uniqueLocations;
};

const DisplayOpeningHours = ({ hours }: $TSFixMe) => {
  if (!hours || hours.every((hour: $TSFixMe) => !hour)) {
    return null;
  }
  const convertOpeningHourToPdfJsx = (dayNumber: $TSFixMe, hour: $TSFixMe) => {
    if (!hour) {
      return (
        <Typography color='textSecondary' variant='body2' sx={{ ml: 2 }}>
          {dayNumberToString(dayNumber)}: Closed
        </Typography>
      );
    } else if (!hour.close && hour.open === '00:00:00') {
      return (
        <Typography color='textSecondary' variant='body2' sx={{ ml: 2 }}>
          {dayNumberToString(dayNumber)}: All Day
        </Typography>
      );
    }
    return (
      <Typography color='textSecondary' variant='body2' sx={{ ml: 2 }}>
        {dayNumberToString(hour.day)}:{' '}
        {hour.open ? convertIsoTimeToReadable(hour.open) : null} -{' '}
        {hour.close ? convertIsoTimeToReadable(hour.close) : null}
      </Typography>
    );
  };
  if (
    hours.length === 1 &&
    hours[0].day === 7 &&
    !hours[0].close &&
    hours[0].open === '00:00:00'
  ) {
    return (
      <Typography color='textSecondary' variant='body2'>
        Hours: Always Open
      </Typography>
    );
  }
  return (
    <Box>
      <Typography color='textSecondary' variant='body2'>
        Hours:
      </Typography>
      {[1, 2, 3, 4, 5, 6, 7].map((dayNumber) => {
        const matchingOpenTime = hours.find(
          (elem: $TSFixMe) => elem.day === dayNumber,
        );
        return convertOpeningHourToPdfJsx(dayNumber, matchingOpenTime);
      })}
    </Box>
  );
};
