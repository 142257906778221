import { useEffect, useState } from 'react';
import { useParams, useLocation } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
// material
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import PolicyNewForm from '../../components/_dashboard/database/policy/PolicyNewForm';
import LoadingScreen from '../../components/LoadingScreen';
// queries
import { POLICY } from '../../../../queries';
// error handler
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../Page500';

// types and classes
import type { RouteComponentProps } from '@reach/router';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

interface PolicyCreateProps extends RouteComponentProps {}
export default function PolicyCreate(_props: PolicyCreateProps) {
  const { pathname } = useLocation();
  const { policyId } = useParams();
  const isEdit = pathname.includes('edit');
  const [currentPolicy, setCurrentPolicy] = useState<$TSFixMe>(null);

  // queries
  const [getPolicy, { loading, data, error }] = useLazyQuery(POLICY);

  // effects
  useEffect(() => {
    if (isEdit) {
      getPolicy({
        variables: {
          id: policyId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.policy) {
      setCurrentPolicy(data.policy);
    }
  }, [data]);

  // Loading actions
  if (loading) {
    return <LoadingScreen />;
  }
  // Error actions
  if (error) {
    console.error('Error getting policy:', error);
    Bugsnag.notify(error);
    return <ErrorPage />;
  }

  return (
    <Page
      title={
        !isEdit
          ? 'Database: Create a new policy | Manager'
          : 'Database: Edit policy | Manager'
      }
    >
      <Container maxWidth='md' data-testid='policy-create'>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new policy' : 'Edit policy'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Policy List', href: PATH_DASHBOARD.database.policy },
            { name: !isEdit ? 'New policy' : 'Edit policy' },
          ]}
        />

        <PolicyNewForm isEdit={isEdit} currentPolicy={currentPolicy} />
      </Container>
    </Page>
  );
}
