import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, take, call, spawn } from 'redux-saga/effects';
import FormStore from './FormStore';
import Bugsnag from '@bugsnag/js';

import {
  publishNewForm,
  updateExistingForm,
  BusinessSpecificFormQuery,
  GET_ALL_FORMS,
} from '../queries';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { BusinessType } from '../Typescript';
import { Language } from '../Typescript/classes';
import { SagaArgument } from './types';
import {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../src/components/FormManager/classes';
import { errorify } from '../util';

interface FormPublishTask {
  form: Pick<ProgramBuilderForm, 'clientId'>;
}

interface ProgramBuilderWizardState {
  requestedBusinessType: BusinessType | null;
  formPublishTasks: Array<FormPublishTask>;
  pendingQuestion: ProgramBuilderQuestionInstance | {};
  languages: Array<Language>;
}
export const defaultInitialState: ProgramBuilderWizardState = {
  requestedBusinessType: null,
  formPublishTasks: [],
  pendingQuestion: {},
  languages: [Language.defaultLanguage],
};
const slice = createSlice({
  name: 'program-builder-wizard',
  initialState: defaultInitialState,
  reducers: {
    /**
     * Set the business type for the current form (or send no selected business type to clear it)
     * @param state - state
     * @param action - The action. Should have payload { selectedBusinessType: BusinessLine }
     */
    requestBusinessTypeProgram: (
      state,
      action: PayloadAction<{
        selectedBusinessType: BusinessType;
      }>,
    ) => {
      const { selectedBusinessType } = action.payload;

      state.requestedBusinessType = selectedBusinessType
        ? selectedBusinessType
        : null;
    },
    /**
     * Publish a form to the backend
     * @param state - state
     * @param action - Action. payload should be
     * {
     * 	form: Form,
     * 	questionInstances: [] --> this has been empty - is it used? Not in this reducer...
     * }
     */
    requestPublishForm: (
      state,
      action: PayloadAction<{
        form: ProgramBuilderForm;
        questionInstances: Array<ProgramBuilderQuestionInstance>;
        onSuccessCallback: $TSFixMe;
        onFailCallback: $TSFixMe;
      }>,
    ) => {
      const { form } = action.payload;

      state.formPublishTasks.push({
        form: {
          clientId: form.clientId,
        },
      });
    },
    /**
     * Update a form and push backend
     * @param state - state
     * @param action - Action. payload should be
     * {
     * 	form: Form,
     * 	questionInstances: [QuestionInstances] --> why is this like this? The questions are already in the form...
     * }
     */
    requestUpdateForm: (
      state,
      action: PayloadAction<{
        form: ProgramBuilderForm;
        questionInstances: Array<ProgramBuilderQuestionInstance>;
      }>,
    ) => {
      //console.log('in requestUpdateForm resolver');
      const { form } = action.payload;
      //console.log('in requestUpdateForm resolver: got form', form);

      //console.log('in requestUpdateForm, about to push the task');
      state.formPublishTasks.push({
        form: {
          clientId: form.clientId,
        },
      });
      //console.log('in requestUpdateForm - about to exit');
    },
    /**
     * Sets the new pending question
     * @param state - state
     * @param action - action. The payload should be {
     *   node: ?????
     *   path: ?????
     * }
     */
    addPendingQuestion: (
      state,
      action: PayloadAction<{
        node: $TSFixMe;
        path: $TSFixMe;
      }>,
    ) => {
      // debugger;
      const { node, path } = action.payload;
      state.pendingQuestion = { node, path };
    },
    /**
     * Clear any existing pending question
     * @param state - state
     */
    clearPendingQuestion: (state) => {
      state.pendingQuestion = {};
    },
    /**
     * Set a list of the available languages
     */
    setLanguages: (state, action: PayloadAction<Array<Language>>) => {
      state.languages = action.payload;
    },
  },
  extraReducers: {
    /**
     * Removes the selected Form from `formPublishTasks` once it's been saved
     * @param state - state
     * @param action - The action. Payload should be {form: Form}
     */
    [FormStore.actions.markFormAsSaved.type]: (
      state,
      action: PayloadAction<{ form: ProgramBuilderForm }>,
    ) => {
      const { form } = action.payload;

      state.formPublishTasks = state.formPublishTasks.filter(
        (formPublishTask) => formPublishTask.form.clientId !== form.clientId,
      );
    },
  },
});

/* Redux Saga Functions */

//This is a generator function used by redux saga
function* loadFormsForBusinessRequest({ client }: SagaArgument) {
  while (true) {
    //console.log('in loadFormsForBusinessRequest');
    // @ts-expect-error
    const action = yield take(slice.actions.requestBusinessTypeProgram.type);

    const { selectedBusinessType } = action.payload;

    if (!selectedBusinessType) {
      continue;
    }

    const { id: selectedBusinessTypeId } = selectedBusinessType;
    const { data } = yield call(client.query, {
      query: BusinessSpecificFormQuery,
      variables: {
        businessId: selectedBusinessTypeId,
      },
      fetchPolicy: 'network-only',

      // variables: { token: localStorage.getItem('accessToken') },
      // variables: { token },
    });

    yield put(
      FormStore.actions.loadExistingForms({
        loadedForms: data.someFinalForms,
      }),
    );
    // debugger;
    yield put(
      FormStore.actions.setBusinessType({
        selectedBusinessType,
      }),
    );
  }
}

/* Saga Functions */

function* publishForms({ client }: SagaArgument) {
  //console.log('0 - in publishForms');
  while (true) {
    //console.log('0.5 - immediately before it took the action');
    // @ts-expect-error
    const action = yield take(slice.actions.requestPublishForm.type);
    //console.log('ProgramBuilderWizard publishForms: just got the action');

    const { form, onSuccessCallback, onFailCallback } = action.payload;
    //console.log('1 - just got the success / failure callbacks and the form');

    const qualifyingQuestion = form.qualifyingQuestion
      ? form.qualifyingQuestion.toQuestionInstanceInput()
      : null;
    const policyExtension = form.policyExtensions
      ? form.policyExtensions.map((extension: $TSFixMe) => {
          const { __typename, ...ext } = extension;
          return ext;
        })
      : [];

    //console.log('2 - about the call the mutation');
    try {
      //console.log('About to call mutation');
      // @ts-expect-error
      const { data } = yield call(client.mutate, {
        mutation: publishNewForm,
        variables: {
          token: localStorage.getItem('accessToken'),
          businessLineId: form.businessLine.id,
          policyId: form.policy && form.policy.id,
          qualifyingQuestion: qualifyingQuestion,
          required: form.required,
          policyExtension: policyExtension,
          questionInstances: (form as ProgramBuilderForm).questionInstances.map(
            (q) => q.toQuestionInstanceInput(),
          ),
        },
        update: (cache: $TSFixMe, { data }: { data: $TSFixMe }) => {
          let forms;
          try {
            forms = cache.readQuery({
              query: GET_ALL_FORMS,
              variables: { token: localStorage.getItem('accessToken') },
            });
          } catch (e) {
            console.error('Failed to read final forms from cache', e);
          }

          try {
            cache.writeQuery({
              query: GET_ALL_FORMS,
              variables: { token: localStorage.getItem('accessToken') },
              data: {
                allFinalForms: [
                  ...forms.allFinalForms,
                  data.createFinalForm.finalForm,
                ],
              },
            });
          } catch (e) {
            console.error('Failed to write to final forms list in cache');
          }
        },
      });
      //console.log('Just after mutation: data:', data);

      //console.log('3 - after the mutation');

      if (data && data.createFinalForm && data.createFinalForm.ok) {
        const { finalForm } = data.createFinalForm;

        //console.log('3.5 about to create new form');
        const newForm = ProgramBuilderForm.generateFromBackendResponse({
          ...form,
          ...finalForm,
          new: false,
        });
        //console.log(
        //   '4 - In publishForms. Just created newForm:',
        //   newForm,
        //   'from old form',
        //   form,
        // );
        if (!form) {
          return;
        }

        yield put(
          FormStore.actions.markFormAsSaved({
            form: newForm,
            // form: {
            //   ...form,
            //   id: finalForm.id,
            // },
          }),
        );
        //console.log('5 - just put the markFormAsSaved action');

        // Remove and then add question instances. There is no way to do this unless we send and receive
        // client generated ids (or unless we make the assumption that order is preserved, or names are unique)
        // debugger;

        yield put(FormStore.actions.clearQuestionsFromForm({ form: newForm }));
        for (const questionInstanceBackendResponse of finalForm.questionInstances) {
          const questionInstance =
            ProgramBuilderQuestionInstance.generateFromBackendResponse(
              questionInstanceBackendResponse,
            );
          questionInstance.clientId = questionInstanceBackendResponse.id;
          questionInstance.ancillary.form = {
            clientId: form.clientId,
            name: form.name,
          };
          yield put(
            FormStore.actions.createQuestionInstance({
              questionInstance,

              // questionInstance: new ProgramBuilderQuestionInstance({
              //   ...questionInstance,
              //   clientId: questionInstance.id,
              //   ancillary: {
              //     form: {
              //       clientId: form.clientId,
              //     },
              //   },
              // }),
            }),
          );
        }

        // also update the extensions
        yield put(
          FormStore.actions.updateExtensions({
            extensionList: {
              form,
              ...(finalForm.policyExtension
                ? finalForm.policyExtension.reduce(
                    (
                      combinedObj: $TSFixMe,
                      extension: $TSFixMe,
                      index: $TSFixMe,
                    ) => {
                      combinedObj[index] = extension;
                      return combinedObj;
                    },
                    {},
                  )
                : {}),
            },
          }),
        );

        if (onSuccessCallback) {
          yield call(onSuccessCallback);
        }
      } else {
        Bugsnag.notify(data);
        console.error(data);
        if (onFailCallback) {
          yield call(onFailCallback);
        }
      }
    } catch (error) {
      Bugsnag.notify(errorify(error));
      console.error(error);
      if (onFailCallback) {
        yield call(onFailCallback);
      }
    }
  }
}

function* updateForms({ client }: SagaArgument) {
  //console.log('in updateForms');
  while (true) {
    //console.log('in ProgramBuilderWizard updateForms');
    // @ts-expect-error
    const action = yield take(slice.actions.requestUpdateForm);
    //console.log('ProgramBuilderWizard updateForms - just got action');

    const { form, onSuccessCallback, onFailCallback } = action.payload;
    //console.log('updateForms - just got form and callbacks');
    const qualifyingQuestion = form.qualifyingQuestion
      ? form.qualifyingQuestion.toQuestionInstanceInput()
      : null;
    // const qualifyingQuestion = generateQuestionInstanceInputObject(
    //   form.qualifyingQuestion,
    // );
    //console.log(
    //   'Just converted the qualifying question ->',
    //   qualifyingQuestion,
    // );
    const policyExtension = form.policyExtensions
      ? form.policyExtensions.map((extension: $TSFixMe) => {
          const { __typename, ...ext } = extension;
          return ext;
        })
      : [];

    // let qq;
    // if (qualifyingQuestion) {
    //   qq = generateQuestionInstanceInputObject(qualifyingQuestion);
    // }

    const questionInstancesInput = (
      form as ProgramBuilderForm
    ).questionInstances.map((q) => q.toQuestionInstanceInput());
    //console.log(
    //   'in updateForms with questionInstanceInput',
    //   questionInstancesInput,
    // );
    // const updatedQuestionInstances = recursivelyGenerateQuestionInstanceObject(
    //   form.questionInstances,
    // );
    try {
      const { data } = yield call(client.mutate, {
        mutation: updateExistingForm,
        variables: {
          token: localStorage.getItem('accessToken'),
          finalFormId: form.id,
          businessLineId: form.businessLine.id,
          required: form.required,
          qualifyingQuestion: qualifyingQuestion,
          questionInstances: questionInstancesInput,
          policyExtension: policyExtension,
        },
      });

      if (data && data.updateFinalForm && data.updateFinalForm.ok) {
        const { finalForm } = data.updateFinalForm;

        yield put(
          FormStore.actions.markFormAsSaved({
            form: ProgramBuilderForm.generateFromBackendResponse({
              ...form,
              ...finalForm,
              new: false,
            }),
          }),
        );

        // Remove and then add question instances. There is no way to do this unless we send and receive client generated ids
        // (or unless we make the assumption that order is preserved, or names are unique)
        // TODO: locks the ui, we need a buffer to send all actions out at once
        // debugger;

        yield put(FormStore.actions.clearQuestionsFromForm({ form }));
        for (const questionInstanceBackendResponse of finalForm.questionInstances) {
          const questionInstance =
            ProgramBuilderQuestionInstance.generateFromBackendResponse(
              questionInstanceBackendResponse,
            );
          questionInstance.clientId = questionInstanceBackendResponse.id;
          questionInstance.ancillary.form = {
            clientId: form.clientId,
            name: form.name,
          };
          yield put(
            FormStore.actions.createQuestionInstance({
              questionInstance,
            }),
          );
        }

        // also update the extensions
        yield put(
          FormStore.actions.updateExtensions({
            extensionList: {
              form,
              ...(finalForm.policyExtension
                ? finalForm.policyExtension.reduce(
                    (
                      combinedObj: $TSFixMe,
                      extension: $TSFixMe,
                      index: $TSFixMe,
                    ) => {
                      combinedObj[index] = extension;
                      return combinedObj;
                    },
                    {},
                  )
                : {}),
            },
          }),
        );

        if (onSuccessCallback) {
          yield call(onSuccessCallback);
        }
      }
    } catch (error) {
      console.error(error);
      Bugsnag.notify(errorify(error));
      if (onFailCallback) {
        yield call(onFailCallback);
      }
    }
  }
}

export function* ProgramBuilderWizardSaga({ client }: SagaArgument) {
  yield spawn(loadFormsForBusinessRequest, { client });
  yield spawn(publishForms, {
    client,
  });
  yield spawn(updateForms, {
    client,
  });
}

export default slice;
