import { RouteComponentProps } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import { ALL_BUSINESS_SELECTOR_INPUTS } from '../../queries';
import { useEffect } from 'react';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface BusinessSelectionInputsProps extends RouteComponentProps {}
export const BusinessSelectionInputs = (
  _props: BusinessSelectionInputsProps,
) => {
  const [getBusinessSelectionInputs, { loading, error, data }] = useLazyQuery(
    ALL_BUSINESS_SELECTOR_INPUTS,
  );
  useEffect(() => {
    getBusinessSelectionInputs();
  }, []);
  return (
    <>
      <h2>Business Selection Inputs</h2>
      {loading ? <p>Loading...</p> : null}
      {error ? (
        <>
          <p>Error</p>
          <pre>{JSON.stringify(error, null, 4)}</pre>
        </>
      ) : null}
      {data ? (
        <div data-testid='business-selection-inputs'>
          <table>
            <thead>
              <tr>
                {[
                  'Date',
                  'Session ID',
                  'Inputs',
                  'Final Selection',
                  'Associated Application',
                ].map((columnHeader) => (
                  <th scope='column'>{columnHeader}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.allBusinessSelectorInputs.map(
                ({
                  sessionId,
                  inputs,
                  businessSelected,
                  date,
                  associatedQuoteUuid,
                }: $TSFixMe) => (
                  <tr>
                    <td key='date'>{new Date(date).toString()}</td>
                    <td key='sessionId'>{sessionId}</td>
                    <td key='inputs'>
                      {inputs.map((input: $TSFixMe) => `"${input}"`).join(', ')}
                    </td>
                    <td key='businessSelected'>{businessSelected}</td>
                    <td key='associatedQuoteUuid'>
                      {associatedQuoteUuid || 'None'}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
};
