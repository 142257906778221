import Extensions from './Extensions';
import Typography from '@mui/material/Typography';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from '../../classes';

type ExtensionsButtonProps = {
  form: ProgramBuilderForm;
  policyExtensions: Array<$TSFixMe> | null;
};
const ExtensionsButton = ({
  form,
  policyExtensions,
}: ExtensionsButtonProps) => {
  /*console.log('In ExtensionsButton with policyExtensions', policyExtensions); */
  if (!form.policy) {
    return (
      <Typography align='center' variant='body2' color='textSecondary'>
        General Information forms cannot have extensions
      </Typography>
    );
  }

  return (
    <>
      <Extensions
        // @ts-expect-error
        parentForm={form}
        // @ts-expect-error
        policyExtensions={policyExtensions}
        // @ts-expect-error
        questionInstances={form.questionInstances}
      />
    </>
  );
};

export default ExtensionsButton;
