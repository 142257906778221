import { Card, Stack, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TotalTodaySkeleton() {
  return (
    <Card>
      <Stack direction='row' justifyContent='space-between'>
        <Stack width='100%'>
          <Skeleton width='40%' sx={{ mt: 3, mx: 3 }} />

          <Skeleton width='20%' sx={{ mx: 3 }} />
        </Stack>

        <Skeleton width='100px' sx={{ mx: 3 }} />
      </Stack>

      <Skeleton variant='rectangular' height='330px' sx={{ m: 3 }} />
    </Card>
  );
}
