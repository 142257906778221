import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';

import { DetailsCard } from './commonComponents';
import {
  IclarifyCommercialConstructionProduct,
  IclarifyCommercialConstructionHighlights,
} from '../../../QuoteLoader/LocationDetails/classes';
import { AddressDisplay } from './commonComponents';

import { NO_INFO_STRING } from '../../../QuoteLoader/LocationDetails/constants';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const COLUMN_WIDTH = '250px';
const COLUMN_STYLING = `${COLUMN_WIDTH} auto`;

interface DisplayIclarifyCommercialConstructionHighlightsProps {
  iClarifyCommercialConstructionProduct: $TSFixMe;
  classes: object;
}
const UnthemedDisplayIclarifyCommercialConstructionHighlights = ({
  iClarifyCommercialConstructionProduct,
  classes = {},
}: DisplayIclarifyCommercialConstructionHighlightsProps) => {
  const [highlights, setHighlights] =
    useState<null | IclarifyCommercialConstructionHighlights>(
      new IclarifyCommercialConstructionHighlights(
        iClarifyCommercialConstructionProduct,
      ),
    );

  useEffect(() => {
    setHighlights(
      new IclarifyCommercialConstructionHighlights(
        iClarifyCommercialConstructionProduct,
      ),
    );
  }, [iClarifyCommercialConstructionProduct]);

  if (highlights && Object.values(highlights).every((elem) => elem === null)) {
    return NO_INFO_STRING;
  }

  const toDisplay = highlights
    ? highlights.generateHighlightsToDisplay()
    : null;

  if (!toDisplay || Object.keys(toDisplay).length === 0) {
    return 'No highlights';
  }
  return (
    // @ts-expect-error
    <Grid container xs={12} className={classes.highlightsContainer} spacing={1}>
      {Object.keys(toDisplay).map((buildingId, index, allKeys) => (
        <Grid item xs={12} key={'building_' + buildingId + '_' + index}>
          <DetailsCard
            heading={allKeys.length > 0 ? `Building ${buildingId}` : null}
            childrenWrapperStyle={{ columns: COLUMN_STYLING }}
          >
            <ul>
              {/*
    // @ts-expect-error */}
              {toDisplay[buildingId].map((highlight, index) => (
                <li key={highlight + '_' + index}>{highlight}</li>
              ))}
            </ul>
          </DetailsCard>
        </Grid>
      ))}
    </Grid>
  );
};

const DisplayIclarifyCommercialConstructionHighlights = withStyles(
  (theme: $TSFixMe) => ({
    highlightsContainer: {
      margin: theme.spacing(0),
      '& li': {
        breakInside: 'avoid',
      },
      '& li:before': {
        content: '"\\233E"',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
      '& ul': {
        paddingLeft: '1em',
        listStyle: 'none',
      },
    },
  }),
)((props: $TSFixMe) => (
  // @ts-expect-error
  <UnthemedDisplayIclarifyCommercialConstructionHighlights {...props} />
));

interface DisplayOptaIclarifyCommercialConstructionDataProps {
  location: $TSFixMe;
  classes: object;
}
/**
 * Display the CommercialConstruction data for the given location
 */
const UnthemedDisplayOptaIclarifyCommercialConstructionData = ({
  location,
  classes = {},
}: DisplayOptaIclarifyCommercialConstructionDataProps) => {
  const [
    iClarifyCommercialConstructionProduct,
    setIclarifyCommercialConstructionProduct,
  ] = useState<null | IclarifyCommercialConstructionProduct>(
    // @ts-expect-error
    new IclarifyCommercialConstructionProduct(),
  );

  useEffect(() => {
    if (
      location &&
      location.details &&
      location.details.opta &&
      location.details.opta.optaIclarifyCommercialConstructionData
    ) {
      setIclarifyCommercialConstructionProduct(
        IclarifyCommercialConstructionProduct.createFromJson(
          location.details.opta.optaIclarifyCommercialConstructionData,
        ),
      );
    } else {
      setIclarifyCommercialConstructionProduct(null);
    }
  }, [
    location,
    location.details,
    location.details.opta,
    location.details.opta.optaIclarifyCommercialConstructionData,
  ]);

  return (
    <div>
      <Typography
        variant='subtitle1'
        align='center'
        color='textSecondary'
        gutterBottom
      >
        IClarify Commercial Construction
      </Typography>
      {iClarifyCommercialConstructionProduct &&
      // @ts-expect-error
      iClarifyCommercialConstructionProduct.Address ? (
        <AddressDisplay>
          {
            // @ts-expect-error
            iClarifyCommercialConstructionProduct.Address.toString()
          }
        </AddressDisplay>
      ) : null}
      {/*
    // @ts-expect-error */}
      <Box className={classes.highlights}>
        <Typography
          variant='subtitle1'
          style={{ textAlign: 'center' }}
          color='textSecondary'
          gutterBottom
        >
          Highlights
        </Typography>
        <DisplayIclarifyCommercialConstructionHighlights
          iClarifyCommercialConstructionProduct={
            iClarifyCommercialConstructionProduct
          }
        />
      </Box>

      {/*
    // @ts-expect-error */}
      <Grid container xs={12} className={classes.rowContainer} spacing={1}>
        {iClarifyCommercialConstructionProduct &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Messages &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Messages.Message &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Messages.Message.length > 0 ? (
          <Grid item xs={12}>
            <DetailsCard heading='Messages'>
              {
                // @ts-expect-error
                iClarifyCommercialConstructionProduct.Messages.Message
                  .length === 1 ? (
                  <Typography variant='body1'>
                    {
                      // @ts-expect-error
                      iClarifyCommercialConstructionProduct.Messages.Message[0]
                    }
                  </Typography>
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      iClarifyCommercialConstructionProduct.Messages.Message.map(
                        (message: $TSFixMe, index: number) => (
                          <li key={index}>{message}</li>
                        ),
                      )
                    }
                  </ul>
                )
              }
            </DetailsCard>
          </Grid>
        ) : null}
        {iClarifyCommercialConstructionProduct &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property ? (
          <Grid item xs={12}>
            <DetailsCard
              heading='Lot'
              childrenWrapperStyle={{ columns: COLUMN_STYLING }}
            >
              {
                // @ts-expect-error
                iClarifyCommercialConstructionProduct.Property.displayAsJsx()
              }
            </DetailsCard>
          </Grid>
        ) : null}
        {iClarifyCommercialConstructionProduct &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property.Buildings &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property.Buildings.Building &&
        // @ts-expect-error
        iClarifyCommercialConstructionProduct.Property.Buildings.Building
          .length > 0
          ? // @ts-expect-error
            iClarifyCommercialConstructionProduct.Property.Buildings.Building.map(
              (
                building: $TSFixMe,
                _index: number,
                buildings: Array<$TSFixMe>,
              ) => (
                <Grid item xs={12}>
                  <DetailsCard
                    heading={`Building${
                      buildings.length > 1 && building.StructureID
                        ? ' ' + building.StructureID
                        : ''
                    }`}
                  >
                    <Grid
                      container
                      xs={12}
                      spacing={1}
                      className={
                        // @ts-expect-error
                        classes.buildingDetailsContainer
                      }
                    >
                      <Grid item xs={9}>
                        <DetailsCard
                          heading='Construction Features'
                          childrenWrapperStyle={{ columns: COLUMN_STYLING }}
                        >
                          {building.ConstructionFeatures
                            ? building.ConstructionFeatures.displayAsJsx()
                            : NO_INFO_STRING}
                        </DetailsCard>
                      </Grid>
                      <Grid item xs={3}>
                        <DetailsCard heading='Protection'>
                          {building.Protection
                            ? building.Protection.displayAsJsx()
                            : NO_INFO_STRING}
                        </DetailsCard>
                      </Grid>
                    </Grid>
                  </DetailsCard>
                </Grid>
              ),
            )
          : null}
      </Grid>
    </div>
  );
};

export const DisplayOptaIclarifyCommercialConstructionData = withStyles(
  (theme: $TSFixMe) => ({
    ul: {
      margin: '0px',
      padding: '0px',
    },
    highlights: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1, 2, 2, 2),
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      border: '1px solid grey',
    },
    maps: {
      padding: theme.spacing(2, 0.5, 0, 0.5),
      width: '100%',
    },
    rowContainer: {
      margin: 0,
    },
    buildingDetailsContainer: {
      marginLeft: theme.spacing(0.125),
    },
    iClarifyCommercialConstructionProductNotFound: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(5),
    },
    notFoundButton: {
      marginTop: theme.spacing(2),
    },
    loadingMarker: {
      marginTop: theme.spacing(2),
      transition: 'opacity 1s',
    },
    animated1: {
      animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
    },
    animated2: {
      animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
      animationDelay: '1s',
    },
    animated3: {
      animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
      animationDelay: '2s',
    },
    '@global': {
      '@keyframes fadeInOut': {
        '0%': {
          opacity: 1,
        },
        '100%': {
          opacity: 0,
        },
      },
    },
  }),
)((props: $TSFixMe) => (
  <UnthemedDisplayOptaIclarifyCommercialConstructionData {...props} />
));
