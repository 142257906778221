import { graphql } from '../../gql';

export const EDIT_PERIL = graphql(`
  mutation editPeril(
    $token: String!
    $perilId: ID!
    $premium: String
    $overridePremium: Boolean
  ) {
    editPeril(
      token: $token
      perilId: $perilId
      premium: $premium
      overridePremium: $overridePremium
    ) {
      peril {
        ...PerilFields
      }
    }
  }
`);
