import { useState, useEffect } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useIframeQueryParams } from '../../hooks';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import { useNavigate } from '@reach/router';

import { generateBusinessLinkUrl } from '../BusinessLink/BusinessLink';
import { ImperativeNavigateLink } from './components';

// types and classes
import type { IframeParams } from '../../hooks';
import Bugsnag from '@bugsnag/browser';

import { IMPERATIVE_NAVIGATE_ID } from './constants';

export const generateNavigationDestination = ({
  businessId,
  policies,
  toBusiness,
}: IframeParams): string => {
  let destination = `/insurtech/iframe/load`;
  if (toBusiness) {
    destination = `${destination}?toBusiness=true`;
  } else if (businessId) {
    destination = `${destination}?businessId=${businessId}`;
    if (policies && policies.length > 0) {
      destination =
        destination + policies.map((policy) => `&policy=${policy}`).join('');
    }
  }
  return destination;
};

interface ThanksForCompletingContactInformationProps
  extends RouteComponentProps {}
export const ThanksForCompletingContactInformation =
  ({}: ThanksForCompletingContactInformationProps) => {
    const { businessId, policies, toBusiness } = useIframeQueryParams();
    const navigate = useNavigate();

    // set where we're navigating to
    useEffect(() => {
      if (toBusiness) {
        setNavigationDestination(`/insurtech/quote/type/commercial`);
      } else if (businessId) {
        setNavigationDestination(generateBusinessLinkUrl(businessId, policies));
      }
    }, [businessId, policies, toBusiness]);

    const [navigationDestination, setNavigationDestination] =
      useState<string>('/insurtech/quote');
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100vh',
        }}
        data-testid='iframe-thanks-page'
      >
        <div
          style={{
            paddingTop: '15vh',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyItems: 'center',
            maxWidth: '30rem',
            gap: '32px',
          }}
        >
          <Typography variant='h2' sx={{ textAlign: 'center' }}>
            Thanks!
          </Typography>
          <Typography variant='body1'>
            A broker will be in contact with you shortly. If you'd like, you can
            speed up the process by filling out the rest of the application
            below.
          </Typography>
          <div
            id='button-container'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                const imperativeNavigateElement = document.getElementById(
                  IMPERATIVE_NAVIGATE_ID,
                );
                if (imperativeNavigateElement) {
                  imperativeNavigateElement.click();
                } else {
                  Bugsnag.notify(
                    new Error(
                      `Unable to find imperative navigation element with id ${IMPERATIVE_NAVIGATE_ID} on the iframe thanks page. Navigating manually.`,
                    ),
                  );
                  navigate(navigationDestination);
                }
              }}
            >
              Continue with Application
            </Button>
            <ImperativeNavigateLink
              href={navigationDestination}
              target='_blank'
            />
          </div>
        </div>
      </div>
    );
  };
