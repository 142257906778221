import theme from './EngineerInsuranceTheme';
import logo from './assets/MainLogo.png';
import squareLogo from './assets/EngineersInsuranceSquareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import EngineeringIcon from '@mui/icons-material/Engineering';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const businessIcons = (businessName: string) => {
  switch (businessName) {
    case 'engineers':
      return <EngineeringIcon color='inherit' fontSize='large' />;

    default:
      return <EngineeringIcon color='inherit' fontSize='large' />;
  }
};

const businessFilter = (business: $TSFixMe) => {
  const allowedBusinessNames = ['engineers'];
  if (
    process.env.NODE_ENV !== 'production' ||
    window.location.origin === 'https://staging-calefy.fuseinsurance.ca'
  ) {
    allowedBusinessNames.push('testing_business');
  }
  return allowedBusinessNames.includes(business.internalName);
};

// Config
const settings = new WebsiteSettings({
  slug: 'engineersinsurance',
  host: 'secure.engineersinsurance.ca',
  companyName: 'Engineer Insurance',
  hours: '8:30am - 5:30pm',
  phone: '1-844-4CA-LEFY (422-5339)',
  email: 'info@calefy.ca',
  chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo: logo,
  businessFilter: businessFilter,
  tileIcons: businessIcons,
  title: 'Engineer Insurance: Engineer Insurance Canada',
  favicon,
  squareLogo,
});

export default settings;
