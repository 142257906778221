import { graphql } from '../gql';

export const DASHBOARD_FORM_DATA = graphql(`
  query GetFormData($token: String!) {
    formDataQuery(token: $token) {
      totalCount
    }
  }
`);

export const DASHBOARD_TOTAL_QUOTES_TODAY = graphql(`
  query GetDailyQuotesData($token: String!) {
    dailyQuotesData(token: $token) {
      quotesToday
      dailyQuotes
      weeklyQuotePercentDiff
    }
  }
`);

export const DASHBOARD_TOTAL_POLICIES_TODAY = graphql(`
  query GetDailyPoliciesData($token: String!) {
    dailyPoliciesData(token: $token) {
      policiesToday
      dailyPolicies
      weeklyPolicyPercentDiff
    }
  }
`);

export const DASHBOARD_TOTAL_QUOTES = graphql(`
  query GetTotalQuotesData($token: String!) {
    totalQuotesData(token: $token) {
      totalQuotes
      monthlyQuotes {
        year
        data {
          name
          data
        }
      }
      monthlyQuotePercentDiff
    }
  }
`);

export const DASHBOARD_TOP_BUSINESSES = graphql(`
  query GetTopBusinessesData($token: String!) {
    topBusinessesData(token: $token) {
      topBusinesses {
        name
        totalCount
      }
    }
  }
`);

export const DASHBOARD_MONTHLY_QUOTES = graphql(`
  query GetMonthlyQuotes($token: String!) {
    monthlyQuotesData(token: $token) {
      monthlyQuotes {
        year
        data {
          name
          data
        }
      }
    }
  }
`);

export const DASHBOARD_LOCATION_DATA = graphql(`
  query GetLocationData($token: String!) {
    locationData(token: $token) {
      geoLocations {
        lat
        long
      }
    }
  }
`);

export const DASHBOARD_SUBMISSION_TIMES = graphql(`
  query GetSubmissionTime($token: String!) {
    submissionTimesData(token: $token) {
      submissionTimes
    }
  }
`);

export const DASHBOARD_NEW_QUOTES = graphql(`
  query GetNewQuotes($token: String!) {
    newQuotesData(token: $token) {
      newQuotes {
        id
        uniqueID
        friendlyId
        status
        businessLine {
          id
          displayName
        }
        structuredData {
          businessLegalName
          businessName
          businessTradeName
          contactName
        }
      }
    }
  }
`);
