import {
  createTheme,
  responsiveFontSizes,
  adaptV4Theme,
} from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

// const FONT_PRIMARY = 'Spartan, sans-serif';
// const FONT_SECONDARY = 'Muli, sans-serif';
const FONT_PRIMARY = 'Open Sans, sans-serif';
const FONT_SECONDARY = 'Montserrat, sans-serif';

const palette = {
  primary: {
    main: '#F15C2A',
  },
  secondary: {
    main: '#ff8e57',
  },
  success: {
    lighter: '#D5FBD1',
    light: '#74EA7F',
    main: '#1EBC4B',
    dark: '#0F8748',
    darker: '#055A3E',
  },
  error: {
    lighter: '#FDE6D3',
    light: '#F69E7C',
    main: '#E23E28',
    dark: '#A2141A',
    darker: '#6C071B',
  },
  info: {
    lighter: '#CAF0FD',
    light: '#62C0F5',
    main: '#0077E0',
    dark: '#0044A1',
    darker: '#00226B',
  },
  warning: {
    lighter: '#FBF2C9',
    light: '#EBC95E',
    main: '#BF8500',
    dark: '#895700',
    darker: '#5B3400',
  },
};

const theme = responsiveFontSizes(
  createTheme(
    adaptV4Theme({
      palette,
      typography: {
        fontFamily: FONT_PRIMARY,
        h1: {
          fontFamily: FONT_SECONDARY,
        },
        h2: {
          fontFamily: FONT_SECONDARY,
        },
        h3: {
          fontFamily: FONT_SECONDARY,
        },
        h4: {
          fontFamily: FONT_SECONDARY,
        },
        h5: {
          fontFamily: FONT_SECONDARY,
        },
        h6: {
          fontFamily: FONT_SECONDARY,
        },
        subtitle1: {
          fontFamily: FONT_PRIMARY,
          fontWeight: 600,
        },
        subtitle2: {
          fontFamily: FONT_PRIMARY,
          fontWeight: 600,
        },
        body1: {},
        body2: {},
        button: {},
        caption: {},
        overline: {},
      },
      // @ts-expect-error
      customShadows: {
        primary: `0 8px 16px 0 ${alpha(palette.primary.main, 0.24)}`,
        secondary: `0 8px 16px 0 ${alpha(palette.secondary.main, 0.24)}`,
        info: `0 8px 16px 0 ${alpha(palette.info.main, 0.24)}`,
        success: `0 8px 16px 0 ${alpha(palette.success.main, 0.24)}`,
        warning: `0 8px 16px 0 ${alpha(palette.warning.main, 0.24)}`,
        error: `0 8px 16px 0 ${alpha(palette.error.main, 0.24)}`,
      },
    }),
  ),
);

export default theme;
