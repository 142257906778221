// material
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
// hooks
import useSettings from '../../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { RouteComponentProps } from '@reach/router';
import { QuestionsTable } from './QuestionsTable';

interface QuestionsTableProps extends RouteComponentProps {}
export const QuestionsTablePage = (_props: QuestionsTableProps) => {
  const { themeStretch } = useSettings();
  return (
    <Page title='Questions | Manager'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Questions'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
            { name: 'Questions' },
          ]}
        />
        <Card>
          <QuestionsTable />
        </Card>
      </Container>
    </Page>
  );
};
