import { cloneDeep } from 'lodash';
import { BusinessType, Policy } from '../../../Typescript';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { QuoteWizardAnsweredForm } from './QuoteWizardAnsweredForm';
import { QuoteWizardQuestionInstance } from './QuoteWizardQuestionInstance';
import { UnifiedCompletedForm } from '../../../Typescript/classes';

interface QuoteWizardFormInput {
  id: string;
  questionInstances: Array<QuoteWizardQuestionInstance>;
  qualifyingQuestion: QuoteWizardQuestionInstance | null;
  businessLine: BusinessType;
  policy: null | Policy;
  [k: string]: $TSFixMe;
}
export class QuoteWizardForm {
  id: string;
  questionInstances: Array<QuoteWizardQuestionInstance>;
  qualifyingQuestion: QuoteWizardQuestionInstance | null;
  businessLine: BusinessType;
  policy: null | Policy;
  [k: string]: $TSFixMe;

  constructor(input: QuoteWizardFormInput) {
    const {
      id,
      questionInstances,
      qualifyingQuestion,
      businessLine,
      policy,
      ...rest
    } = input;

    this.id = id;
    this.questionInstances = questionInstances;
    this.qualifyingQuestion = qualifyingQuestion;
    this.businessLine = businessLine || null;
    this.policy = policy;

    for (let [k, v] of Object.entries(rest)) {
      this[k] = v;
    }
  }

  toDefaultQuoteWizardAnsweredForm() {
    return new QuoteWizardAnsweredForm({
      form: this,
      answers: [],
      inProgress: false,
      complete: true,
    });
  }

  toDefaultUnifiedCompletedForm() {
    return new UnifiedCompletedForm({
      form: this,
      answers: [],
      inProgress: false,
      complete: true,
    });
  }

  /**
   * Return a copy, but witih some features changes
   */
  copyWithAmendments(amendments: Partial<QuoteWizardFormInput>) {
    const input = Object.entries(this).reduce((acc: $TSFixMe, [k, v]) => {
      //console.log('About to process key, value pair', k, v, 'for', this);
      switch (k) {
        case 'questionInstances':
          // @ts-expect-error
          acc[k] = v.map((q) => q.copy());
          break;
        case 'qualifyingQuestion':
          acc[k] = v ? v.copy() : v;
          break;
        default:
          acc[k] = cloneDeep(v);
      }
      return acc;
    }, {});
    const copied = new QuoteWizardForm({ ...input, ...amendments });
    return copied;
  }

  /**
   * Return a copy of this QuoteWizardForm
   */
  copy() {
    return this.copyWithAmendments({});
  }

  /**
   * Determines whether another QuoteWizardForm matches this one by policy
   */
  matchByPolicy(other: QuoteWizardForm | Policy | null) {
    const otherPolicy =
      other === null ? null : 'policy' in other ? other.policy : other;
    if (this.policy === null && otherPolicy === null) {
      return true;
    }
    if ((!this.policy && otherPolicy) || (this.policy && !otherPolicy)) {
      return false;
    }
    // @ts-expect-error
    return this.policy.id === otherPolicy.id;
  }

  /**
   * Determines whether another QuoteWizardForm matches this one by businessLine
   */
  matchByBusinessLine(other: QuoteWizardForm) {
    return this.businessLine.id === other.businessLine.id;
  }

  /**
   * Determines whether another QuoteWizardForm matches this one by both businessLine and policy
   */
  matchByPolicyAndBusiness(other: QuoteWizardForm) {
    return this.matchByPolicy(other) && this.matchByBusinessLine(other);
  }

  /**
   * This filters out questions based on the a passed-in callback function. Mainly used when dealing with a renewed quote.
   */
  filterQuestions(
    cb: (
      question: QuoteWizardQuestionInstance,
      index: number,
      questions: QuoteWizardForm['questionInstances'],
    ) => boolean,
  ): QuoteWizardForm {
    this.questionInstances = this.questionInstances.filter(cb);
    return this;
  }

  /**
   * Determines whether there are any questions on this form which are askOnRenewal
   */
  hasRenewalQuestions() {
    return this.questionInstances.some((q) => q.askOnRenewal);
  }

  /**
   * Get the display name of the form (here for consistency)
   */
  getName() {
    return this.policy === null
      ? 'General Information'
      : this.policy.displayName;
  }

  /**
   * Get the api names of the leaf questions (ones with no subquestions)
   */
  getLeafQuestions(): Array<QuoteWizardQuestionInstance> {
    return this.questionInstances.reduce(
      (acc: Array<QuoteWizardQuestionInstance>, q) => {
        return [...acc, ...q.getLeafQuestions()];
      },
      [],
    );
  }

  /**
   * Determine if something is a QuoteWizardForm
   * @param other - The thing to test for QuoteWizardFormhood
   * @return - Whether the parameter is a QuoteWizardForm
   */
  static isQuoteWizardForm(x: unknown): x is QuoteWizardForm {
    return x instanceof QuoteWizardForm;
  }
}
