import { useFormApi } from 'informed';
import {
  Slider,
  LogarithmicSlider,
  Select,
  NumberInput,
} from '@calefy-inc/informedMaterial';

import LinearScaleRoundedIcon from '@mui/icons-material/LinearScaleRounded';

import { Component } from './classes';

// types and classes
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';
import {
  QuoteComponentProps,
  ProgramBuilderComponentProps,
  QuoteComponentValidationFunctionReturn,
} from './types';

// Slider Max select options
const sliderOptions = [
  { value: 100, label: '100' },
  { value: 1000, label: '1,000' },
  { value: 10000, label: '10,000' },
  { value: 100000, label: '100,000' },
  { value: 1000000, label: '1,000,000' },
  { value: 10000000, label: '10,000,000' },
  { value: 100000000, label: '100,000,000' },
];

// list of slider datatypes
const sliderDatatypes = [
  { value: '$', label: '$' },
  { value: '%', label: '%' },
  { value: 'ft²', label: 'ft²' },
  { value: 'acres', label: 'acres' },
];

function ProgramBuilderComponent({
  classes = {},
}: ProgramBuilderComponentProps) {
  const formApi = useFormApi();

  return (
    <>
      <Select
        field='props_blob_dataType'
        label='Data Type'
        inputId='props_blob_dataType'
        isClearable={true}
        className={classes.selectField}
        classes={classes}
        onChange={() => formApi.setError('props_blob_dataType', undefined)}
        placeholder="What is the prefix or suffix of the slider i.e '$'"
        options={sliderDatatypes}
        variant='standard'
      />
      <Select
        field='props_blob_slider_max'
        label="Slider's maximum value..."
        inputId='props_blob_slider_max'
        isClearable={true}
        required
        className={classes.selectField}
        classes={classes}
        placeholder='What is the upper limit of the slider? (Lower limit is zero)'
        options={sliderOptions}
        variant='standard'
      />
      <NumberInput field='defaultValue' label='Default Value' />
    </>
  );
}

const QuoteComponent = ({
  questionInstance,
  classes = {},
  ...props
}: QuoteComponentProps) => {
  let min = 0;
  let prefix = null;
  let suffix = null;

  const { slider_max = 10000000, dataType = null } = questionInstance.propsBlob;

  // Assign datatype to prefix or suffix, only need special case if value is a prefix
  switch (dataType) {
    case '$':
      prefix = dataType;
      break;
    default:
      suffix = dataType;
  }

  const sliderProps = {
    label: questionInstance.label,
    field: questionInstance.generateFieldName(),
    id: questionInstance.id,
    helperText: questionInstance.helpText,
    className: classes.slider,
    classes: classes,
    required: questionInstance.required,
    max: slider_max,
    min: min,
    prefix: prefix,
    suffix: suffix,
    ...props,
  };

  if (slider_max >= 1e3) {
    return (
      <LogarithmicSlider
        {...sliderProps}
        min={sliderProps.max >= 1e6 ? 1e3 : 0}
      />
    );
  } else {
    return <Slider {...sliderProps} />;
  }
};

function validateSlider(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const errorMessages = [];
  const { propsBlob } = questionInstance;
  if (!propsBlob) {
    errorMessages.push(
      `Slider question with name "${questionInstance.generateDisplayName()}" is missing some required attributes.`,
    );
  } else {
    const { slider_max } = propsBlob;
    if (!slider_max) {
      console.error(
        'Found invalid slider missing slider_max propsBlob:',
        questionInstance,
      );
      errorMessages.push(
        `Slider question with name "${questionInstance.generateDisplayName()}" is missing a maximum value`,
      );
    }
    if (questionInstance.defaultValue) {
      try {
        const defaultAsNumber = Number(questionInstance.defaultValue);
        if (defaultAsNumber < 0) {
          errorMessages.push(
            `Default value for slider with API name ${questionInstance.apiName} (${questionInstance.defaultValue}) is less than 0`,
          );
        } else if (defaultAsNumber > slider_max) {
          errorMessages.push(
            `Default value for slider with API name ${questionInstance.apiName} (${questionInstance.defaultValue}) is greater than the maximum (${slider_max})`,
          );
        }
      } catch (e) {
        errorMessages.push(
          `Unable to convert default value for slider with API name ${questionInstance.apiName} ${questionInstance.defaultValue} to a number.`,
        );
      }
    }
  }
  return errorMessages;
}

const IconComponent = () => {
  return <LinearScaleRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'slider',
    typeLabel: 'Slider',
    quoteComponent: QuoteComponent,
    dataType: 'number',
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateSlider,
    icon: IconComponent,
  });
}

export { QuoteComponent, ProgramBuilderComponent };
