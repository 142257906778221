import { Stack, Box, Divider, Typography } from '@mui/material';
import {
  SIGNATURE_DISCLAIMER_TOPLINE,
  SIGNATURE_DISCLAIMER_BODY,
} from '../../../QuoteWizard/QuoteReview/Signature/constants';
import { format } from 'date-fns';
import { Pagebreak } from '../utils';

// classes and types
import type { QuotePdfComponentPageProps } from './types';

export const SignaturePage = ({ quote }: QuotePdfComponentPageProps) => {
  const { signature } = quote;
  if (!signature || !signature.signatureData) {
    return null;
  }
  const { signatureData, signatureTime } = signature;

  return (
    <>
      {quote.additionalInformation ? (
        <>
          <Box sx={{ mb: 6 }}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>
              Additional information or comments
            </Typography>
            <Typography variant='body2'>
              {quote.additionalInformation}
            </Typography>
          </Box>
        </>
      ) : null}
      <Box height={500}>
        <Stack
          height={'100%'}
          direction={'column'}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography
              gutterBottom
              paragraph
              color='textSecondary'
              variant='subtitle2'
            >
              {SIGNATURE_DISCLAIMER_TOPLINE}
            </Typography>
            <Typography paragraph color='textSecondary' variant='subtitle2'>
              {SIGNATURE_DISCLAIMER_BODY}
            </Typography>
          </Box>
          <Box>
            <Stack direction='row' justifyContent='flex-end'>
              <Stack direction='column' alignItems='center'>
                <img
                  src={signatureData}
                  height={100}
                  width={300}
                  alt='Signature'
                />
                <Typography>
                  Signed {format(new Date(signatureTime), 'MMMM d, yyyy')}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ mt: 2 }} />
          </Box>
        </Stack>
      </Box>
      <Pagebreak />
    </>
  );
};
