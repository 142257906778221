import { graphql } from '../../gql';

export const GET_INSURER_QUOTE = graphql(`
  query getInsurerQuote($quoteUuid: String!, $insurerQuoteIdentifier: String!) {
    getInsurerQuote(
      quoteUuid: $quoteUuid
      insurerQuoteIdentifier: $insurerQuoteIdentifier
    ) {
      id
      insurerQuoteIdentifier
      llmResponseBlob
      pdfFilePath
      isValid
    }
  }
`);
