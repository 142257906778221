import { graphql } from '../../gql';

export const ALL_ORGANIZATIONS = graphql(`
  query allOrganization($token: String!) {
    allOrganizations(token: $token) {
      id
      name
      category
    }
  }
`);
