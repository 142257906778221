import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Tooltip } from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { LoadingIconButton } from './LoadingIconButton';
import { SEND_RENEWAL_EMAIL } from '../../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';

// types and classes
import type { Quote } from '../../../../../../Typescript/backend/classes';

interface SendRenewalEmailButtonProps {
  quote: Quote;
  setStatus: Dispatch<SetStateAction<Quote['status'] | null>>;
  isArchived: boolean;
}

export const SendRenewalEmailButton = ({
  quote,
  setStatus,
  isArchived,
}: SendRenewalEmailButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const [renewalErrorPresent, setRenewalErrorPresent] =
    useState<boolean>(false);

  const [
    sendRenewalEmail,
    {
      loading: renewalEmailLoading,
      error: renewalEmailError,
      data: renewalEmailData,
    },
  ] = useMutation(SEND_RENEWAL_EMAIL, { fetchPolicy: 'no-cache' });

  // deal with the sendRenewalEmail data
  useEffect(() => {
    if (renewalEmailData?.sendRenewalEmail?.ok) {
      enqueueSnackbar(`Renewal email successfully sent!`, {
        variant: 'success',
      });
      setRenewalErrorPresent(false);
      setStatus('RENEWAL_EMAIL_SENT');
    }
  }, [renewalEmailData, enqueueSnackbar, setStatus]);

  useEffect(() => {
    if (
      renewalEmailError ||
      (renewalEmailData?.sendRenewalEmail &&
        !renewalEmailData?.sendRenewalEmail?.ok)
    ) {
      setRenewalErrorPresent(true);
    }
  }, [renewalEmailData, renewalEmailError]);

  // sort out renewal errors
  useEffect(() => {
    if (renewalErrorPresent) {
      enqueueSnackbar(
        `Error sending renewal email. Support has been contacted.${
          renewalEmailError ? ' ' + renewalEmailError : ''
        }`,
        { variant: 'error' },
      );
    }
  }, [renewalErrorPresent, enqueueSnackbar, renewalEmailError]);
  return (
    <Tooltip
      title={
        quote.status === 'RENEWAL_EMAIL_SENT' ||
        renewalEmailData?.sendRenewalEmail?.ok
          ? 'Renewal email sent'
          : quote.status === 'RENEWAL_IN_PROGRESS'
          ? 'This application is currently being renewed'
          : !quote.isComplete()
          ? 'Only applications which have been completed can be renewed'
          : 'Send a renewal email'
      }
    >
      <span>
        <LoadingIconButton
          data-testid='renewal_email_button'
          size='small'
          aria-label='send renewal email'
          onClick={() =>
            sendRenewalEmail({
              variables: {
                token,
                uuid: quote.uniqueId,
              },
            })
          }
          loadingPosition='center'
          loading={renewalEmailLoading}
          disabled={
            !!(
              !token ||
              !quote.isComplete() ||
              (renewalEmailData && renewalEmailData.sendRenewalEmail) ||
              quote.status === 'RENEWAL_EMAIL_SENT' ||
              isArchived
            )
          }
        >
          <EmailRoundedIcon fontSize='small' />
        </LoadingIconButton>
      </span>
    </Tooltip>
  );
};
