import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';

import { handleErrors } from '../../../ErrorHandling';
import { OptaError } from '../errors';
import { useAuth } from '@calefy-inc/authentication';
import { GET_ALL_OPTA_DATA } from '../../../../../queries';

// types and classes
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../../../util';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  optaNotFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  optaNotFoundButton: {
    marginTop: theme.spacing(2),
  },
}));

interface OptaDataNotLoadedPageProps {
  details: $TSFixMe;
  setLocationData: $TSFixMe;
  loadQuotePdf: $TSFixMe;
  isArchived: $TSFixMe;
}

export const OptaDataNotLoadedPage = ({
  details,
  setLocationData,
  loadQuotePdf,
  isArchived,
}: OptaDataNotLoadedPageProps) => {
  const classes = useStyles();
  const { token } = useAuth();
  const [getOptaData, { loading, error, data }] = useMutation(
    GET_ALL_OPTA_DATA,
    {
      variables: {
        answer: details.general.id,
        token,
      },
    },
  );

  useEffect(() => {
    if (data?.getAllOptaData) {
      (async () => {
        if (!details || !details.general || !details.general.id) {
          return;
        }
        const selectedId = details.general.id;
        const optaDetails = data?.getAllOptaData?.ok
          ? data.getAllOptaData.data
          : {};
        // @ts-expect-error
        setLocationData((locationData) => {
          const selectedLocationDetails = {
            ...locationData[selectedId],
          };
          selectedLocationDetails.opta = optaDetails;

          const amendedLocationData = Object.assign({}, locationData, {
            [selectedId]: selectedLocationDetails,
          });
          return amendedLocationData;
        });
        try {
          await loadQuotePdf();
        } catch (e) {
          Bugsnag.notify(errorify(e));
          console.error('Error getting PDF:', e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return handleErrors(error);
  }
  if (data && !data?.getAllOptaData?.ok) {
    return <OptaError />;
  }

  return (
    <div className={classes.optaNotFoundContainer}>
      {isArchived ? (
        <Typography variant='subtitle1'>No OPTA data available</Typography>
      ) : (
        <>
          <Typography variant='subtitle1'>OPTA Data Not Yet Loaded</Typography>
          <LoadingButton
            variant='contained'
            size='small'
            color='primary'
            onClick={() => getOptaData()}
            disabled={loading || error}
            className={classes.optaNotFoundButton}
            loading={loading}
          >
            Get OPTA Data
          </LoadingButton>
        </>
      )}
    </div>
  );
};
