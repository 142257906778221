import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
// material
import { Box } from '@mui/material';

// types and classes
import type { ReactNode } from 'react';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

interface PageProps {
  children: ReactNode;
  title: string;
  [k: string]: $TSFixMe;
}
const Page = forwardRef(
  ({ children, title = '', ...other }: PageProps, ref: $TSFixMe) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  ),
);

export default Page;
