function createRowData(
  tempTableID: number,
  quoteIssuer: string,
  date: string,
  premium: string,
  limit: string,
  quoteIdentifier: string,
  isValid: boolean,
): InsurerQuoteRowData {
  return {
    tempTableID,
    quoteIssuer,
    date,
    premium,
    limit,
    quoteIdentifier,
    isValid,
  };
}

function createComparisonHeadCells(columnLabels: string[]) {
  const headCells = ['Description'];
  columnLabels.forEach((columnLabel) => headCells.push(columnLabel));
  return headCells;
}

interface InsurerQuoteRowData {
  tempTableID: number;
  date: string;
  limit: string;
  premium: string;
  quoteIssuer: string;
  quoteIdentifier: string;
  isValid: boolean;
}

interface HeadCell {
  tempTableID: keyof InsurerQuoteRowData | 'delete' | 'view';
  label: string;
  numeric: boolean;
  icon: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    tempTableID: 'quoteIssuer',
    numeric: false,
    label: 'Quote Provider',
    icon: false,
  },
  {
    tempTableID: 'date',
    numeric: true,
    label: 'Date',
    icon: false,
  },
  {
    tempTableID: 'premium',
    numeric: true,
    label: 'Premium',
    icon: false,
  },
  {
    tempTableID: 'limit',
    numeric: true,
    label: 'Limit',
    icon: false,
  },
  {
    tempTableID: 'quoteIdentifier',
    numeric: true,
    label: 'Quote Identifier',
    icon: false,
  },
  {
    tempTableID: 'delete',
    numeric: false,
    label: 'Delete',
    icon: true,
  },
  {
    tempTableID: 'view',
    numeric: false,
    label: 'View Quote',
    icon: true,
  },
];

interface TransformedData {
  description: string;
  [key: string]: string;
}

export { headCells, createRowData, createComparisonHeadCells };
export type { InsurerQuoteRowData, TransformedData };
