import { graphql } from '../gql';

export const GET_ALL_OPTA_DATA = graphql(`
  mutation getAllOptaData($answer: ID!, $token: String!) {
    getAllOptaData(answer: $answer, token: $token) {
      ok
      data {
        optaFusData {
          ...OptaFusDataFields
        }
        optaFloodscoreData {
          ...OptaFloodScoreDataFields
        }
        optaIclarifyCommercialConstructionData {
          ...OptaIclarifyCommercialConstructionDataFields
        }
        optaCommercialPerilScoreData {
          ...OptaCommercialPerilScoreDataFields
        }
      }
    }
  }
`);
