import { $TSFixMe } from '@calefy-inc/utilityTypes';

export const generateAddressString = (location: $TSFixMe) => {
  const { streetAddress, city, province, postalCode } = location.general;
  return [streetAddress, city, province, postalCode]
    .filter((elem) => elem !== undefined && elem !== 'N/A')
    .join(' ');
};

export function formatLat(lat: number) {
  return `${Math.abs(lat)} ${lat < 0 ? 'S' : 'N'}`;
}

export function formatLng(lng: number) {
  return `${Math.abs(lng)} ${lng < 0 ? 'W' : 'E'}`;
}

export const generateColourInRedGreenFromProportion = (prop: number) => {
  if (prop < 0.5) {
    return [255, (255 * prop) / 0.5, 0];
  } else {
    return [255 * (1 - (prop - 0.5) / 0.5), 255, 0];
  }
};

export const generateColourStringInRedGreenFromProportion = (prop: number) => {
  const [red, green, blue] = generateColourInRedGreenFromProportion(prop);
  return `rgb(${red}, ${green}, ${blue})`;
};

export const generateColourStringInRedGreenFromProportionWithAlpha = (
  prop: number,
  alpha: number,
) => {
  const [red, green, blue] = generateColourInRedGreenFromProportion(prop);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

/**
 * Returns the proportion from a value in the range [min, max]
 */
export const generateProportionFromValueInRange = (
  value: number,
  min: number,
  max: number,
) => {
  return (value - min) / (max - min);
};

export const lossCostScoreProportion = (LossScore: number) =>
  generateProportionFromValueInRange(LossScore, 1, 100);
