import { TextField, Checkbox } from '@calefy-inc/informedMaterial';
import { InformedAutocompleteGoogleLocation } from './InformedAutocompleteGoogleLocation';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { withStyles } from '@mui/styles';
import { ArrayField, useFormApi } from 'informed';
import RenderedQuestionInstance from './RenderedQuestionInstance';
import { v4 as createId } from 'uuid';
import { SameAsMailingAddressButton } from './SameAsMailingAddressButton';
import CloseIcon from '@mui/icons-material/Close';
// @ts-expect-error
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { formatAsReadableList } from './utility';
import { AddItemButton } from './common';
import { VagoCopyLocationCoveragesButton } from './organizationSpecific/vago';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language, LanguageAwareString } from '../../../Typescript/classes';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';
import {
  QuoteComponentProps,
  ProgramBuilderComponentProps,
  QuoteComponentValidationFunctionReturn,
} from './types';
import { Component } from './classes';

export const requiredComponents = [
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'address',
    displayNames: [new LanguageAwareString('address', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Address', Language.english)],
    dataType: 'string',
    propsBlob: {},
    subQuestions: [],
  }),
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'city',
    displayNames: [new LanguageAwareString('city', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('City', Language.english)],
    dataType: 'string',
    propsBlob: {},
    subQuestions: [],
  }),
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'province',
    displayNames: [new LanguageAwareString('province', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Province', Language.english)],
    dataType: 'string',
    propsBlob: {},
    subQuestions: [],
  }),
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'postal',
    displayNames: [new LanguageAwareString('postal', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Postal Code', Language.english)],
    dataType: 'string',
    propsBlob: {},
    subQuestions: [],
  }),
];

const ProgramBuilderComponent = ({
  classes = {},
  questionInstance,
}: ProgramBuilderComponentProps) => {
  const propsBlob =
    questionInstance && questionInstance.propsBlob
      ? questionInstance.propsBlob
      : {};
  const repeatable = propsBlob.repeatable ? true : false;

  return (
    <>
      <Checkbox
        classes={classes}
        className={classes.checkbox}
        field='props_blob_repeatable'
        label='Repeatable set'
        value={repeatable}
      />
    </>
  );
};

const QuoteComponentWithoutStyles = function ({
  questionInstance,
  blockAddSubquestions,
  classes = {},
  ...props
}: QuoteComponentProps) {
  /* const propsBlob = */
  /*   questionInstance && questionInstance.propsBlob */
  /*     ? JSON.parse(questionInstance.propsBlob) */
  /*     : {}; */
  const { repeatable } = questionInstance.propsBlob;

  //Render the top level question, then call RenderQuestionInstance on all the other questions so they look nice
  // Define a callback function that fires on clicking the "Add new" button
  // The "Add new" button should add a new set of fieldsets to the form's state using the Informed field API

  /* {
    form : {
      fieldset: [
        [fieldset1:
          q1: a1
          q2: a2],
        [fieldset2: //so this is what we'd add
          q1: a1:
          q2: a2]
      ]

    }
  }*/
  // const { add, fields } = useArrayField({ field: questionInstance.name });
  // debugger;

  let otherSubQuestions = questionInstance.subQuestions.filter(
    (ele) => !requiredComponents.map((q) => q.apiName).includes(ele.apiName),
  );

  const formApi = useFormApi();

  return (
    <div className={classes.addressContainer}>
      {/* <h4>{questionInstance.label}</h4> */}
      <InputLabel
        className={classes.inputlabel}
        htmlFor={questionInstance.label}
      >
        {questionInstance.label}
      </InputLabel>
      <ArrayField field={questionInstance.generateFieldName()}>
        {(arrayFieldApi) => {
          // debugger;
          if (arrayFieldApi.fields.length === 0) {
            arrayFieldApi.add();
            // arrayFieldApi.add();
          }
          return (
            <>
              {/* @ts-expect-error */}
              <ArrayField.Items>
                {(arrayFieldItemsApi: $TSFixMe) => {
                  let address = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'address',
                  );
                  if (!address) {
                    throw new Error(
                      `Unable to find required field "address" for question ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}!`,
                    );
                  }
                  let city = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'city',
                  );
                  if (!city) {
                    throw new Error(
                      `Unable to find required field "city" for question ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}!`,
                    );
                  }
                  let province = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'province',
                  );
                  if (!province) {
                    throw new Error(
                      `Unable to find required field "province" for question ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}!`,
                    );
                  }
                  let postal = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'postal',
                  );
                  if (!postal) {
                    throw new Error(
                      `Unable to find required field "postal" for question ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}!`,
                    );
                  }
                  // now set the field prefix on all of these
                  [address, city, province, postal].forEach((ele) => {
                    ele.fieldPrefix = arrayFieldItemsApi.field;
                  });

                  return (
                    <Grid>
                      {arrayFieldApi.fields.length > 1 ? (
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          className={classes.center}
                        >
                          Address {arrayFieldItemsApi.index + 1}
                        </Typography>
                      ) : null}
                      <div className={classes.addressHeader}>
                        <div className={classes.sameAsMailingAddressButton}>
                          <SameAsMailingAddressButton
                            fillFields={{
                              address: address.generateFieldName(),
                              city: city.generateFieldName(),
                              province: province.generateFieldName(),
                              postalCode: postal.generateFieldName(),
                            }}
                            questionInstanceApiName={questionInstance.apiName}
                          />
                        </div>
                        <div className={classes.removeAddress}>
                          {arrayFieldApi.fields.length > 1 ? (
                            <Tooltip title='Remove Address'>
                              <IconButton
                                color='default'
                                size='small'
                                onClick={() => {
                                  arrayFieldItemsApi.remove();
                                }}
                                type='button'
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          ) : undefined}
                        </div>
                      </div>
                      <InformedAutocompleteGoogleLocation
                        label={address.label}
                        field={address.generateFieldName()}
                        // @ts-expect-error
                        id={address.apiName}
                        helperText={address.helpText}
                        required={
                          address.required
                            ? address.required
                            : questionInstance.required
                        }
                        className={classes.textfield}
                        classes={classes}
                        formApi={formApi}
                        fillFields={{
                          address: address.generateFieldName(),
                          city: city.generateFieldName(),
                          province: province.generateFieldName(),
                          postalCode: postal.generateFieldName(),
                        }}
                        {...props}
                      />
                      <TextField
                        label={city.label}
                        field={city.generateFieldName()}
                        id={city.apiName}
                        helperText={city.helpText}
                        required={
                          city.required
                            ? city.required
                            : questionInstance.required
                        }
                        className={classes.textfield}
                        classes={classes}
                        {...props}
                        variant='standard'
                      />
                      <Grid
                        container
                        direction='row'
                        justifyContent='space-between'
                      >
                        <Grid item xs={12} md={5}>
                          <TextField
                            label={province.label}
                            field={province.generateFieldName()}
                            id={province.apiName}
                            helperText={province.helpText}
                            required={
                              province.required
                                ? province.required
                                : questionInstance.required
                            }
                            className={classes.textfield}
                            classes={classes}
                            {...props}
                            variant='standard'
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            label={postal.label}
                            field={postal.generateFieldName()}
                            id={postal.id}
                            helperText={postal.helpText}
                            required={
                              postal.required
                                ? postal.required
                                : questionInstance.required
                            }
                            className={classes.textfield}
                            classes={classes}
                            {...props}
                            variant='standard'
                          />
                        </Grid>
                      </Grid>
                      <VagoCopyLocationCoveragesButton
                        index={arrayFieldItemsApi.index}
                        apiName={questionInstance.apiName}
                        arrayFieldItemApi={arrayFieldItemsApi.arrayFieldItemApi}
                      />
                      {otherSubQuestions &&
                        otherSubQuestions.map((subq) => {
                          subq.fieldPrefix = arrayFieldItemsApi.field;
                          return (
                            <RenderedQuestionInstance
                              questionInstance={subq}
                              key={subq.id}
                              classes={classes}
                            />
                          );
                        })}
                    </Grid>
                  );
                }}
                {/* @ts-expect-error */}
              </ArrayField.Items>
              {repeatable && !blockAddSubquestions ? (
                <AddItemButton
                  text='Add Address'
                  aria-label='Add Address'
                  className={classes.button}
                  type='button'
                  color='primary'
                  onClick={() => {
                    // debugger;
                    arrayFieldApi.add();
                  }}
                  size='large'
                />
              ) : null}
            </>
          );
        }}
      </ArrayField>
    </div>
  );
};

const QuoteComponent = withStyles((_theme: $TSFixMe) => ({
  // addressContainer: {
  //   display: 'grid',
  //   gridGap: '10px',
  //   gridTemplateColumns: '50px 50px',
  //   color: 'red',
  // },
  // address: {
  //   textColor: 'red',
  // },
}))((props: QuoteComponentProps) => <QuoteComponentWithoutStyles {...props} />);

const gatherSubQuestions = (
  _formState: $TSFixMe,
  questionInstance: $TSFixMe,
) => {
  questionInstance = initialSubQuestions(questionInstance);
  return questionInstance;
};

const initialSubQuestions = (
  questionInstance: ProgramBuilderQuestionInstance,
) => {
  // debugger;
  questionInstance.subQuestions = [
    ...requiredComponents,
    ...questionInstance.subQuestions.filter(
      (subq) =>
        !requiredComponents.map((q) => q.apiName).includes(subq.apiName),
    ),
  ];
  questionInstance.children = questionInstance.subQuestions.map((subq) =>
    subq.copy(),
  );
  /* if (questionInstance.subQuestions) { */
  /*   questionInstance.subQuestions = [ */
  /*     ...addressInstances, */
  /*     ...questionInstance.subQuestions, */
  /*   ]; */
  /* } else { */
  /*   questionInstance.subQuestions = [...addressInstances]; */
  /* } */
  return questionInstance;
};

export function validateLocation(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const requiredSubquestionNames = requiredComponents.map((q) => q.apiName);
  const errorMessages = [];
  const missingComponentApiNames = requiredSubquestionNames.filter(
    (apiName) =>
      !questionInstance.subQuestions.find((subq) => subq.apiName === apiName),
  );
  if (missingComponentApiNames.length > 0) {
    const plural = missingComponentApiNames.length > 1;
    errorMessages.push(
      `Location component with name "${questionInstance.generateDisplayName()}" is missing required subquestion${
        plural ? 's' : ''
      } with name${plural ? 's' : ''} ${formatAsReadableList(
        missingComponentApiNames.map((s) => `"${s}"`),
      )}. The safest thing to do is to delete the question and re-create it to avoid problems later.`,
    );
  }
  return errorMessages;
}

const IconComponent = () => {
  return <LocationOnRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'location',
    typeLabel: 'Location',
    gatherSubQuestions: gatherSubQuestions,
    initialSubQuestions: initialSubQuestions,
    // @ts-expect-error
    quoteComponent: QuoteComponent,
    dataType: 'nested',
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateLocation,
    icon: IconComponent,
  });
}

export { QuoteComponent };
