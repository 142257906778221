import { useState } from 'react';
import { DragSource } from 'react-dnd';
// import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from '@apollo/client';
import { questionsDatabase as questionsDatabaseQuery } from '../../../../../../../../queries';
import SearchIcon from '@mui/icons-material/Search';
import Scrollbar from '../../../../../../components/Scrollbar';
import { QuestionPreviewDialog } from './QuestionPreviewModal';
import Brightness1Icon from '@mui/icons-material/Brightness1';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderQuestionInstance } from '../../../../../../../FormManager/classes';

const externalNodeType = 'databaseQuestion';
const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: (componentProps: $TSFixMe) => {
    const newNode = new ProgramBuilderQuestionInstance({
      ...componentProps.node,
    });
    return {
      node: newNode,
    };
  },
};
const externalNodeCollect = (connect: $TSFixMe /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
  // Add props via react-dnd APIs to enable more visual
  // customization of your component
  // isDragging: monitor.isDragging(),
  // didDrop: monitor.didDrop(),
});

interface ExternalNodeBaseComponentProps {
  connectDragSource: $TSFixMe;
  node: ProgramBuilderQuestionInstance;
}
function ExternalNodeBaseComponent({
  connectDragSource,
  node,
}: ExternalNodeBaseComponentProps) {
  const [testModalOpen, setTestModalOpen] = useState<boolean>(false);
  return connectDragSource(
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '4px',
        // paddingBottom: '5px',
      }}
      data-testid={`questions-database-${node.apiName}`}
    >
      <Tooltip
        title={
          <>
            <span>{node.labels[0].value}</span>
            {node.subQuestions.length > 0 ? (
              <>
                <br />
                <span>{`${node.subQuestions.length} subquestion${
                  node.subQuestions.length > 1 ? 's' : ''
                }
                `}</span>
              </>
            ) : null}
            {node.required ? (
              <>
                <br />
                <span>Required</span>
                <Brightness1Icon color='error' sx={{ height: '0.25em' }} />
              </>
            ) : null}
          </>
        }
      >
        <Paper
          sx={{
            padding: '5px 12px',
            borderRadius: '5px',
            border: '1px solid #D9D9D9',
            color: '#595959',
            backgroundColor: `rgb(255, 102, 0, ${
              node.ancillary.parentForms.length / node.maxParents
            })`,
            transitionDuration: '1s',
            overflowWrap: 'anywhere',
            '&:hover': {
              cursor: 'grab',
              //color: 'red',
              transform: 'scale(0.97)',
            },
          }}
          square
          elevation={0}
          onClick={() => {
            setTestModalOpen(true);
          }}
        >
          <Typography style={{ fontSize: '0.9rem' }}>{node.title}</Typography>
          <Typography style={{ fontSize: '0.7rem' }}>
            {node.component}
          </Typography>
        </Paper>
      </Tooltip>
      <QuestionPreviewDialog
        open={testModalOpen}
        node={node}
        onClose={() => {
          setTestModalOpen(false);
        }}
      />
    </div>,
    { dropEffect: 'copy' },
  );
}

const QuestionDatabaseNodeComponent = DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect,
)(ExternalNodeBaseComponent);

interface QuestionsDatabaseProps {
  recursiveTransform: $TSFixMe;
  classes?: { [k: string]: $TSFixMe };
}
export default function QuestionsDatabase({
  recursiveTransform,
  // @ts-expect-error
  classes = {},
}: QuestionsDatabaseProps) {
  const [query, setQuery] = useState<string>('');
  const { loading, data } = useQuery(questionsDatabaseQuery, {
    variables: { query: query },
  });
  const questionsDatabase: Array<ProgramBuilderQuestionInstance> =
    data?.questionsDatabase
      ? data.questionsDatabase.map((rawQuestion) =>
          ProgramBuilderQuestionInstance.generateFromBackendResponse(
            rawQuestion,
          ),
        )
      : [];

  const maxParents = Math.max.apply(
    null,
    questionsDatabase.map((question) => question.ancillary.parentForms.length),
  );

  return (
    <Box>
      <TextField
        value={query}
        placeholder={'Search '}
        onChange={(event) => setQuery(event.target.value)}
        variant='outlined'
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        size='small'
        sx={{ mb: 1 }}
      />
      <Scrollbar
        sx={{
          height: '70vh',
        }}
      >
        {!loading ? (
          <>
            {questionsDatabase.map((ele, idx) => (
              <QuestionDatabaseNodeComponent
                key={idx}
                node={
                  new ProgramBuilderQuestionInstance({
                    ...ele,
                    title: ele.generateDisplayName(),
                    children: ele.subQuestions
                      ? recursiveTransform(ele.subQuestions)
                      : null,
                    expanded: true,
                    maxParents: maxParents,
                  })
                }
              />
            ))}
          </>
        ) : (
          <>
            {Array(15)
              .fill(0)
              .map(() => (
                <Skeleton height='50px' />
              ))}
          </>
        )}
      </Scrollbar>
    </Box>
  );
}
