/**
 * Whether or not a given form has a given policy
 * @param {object} form - the form
 * @param {null | str (convertible to int)} policyId - Either the id of the relevant policy or null (if it came from a general information form)
 */
export const doesFormHavePolicy = (form, policyId) => {
  if (!policyId) {
    if (!form.policy) {
      return true;
    } else {
      return false;
    }
  } else {
    if (!form.policy) {
      return false;
    } else {
      return form.policy.id === policyId;
    }
  }
};

// NB this needs to be curried before use
/**
 * Compares two forms against a target policy, sorting by whether or not their policy matches the target one
 * @param {object} form1 - The first form
 * @param {object} form2 - the second form
 * @param {null | str (convertible to int)} matchingPolicyId - either the id of the relevant policy or null (if it came from a general information form)
 */
export const compareToMatchingPolicy = (form1, form2, matchingPolicyId) => {
  const form1matches = doesFormHavePolicy(form1, matchingPolicyId);
  const form2matches = doesFormHavePolicy(form2, matchingPolicyId);

  if (form1matches && !form2matches) {
    return -1;
  } else if (!form1matches && form2matches) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Given a target policy, generate a function to compare to forms to that policy
 * @param {null | str (convertible to int)} matchingPolicyId - Either the id of the policy to match against or null (if the original form is a general information form)
 * @return {function} - A sorting function which sorts by whether or not the forms have the same policy as the target form
 */
export const generatePolicyComparison = (matchingPolicyId) => (form1, form2) =>
  compareToMatchingPolicy(form1, form2, matchingPolicyId);

/**
 * Given two forms, compare their policies alphabetically (but general information forms get sorted to the beginning)
 * @param {object} form1 - form to compare. Shape: {id: str (convertible to int), policy: null | {id: str (convertible to int), displayName: str}, businessLine: {id: str (convertible to int), displayName: str}}
 * @param {object} form2 - form to compare. Shape: {id: str (convertible to int), policy: null | {id: str (convertible to int), displayName: str}, businessLine: {id: str (convertible to int), displayName: str}}
 * @return {number} - the comparison between the two (based on their policy)
 */
export const comparePoliciesAlphabetically = (form1, form2) => {
  if (!form1.policy && !form2.policy) {
    return 0;
  } else if (!form1.policy && form2.policy) {
    return -1;
  } else if (form1.policy && !form2.policy) {
    return 1;
  } else if (form1.policy && form2.policy) {
    if (form1.policy.displayName < form2.policy.displayName) {
      return -1;
    } else if (form1.policy.displayName === form2.policy.displayName) {
      return 0;
    } else if (form1.policy.displayName > form2.policy.displayName) {
      return 1;
    }
  }

  return 0;
};

/**
 * Given two forms, compare their business' display names alphabetically
 * @param {object} form1 - form to compare. Shape: {id: str (convertible to int), policy: null | {id: str (convertible to int), displayName: str}, businessLine: {id: str (convertible to int), displayName: str}}
 * @param {object} form2 - form to compare. Shape: {id: str (convertible to int), policy: null | {id: str (convertible to int), displayName: str}, businessLine: {id: str (convertible to int), displayName: str}}
 * @return {number} - the comparison between the two (based on their business' name)
 */
export const compareBusinessesAlphabetically = (form1, form2) => {
  if (form1.businessLine.displayName > form2.businessLine.displayName) {
    return 1;
  } else if (form1.businessLine.displayName < form2.businessLine.displayName) {
    return -1;
  } else {
    return 0;
  }
};

/**
 * Given a target form, return a sorting function based on relevance to that form. First by matching the policy, then order by business name, then by policy name (with general information forms at the beginning)
 * @param {object} form - The form to compare to. Shape should be
 * {
 * 	id: str (convertible to int)
 * 	policy: null | {id: str (convertible to int), displayName: str}
 * 	businessLine: {id: str (convertible to int), displayName: str}
 * }
 * @return {function} - A comparison function with signature (form1, form2) => number (same signature as for any comparison function for Array.sort)
 */
export const generateRelevanceComparison = (form) => {
  const comparators = [];

  // first the matching policy one
  comparators.push(
    generatePolicyComparison(form.policy ? form.policy.id : null),
  );
  // now the business (alphabetically)
  comparators.push(compareBusinessesAlphabetically);
  // now the policies (alphabetically)
  comparators.push(comparePoliciesAlphabetically);

  // now generate them
  return (
    (comparators) => (form1, form2) =>
      generateCompoundComparisons(form1, form2, ...comparators)
  )(comparators);
};

/**
 * Given a set of comparison functions, return a combined comparison function which goes through and sorts by each comparison in form (order or precedence is the order in which the comparisons are entered)
 * @param {Any} item1 - the first item to be compared
 * @param {Any} item2 - the second item to be compared
 * @param {[ function ]} comparators - A list of comparison functions, each of which should have the signature (item1, item2) => number
 * @return {number} - the ordering of the items item1, item2 (same rules as for any comparison function passed into Array.sort)
 */
export const generateCompoundComparisons = (item1, item2, ...comparators) => {
  // compare each one in order
  for (let comparator of comparators) {
    const comparisonValue = comparator(item1, item2);
    if (comparisonValue !== 0) {
      return comparisonValue;
    }
  }

  // if none of the comparators give any other indication, call them the same
  return 0;
};

export const formsAreTheSame = (form1, form2) => {
  let samePolicies;
  if ((!form1.policy && form2.policy) || (form1.policy && !form2.policy)) {
    samePolicies = false;
  } else if (!form1.policy && !form2.policy) {
    samePolicies = true;
  } else {
    samePolicies = form1.policy.id === form2.policy.id;
  }

  let sameBusiness = form1.businessLine.id === form2.businessLine.id;

  return samePolicies && sameBusiness;
};
