import initializeComponentRegistry from './ComponentRegistry';
import createSliderRegistry from './Slider';
import createTextfieldRegistry from './TextField';
import createCheckboxRegistry from './Checkbox';
import createRepeatableRegistry from './Repeatable';
import createLogicRegistry from './Logic';
import createYesNoToggleResistry from './YesNoToggle';
import createPhoneFieldRegistry from './PhoneField';
// import createSliderGroupRegistry from './SliderGroup'; //superseded by InternationalRevenue
import createCalendarRegistry from './Calendar';
import createEmailInputRegistry from './EmailInput';
import createTextAreaRegistry from './TextArea';
import createYearPickerRegistry from './YearPicker';
import createLocationRegistry from './Location';
import createInputToggleRegistry from './InputToggle';
import createInternationalRevenue from './InternationalRevenue';
// import createProvincialComponent from './ProvincialComponent';
import createVINComponent from './VINComponent';
import createNumberInputRegistry from './NumberInput';
import { createVagoOtherSubscribersAutocompleteComponentRegistry } from './VagoOtherSubscribersAutocomplete';
import createYearPickerAutocompleteRegistry from './YearPickerAutocomplete';
import { createObligateInputToggleComponentRegistry } from './ObligateInputToggle';
import { createVagoIbcSelectorComponentRegistry } from './VagoIbcSelector';
import { createObligateInputToggleMultipleComponentRegistry } from './ObligateInputToggleMultiple';

const componentRegistry = initializeComponentRegistry();

// Note: The order of these components matter and could break certain portions
// of the application. Always add a new component to the bottom of the registry.
componentRegistry.registerComponent(createTextfieldRegistry());
componentRegistry.registerComponent(createSliderRegistry());
componentRegistry.registerComponent(createCheckboxRegistry());
componentRegistry.registerComponent(createRepeatableRegistry());
componentRegistry.registerComponent(createLogicRegistry());
componentRegistry.registerComponent(createYesNoToggleResistry());
componentRegistry.registerComponent(createPhoneFieldRegistry());
// componentRegistry.registerComponent(createSliderGroupRegistry()); // superseded
componentRegistry.registerComponent(createCalendarRegistry());
componentRegistry.registerComponent(createEmailInputRegistry());
componentRegistry.registerComponent(createTextAreaRegistry());
componentRegistry.registerComponent(createYearPickerRegistry());
componentRegistry.registerComponent(createLocationRegistry());
componentRegistry.registerComponent(createInputToggleRegistry());
componentRegistry.registerComponent(createInternationalRevenue());
componentRegistry.registerComponent(createVINComponent());
componentRegistry.registerComponent(createNumberInputRegistry());
componentRegistry.registerComponent(
  createVagoOtherSubscribersAutocompleteComponentRegistry(),
);
componentRegistry.registerComponent(createYearPickerAutocompleteRegistry());
componentRegistry.registerComponent(
  createObligateInputToggleComponentRegistry(),
);
componentRegistry.registerComponent(createVagoIbcSelectorComponentRegistry());
componentRegistry.registerComponent(
  createObligateInputToggleMultipleComponentRegistry(),
);

export default componentRegistry;
