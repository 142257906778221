// @ts-nocheck
/**
 * This is the AnswerInstance as it appears when you are completing a quote in the QuoteWizard - it is not the same as the backend AnswerInstance
 */
import { cloneDeep } from 'lodash';

import { $TSFixMe } from '../../../Typescript';
import { QuoteWizardQuestionInstance } from './QuoteWizardQuestionInstance';
// import { AnswerInstanceInput } from '../../../Typescript/backend/inputTypes';
import { AnswerInstanceDetails } from '../../../Typescript/backend/classes';
import { UnifiedAnswerInstance } from '../../../Typescript/classes';

interface QuoteWizardAnswerInstanceInput {
  id?: string;
  questionInstance: QuoteWizardQuestionInstance;
  value: any;
  subAnswers?: Array<QuoteWizardAnswerInstance>;
  details?: AnswerInstanceDetails;
  [k: string]: $TSFixMe;
}

export class QuoteWizardAnswerInstance {
  id?: string;
  questionInstance: QuoteWizardQuestionInstance;
  questionTemplate?: $TSFixMe;
  value: any;
  subAnswers: Array<QuoteWizardAnswerInstance>;
  details: AnswerInstanceDetails;
  [k: string]: $TSFixMe;

  constructor(input: QuoteWizardAnswerInstanceInput) {
    //console.log('Constructing new QuoteWizardAnswer from', input);
    const { id, questionInstance, value, subAnswers, details, ...rest } = input;
    this.id = id;
    this.questionInstance = questionInstance;
    this.value = value;
    this.subAnswers = subAnswers ? subAnswers : [];
    this.details = details ? details : new AnswerInstanceDetails();

    for (let [k, v] of Object.entries(rest)) {
      this[k] = v;
    }
  }

  /**
   * Return a copy of this QuoteWizardAnswerInstance
   */
  copy() {
    //console.log('QuoteWizardAnswerInstance: in copy');
    const input = Object.entries(this).reduce((acc, [k, v]) => {
      if (k === 'questionInstance') {
        // @ts-expect-error
        acc[k] = v.copy();
      } else if (k === 'subAnswers') {
        // @ts-expect-error
        acc[k] = v.map((subA) => subA.copy());
      } else if (k === 'details') {
        //console.log('About to copy details from', this, v);
        // @ts-expect-error
        acc[k] = v.copy();
      } else {
        // @ts-expect-error
        acc[k] = cloneDeep(v);
      }
      return acc;
    }, {});
    // @ts-expect-error
    return new QuoteWizardAnswerInstance(input);
  }

  /**
   * Make a copy with some attributes changed
   */
  copyWithAmendments(amendments: Partial<QuoteWizardAnswerInstance>) {
    return new QuoteWizardAnswerInstance({
      ...this.copy(),
      ...amendments,
    });
  }

  /**
   * Determine if this QuoteWizardAnswerInstance is the answer to the provided QuoteWizardQuestionInstance
   */
  matchQuoteWizardQuestionInstanceById(other: QuoteWizardQuestionInstance) {
    return this.questionInstance.id === other.id;
  }

  /**
   * Is this answer for a location question with at least one answered location subquestion?
   */
  isAnsweredLocation() {
    return (
      this.questionInstance.component === 'location' &&
      this.subAnswers
        .filter((subAnswer) =>
          ['address', 'city', 'province', 'postal'].includes(
            subAnswer.questionInstance.apiName,
          ),
        )
        .some((subAnswer) => subAnswer.value !== 'N/A')
    );
  }

  /**
   * Convert to a UnifiedAnswerInstance
   */
  toUnifiedAnswerInstance(): UnifiedAnswerInstance {
    const newAnswer = this.questionInstance.toUnifiedAnswerInstance({
      value: this.value,
      subAnswers: this.subAnswers.map((subAnswer) =>
        subAnswer.toUnifiedAnswerInstance(),
      ),
    });
    //console.log(
    //   'converted QuoteWizardAnswerInstance to UnifiedAnswerInstance:',
    //   {
    //     QuoteWizardAnswerInstance: this,
    //     UnifiedAnswerInstance: newAnswer,
    //   },
    // );
    return newAnswer;
  }

  /**
   * Generate the answer instance from a raw object (basically, the result of JSON.stringifying it and then loading it back in)
   * @param obj - The JSON object representing an answer instance
   * @return - The QuoteWizardAnswerInstance
   */
  static generateFromObject(obj: $TSFixMe): QuoteWizardAnswerInstance {
    return new QuoteWizardAnswerInstance({
      ...obj,
      questionInstance: QuoteWizardQuestionInstance.generateFromBackendResponse(
        obj.questionInstance,
      ),
      subAnswers: obj.subAnswers.map((subAnswerObj: $TSFixMe) =>
        QuoteWizardAnswerInstance.generateFromObject(subAnswerObj),
      ),
      details: new AnswerInstanceDetails(obj.details),
    });
  }
}
