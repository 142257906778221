import Bugsnag from '@bugsnag/js';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { UnifiedAnswerInstance } from '../../../Typescript/classes';
import { errorify } from '../../../util';
// import { errorify } from '../../../util';
// import { numberFormat } from '../../util/NumberUtils';
import { numberFormat } from '../../util/NumberUtils';
import { OBLIGATE_INPUT_TOGGLE_MULTIPLE_TYPE } from '../QuoteComponents/ObligateInputToggleMultiple';
import { VALUE_SEPARATOR as INPUT_TOGGLE_MULTIPLE_VALUE_SEPARATOR } from '../InformedMaterialFills/InformedMaterialObligateInputToggleMultiple';
import { formatAsReadableList } from '../QuoteComponents/utility';

/**
 * Generate the answer for a location component by grabbing the answers to address, city, &c and forming a nicely formatted string from them
 */
export const generateLocationAnswer = (answer: UnifiedAnswerInstance) => {
  if (!answer.subAnswers || answer.subAnswers.length === 0) {
    return 'N/A';
  }
  const validComponents = ['address', 'city', 'province', 'postal']
    .map((element) => {
      const matchingAnswer = answer.subAnswers.find(
        (subAnswer) => subAnswer.apiName === element,
      );
      if (matchingAnswer) {
        return { element, data: matchingAnswer.value };
      }
      return null;
    })
    .filter((component) => !!component && component.data !== 'N/A')
    .reduce((acc: $TSFixMe, current) => {
      // @ts-expect-error
      acc[current.element] = current.data;
      return acc;
    }, {});

  if (Object.keys(validComponents).length === 0) {
    return 'N/A';
  }

  const { address, city, province, postal } = validComponents;
  const cityProvinceString =
    !city && !province
      ? null
      : [city, province].filter((elem) => !!elem).join(', ');
  return (
    <span style={{ fontWeight: 'bold' }}>
      {address ? (
        <>
          {address}
          {cityProvinceString || postal ? ',' : null}
          <br />
        </>
      ) : null}
      {cityProvinceString ? (
        <>
          {cityProvinceString}
          {postal ? ',' : null}
          <br />
        </>
      ) : null}
      {postal ? (
        <>
          {postal}
          <br />
        </>
      ) : null}
    </span>
  );
};

export const defaultBadDisplay = ''; // if there is something wrong, return this
/**
 * This is the *answer* to the question
 */
export const generateDisplayValue = (answer: UnifiedAnswerInstance): string => {
  let displayValue;
  switch (answer.component) {
    case 'internationalRevenue':
      displayValue =
        answer.subAnswers.length > 0
          ? generateDisplayValue(answer.subAnswers[0])
          : defaultBadDisplay;
      break;
    case 'location':
      displayValue = generateLocationAnswer(answer);
      break;
    case 'logic':
      displayValue =
        answer.subAnswers.length > 0
          ? generateDisplayValue(answer.subAnswers[0])
          : defaultBadDisplay;
      break;
    case 'vin':
    case 'repeatable':
      displayValue = '';
      break;
    case 'checkbox':
      displayValue = answer.value ? 'Yes' : 'No';
      break;
    case 'YesNoToggle':
      if (answer.value === 'yes') {
        displayValue = 'Yes';
      } else if (answer.value === 'no') {
        displayValue = 'No';
      } else {
        displayValue = 'N/A';
      }
      break;
    case 'ObligateInputToggle':
    case 'InputToggle':
      displayValue = answer.value;
      try {
        if (
          // @ts-expect-error
          answer.propsBlob?.options &&
          // @ts-expect-error
          Array.isArray(answer.propsBlob?.options) &&
          // @ts-expect-error
          answer.propsBlob.options.length > 0
        ) {
          // @ts-expect-error
          const matchedOption = answer.propsBlob.options.find(
            // @ts-expect-error
            (option) => option.value === answer.value,
          );
          if (matchedOption && matchedOption.label) {
            displayValue = matchedOption.label;
          }
        }
      } catch (e) {
        Bugsnag.notify(errorify(e));
        console.error(`Error finding display value for InputToggle`, e);
      }
      break;
    case OBLIGATE_INPUT_TOGGLE_MULTIPLE_TYPE:
      displayValue = answer.value;
      try {
        if (typeof answer.value != 'string') {
          break;
        }
        const splitValues = answer.value.split(
          INPUT_TOGGLE_MULTIPLE_VALUE_SEPARATOR,
        );
        if (
          // @ts-expect-error
          answer.propsBlob?.options &&
          // @ts-expect-error
          Array.isArray(answer.propsBlob?.options) &&
          // @ts-expect-error
          answer.propsBlob.options.length > 0
        ) {
          const splitLabels = splitValues.map((value) => {
            // @ts-expect-error
            const matchedOption = answer.propsBlob.options.find(
              // @ts-expect-error
              (option) => option.value === value,
            );
            if (matchedOption && matchedOption.label) {
              return matchedOption.label;
            }
            return value;
          });
          displayValue = formatAsReadableList(splitLabels);
        }
      } catch (e) {
        Bugsnag.notify(errorify(e));
        console.error(
          `Error finding display value for in InputToggleMultiple`,
          e,
        );
      }
      break;
    case 'numberinput':
      try {
        if (!Number.isNaN(Number(answer.value))) {
          displayValue = numberFormat(answer.propsBlob, answer.value);
        }
      } catch (e) {
        console.error(e);
        Bugsnag.notify(errorify(e));
      }
      break;
    default:
      displayValue = answer.value;
      if (answer.dataType === 'nested' && answer.value === 1) {
        displayValue = '';
      }
  }
  return displayValue === undefined ? 'N/A' : displayValue;
};

export const badDisplayLabel = ''; // in the case that something goes wrong, this is what should be displayed for the label
export const generateDisplayLabel = (
  answer: UnifiedAnswerInstance,
  answers: Array<UnifiedAnswerInstance>,
): string => {
  let displayLabel;
  if (!answer) {
    throw new Error(
      `Empty answer passed in to generateDisplayLabel; aborting: ${JSON.stringify(
        { answer, answers },
        null,
        4,
      )}`,
    );
  }
  switch (answer.component) {
    case 'internationalRevenue':
      displayLabel =
        answer.subAnswers.length > 0
          ? generateDisplayLabel(answer.subAnswers[0], answers)
          : badDisplayLabel;
      break;
    case 'logic':
      displayLabel =
        answer.subAnswers.length > 0
          ? generateDisplayLabel(answer.subAnswers[0], answers)
          : badDisplayLabel;
      break;
    case 'repeatable':
    case 'location':
    case 'vin':
      if (answer.countInAnswersList(answers) === 1) {
        displayLabel = answer.label;
      } else {
        displayLabel = `${answer.displayName} #${answer.value}`;
      }
      break;
    default:
      displayLabel = answer.label;
  }
  return displayLabel;
};

export const isInternationalRevenueAnswerTrue = (
  answer: UnifiedAnswerInstance,
) => {
  const internationalRevenueBool =
    answer && answer.subAnswers
      ? answer.subAnswers.find(
          (subAnswer) => subAnswer.apiName === 'international_revenue_bool',
        )
      : undefined;
  return internationalRevenueBool
    ? internationalRevenueBool.value === 'yes'
    : false;
};
