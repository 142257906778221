// material
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

/* PolicyListHead.propTypes = { */
/*   order: PropTypes.oneOf(['asc', 'desc']), */
/*   orderBy: PropTypes.string, */
/*   rowCount: PropTypes.number, */
/*   headLabel: PropTypes.array, */
/*   numSelected: PropTypes.number, */
/*   onRequestSort: PropTypes.func, */
/* }; */

type Order = 'asc' | 'desc';

interface PolicyListHeadProps {
  order: Order;
  orderBy: string;
  rowCount?: number;
  headLabel: Array<$TSFixMe>;
  numSelected?: number;
  onRequestSort: $TSFixMe; // function
}

export default function PolicyListHead({
  order,
  orderBy,
  // @ts-expect-error
  rowCount,
  headLabel,
  // @ts-expect-error
  numSelected,
  onRequestSort,
}: PolicyListHeadProps) {
  const createSortHandler = (property: $TSFixMe) => (event: $TSFixMe) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
