import { useVagoIbcList } from '../../../hooks';
import { InformedMaterialAutocomplete } from '../InformedMaterialFills';

import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

import { Component } from './classes';

// types
import { QuoteComponentProps } from './types';

const QuoteComponent = function ({
  questionInstance,
  classes,
  handleFilesChanged,
  ...props
}: QuoteComponentProps) {
  const ibcList = useVagoIbcList();

  return (
    <InformedMaterialAutocomplete
      label={questionInstance.label}
      field={questionInstance.generateFieldName()}
      id={questionInstance.id}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes && classes.textfield ? classes.textfield : ''}
      classes={classes}
      {...props}
      variant='standard'
      options={ibcList}
    />
  );
};

const IconComponent = () => {
  return <ElectricMeterIcon fontSize='small' color='primary' />;
};

export function createVagoIbcSelectorComponentRegistry() {
  return new Component({
    type: 'vagoIbcSelection',
    typeLabel: 'VaGo IBC Selection',
    quoteComponent: QuoteComponent,
    dataType: 'string',
    // programBuilderComponent: ProgramBuilderComponent,
    icon: IconComponent,
  });
}

export { QuoteComponent };
