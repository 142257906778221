import ErrorComponent, {
  InsufficientPermissionsErrorComponent,
} from './ErrorComponent';

export const handleDashboardError = (error: Error) => {
  if (/invalid permissions/i.test(error?.message)) {
    return <InsufficientPermissionsErrorComponent />;
  }
  return <ErrorComponent />;
};
