import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ErrorView } from '../common/ErrorView';
import { InsufficientPermissionsErrorPage } from '../common/ErrorHandling';
import FormBuilder from './FormBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { formStore } from '../../store';
import {
  useAllowByPermission,
  useAllowByQuestionEditPermissions,
} from '../../hooks';

// queries
import {
  BUSINESS_AND_POLICY_SPECIFIC_FORM,
  BUSINESS_TYPE,
  POLICY,
} from '../../queries';
import { useAuth } from '@calefy-inc/authentication';

// types and classes
import type { $TSFixMe, BooleanWithUnknown } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from './classes';
import type { StoreState } from '../../store';
import Bugsnag from '@bugsnag/browser';

interface FormManagerProps {
  businessId: string;
  policyId: string;
}

const FormManager = ({ businessId, policyId }: FormManagerProps) => {
  const dispatch = useDispatch();
  const hasFormEditPermissions = useAllowByPermission('edit:forms');
  const { allowed: hasQuestionEditPermissions } =
    useAllowByQuestionEditPermissions();
  const { token } = useAuth();
  const [
    getBusinessAndPolicySpecificForm,
    { error: existingFormError, data: existingFormData },
  ] = useLazyQuery(BUSINESS_AND_POLICY_SPECIFIC_FORM);

  const [getBusiness, { error: businessError, data: businessData }] =
    useLazyQuery(BUSINESS_TYPE);
  const [
    getPolicy,
    { loading: policyLoading, error: policyError, data: policyData },
  ] = useLazyQuery(POLICY);

  const [usingExistingForm, setUsingExistingForm] =
    useState<BooleanWithUnknown>('unknown');

  const filterRelevantForms = useCallback(
    (forms: Array<$TSFixMe>) => {
      // this should be a list of forms FROM THE BACKEND - they aren't ProgramBuilderForms!
      return forms
        .filter((form: $TSFixMe) => {
          return form.businessLine.id === businessId;
        })
        .filter((form) =>
          policyId !== '0'
            ? form.policy && form.policy.id === policyId
            : form.policy === null,
        );
    },
    [businessId, policyId],
  );
  const form = useSelector((state: StoreState) => {
    const { forms } = state.formStore;

    const foundForms = filterRelevantForms(forms);

    if (foundForms.length === 0) {
      return null;
    }
    if (foundForms.length > 1) {
      const error = new Error(
        `FormManager: Found multiple forms matching the requested business ${businessId} and policy ${policyId}: ${JSON.stringify(
          foundForms,
          null,
          4,
        )}`,
      );
      Bugsnag.notify(error);
      throw error;
    }
    const foundForm = foundForms[0];
    return foundForm;
  });

  // try to get a form from the backend if none is passed in
  useEffect(() => {
    if (
      form === null &&
      token &&
      (hasFormEditPermissions || hasQuestionEditPermissions)
    ) {
      getBusinessAndPolicySpecificForm({
        variables: {
          token,
          businessId: businessId,
          policyId: policyId === '0' ? null : policyId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    policyId,
    businessId,
    token,
    hasFormEditPermissions,
    hasQuestionEditPermissions,
  ]);

  // handle the response from getBusinessAndPolicySpecificForm
  useEffect(() => {
    if (existingFormData) {
      if (!existingFormData.businessAndPolicySpecificForm) {
        setUsingExistingForm(false);
        return;
      }
      const relevantForm = ProgramBuilderForm.generateFromBackendResponse(
        existingFormData.businessAndPolicySpecificForm,
      );
      setUsingExistingForm(true);
      dispatch(
        formStore.actions.loadExistingForms({
          loadedForms: [relevantForm],
        }),
      );

      const businessLine = relevantForm.businessType;
      const policy = relevantForm.policy;

      if (policy) {
        dispatch(
          formStore.actions.addPolicy({
            businessLine: businessLine,
            policy: policy,
          }),
        );
      } else {
        dispatch(
          formStore.actions.addGeneralForm({
            businessLine: businessLine,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFormData]);

  // if we are not using the existing form, make the queries for business and policy
  useEffect(() => {
    if (usingExistingForm === false) {
      if (policyId !== '0') {
        getPolicy({ variables: { id: policyId } });
      }
      // @ts-expect-error
      getBusiness({ variables: { businessId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingExistingForm]);

  // once the businessData / policyData (if we are getting it) are present, dispatch the action that sets them in the store
  useEffect(() => {
    if (businessData) {
      if (policyData && policyData.policy) {
        const policyToAdd = {
          businessLine: businessData.businessType,
          policy: policyData.policy,
        };
        // @ts-expect-error
        dispatch(formStore.actions.addPolicy(policyToAdd));
      } else if (!policyLoading) {
        const generalInformationToAdd = {
          businessLine: businessData.businessType,
        };
        // @ts-expect-error
        dispatch(formStore.actions.addGeneralForm(generalInformationToAdd));
      }
    }
    if (businessData && (policyData || !policyLoading)) {
      const policyToAdd = {
        businessLine: businessData.businessType,
        policy: policyData && policyData.policy ? policyData.policy : null,
      };
      // @ts-expect-error
      dispatch(formStore.actions.addPolicy(policyToAdd));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessData, policyData, policyLoading]);

  if (existingFormError || policyError || businessError) {
    return <ErrorView />;
  }
  if (
    hasFormEditPermissions === false &&
    hasQuestionEditPermissions === false
  ) {
    return (
      <InsufficientPermissionsErrorPage deniedIdentifier='Form Creation / Edit page' />
    );
  }

  return <FormBuilder form={form} />;
};

export default FormManager;
