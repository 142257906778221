import componentRegistry from '../../../../../common/QuoteComponents';

// types
import type {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../../../classes';
import { ValidateFunctionReturn } from '../types';

function validateQuestionInstance(
  questionInstance: ProgramBuilderQuestionInstance,
): ValidateFunctionReturn {
  const errorMessages = [];
  const validationFunction =
    componentRegistry.getProgramBuilderValidationFunction(
      questionInstance.component,
    );
  const result = validationFunction ? validationFunction(questionInstance) : [];
  errorMessages.push(...result);
  questionInstance.subQuestions.forEach((subq) => {
    const result = validateQuestionInstance(subq);
    errorMessages.push(...result);
  });
  return errorMessages;
}

/**
 * Validate that each individual questionInstance is correctly formatted (done according to the specific component type)
 */
export function validateQuestionInstances(form: {
  questionInstances: ProgramBuilderForm['questionInstances'];
}): ValidateFunctionReturn {
  const errorMessages = [];
  const { questionInstances } = form;
  if (!questionInstances || questionInstances.length === 0) {
    errorMessages.push('Form invalid: you must include at least one question.');
  } else {
    for (let questionInstance of questionInstances) {
      const result = validateQuestionInstance(questionInstance);
      errorMessages.push(...result);
    }
  }
  return errorMessages;
}
