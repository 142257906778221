import { ValidateFunctionReturn } from '../types';
import {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../../../classes';

/**
 * Do basic (not dependent on the question type) validation
 */
export function validateQuestionBasics(form: {
  questionInstances: ProgramBuilderForm['questionInstances'];
}): ValidateFunctionReturn {
  const errorMessages: Array<string> = [];
  const questionInstances =
    form.questionInstances && form.questionInstances.length > 0
      ? form.questionInstances
      : [];

  const validators = [
    validateQuestionApiNameUniqueOnLevel,
    validateQuestionApiNamesUniqueInTree,
  ];
  for (let validator of validators) {
    const result = validator(questionInstances);
    errorMessages.push(...result);
  }
  return errorMessages;
}

/**
 * Validate that one each level (e.g. horizontal group of questions / subquestions), there are no duplicated question api names
 */
function validateQuestionApiNameUniqueOnLevel(
  questionInstances: ProgramBuilderQuestionInstance['subQuestions'],
): ValidateFunctionReturn {
  const errorMessages: Array<string> = [];
  if (!questionInstances || questionInstances.length === 0) {
    return errorMessages;
  }
  const uniqueNames: Array<string> = [];
  for (let questionInstance of questionInstances) {
    const { apiName } = questionInstance;
    if (uniqueNames.includes(apiName)) {
      errorMessages.push(
        `Questions at the top level, or subquestions of a single question, may not share a name. Found duplicate API name "${apiName}".`,
      );
    }
    uniqueNames.push(apiName);

    // now recurse on the subquestions
    const subQuestionsResults = validateQuestionApiNameUniqueOnLevel(
      questionInstance.subQuestions,
    );
    errorMessages.push(...subQuestionsResults);
  }
  return errorMessages;
}

/**
 * Validate that for each questionInstance in a tree, all name attributes are unique within a subtree
 * @param questionInstances - A list of questionInstances to check
 * @param names - The names found above this node in the tree
 */
function validateQuestionApiNamesUniqueInTree(
  questionInstances: ProgramBuilderQuestionInstance['subQuestions'],
  apiNames: Array<string> = [],
): ValidateFunctionReturn {
  const errorMessages: Array<string> = [];
  if (!questionInstances || questionInstances.length === 0) {
    return errorMessages;
  }

  for (let questionInstance of questionInstances) {
    const { apiName } = questionInstance;
    if (apiNames.includes(apiName)) {
      errorMessages.push(
        `A question and its subquestions may not share an API name. Found duplicate API name "${apiName}".`,
      );
    }

    // now recurse on the subquestions
    const subQuestionsResult = validateQuestionApiNamesUniqueInTree(
      questionInstance.subQuestions,
      [...apiNames, apiName],
    );
    errorMessages.push(...subQuestionsResult);
  }

  return errorMessages;
}
