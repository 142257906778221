import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { PREMIUM_PROPOSALS_FROM_HASH } from '../../../queries';

// types and classes
import { RouteComponentProps } from '@reach/router';
import { PremiumProposal } from '../../../Typescript/backend/classes';
import { PremiumProposalsSummaryPage } from './PremiumProposalsSummaryPage';
import { IndividualProposalDetailsDisplay } from './IndividualProposalDetailsDisplay';

interface PremiumProposalsPdfProps extends RouteComponentProps {
  hash?: string;
  insurerName?: string;
}

export const PremiumProposalsPdf = ({
  hash,
  insurerName,
}: PremiumProposalsPdfProps) => {
  const { loading, error, data } = useQuery(PREMIUM_PROPOSALS_FROM_HASH, {
    variables: {
      hash,
      // @ts-expect-error
      insurerName,
    },
  });

  const [proposals, setProposals] = useState<Array<PremiumProposal>>([]);

  useEffect(() => {
    if (data) {
      setProposals(
        // @ts-expect-error
        data.premiumProposalsFromHash.map(
          PremiumProposal.generateFromBackendResponse,
        ),
      );
    }
  }, [data]);

  if (loading) {
    return null;
  }
  if (proposals.length === 0) {
    return null;
  }
  if (error) {
    return (
      <>
        <p>Error Displaying Proposals</p>
        <span>{JSON.stringify(error, null, 4)}</span>
      </>
    );
  }
  return (
    <>
      {proposals[0].insurer.logoUrl ? (
        <>
          <img
            src={proposals[0].insurer.logoUrl}
            alt={proposals[0].insurer.name}
            style={{ width: 100, height: 100 }}
          />
          <Divider sx={{ my: 2 }} />
        </>
      ) : null}
      <Typography
        variant='h1'
        align='center'
        gutterBottom
        sx={{ fontWeight: 600 }}
      >
        {insurerName} Premium Proposal{proposals.length === 1 ? '' : 's'}
      </Typography>
      <PremiumProposalsSummaryPage proposals={proposals} />
      {proposals.some((proposal) => proposal.status !== 'REJECTED')
        ? proposals
            .filter((proposal) => proposal.status !== 'REJECTED')
            .map((proposal, index) => {
              return (
                <IndividualProposalDetailsDisplay
                  key={proposal.notes}
                  proposal={proposal}
                  identifier={
                    proposal.identifier
                      ? proposal.identifier
                      : proposals.length === 1
                      ? 'Proposal'
                      : `Proposal #${index + 1}`
                  }
                />
              );
            })
        : null}
    </>
  );
};
