import { LoadingButton } from '@mui/lab';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useLazyQuery } from '@apollo/client';
import { VAGO_CLIENT_SUMMARY } from '../../../../../../queries';
import { useSnackbar } from 'notistack';
import Bugsnag from '@bugsnag/js';
import { downloadFile } from '@calefy-inc/utility';
import { useAuth } from '@calefy-inc/authentication';

export const VagoClientSummary = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const [getVagoClientSummary, { loading }] = useLazyQuery(
    VAGO_CLIENT_SUMMARY,
    {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        Bugsnag.notify(error);
        enqueueSnackbar(`Error downloading client summary: ${error.message}`, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        if (data.vagoClientSummary) {
          (async () => {
            await downloadFile(
              // @ts-expect-error
              data.vagoClientSummary,
              `Client Summary ${new Date().toLocaleString()}.csv`,
              'data:text/csv;base64',
            );
          })();
        }
      },
    },
  );
  if (!token) {
    return null;
  }
  return (
    <LoadingButton
      loading={loading}
      loadingPosition='start'
      startIcon={<FileDownloadOutlinedIcon />}
      onClick={() => getVagoClientSummary({ variables: { token } })}
    >
      Client Summary
    </LoadingButton>
  );
};
