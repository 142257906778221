// types and classes
import type { FormValues } from 'informed';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { QuestionInstanceFormError, LanguageSegregatedErrors } from './types';
import {
  ILanguageAwareString,
  Language,
} from '../../../../../../../../../Typescript/classes';
import { ProgramBuilderQuestionInstance } from '../../../../../../../../FormManager/classes';

export function validateExists(value: unknown) {
  if (!value) {
    return 'Field cannot be blank';
  }
}

export function filterNotNullishOrEmpty(e: unknown) {
  return e !== undefined && e !== null && e !== '';
}
/**
 * Compare the new and old question instances
 * This needs to be because the questionInstance seems to gather new props fairly regularly; some belonging to the actual questionInstance and other which are there to control how it is acted upon (e.g. `expanded`, `editAll`)
 */
export const areNewAndOldQuestionInstancesTheSame = (
  newQuestionInstance: ProgramBuilderQuestionInstance | null | undefined,
  oldQuestionInstance: ProgramBuilderQuestionInstance | null | undefined,
) => {
  //console.log(
  //   'in areNewAndOldQuestionInstancesTheSame with',
  //   newQuestionInstance,
  //   oldQuestionInstance,
  // );
  if (
    (!newQuestionInstance && oldQuestionInstance) ||
    (newQuestionInstance && !oldQuestionInstance)
  ) {
    return false;
  }
  if (!newQuestionInstance && !oldQuestionInstance) {
    return true;
  }
  // @ts-expect-error
  const areTheyEqual = oldQuestionInstance.equals(newQuestionInstance);
  //console.log(
  //   'areTheyEqual for old',
  //   oldQuestionInstance,
  //   'new',
  //   newQuestionInstance,
  //   `is ${areTheyEqual}`,
  // );
  return areTheyEqual;
  // const attributesToCheck: Array<keyof ProgramBuilderQuestionInstance> = [
  //   'clientId',
  //   'component',
  //   'helpText',
  //   'id',
  //   'label',
  //   'name',
  //   'parentForms',
  //   'propsBlob',
  //   'required',
  //   'subQuestions',
  // ];
  // const areTheyTheSame = attributesToCheck.every((attribute) => {
  //   if (attribute === 'helpText') {
  //     // i.e. check for null / undefined / ''
  //     if (
  //       (!newQuestionInstance[attribute] && !oldQuestionInstance[attribute]) ||
  //       newQuestionInstance[attribute] === oldQuestionInstance[attribute]
  //     ) {
  //       return true;
  //     }
  //   } else if (attribute === 'subQuestions') {
  //     // because different parts of the program deal with having no subQuestions in each of these different ways
  //     if (
  //       [newQuestionInstance, oldQuestionInstance].every(
  //         (qi) =>
  //           qi[attribute] === undefined ||
  //           qi[attribute] === null ||
  //           (qi[attribute] && qi[attribute].length === 0),
  //       ) ||
  //       isEqual(newQuestionInstance[attribute], oldQuestionInstance[attribute])
  //     ) {
  //       return true;
  //     }
  //   } else if (attribute === 'propsBlob') {
  //     // again, different parts of the program give different values to a default propsBlob
  //     if (
  //       [newQuestionInstance, oldQuestionInstance].every(
  //         (qi) =>
  //           qi[attribute] === null ||
  //           qi[attribute] === undefined ||
  //           qi[attribute] === JSON.stringify({}),
  //       ) ||
  //       isEqual(newQuestionInstance[attribute], oldQuestionInstance[attribute])
  //     ) {
  //       return true;
  //     }
  //   } else if (
  //     isEqual(oldQuestionInstance[attribute], newQuestionInstance[attribute])
  //   ) {
  //     return true;
  //   }
  //   return false;
  // });
  // return areTheyTheSame;
};

// export function extractPropProperties(propsBlob: $TSFixMe) {
//   let propBlobAsProperties = {};

//   /* in order to avoid collisions with other properties, we will unpack all the key-vals from the props object, and append props_blob to the key. */
//   try {
//     propBlobAsProperties = Object.entries(JSON.parse(propsBlob)).reduce(
//       (acc, [key, value]) => {
//         return {
//           ...acc,
//           [`props_blob_${key}`]: value,
//         };
//       },
//       {},
//     );
//   } catch (e) {}

//   return propBlobAsProperties;
// }

export function filterPropProperties(formState: $TSFixMe) {
  return Object.entries(formState).reduce((acc, [key, value]) => {
    const propsBlobRegex = /^props_blob_(.+)/;

    if (key.match(propsBlobRegex)) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

export function groupPropProperties(formValues: $TSFixMe) {
  // debugger;
  const propsBlob = Object.entries(formValues).reduce((acc, [key, value]) => {
    const propsBlobRegex = /^props_blob_(.+)/;

    const regexMatch = propsBlobRegex.exec(key);

    if (!regexMatch) {
      return acc;
    }

    const capturedPropertyName = regexMatch[1];

    // This will strip the props_blob prefix from the key, and store it in this new obj
    return {
      ...acc,
      [capturedPropertyName]: value,
    };
  }, {});
  // debugger;
  // return JSON.stringify(propsBlob);
  return propsBlob;
}

/**
 * Take an object of language aware fields, and transform all fields of the form <languageString>_<desiredName> -> <desiredName>: [{value: form value, language: Language matching the code}]
 */
export function transformLanguageAwareProperties(
  values: {
    [k: string]: $TSFixMe;
  },
  languages: Language[],
): { [k: string]: ILanguageAwareString[] } {
  const languageMatchRegex = new RegExp(
    `(${languages.map((language) => language.shortName).join('|')})_(.+)`,
  );
  // @ts-expect-error
  return Object.entries(values).reduce(
    // @ts-expect-error
    (transformedProperties: { [k: string]: ILanguageAwareString }, entry) => {
      const [key, value] = entry;
      if (typeof value !== 'string') {
        return transformedProperties;
      }
      const match = languageMatchRegex.exec(key);
      if (match) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, languageCode, desiredName] = match;
        const matchingLanguage = languages.find(
          (language) => language.shortName === languageCode,
        );
        const transformedValue = {
          value,
          language: matchingLanguage,
        };
        return {
          ...transformedProperties,
          [desiredName]: transformedProperties[desiredName]
            ? // @ts-expect-error
              [...transformedProperties[desiredName], transformedValue]
            : [transformedValue],
        };
      } else {
        return transformedProperties;
      }
    },
    {},
  );
}

interface GenerateNewQualifyingQuestionInstanceFromFormArgument {
  oldQuestion: $TSFixMe;
  formValues: $TSFixMe;
  dataType: $TSFixMe;
  form: $TSFixMe;
  newQualifyingId: $TSFixMe;
}
export const generateNewQualifyingQuestionInstanceFromForm = ({
  oldQuestion,
  formValues,
  dataType,
  form,
  newQualifyingId,
}: GenerateNewQualifyingQuestionInstanceFromFormArgument) => {
  const nonPropProperties = filterPropProperties(formValues);
  const propsBlob = groupPropProperties(formValues);

  // if the question already exists, then we want to grab some (but not all) of the properties to map onto the new question instance
  const attributesToCopy = ['id', 'clientId', 'parentForms', 'form'];
  let copy: { [k: string]: $TSFixMe } = {};
  if (oldQuestion) {
    copy = attributesToCopy.reduce((acc: $TSFixMe, attribute: $TSFixMe) => {
      acc[attribute] = oldQuestion[attribute];
      return acc;
    }, copy);
  }

  let newQualifyQuestion = {
    ...copy,
    dataType,
    ...nonPropProperties,
    required: true,
    propsBlob,
    form,
    clientId:
      oldQuestion && oldQuestion.clientId
        ? oldQuestion.clientId
        : newQualifyingId,
  };

  return newQualifyQuestion;
};

interface QuestionInstanceFormDirtyArgument {
  initialFormValues: $TSFixMe;
  currentFormValues: $TSFixMe;
  oldQuestion: $TSFixMe;
  newQuestion: $TSFixMe;
}
export const questionInstanceFormDirty = ({
  initialFormValues,
  currentFormValues,
  oldQuestion,
  newQuestion,
}: QuestionInstanceFormDirtyArgument) => {
  //console.log('in questionInstanceFormDirty');
  // first of all: if there is initial form date but the formstate is empty, then no changes have been made
  if (
    initialFormValues &&
    Object.keys(initialFormValues).length > 0 &&
    Object.keys(currentFormValues).filter((value) => value).length === 0
  ) {
    //console.log(
    //   'questionInstanceformDirty: initial form values but no current form values -> no changes',
    // );
    return false;
  }

  // if there is no questionInstance, then anything in the formState.values => changes
  if (!oldQuestion) {
    const numPresentValues = Object.entries(currentFormValues).filter(
      ([k, v]) => v && k !== 'component', // because the component is automatically set -> we ignore it for the purposes of deciding if a change ocurred
    ).length;
    const changesPresent = numPresentValues !== 0;
    //console.log(
    //   `No existing question passed in - current form has ${numPresentValues} filled in keys`,
    //   currentFormValues,
    //   ` - changesPresent=${changesPresent}`,
    // );
    return changesPresent;
  }

  // otherwise - something has been touched and there is a questionInstance - we need to check whether the original and the new one are the same
  //console.log('About to fire areNewAndOldQuestionInstancesTheSame');
  const questionsAreTheSame = areNewAndOldQuestionInstancesTheSame(
    newQuestion,
    oldQuestion,
  );

  return !questionsAreTheSame;
};

/**
 * Sanitizes a string to be an api name - only alphanumeric characters (and underscores), lowercase, and with duplicate _ removed. Also, apostrophes are simply removed. Check that this matches the backend QuestionInstance.sanitize_api_name function.
 * @param string - The string to be sanitized
 */
export function sanitizeForApiName(string: string): string {
  string = string.replace(/'/g, '');
  string = string.replace(/\W/g, '_');
  string = string.replace(/_+/g, '_');
  string = string.toLowerCase();
  return string;
}

/**
 * Validate that the api name textbox has the correct form
 */
export function validateApiName(string: string): string | undefined {
  if (!string) {
    return 'This field is required';
  }

  const matchesNonAlphanumeric = string.match(/\W/g);
  const matchesUppercase = string.match(/[A-Z]/);
  const matchesMultipleUndersccores = string.match(/_{2,}/);
  if (matchesNonAlphanumeric) {
    return `Invalid API name: found invalid characters ${matchesNonAlphanumeric.join(
      ', ',
    )}. A valid API name must contain only lowercase alphanumeric characters and underscores`;
  }
  if (matchesUppercase) {
    return `Invalid API name: found uppercase letters. A valid API name must contain only lowercase alphanumeric characters and underscores`;
  }
  if (matchesMultipleUndersccores) {
    return `Invalid API name: found multiple adjacent underscores. A valid API name cannot contain multiple underscores next to each other.`;
  }
  return undefined;
}

/**
 * Determine if any question with the same language prefix has been answered in the formState
 */
// export const questionWithSameLanguagePrefixAnswered = (
//   formState: {
//     values: { [k: string]: $TSFixMe };
//   },
//   languagePrefix: string,
// ) => {
//   const languageMatch = new RegExp(`^${languagePrefix}_`);
//   const answered = Object.keys(formState.values).some(
//     (field) => field.match(languageMatch) && formState.values[field],
//   );
//   //console.log('in questionWithSameLanguagePrefixAnswered', {
//     formState,
//     languagePrefix,
//     answered,
//   });
//   return answered;
// };

/**
 * Determine if any answer to a language-prefixed exists in the formState
 */
// export const languagePrefixedFieldAnswered = (
//   formState: {
//     values: { [k: string]: $TSFixMe };
//   },
//   baseField: string,
// ) => {
//   const baseFieldMatch = new RegExp(`^\w+_${baseField}`);
//   return Object.keys(formState.values).some(
//     (field) => field.match(baseFieldMatch) && formState.values[field],
//   );
// };

/**
 * Given a FormValues, get a list of the languages for which there are languages
 */
function getLanguagesByKeyRegex(
  formValues: FormValues,
  regex: RegExp,
  allLanguages: Array<Language>,
): Array<Language> {
  const results: Array<Language> = Object.entries(formValues)
    .reduce((acc: string[], [k, v]) => {
      if (v) {
        return [...acc, k];
      }
      return acc;
    }, [])
    .reduce((acc: string[], key) => {
      const match = regex.exec(key);
      if (match) {
        return [...acc, match[1]];
      }
      return acc;
    }, [])
    .map((languageCode: string) => {
      const matched = allLanguages.find(
        (language) => language.shortName === languageCode,
      );
      if (!matched) {
        throw new Error(
          `Unable to find language matching code ${languageCode} in ${JSON.stringify(
            allLanguages,
            null,
            4,
          )}`,
        );
      }
      return matched;
    });
  return results;
}

/**
 * Returns a function which validates the QuestionInstanceForm as a whole to determine if the languages are consistent:
 * 1. There is at least one language with a name / label pair
 * 2. There are no unpaired names / labels - that is, if there is a French label, there must also be a French name
 * 3. If there is helpText for a language, there must also be a name / label
 * 4. If there are options, then the languages of the options must also match the languages of the name / label
 */
export const generateValidateLanguages = (allLanguages: Language[]) => {
  return (values: FormValues): QuestionInstanceFormError => {
    // get all the languages for which names exist
    const languageNameMatchRegex = /(.+)_displayNames/i;
    const languageLabelMatchRegex = /(.+)_labels/i;
    const languageHelpTextsMatchRegex = /(.+)_helpTexts/i;

    const nameLanguages = getLanguagesByKeyRegex(
      values,
      languageNameMatchRegex,
      allLanguages,
    );
    const labelLanguages = getLanguagesByKeyRegex(
      values,
      languageLabelMatchRegex,
      allLanguages,
    );
    const helpTextLanguages = getLanguagesByKeyRegex(
      values,
      languageHelpTextsMatchRegex,
      allLanguages,
    );

    const languagesInBoth = allLanguages.filter(
      (language) =>
        language.inLanguageList(nameLanguages) &&
        language.inLanguageList(labelLanguages),
    );

    if (languagesInBoth.length === 0) {
      return 'Invalid - no language has both Field Name and Question Prompt filled out.';
    }

    const unmatchedNameLanguages = nameLanguages.filter(
      (language) => !language.inLanguageList(labelLanguages),
    );
    const unmatchedLabelLanguages = labelLanguages.filter(
      (language) => !language.inLanguageList(nameLanguages),
    );

    // check for ones where the helptext is missing a name / label pair
    const helpTextButMissingNameAndLabel = helpTextLanguages.filter(
      (language) =>
        !language.inLanguageList(nameLanguages) &&
        !language.inLanguageList(labelLanguages),
    );

    // mapping from the language short code to an array of the errors associated with it
    const errors: LanguageSegregatedErrors = {};
    unmatchedNameLanguages.forEach((language) => {
      if (!errors[language.shortName]) {
        errors[language.shortName] = [];
      }
      errors[language.shortName].push(
        `There is a Field Name for ${language.fullName} without a corresponding Question Prompt.`,
      );
    });
    unmatchedLabelLanguages.forEach((language) => {
      if (!errors[language.shortName]) {
        errors[language.shortName] = [];
      }
      errors[language.shortName].push(
        `There is a Question Prompt for ${language.fullName} without a corresponding Field Name.`,
      );
    });
    helpTextButMissingNameAndLabel.forEach((language) => {
      if (!errors[language.shortName]) {
        errors[language.shortName] = [];
      }
      errors[language.shortName].push(
        `There is Help Text for ${language.fullName} without a corresponding Field Name and Question Prompt.`,
      );
    });

    if (
      values &&
      values.props_blob_options &&
      // @ts-expect-error
      values.props_blob_options.length > 0
    ) {
      const optionsErrors = validateOptionsLanguages(
        // @ts-expect-error
        values.props_blob_options,
        languagesInBoth,
      );
      Object.entries(optionsErrors).forEach(([languageCode, errorsList]) => {
        if (!errors[languageCode]) {
          errors[languageCode] = errorsList;
        } else {
          errors[languageCode].push(...errorsList);
        }
      });
    }
    return Object.keys(errors).length === 0 ||
      Object.values(errors).every((errorList) => errorList.length === 0)
      ? undefined
      : errors;
  };
};

/**
 * validate that the options for a question (InputToggle) are correct and in sync with the existing valid languages
 */
function validateOptionsLanguages(
  options: Array<{ value: string; [lang_labels: string]: string }>,
  validLanguages: Array<Language>,
): LanguageSegregatedErrors {
  //console.log('validateOptionsLanguages options:', options);
  const errors: LanguageSegregatedErrors = {};

  // They need to fix this first - then we can worry about the options
  if (validLanguages.length === 0) {
    return errors;
  }

  // now check that valid labels exist for every option for every valid language
  options.forEach((option) => {
    validLanguages.forEach((validLanguage) => {
      if (!option[validLanguage.generatePrefixedString('labels')]) {
        if (!errors[validLanguage.shortName]) {
          errors[validLanguage.shortName] = [];
        }
        errors[validLanguage.shortName].push(
          `Question is otherwise available in ${validLanguage.fullName}, but is missing a label in option with value ${option.value}`,
        );
      }
    });
  });

  return errors;
}
