import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { EDIT_BINDINGS } from '../../../../queries';
// import Button from '@mui/material/Button';
import { Quote } from '../../../../Typescript/backend/classes';
import Label from '../../components/Label';
import { useUsingVagoSettings } from '../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
// import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
// import Bugsnag from '@bugsnag/browser';
// import { errorify } from '@calefy-inc/utility';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { isNotNullish } from '../../../../util';
import { EditBindingInput } from '../../../../gql/graphql';
import { Form, Checkbox, TextArea } from '@calefy-inc/informedMaterial';
import { ArrayField } from 'informed';
import { Binding } from '../../../../Typescript/backend/classes/Quote';

interface QuoteBoundStatusProps {
  quote: Quote;
}
export const QuoteBoundStatus = ({ quote }: QuoteBoundStatusProps) => {
  const { token } = useAuth();
  const onVago = useUsingVagoSettings();
  const [editBindings, { loading, error, data }] = useMutation(EDIT_BINDINGS, {
    refetchQueries: ['Quote'],
    // @ts-expect-error
    update: (cache, { data }) => {
      // if (!data?.toggleBindStatus?.quote) {
      //   return;
      // }
      // const id = cache.identify(data?.toggleBindStatus?.quote);
      // cache.modify({
      //   id,
      //   fields: {
      //     bindings: () => data?.toggleBindStatus?.quote?.bindings,
      //   },
      // });
    },
  });
  const [bindings, setBindings] = useState<Quote['bindings']>([
    ...quote.bindings,
  ]);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [showBindButton, setShowBindButton] = useState<boolean>(
    !quote.anyBound(),
  );
  const { enqueueSnackbar } = useSnackbar();

  // update the bound status
  useEffect(() => {
    if (data?.editBindings?.bindings) {
      setBindings([
        ...data.editBindings.bindings
          .filter(isNotNullish)
          .map((input) => new Binding(input)),
      ]);
    }
  }, [data]);

  // update the error message
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error]);

  // update whether or not we should show the button to bind / unbind the quote
  useEffect(() => {
    if (quote) {
      setShowBindButton(!!token);
    }
  }, [token, quote]);

  // whenever we get a response hide the button until there's a chance to set the correct state
  useEffect(() => {
    if (data) {
      setShowBindButton(false);
    }
  }, [data]);

  // whenever the quote updates, we should update the bindings
  useEffect(() => {
    setBindings([...quote.bindings]);
  }, [quote]);

  if (!onVago) {
    return null;
  }
  if (bindings.length === 0) {
    return null;
  }
  return (
    <BoundContainer>
      <BoundStatusContainer>
        {bindings.map((binding) => {
          return (
            <Tooltip
              title={`${binding.label} ${
                binding.bound ? 'is' : 'is not'
              } bound`}
            >
              <BoundLabelContainer>
                <Label color={binding.bound ? 'success' : 'warning'}>
                  {binding.label}{' '}
                </Label>
                {binding.bound ? (
                  <CheckIcon sx={{ color: 'success.main' }} />
                ) : (
                  <CloseIcon sx={{ color: 'error.main' }} />
                )}
              </BoundLabelContainer>
            </Tooltip>
          );
        })}
      </BoundStatusContainer>
      {showBindButton ? (
        <LoadingButton
          loading={loading}
          disabled={!token}
          onClick={() => setConfirmationOpen(true)}
        >
          Modify Bound
        </LoadingButton>
      ) : null}
      <MarkQuoteBoundConfirmationDialog
        quote={quote}
        open={confirmationOpen}
        onCancel={() => setConfirmationOpen(false)}
        onConfirm={(bindings: Array<EditBindingInput>) => {
          editBindings({
            variables: {
              token,
              bindingsToEdit: bindings,
            },
          });
          setConfirmationOpen(false);
        }}
      />
      {bindings.map((binding) =>
        binding.unboundReason ? (
          <Typography variant='subtitle2'>
            {binding.label} unbound reason: {binding.unboundReason}
          </Typography>
        ) : null,
      )}
    </BoundContainer>
  );
};

const BoundStatusContainer = styled('div')(({ theme }) => {
  return {
    // display: 'grid',
    // gridTemplateColumns: 'repeat(4, auto)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  };
});

const BoundContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'center',
    gap: theme.spacing(1),
  };
});

interface MarkQuoteBoundConfirmationDialogProps {
  quote: Quote;
  open: boolean;
  onConfirm: (bindings: Array<EditBindingInput>) => void;
  onCancel: () => void;
}
const MarkQuoteBoundConfirmationDialog = ({
  quote,
  open,
  onConfirm,
  onCancel,
}: MarkQuoteBoundConfirmationDialogProps) => {
  return (
    <Dialog open={open}>
      <Form
        initialValues={{ bindings: quote.bindings }}
        onSubmit={
          // @ts-expect-error
          (values) => {
            const bindingEdits: Array<EditBindingInput> = values.bindings
              .map(
                // @ts-expect-error
                (binding, index) => {
                  const matching =
                    index in quote.bindings ? quote.bindings[index] : null;
                  if (!matching) {
                    return null;
                  }
                  return {
                    bindingId: matching.id,
                    bound: binding.bound,
                    unboundReason: binding.unboundReason,
                  };
                },
              )
              .filter(isNotNullish);
            onConfirm(bindingEdits);
          }
        }
      >
        {() => {
          return (
            <>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Please be careful when editing the status of coverages /
                  policies. Having any of these as bound will prevent further
                  edits to the application, and any changes made will
                  immediately affect the rating document and any generated
                  bordereau files.
                </Typography>
                <ArrayField field='bindings'>
                  {
                    // @ts-expect-error
                    ({ arrayFieldApi, arrayFieldState }) => {
                      return (
                        <CoverageTable>
                          <thead>
                            <tr>
                              <th scope='col'>Coverage</th>
                              <th scope='col'>Bound?</th>
                              <th scope='col'>Unbinding Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*
                            // @ts-expect-error */}
                            <ArrayField.Items>
                              {({
                                // @ts-expect-error
                                index,
                                // @ts-expect-error
                                field,
                                // @ts-expect-error
                                arrayFieldItemApi,
                                // @ts-expect-error
                                arrayFieldItemState,
                              }) => {
                                const matchingBinding =
                                  index in quote.bindings
                                    ? quote.bindings[index]
                                    : null;
                                if (!matchingBinding) {
                                  return null;
                                }
                                const needsUnboundReason =
                                  matchingBinding.bound &&
                                  !arrayFieldItemState?.values?.bound;
                                return (
                                  <tr>
                                    <CoverageTd>
                                      {matchingBinding.label}
                                    </CoverageTd>
                                    <CheckboxTd>
                                      <Checkbox field={`${field}.bound`} />
                                    </CheckboxTd>
                                    <td>
                                      {
                                        // we want to show it if
                                        // 1. it's not bound and it used to be OR it's not bound and there's a reason
                                        // 2. It needs to be there
                                        needsUnboundReason ||
                                        (!arrayFieldItemState?.values?.bound &&
                                          arrayFieldItemState?.initialValue
                                            ?.unboundReason) ? (
                                          !needsUnboundReason ? ( // if they don't need it, don't display it as a textarea; that's confusing
                                            <Typography>
                                              {matchingBinding.unboundReason}
                                            </Typography>
                                          ) : (
                                            <TextArea
                                              fullWidth
                                              required={needsUnboundReason}
                                              disabled={!needsUnboundReason}
                                              label={`Reason for unbinding ${matchingBinding.label}`}
                                              field={`${field}.unboundReason`}
                                            />
                                          )
                                        ) : (
                                          <Typography>
                                            <em>Not needed</em>
                                          </Typography>
                                        )
                                      }
                                    </td>
                                  </tr>
                                );
                              }}
                              {/*
                        // @ts-expect-error */}
                            </ArrayField.Items>
                          </tbody>
                        </CoverageTable>
                      );
                    }
                  }
                </ArrayField>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button type='submit'>Apply Changes</Button>
              </DialogActions>
            </>
          );
        }}
      </Form>
    </Dialog>
  );
};

const CoverageTable = styled('table')({
  width: '100%',
});
const CoverageTd = styled('td')(({ theme }) => {
  return {
    paddingRight: theme.spacing(1),
  };
});
const CheckboxTd = styled('td')({
  textAlign: 'center',
});

const BoundLabelContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  };
});

// @ts-expect-error
const ArrayFieldItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
