import { graphql } from '../gql';

export const ALL_LANGUAGES = graphql(`
  query allLanguages {
    allLanguages {
      shortName
      fullName
    }
  }
`);
