import { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Quote } from '../../../../../../Typescript/backend/classes';
import { useUsingVagoSettings } from '../../../../../../WhiteLabelSettings/VagoAssure/settings';
import { useLazyQuery } from '@apollo/client';
import { VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION } from '../../../../../../queries';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '@calefy-inc/authentication';
import { downloadZipFile } from '@calefy-inc/utility';
import { useSnackbar } from 'notistack';
import { useAllowByPermission } from '../../../../../../hooks';

interface VagoDownloadDocumentsButtonProps {
  quote: Quote | null;
}
/**
 * @deprecated Use the combined download button component instead. There should be no reason to use this
 */
export const VagoDownloadDocumentsButton = ({
  quote,
}: VagoDownloadDocumentsButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const usingVago = useUsingVagoSettings();
  const hasForceDownloadPermissions = useAllowByPermission(
    'force:document:download',
  );
  const { token } = useAuth();
  const [vagoDownloadApplicationDocumentation, { loading, error, data }] =
    useLazyQuery(VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION, {
      fetchPolicy: 'no-cache',
    });

  // download the file once we get it
  useEffect(() => {
    if (
      data?.vagoDownloadApplicationDocumentation?.data &&
      data.vagoDownloadApplicationDocumentation.filename
    ) {
      (async () => {
        await downloadZipFile(
          data.vagoDownloadApplicationDocumentation?.data || '',
          data.vagoDownloadApplicationDocumentation?.filename || '',
        );
      })();
    }
  }, [data]);

  // if there's an error, display it in the snackbar
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error]);

  if (
    quote === null ||
    !usingVago ||
    !token ||
    hasForceDownloadPermissions === false
  ) {
    return null;
  }
  return (
    <Tooltip title='Download application documentation'>
      {loading ? (
        <CircularProgress />
      ) : (
        <IconButton
          onClick={() => {
            vagoDownloadApplicationDocumentation({
              variables: {
                quoteUuid: quote.uniqueId,
                token,
              },
            });
          }}
        >
          <SimCardDownloadIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};
