// Utility functions for use in the Quote Review Display

// types and classes
import type { $TSFixMe, OrNullish } from '@calefy-inc/utilityTypes';
import {
  QuoteWizardAnsweredForm,
  QuoteWizardAnswerInstance,
  QuoteWizardForm,
} from '../classes';
import { UnifiedCompletedForm } from '../../../Typescript/classes';
// import { UnifiedAnswerInstance } from '../../../Typescript/classes';

/**
 * Lazily validate that an email is formatted correctly. The intent is to guard against user typos, not provide an ironclad defence against incorrect email formats
 *
 * @param address - the address to check
 * @returns - true if the address is valid and false otherwise
 */
export const validateEmail = (email: unknown) => {
  let emailRegEx = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  const invalidFormatMessage =
    'Invalid format; email should match jsmith@example.com';
  if (typeof email !== 'string') {
    return invalidFormatMessage;
  }
  return email.match(emailRegEx) ? undefined : invalidFormatMessage;
};

// returns the current form label
export const getLabel = (formWithAnswer: { form: QuoteWizardForm }) => {
  const { form } = formWithAnswer;

  let label;
  if (form.policy) {
    label = form.policy.displayName;
  } else {
    label = 'General Information';
  }

  return label;
};

// allForms is an object {[formId]: formWithAnswers}
// Shape of each form: {
// 	form : { ... }
// 	answers: [
// 		{
// 			questionInstance: {
// 				name
// 				component
// 				label
// 				...
// 			}
// 			value
// 		}
// 	]
// 	inProgress: Boolean;
// 	complete: Boolean
// }
export const getCompanyNameFromForms = (
  allFormsWithAnswers: Record<string, QuoteWizardAnsweredForm>,
) => {
  let legalName;
  let tradeName;
  let actualName;
  for (let form of Object.values(allFormsWithAnswers)) {
    legalName = findValueByApiNameInReduxAnswerList(
      'company_name',
      form.answers,
    );
    tradeName = findValueByApiNameInReduxAnswerList('trade_name', form.answers);

    if (legalName || tradeName) {
      if (legalName && tradeName) {
        actualName = `${legalName} o/a ${tradeName}`;
      } else {
        actualName = legalName ? legalName : tradeName;
      }
      break;
    }
  }
  return actualName;
};

// these two functions are almost identical copies of the ones that exist in QuoteLoader utility.js; these has got to be a way of combining them...
const findValueByApiNameInReduxAnswerList = (
  apiName: OrNullish<string>,
  answerList: Array<QuoteWizardAnswerInstance>,
): undefined | string => {
  if (!answerList || answerList.length === 0) {
    return undefined;
  }
  for (let index = 0; index < answerList.length; index++) {
    const answer = answerList[index];
    if (answer.questionInstance.apiName === apiName) {
      return answer.value === undefined ? undefined : String(answer.value);
    }
    const foundInSubAnswers = findValueByApiNameInReduxAnswerList(
      apiName,
      answer.subAnswers,
    );
    if (foundInSubAnswers !== undefined) {
      return foundInSubAnswers;
    }
  }
  return undefined;
};

export const extractLocationDetailsFromAnswerInstance = (
  locationAnswer: $TSFixMe,
) => {
  const details = {
    opta: {},
    google: {},
    general: {
      id: locationAnswer.id,
      streetAddress: findValueByApiNameInReduxAnswerList(
        'address',
        locationAnswer.subAnswers,
      ),
      city: findValueByApiNameInReduxAnswerList(
        'city',
        locationAnswer.subAnswers,
      ),
      province: findValueByApiNameInReduxAnswerList(
        'province',
        locationAnswer.subAnswers,
      ),
      postalCode: findValueByApiNameInReduxAnswerList(
        'postal',
        locationAnswer.subAnswers,
      ),
    },
  };

  // set the lat/long, if we know them
  if (
    locationAnswer.details &&
    locationAnswer.details.lat !== undefined &&
    locationAnswer.details.lng !== undefined
  ) {
    // @ts-expect-error
    details.general.coordinates = {
      lat: locationAnswer.details.lat,
      lng: locationAnswer.details.lng,
    };
  }

  // attach the images, if possible
  if (locationAnswer.details && locationAnswer.details.images) {
    // @ts-expect-error
    details.images = locationAnswer.details.images.map((gqlImage: $TSFixMe) => {
      const { __typename, ...realImage } = gqlImage;
      return realImage;
    });
  }

  return details;
};

export const dayNumberToString = (dayNumber: unknown) => {
  if (typeof dayNumber !== 'number') {
    throw new Error(
      `Invalid; day number must be a number. You provided ${dayNumber}, a ${typeof dayNumber}`,
    );
  }
  if (dayNumber > 7 || dayNumber < 1) {
    throw new Error(
      `Invalid; day number must be between 1 (Monday) and 7 (Sunday). You provided ${dayNumber}`,
    );
  }
  if (dayNumber !== Math.round(dayNumber)) {
    throw new Error(
      `Invalid; day number must be an integer. You provided ${dayNumber}`,
    );
  }

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  return days[dayNumber - 1];
};

export const convertIsoTimeToReadable = (isoTime: string) => {
  const [hours, minutes, seconds] = isoTime
    .split(':')
    .map((elem) => Number(elem));

  if ((hours === 0 || hours === 24) && !seconds && !minutes) {
    return '12 Midnight';
  }

  const amPm = hours > 12 ? 'PM' : 'AM';
  const twelveHourTime = hours > 12 ? hours - 12 : hours;
  return (
    `${twelveHourTime}` +
    (!minutes && !seconds ? '' : `:${String(minutes).padStart(2, '0')}`) +
    (!seconds ? '' : `:${String(seconds).padStart(2, '0')}`) +
    ` ${amPm}`
  );
};

export const sortReviewForms = (forms: Array<UnifiedCompletedForm>) => {
  const generalInformation = forms.filter((form) => form.form.policy === null);

  const generalLiablility = forms.filter(
    (form) => form.form.policy?.displayName === 'General Liability',
  );
  const property = forms.filter(
    (form) => form.form.policy?.displayName === 'Property',
  );
  const formsFound = [...generalInformation, ...generalLiablility, ...property];
  const otherForms = forms
    .filter(
      (form) =>
        formsFound.find((innerForm) => form === innerForm) === undefined,
    )
    .sort((a, b) =>
      a.form.policy && b.form.policy
        ? a.form.policy?.displayName.localeCompare(b.form.policy?.displayName)
        : 0,
    );

  return [...formsFound, ...otherForms];
};
