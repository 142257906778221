import { graphql } from '../gql';

export const GET_MISSING_POLICIES = graphql(`
  query getMissingPolicies($businessId: ID!, $token: String!) {
    doesGeneralInfoExist(businessId: $businessId, token: $token)
    allEmptyBusinessSpecificPolicies(businessId: $businessId, token: $token) {
      id
      displayName
      internalName
    }
  }
`);
