import { graphql } from '../gql';

// Query the quote form the database
export const GET_QUOTE = graphql(`
  query Quote($uID: String!, $token: String) {
    quote(uniqueID: $uID, token: $token) {
      ...QuoteFields
    }
  }
`);

// Query an archived quote form the database
export const GET_ARCHIVED_QUOTE = graphql(`
  query ArchivedQuote($archiveID: String!, $token: String!) {
    archivedQuote(archiveID: $archiveID, token: $token) {
      ...ArchivedQuoteFields
    }
  }
`);

// get list id and dateadded of archived quote for specific quote
export const GET_QUOTE_VERSION_LIST = graphql(`
  query QuoteVersions($token: String!, $uniqueID: String!) {
    quoteVersions(token: $token, uniqueID: $uniqueID) {
      quoteId
      dateAdded
    }
  }
`);
