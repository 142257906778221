import { Typography } from '@mui/material';
// components
import QualifyingQuestionForm from './QualifyingQuestionForm';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from '../../classes';

interface CreateQualifyingQuestionProps {
  form: ProgramBuilderForm;
  setQualifyingQuestionDirty: $TSFixMe;
}
const CreateQualifyingQuestion = ({
  form,
  setQualifyingQuestionDirty,
}: CreateQualifyingQuestionProps) => {
  const { qualifyingQuestion } = form;

  if (!form.policy) {
    return (
      <Typography align='center' variant='body2' color='textSecondary'>
        General Information forms cannot have qualifying questions
      </Typography>
    );
  }

  return (
    <QualifyingQuestionForm
      parentForm={form}
      isEditing={!!qualifyingQuestion}
      qualifyingQuestion={qualifyingQuestion}
      setQualifyingQuestionDirty={setQualifyingQuestionDirty}
    />
  );
};

export default CreateQualifyingQuestion;
