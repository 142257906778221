import IconButton from '@mui/material/IconButton';
import { ProgramBuilderQuestionInstance } from '../../../../FormManager/classes';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from '@reach/router';

interface EditQuestionButtonProps {
  question: ProgramBuilderQuestionInstance;
}
export const EditQuestionButton = ({ question }: EditQuestionButtonProps) => {
  const navigate = useNavigate();
  return (
    <IconButton
      disabled={question.ancillary.parentForms.length === 0}
      onClick={() =>
        navigate(
          `/insurtech/manager/forms/editbyid/${question.ancillary.parentForms[0].id}`,
        )
      }
    >
      <EditIcon />
    </IconButton>
  );
};
