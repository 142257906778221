/**
 * This is the version of the answered form as it appears in the QuoteWizard when you are in the process of completing a form - it is not the same as a CompletedForm on the backend or when loading in a previously saved quote!
 */
import { cloneDeep } from 'lodash';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { QuoteWizardQuote } from './QuoteWizardQuote';
import { QuoteWizardForm } from './QuoteWizardForm';
import { QuoteWizardAnswerInstance } from '.';
// import { UnifiedAnswerInstance } from '../../../Typescript/classes';
// import type { CompletedFormInput } from '../../../Typescript/backend/inputTypes';

export interface IQuoteWizardAnsweredFormInput {
  form: QuoteWizardForm;
  answers: Array<QuoteWizardAnswerInstance>;
  inProgress: boolean;
  errors?: Array<$TSFixMe>;
  complete?: boolean;
  completedQuote?: QuoteWizardQuote;

  [k: string]: $TSFixMe;
}

export class QuoteWizardAnsweredForm {
  form: QuoteWizardForm;
  answers: Array<QuoteWizardAnswerInstance>;
  inProgress: boolean;
  errors: Array<$TSFixMe>;
  complete: boolean;
  completedQuote?: QuoteWizardQuote;
  [k: string]: $TSFixMe;

  constructor(input: IQuoteWizardAnsweredFormInput) {
    //console.log('Constructing QuoteWizardAnsweredForm from', input);

    const {
      form,
      answers,
      inProgress,
      errors,
      complete,
      completedQuote,
      ...rest
    } = input;
    this.form = form;
    this.answers = answers;
    this.inProgress = inProgress;
    this.errors = errors ? errors : [];
    this.complete = complete ? true : false;
    this.completedQuote = completedQuote;

    for (let [k, v] of Object.entries(rest)) {
      this[k] = v;
    }
  }

  /**
   * return a copy of this QuoteWizardAnsweredForm
   */
  copy() {
    //console.log('Calling QuoteWizardAnsweredForm copy');
    const input = Object.entries(this).reduce((acc, [k, v]) => {
      switch (k) {
        case 'form':
          // @ts-expect-error
          acc[k] = v.copy();
          break;
        case 'answers':
          // @ts-expect-error
          acc[k] = v.map((answer) => answer.copy());
          break;
        default:
          // @ts-expect-error
          acc[k] = cloneDeep(v);
      }
      return acc;
    }, {});
    // @ts-expect-error
    return new QuoteWizardAnsweredForm(input);
  }

  /**
   * Determine whether this QuoteWizardAnsweredForm matches a QuoteWizardForm by policy
   */
  matchQuoteWizardFormByPolicy(other: QuoteWizardForm) {
    return this.form.matchByPolicy(other);
  }

  /**
   * NB this should no longer be handled by this class, but by the UnifiedCompletedForm instead
   * Convert to a CompletedFormInput for sending to the backend
   */
  // toCompletedFormInput(): CompletedFormInput {
  //   return {
  //     policy: this.form.policy ? this.form.policy.id : null,
  //     answers: this.answers.map((answer) => answer.toAnswerInstanceInput()),
  //   };
  // }
}
