import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import { QUESTIONS_TABLE_QUESTIONS } from '../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';
import LoadingScreen from '../../../components/LoadingScreen';
import { ProgramBuilderQuestionInstance } from '../../../../FormManager/classes';
import { EditQuestionButton } from './EditQuestionButton';
import Scrollbar from '../../../components/Scrollbar';
import { QuestionsTableSearch } from './QuestionsTableSearch';
import { SelectQuestionsPerPage } from './SelectQuestionsPerPage';
import { QuestionsPerPageOptions } from './SelectQuestionsPerPage';
import { useAllowByEmailDomain } from '../../../../../hooks';
import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const sortOptions = ['asc', undefined, 'desc'] as const;
type SortOptions = typeof sortOptions[number];

const columns = [
  {
    label: 'API Name',
    sortable: true,
    sorter: (
      a: ProgramBuilderQuestionInstance,
      b: ProgramBuilderQuestionInstance,
    ) => {
      return a.apiName.localeCompare(b.apiName);
    },
  },
  {
    label: 'Display Name',
    sortable: true,
    sorter: (
      a: ProgramBuilderQuestionInstance,
      b: ProgramBuilderQuestionInstance,
    ) => {
      return a.displayNames
        .map((displayName) => displayName.value)
        .join('; ')
        .localeCompare(
          b.displayNames.map((displayName) => displayName.value).join('; '),
        );
    },
  },
  {
    label: 'Label',
    sortable: true,
    sorter: (
      a: ProgramBuilderQuestionInstance,
      b: ProgramBuilderQuestionInstance,
    ) => {
      return a.labels
        .map((label) => label.value)
        .join('; ')
        .localeCompare(b.labels.map((label) => label.value).join('; '));
    },
  },
  {
    label: 'Component',
    sortable: true,

    sorter: (
      a: ProgramBuilderQuestionInstance,
      b: ProgramBuilderQuestionInstance,
    ) => {
      return a.component.localeCompare(b.component);
    },
  },
  {
    label: '# Forms',
    sortable: true,
    sorter: (
      a: ProgramBuilderQuestionInstance,
      b: ProgramBuilderQuestionInstance,
    ) => a.ancillary.parentForms.length - b.ancillary.parentForms.length,
  },
  { label: 'Edit' },
];

const tableColumns = [
  'API Name',
  'Display Name',
  'Label',
  'Component',
  '# Forms',
  'Edit',
] as const;
type TableColumns = typeof tableColumns[number];
// @ts-expect-error
const sorters: {
  [key in TableColumns]?: (
    a: ProgramBuilderQuestionInstance,
    b: ProgramBuilderQuestionInstance,
  ) => number;
} = {};

export const QuestionsTable = () => {
  const hasDomainPermissions = useAllowByEmailDomain('calefy.ca');
  const { token } = useAuth();
  const [questions, setQuestions] = useState<
    Array<ProgramBuilderQuestionInstance>
  >([]);
  const [sortedQuestions, setSortedQuestions] = useState<
    Array<ProgramBuilderQuestionInstance>
  >([]);
  const [search, setSearch] = useState<string>('');
  const [fuzzy, setFuzzy] = useState<boolean>(false); // should we use a fuzzy or exact search on the string?
  const [searchTimeout, setSearchTimeout] =
    useState<ReturnType<typeof setTimeout>>();
  const [questionsPerPage, setQuestionsPerPage] =
    useState<QuestionsPerPageOptions>(25);
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [sortState, setSortState] = useState<{
    [key in TableColumns]?: SortOptions;
  }>({});
  const [getQuestions, { loading, error, data }] = useLazyQuery(
    QUESTIONS_TABLE_QUESTIONS,
    {
      onCompleted: (data) => {
        if (data.questionsTableQuestions?.pageInfo.hasNextPage) {
          setHasNextPage(true);
        }
        if (data.questionsTableQuestions?.pageInfo.hasPreviousPage) {
          setHasPreviousPage(true);
        }
        if (data.questionsTableQuestions?.edges) {
          setQuestions(
            data.questionsTableQuestions.edges.map((edge) => {
              return ProgramBuilderQuestionInstance.generateFromBackendResponse(
                edge?.node,
              );
            }),
          );
        }
      },
    },
  );

  // get the search question instances whenever the search term changes
  // (also gets the inital questions)
  useEffect(() => {
    if (token && !search) {
      getQuestions({
        variables: {
          token,
          search,
          first: questionsPerPage,
          fuzzy,
        },
      });
    }
  }, [token, search, questionsPerPage]);

  useEffect(() => {
    if (token && search) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(
        setTimeout(() => {
          getQuestions({
            variables: {
              token,
              search,
              first: questionsPerPage,
              fuzzy,
            },
          });
        }, 1000),
      );
    }
  }, [token, search, fuzzy, questionsPerPage]);

  // set the sorted questions once the original questions are present and the sorters are there
  useEffect(() => {
    const sortLabel =
      Object.keys(sortState).length > 0 ? Object.keys(sortState)[0] : undefined;
    if (!sortLabel) {
      setSortedQuestions(questions);
      return;
    }
    // @ts-expect-error
    const sortDirection = sortState[sortLabel];
    if (!sortDirection) {
      setSortedQuestions(questions);
      return;
    }
    const sorter = (columns.find((column) => column.label === sortLabel) || {})
      .sorter;
    if (!sorter) {
      setSortedQuestions(questions);
      return;
    }
    const sortAdjustment = sortDirection === 'desc' ? -1 : 1;
    const sorted = [...questions].sort((a, b) => sortAdjustment * sorter(a, b));
    setSortedQuestions(sorted);
  }, [questions, sortState]);

  if (!hasDomainPermissions) {
    return <InsufficientPermissionsErrorPage />;
  }

  return (
    <QuestionsTableContainer>
      <QuestionsTableSearch
        filterName={search}
        onFilterName={(e) => {
          setSearch(e.target.value);
        }}
        isLoading={false}
        fuzzy={fuzzy}
        setFuzzy={setFuzzy}
      />
      {loading ? <LoadingScreen /> : null}
      {error ? (
        <>
          <Typography variant='h2'>An Error Occurred!</Typography>
          <pre>{JSON.stringify(error, null, 4)}</pre>
        </>
      ) : null}
      {questions ? (
        <Scrollbar sx={{ minWidth: 800, minHeight: 400 }}>
          <TableContainer>
            <Table
              sx={{
                maxWidth: '100%',
                '& td:nth-of-type(1)': {
                  // maxWidth: '30%',
                  wordBreak: 'break-all',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    const { label, sortable } = column;
                    return (
                      <TableCell key={label} scope='col'>
                        <TableHeaderContents>
                          {label}{' '}
                          {sortable ? (
                            <KeyboardArrowUpIcon
                              data-sort={
                                // @ts-expect-error
                                sortState[label] === undefined
                                  ? 'none'
                                  : // @ts-expect-error
                                    sortState[label]
                              }
                              onClick={() => {
                                setSortState((oldSortState) => {
                                  // @ts-expect-error
                                  const oldSort = oldSortState[label];
                                  const newSort =
                                    sortOptions[
                                      (sortOptions.indexOf(oldSort) + 1) %
                                        sortOptions.length
                                    ];
                                  return { [label]: newSort };
                                });
                              }}
                              sx={{
                                transform:
                                  // @ts-expect-error
                                  sortState[label] == undefined
                                    ? 'rotate(90deg)'
                                    : // @ts-expect-error
                                    sortState[label] == 'asc'
                                    ? 'rotate(0deg)'
                                    : // @ts-expect-error
                                    sortState[label] == 'desc'
                                    ? 'rotate(180deg)'
                                    : undefined,
                                transition: 'transform 0.5s ease-in-out',
                              }}
                            />
                          ) : null}
                        </TableHeaderContents>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedQuestions.length === 0 ? (
                  <TableCell colSpan={6}>No questions found</TableCell>
                ) : null}
                {sortedQuestions.map((question) => (
                  <TableRow key={question.id}>
                    <TableCell>{question.apiName}</TableCell>
                    <TableCell>
                      {question.displayNames
                        .map((displayName) => displayName.value)
                        .join('; ')}
                    </TableCell>
                    <TableCell>
                      {question.labels.map((label) => label.value).join('; ')}
                    </TableCell>
                    <TableCell>{question.component}</TableCell>
                    <TableCell>
                      {question.ancillary.parentForms.length}
                    </TableCell>
                    <TableCell>
                      <EditQuestionButton question={question} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableActionsContainer>
              <IconButton
                disabled={!token || !hasPreviousPage}
                onClick={() => {
                  if (token) {
                    getQuestions({
                      variables: {
                        token,
                        search,
                        last: questionsPerPage,
                        before:
                          data?.questionsTableQuestions?.pageInfo.startCursor,
                        fuzzy,
                      },
                    });
                    setHasNextPage(true);
                    setHasPreviousPage(false);
                  }
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <SelectQuestionsPerPage
                questionsPerPage={questionsPerPage}
                setQuestionsPerPage={setQuestionsPerPage}
              />
              <IconButton
                disabled={!token || !hasNextPage}
                onClick={() => {
                  if (token) {
                    getQuestions({
                      variables: {
                        token,
                        search,
                        first: questionsPerPage,
                        after:
                          data?.questionsTableQuestions?.pageInfo.endCursor,
                        fuzzy,
                      },
                    });
                    setHasNextPage(false);
                    setHasPreviousPage(true);
                  }
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </TableActionsContainer>
          </TableContainer>
        </Scrollbar>
      ) : null}
    </QuestionsTableContainer>
  );
};

const QuestionsTableContainer = styled('div')(() => {
  return {};
});

const TableActionsContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const TableHeaderContents = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    "& [data-sort='none']": {
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 0.5s, visibility 0.5s',
    },
    "&:hover [data-sort='none']": {
      opacity: 1,
      visibility: 'visible',
    },
  };
});
