import { graphql } from '../gql';

export const GENERATE_QUOTE_CONFIRMATION_CORRESPONDENCE = graphql(`
  mutation generateQuoteConfirmationCorrespondence(
    $token: String!
    $uuid: String!
  ) {
    generateQuoteConfirmationCorrespondence(token: $token, uuid: $uuid) {
      ok
      hash
    }
  }
`);
