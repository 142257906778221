import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import ReactApexChart from 'react-apexcharts';
import { getIcon } from '../../../utils/percentIcon';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
// utils
import { fNumber, fPercent } from '../../../utils/formatNumber';
// hooks
import { useAuth, } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import TotalTodaySkeleton from './skeletons/TotalTodaySkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { TotalPoliciesTodayMock } from './Mock';
// queries
import { DASHBOARD_TOTAL_POLICIES_TODAY } from '../../../../../queries';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

export default function AppTotalPoliciesToday() {
  const theme = useTheme();
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const { data, loading, error } = useQuery(DASHBOARD_TOTAL_POLICIES_TODAY, {
    variables: { token },
    skip:
      hasDashboardPermissions !== true ||
      !token ||
      slug === 'lastnameinsurance',
  });
  const [dailyPolicies, setDailyPolicies] = useState<number[]>([]);
  const [percent, setPercent] = useState<number>();
  const [policiesToday, setPoliciesToday] = useState<number>();
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setDailyPolicies(TotalPoliciesTodayMock.dailyPolicies);
      setPercent(TotalPoliciesTodayMock.percent);
      setPoliciesToday(TotalPoliciesTodayMock.policiesToday);
      setOkToDisplay(true);
    } else if (data && data.dailyPoliciesData) {
      // @ts-expect-error
      setDailyPolicies(data.dailyPoliciesData.dailyPolicies);
      // @ts-expect-error
      setPercent(data.dailyPoliciesData.weeklyPolicyPercentDiff);
      // @ts-expect-error
      setPoliciesToday(data.dailyPoliciesData.policiesToday);
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  if (hasDashboardPermissions !== true) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if (
    (loading || !okToDisplay || hasDashboardPermissions === undefined) &&
    !error
  )
    return <TotalTodaySkeleton />;
  if (error) {
    Bugsnag.notify(`Could not load Total Policies Today component, ${error}`);
    return handleDashboardError(error);
  }

  const CHART_DATA = [{ data: dailyPolicies }];

  const chartOptions = {
    colors: [theme.palette.error.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (_seriesName: string) => `Daily Policies`,
        },
      },
      marker: { show: false },
    },
  };

  return (
    <Card
      sx={{ display: 'flex', alignItems: 'center', p: 3 }}
      data-testid='app-total-policies-today'
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2'>Total Policies Today</Typography>

        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{ mt: 2, mb: 1 }}
        >
          {percent !== null && percent !== undefined ? (
            <>
              <IconWrapperStyle
                sx={{
                  ...(percent < 0 && {
                    color: 'error.main',
                    bgcolor: alpha(theme.palette.error.main, 0.16),
                  }),
                  ...(percent === 0 && {
                    color: theme.palette.grey[500],
                    bgcolor: alpha(theme.palette.grey[400], 0.16),
                  }),
                }}
              >
                <Icon width={16} height={16} icon={getIcon(percent)} />
              </IconWrapperStyle>
              <Typography component='span' variant='subtitle2'>
                {percent > 0 && '+'}
                {fPercent(percent)}
              </Typography>
              <Typography component='span' variant='caption'>
                Last 7 Days
              </Typography>
            </>
          ) : (
            <Icon width={24} height={24} icon={getIcon(percent)} />
          )}
        </Stack>

        <Typography variant='h3'>{fNumber(policiesToday)}</Typography>
      </Box>

      <ReactApexChart
        type='bar'
        series={CHART_DATA}
        //@ts-expect-error
        options={chartOptions}
        width={60}
        height={36}
      />
    </Card>
  );
}
