import { Redirect, Router } from '@reach/router';
import {
  ContactInfoIframe,
  PersonalCommercialChoiceButtons,
  GenericBusinessGetQuoteButton,
} from './components';
import { ThanksForCompletingContactInformation } from './ThanksForCompletingContactInformation';
import { Load } from './Load';

// types and classes
import { RouteComponentProps } from '@reach/router';

export const PATH_TO_CONTACT_INFO_TO_ANY_BUSINESS = 'contactinfotobusiness';

interface IframeProps extends RouteComponentProps {}

/**
 * Wrapper around the different components that we'll display in iframes on people's sites
 */
export const Iframe = (_props: IframeProps) => {
  return (
    <Router>
      <ContactInfoIframe
        path='contactinfotobusinessselection'
        dataTestId='iframe-contact-info-to-business-selection'
      />
      <ContactInfoIframe
        path='contactinfotopersonal'
        businessId='personal'
        dataTestId='iframe-contact-info-to-personal'
      />
      <ContactInfoIframe
        path='contactinfotocommercial'
        toBusiness={true}
        dataTestId='iframe-contact-info-to-commercial'
      />
      <ContactInfoIframe
        path='contactinfochoice'
        toBusiness={true}
        ActionButtons={PersonalCommercialChoiceButtons}
        dataTestId='iframe-contact-info-choice'
      />
      <ContactInfoIframe
        path={PATH_TO_CONTACT_INFO_TO_ANY_BUSINESS}
        ActionButtons={GenericBusinessGetQuoteButton}
        dataTestId='iframe-contact-info-to-any-business'
      />
      <Load path='load' />
      <ThanksForCompletingContactInformation path='thanks/contact' />

      <Redirect
        from='*'
        to='/insurtech/iframe/contactinfotobusinessselection'
        default
        noThrow
      />
    </Router>
  );
};
