import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from '@reach/router';

// classes and types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import type { Quote } from '../../../../Typescript/backend/classes';

interface BaseInvalidConfirmationStatusErrorProps {
  hash: Quote['uniqueId'];
  status: Quote['status'];
  classes: GenericObject;
}
export const BaseInvalidConfirmationStatusError = ({
  hash,
  status,
  classes = {},
}: BaseInvalidConfirmationStatusErrorProps) => {
  const navigate = useNavigate();
  const quoteLocation = '/insurtech/quote';
  return (
    <div
      className={classes.outerContainer}
      data-testid='invalid-confirmation-status-error'
    >
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          Invalid Status - {status}
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          The application which you are trying to confirm with identifier {hash}{' '}
          cannot be confirmed at the current time as it has an invalid status.
          This usually means that it has already been confirmed or has been
          saved for later. If you believe that this is an error, please contact
          your agent or contact Calefy support at{' '}
          <a
            href={`mailto:support@calefy.ca?subject=Unable to Confirm Application With Correspondence ${hash} Due to Invalid Status`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>

        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => navigate(quoteLocation)}
        >
          Return to Applications
        </Button>
      </div>
    </div>
  );
};

export const InvalidConfirmationStatusError = withStyles((theme) => ({
  outerContainer: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <BaseInvalidConfirmationStatusError {...props} />);
