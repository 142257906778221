import { graphql } from '../gql';

export const CREATE_FINAL_FORM_MODIFIER = graphql(`
  mutation createFinalFormModifier(
    $existingFormId: ID
    $businessId: ID
    $policyId: ID
    $questionInstances: [QuestionInstanceInput]!
    $apiNamesForRemoval: [String]
    $token: String!
  ) {
    createFinalFormModifier(
      existingFormId: $existingFormId
      businessId: $businessId
      policyId: $policyId
      questionInstances: $questionInstances
      apiNamesForRemoval: $apiNamesForRemoval
      token: $token
    ) {
      finalFormModifier {
        id
        business {
          id
          displayName
        }
        policy {
          id
          displayName
        }
        questionInstances {
          ...QuestionInstanceFields
          subQuestions {
            ...QuestionInstanceFields
            subQuestions {
              ...QuestionInstanceFields
              subQuestions {
                ...QuestionInstanceFields
                subQuestions {
                  ...QuestionInstanceFields
                  subQuestions {
                    ...QuestionInstanceFields
                    subQuestions {
                      ...QuestionInstanceFields
                      subQuestions {
                        ...QuestionInstanceFields
                        subQuestions {
                          ...QuestionInstanceFields
                          subQuestions {
                            ...QuestionInstanceFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        apiNamesForRemoval
      }
    }
  }
`);
