import { ComponentType } from 'react';
import { Form } from '@calefy-inc/informedMaterial';
import RenderedQuestionInstance from '../../common/QuoteComponents/RenderedQuestionInstance';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';
import { RouteComponentProps } from '@reach/router';
import { Language } from '../../../Typescript/classes';

import contactInfoJson from './data/contactInfoNameEmailPhone.json';
import { withFormStyles } from '../../../util/withFormStyles';
import { createStyles } from '@mui/styles';
import type { WithFormStyles } from '../../../util/withFormStyles';
import type { BusinessLinkProps } from '../../BusinessLink/BusinessLink';
import { generateGenerateNavigationDestination } from '../utility';
import { DefaultActionButton } from './DefaultActionButton';

// types and classes
import type { ActionButtonProps } from './types';

export const generateNavigationDestination =
  generateGenerateNavigationDestination('/insurtech/iframe/thanks/contact');

const additionalStyles = createStyles({
  outerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '8px 0',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const questionInstances = [
  ProgramBuilderQuestionInstance.generateFromBackendResponse(
    contactInfoJson,
  ).toQuoteWizardQuestionInstance(Language.english),
];

interface ContactInfoIframeProps
  extends RouteComponentProps,
    WithFormStyles<typeof additionalStyles> {
  businessId?: BusinessLinkProps['businessId'];
  policies?: Array<string>;
  toBusiness?: boolean; // should the redirect be to the business selection for commercial (as opposed to personal)?
  ActionButtons?: ComponentType<ActionButtonProps>;
  dataTestId?: string;
}

export const ContactInfoIframe = withFormStyles(additionalStyles)(
  // @ts-expect-error
  ({
    classes,
    businessId,
    policies,
    toBusiness,
    ActionButtons = DefaultActionButton,
    dataTestId,
  }: ContactInfoIframeProps) => {
    return (
      <>
        <div className={classes.outerContainer} data-testid={dataTestId}>
          <Form className={classes.form}>
            {({ formApi }) => {
              return (
                <>
                  {questionInstances.map((questionInstance) => (
                    <RenderedQuestionInstance
                      questionInstance={questionInstance}
                      classes={classes}
                    />
                  ))}
                  <div className={classes.buttonContainer}>
                    <ActionButtons
                      formApi={formApi}
                      businessId={businessId}
                      policies={policies || []}
                      toBusiness={toBusiness === true}
                      questionInstances={questionInstances}
                    />
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      </>
    );
  },
);
