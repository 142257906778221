// Types
export enum EventActions {
  PageLoad = 'page_load',
  Selection = 'selection',
  Click = 'click',
  Saved = 'saved',
}

export enum GAEventNames {
  Business = 'business',
  Contact = 'contact',
  Policy = 'policy',
  GeneralInformation = 'general_information',
  GeneralLiability = 'general_liability',
  Property = 'property',
  Review = 'review',
  Success = 'success',
}

export enum Products {
  // Home = 'home',
  // Auto = 'auto',
  // Tenant = 'tenant',
  // Travel = 'travel',
  Commercial = 'commercial',
}

export enum Environments {
  Development = 'development',
  Production = 'production',
}

export type GoogleAnalyticsEvent = {
  action: EventActions;
  sponsorCode: string;
  description: string;
  product: Products;
  environment: string;
};
