import {
  useState,
  useEffect,
  useRef,
  // useLayoutEffect,
  useCallback,
  ReactNode,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { QuoteWizardState } from '../../../store';
import { useParams } from '@reach/router';
// material
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Check from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { useLazyQuery } from '@apollo/client';
import { withStyles } from '@mui/styles';
import { Form, FormValues, FormApi } from 'informed';
import { Checkbox, MultiTileSelector } from '@calefy-inc/informedMaterial';
import RenderedQuestionInstance from '../../common/QuoteComponents/RenderedQuestionInstance';
import { NoAvailablePolicies } from './ErrorComponents';
import { NextButton, BackButton } from '../NavigationButtons';
import { SelectQuestionsPerPage } from '../SelectQuestionsPerPage';
import { BusinessSpecificFormQuery } from '../../../queries';
import {
  hideSaveButton,
  setCurrentFormApi,
  actions,
  setSkipPolicySelection,
} from '../../../store/QuoteWizardState';
import {
  handleErrors,
  DisconnectionError,
  HandleableError,
} from '../../common/ErrorHandling';
import {
  useWarnBeforeUnloadWithAnswers,
  useSettings,
  useTileSelectorSettings,
} from '../../../hooks';
import { useScrollToTopOnInitialMount } from '@calefy-inc/hooks';
import { LanguageSelection } from '../LanguageSelection';
import { DataLossWarning } from '../QuoteBusinessSelection/DataLossWarning';
import { createFormLoadedThunk } from '../../../store/analyticsStore';

// types and classes
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { Language } from '../../../Typescript/classes';
import { QuoteWizardForm } from '../classes';
import { PolicySummary } from '../types';
import { ProgramBuilderForm } from '../../FormManager/classes';
import { Dispatch, StoreState } from '../../../App';
import { StepWizardChildProps } from 'react-step-wizard';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';
import {
  vagoSettings,
  VAGO_POLICY_INTERNAL_NAMES,
} from '../../../WhiteLabelSettings/VagoAssure';
import { useWalnutGoogleAnalytics } from '../../../hooks';
import {
  GAEventNames,
  EventActions,
} from '../../../hooks/useWalnutGoogleAnalytics';
import { createPageLoadThunk } from '../../../store/analyticsStore';

// Returns true if object is empty
const isEmpty = (inputObject: object) => {
  return Object.keys(inputObject).length === 0;
};

// Validate that there is a least one chosen policy
const validate = (values: FormValues) => {
  /*console.log('Calling validate with', values); */
  return Object.values(values).indexOf(true) === -1 &&
    Object.values(values).indexOf('yes') === -1 &&
    (!values.policyTypes ||
      (Array.isArray(values.policyTypes) && values.policyTypes.length === 0))
    ? "At least one policy or 'Yes' is required to proceed"
    : undefined;
};

function getSelectedBusinessName(state: StoreState): string {
  const businessType = state.quoteWizard.selectedBusinessType;
  if (!businessType) {
    return '';
  }
  if (!businessType?.displayName) {
    return '';
  }
  return businessType.displayName;
}

function getRenewalInProgress(
  state: StoreState,
): StoreState['quoteWizard']['renewalInProgress'] {
  return state.quoteWizard.renewalInProgress;
}

function getSelectedInsurType(state: StoreState) {
  return state.quoteWizard.selectedInsurType;
}

interface QuotePolicySelectionDisplayProps extends StepWizardChildProps {
  onSubmit: (inputs: {
    selectedPolicies: Array<PolicySummary>;
    businessForm: QuoteWizardForm | {};
    policyForms: Array<QuoteWizardForm>;
    options: { overwrite: boolean };
  }) => void;
  businessForm: ProgramBuilderForm;
  policyForms: Array<ProgramBuilderForm>;
  allAvailablePolicyForms: Array<PolicySummary>;
  selectedPolicies: Array<PolicySummary>;
  formsLoaded: boolean;
  classes: GenericObject;
  availableLanguages: Array<Language>;
  selectedLanguage: Language;
}
/**
 * Displays a list of the available policy forms for the particular business, along with checkboxes to select the relevant ones. Also responsible for setting these in the store state
 * @param onSubmit - function to handle submission of the form. Should set the selected policies correctly
 * @param businessForm - the General Information form
 * @param policyForms - description
 * @param availablePolicyForms - description
 * @param selectedPolicies -  the policy summary for the selected policies
 * @param classes - The theming classes
 * @param availableLanguages - A list of the languages for which at least one available form has questions
 */
export function QuotePolicySelectionDisplay({
  onSubmit,
  businessForm,
  policyForms,
  allAvailablePolicyForms,
  selectedPolicies,
  nextStep,
  previousStep,
  formsLoaded,
  classes = {},
  availableLanguages,
  selectedLanguage,
  currentStep,
  totalSteps,
}: QuotePolicySelectionDisplayProps) {
  /*console.log({ selectedPolicies }); */
  useWarnBeforeUnloadWithAnswers();
  useScrollToTopOnInitialMount();

  /* Redux State */
  const selectedInsurType = useSelector(getSelectedInsurType);

  /* Local State */
  const { personalInsurance } = useSettings();
  const { settings, tileClasses } = useTileSelectorSettings();
  const params = useParams();
  const formApiRef = useRef<FormApi>();
  const dispatch = useDispatch();
  const selectedBusinessName = useSelector(getSelectedBusinessName);
  const renewalInProgress = useSelector(getRenewalInProgress);
  /*console.log({ renewalInProgress }); */
  const [availablePolicyForms, setAvailablePolicyForms] = useState<
    Array<PolicySummary>
  >(allAvailablePolicyForms);
  const [requiredPolicyForms, setRequiredPolicyForms] = useState<
    Array<PolicySummary>
  >([]);
  const [optionalPolicyForms, setoptionalPolicyForms] = useState<
    Array<PolicySummary>
  >([]);
  const [defaultPolicy, setDefaultPolicy] = useState<boolean>(false); // is there a required policy?
  const [blocked, setBlocked] = useState<boolean>(true);
  const [pendingNavType, setPendingNavType] = useState<
    'forward' | 'backward' | undefined
  >();
  const [warningInfo, setWarningInfo] = useState({
    open: false,
    message: '',
    isNavBack: false,
  });
  const [onVago, setOnVago] = useState<boolean>(false);
  const googleAnalytics = useWalnutGoogleAnalytics();

  /* State Modifiers */
  // see if we're on vago
  useEffect(() => {
    if (settings === vagoSettings) {
      setOnVago(true);
    }
  }, [settings]);

  // make the analytics call once the page loads
  useEffect(() => {
    if (googleAnalytics) {
      googleAnalytics.triggerEvent(
        GAEventNames.Policy,
        EventActions.PageLoad,
        '',
      );
    }
  }, [googleAnalytics]);

  // on page load, make the analytics call
  useEffect(() => {
    dispatch(
      createPageLoadThunk({
        page: 'Policy Selection',
        pageNumber: currentStep,
        totalPages: totalSteps,
      }),
    );
  }, []);

  // if we're on vago, then set the policies and navigate forward
  useEffect(() => {
    if (!onVago) {
      return;
    }
    if (selectedPolicies.length > 0) {
      return;
    }
    const matchingPolicies = availablePolicyForms.filter((availableForm) =>
      VAGO_POLICY_INTERNAL_NAMES.includes(availableForm.internalName),
    );
    if (matchingPolicies.length !== VAGO_POLICY_INTERNAL_NAMES.length) {
      Bugsnag.notify(
        new Error(
          `Unable to match all policies ${JSON.stringify(
            VAGO_POLICY_INTERNAL_NAMES,
            null,
            4,
          )} to ${JSON.stringify(availablePolicyForms, null, 4)}`,
        ),
      );
      return;
    }
    const input = {
      selectedPolicies: matchingPolicies,
      /* businessForm, */
      businessForm: selectedLanguage.inLanguageList(
        businessForm.getAvailableLanguages(),
      )
        ? businessForm.toQuoteWizardForm(selectedLanguage)
        : {},
      /* policyForms, */
      policyForms: policyForms.reduce(
        (acc: Array<QuoteWizardForm>, policyForm) => {
          if (
            selectedLanguage.inLanguageList(policyForm.getAvailableLanguages())
          ) {
            return [...acc, policyForm.toQuoteWizardForm(selectedLanguage)];
          }
          return acc;
        },
        [],
      ),
      options: {
        // This is here to avoid overwriting partial renewal forms
        overwrite: !renewalInProgress,
      },
    };
    onSubmit(input);
    setBlocked(false);
    setPendingNavType('forward');
  }, [onVago, availablePolicyForms, renewalInProgress, selectedPolicies]);

  // hide the save button
  useEffect(() => {
    dispatch(hideSaveButton());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // change the available forms based on the selected language and the languages of the forms
  useEffect(() => {
    const newAvailableForms = allAvailablePolicyForms.filter(
      (availablePolicyForm) =>
        selectedLanguage.inLanguageList(availablePolicyForm.availableLanguages),
    );
    setAvailablePolicyForms((oldAvailableForms) => {
      if (
        oldAvailableForms.length === newAvailableForms.length &&
        newAvailableForms.every((newForm) =>
          oldAvailableForms.some((oldForm) => newForm.id === oldForm.id),
        )
      ) {
        /*console.log( */
        /*   'QuotePolicySelection: old and new availableForms are the same - not changing', */
        /* ); */
        return oldAvailableForms;
      }
      /*console.log( */
      /*   'QuotePolicySelection: old and new availableForms are different - changing', */
      /* ); */
      return newAvailableForms;
    });
  }, [selectedLanguage, allAvailablePolicyForms]);

  // Update the `defaultPolicy` value if there is a required form
  useEffect(() => {
    setDefaultPolicy(
      availablePolicyForms.some((policySummary) => policySummary.required),
    );
  }, [availablePolicyForms]);

  // if all of the forms are required, just go to the next page
  useEffect(() => {
    try {
      if (
        availablePolicyForms &&
        availablePolicyForms.length > 0 &&
        availablePolicyForms.every(
          (policyForm: $TSFixMe) => policyForm.required,
        ) &&
        Object.keys(businessForm).length > 0
      ) {
        const input = {
          selectedPolicies: availablePolicyForms,
          /* businessForm, */
          businessForm: selectedLanguage.inLanguageList(
            businessForm.getAvailableLanguages(),
          )
            ? businessForm.toQuoteWizardForm(selectedLanguage)
            : {},
          /* policyForms, */
          policyForms: policyForms.reduce(
            (acc: Array<QuoteWizardForm>, policyForm) => {
              if (
                selectedLanguage.inLanguageList(
                  policyForm.getAvailableLanguages(),
                )
              ) {
                return [...acc, policyForm.toQuoteWizardForm(selectedLanguage)];
              }
              return acc;
            },
            [],
          ),
          options: {
            // This is here to avoid overwriting partial renewal forms
            overwrite: !renewalInProgress,
          },
        };
        onSubmit(input);
        setPendingNavType('forward');
        setBlocked(false);
        dispatch(setSkipPolicySelection());
      }
    } catch (e) {
      console.error('Error navigating to next step:', e);
      Bugsnag.notify(errorify(e));
    }
  }, [
    availablePolicyForms,
    businessForm,
    dispatch,
    onSubmit,
    policyForms,
    renewalInProgress,
    selectedLanguage,
  ]);

  // initialize the form values with selected policies
  let selectedData = {};
  if (selectedInsurType === 'personal') {
    selectedData = { policyTypes: selectedPolicies.map((policy) => policy.id) };
  } else {
    selectedData = selectedPolicies.reduce((acc: $TSFixMe, selectedPolicy) => {
      acc[selectedPolicy.displayName] = true;
      if (selectedPolicy.qualifyingQuestion) {
        acc[selectedPolicy.qualifyingQuestion.apiName] = 'yes';
      }
      return acc;
    }, {});
  }
  /*console.log({ selectedData }); */

  // initialize not selected data
  // TODO fix this - undefined shouldn't be a key
  const notSelectedData = availablePolicyForms.reduce(
    (acc: $TSFixMe, availablePolicyForm: PolicySummary) => ({
      ...acc,
      [availablePolicyForm.qualifyingQuestion
        ? availablePolicyForm.qualifyingQuestion.apiName
        : undefined]: 'no',
    }),
    {},
  );

  // Initial formadata is used when form is originally loaded and no polcies have been selected.
  const initialFormData = {};
  // Used to initialize form when policies have been selected
  const formData = renewalInProgress
    ? selectedPolicies.reduce(
        (acc: $TSFixMe, selectedPolicy) => ({
          ...acc,
          [selectedPolicy.displayName]: true,
        }),
        {},
      )
    : { ...notSelectedData, ...selectedData };
  /*console.log({ formData }); */

  const handleFormSubmission = useCallback(
    (data) => {
      // Data will provide a key value object of [displayName]: boolean
      // Use this to grab the actual policy objects, and then send those to redux
      if (renewalInProgress === undefined) {
        /*console.log( */
        /*   'Unable to ascertain value for renewalInProgress - not submitting', */
        /* ); */
        return;
      }
      const newSelectedPolicies = renewalInProgress
        ? selectedPolicies
        : availablePolicyForms.filter((policy) => {
            // Filter toggled policies
            if (
              data[
                policy.qualifyingQuestion && policy.qualifyingQuestion !== null
                  ? policy.qualifyingQuestion.apiName
                  : null
              ] === 'yes'
            ) {
              return true;
            }
            // Filter checked policies
            if (data[policy.displayName] === true) {
              return true;
            }

            // Filter required polices
            if (policy.required === true) {
              return true;
            }

            if (
              data.policyTypes &&
              Array.isArray(data.policyTypes) &&
              data.policyTypes.includes(policy.id)
            ) {
              return true;
            }
            return false;
          });
      /*console.log( */
      /*   'About to call onSubmit in handleFormSubmission. Language:', */
      /*   selectedLanguage, */
      /* ); */
      onSubmit({
        selectedPolicies: newSelectedPolicies,
        /* businessForm, */
        businessForm: selectedLanguage.inLanguageList(
          businessForm.getAvailableLanguages(),
        )
          ? businessForm.toQuoteWizardForm(selectedLanguage)
          : {},
        /* policyForms, */
        policyForms: policyForms.reduce(
          (acc: Array<QuoteWizardForm>, policyForm) => {
            if (
              selectedLanguage.inLanguageList(
                policyForm.getAvailableLanguages(),
              )
            ) {
              /*console.log( */
              /*   'About to convert using language', */
              /*   selectedLanguage, */
              /*   policyForm, */
              /* ); */
              return [...acc, policyForm.toQuoteWizardForm(selectedLanguage)];
            }
            return acc;
          },
          [],
        ),
        options: {
          overwrite: !renewalInProgress,
        },
      });
    },
    [
      availablePolicyForms,
      businessForm,
      onSubmit,
      policyForms,
      selectedLanguage,
      renewalInProgress,
      selectedPolicies,
    ],
  );

  // hide the save button
  useEffect(() => {
    dispatch(hideSaveButton());
  }, [dispatch]);

  // navigate when possible
  useEffect(() => {
    if (!blocked && formsLoaded && pendingNavType) {
      if (pendingNavType === 'forward') {
        [businessForm, ...policyForms]
          .filter((form) => Object.keys(form).length > 0)
          // @ts-expect-error
          .forEach((form) => dispatch(createFormLoadedThunk(form)));
        nextStep();
      } else if (pendingNavType === 'backward') {
        previousStep();
      } else {
        console.error(
          `pendingNavType was ${pendingNavType}, which was not forward or backward. Not navigating`,
        );
      }
    }
  }, [blocked, formsLoaded, nextStep, pendingNavType, previousStep]);

  // set the required / optional policy forms once we have all of them
  useEffect(() => {
    const optional = availablePolicyForms.filter(
      (policyForm) => !policyForm.required,
    );
    const required = availablePolicyForms.filter(
      (policyForm) => policyForm.required,
    );
    setRequiredPolicyForms(required);
    setoptionalPolicyForms(optional);
  }, [availablePolicyForms]);

  if (!availablePolicyForms || availablePolicyForms.length === 0) {
    return (
      <>
        <NoAvailablePolicies previousStep={previousStep} />
      </>
    );
  }

  const policyOptions = availablePolicyForms.map((policy: $TSFixMe) => ({
    value: policy.id,
    label: policy.displayName,
    tileIdentifier: policy.internalName,
  }));

  /**
   * Closes business change alert dialog
   */
  const handleClose = () => {
    setWarningInfo((prevState) => ({
      ...prevState,
      open: false,
      isNavBack: false,
    }));
  };

  return (
    <>
      <LanguageSelection availableLanguages={availableLanguages} />
      <Form
        className={classes.form}
        id='Policies'
        data-testid='policy-selection-wizard-step'
        getApi={(api) => {
          formApiRef.current = api;
          dispatch(setCurrentFormApi(api));
        }}
        initialValues={isEmpty(selectedData) ? initialFormData : formData}
        validate={!defaultPolicy && !renewalInProgress ? validate : undefined}
        onSubmit={(_data: FormValues) => {
          /*console.log('QUOTEPOLICYSELECTION: form onSubmit'); */
          /* handleFormSubmission(data); */ // should no longer be necessary, since it would have happened in the onchange
          setBlocked(false);
        }}
        onChange={(formState: $TSFixMe) => {
          handleFormSubmission(formState.values);
        }}
      >
        {({ formApi, formState }) => (
          <>
            {/* <pre> */}
            {/*   {JSON.stringify(optionalPolicyForms, null, 4)} */}
            {/*   {JSON.stringify(requiredPolicyForms, null, 4)} */}
            {/* </pre> */}
            <FormControl
              className={classes.formControl}
              error={!!formApi.getState().error}
              variant='standard'
            >
              <FormLabel htmlFor='policies'>
                <Typography variant='h6' align='center' sx={{ mb: 2 }}>
                  {selectedBusinessName}
                </Typography>
                <Divider />
                {/* <Typography variant='h6' align='center' sx={{ mt: 2 }}> */}
                {/*   What types of coverage do you need? */}
                {/* </Typography> */}
              </FormLabel>

              {personalInsurance && selectedInsurType === 'personal' ? (
                <MultiTileSelector
                  name='policyTypes'
                  inputId='policyTypes'
                  aria-label='policyTypes'
                  field='policyTypes'
                  options={policyOptions}
                  settings={settings}
                  tileClasses={tileClasses}
                />
              ) : (
                <>
                  <FormGroup className={classes.checkboxGroup}>
                    {renewalInProgress ? (
                      availablePolicyForms.map((policy) => {
                        const { displayName, id } = policy;
                        return (
                          <div className={classes.checkbox} key={id}>
                            <FormControlLabel
                              label={displayName}
                              control={
                                <Check
                                  color='primary'
                                  checked={selectedPolicies.some(
                                    (selectedPolicy) =>
                                      selectedPolicy.id === id,
                                  )}
                                  disabled
                                />
                              }
                            />
                          </div>
                        );
                      })
                    ) : (
                      <>
                        {optionalPolicyForms.length > 0 &&
                        requiredPolicyForms.length > 0 ? (
                          <Typography
                            component='h3'
                            variant='h6'
                            sx={{ mb: 2, color: 'text.secondary' }}
                          >
                            Required Coverages
                          </Typography>
                        ) : null}
                        {requiredPolicyForms.length > 0 ? (
                          <List>
                            {requiredPolicyForms.map((policy) => {
                              const { displayName } = policy;
                              return (
                                <ListItem>
                                  {' '}
                                  <ListItemIcon>
                                    <CheckIcon sx={{ color: 'success.main' }} />
                                  </ListItemIcon>
                                  {displayName}
                                  <div
                                    className={classes.checkbox}
                                    style={{
                                      display: 'none',
                                      visibility: 'hidden',
                                    }}
                                  >
                                    <FormControlLabel
                                      label={displayName}
                                      control={
                                        <Check
                                          color='primary'
                                          checked
                                          disabled
                                        />
                                      }
                                    />
                                  </div>
                                </ListItem>
                              );
                            })}
                          </List>
                        ) : null}
                        {optionalPolicyForms.length > 0 &&
                        requiredPolicyForms.length > 0 ? (
                          <Typography
                            component='h3'
                            variant='h6'
                            sx={{ mb: 2, color: 'text.secondary' }}
                          >
                            Optional Coverages
                          </Typography>
                        ) : null}
                        {optionalPolicyForms.map((policy, index) => {
                          const {
                            displayName,
                            id,
                            qualifyingQuestion,
                            availableLanguages,
                          } = policy;
                          if (
                            qualifyingQuestion &&
                            qualifyingQuestion !== null
                          ) {
                            return (
                              <RenderedQuestionInstance
                                questionInstance={
                                  selectedLanguage &&
                                  selectedLanguage.inLanguageList(
                                    availableLanguages,
                                  )
                                    ? qualifyingQuestion.toQuoteWizardQuestionInstance(
                                        selectedLanguage,
                                      )
                                    : null
                                }
                                key={qualifyingQuestion.id + `_${index}`}
                                classes={classes}
                              />
                            );
                          } else {
                            return (
                              <div className={classes.checkbox}>
                                <Checkbox
                                  key={id + `_${index}`}
                                  data-testid={`policy-${displayName}`}
                                  field={displayName}
                                  label={displayName}
                                />
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                    {
                      // availablePolicyForms.map((policy, index) => {
                      // const {
                      //   displayName,
                      //   id,
                      //   qualifyingQuestion,
                      //   required,
                      //   availableLanguages,
                      // } = policy;
                      // return (
                      //   <>
                      //     {renewalInProgress ? (
                      //       <div className={classes.checkbox}>
                      //         <FormControlLabel
                      //           label={displayName}
                      //           control={
                      //             <Check
                      //               color='primary'
                      //               checked={selectedPolicies.some(
                      //                 (selectedPolicy) =>
                      //                   selectedPolicy.id === id,
                      //               )}
                      //               disabled
                      //             />
                      //           }
                      //         />
                      //       </div>
                      //     ) : required ? (
                      //       <>
                      //         <div className={classes.checkbox}>
                      //           <FormControlLabel
                      //             label={displayName}
                      //             control={
                      //               <Check color='primary' checked disabled />
                      //             }
                      //           />
                      //         </div>
                      //       </>
                      //     ) : qualifyingQuestion &&
                      //       qualifyingQuestion !== null ? (
                      //       <RenderedQuestionInstance
                      //         questionInstance={
                      //           selectedLanguage &&
                      //           selectedLanguage.inLanguageList(
                      //             availableLanguages,
                      //           )
                      //             ? qualifyingQuestion.toQuoteWizardQuestionInstance(
                      //                 selectedLanguage,
                      //               )
                      //             : null
                      //         }
                      //         key={qualifyingQuestion.id + `_${index}`}
                      //         classes={classes}
                      //       />
                      //     ) : (
                      //       <div className={classes.checkbox}>
                      //         <Checkbox
                      //           key={id + `_${index}`}
                      //           data-testid={`policy-${displayName}`}
                      //           field={displayName}
                      //           label={displayName}
                      //         />
                      //       </div>
                      //     )}
                      //   </>
                      // );
                      // })
                    }
                  </FormGroup>
                  <FormHelperText>{formApi.getState().error}</FormHelperText>
                </>
              )}
            </FormControl>

            <div className={classes.navButtons}>
              {params?.directTo !== 'personal' ? (
                <BackButton
                  onClick={() => {
                    if (personalInsurance && selectedInsurType === 'personal') {
                      if (selectedPolicies.length > 0) {
                        setWarningInfo({
                          open: true,
                          message:
                            'Returning to insurance type selection will delete any data entered. This action is irreversible.',
                          isNavBack: true,
                        });
                      } else {
                        dispatch(
                          actions.selectInsurType({
                            selectedInsurType: null,
                          }),
                        );
                      }
                    } else {
                      setBlocked(false);
                      handleFormSubmission(formState.values);
                      setPendingNavType('backward');
                    }
                  }}
                  type='button'
                />
              ) : (
                <Box />
              )}

              <SelectQuestionsPerPage />

              <NextButton
                onClick={() => {
                  setPendingNavType('forward');
                }}
                type='submit'
              />
            </div>
            <DataLossWarning
              warningInfo={warningInfo}
              onBack={() => handleClose()}
              onContinue={() => {
                dispatch(
                  actions.selectInsurType({
                    selectedInsurType: null,
                  }),
                );
                handleClose();
              }}
            />
          </>
        )}
      </Form>
    </>
  );
}

/* Styling */
const QuotePolicySelectionDisplayWithStyles = withStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
    margin: 'auto',
    maxWidth: '60rem',
    '& label': {
      alignSelf: 'center',
      marginBottom: theme.spacing(1),
    },
    '& h5': {
      marginBottom: theme.spacing(2),
    },
    // Smaller Screens
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
    },
    [theme.breakpoints.only('md')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(12)}`,
      maxWidth: '50rem',
    },
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    marginBottom: theme.spacing(0.75),
  },
  yesNoToggle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
  },
  helpText: {
    ...theme.typography.caption,
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <QuotePolicySelectionDisplay {...props} />);

/* GraphQL Layer */

interface QuoteFormsQueryLayerProps {
  children: ReactNode;
  selectedBusinessType: ReturnType<
    typeof mapStateToProps
  >['selectedBusinessType'];
  goToStep: $TSFixMe;
  [k: string]: any;
}
export function QuoteFormsQueryLayer({
  children,
  selectedBusinessType,
  goToStep,
  ...mappedState
}: QuoteFormsQueryLayerProps) {
  const selectedBusinessTypeId = selectedBusinessType
    ? 'original' in selectedBusinessType
      ? selectedBusinessType.original.id
      : selectedBusinessType.id
    : null;

  const queryOptions = {
    variables: {
      businessId: selectedBusinessTypeId,
    },
  };

  const [getBusinessForm, { loading, error, data }] = useLazyQuery(
    BusinessSpecificFormQuery,
  );

  useEffect(() => {
    // @ts-expect-error
    getBusinessForm(queryOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessTypeId]);

  if (!selectedBusinessTypeId) {
    goToStep(1);
  }

  if (error) {
    const handleDisconnectionError = (error: HandleableError) => {
      if ('networkError' in error) {
        return (
          <DisconnectionError
            reset={() => {
              // @ts-expect-error
              getBusinessForm(queryOptions);
            }}
          />
        );
      }
    };
    return handleErrors(error, handleDisconnectionError);
  }
  if (loading) {
    // @ts-expect-error
    return children({ loading, error });
  }

  let allBusinessRelatedForms: Array<ProgramBuilderForm> = [];
  if (data?.someFinalForms) {
    allBusinessRelatedForms = data.someFinalForms.map((rawForm: $TSFixMe) =>
      ProgramBuilderForm.generateFromBackendResponse(rawForm),
    );
  }

  const businessForm = allBusinessRelatedForms.find((form) => !form.policy);
  const policyForms = allBusinessRelatedForms.filter((form) => !!form.policy);

  // @ts-expect-error
  const allAvailablePolicyForms: Array<PolicySummary> = policyForms
    .map((form) => {
      // @ts-expect-error
      const displayName = form.policy.displayName;
      // @ts-expect-error
      const internalName = form.policy.internalName;
      // @ts-expect-error
      const id = form.policy.id;
      const qualifyingQuestion = form.qualifyingQuestion;
      const required = form.required;
      return {
        id,
        displayName,
        internalName,
        qualifyingQuestion,
        required,
        availableLanguages: form.getAvailableLanguages(),
      };
    })
    .sort((a: $TSFixMe, b: $TSFixMe) => {
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
      return 0;
    });

  const generalInformationLanguages = businessForm
    ? businessForm.getAvailableLanguages()
    : [];
  const policyLanguages = Language.union(
    ...policyForms.map((policyForm) => policyForm.getAvailableLanguages()),
  );
  const availableLanguages = Language.intersect(
    generalInformationLanguages,
    policyLanguages,
  );

  // @ts-expect-error
  return children({
    businessForm,
    policyForms,
    allAvailablePolicyForms,
    availableLanguages,
    ...mappedState,
  });
}

/* Redux Layer */
function mapStateToProps(state: StoreState) {
  const selectedBusinessType = state.quoteWizard.selectedBusinessType;
  const selectedPolicies = state.quoteWizard.selectedPolicies;
  const selectedLanguage = state.quoteWizard.selectedLanguage;

  return { selectedBusinessType, selectedPolicies, selectedLanguage };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const { setPolicies } = QuoteWizardState.actions;

  function onSubmit({
    selectedPolicies,
    businessForm,
    policyForms,
    options,
  }: {
    selectedPolicies: Array<PolicySummary>;
    businessForm: QuoteWizardForm | {};
    policyForms: Array<QuoteWizardForm>;
    options: { overwrite: boolean };
  }) {
    /*console.log('QUOTEPOLICYSELECTION: in redux onSubmit'); */

    dispatch(
      setPolicies({ selectedPolicies, businessForm, policyForms, options }),
    );
  }

  return {
    onSubmit,
  };
}

export const QuoteFormPolicySelectionConnectLayer = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default QuoteFormPolicySelectionConnectLayer((mappedState) => (
  <QuoteFormsQueryLayer {...mappedState}>
    {(props: $TSFixMe) => {
      const { loading, error, ...passThroughProps } = props;

      if (loading) {
        return (
          <Stack
            direction='column'
            spacing={2}
            width='100%'
            sx={{
              margin: 'auto',
              maxWidth: '60rem',
              padding: '48px 96px',
            }}
          >
            <Stack justifyContent='center' direction='row'>
              <Skeleton width='50%' height='35px' />
            </Stack>
            {new Array(6).fill(0).map(() => (
              <Skeleton height='35px' />
            ))}

            <Stack direction='row' justifyContent='space-between'>
              <Skeleton width='17%' height='50px' />
              <Skeleton width='17%' height='50px' />
            </Stack>
          </Stack>
        );
      }

      if (error) {
        return handleErrors(error);
      }

      return <QuotePolicySelectionDisplayWithStyles {...passThroughProps} />;
    }}
  </QuoteFormsQueryLayer>
));
