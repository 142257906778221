import { useState } from 'react';
// @ts-expect-error
import { ArrayField, useFormApi, Relevant } from 'informed';
import RenderedQuestionInstance from './RenderedQuestionInstance';
import { TextField, Checkbox } from '@calefy-inc/informedMaterial';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MaterialCheckbox from '@mui/material/Checkbox';
import { v4 as createId } from 'uuid';
import { VINDecoder } from './VINDecoder';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddItemButton } from './common';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';
import { formatAsReadableList } from './utility';

// types and classes
import { Component } from './classes';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  QuoteComponentProps,
  ProgramBuilderComponentProps,
  QuoteComponentValidationFunctionReturn,
} from './types';
import { Language, LanguageAwareString } from '../../../Typescript/classes';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../util';

const requiredSubQuestions = [
  new ProgramBuilderQuestionInstance({
    apiName: 'vin',
    clientId: createId(),
    displayNames: [new LanguageAwareString('vin', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('V.I.N.', Language.english)],
    dataType: 'string',
  }),
  new ProgramBuilderQuestionInstance({
    apiName: 'model_year',
    clientId: createId(),
    displayNames: [new LanguageAwareString('model year', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Model Year', Language.english)],
    dataType: 'string',
  }),
  new ProgramBuilderQuestionInstance({
    apiName: 'make',
    clientId: createId(),
    displayNames: [new LanguageAwareString('make', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Make', Language.english)],
    dataType: 'string',
  }),
  new ProgramBuilderQuestionInstance({
    apiName: 'model',
    clientId: createId(),
    displayNames: [new LanguageAwareString('model', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Model', Language.english)],
    dataType: 'string',
  }),
  new ProgramBuilderQuestionInstance({
    apiName: 'body_type',
    clientId: createId(),
    displayNames: [new LanguageAwareString('body type', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Body Type', Language.english)],
    dataType: 'string',
  }),
  new ProgramBuilderQuestionInstance({
    apiName: 'engine_size',
    clientId: createId(),
    displayNames: [new LanguageAwareString('engine size', Language.english)],
    component: 'textfield',
    labels: [new LanguageAwareString('Engine Cylinders', Language.english)],
    dataType: 'string',
  }),
  new ProgramBuilderQuestionInstance({
    apiName: 'gross_vehicle_weight',
    clientId: createId(),
    displayNames: [
      new LanguageAwareString('gross vehicle weight', Language.english),
    ],
    component: 'textfield',
    labels: [new LanguageAwareString('Gross Vehicle Weight', Language.english)],
    dataType: 'string',
  }),
];

const REQUIRED_SUBQUESTION_API_NAMES = requiredSubQuestions.map(
  (q) => q.apiName,
);

const ProgramBuilderComponent = ({
  classes = {},
  questionInstance,
}: ProgramBuilderComponentProps) => {
  const propsBlob =
    questionInstance && questionInstance.propsBlob
      ? questionInstance.propsBlob
      : {};
  /* const { repeatable } = propsBlob; */
  const repeatable = propsBlob.repeatable ? true : false;

  return (
    <>
      <Checkbox
        classes={classes}
        className={classes.checkbox}
        field='props_blob_repeatable'
        label='Repeatable set'
        value={repeatable}
      />
    </>
  );
};

/**
 * Fetches data on the VIN entered and displays some oh that data
 *@param questionInstance - List of question instances for current form
 * @param classes - styling
 * @returns Question instances components
 */
const QuoteComponent = ({
  classes = {},
  blockAddSubquestions,
  questionInstance,
  ...props
}: QuoteComponentProps) => {
  const formApi = useFormApi();
  const [relevant, setRelevant] = useState([false]);

  const handleChange = (index: $TSFixMe) => {
    setRelevant((prev) =>
      prev.map((item, idx) => (idx === index ? !item : item)),
    );
  };

  const handleAdd = () => {
    setRelevant((prev) => [...prev, false]);
  };

  const handleRemove = (index: $TSFixMe) => {
    const tempRel = [...relevant];
    tempRel.splice(index, 1);
    setRelevant(tempRel);
  };

  const { repeatable } = questionInstance.propsBlob;

  let otherSubQuestions =
    questionInstance &&
    questionInstance.subQuestions.filter(
      (ele) =>
        !requiredSubQuestions.map((q) => q.apiName).includes(ele.apiName),
    );

  return (
    <>
      <Typography
        variant='subtitle1'
        color='textSecondary'
        className={classes.center}
      >
        {questionInstance.label}
      </Typography>
      <ArrayField field={questionInstance.generateFieldName()}>
        {(arrayFieldApi) => {
          if (arrayFieldApi.fields.length === 0) {
            arrayFieldApi.add();
          }
          return (
            <>
              {/* @ts-expect-error */}
              <ArrayField.Items>
                {(arrayFieldItemsApi: $TSFixMe) => {
                  // first set the field prefix for all subQuestions
                  questionInstance.subQuestions.forEach(
                    (subq) => (subq.fieldPrefix = arrayFieldItemsApi.field),
                  );

                  let vin = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'vin',
                  );
                  if (!vin) {
                    throw new Error(
                      `Unable to find required subquestion "vin" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  let modelYear = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'model_year',
                  );
                  if (!modelYear) {
                    throw new Error(
                      `Unable to find required subquestion "model_year" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  let make = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'make',
                  );
                  if (!make) {
                    throw new Error(
                      `Unable to find required subquestion "make" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  let model = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'model',
                  );
                  if (!model) {
                    throw new Error(
                      `Unable to find required subquestion "model" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  let bodyType = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'body_type',
                  );
                  if (!bodyType) {
                    throw new Error(
                      `Unable to find required subquestion "body_type" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  let engineSize = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'engine_size',
                  );
                  if (!engineSize) {
                    throw new Error(
                      `Unable to find required subquestion "engine_size" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  let grossVehicleWeight = questionInstance.subQuestions.find(
                    (ele) => ele.apiName === 'gross_vehicle_weight',
                  );
                  if (!grossVehicleWeight) {
                    throw new Error(
                      `Unable to find required subquestion "gross_vehicle_weight" in ${JSON.stringify(
                        questionInstance,
                        null,
                        4,
                      )}`,
                    );
                  }
                  return (
                    <>
                      {arrayFieldApi.fields.length > 1 &&
                      arrayFieldItemsApi.index !== 0 ? (
                        <Divider className={classes.divider} />
                      ) : null}
                      <div className={classes.addressContainer}>
                        <div className={classes.addressHeader}>
                          {arrayFieldApi.fields.length > 1 ? (
                            <Typography variant='subtitle1' color='primary'>
                              Vehicle {arrayFieldItemsApi.index + 1}
                            </Typography>
                          ) : null}
                          <div className={classes.removeAddress}>
                            {arrayFieldApi.fields.length > 1 ? (
                              <IconButton
                                color='default'
                                size='small'
                                onClick={() => {
                                  handleRemove(arrayFieldItemsApi.index);
                                  arrayFieldItemsApi.remove();
                                }}
                                type='button'
                                aria-label='remove vehicle'
                              >
                                <Tooltip title={'Remove Vehicle'}>
                                  <CloseIcon />
                                </Tooltip>
                              </IconButton>
                            ) : undefined}
                          </div>
                        </div>
                        {/* Fills other fields appropriately*/}
                        <VINDecoder
                          field={vin.generateFieldName()}
                          formApi={formApi}
                          vin={vin}
                          classes={classes}
                          fillFields={{
                            vin: vin.generateFieldName(),
                            modelYear: modelYear.generateFieldName(),
                            make: make.generateFieldName(),
                            model: model.generateFieldName(),
                            bodyType: bodyType.generateFieldName(),
                            engineSize: engineSize.generateFieldName(),
                            grossVehicleWeight:
                              grossVehicleWeight.generateFieldName(),
                          }}
                        />
                        <Relevant
                          when={() => {
                            let subquestionsPresent = false; // are there answered required subquestions?
                            try {
                              const initialState =
                                /* prettier-ignore */
                                // @ts-expect-error
                                arrayFieldApi.fields[arrayFieldItemsApi.index].arrayFieldItemState;
                              if (
                                initialState.initialValue &&
                                typeof initialState.initialValue === 'object'
                              ) {
                                //console.log('testing');
                                subquestionsPresent =
                                  REQUIRED_SUBQUESTION_API_NAMES.some(
                                    (apiName) =>
                                      Object.keys(
                                        initialState.initialValue,
                                      ).includes(apiName),
                                  );
                              }
                            } catch (e) {
                              console.error(e);
                              Bugsnag.notify(errorify(e));
                            }
                            //console.log(formApi.getState().values);
                            //console.log({ subquestionsPresent });

                            const instanceValue = formApi.getValue(
                              questionInstance.generateFieldName(),
                            );

                            const actualValue =
                              Array.isArray(instanceValue) &&
                              arrayFieldItemsApi.index in instanceValue
                                ? instanceValue[arrayFieldItemsApi.index]
                                : undefined;
                            let isRelevant = false;

                            if (relevant[arrayFieldItemsApi.index]) {
                              isRelevant = true;
                            } else if (subquestionsPresent) {
                              isRelevant = true;
                            } else if (!actualValue) {
                              isRelevant = false;
                            } else if (actualValue?.vin?.length === 17) {
                              isRelevant = true;
                            }

                            return isRelevant;
                          }}
                        >
                          <Grid container justifyContent='space-between'>
                            <Grid item xs={12} sm={5} md={2}>
                              <TextField
                                label={modelYear.label}
                                field={modelYear.generateFieldName()}
                                id={modelYear.apiName}
                                helperText={modelYear.helpText}
                                required={
                                  modelYear.required
                                    ? modelYear.required
                                    : questionInstance.required
                                }
                                className={classes.textfield}
                                classes={classes}
                                {...props}
                                variant='standard'
                              />
                            </Grid>

                            <Grid item xs={12} sm={5} md={3}>
                              <TextField
                                label={make.label}
                                field={make.generateFieldName()}
                                id={make.apiName}
                                helperText={make.helpText}
                                required={
                                  make.required
                                    ? make.required
                                    : questionInstance.required
                                }
                                className={classes.textfield}
                                classes={classes}
                                {...props}
                                variant='standard'
                              />
                            </Grid>

                            <Grid item xs={12} sm={5} md={3}>
                              <TextField
                                label={model.label}
                                field={model.generateFieldName()}
                                id={model.apiName}
                                helperText={model.helpText}
                                required={
                                  model.required
                                    ? model.required
                                    : questionInstance.required
                                }
                                className={classes.textfield}
                                classes={classes}
                                {...props}
                                variant='standard'
                              />
                            </Grid>

                            <Grid item xs={12} sm={5} md={3}>
                              <TextField
                                label={engineSize.label}
                                field={engineSize.generateFieldName()}
                                id={engineSize.apiName}
                                helperText={engineSize.helpText}
                                required={
                                  engineSize.required
                                    ? engineSize.required
                                    : questionInstance.required
                                }
                                className={classes.textfield}
                                classes={classes}
                                {...props}
                                variant='standard'
                              />
                            </Grid>
                          </Grid>

                          <TextField
                            label={bodyType.label}
                            field={bodyType.generateFieldName()}
                            id={bodyType.apiName}
                            helperText={bodyType.helpText}
                            required={
                              bodyType.required
                                ? bodyType.required
                                : questionInstance.required
                            }
                            className={classes.textfield}
                            classes={classes}
                            {...props}
                            variant='standard'
                          />

                          <TextField
                            label={grossVehicleWeight.label}
                            field={grossVehicleWeight.generateFieldName()}
                            id={grossVehicleWeight.apiName}
                            helperText={grossVehicleWeight.helpText}
                            required={
                              grossVehicleWeight.required
                                ? grossVehicleWeight.required
                                : questionInstance.required
                            }
                            className={classes.textfield}
                            classes={classes}
                            {...props}
                            variant='standard'
                          />
                        </Relevant>
                        <FormControlLabel
                          control={
                            <MaterialCheckbox
                              color='primary'
                              checked={relevant[arrayFieldItemsApi.index]}
                              onChange={() =>
                                handleChange(arrayFieldItemsApi.index)
                              }
                            />
                          }
                          label='No V.I.N.'
                        />
                        {otherSubQuestions &&
                          otherSubQuestions.map((sub_q) => {
                            /* let new_sub_q = Object.assign({}, sub_q); */
                            /* new_sub_q.name = `${arrayFieldItemsApi.field}.${sub_q.name}`; */
                            return (
                              <RenderedQuestionInstance
                                questionInstance={sub_q}
                                key={sub_q.id}
                                classes={classes}
                              />
                            );
                          })}
                      </div>
                    </>
                  );
                }}
                {/* @ts-expect-error */}
              </ArrayField.Items>
              {repeatable && !blockAddSubquestions ? (
                <AddItemButton
                  text='Add Vehicle'
                  className={classes.button}
                  type='button'
                  color='primary'
                  onClick={() => {
                    handleAdd();
                    arrayFieldApi.add();
                  }}
                  size='large'
                  aria-label='add vehicle'
                />
              ) : null}
            </>
          );
        }}
      </ArrayField>
    </>
  );
};

function validateVin(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const errorMessages: Array<string> = [];

  const missingComponentApiNames = requiredSubQuestions
    .map((q) => q.apiName)
    .filter(
      (apiName) =>
        !questionInstance.subQuestions.find((subq) => subq.apiName === apiName),
    );

  if (missingComponentApiNames.length > 0) {
    const plural = missingComponentApiNames.length > 1;
    errorMessages.push(
      `VIN component with name "${questionInstance.generateDisplayName()}" is missing required subquestion${
        plural ? 's' : ''
      } with API name${plural ? 's' : ''} ${formatAsReadableList(
        missingComponentApiNames.map((s) => `"${s}"`),
      )}. The safest thing to do is to delete and re-create it to avoid problems later.`,
    );
  }

  return errorMessages;
}

const gatherSubQuestions = (
  _formState: $TSFixMe,
  questionInstance: ProgramBuilderQuestionInstance,
) => {
  questionInstance = initialSubQuestions(questionInstance);
  return questionInstance;
};

const initialSubQuestions = (
  questionInstance: ProgramBuilderQuestionInstance,
) => {
  // debugger;
  questionInstance.subQuestions = [
    ...requiredSubQuestions,
    ...questionInstance.subQuestions.filter(
      (subq) =>
        !requiredSubQuestions.map((q) => q.apiName).includes(subq.apiName),
    ),
  ];
  questionInstance.children = questionInstance.subQuestions.map((subq) =>
    subq.copy(),
  );
  return questionInstance;
};

const IconComponent = () => {
  return <DirectionsCarFilledRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'vin',
    typeLabel: 'VIN',
    dataType: 'nested',
    quoteComponent: QuoteComponent,
    gatherSubQuestions: gatherSubQuestions,
    initialSubQuestions: initialSubQuestions,
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateVin,
    icon: IconComponent,
  });
}
