import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export default function DragIcon() {
  return (
    // <svg className={classes.root} viewBox='0 0 24 24'>
    //   <path d='M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
    // </svg>
    <DragIndicatorIcon
      color='disabled'
      fontSize='small'
      sx={{
        '&:hover': {
          cursor: 'grab',
        },
      }}
    />
  );
}
