// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  QuoteWizardAnswerInstance,
  QuoteWizardQuestionInstance,
} from '../QuoteWizard/classes';
import { AnswerInstanceDetails } from '../../Typescript/backend/classes';

/**
 * Given an answer instance and a question, see if they match
 * @param {{questionInstance: QuestionInstance, value: any, subAnswers: AnswerInstance[]}} answer - AnswerInstance
 * @param {QuestionInstance} question - The question
 */
const doesAnswerMatchQuestion = (
  answer: QuoteWizardAnswerInstance,
  question: $TSFixMe,
) => {
  if (!answer || !question) {
    return false;
  }
  const answerQuestion = answer.questionInstance;
  if (!answerQuestion) {
    return false;
  }
  const attributesToCheck: Array<
    keyof QuoteWizardAnswerInstance['questionInstance']
  > = ['apiName', 'displayName', 'label', 'component'];
  return attributesToCheck.every(
    (attribute) => answerQuestion[attribute] === question[attribute],
  );
};
/**
 * Given a canned answers and a list of questions, find the question that matches the answer and return it
 * @param {AnswerInstance} cannedAnswer - The canned answer
 * @param {QuestionInstance[]} questionsList - list of questions
 */
const findMatchingQuestionInstanceToAnswer = (
  answer: $TSFixMe,
  questionsList: Array<QuoteWizardQuestionInstance>,
) => {
  if (!answer || !questionsList || questionsList.length === 0) {
    return null;
  }
  const matchingQuestion = questionsList.find((question) =>
    doesAnswerMatchQuestion(answer, question),
  );
  if (!matchingQuestion) {
    //console.log(
    //   'FINDMATCHINGQUESTIONINSTANCETOANSWER: unable to find matching question for',
    //   answer,
    //   'in',
    //   questionsList,
    // );
  }
  return matchingQuestion ? matchingQuestion : null;
};

/**
 * Given the answer and a list of possible matching questions, cobble together the answer in state
 * @param {AnswerInstance} cannedAnswer - the answer
 * @param {QuestionInstance[]} questionsList - A list of possible matching question instances
 */
const cobbleTogetherAnswerUsingQuestionsList = (
  cannedAnswer: $TSFixMe,
  questionsList: Array<QuoteWizardQuestionInstance>,
) => {
  const matchingQuestion = findMatchingQuestionInstanceToAnswer(
    cannedAnswer,
    questionsList,
  );
  return matchingQuestion
    ? cobbleTogetherAnswerForState(cannedAnswer, matchingQuestion)
    : null;
};

/**
 * Given the answer and the matching question, cobble together what it is supposed to look like in the Redux store
 */
const cobbleTogetherAnswerForState = (
  cannedAnswer: $TSFixMe,
  matchingQuestion: QuoteWizardQuestionInstance,
) => {
  return new QuoteWizardAnswerInstance({
    ...cannedAnswer,
    questionInstance: matchingQuestion,
    // ? matchingQuestion
    // : QuoteWizardQuestionInstance.generateFromBackendResponse(
    //     cannedAnswer.questionInstance,
    //   ),
    subAnswers: generateAnswersFromQuestionsList(
      cannedAnswer.subAnswers,
      matchingQuestion ? matchingQuestion.subQuestions : [],
    ),
    details: new AnswerInstanceDetails(cannedAnswer.details),
  });
};

/**
 * Given the answers template and the list of questions from the corresponding form, match the questions and move the id from the form into the answers
 * @param {object[]} cannedAnswers - list of the canned answer, in the correct format for the redux store (except that the questionInstance field is missing the id)
 * @param {object[]} questionsList - list of the question instances
 */
export const generateAnswersFromQuestionsList = (
  cannedAnswers: Array<$TSFixMe>,
  questionsList: Array<QuoteWizardQuestionInstance>,
): Array<QuoteWizardAnswerInstance> => {
  // @ts-expect-error
  return cannedAnswers
    .map((answer: $TSFixMe) =>
      cobbleTogetherAnswerUsingQuestionsList(answer, questionsList),
    )
    .filter((answer) => answer !== null);
};

/**
 * Is this one of the locations which are allowed to access the demo component?
 */
export const isAllowedDemoLocation = () => {
  return !!(
    window.location.hostname.match(
      /(localhost|calefy.ca|lastnameinsurance.ca)/i,
    ) || window.location.origin.match(/staging-calefy\.fuseinsurance.ca/i)
  );
};
