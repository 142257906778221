const DisplayResults = ({ results }) => {
  if (!results) {
    return null;
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Address</th>
          <th>Contacts</th>
        </tr>
      </thead>
      <tbody>
        {results.map((result) => (
          <tr>
            <td>{result.name}</td>
            <td>{result.phoneNumber}</td>
            <td>{result.address}</td>
            <td>{result.contacts}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DisplayResults;
