// A page for the user to log in
// They should really only find themselves here if they try to access a page / resource that they shouldn't have access to

import { useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from '@reach/router';

import {
  UnauthorizedLoginWithThemes as UnauthorizedLogin,
  GenericLoginErrorWithThemes as GenericLoginError,
  DifferentDomainsError,
  UnverifiedEmailErrorLogin,
} from './LoginErrors';
import { determineBaseURL } from '@calefy-inc/authentication';
import { useAuth } from '@calefy-inc/authentication';

// classes and types
import { RouteComponentProps } from '@reach/router';

interface LoginProps extends RouteComponentProps {
  exact?: boolean;
}
/**
 * Page for the user to log in
 *
 * They should really only find themselves here if they try to access a page / resource that they shouldn't have access to
 *
 * Also supports redirect. Flow:
 * 	- user visits a site the don't have permission to access (/insurtech/denied)
 * 	- Authentication bumps them to /insurtech/login/denied
 * 	- Login component stores the 'denied' part in localStorage
 * 	- Once the user is done logging in, they are redirected to the Login page
 * 	- Login page checks localStorage: if there is a desiredPath there, it bumps them there and removes the desiredPath from localStorage. Otherwise, it continues as normal
 */
const Login = (_props: LoginProps) => {
  const { token, loginWithRedirect, isAuthenticated } = useAuth({
    onTokenError: (error, reallySetToken, logout) => {
      console.error(`Error validating token:`, error);
      let searchString = `error=login_error&error_description=${encodeURIComponent(
        error.message,
      )}`;
      if (
        error.message.match(
          /organization associated with the email in the token .+ does not match the organization associated with the url from which the token was received.+/i,
        )
      ) {
        searchString = `error=different_domains&error_description=${encodeURIComponent(
          error.message,
        )}`;
      }
      reallySetToken(null);
      logout({
        returnTo: determineBaseURL() + `/insurtech/login?${searchString}`,
      });
    },
  });
  // const { log } = console;
  // log('In Login');
  const navigate = useNavigate();

  // to check if there was an error
  const unverifiedEmailErrorRegex =
    /error=access_denied.+error_description=access.+denied.+this.+email.+has.+not.+yet.+been.+verified/i;
  const accessDeniedErrorRegex =
    /error=access_denied.+error_description=access.+denied/i;
  const genericErrorRegex = /error=.+error_description=.+/;
  const differentDomainsRegex = /error=different_domains/i;

  const location = useLocation();
  //console.log('location:', JSON.stringify(location, null, 4));
  const base = location.pathname.includes('whitelabel')
    ? `whitelabel`
    : `insurtech`;
  const params = new URLSearchParams(window.location.search);
  const desireFragment = params.get('to');
  // const initial = `/${base}/login/`;
  // const desireFragment = location.pathname.slice(
  //   location.pathname.indexOf(initial) + initial.length,
  // );
  // const basePath = location.href.slice(
  //   0,
  //   location.href.indexOf(initial) + initial.length,
  // );
  const localStoragePath = window.localStorage.getItem('desiredPath');
  //console.log({ desireFragment });

  useEffect(() => {
    (async () => {
      // const desireFragment = location.pathname.slice(
      //   location.pathname.indexOf(initial) + initial.length,
      // );
      //console.log({ desireFragment, token, isAuthenticated });
      if (desireFragment && !(token || isAuthenticated)) {
        //console.log('LOGIN: 1 (not authenticated)', desireFragment);
        window.localStorage.setItem('desiredPath', desireFragment);
        try {
          window.history.pushState(
            undefined,
            // @ts-expect-error
            window.title,
            `${determineBaseURL()}/insurtech/login`,
          );
        } catch (e) {
          console.error('error when pushing history: ', e);
        }
        loginWithRedirect();
      } else if (localStoragePath && (token || isAuthenticated)) {
        //console.log('LOGIN: 2 (desired path in local storage, authenticated)');
        // let newLocation = `/${base}/` + localStoragePath;
        let newLocation = localStoragePath;
        window.localStorage.removeItem('desiredPath');
        //console.log({ newLocation });
        await navigate(newLocation);
      } else if (!desireFragment && (token || isAuthenticated)) {
        //console.log('LOGIN: 3 (no desire path, authenticated)');
        const newLocation = `/${base}/manager/dashboard`;
        //console.log({ newLocation });
        await navigate(newLocation);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isAuthenticated, desireFragment, localStoragePath]);

  // first check if we are here because of an unauthorized login attempt and return the appropriate error if so:
  if (location.search.match(unverifiedEmailErrorRegex)) {
    return <UnverifiedEmailErrorLogin />;
  }
  if (location.search.match(accessDeniedErrorRegex)) {
    return <UnauthorizedLogin />;
  } else if (location.search.match(differentDomainsRegex)) {
    return <DifferentDomainsError />;
  } else if (location.search.match(genericErrorRegex)) {
    return <GenericLoginError />;
  }

  return (
    <div
      style={{
        margin: '1% 10% 5% 10%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: `70vh`,
      }}
    >
      <Backdrop style={{ zIndex: 1 }} open={true}>
        <CircularProgress color='secondary' size='1.5rem' />
      </Backdrop>
    </div>
  );
};

export default Login;
