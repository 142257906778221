import theme from './KrywoltTheme';
import logo from './assets/MainLogo.png';
import squareLogo from './assets/KrywoltSquareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';

// Calefy Config
const settings = new WebsiteSettings({
  slug: 'krywolt',
  // @ts-expect-error
  host: null,
  companyName: 'Krywolt',
  hours: '8:30am - 5:30pm',
  // @ts-expect-error
  phone: null,
  // @ts-expect-error
  email: null,
  // @ts-expect-error
  chatLink: null,
  companyTheme: theme,
  logo: logo,
  title: 'Krywolt Insurance',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: true,
  squareLogo,
  contactInformationFirst: true,
});

export default settings;
