import { InfoChunk, ProportionColourCircle } from '../commonComponents';

import { OptaAddress } from './OptaCommonClasses';
import { NO_INFO_STRING } from '../constants';
import {
  generateColourStringInRedGreenFromProportionWithAlpha,
  lossCostScoreProportion,
} from '../utility';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../../../util';

export class PerilDetails {
  // @ts-expect-error
  constructor(PerilTypeInput, LossCostScoreInput, PredictedLossCostInput) {
    // @ts-expect-error
    this.PerilType = PerilTypeInput;
    // @ts-expect-error
    this.LossCostScore = LossCostScoreInput;
    // @ts-expect-error
    this.PredictedLossCost = PredictedLossCostInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new PerilDetails(
        response.PerilType,
        response.LossCostScore,
        response.PredictedLossCost,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        `Error creating new PerilDetails with respnse`,
        response,
        e,
      );
      return null;
    }
  }
  static perilTypeToHumanReadable = new Map([
    ['AllProperty', 'All Property'],
    ['Water', 'Water'],
    ['Fire', 'Fire'],
    ['WindHail', 'Wind and Hail'],
    ['Crime', 'Crime'],
    ['OtherBusinessInterruption', 'Other Business Interruption'],
    ['VehicleImpact', 'Vehicle Impact'],
    ['OtherProperty', 'Other Property'],
    ['AllCasualty', 'All Casualty'],
    ['ProductsCompletedOperations', 'Products Completed Operations'],
    ['PremisesOperations', 'Premises Operations'],
    ['Automobile', 'Automobile'],
    ['Employers', 'Employers'],
    ['Professional', 'Professional'],
    ['Pollution', 'Pollution'],
  ]);
  convertPerilTypeToHumanReadable() {
    // @ts-expect-error
    if (PerilDetails.perilTypeToHumanReadable.has(this.PerilType)) {
      // @ts-expect-error
      return PerilDetails.perilTypeToHumanReadable.get(this.PerilType);
    } else {
      // @ts-expect-error
      return this.PerilType;
    }
  }
  displayAsJsx() {
    try {
      return (
        <li
          key={
            // @ts-expect-error
            this.PerilType
          }
        >
          {this.convertPerilTypeToHumanReadable()}:{' '}
          {
            // @ts-expect-error
            this.LossCostScore || this.PredictedLossCost ? (
              <ul>
                {
                  // @ts-expect-error
                  this.LossCostScore ? (
                    <li key='LossCostScore' style={{}}>
                      Loss Cost Score:{' '}
                      {
                        // @ts-expect-error
                        this.LossCostScore
                      }{' '}
                      <ProportionColourCircle
                        proportion={
                          // @ts-expect-error
                          1 - lossCostScoreProportion(this.LossCostScore)
                        }
                      />
                    </li>
                  ) : null
                }
                {
                  // @ts-expect-error
                  this.PredictedLossCost ? (
                    <li key='PredictedLossCost'>
                      Predicted Loss Score:{' '}
                      {
                        // @ts-expect-error
                        this.PredictedLossCost
                      }
                    </li>
                  ) : null
                }
              </ul>
            ) : (
              NO_INFO_STRING
            )
          }
        </li>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying PerilDetails`, e);
      return null;
    }
  }
}
export class CasualtyCommercialPerils {
  // @ts-expect-error
  constructor(allPerilInput) {
    if (!allPerilInput || allPerilInput.length === 0) {
      // @ts-expect-error
      return null;
    }
    // @ts-expect-error
    this.Peril = allPerilInput.map((peril) =>
      PerilDetails.createFromJson(peril),
    );
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CasualtyCommercialPerils(response.Peril);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        `Error creating new CasualtyCommercialPerils with respnse`,
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      if (this.Peril && this.Peril.length > 0) {
        // @ts-expect-error
        return <ul>{this.Peril.map((peril) => peril.displayAsJsx())}</ul>;
      } else {
        return NO_INFO_STRING;
      }
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CasualtyCommercialPerils`, e);
      return NO_INFO_STRING;
    }
  }
}
export class PropertyCommercialPerils {
  // @ts-expect-error
  constructor(allPerilInput) {
    if (!allPerilInput || allPerilInput.length === 0) {
      // @ts-expect-error
      return null;
    }
    // @ts-expect-error
    this.Peril = allPerilInput.map((peril) =>
      PerilDetails.createFromJson(peril),
    );
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new PropertyCommercialPerils(response.Peril);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        `Error creating new PropertyCommercialPerils with respnse`,
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      if (this.Peril && this.Peril.length > 0) {
        // @ts-expect-error
        return <ul>{this.Peril.map((peril) => peril.displayAsJsx())}</ul>;
      } else {
        return NO_INFO_STRING;
      }
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CasualtyCommercialPerils`, e);
      return NO_INFO_STRING;
    }
  }
}
export class CommercialPerilScore {
  // @ts-expect-error
  constructor(LossCostScoreInput, PredictedLossCostInput) {
    // @ts-expect-error
    this.LossCostScore = LossCostScoreInput;
    // @ts-expect-error
    this.PredictedLossCost = PredictedLossCostInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response) {
        return null;
      }
      return new CommercialPerilScore(
        response.LossCostScore,
        response.PredictedLossCost,
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        `Error creating new CommercialPerilScore with response`,
        response,
        e,
      );
      return null;
    }
  }
  displayAsJsx() {
    try {
      // @ts-expect-error
      return this.LossCostScore || this.PredictedLossCost ? (
        <ul>
          <li key='LossCostScore'>
            Loss Cost Score:{' '}
            {
              // @ts-expect-error
              this.LossCostScore
            }{' '}
            <ProportionColourCircle
              proportion={
                // @ts-expect-error
                1 - lossCostScoreProportion(this.LossCostScore)
              }
            />
          </li>
          <li key='PredictedLossCost'>
            Predicted Loss Cost:{' '}
            {
              // @ts-expect-error
              this.PredictedLossCost
            }
          </li>
        </ul>
      ) : (
        NO_INFO_STRING
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error displaying CommercialPerilScore`, e);
      return NO_INFO_STRING;
    }
  }
}

export class CommercialPerilScoreProduct {
  Address: $TSFixMe;
  AllPerilsScore: $TSFixMe;
  PropertyPerilScores: $TSFixMe;
  CasualtyPerilScores: $TSFixMe;
  constructor(
    Address: $TSFixMe,
    AllPerilsScoreInput: $TSFixMe,
    PropertyPerilScoresInput: $TSFixMe,
    CasualtyPerilScoresInput: $TSFixMe,
  ) {
    this.Address = OptaAddress.createFromJson(Address);
    this.AllPerilsScore =
      CommercialPerilScore.createFromJson(AllPerilsScoreInput);
    this.PropertyPerilScores = PropertyCommercialPerils.createFromJson(
      PropertyPerilScoresInput,
    );
    this.CasualtyPerilScores = CasualtyCommercialPerils.createFromJson(
      CasualtyPerilScoresInput,
    );
  }
  static createFromJson(response: $TSFixMe) {
    if (!response) {
      return null;
    }

    return new CommercialPerilScoreProduct(
      response.Address,
      response.AllPerilsScore,
      response.PropertyPerilScores,
      response.CasualtyPerilScores,
    );
  }
}

export class CommercialPerilScoreHighlights {
  // @ts-expect-error
  constructor(commercialPerilScoreProductInput) {
    // @ts-expect-error
    this.AllPerilsLossCostScore = null;
    // @ts-expect-error
    this.AllPropertyLossCostScore = null;
    // @ts-expect-error
    this.AllCasualtyLossCostScore = null;

    const product = commercialPerilScoreProductInput;
    if (!product) {
      return;
    }

    if (product.AllPerilsScore && product.AllPerilsScore.LossCostScore) {
      // @ts-expect-error
      this.AllPerilsLossCostScore = product.AllPerilsScore.LossCostScore;
    }
    if (
      product.PropertyPerilScores &&
      product.PropertyPerilScores.Peril &&
      product.PropertyPerilScores.Peril.length > 0
    ) {
      const foundAllProperty = product.PropertyPerilScores.Peril.find(
        // @ts-expect-error
        (perilDetails) =>
          perilDetails &&
          perilDetails.PerilType &&
          perilDetails.PerilType === 'AllProperty',
      );
      if (foundAllProperty) {
        // @ts-expect-error
        this.AllPropertyLossCostScore = foundAllProperty.LossCostScore;
      }
    }
    if (
      product.CasualtyPerilScores &&
      product.CasualtyPerilScores.Peril &&
      product.CasualtyPerilScores.Peril.length > 0
    ) {
      const foundAllCasualty = product.CasualtyPerilScores.Peril.find(
        // @ts-expect-error
        (perilDetails) =>
          perilDetails &&
          perilDetails.PerilType &&
          perilDetails.PerilType === 'AllCasualty',
      );
      if (foundAllCasualty) {
        // @ts-expect-error
        this.AllCasualtyLossCostScore = foundAllCasualty.LossCostScore;
      }
    }
  }
  generateHighlightsToDisplay() {
    const backgroundAlpha = 0.5;
    const toDisplay = [];
    // @ts-expect-error
    if (this.AllPerilsLossCostScore) {
      toDisplay.push(
        <InfoChunk
          key='AllPerilsLossCostScore'
          heading='All Perils Loss Cost Score'
          information={
            // @ts-expect-error
            this.AllPerilsLossCostScore
          }
          backgroundColor={generateColourStringInRedGreenFromProportionWithAlpha(
            // @ts-expect-error
            1 - lossCostScoreProportion(this.AllPerilsLossCostScore),
            backgroundAlpha,
          )}
        />,
      );
    }
    // @ts-expect-error
    if (this.AllPropertyLossCostScore) {
      toDisplay.push(
        <InfoChunk
          key='AllPropertyLossCostScore'
          heading='All Property Loss Cost Score'
          information={
            // @ts-expect-error
            this.AllPropertyLossCostScore
          }
          backgroundColor={generateColourStringInRedGreenFromProportionWithAlpha(
            // @ts-expect-error
            1 - lossCostScoreProportion(this.AllPropertyLossCostScore),
            backgroundAlpha,
          )}
        />,
      );
    }
    // @ts-expect-error
    if (this.AllCasualtyLossCostScore) {
      toDisplay.push(
        <InfoChunk
          key='AllCasualtyLossCostScore'
          heading='All Casualty Loss Cost Score'
          information={
            // @ts-expect-error
            this.AllCasualtyLossCostScore
          }
          backgroundColor={generateColourStringInRedGreenFromProportionWithAlpha(
            // @ts-expect-error
            1 - lossCostScoreProportion(this.AllCasualtyLossCostScore),
            backgroundAlpha,
          )}
        />,
      );
    }
    return toDisplay;
  }
}
