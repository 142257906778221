import { graphql } from '../gql';

export const GET_OPTA_FLOODSCORE_DATA = graphql(`
  mutation getOptaFloodScoreData($token: String!, $answer: ID!) {
    getOptaFloodscoreData(token: $token, answer: $answer) {
      ok
      optaFloodscoreData {
        ...OptaFloodScoreDataFields
      }
    }
  }
`);
