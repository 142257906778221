import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from '@reach/router';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Tooltip } from '@mui/material';
import {
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import { DialogAnimate } from '../../../animate';
// auth
import { useAllowByPermission } from '../../../../../../hooks';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

PolicyMoreMenu.propTypes = {
  displayName: PropTypes.string,
  onDelete: PropTypes.func,
  isDeletable: PropTypes.bool,
};

interface PolicyMoreMenuProps {
  policyId: string;
  handleDelete: $TSFixMe; // function
  isDeletable: boolean;
}

export default function PolicyMoreMenu({
  policyId,
  handleDelete,
  isDeletable,
}: PolicyMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const hasPolicyEditPermissions = useAllowByPermission('edit:policy');

  // handlers
  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleClickDelete = () => {
    setIsOpen(false);
    setDeleteOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteOpen(false);
  };

  const handleDeletePolicy = () => {
    setDeleteOpen(false);
    handleDelete();
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleMenuOpen} size='large'>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Tooltip
          title={
            hasPolicyEditPermissions
              ? 'Edit Policy'
              : "You don't have permission to edit policies"
          }
        >
          <span>
            <MenuItem
              component={RouterLink}
              to={`${PATH_DASHBOARD.database.policy}/${policyId}/edit`}
              sx={{ color: 'text.secondary' }}
              disabled={!hasPolicyEditPermissions}
            >
              <Icon icon={editFill} width={20} height={20} />
              <Typography variant='body2' sx={{ ml: 2 }}>
                Edit
              </Typography>
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        <Tooltip
          title={
            hasPolicyEditPermissions
              ? 'Delete Policy'
              : "You don't have permission to delete policies"
          }
        >
          <span>
            <MenuItem
              onClick={handleClickDelete}
              sx={{ color: 'error.main' }}
              disabled={!isDeletable || !hasPolicyEditPermissions}
            >
              <Icon icon={trash2Outline} width={20} height={20} />
              <Typography variant='body2' sx={{ ml: 2 }}>
                Delete
              </Typography>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
      <DialogAnimate open={deleteOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Delete Policy</DialogTitle>
        <DialogContent>
          <Typography variant='body2' color='textSecondary'>
            This action is irreversible. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleDeleteDialogClose}>
            Close
          </Button>
          <Button variant='contained' onClick={handleDeletePolicy}>
            Delete
          </Button>
        </DialogActions>
      </DialogAnimate>
    </>
  );
}
