// @ts-nocheck
import { useEffect, useState, ReactNode, PropsWithChildren } from 'react';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { useLazyQuery } from '@apollo/client';
import {
  GENERIC_BUSINESS_WORDCLOUD,
  GENERIC_BUSINESS_WORDCLOUD_INDIVIDUAL_QUOTES,
} from '../../../../../../queries';
import Bugsnag from '@bugsnag/browser';
import Card from '@mui/material/Card';
import HelpIcon from '@mui/icons-material/Help';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useAuth } from '@calefy-inc/authentication';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import { GenericBusinessWordcloudIndividualQuotesQuery } from '../../../../../../gql/graphql';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import { Link } from '@reach/router';

import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import { Text } from '@visx/text';
import {
  WordData,
  useWordcloudOptions,
  useGetWordcloudTextOptions,
  convertToWordData,
} from './utility';
import { correctMod } from '../../../../../../util/correctMod';

type IndividualQuoteWordcloudData = ArrayElement<
  NonNullable<GenericBusinessWordcloudIndividualQuotesQuery>
>;
interface GenericBusinessWordcloudProps {
  Container: $TSFixMe;
}
export const GenericBusinessWordcloud = ({
  Container,
}: GenericBusinessWordcloudProps) => {
  const { token } = useAuth();
  const [
    getIndividualWordcloudInformation,
    { error: individualError, data: individualData },
  ] = useLazyQuery(GENERIC_BUSINESS_WORDCLOUD_INDIVIDUAL_QUOTES);
  const [
    getGenericWordFrequencies,
    { error: allTimeError, data: allTimeData },
  ] = useLazyQuery(GENERIC_BUSINESS_WORDCLOUD);
  // @ts-expect-error
  const [genericWordcloudLoaded, setGenericWordcloudLoaded] =
    useState<boolean>(false);
  const [individualWordclouds, setIndividualWordclouds] = useState<
    Array<ReactNode>
  >([]);
  const [allTimeWords, setAllTimeWords] = useState<Array<WordData>>([]);
  const [individualWords, setIndivividualWords] = useState<
    Array<Array<WordData>>
  >([]);

  // get the data
  useEffect(() => {
    if (token) {
      getIndividualWordcloudInformation({ variables: { token } });
      getGenericWordFrequencies({ variables: { token } });
    }
  }, [token]);

  // spit the error out
  useEffect(() => {
    if (individualError) {
      Bugsnag.notify(individualError);
    }
  }, [individualError]);
  useEffect(() => {
    if (allTimeError) {
      Bugsnag.notify(allTimeError);
    }
  }, [allTimeError]);

  // once the data's here, set the values
  // useEffect(() => {
  //   if (data?.genericBusinessWordcloudIndividualQuotes) {
  //     setIndividualWordclouds(
  //       data.genericBusinessWordcloudIndividualQuotes.map((datum) => (
  //         <IndividualQuoteWordcloud data={datum} />
  //       )),
  //     );
  //   }
  // }, [data]);

  // if (!genericWordcloudLoaded && individualWordclouds.length === 0) {
  //   return null;
  // }

  // if (allTimeWords.length === 0 && individualWords.length === 0) {
  //   return null;
  // }

  if (
    (!allTimeData || allTimeData.genericBusinessWordcloud.length === 0) &&
    (!individualData ||
      individualData.genericBusinessWordcloudIndividualQuotes.length === 0)
  ) {
    return null;
  }

  return (
    <Container>
      <Card>
        <CardHeader title='Generic Business Inputs' />
        <InterimCarousel>
          {[
            ...(allTimeData?.genericBusinessWordcloud?.length > 0
              ? [
                  <AllTimeGenericWordFrequencies
                    words={convertToWordData(
                      allTimeData?.genericBusinessWordcloud,
                    )}
                  />,
                ]
              : []),
            ...(individualData?.genericBusinessWordcloudIndividualQuotes
              ?.length > 0
              ? individualData?.genericBusinessWordcloudIndividualQuotes.reduce(
                  (acc, individualSessionData) => {
                    const words = convertToWordData(
                      individualSessionData?.wordcloud,
                    );
                    if (words.length > 0) {
                      acc.push(
                        <IndividualQuoteWordcloud
                          words={words}
                          data={individualSessionData}
                        />,
                      );
                    }
                    return acc;
                  },
                  [],
                )
              : []),
          ]}
        </InterimCarousel>
      </Card>
    </Container>
  );
};

interface AllTimeGenericWordFrequenciesProps {
  words: Array<WordData>;
}
const AllTimeGenericWordFrequencies = ({
  words,
}: AllTimeGenericWordFrequenciesProps) => {
  const wordcloudOptions = useWordcloudOptions(words);
  const generateWordcloudTextOptions = useGetWordcloudTextOptions();

  if (words.length === 0) {
    return null;
  }

  return (
    <>
      <TitleAndIconContainer>
        <Typography variant='h5'>All time</Typography>
        <CustomTooltip title='This is the text entered by all users who were not able to find their business in the business selection'>
          <HelpIcon sx={{ pl: 1 }} />
        </CustomTooltip>
      </TitleAndIconContainer>
      <Wordcloud {...wordcloudOptions}>
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            // @ts-expect-error
            <Text key={w.text} {...generateWordcloudTextOptions(w, i)}>
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </>
  );
};

interface IndividualQuoteWordCloudProps {
  words: Array<WordData>;
  data: ArrayElement<
    GenericBusinessWordcloudIndividualQuotesQuery['genericBusinessWordcloudIndividualQuotes']
  >;
}
const IndividualQuoteWordcloud = ({
  words,
  data,
}: IndividualQuoteWordCloudProps) => {
  const wordcloudOptions = useWordcloudOptions(words);
  const generateWordcloudTextOptions = useGetWordcloudTextOptions();

  return (
    <>
      <TitleAndIconContainer>
        <Typography variant='h5'>
          Individual Application
          {data.quoteIdentifierName ? ` - ${data.quoteIdentifierName}` : null}
        </Typography>
        <CustomTooltip title="This is the text entered by an individual applicant who couldn't find a matching business in the business selection stage">
          <HelpIcon sx={{ pl: 1 }} />
        </CustomTooltip>
      </TitleAndIconContainer>
      <Wordcloud {...wordcloudOptions} height={0.8 * wordcloudOptions.height}>
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            // @ts-expect-error
            <Text key={w.text} {...generateWordcloudTextOptions(w, i)}>
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
      <br />
      {data?.quoteUuid ? (
        <Button
          component={Link}
          to={`/insurtech/manager/quotes/details/${data.quoteUuid}`}
        >
          Go to Application
        </Button>
      ) : (
        <Typography sx={{ m: 1 }}>No application completed</Typography>
      )}
      <Typography sx={{ m: 1 }}>
        <span style={{ fontWeight: 'bold' }}>Additional Information: </span>
        <span>{data.additionalInformation || 'No additional information'}</span>
      </Typography>
    </>
  );
};

const InterimCarousel = ({ children }: PropsWithChildren<{}>) => {
  const [actualChildren, setActualChildren] = useState<Array<ReactNode>>([]);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (Array.isArray(children)) {
      setActualChildren(children.filter((node) => !!node));
    } else {
      setActualChildren([children].filter((node) => !!node));
    }
  }, [children]);

  if (actualChildren.length === 0) {
    return null;
  }
  return (
    <CarouselContainer>
      {actualChildren.map((child, childIndex) => {
        const Container =
          childIndex === index ? VisibleContainer : HiddenContainer;
        return <Container>{child}</Container>;
      })}
      {actualChildren.length > 1 ? (
        <CarouselButtonContainer>
          <Button
            onClick={() =>
              setIndex((oldIndex) =>
                correctMod(oldIndex - 1, actualChildren.length),
              )
            }
          >
            Back
          </Button>
          <CarouselIndexIndicator index={index} total={actualChildren.length} />
          <Button
            onClick={() =>
              setIndex((oldIndex) =>
                correctMod(oldIndex + 1, actualChildren.length),
              )
            }
          >
            Next
          </Button>
        </CarouselButtonContainer>
      ) : null}
    </CarouselContainer>
  );
};

interface CarouselIndexIndicatorProps {
  index: number;
  total: number;
}
const CarouselIndexIndicator = ({
  index,
  total,
}: CarouselIndexIndicatorProps) => {
  if (total === 0) {
    return null;
  }
  return (
    <Typography
      sx={{ color: 'primary.main', fontWeight: 'bold', alignSelf: 'center' }}
    >
      {index + 1} / {total}
    </Typography>
  );
};

const TitleAndIconContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: theme.spacing(2),
  };
});

const CustomTooltip = styled(Tooltip)(({ theme }) => {
  return {
    tooltip: {
      fontSize: '0.9rem',
      backgroundColor: theme.palette.primary.main,
      padding: '8px',
    },
  };
});

const HiddenContainer = styled(Card)(({ theme }) => {
  return {
    display: 'none',
    visibility: 'hidden',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  };
});

const VisibleContainer = styled(Card)(({ theme }) => {
  return {
    display: 'revert',
    visibility: 'visible',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  };
});

const CarouselContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

const CarouselButtonContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spacing(1),
  };
});
