/**
 * Merge the old keywords (array of strings) with the new ones (single string with individual keywords separated with commas). Should filter out out blank keywords or ones that are already in the list of old ones.
 * @param oldKeywords - An array of the existing keywords
 * @param newKeywords - A single string containing the new keywords, separated by a comma
 */
export const mergeExistingKeywordsWithNew = (
  oldKeywords: Array<string>,
  newKeywords: string | undefined,
) => {
  if (!newKeywords) {
    return oldKeywords;
  }
  return [
    ...oldKeywords,
    ...newKeywords
      .split(',')
      .map((keyword) => keyword.trim())
      .filter(
        (candidateKeyword) =>
          candidateKeyword.length > 0 &&
          !oldKeywords.includes(candidateKeyword),
      ),
  ];
};
