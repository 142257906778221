import { graphql } from '../gql';

export const updateExistingForm = graphql(`
  mutation updateExistingForm(
    $finalFormId: ID!
    $questionInstances: [QuestionInstanceInput]!
    $qualifyingQuestion: QuestionInstanceInput
    $businessLineId: ID!
    $token: String!
    $required: Boolean
    $policyExtension: [PolicyExtensionInput]
  ) {
    updateFinalForm(
      finalFormId: $finalFormId
      questionInstances: $questionInstances
      qualifyingQuestion: $qualifyingQuestion
      businessLineId: $businessLineId
      token: $token
      required: $required
      policyExtension: $policyExtension
    ) {
      ok
      finalForm {
        id
        qualifyingQuestion {
          ...QuestionInstanceFields
        }
        policyExtension {
          label
          value
          questionId
          multiplier
        }
        required
        questionInstances {
          ...QuestionInstanceFields
          subQuestions {
            ...QuestionInstanceFields
            subQuestions {
              ...QuestionInstanceFields
              subQuestions {
                ...QuestionInstanceFields
                subQuestions {
                  ...QuestionInstanceFields
                  subQuestions {
                    ...QuestionInstanceFields
                    subQuestions {
                      ...QuestionInstanceFields
                      subQuestions {
                        ...QuestionInstanceFields
                        subQuestions {
                          ...QuestionInstanceFields
                          subQuestions {
                            ...QuestionInstanceFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
