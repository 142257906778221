// @ts-nocheck
import 'react-sortable-tree/style.css';
import { withStyles } from '@mui/styles';
import { formStore, ProgramBuilderWizard } from '../../../../store';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useState, useEffect, useCallback } from 'react';

import {
  SortableTreeWithoutDndContext as SortableTree,
  toggleExpandedForAll,
} from 'react-sortable-tree';
import Theme from './Theme';
import QuestionsDatabase from './QuestionsDatabase';
import EditAllDialogue from '../common/EditAllDialogue';
import { shallowEquals } from '../../../../util/QuestionInstance';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// types and classes
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { BusinessType } from '../../../../Typescript';
import {
  ProgramBuilderQuestionInstance,
  ProgramBuilderForm,
} from '../../classes';
import { transformQuestionListToTreeData } from '../../../util/FormManager/questionListTree';
import {
  useAllowByPermission,
  useAllowByQuestionEditPermissions,
} from '../../../../hooks';

// This type must be assigned to the tree via the `dndType` prop as well

const externalNodeType = 'databaseQuestion';

const DragDropFormBuilderWithStyles = withStyles((theme) => ({
  root: {
    maxWidth: '70rem',
  },

  container: {
    marginTop: theme.spacing(2),
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '27% auto',
  },
  singleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: theme.spacing(2),
    // display: 'grid',
    // width: '100%',
    // gridTemplateColumns: '27% auto',
  },
  sortable: {
    paddingLeft: theme.spacing(3),
  },
}))((props: DragDropFormBuilderProps) => <DragDropFormBuilder {...props} />);

interface DragDropFormBuilderProps {
  form: ProgramBuilderForm;
  classes: GenericObject;
  updateForm: (form: ProgramBuilderForm, treeData: Array<$TSFixMe>) => void;
  quotePreview?: $TSFixMe;
  addPendingQuestionData: (path: $TSFixMe, node: $TSFixMe) => void;
  updateEditAll: (
    form: ProgramBuilderForm,
    question: ProgramBuilderQuestionInstance,
    editAllFlag: boolean,
  ) => void;
  refreshStoreData?: (selectedBusinessType: BusinessType) => void;
  requestedBusinessType?: $TSFixMe;
}

function DragDropFormBuilder({
  form,
  classes = {},
  updateForm,
  addPendingQuestionData,
  updateEditAll,
}: DragDropFormBuilderProps) {
  const [pendingTreeData, setPendingTreeData] = useState<$TSFixMe>();
  const [topLevelQuestion, setTopLevelQuestion] =
    useState<ProgramBuilderQuestionInstance | null>(null);
  const [userEditAllResponse, setUserEditAllResponse] = useState<
    boolean | undefined
  >();
  const [editAllDialogueOpen, setEditAllDialogueOpen] =
    useState<boolean>(false);
  const [myTreeData, setMyTreeData] = useState<
    ProgramBuilderQuestionInstance[]
  >(transformQuestionListToTreeData(form.questionInstances));
  const [myTreeDataHasCollapsibleNodes, setMyTreeDataHasCollapsibleNodes] =
    useState<boolean>(
      myTreeData.some((question) => question.children.length != 0),
    );
  const hasFormEditPermissions = useAllowByPermission('edit:forms');
  const { canEditQuestion } = useAllowByQuestionEditPermissions();

  useEffect(() => {
    setMyTreeData(transformQuestionListToTreeData(form.questionInstances));
  }, [form]);

  useEffect(() => {
    setMyTreeDataHasCollapsibleNodes(
      myTreeData.some((question) => question.children.length != 0),
    );
  }, [myTreeData]);

  useEffect(() => {
    if (userEditAllResponse !== undefined && topLevelQuestion) {
      updateForm(form, pendingTreeData);
      updateEditAll(form, topLevelQuestion, userEditAllResponse);
      setUserEditAllResponse(undefined);
    }
  }, [userEditAllResponse, topLevelQuestion]);

  const requestUpdateForm = useCallback(
    (
      form: ProgramBuilderForm,
      treeData: $TSFixMe,
      setEditAllDialogueOpen: $TSFixMe,
      setPendingTreeData: $TSFixMe,
      setTopLevelQuestion: $TSFixMe,
    ) => {
      const changed = findConcerningChangedQuestion(form, treeData);
      if (changed) {
        /*console.log('DragDropFormBuilder Found concerning changed question'); */
        setPendingTreeData(treeData);
        setTopLevelQuestion(changed);
        setEditAllDialogueOpen(true);
      } else {
        updateForm(form, treeData);
      }
    },
    [
      setPendingTreeData,
      setTopLevelQuestion,
      setEditAllDialogueOpen,
      updateForm,
    ],
  );

  const toggleExpandTree = useCallback(
    (expanded: boolean) => {
      const newTreeData: $TSFixMe[] = toggleExpandedForAll({
        treeData: myTreeData,
        expanded,
      });
      setMyTreeData(newTreeData);
    },
    [setMyTreeData],
  );

  return (
    <div className={classes.root}>
      <EditAllDialogue
        classes={classes}
        open={editAllDialogueOpen}
        setOpen={setEditAllDialogueOpen}
        editAllSiblingFormCount={
          topLevelQuestion ? topLevelQuestion.ancillary.parentForms.length : 0
        }
        setUserEditAllResponse={setUserEditAllResponse}
      />

      <DndProvider backend={HTML5Backend}>
        <div
          className={
            !hasFormEditPermissions
              ? classes.singleContainer
              : classes.container
          }
        >
          {hasFormEditPermissions ? (
            <QuestionsDatabase
              recursiveTransform={transformQuestionListToTreeData}
            />
          ) : null}
          <div className={classes.sortable}>
            <Stack
              mb={1}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent='flex-start'
              spacing={2}
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => toggleExpandTree(true)}
                disabled={!myTreeDataHasCollapsibleNodes}
              >
                Expand All
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => toggleExpandTree(false)}
                disabled={!myTreeDataHasCollapsibleNodes}
              >
                Collapse All
              </Button>
            </Stack>
            <SortableTree
              // @ts-expect-error
              theme={Theme}
              treeData={myTreeData}
              onMoveNode={(...args) => {
                const { treeData } = args[0];
                requestUpdateForm(
                  form,
                  treeData,
                  setEditAllDialogueOpen,
                  setPendingTreeData,
                  setTopLevelQuestion,
                );
              }}
              onChange={(treeData) => {
                // @ts-expect-error
                setMyTreeData(treeData);
              }}
              canDrop={canDrop}
              isVirtualized={false}
              dndType={externalNodeType}
              // @ts-expect-error
              generateNodeProps={({ node, path }) => {
                const permissions = {
                  hasGlobalQuestionEditPermissions: hasFormEditPermissions,
                  canEditQuestion: canEditQuestion(node),
                };
                return {
                  buttons: [
                    addPendingQuestionData,
                    myTreeData,
                    requestUpdateForm,
                    form,
                  ],
                  setEditAllDialogueOpen,
                  setPendingTreeData,
                  setTopLevelQuestion,
                  ...permissions,
                };
              }}
            />
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

function mapStateToProps(state: $TSFixMe) {
  const { requestedBusinessType } = state.programBuilder;

  return {
    requestedBusinessType,
  };
}

const canDrop = (props: $TSFixMe) => {
  /*console.log('DRAGDROPFORMBUILDER: canDrop props:', props); */
  const { nextParent } = props;
  // debugger;

  if (!nextParent) {
    return true;
  }
  if (nextParent.dataType === 'nested') {
    return true;
  }
  return false;
};

function deepEquals(
  question: ProgramBuilderQuestionInstance,
  comparisonQuestion: ProgramBuilderQuestionInstance,
) {
  if (!shallowEquals(question, comparisonQuestion)) {
    return false;
  }
  if (question.subQuestions) {
    if (!comparisonQuestion.subQuestions) {
      return false;
    } else {
      if (
        question.subQuestions.length !== comparisonQuestion.subQuestions.length
      ) {
        return false;
      }
      for (let i = 0; i < question.subQuestions.length; i++) {
        if (
          !deepEquals(
            question.subQuestions[i],
            comparisonQuestion.subQuestions[i],
          )
        ) {
          return false;
        }
      }
    }
  }
  return true;
}

function findConcerningChangedQuestion(
  form: ProgramBuilderForm,
  treeData: Array<$TSFixMe>,
): ProgramBuilderQuestionInstance | undefined {
  for (
    let i = 0;
    form.questionInstances && i < form.questionInstances.length;
    i++
  ) {
    let question = form.questionInstances[i];
    if (
      !question.ancillary.parentForms ||
      question.ancillary.parentForms.filter((my_form) => form.id !== my_form.id)
        .length < 1 ||
      question.options.editAll !== undefined
    ) {
      continue;
    }
    const matchingQuestion = treeData.find((ele: $TSFixMe) =>
      ele.id && question.id
        ? ele.id === question.id
        : ele.clientId && question.clientId
        ? ele.clientId === question.clientId
        : undefined,
    );
    if (matchingQuestion !== undefined) {
      matchingQuestion.subQuestions = [...matchingQuestion.children];
      if (!deepEquals(matchingQuestion, question)) {
        return matchingQuestion;
      }
    }
  }
  return undefined;
}

function mapDispatchToProps(dispatch: $TSFixMe) {
  function updateEditAll(
    form: ProgramBuilderForm,
    question: ProgramBuilderQuestionInstance,
    editAllFlag: boolean,
  ) {
    return dispatch(
      formStore.actions.setEditAll({ form, question, editAllFlag }),
    );
  }
  function addPendingQuestionData(path: $TSFixMe, node: $TSFixMe) {
    // debugger;
    return dispatch(
      ProgramBuilderWizard.actions.addPendingQuestion({ path, node }),
    );
  }

  function clearPendingQuestion() {
    return dispatch(ProgramBuilderWizard.actions.clearPendingQuestion());
  }
  function updateForm(form: ProgramBuilderForm, treeData: $TSFixMe) {
    const recursivelyAddClientId = (list: Array<$TSFixMe>) =>
      list.map((ele: $TSFixMe) => {
        const newQuestionInstance = new ProgramBuilderQuestionInstance(ele);
        newQuestionInstance.children = recursivelyAddClientId(ele.children);
        return newQuestionInstance;
      });

    let newForm = form.copy();
    newForm.questionInstances = recursivelyAddClientId(treeData);

    return dispatch(formStore.actions.updateTreeStructure({ form: newForm }));
  }
  function refreshStoreData(selectedBusinessType: BusinessType) {
    return dispatch(
      ProgramBuilderWizard.actions.requestBusinessTypeProgram({
        selectedBusinessType,
      }),
    );
  }

  return {
    updateEditAll,
    updateForm,
    addPendingQuestionData,
    clearPendingQuestion,
    refreshStoreData,
  };
}

export const DragDropConnect = connect(mapStateToProps, mapDispatchToProps);

export default DragDropConnect((mappedState) => (
  <DragDropFormBuilderWithStyles {...mappedState} />
));
