import Fuse from 'fuse.js';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

export const BUSINESS_NOT_FOUND_LABEL = "My business isn't shown";

interface Option {
  value: any;
  label: string;
  keywords: Array<string>;
}

export const businessOptionsMatcher = (
  options: Array<Option>,
  state: { inputValue: string; [key: string]: unknown },
): Array<{
  value: any;
  label: $TSFixMe;
  matches?: $TSFixMe;
}> => {
  if (!state.inputValue) {
    return options;
  }
  const searchOptions = {
    includeScore: true,
    includeMatches: true,
    shouldSort: true,
    threshold: 0.3,
    keys: [
      { name: 'label', weight: 0.6 },
      {
        name: 'keywords',
        weight: 0.4,
      },
    ],
  };
  const fuse = new Fuse(options, searchOptions);
  const results = fuse.search(state.inputValue);
  let newOptions = results
    // @ts-expect-error
    .filter((result) => result.item.label !== BUSINESS_NOT_FOUND_LABEL)
    .slice(0, 10)
    .map((result: $TSFixMe) => ({
      value: result.item.value,
      label: result.item.label,
      matches: result.matches,
    }));

  if (newOptions.length < 10) {
    const notFoundOption = options.find(
      (option) => option.label === BUSINESS_NOT_FOUND_LABEL,
    );
    if (notFoundOption) {
      newOptions.push({
        value: notFoundOption.value,
        label: notFoundOption.label,
        matches: [],
      });
    }
  }
  return newOptions;
};

export function generateHighlightedLabel({
  label,
  matches,
}: {
  label: string;
  matches?: Array<{
    key: string;
    indices: [number, number];
  }>;
}) {
  let formattedLabel;
  try {
    if (!matches || matches.length === 0) {
      return label;
    }
    const relevantMatch = matches.find((match) => match.key === 'label');
    if (!relevantMatch) {
      return label;
    }
    formattedLabel = label
      .split('')
      .map((char: string, index: number) => {
        let formatted = '';
        // @ts-expect-error
        if (relevantMatch.indices.find(([start, end]) => start === index)) {
          formatted = '<mark>';
        }
        formatted += char;
        // @ts-expect-error
        if (relevantMatch.indices.find(([start, end]) => end === index)) {
          formatted += '</mark>';
        }
        return formatted;
      })
      .join('');
    return <span dangerouslySetInnerHTML={{ __html: formattedLabel }}></span>;
  } catch (e) {
    console.error(e);
    return label;
  }
}

/**
 * Take a mangled id (for the business selection component) and retrieve the original id. It can be mangled either by placing the original id at the beginning or the end, and in fact it's possible that it's not mangled at all.
 */
export const unmangleId = (mangledId: string): string => {
  let finalId = mangledId;
  const finalMangleCheck = /^\w+_(\d+)$/;
  const endingMangleCheck = /^(\d+)_\w+/;
  [finalMangleCheck, endingMangleCheck].forEach((mangleCheck) => {
    if (mangleCheck.test(mangledId)) {
      const match = mangledId.match(mangleCheck);
      if (match && match.length >= 2) {
        finalId = match[1]; // match against the group, not the entire match
      }
    }
  });
  return finalId;
};
