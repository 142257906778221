import { RouteComponentProps } from '@reach/router';
import { Calendar, Form } from '@calefy-inc/informedMaterial';
import { withFormStyles, WithFormStyles } from '../../util/withFormStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { GEOGRAPHY_REPORT } from '../../queries';
import { useAuth } from '@calefy-inc/authentication';
import { GeographyReportQuery } from '../../gql/graphql';
import { OrganizationSelect } from './components/OrganizationSelect';
import { ArrayElement } from '@calefy-inc/utilityTypes';

interface GeographyReportProps extends RouteComponentProps, WithFormStyles {}
export const GeographyReport = withFormStyles()(
  ({ classes }: GeographyReportProps) => {
    const { token } = useAuth();
    const [getGeographyReport, { loading, error, data }] =
      useLazyQuery(GEOGRAPHY_REPORT);

    // get the organizations to populate the dropdown
    return (
      <div>
        <h1>Geography Report</h1>
        <Form
          className={classes.form}
          onSubmit={
            // @ts-expect-error
            (values) => {
              const { startDate, endDate, organizationName } = values;
              const actualStartDate = convertDate(startDate);
              const actualEndDate = convertDate(endDate);
              getGeographyReport({
                variables: {
                  token,
                  organizationName,
                  startDate: actualStartDate,
                  endDate: actualEndDate,
                },
              });
            }
          }
        >
          {/* 
          // @ts-expect-error */}
          <OrganizationSelect
            field='organizationName'
            label='Organization'
            className={classes.selectField}
          />
          <Calendar field='startDate' className={classes.calendar} />
          <Calendar field='endDate' className={classes.calendar} />
          <LoadingButton loading={loading} type='submit'>
            Submit!
          </LoadingButton>
        </Form>
        {error ? (
          <ErrorDisplay>
            ERROR:
            <pre>{JSON.stringify(error, null, 4)}</pre>
          </ErrorDisplay>
        ) : null}
        {data ? (
          <TableContainer>
            <GeographyReportTable data={data.geographyReport} />
          </TableContainer>
        ) : null}
      </div>
    );
  },
);

interface GeographyReportTableProps {
  data: GeographyReportQuery['geographyReport'];
}
const GeographyReportTable = ({ data }: GeographyReportTableProps) => {
  if (!data) {
    return null;
  }
  if (data.length === 0) {
    return <p>No applications match the search criteria.</p>;
  }
  return (
    <>
      <button
        type='button'
        onClick={() => {
          const columns: Array<{
            name: string;
            accessor: (
              r: NonNullable<
                ArrayElement<GeographyReportQuery['geographyReport']>
              >,
            ) => string;
          }> = [
            { name: 'Unique ID', accessor: (report) => report.uniqueId },
            { name: 'Date Added', accessor: (report) => report.dateAdded },
            {
              name: 'Address',
              accessor: (report) => report.mailingAddress.address,
            },
            { name: 'City', accessor: (report) => report.mailingAddress.city },
            {
              name: 'Province',
              accessor: (report) => report.mailingAddress.province,
            },
            {
              name: 'Postal',
              accessor: (report) => report.mailingAddress.postal,
            },
          ];
          const csv =
            'data:text/csv;charset=utf-8,' +
            `${columns.map((column) => column.name).join(',')}\n` +
            data
              .map((report) =>
                columns
                  .map((column) => column.accessor(report))
                  .map((elem) => `"${elem}"`)
                  .join(','),
              )
              .join('\n');
          const uri = window.encodeURI(csv);
          window.open(uri);
        }}
      >
        Download
      </button>
      <table>
        <thead>
          <tr>
            {['ID', 'Date', 'Address', 'City', 'Province', 'Postal Code'].map(
              (header) => (
                <th scope='col' key={header}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((report) => {
            if (!report) return null;
            return (
              <tr key={report.uniqueId}>
                <td>
                  {report.uniqueId
                    ? `${report.uniqueId.slice(0, 4)}...${report.uniqueId.slice(
                        -4,
                      )}`
                    : null}
                </td>
                <td>
                  {report.dateAdded
                    ? new Intl.DateTimeFormat('en-CA', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }).format(new Date(report.dateAdded))
                    : null}
                </td>
                <td>{report?.mailingAddress?.address}</td>
                <td>{report?.mailingAddress?.city}</td>
                <td>{report?.mailingAddress?.province}</td>
                <td>{report?.mailingAddress?.postal}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const TableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
const ErrorDisplay = styled('p')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});

const convertDate = (date: undefined | null | string) => {
  if (!date) {
    return undefined;
  }
  const [month, day, year] = date.split('/');
  const dateString = [year, month, day].join('-');
  return new Date(dateString);
};
