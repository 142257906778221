import Bugsnag from '@bugsnag/browser';
import { errorify } from './errorify';

export const safelyGetAttributes = (
  obj: Object,
  attrs: Array<string | number>,
) => {
  try {
    let current = obj;
    for (let attr of attrs) {
      //console.log({ current, attr });
      if (attr in current) {
        // @ts-expect-error
        current = current[attr];
      } else {
        return null;
      }
    }
    return current;
  } catch (e) {
    Bugsnag.notify(errorify(e));
  }
};
