// @ts-nocheck

import theme from './OraclermsTheme';
import logo from './assets/MainLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import { Icon } from '@iconify/react';

import { $TSFixMe } from '@calefy-inc/utilityTypes';

const tileIcons = (internalName: string) => {
  switch (internalName) {
    case 'cement_concrete_work_not_otherwise_classified':
      return <Icon icon='fa-solid:hard-hat' width='35' />;

    case 'tree_removal_trimming_pruning':
      return <Icon icon='fa-solid:tree' width='35' />;

    case 'excavation':
      return <Icon icon='fa-solid:snowplow' width='35' />;

    case 'fences_construction_of':
      return <Icon icon='fa-solid:screwdriver' width='35' />;

    case 'gardening_for_others_landscaping':
      return <Icon icon='fa-solid:seedling' width='35' />;

    case 'highway_street_road_incl_paving_resurfacing':
      return <Icon icon='fa-solid:hard-hat' width='35' />;

    case 'masonry_incl_bricklaying_stonework_tile_marble_mosaic_stuccoing':
      return <Icon icon='fa-solid:drafting-compass' width='35' />;

    case 'roofing_other_hot_tar_etc':
      return <Icon icon='fa-solid:wrench' width='35' />;

    case 'wrecking_demolition_not_otherwise_classified':
      return <Icon icon='fa-solid:dumpster' width='35' />;

    case 'carpentry_away_from_shop':
      return <Icon icon='fa-solid:hammer' width='35' />;

    case 'electrical_contractor':
      return <Icon icon='fa-solid:bolt' width='35' />;

    case 'plastering_lathing_incl_drywall':
      return <Icon icon='fa-solid:pencil-alt' width='35' />;

    case 'plumbing_and_gas_fitting_services':
      return <Icon icon='fa-solid:faucet' width='35' />;

    case 'general_contractors':
      return <Icon icon='fa-solid:toolbox' width='35' />;

    case 'flooring_and_tiling_contractor':
      return <Icon icon='fa-solid:ruler-combined' width='35' />;

    case 'framing_contractor':
      return <Icon icon='fa-solid:pencil-ruler' width='35' />;

    case 'handyman':
      return <Icon icon='fa-solid:tools' width='35' />;

    case 'hvac_contractor':
      return <Icon icon='fa-solid:fan' width='35' />;

    case 'painting_contractor':
      return <Icon icon='fa-solid:paint-roller' width='35' />;

    default:
      return <Icon icon='fa-solid:hard-hat' width='35' />;
  }
};

const businessFilter = (business) => {
  const allowedBusinessNames = [
    'cement_concrete_work_not_otherwise_classified',
    'tree_removal_trimming_pruning',
    'excavation',
    'fences_construction_of',
    'gardening_for_others_landscaping',
    'highway_street_road_incl_paving_resurfacing',
    'masonry_incl_bricklaying_stonework_tile_marble_mosaic_stuccoing',
    'roofing_other_hot_tar_etc',
    'wrecking_demolition_not_otherwise_classified',
    'carpentry_away_from_shop',
    'electrical_contractor',
    'plastering_lathing_incl_drywall',
    'plumbing_and_gas_fitting_services',
    'general_contractors',
    'flooring_and_tiling_contractor',
    'framing_contractor',
    'handyman',
    'hvac_contractor',
    'painting_contractor',
  ];
  if (
    process.env.NODE_ENV !== 'production' ||
    window.location.origin === 'https://staging-calefy.fuseinsurance.ca'
  ) {
    allowedBusinessNames.push('testing_business');
  }
  return allowedBusinessNames.includes(business.internalName);
};

// Cannabis Insurance Config
const settings = new WebsiteSettings({
  slug: 'oraclerms',
  // host: 'secure.cannabisinsurance.ca',
  companyName: 'Oracle RMS Insurance Brokers',
  hours: '8:30am - 5:30pm',
  phone: '',
  email: 'info@oraclerms.com',
  chatLink: '',
  companyTheme: theme,
  logo: logo,
  businessFilter: businessFilter,
  tileIcons: tileIcons,
  title: 'Commercial, Personal, Life',
  favicon: favicon,
});

export default settings;
