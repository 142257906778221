import { graphql } from '../../gql';

export const ALL_AUTHORIZATION_TOKENS = graphql(`
  query allAuthorizationTokens($token: String!) {
    allAuthorizationTokens(token: $token) {
      id
      token
      description
      valid
      createdAt
      organization {
        name
      }
    }
  }
`);
