import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  POLICY,
  BUSINESS_TYPE,
  FINAL_FORM_MODIFIER,
} from '../../../../../queries';
import LoadingScreen from '../../../components/LoadingScreen';
import { setCurrentlyEditingFinalFormModifier } from '../../../../../store/FormModifierStore';
// types and classes
import { RouteComponentProps } from '@reach/router';
import { FinalFormModifier } from './classes';
import { ALL_SIGNIFIER } from './classes/FinalFormModifier';
import type { BooleanWithUnknown } from '@calefy-inc/utilityTypes';
import { FormModifierBuilder } from './FormModifierManager';

const validateBusinessId = (businessId: unknown) => {
  if (typeof businessId !== 'string') {
    return false;
  }
  if (businessId === 'all') {
    return true;
  }
  if (!Number.isNaN(Number(businessId))) {
    return true;
  }
  return false;
};

const validatePolicyId = (policyId: unknown) => {
  if (typeof policyId !== 'string') {
    return false;
  }
  if (policyId === 'none') {
    return true;
  }
  if (!Number.isNaN(Number(policyId))) {
    return true;
  }
  return false;
};

interface FormModifierEditProps extends RouteComponentProps {
  businessId?: string;
  policyId?: string;
}
/**
 * Take care of getting the business and policy, as well as the existing FormModifier if it exists. If not, it should create a fake one. Once everything's set up, it should pass the FormModifier into the DragDropFormModifierBuilder
 * @param name - description
 * @returns - description
 */
export const FormModifierEdit = ({
  businessId,
  policyId,
}: FormModifierEditProps) => {
  const dispatch = useDispatch();
  const [policy, setPolicy] = useState<
    FinalFormModifier['policy'] | undefined
  >();
  const [business, setBusiness] = useState<
    FinalFormModifier['business'] | undefined
  >();
  const [formModifierExists, setFormModifierExists] =
    useState<BooleanWithUnknown>('unknown'); // is there an existing FormModifier with the correct business and policy?
  const [formModifier, setFormModifier] = useState<
    FinalFormModifier | undefined
  >();

  const [
    getFinalFormModifier,
    { loading: modifierLoading, error: modifierError, data: modifierData },
  ] = useLazyQuery(FINAL_FORM_MODIFIER);
  const [
    getBusiness,
    { loading: businessLoading, error: businessError, data: businessData },
  ] = useLazyQuery(BUSINESS_TYPE);
  const [
    getPolicy,
    { loading: policyLoading, error: policyError, data: policyData },
  ] = useLazyQuery(POLICY);

  // try to get an existing FinalFormModifier right off the bat
  useEffect(() => {
    if (validatePolicyId(policyId) && validateBusinessId(businessId)) {
      getFinalFormModifier({
        variables: {
          policyId: policyId === 'none' ? undefined : policyId,
          businessId: businessId === 'all' ? undefined : businessId,
          raiseErrors: false,
        },
      });
    }
  }, [policyId, businessId]);

  // if we can load the existing modifier, load it into state
  useEffect(() => {
    if (modifierData?.finalFormModifier) {
      setFormModifier(
        FinalFormModifier.generateFromBackendResponse(
          // @ts-expect-error
          modifierData.finalFormModifier,
        ),
      );
      setFormModifierExists(true);
    } else if (modifierData?.finalFormModifier === null) {
      setFormModifierExists(false);
    }
  }, [modifierData]);

  // set the policy for a newly created FormModifier
  useEffect(() => {
    if (validatePolicyId(policyId) && formModifierExists === false) {
      if (policyId === 'none') {
        setPolicy(null);
      } else {
        getPolicy({
          variables: {
            id: policyId,
          },
        });
      }
    }
  }, [policyId, formModifierExists]);

  // set the business for a newly created FormModifier
  useEffect(() => {
    if (validateBusinessId(businessId) && formModifierExists === false) {
      if (businessId === 'all') {
        setBusiness(ALL_SIGNIFIER);
      } else {
        getBusiness({
          variables: {
            id: businessId,
          },
        });
      }
    }
  }, [businessId, formModifierExists]);

  // set the policy, if we need one, from the backend response
  useEffect(() => {
    if (policyData?.policy) {
      setPolicy(policyData.policy);
    }
  }, [policyData]);

  // set the business, if we need one, from the backend response
  useEffect(() => {
    if (businessData?.businessType) {
      // @ts-expect-error
      setBusiness(businessData.businessType);
    }
  }, [businessData]);

  // if we have the policy and business, create a new FinalFormModifier and set it in state
  useEffect(() => {
    if (
      formModifierExists === false &&
      policy !== undefined &&
      business !== undefined
    ) {
      setFormModifier(
        new FinalFormModifier({
          policy,
          business,
          questionInstances: [],
        }),
      );
    }
  }, [policy, business, formModifierExists]);

  // once we have a form, set it in the state. The builder will listen for (and edit) this
  useEffect(() => {
    if (formModifier) {
      dispatch(setCurrentlyEditingFinalFormModifier(formModifier));
    }
  }, [formModifier]);

  if (!businessId || !policyId) {
    return (
      <p>
        No business id ( {businessId} ) / no policy id ({policyId})
      </p>
    );
  }
  if (!validateBusinessId(businessId)) {
    return <p>Invalid Business Id {businessId}</p>;
  }
  if (!validatePolicyId(policyId)) {
    return <p>Invalid Policy Id {policyId}</p>;
  }
  if (policyError) {
    return (
      <>
        <p>Policy error:</p>
        <pre>{JSON.stringify(policyError, null, 4)}</pre>
      </>
    );
  }
  if (businessError) {
    return (
      <>
        <p>Business error:</p>
        <pre>{JSON.stringify(businessError, null, 4)}</pre>
      </>
    );
  }
  if (modifierError) {
    <>
      <p>Modifier Error</p>
      <pre>{JSON.stringify(modifierError, null, 4)}</pre>
    </>;
  }
  if (businessLoading || policyLoading || modifierLoading) {
    return <LoadingScreen />;
  }
  return <FormModifierBuilder />;
};
