import { LanguageInput } from '../backend/inputTypes';
import { ILanguageAwareString } from './LanguageAwareString';
import { LanguageAwareString } from './LanguageAwareString';

export interface LanguageInterface {
  shortName: string;
  fullName: string;
}

/**
 * A language
 * @param shortName - A short (roughly ISO 639-1 code)
 * @param fullName - The full name (in English) of the language, suitable for display
 */
export class Language implements LanguageInterface {
  shortName: string;
  fullName: string;

  static english = new Language('en', 'English');
  static french = new Language('fr', 'French');
  static defaultLanguage = Language.english;

  constructor(shortName: string, fullName: string) {
    this.shortName = shortName;
    this.fullName = fullName;
  }

  /**
   * Returns whether this Language is equal to another one
   */
  equals(other: LanguageInterface): boolean;
  equals(other: Language) {
    return (
      this.shortName === other.shortName && this.fullName === other.fullName
    );
  }

  /**
   * Generate a version of the string prefixed with the language's short name
   */
  generatePrefixedString(base: string) {
    return `${this.shortName}_${base}`;
  }

  generateLanguageAwareString(value: string) {
    return new LanguageAwareString(value, this);
  }

  /**
   * Returns a copy of the object
   */
  copy() {
    return new Language(this.shortName, this.fullName);
  }

  /**
   * Convert a language so it can be sent to the backend
   */
  toLanguageInput(): LanguageInput {
    return {
      ...this,
    };
  }

  /**
   * Determine whether this Language instance is in a list of Languages
   */
  inLanguageList(list: Array<LanguageInterface>): boolean {
    return list.some((language) => this.equals(language));
  }

  /**
   * Determines whether this language has an element in a list of LanguageAwareStrings which matches this language
   */
  inLanguageAwareStringList(list: Array<ILanguageAwareString>) {
    const mappedLanguages = list.map((las) => las.language);
    return this.inLanguageList(mappedLanguages);
  }

  /**
   * Create an instance from an object which already has the right shape (typically this will be the response from the backend)
   */
  static createFromObject(backendLanguage: {
    shortName: string;
    fullName: string;
  }) {
    const { fullName, shortName } = backendLanguage;
    return new Language(shortName, fullName);
  }

  /**
   * Alias of createFromObject for consistency with other classes
   */
  static createFromBackendResponse(response: {
    shortName: string;
    fullName: string;
  }) {
    return Language.createFromObject(response);
  }

  /**
   * Returns the unique Languages in a list of Languages
   */
  static unique(list: Array<Language>) {
    return list.filter(
      (language, index, allLanguages) =>
        index ===
        allLanguages.findIndex((innerLanguage) =>
          innerLanguage.equals(language),
        ),
    );
  }
  /**
   * Find the union of lists of Languages
   */
  static union(...lists: Array<Array<Language>>) {
    if (lists.length === 0) {
      return [];
    }
    if (lists.length === 1) {
      return lists[0];
    }
    const result = Language.unique(lists[0]);
    lists.slice(1).forEach((languageList) =>
      languageList.forEach((otherLanguage) => {
        if (!otherLanguage.inLanguageList(result)) {
          result.push(otherLanguage);
        }
      }),
    );
    return result;
  }

  /**
   * Find the intersection of lists of Languages
   */
  static intersect(...lists: Array<Array<Language>>) {
    if (lists.length === 0) {
      return [];
    }
    if (lists.length === 1) {
      return lists[0];
    }
    const allLanguages = lists.reduce((acc, list) => {
      return [...acc, ...list];
    });
    const result: Array<Language> = [];
    allLanguages.forEach((language) => {
      if (
        lists.every((languageList) => language.inLanguageList(languageList))
      ) {
        result.push(language);
      }
    });
    return Language.unique(result);
  }
}
