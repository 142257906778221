import createComponentRegistry from './Logic';
import { QuoteComponent } from './Logic';
import {
  isLogicQuestionConditionTrue,
  isBackendLogicQuestionConditionTrue,
} from './utility';

export default createComponentRegistry;
export {
  QuoteComponent,
  isLogicQuestionConditionTrue,
  isBackendLogicQuestionConditionTrue,
};
// export { default as createLogicRegistry } from './Logic';
// export { QuoteComponent } from './Logic';
// export { isLogicQuestionConditionTrue } from './utility';
