import { NumberInput, Select } from '@calefy-inc/informedMaterial';
import { useFormApi } from 'informed';
import { Typography } from '@mui/material';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps, ProgramBuilderComponentProps } from './types';

// list of slider datatypes
const numberInputTypes = [
  { value: '$', label: '$' },
  { value: '%', label: '%' },
  { value: 'ft²', label: 'ft²' },
  { value: 'acres', label: 'acres' },
  { value: 'kg', label: 'kg' },
  { value: 'km', label: 'km' },
];

function ProgramBuilderComponent({
  classes = {},
}: ProgramBuilderComponentProps) {
  const formApi = useFormApi();
  return (
    <>
      <Select
        field='props_blob_dataType'
        label='DataType'
        inputId='props_blob_dataType'
        isClearable={true}
        className={classes.selectField}
        classes={classes}
        onChange={() => formApi.setError('props_blob_dataType', undefined)}
        placeholder="What is the prefix or suffix of the input i.e '$'"
        options={numberInputTypes}
      />
      <NumberInput field='defaultValue' label='Default Value' />
      <Typography variant='caption' sx={{ my: 2 }} color='textSecondary'>
        Number Input question prompts longer than 79 characters can cause visual
        discrepencies between textfields
      </Typography>
    </>
  );
}

const quoteComponent = function ({
  questionInstance,
  classes = {},
  ...props
}: QuoteComponentProps) {
  let prefix = null;
  let suffix = null;

  const { dataType } = questionInstance.propsBlob;

  // Assign datatype to prefix or suffix, only need special case if value is a prefix
  switch (dataType) {
    case '$':
      prefix = dataType;
      break;
    default:
      suffix = dataType;
  }

  return (
    <NumberInput
      label={questionInstance.label}
      field={questionInstance.generateFieldName()}
      id={questionInstance.id}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes.textfield}
      classes={classes}
      prefix={prefix}
      suffix={suffix}
      {...props}
    />
  );
};

const IconComponent = () => {
  return <NumbersRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'numberinput',
    typeLabel: 'Number Input',
    quoteComponent,
    dataType: 'number',
    programBuilderComponent: ProgramBuilderComponent,
    icon: IconComponent,
  });
}

export { quoteComponent };
