import { graphql } from '../gql';

export const GET_ALL_POLICIES = graphql(`
  query policies {
    allPolicies {
      id
      displayName
      internalName
      isDeletable
    }
  }
`);

// Demo form policy query
export const POLICY = graphql(`
  query policy($id: ID, $internalName: String, $displayName: String) {
    policy(id: $id, internalName: $internalName, displayName: $displayName) {
      id
      internalName
      displayName
    }
  }
`);

export const CREATE_POLICY = graphql(`
  mutation createPolicy(
    $displayName: String!
    $internalName: String!
    $token: String!
  ) {
    createPolicy(
      displayName: $displayName
      internalName: $internalName
      token: $token
    ) {
      ok
      policy {
        id
        displayName
        internalName
        isDeletable
      }
    }
  }
`);

export const DELETE_POLICY = graphql(`
  mutation deletePolicy($policyId: ID!, $token: String!) {
    deletePolicy(policyId: $policyId, token: $token) {
      ok
      deletedId
    }
  }
`);

export const UPDATE_POLICY = graphql(`
  mutation updatePolicy(
    $policyId: ID!
    $displayName: String!
    $internalName: String!
    $token: String!
  ) {
    updatePolicy(
      policyId: $policyId
      displayName: $displayName
      internalName: $internalName
      token: $token
    ) {
      ok
      policy {
        id
        displayName
        internalName
        isDeletable
      }
    }
  }
`);
