import { graphql } from '../gql';

export const QUOTE_FROM_HASH = graphql(`
  query quoteFromHash($hash: String!, $delete: Boolean) {
    quoteFromHash(hash: $hash, delete: $delete) {
      ... on QuoteType {
        ...QuoteFields
      }
      ... on ArchivedQuoteType {
        ...ArchivedQuoteFields
      }
    }
  }
`);
