import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuoteUUID } from '../store/QuoteWizardState';
import { usePrevious } from './usePrevious';
import { StoreState } from '../store';
import { setBlockContactInfoFirst } from '../store/QuoteWizardState';

const getExistingUuid = (state: StoreState) =>
  state.quoteWizard.currentQuoteUUID;

/**
 * Listen for a change in localStorage related to the given key; if one is found, then dispatch it to the redux store
 * @param key - The key in localStorage to check for the new uuid
 */
export const useListenForExistingUuid = (key: string = 'existingQuoteUuid') => {
  const [dispatched, setDispatched] = useState<boolean>(false); // have we dispatched it? If yes, then don't do it again
  const existingUuid = useSelector(getExistingUuid);
  const dispatch = useDispatch();

  const eventHandler = useCallback(
    (e: StorageEvent) => {
      if (e.key !== key) {
        return;
      }
      if (e.newValue) {
        dispatch(setBlockContactInfoFirst(true));
        dispatch(setCurrentQuoteUUID({ uuid: e.newValue }));
      }
    },
    [key, dispatch],
  );
  const oldEventHandler = usePrevious(eventHandler);

  // Check if the key is in state on initial load - in that case we don't need to listener
  useEffect(() => {
    const initialValue = window.localStorage.getItem(key);
    if (initialValue && !existingUuid) {
      dispatch(setBlockContactInfoFirst(true));
      dispatch(setCurrentQuoteUUID({ uuid: initialValue }));
      setDispatched(true);
    }
  }, [key, dispatch, existingUuid]);

  // listen for a change and set the existing uuid in store, clearing it when we've done so
  useEffect(() => {
    if (dispatched || existingUuid) {
      if (oldEventHandler) {
        window.removeEventListener('storage', oldEventHandler);
      }
      window.removeEventListener('storage', eventHandler);
      window.localStorage.removeItem(key);
    } else {
      if (oldEventHandler) {
        window.removeEventListener('storage', oldEventHandler);
      }
      window.addEventListener('storage', eventHandler);
    }
  }, [key, dispatch, dispatched, eventHandler]);
};
