import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from '@reach/router';
import { useMutation } from '@apollo/client';
// material
import { LoadingButton } from '@mui/lab';
import { Box, Card } from '@mui/material';
// Informed
import { Form } from 'informed';
import { TextField } from '@calefy-inc/informedMaterial';
// queries
import {
  CREATE_POLICY,
  GET_ALL_POLICIES,
  UPDATE_POLICY,
} from '../../../../../../queries';
// error handler
import Bugsnag from '@bugsnag/js';
import { InsufficientPermissionsErrorPage } from '../../../../../common/ErrorHandling';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../../hooks';
// utils
import { converter } from '../../../../utils/formatInternalName';
import LoadingScreen from '../../../LoadingScreen';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

PolicyNewForm.propTypes = {
  isEdit: PropTypes.bool,
  currentPolicy: PropTypes.object,
};

interface PolicyNewFormProps {
  isEdit: boolean;
  currentPolicy: $TSFixMe;
}
export default function PolicyNewForm({
  isEdit,
  currentPolicy,
}: PolicyNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const hasPolicyEditPermissions = useAllowByPermission('edit:policy');
  // mutations
  const [createPolicy, { data, loading, error }] = useMutation(CREATE_POLICY);

  const [
    updatePolicy,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_POLICY);

  // effects
  useEffect(() => {
    const anyError = error || updateError;
    const anyData = data || updateData;
    const anyLoading = loading || updateLoading;

    if (anyError) {
      enqueueSnackbar(!isEdit ? 'Create failed' : 'Update failed', {
        variant: 'error',
      });
      console.error('Error creating or updating policy:', anyError);
      Bugsnag.notify(anyError);
    }
    if (!anyLoading && !anyError && anyData) {
      enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', {
        variant: 'success',
      });
      navigate('/insurtech/manager/database/policy');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, updateError, updateData]);

  // handlers
  const handleSubmit = (values: $TSFixMe) => {
    const displayName = values.displayName;

    if (isEdit && token) {
      updatePolicy({
        variables: {
          policyId: currentPolicy.id,
          displayName: displayName,
          internalName: converter(displayName),
          token,
        },
      });
    } else {
      createPolicy({
        variables: {
          displayName: displayName,
          internalName: converter(displayName),
          token,
        },
        update: (cache, { data }) => {
          let policies: $TSFixMe;
          try {
            policies = cache.readQuery({ query: GET_ALL_POLICIES });
          } catch (e) {
            console.error('Failed to read policy list from cache');
          }

          try {
            cache.writeQuery({
              query: GET_ALL_POLICIES,
              data: {
                allPolicies: [
                  ...policies.allPolicies,
                  // @ts-expect-error
                  data.createPolicy.policy,
                ],
              },
            });
          } catch (e) {
            console.error('Failed to write to policy list in cache');
          }
        },
      });
    }
  };

  if (isEdit && !currentPolicy) {
    return <LoadingScreen />;
  }

  return token && !hasPolicyEditPermissions ? (
    <InsufficientPermissionsErrorPage deniedIdentifier='Create / Edit Policy page' />
  ) : (
    <Form
      noValidate
      autoComplete='off'
      initialValues={{
        displayName: isEdit ? currentPolicy.displayName : '',
      }}
      onSubmit={handleSubmit}
    >
      <Card sx={{ p: 3 }}>
        <TextField
          fullWidth
          variant='outlined'
          field='displayName'
          label='Display Name'
          required
        />

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            type='submit'
            variant='contained'
            loading={loading || updateLoading}
          >
            {!isEdit ? 'Create Policy' : 'Save Changes'}
          </LoadingButton>
        </Box>
      </Card>
    </Form>
  );
}
