import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { PageBreak } from './PageBreak';
// types and classes
import { PremiumProposal } from '../../../Typescript/backend/classes';

interface IndividualProposalDetailsDisplayProps {
  proposal: PremiumProposal;
  identifier: string;
}

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const IndividualProposalDetailsDisplay = ({
  proposal,
  identifier,
}: IndividualProposalDetailsDisplayProps) => {
  return (
    <>
      <Box
        sx={{
          '@media print': {
            colorAdjust: 'exact',
          },
          // @ts-expect-error
          '--transparent-grey': 'hsl(0, 0%, 50%, 20%)',
          table: { borderCollapse: 'collapse' },
          thead: { borderBottom: 'solid 4px black' },
          td: { padding: '8px 0', verticalAlign: 'top' },
          'td + td': { paddingLeft: '20px' }, // make the second column spaced further from the text
          'th:first-of-type': { width: '50%' }, // make the first column 50%
          /*
          'tbody > tr': {
            borderTop: 'solid 1px hsl(0, 0%, 50%, 50%)',
          },
         */
          'tr:nth-of-type(2n)': {
            background: 'var(--transparent-grey)',
          },
          'tbody > tr:last-of-type': {
            borderTop: 'solid 2px black',
            background: 'none',
          },
        }}
      >
        <Typography
          variant='h2'
          gutterBottom
          sx={{ fontWeight: 600, marginTop: 3 }}
        >
          {identifier} - {formatter.format(proposal.amountInCents / 100)}
        </Typography>
        <ReactMarkdown children={proposal.notes} remarkPlugins={[remarkGfm]} />
      </Box>
      <PageBreak />
    </>
  );
};
