import { generateUseUseSettings } from '../../hooks/generateUseUsingSettings';
import { WalnutCommercialSettings } from './Commercial/settings';
import { WalnutNeoSettings } from './Neo/settings';
import { WalnutAtbSettings } from './Atb/settings';

export const useUsingWalnutSettings = generateUseUseSettings([
  WalnutCommercialSettings,
  WalnutNeoSettings,
  WalnutAtbSettings,
]);
