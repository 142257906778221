import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';
import { InputToggle } from '@calefy-inc/informedMaterial';
import {
  withFormStyles,
  WithFormStyles,
} from '../../../../../util/withFormStyles';
import { RateTableRate } from './types';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@apollo/client';
import { EDIT_INSURANCE_RATE } from '../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';
import { RateChangerProps } from './RateChanger';
import Autocomplete from '@mui/material/Autocomplete';
import { useAllowByPermission } from '../../../../../hooks';
import { DeleteRateButton } from './DeleteRateButton';
import { useSnackbar } from 'notistack';

interface EditRateButtonProps {
  rate: RateTableRate;
  onComplete: RateChangerProps['onComplete'];
  groups: Array<string>;
}
export const EditRateButton = ({
  rate,
  onComplete,
  groups,
}: EditRateButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const hasEditPermissions = useAllowByPermission('edit:insurance:rates');
  return hasEditPermissions === true ? (
    <>
      <IconButton size='small' onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      <EditRateDialog
        open={open}
        onClose={() => setOpen(false)}
        onComplete={onComplete}
        groups={groups}
        rate={rate}
      />
    </>
  ) : null;
};

interface EditRateDialogProps extends WithFormStyles {
  open: boolean;
  onClose: () => void;
  groups: Array<string>;
  rate: RateTableRate;
  onComplete: EditRateButtonProps['onComplete'];
}
const EditRateDialog = withFormStyles()(
  ({
    open,
    onClose,
    groups,
    classes,
    rate,
    onComplete,
  }: EditRateDialogProps) => {
    const { token } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [displayName, setDisplayName] = useState<string>(rate.displayName);
    const [internalName, setInternalName] = useState<string>(rate.internalName);
    const [value, setValue] = useState<string>(String(Number(rate.value)));
    const [group, setGroup] = useState<string>(rate.group);
    const [symbol, setSymbol] = useState<string>(rate.symbol);
    const [errors, setErrors] = useState<
      Record<'internalName' | 'displayName' | 'value', boolean>
    >({ internalName: false, displayName: false, value: false });
    const [touched, setTouched] = useState<
      Record<'internalName' | 'displayName' | 'value', boolean>
    >({ internalName: false, displayName: false, value: false });
    const [editInsuranceRate, { loading, error }] = useMutation(
      EDIT_INSURANCE_RATE,
      {
        onCompleted: (data) => {
          setTimeout(() => onComplete(data), 100);
          enqueueSnackbar('Successfully edited rate', { variant: 'success' });
          // onComplete(data);
          onClose();
          setErrors({
            internalName: false,
            displayName: false,
            value: false,
          });
          setTouched({
            internalName: false,
            displayName: false,
            value: false,
          });
        },
        update: (cache, result) => {
          const updated = result.data?.editRate?.rate;
          if (!updated) {
            return;
          }
          // first update the returned rate
          cache.modify({
            id: cache.identify(updated),
            broadcast: true,
            fields: {
              internalName: () => updated.internalName,
              displayName: () => updated.displayName,
              group: () => updated.group,
              symbol: () => updated.symbol,
              value: () => updated.value,
            },
          });
        },
      },
    );
    // when the required values change, check that they are still good
    useEffect(() => {
      setErrors({
        internalName: internalName.length === 0,
        displayName: displayName.length === 0,
        value:
          value === '' ||
          Number.isNaN(Number(value)) ||
          !Number.isFinite(Number(value)),
      });
    }, [value, internalName, displayName]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Rate</DialogTitle>
        <DialogContent style={{ paddingTop: '24px', width: '600px' }}>
          <Autocomplete
            className={classes.textfield}
            value={group}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label='Group'
                onChange={(e) => setGroup(e.target.value)}
              />
            )}
            options={groups}
          />
          <InputToggle
            className={classes.yesNoToggle}
            field='symbol'
            value={symbol}
            onChange={
              // @ts-expect-error
              (e) => setSymbol(e.target.value)
            }
            options={[
              { value: '$', label: '$' },
              { value: '%', label: '%' },
            ]}
          />
          <TextField
            className={classes.textfield}
            label='Display Name'
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
              setTouched((old) => ({ ...old, displayName: true }));
            }}
          />
          {touched.displayName && errors.displayName ? (
            <ErrorP>This field is required</ErrorP>
          ) : null}
          <TextField
            className={classes.textfield}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setTouched((old) => ({ ...old, value: true }));
            }}
            label='Value'
            InputProps={{
              startAdornment:
                symbol === '$' ? (
                  <InputAdornment position='start'>{symbol}</InputAdornment>
                ) : (
                  ''
                ),
              endAdornment:
                symbol === '%' ? (
                  <InputAdornment position='end'>{symbol}</InputAdornment>
                ) : (
                  ''
                ),
            }}
          />
          {touched.value && errors.value ? (
            value === '' ? (
              <ErrorP>This field is required</ErrorP>
            ) : (
              <ErrorP>{value} is not a valid number.</ErrorP>
            )
          ) : null}
          <TextField
            className={classes.textfield}
            value={internalName}
            label='Internal Name'
            onChange={(e) => {
              setInternalName(e.target.value);

              setTouched((old) => ({ ...old, internalName: true }));
            }}
          />
          {touched.internalName && errors.internalName ? (
            <ErrorP>This field is required</ErrorP>
          ) : null}
          {error ? <ErrorP>{error.message}</ErrorP> : null}
        </DialogContent>
        <DialogActions>
          {error ? <ErrorP>{error.message}</ErrorP> : null}
          <AllButtonsContainer>
            <DeleteRateButton rate={rate} />
            <NonDeleteButtonsContainer>
              <Button onClick={() => onClose()}>Cancel</Button>
              <LoadingButton
                disabled={Object.values(errors).some((value) => value)}
                loading={loading}
                onClick={() => {
                  editInsuranceRate({
                    variables: {
                      token,
                      internalName,
                      displayName,
                      group,
                      rateId: rate.id,
                      symbol,
                      value,
                    },
                  });
                }}
              >
                Submit
              </LoadingButton>
            </NonDeleteButtonsContainer>
          </AllButtonsContainer>
        </DialogActions>
      </Dialog>
    );
  },
);

const AllButtonsContainer = styled('div')(() => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const NonDeleteButtonsContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };
});
const ErrorP = styled('p')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
