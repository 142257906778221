import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  DisplayOptaFusData,
  DisplayOptaFloodscoreData,
  DisplayOptaCommercialPerilData,
  DisplayOptaIclarifyCommercialConstructionData,
} from './Displays';
import { Pagebreak } from '../utils';
import { useLazyQuery } from '@apollo/client';
import { LOCATIONS_FROM_QUOTE_HASH } from '../../../../queries';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { RouteComponentProps } from '@reach/router';

const A4_PAPER_WIDTH = '210mm';
const useDetailsForSingleLocationStyles = makeStyles((_theme: $TSFixMe) => ({
  printingPaper: {
    width: A4_PAPER_WIDTH,
  },
}));

interface OptaDetailsForSingleLocationProps {
  location: $TSFixMe;
}
export const OptaDetailsForSingleLocation = ({
  location,
}: OptaDetailsForSingleLocationProps) => {
  const classes = useDetailsForSingleLocationStyles();

  if (!location || !location.details || !location.details.opta) {
    return null;
  }
  const optaDataTypes = [
    'optaFusData',
    'optaFloodscoreData',
    'optaCommercialPerilScoreData',
    'optaIclarifyCommercialConstructionData',
  ];
  if (optaDataTypes.every((type) => !location.details.opta[type])) {
    return null;
  }
  return (
    <div className={classes.printingPaper}>
      {location.details.opta.optaFusData ? (
        <>
          <DisplayOptaFusData location={location} />
          <Pagebreak />
        </>
      ) : null}
      {location.details.opta.optaFloodscoreData ? (
        <>
          <DisplayOptaFloodscoreData location={location} />
          <Pagebreak />
        </>
      ) : null}
      {location.details.opta.optaCommercialPerilScoreData ? (
        <>
          <DisplayOptaCommercialPerilData location={location} />
          <Pagebreak />
        </>
      ) : null}
      {location.details.opta.optaIclarifyCommercialConstructionData ? (
        <>
          <DisplayOptaIclarifyCommercialConstructionData location={location} />
          <Pagebreak />
        </>
      ) : null}
    </div>
  );
};

interface OptaPdfProps extends RouteComponentProps {
  hash?: string;
}
export const OptaPdf = ({ hash }: OptaPdfProps) => {
  const [locations, setLocations] = useState<Array<$TSFixMe>>([]);
  const [getDetails, { error, data }] = useLazyQuery(LOCATIONS_FROM_QUOTE_HASH);

  useEffect(() => {
    if (hash) {
      getDetails({
        variables: {
          hash,
        },
      });
    }
  }, [hash, getDetails]);

  useEffect(() => {
    if (data?.locationsFromQuoteHash) {
      setLocations(data.locationsFromQuoteHash);
    }
  }, [data]);

  if (error) {
    return null;
  }

  return (
    <div data-present='true'>
      {locations.map((location, index) => (
        <OptaDetailsForSingleLocation key={index} location={location} />
      ))}
    </div>
  );
};
