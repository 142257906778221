import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_VAGO_SUBSCRIBERS } from '../queries';
import { useAuth } from '@calefy-inc/authentication';

export const useVagoOtherSubscribers = () => {
  const [vagoSubscribers, setVagoSubscribers] = useState<Array<string>>([]);
  const [getVagoSubscribers, { data }] = useLazyQuery(GET_VAGO_SUBSCRIBERS);
  const { token } = useAuth();

  // get the subscribers as soon as we can
  useEffect(() => {
    if (token) {
      getVagoSubscribers({
        variables: {
          token,
        },
      });
    }
  }, [token]);

  // once the data is loaded, set the options
  useEffect(() => {
    if (data) {
      setVagoSubscribers(
        // @ts-expect-error
        (data.getVagoSubscribers || [])
          .map((subscriber) => subscriber?.name)
          // @ts-expect-error
          .sort((a, b) => a?.localeCompare(b)),
      );
    }
  }, [data]);

  return vagoSubscribers;
};
