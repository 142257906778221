import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GET_QUOTE,
  GET_QUOTE_PDF,
  GET_QUOTE_VERSION_LIST,
  GET_ARCHIVED_QUOTE,
} from '../../../../queries';
// material
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// utils
import { fCurrency } from '../../utils/formatNumber';
import {
  downloadPdfFile,
  extractLocationDetailsFromAnswerInstance,
} from '../../../common/QuoteLoader/utility';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { QuoteToolbar } from '../../components/_dashboard/quotes/details';
import LoadingScreen from '../../components/LoadingScreen';
import {
  QuoteCollapsibleRow,
  QuoteLocationRow,
} from '../../components/_dashboard/quotes/details';
import { SelectChangeEvent } from '@mui/material/Select';
import { InsurerQuoteComparison } from './InsurerQuoteDetails/InsurerQuoteComparison';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import {
  useSettings as useCompanySettings,
  useAllowByPermission,
  useAllowByAdminPermissions,
  useUsingVagoSettings,
} from '../../../../hooks';
import { useSnackbar } from 'notistack';
import { sortForms } from '../../../common/QuotePdf/components/utility';
import { styled } from '@mui/material';

// error handlers
import {
  InvalidUUIDError,
  MissingQuoteError,
} from '../../../QuoteWizard/QuoteResume/ErrorComponents';
import {
  handleErrors,
  InsufficientPermissionsErrorPage,
} from '../../../common/ErrorHandling';
import Bugsnag from '@bugsnag/js';
import { PremiumOverrides } from './PremiumOverrides';

// classes and types
import { Quote } from '../../../../Typescript/backend/classes';
import type { HandleableError } from '../../../../components/common/ErrorHandling';
import type { QuoteReviewLocationDetails } from './types';
import { generateColourFromQuoteStatus } from './utility';
import { RouteComponentProps } from '@reach/router';
import { QuoteBoundStatus } from './QuoteBoundStatus';

// ----------------------------------------------------------------------
// types

type VersionListType = {
  quoteId: string;
  dateAdded: string;
};

enum TAB_VALUES {
  QUOTE_DETAILS = 'QUOTE DETAILS',
  PREMIUM_OVERRIDES = 'PREMIUM OVERRIDES',
  AI = 'AI',
}

// ----------------------------------------------------------------------
// utility

/**
 * Component-specific error handling
 * @param error - the error to process
 * @return - Either return a valid react element or nothing (to have control pass back to the handler function)
 */
const baseHandleSpecificErrors = (error: HandleableError, uuid: string) => {
  if (/invalid permissions/i.test(error.message)) {
    // this will probably never fire, but is here for safety
    return (
      <InsufficientPermissionsErrorPage deniedIdentifier='Quote Details page' />
    );
  }
  if (error.message.match(/is not a valid uuid/i)) {
    return <InvalidUUIDError uuid={uuid} />;
  }
  if (error.message.match(/quote matching query does not exist/i)) {
    return <MissingQuoteError uuid={uuid} />;
  }
};

// ----------------------------------------------------------------------
// component

/**
 * Dispays the details of a quote and provides the ability to edit a quote
 * or download a copy of the quotes pdf
 *
 * @param {string} uniqueID: the unique id of the quote we want to display
 */
interface QuoteDetailsProps extends RouteComponentProps {
  uniqueID?: string;
}
export default function QuoteDetails({ uniqueID }: QuoteDetailsProps) {
  const { logo } = useCompanySettings();
  const { token } = useAuth();
  const hasViewQuoteDetailsPermission =
    useAllowByPermission('view:quote:details');
  const { enqueueSnackbar } = useSnackbar();

  // ----------------------------------------------------------------------
  // state

  const [quote, setQuote] = useState<Quote | null>(null);
  const [versionList, setVersionList] = useState<VersionListType | []>([]);
  const [selectedVersion, setSelectedVersion] = useState<string>('0');
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [previousArchivedID, setPreviousArchivedID] = useState<
    string | null | undefined
  >(null);
  const [status, setStatus] = useState<Quote['status'] | null>(null); // clean up the quote status for display
  const [locationData, setLocationData] = useState<
    Record<
      QuoteReviewLocationDetails['general']['id'],
      QuoteReviewLocationDetails
    >
  >({});
  const [renewalOnly, setRenewalOnly] = useState<boolean>(false);
  const displayAiQuoteComparison = useDisplayQuoteComparison();
  const displayPremiumOverrides = useDisplayPremiumOverrides();
  const [tabsToDisplay, setTabsToDisplay] = useState<Array<TAB_VALUES>>([
    TAB_VALUES.QUOTE_DETAILS,
  ]);
  const [tabValue, setTabValue] = useState<number>(0);

  // ----------------------------------------------------------------------
  // queries

  // load current quote
  const [loadQuote, { called, loading, error, data }] = useLazyQuery(
    GET_QUOTE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  // load version list
  const [
    loadQuoteVersionList,
    { data: versionListData, error: versionListError },
  ] = useLazyQuery(GET_QUOTE_VERSION_LIST);

  // load archived quote
  const [
    loadArchivedQuote,
    {
      data: archivedQuoteData,
      loading: archivedQuoteLoading,
      error: archivedQuoteError,
    },
  ] = useLazyQuery(GET_ARCHIVED_QUOTE);

  // load pdf query
  const [
    loadQuotePdf,
    { data: pdfData, loading: pdfLoading, error: pdfError },
  ] = useLazyQuery(GET_QUOTE_PDF, {
    fetchPolicy: 'no-cache',
  });

  // ----------------------------------------------------------------------
  // state managers

  // update the tabs to display as we get the information
  useEffect(() => {
    if (displayPremiumOverrides) {
      // we want this to be at index 1
      setTabsToDisplay((oldTabsToDisplay) => {
        const newTabs = oldTabsToDisplay.filter(
          (tab) => tab !== TAB_VALUES.PREMIUM_OVERRIDES,
        );
        newTabs.splice(1, 0, TAB_VALUES.PREMIUM_OVERRIDES);
        return newTabs;
      });
    } else {
      setTabsToDisplay((oldTabsToDisplay) =>
        oldTabsToDisplay.filter((tab) => tab !== TAB_VALUES.PREMIUM_OVERRIDES),
      );
    }
  }, [displayPremiumOverrides]);
  useEffect(() => {
    // this one should be last
    if (displayAiQuoteComparison) {
      setTabsToDisplay((oldTabsToDisplay) => [
        ...oldTabsToDisplay,
        TAB_VALUES.AI,
      ]);
    } else {
      setTabsToDisplay((oldTabsToDisplay) =>
        oldTabsToDisplay.filter((tab) => tab !== TAB_VALUES.AI),
      );
    }
  }, [displayAiQuoteComparison]);

  // when the page is loaded, fetch the quote
  useEffect(() => {
    if (token && hasViewQuoteDetailsPermission && uniqueID) {
      loadQuote({ variables: { uID: uniqueID, token } });
    }
  }, [uniqueID, token, loadQuote, hasViewQuoteDetailsPermission]);

  // when quote is successfully loaded, add it to state and get the version list
  useEffect(() => {
    if (data && data.quote) {
      setQuote(Quote.generateFromBackendResponse(data.quote));
      //@ts-expect-error
      loadQuoteVersionList({ variables: { token, uniqueID }, skip: !token });
    }
  }, [data, token, loadQuoteVersionList, uniqueID]);

  // when version list is loaded, add it to state if any exist
  useEffect(() => {
    // @ts-expect-error
    if (versionListData?.quoteVersions?.length > 0) {
      // @ts-expect-error
      setVersionList(versionListData?.quoteVersions);
    }
  }, [versionListData]);

  // when the selected version changes, fetch data for the selected version
  useEffect(() => {
    if (selectedVersion !== '0') {
      if (selectedVersion === previousArchivedID) {
        setLocationData({});
        setQuote(
          Quote.generateFromBackendResponse(archivedQuoteData?.archivedQuote),
        );
        setIsArchived(true);
      } else {
        setPreviousArchivedID(null);
        loadArchivedQuote({ variables: { token, archiveID: selectedVersion } });
      }
    } else {
      setPreviousArchivedID(quote?.id);
      if (data?.quote) {
        setLocationData({});
        setQuote(Quote.generateFromBackendResponse(data.quote));
        setIsArchived(false);
      }
    }
  }, [
    selectedVersion,
    archivedQuoteData?.archivedQuote,
    data?.quote,
    loadArchivedQuote,
    previousArchivedID,
    quote?.id,
    token,
  ]);

  // when archive quote is loaded, set it as the quote to display
  useEffect(() => {
    if (archivedQuoteData?.archivedQuote) {
      setLocationData({});
      setQuote(
        Quote.generateFromBackendResponse(archivedQuoteData?.archivedQuote),
      );
      setIsArchived(true);
    }
  }, [archivedQuoteData]);

  // when archived quote query has any error notify bugsnag
  useEffect(() => {
    if (archivedQuoteError) {
      const msg = 'Error getting archived version';
      enqueueSnackbar(msg, { variant: 'error' });
      Bugsnag.notify(`${msg}: ${archivedQuoteError}`);
    }
  }, [archivedQuoteError, enqueueSnackbar]);

  // when version list has an error notify bugsnag
  useEffect(() => {
    if (versionListError) {
      const msg = 'Error getting version list';
      enqueueSnackbar(msg, { variant: 'error' });
      Bugsnag.notify(`${msg}: ${versionListError}`);
    }
  }, [versionListError, enqueueSnackbar]);

  // when pdf is loaded, download pdf and display completion message
  useEffect(() => {
    if (pdfData && pdfData.quotePdf?.pdfString) {
      const quotePdf = pdfData.quotePdf;
      downloadPdfFile(
        // @ts-expect-error
        quotePdf.pdfString,
        `${quotePdf.businessName || 'New Application'}.pdf`,
      );
      enqueueSnackbar('Download Complete', { variant: 'success' });
    }
  }, [pdfData, enqueueSnackbar]);

  // when there is an error getting the pdf, display error message and notify bugsnag
  useEffect(() => {
    if (pdfError) {
      enqueueSnackbar('Download Failed', { variant: 'error' });
      Bugsnag.notify(`Pdf download failed: ${pdfError}`);
    } else if (
      pdfData &&
      (pdfData?.quotePdf === null || pdfData?.quotePdf?.pdfString === null)
    ) {
      enqueueSnackbar('Download Failed - Unable to Create PDF', {
        variant: 'error',
      });
      Bugsnag.notify(`Pdf download failed: ${pdfData}`);
    }
  }, [pdfError, pdfData, enqueueSnackbar]);

  // when the quote is loaded, extract the relevant location answers
  useEffect(() => {
    if (!quote) {
      return;
    }
    const allLocations = quote.allUniqueAnsweredLocationAnswers();
    const locationsWithBasicDetails = allLocations.map(
      extractLocationDetailsFromAnswerInstance,
    );
    setLocationData((oldLocationData) => {
      return Object.assign(
        {},
        oldLocationData,
        locationsWithBasicDetails.reduce(
          (
            acc: Record<
              QuoteReviewLocationDetails['general']['id'],
              QuoteReviewLocationDetails
            >,
            location,
          ) => {
            acc[location.general.id] = location;
            return acc;
          },
          {},
        ),
      );
    });
    setStatus(quote.status);
  }, [quote]);

  // ----------------------------------------------------------------------
  // status actions

  if (!uniqueID) {
    return null;
  }

  // ----------------------------------------------------------------------
  // handlers

  const handlePdfDownload = (downloadType?: string) => {
    if (token) {
      loadQuotePdf({
        variables: {
          token,
          uniqueID,
          archiveID: selectedVersion !== '0' ? selectedVersion : null,
          renewalOnly: renewalOnly,
          downloadType,
        },
      });
    }
  };

  const handleVersionChange = (event: SelectChangeEvent) => {
    setSelectedVersion(event?.target.value);
  };

  const handleRenewalOnlyChange = () => {
    setRenewalOnly(!renewalOnly);
  };

  const handleTabValueChange = (
    _event: React.SyntheticEvent,
    newTabValue: number,
  ) => {
    setTabValue(newTabValue);
  };

  return (
    <Page title='Quote: View Details | Manager'>
      <Container maxWidth={'lg'} data-testid='quote-details'>
        {hasViewQuoteDetailsPermission === false ? (
          <InsufficientPermissionsErrorPage deniedIdentifier='Quote Details page' />
        ) : loading || !called || archivedQuoteLoading ? (
          <LoadingScreen />
        ) : error ? (
          handleErrors(
            error,
            (
              (uuid: string) => (error: HandleableError) =>
                baseHandleSpecificErrors(error, uuid)
            )(uniqueID),
          )
        ) : !uniqueID || !quote ? null : (
          <>
            <HeaderBreadcrumbs
              heading='Quote Details'
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
                { name: 'Quotes', href: PATH_DASHBOARD.quotes.root },
                { name: 'Quote' },
              ]}
            />
            <QuoteToolbar
              quote={quote}
              setStatus={setStatus}
              onDownload={handlePdfDownload}
              pdfStatus={pdfLoading}
              selectedVersion={selectedVersion}
              versionList={versionList}
              handleVersionChange={handleVersionChange}
              isArchived={isArchived}
              renewalOnly={renewalOnly}
              handleRenewalOnlyChange={handleRenewalOnlyChange}
            />
            <Card sx={{ pt: 5, px: 5 }}>
              <Tabs
                onChange={handleTabValueChange}
                value={tabValue}
                sx={{ mb: 3 }}
              >
                <Tab label='Application Details' />
                {displayPremiumOverrides ? (
                  <Tab label='Premium Overrides' />
                ) : null}
                {displayAiQuoteComparison ? (
                  <Tab label='Insurer Quotes' />
                ) : null}
              </Tabs>
              <TabPanel
                tabValue={tabValue}
                index={tabsToDisplay.indexOf(TAB_VALUES.QUOTE_DETAILS)}
              >
                <Grid container>
                  <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                    <Box
                      component='img'
                      alt='logo'
                      src={logo}
                      sx={{ height: 48 }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                    <Box sx={{ textAlign: { sm: 'right' } }}>
                      <Label
                        color={generateColourFromQuoteStatus(status)}
                        sx={{ textTransform: 'uppercase', mb: 1 }}
                        data-testId='quoteStatusChip'
                      >
                        {status && status.replace(/_/g, ' ')}
                      </Label>
                      <Typography variant='h6'>
                        Quote - {quote.getDisplayId()}
                      </Typography>
                    </Box>
                  </Grid>

                  <QuoteMiscellanyContainer>
                    <QuoteStructuredDataContainer>
                      <Typography
                        paragraph
                        variant='overline'
                        sx={{ color: 'text.disabled' }}
                      >
                        Quote from
                      </Typography>
                      {[
                        quote.structuredData.contactName,
                        quote.structuredData.businessName,
                        quote.structuredData?.contactAddress?.formattedAddress,
                        quote.structuredData.contactEmail,
                        quote.structuredData.contactPhoneNumber,
                      ]
                        .filter((s) => !!s)
                        .map((info) => (
                          <Typography variant='body2'>{info}</Typography>
                        ))}
                      {quote.producerId ? (
                        <Typography variant='body2'>
                          Producer ID: {quote.producerId}
                        </Typography>
                      ) : null}

                      {quote.structuredData.revenue === undefined ||
                      quote.structuredData.revenue === null ? null : (
                        <Typography variant='body2'>
                          Revenue: {fCurrency(quote.structuredData.revenue)}
                        </Typography>
                      )}
                      <Typography variant='body2'>
                        Line: {quote.businessLine.displayName}
                      </Typography>
                      {quote.structuredData.alreadyContactedBrokerName ? (
                        <Typography variant='body2'>
                          <span style={{ fontWeight: 'bold' }}>Broker: </span>
                          {quote.structuredData.alreadyContactedBrokerName}
                        </Typography>
                      ) : null}
                    </QuoteStructuredDataContainer>
                    <QuoteBoundStatusContainer>
                      <QuoteBoundStatus quote={quote} />
                    </QuoteBoundStatusContainer>
                  </QuoteMiscellanyContainer>
                </Grid>

                <Scrollbar>
                  <TableContainer>
                    <Table>
                      <TableHead
                        sx={{
                          borderBottom: (theme) =>
                            `solid 1px ${theme.palette.divider}`,
                          '& th': { backgroundColor: 'transparent' },
                        }}
                      >
                        <TableRow>
                          <TableCell align='left'>Forms</TableCell>
                          <TableCell width={40} />
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {sortForms(quote.completedForms).map((form, index) => (
                          <QuoteCollapsibleRow
                            form={form}
                            index={index}
                            quoteStatus={quote.status}
                            renewalOnly={renewalOnly}
                          />
                        ))}
                      </TableBody>
                    </Table>

                    {Object.keys(locationData).length > 0 && (
                      <Table>
                        <TableHead
                          sx={{
                            borderBottom: (theme) =>
                              `solid 1px ${theme.palette.divider}`,
                            '& th': { backgroundColor: 'transparent' },
                          }}
                        >
                          <TableRow>
                            <TableCell align='left'>Locations</TableCell>
                            <TableCell width={40} />
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {Object.values(locationData).map(
                            (location, index) => (
                              <QuoteLocationRow
                                location={location}
                                index={index}
                                setLocationData={setLocationData}
                                loadQuotePdf={handlePdfDownload}
                                isArchived={isArchived}
                              />
                            ),
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Scrollbar>

                {quote.additionalInformation ? (
                  <>
                    <Box sx={{ my: 3 }}>
                      <Typography variant='subtitle2' sx={{ mb: 1 }}>
                        Additional information or comments
                      </Typography>
                      <Typography variant='body2'>
                        {quote.additionalInformation}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                ) : null}

                <Grid container>
                  <Grid item xs={12} md={9} sx={{ py: 3 }}></Grid>
                  <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
                    <Typography variant='subtitle2'>
                      Have a Question?
                    </Typography>
                    <a href='mailto:support@calefy.ca'>
                      <Typography variant='body2'>support@calefy.ca</Typography>
                    </a>
                  </Grid>
                </Grid>
              </TabPanel>
              {displayPremiumOverrides ? (
                <TabPanel
                  tabValue={tabValue}
                  index={tabsToDisplay.indexOf(TAB_VALUES.PREMIUM_OVERRIDES)}
                >
                  <PremiumOverrides quote={quote} />
                </TabPanel>
              ) : null}
              {displayAiQuoteComparison ? (
                <TabPanel
                  tabValue={tabValue}
                  index={tabsToDisplay.indexOf(TAB_VALUES.AI)}
                >
                  <InsurerQuoteComparison uniqueID={uniqueID} />
                </TabPanel>
              ) : null}
            </Card>
          </>
        )}
      </Container>
    </Page>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabValue: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={tabValue !== index} id={`${index}`} {...other}>
      {children}
    </div>
  );
}

/**
 * Determines whether we should display the premium overrides. This is probably overkill for now (since it's only VaGo, but it should be relatively easy to update. If we get a bunch of these we should probably just move it to be part of website settings)
 */
const useDisplayPremiumOverrides = () => {
  const onVago = useUsingVagoSettings();

  if ([onVago].some((onPremiumOverrideOrg) => !!onPremiumOverrideOrg)) {
    return true;
  }
  return false;
};

/**
 * Determines whether we should display the AI quote comparison. This is probably overkill for now. If we get a bunch of these we should probably just move it to be part of website settings)
 */
export const useDisplayQuoteComparison = () => {
  const hasAdminPermissions = useAllowByAdminPermissions();
  return hasAdminPermissions;
};

const QuoteMiscellanyContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});
const QuoteStructuredDataContainer = styled('div')();
const QuoteBoundStatusContainer = styled('div')({
  flexGrow: 1,
});
