// @ts-nocheck
import { useState, useEffect } from 'react';
import { useField } from 'informed';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

export const VALUE_SEPARATOR = '|';

interface InformedObligateInputToggleMultipleProps {
  field: $TSFixMe;
  label?: $TSFixMe;
  onChange?: $TSFixMe;
  onBlur?: $TSFixMe;
  className?: $TSFixMe;
  required?: $TSFixMe;
  helperText?: $TSFixMe;
  options: Array<{ value: $TSFixMe; label: string; [k: string]: $TSFixMe }>;
  validateOnChange?: $TSFixMe;
  exclusive?: boolean;
  numColumns?: number; // number of columns; can be undefined or 0 to just have it wrap normally
  [k: string]: $TSFixMe;
}
/**
 * @param options - the options to be displayed
 */
export const InformedMaterialObligateInputToggleMultiple = (
  actualProps: InformedObligateInputToggleMultipleProps,
) => {
  const {
    onChange,
    onBlur,
    field,
    className,
    label,
    required,
    helperText,
    options = [],
    // eslint-disable-next-line no-unused-vars
    validateOnChange = 'true',
    numColumns = 0,
    ...props
  } = actualProps;

  const [internalValue, setInternalValue] = useState<Array<string>>([]);
  // Returns object with validate if required is true

  const { fieldState, fieldApi, render } = useField({ ...actualProps });

  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;

  // change the internal value when the external one changes and vice versa
  useEffect(() => {
    if (value && typeof value === 'string') {
      setInternalValue(value.split(VALUE_SEPARATOR));
    }
  }, [value]);
  useEffect(() => {
    const actualValue = internalValue.join(VALUE_SEPARATOR);
    setValue(actualValue);
  }, [internalValue]);


  return render(
    <>
      <FormControl
        className={className}
        error={!!fieldState.error}
        disabled={props.isDisabled}
        variant='standard'
      >
        <Grid container direction='column' spacing={1}>
          <Grid item key='label'>
            <FormLabel htmlFor={field}>{label}</FormLabel>
          </Grid>
          <Grid item key='buttons' sx={{ flexWrap: 'wrap' }}>
            <ToggleButtonGroup
              size='medium'
              value={internalValue}
              color='primary'
              exclusive={false}
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setInternalValue(newValue);
                  onChange && onChange(e);
                }
              }}
              onBlur={(e) => {
                setTouched(true);
                onBlur && onBlur(e);
              }}
              sx={{
                width: '100%',
                '& .MuiToggleButtonGroup-grouped': {
                  lineHeight: 1,
                },
                ...(numColumns > 0
                  ? {
                      display: 'grid',
                      gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
                    }
                  : { flexWrap: 'wrap' }),
                ...(numColumns > 0 && options.length > numColumns // i.e. styles to apply when there are multiple rows
                  ? {
                      [`&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:nth-of-type(${numColumns}n + 1)`]:
                        {
                          borderBottomLeftRadius: 8,
                          borderTopLeftRadius: 8,
                        },
                      [`&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:nth-of-type(${numColumns}n)`]:
                        {
                          borderBottomRightRadius: 8,
                          borderTopRightRadius: 8,
                        },
                      [`&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:last-of-type`]:
                        {
                          borderBottomRightRadius: 8,
                          borderTopRightRadius: 8,
                        },
                    }
                  : {}),
                color: 'primary.main',
                '& .MuiToggleButton-root': {
                  '&.Mui-selected': {
                    color: 'primary.main.contrastText',
                    bgcolor: 'primary.main',
                  },
                  '&.Mui-selected:hover': {
                    color: 'primary.dark.contrastText',
                    bgcolor: 'primary.dark',
                  },
                },
                '&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)':
                  {
                    borderLeftColor: 'rgba(145, 158, 171, 0.32)',
                  },
                minHeight: { sm: 50, md: 25 },
              }}
              {...props}
            >
              {options.map((option) => {
                return (
                  <ToggleButton
                    key={option.value}
                    value={option.value}
                    role='button'
                    aria-label={option.label}
                    sx={{
                      px: 2,
                      py: 1,
                      margin: 0,
                      // maxWidth: '33%',
                    }}
                  >
                    {option.label}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
          {fieldState.error && (
            <FormHelperText>{fieldState.error}</FormHelperText>
          )}
        </Grid>
      </FormControl>
    </>,
  );
};
