import { InputToggle } from '@calefy-inc/informedMaterial';
import { QuestionAnswerLocation } from '../../../../common/QuestionAnswerDisplay/QuestionAnswerDisplay';

/**
 * Component to select where the answer should be hidden
 */
export const HideInLocations = () => {
  const options: Array<{ label: string; value: QuestionAnswerLocation | '' }> =
    [
      { value: '', label: 'None' },
      { value: 'quotePdf', label: 'Quote PDF' },
    ];
  return (
    <InputToggle
      field='props_blob_hideInLocations'
      label='Hide In Locations'
      exclusive={false}
      options={options}
    />
  );
};
