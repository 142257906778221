import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
// material
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import { useAllowByPermission } from '../../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
// components
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {
  QuoteListHead,
  QuoteListToolbar,
} from '../../../components/_dashboard/quotes/list';
import LoadingScreen from '../../../components/LoadingScreen';
import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';
// utils
import { findLegalOrTradeNameFromStructuredData } from '../../../utils/AnswerSearch';
import { downloadPdfFile } from '../../../../common/QuoteLoader/utility';
import { generateColourFromQuoteStatus } from '../utility';
import { format } from 'date-fns';
// queries
import {
  RELAY_QUOTES,
  GET_QUOTE_PDF,
  GET_ALL_QUOTES,
} from '../../../../../queries';
// error handler
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../../Page500';
import { useSnackbar } from 'notistack';
import { useUsingVagoSettings } from '../../../../../hooks';
import { VagoFileDownloadMenuButton } from '../../../../OrganizationSpecific/Vago';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'dateAdded', label: 'Date', alignRight: false },
  { id: 'company', label: 'Client', alignRight: false },
  { id: 'business', label: 'Line', alignRight: false },
  { id: 'uniqueID', label: 'Unique ID', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a: $TSFixMe, b: $TSFixMe, orderBy: $TSFixMe) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy: $TSFixMe) {
  return order === 'desc'
    ? (a: $TSFixMe, b: $TSFixMe) => descendingComparator(a, b, orderBy)
    : (a: $TSFixMe, b: $TSFixMe) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: Array<$TSFixMe>, comparator: $TSFixMe) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function BasicQuoteList() {
  const theme = useTheme();
  const { token } = useAuth();
  const hasCorrectPermissions = useAllowByPermission('view:quotes:table');
  const { enqueueSnackbar } = useSnackbar();
  const [quoteList, setQuoteList] = useState<Array<$TSFixMe>>([]);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<$TSFixMe>('dateAdded');
  const [filterName, setFilterName] = useState<string>(''); // the string to search for
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [endCursor, setEndcursor] = useState<$TSFixMe>();
  const [hasNextPage, setHasNextPage] = useState<$TSFixMe>();
  const [allFetchedQuotes, setAllFetchedQuotes] = useState<Array<$TSFixMe>>([]);
  const [timeOut, setTimeOut] = useState<$TSFixMe>();
  const [hiddenQueryLoading, setHiddenQueryLoading] = useState<$TSFixMe>();
  const [generatingPdf, setGeneratingPdf] = useState<$TSFixMe>();
  const [showActionId, setShowActionId] = useState<$TSFixMe>(false);
  const onVago = useUsingVagoSettings();

  // Relay quotes query
  const [getQuotes, { loading, data, error }] = useLazyQuery(RELAY_QUOTES);

  // Search db query
  const [
    getSearchedQuotes,
    { data: searchedData, loading: searchedLoading, error: searchedError },
  ] = useLazyQuery(GET_ALL_QUOTES);

  // Load pdf query
  const [
    loadQuotePdf,
    { data: pdfData, loading: pdfLoading, error: pdfError },
  ] = useLazyQuery(GET_QUOTE_PDF);

  useEffect(() => {
    if (token && hasCorrectPermissions) {
      getQuotes({
        variables: {
          token: token,
        },
      });
    }
  }, [token, hasCorrectPermissions, getQuotes]);

  useEffect(() => {
    clearTimeout(timeOut);
    if (filterName) {
      setTimeOut(
        setTimeout(() => {
          searchQuery(filterName);
        }, 500),
      );
    }

    if (!filterName) {
      setQuoteList(allFetchedQuotes);
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName]);

  useEffect(() => {
    if (pdfData && pdfData.quotePdf?.pdfString) {
      const quotePdf = pdfData.quotePdf;
      downloadPdfFile(
        // @ts-expect-error
        quotePdf.pdfString,
        `${quotePdf.businessName || 'New Application'}.pdf`,
      );
      enqueueSnackbar('Download Complete', { variant: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfData]);

  useEffect(() => {
    if (pdfError) {
      enqueueSnackbar('Download Failed', { variant: 'error' });
      Bugsnag.notify(
        `Pdf download failed: ${JSON.stringify(pdfError, null, 4)}`,
      );
    } else if (pdfData && !pdfData.quotePdf?.pdfString) {
      enqueueSnackbar('Download Failed - Unable to Generate PDF', {
        variant: 'error',
      });
      Bugsnag.notify(
        `Pdf download failed: ${JSON.stringify(pdfData, null, 4)}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfError, pdfData]);

  useEffect(() => {
    if (searchedData) {
      // @ts-expect-error
      const quotesWithCompanyName = searchedData.allQuotes.map(
        (quote: $TSFixMe) => ({
          ...quote,
          business: quote.businessLine.displayName,
          company:
            quote.businessLine.displayName === 'Personal'
              ? quote.structuredData.companyName
              : findLegalOrTradeNameFromStructuredData(
                  quote.structuredData,
                  quote.businessLine.displayName,
                ),
        }),
      );
      setQuoteList(quotesWithCompanyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedData]);

  useEffect(() => {
    if (
      hasNextPage &&
      rowsPerPage * (page + 2) >= quoteList.length &&
      !searchedData
    ) {
      getQuotes({
        variables: {
          first: 100,
          cursor: endCursor,
          token,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteList, rowsPerPage, page]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      const quotesWithCompanyName = data.relayQuotes.edges.map(
        (quote: $TSFixMe) => ({
          ...quote.node,
          business: quote.node.businessLine.displayName,
          company: findLegalOrTradeNameFromStructuredData(
            quote.node.structuredData,
            quote.node.businessLine.displayName,
          ),
        }),
      );

      setQuoteList([...quoteList, ...quotesWithCompanyName]);
      setAllFetchedQuotes([...quoteList, ...quotesWithCompanyName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      setEndcursor(data.relayQuotes.pageInfo.endCursor);
      // @ts-expect-error
      setHasNextPage(data.relayQuotes.pageInfo.hasNextPage);
    }
  }, [data]);

  useEffect(() => {
    const anyLoading = loading || searchedLoading;
    if (anyLoading && quoteList.length > 0) {
      setHiddenQueryLoading(true);
    } else {
      setHiddenQueryLoading(false);
    }

    if (pdfLoading) {
      setGeneratingPdf(true);
    } else {
      setGeneratingPdf(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchedLoading, pdfLoading]);

  // Check current status of queries
  if ((loading && quoteList.length === 0) || !token) {
    return <LoadingScreen />;
  }

  if (error || pdfError || searchedError) {
    const anyError = error || pdfError || searchedError;
    console.error('Error:', anyError);
    // @ts-expect-error
    Bugsnag.notify(anyError);
    return <ErrorPage />;
  }

  const handleChangePage = (_event: $TSFixMe, newPage: $TSFixMe) => {
    setPage(newPage);
  };

  const handleRequestSort = (_event: $TSFixMe, property: $TSFixMe) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: $TSFixMe) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: $TSFixMe) => {
    setFilterName(event.target.value);
  };

  const searchQuery = (searchState: $TSFixMe) => {
    if (searchState) {
      getSearchedQuotes({
        variables: { token, search: searchState },
      });

      setPage(0);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quoteList.length) : 0;

  const filteredQuotes = applySortFilter(
    quoteList,
    getComparator(order, orderBy),
  );

  const isQuoteNotFound = filteredQuotes.length === 0;

  return (
    <>
      {!!token && hasCorrectPermissions === false ? (
        <InsufficientPermissionsErrorPage deniedIdentifier='Quotes Table' />
      ) : (
        <>
          <HeaderBreadcrumbs
            heading='Application List'
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
              { name: 'Quotes' },
            ]}
            action={
              <Button
                variant='contained'
                component={RouterLink}
                to={'/insurtech/manager/quotes/create'}
                startIcon={<Icon icon={plusFill} />}
              >
                New Application
              </Button>
            }
          />
          <Card>
            <QuoteListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              isLoading={hiddenQueryLoading}
              generatingPdf={generatingPdf}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, minHeight: 400 }}>
                <Table>
                  <QuoteListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={quoteList.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredQuotes
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => {
                        const {
                          id,
                          uniqueID,
                          friendlyId,
                          status,
                          dateAdded,
                          company,
                          business,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            onMouseEnter={() => {
                              setShowActionId(row.id);
                            }}
                            onMouseLeave={() => setShowActionId(-1)}
                          >
                            {[
                              format(new Date(dateAdded), 'MMMM dd yyyy, p'),
                              company,
                              business,
                              friendlyId || uniqueID,
                              <Label
                                variant={
                                  theme.palette.mode === 'light'
                                    ? 'ghost'
                                    : 'filled'
                                }
                                color={generateColourFromQuoteStatus(status)}
                              >
                                {sentenceCase(status)}
                              </Label>,
                            ].map((elem: $TSFixMe) =>
                              // @ts-expect-error
                              // prettier-ignore
                              <TableCell component={RouterLink}
                                align='left'
                                to={`/insurtech/manager/quotes/details/${uniqueID}`}
                                sx={{ textDecoration: 'none' }}
                              >
                                {elem}
                              </TableCell>,
                            )}

                            <TableCell align='right'>
                              {row.id === showActionId ? (
                                onVago ? (
                                  <VagoFileDownloadMenuButton
                                    quote={{ ...row, uniqueId: uniqueID }}
                                  />
                                ) : (
                                  <IconButton
                                    sx={{ color: 'text.secondary' }}
                                    onClick={() => {
                                      if (token) {
                                        loadQuotePdf({
                                          variables: {
                                            token,
                                            uniqueID,
                                          },
                                        });
                                      }
                                    }}
                                  >
                                    <DownloadRoundedIcon />
                                  </IconButton>
                                )
                              ) : (
                                <IconButton sx={{ visibility: 'hidden' }}>
                                  <DownloadRoundedIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isQuoteNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={5} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={quoteList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </>
      )}
    </>
  );
}
