import { FinalFormModifier } from '../../../../../classes';
import { ValidationFunction } from '../types';
import { formatAsReadableList } from '../../../../../../../../../common/QuoteComponents/utility';

/**
 * Validate that a FormModifier doesn't have a question added / modifier and then deleted with the same API name
 */
export const validateFormModifierNoDeletedAndAddedQuestions: ValidationFunction =
  (form: FinalFormModifier) => {
    const apiNamesToAddAndDelete = form.questionInstances.reduce(
      (acc: Array<string>, question) => {
        if (form.apiNamesForRemoval.includes(question.apiName)) {
          return [...acc, question.apiName];
        }
        return acc;
      },
      [],
    );
    return apiNamesToAddAndDelete.length === 0
      ? []
      : [
          `There ${
            apiNamesToAddAndDelete.length === 1 ? 'is a' : 'are some'
          } question which are slated to be both added / modifier and deleted. The API ${
            apiNamesToAddAndDelete.length === 1 ? 'name is' : 'names are'
          } ${formatAsReadableList(
            apiNamesToAddAndDelete,
          )}. Please choose to either add / modify or remove ${
            apiNamesToAddAndDelete.length === 1 ? 'it' : 'them'
          }.`,
        ];
  };
