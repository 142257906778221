import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProgramBuilderQuestionInstance } from '../components/FormManager/classes';

// types and classes
import { FinalFormModifier } from '../components/ManagementPanel/pages/dashboard/FormModifiers/classes/FinalFormModifier';
import { Language } from '../Typescript/classes';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { recursiveSearchAndCopy } from './utility';

interface FormModifierState {
  currentlyEditingFinalFormModifier: FinalFormModifier | null;
  business?: FinalFormModifier['business']; // undefined -> no information
  policy?: FinalFormModifier['policy']; // undefined -> no information (different from null: General Information)
  languages: Array<Language>;
  pendingQuestion:
    | ProgramBuilderQuestionInstance
    | { node: $TSFixMe; path: $TSFixMe }
    | null;
}
export const defaultInitialState: FormModifierState = {
  currentlyEditingFinalFormModifier: null,
  languages: [],
  pendingQuestion: null,
};

const slice = createSlice({
  name: 'formModifier',
  initialState: defaultInitialState,
  reducers: {
    setBusiness: (
      state,
      action: PayloadAction<FormModifierState['business']>,
    ) => {
      const { payload } = action;
      state.business = payload;
    },
    setPolicy: (state, action: PayloadAction<FormModifierState['policy']>) => {
      const { payload } = action;
      state.policy = payload;
    },
    setCurrentlyEditingFinalFormModifier: (
      state,
      action: PayloadAction<
        FormModifierState['currentlyEditingFinalFormModifier']
      >,
    ) => {
      const { payload } = action;
      state.currentlyEditingFinalFormModifier = payload;
    },
    setLanguages: (state, action: PayloadAction<Array<Language>>) => {
      const { payload } = action;
      state.languages = payload;
    },
    updateTreeStructure: (state, action: PayloadAction<FinalFormModifier>) => {
      const form = action.payload;
      const recursiveMap = (
        questions: Array<ProgramBuilderQuestionInstance>,
        parent: null | ProgramBuilderQuestionInstance = null,
      ): Array<ProgramBuilderQuestionInstance> => {
        return questions.map((ele) => {
          if (
            ele.children &&
            (ele.children.length !== 0 || ele.children.length === 0)
          ) {
            return new ProgramBuilderQuestionInstance({
              ...ele,
              subQuestions: recursiveMap(ele.children, ele),
            });
          } else {
            const copy = ele.copy();
            return parent ? copy : copy;
          }
        });
      };
      let withSubQuestionsSet = recursiveMap(form.questionInstances);

      if (!form) {
        throw new Error('No form provided');
      }
      state.currentlyEditingFinalFormModifier = form.copyWithAmendments({
        questionInstances: withSubQuestionsSet,
      });
    },
    /**
     * Updates a question in the form by replacing it with the one in the payload.
     */
    updateQuestionInstance: (
      state,
      action: PayloadAction<{
        node: $TSFixMe;
        path: $TSFixMe;
        questionInstance: ProgramBuilderQuestionInstance;
        topLevelQuestion: ProgramBuilderQuestionInstance;
      }>,
    ) => {
      const { questionInstance, topLevelQuestion } = action.payload;

      const replaceQuestionsInList = (
        questionsList: Array<ProgramBuilderQuestionInstance>,
        topLevelQuestion: ProgramBuilderQuestionInstance | null,
        questionInstance: ProgramBuilderQuestionInstance | null,
      ): Array<ProgramBuilderQuestionInstance> => {
        return questionsList.map((originalQuestion) => {
          let mappedQuestion: ProgramBuilderQuestionInstance;
          if (
            questionInstance &&
            questionInstance.matchById(originalQuestion)
          ) {
            mappedQuestion = questionInstance;
          } else if (
            topLevelQuestion &&
            topLevelQuestion.matchById(originalQuestion)
          ) {
            mappedQuestion = originalQuestion.copy();
          } else {
            mappedQuestion = originalQuestion.copy();
          }
          mappedQuestion.subQuestions = replaceQuestionsInList(
            mappedQuestion.subQuestions,
            topLevelQuestion,
            questionInstance,
          );
          return mappedQuestion;
        });
      };

      if (!state.currentlyEditingFinalFormModifier) {
        throw new Error('Trying to edit form, but no form existst in state!');
      }
      const newForm =
        state.currentlyEditingFinalFormModifier.copyWithAmendments({
          questionInstances: replaceQuestionsInList(
            state.currentlyEditingFinalFormModifier.questionInstances,
            topLevelQuestion,
            questionInstance,
          ),
        });
      state.currentlyEditingFinalFormModifier = newForm;
      // const newForm = state.currentlyEditingFinalFormModifier.copy();
      // newForm.questionInstances =
      // state.forms[matchingIndex] = newForm;
    },
    /**
     * Creates a copy of the passed in QuestionInstance and inserts it into the form modifier's list of question instances after the passed in QuestionInstance
     * @param  state - state
     * @param  action - Should have payload {questionInstance: QuestionInstance, parentForm: Form}
     */
    copyQuestionInstance: (
      state,
      action: PayloadAction<{
        questionInstance: ProgramBuilderQuestionInstance;
        parentForm: FinalFormModifier;
      }>,
    ) => {
      let { questionInstance, parentForm } = action.payload;

      if (!parentForm) {
        throw new Error(
          `Error when calling copyQuestionInstance in FormModifierStore with ${JSON.stringify(
            questionInstance,
            null,
            4,
          )} - parent form does not exist`,
        );
      } else if (!state.currentlyEditingFinalFormModifier) {
        throw new Error('Trying to edit form, but no form exists in state!');
      } else {
        const newForm = new FinalFormModifier({ ...parentForm });
        recursiveSearchAndCopy(newForm.questionInstances, questionInstance);
        state.currentlyEditingFinalFormModifier = newForm;
      }
    },
    setPendingQuestion: (
      state,
      action: PayloadAction<FormModifierState['pendingQuestion']>,
    ) => {
      state.pendingQuestion = action.payload;
    },
    addPendingQuestion: (
      state,
      action: PayloadAction<{
        node: $TSFixMe;
        path: $TSFixMe;
      }>,
    ) => {
      // debugger;
      const { node, path } = action.payload;
      state.pendingQuestion = { node, path };
    },
    /**
     * Clear any existing pending question
     * @param state - state
     */
    clearPendingQuestion: (state) => {
      state.pendingQuestion = null;
    },
    removeApiNameForRemoval: (state, action: PayloadAction<string>) => {
      const apiName = action.payload;
      if (!state.currentlyEditingFinalFormModifier) {
        throw new Error(
          `Attempting to remove API name ${apiName} from existing form modifier, but the form modifier is not defined. Aborting.`,
        );
      }
      state.currentlyEditingFinalFormModifier =
        state.currentlyEditingFinalFormModifier.copyWithAmendments({
          apiNamesForRemoval:
            state.currentlyEditingFinalFormModifier.apiNamesForRemoval.filter(
              (oldApiName) => oldApiName !== apiName,
            ),
        });
    },
    addApiNameForRemoval: (state, action: PayloadAction<string>) => {
      const apiName = action.payload;
      if (!state.currentlyEditingFinalFormModifier) {
        throw new Error(
          `Attempting to add API name ${apiName} to existing form modifier, but the form modifier is not defined. Aborting.`,
        );
      }
      state.currentlyEditingFinalFormModifier =
        state.currentlyEditingFinalFormModifier.copyWithAmendments({
          apiNamesForRemoval: [
            ...state.currentlyEditingFinalFormModifier.apiNamesForRemoval,
            apiName,
          ],
        });
    },
  },
});

export { slice as formModifierStore };

/* Action Creators */
export const {
  setBusiness,
  setPolicy,
  setCurrentlyEditingFinalFormModifier,
  setLanguages,
  addApiNameForRemoval,
  removeApiNameForRemoval,
  copyQuestionInstance,
} = slice.actions;
