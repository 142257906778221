import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
import minusOutline from '@iconify/icons-eva/minus-outline';

export const getIcon = (percent) => {
  if (percent > 0) {
    return trendingUpFill;
  } else if (percent < 0) {
    return trendingDownFill;
  } else {
    return minusOutline;
  }
};
