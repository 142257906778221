import Bugsnag from '@bugsnag/browser';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { errorify } from '../../../../../util';
import { NO_INFO_STRING } from '../constants';

/**
 * Try to convert it into the locale string; oterwise just return the original string
 */
export const dateStringToLocaleString = (dateString: string) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;
  try {
    const [year, month, day] = dateString.split('-');
    // @ts-expect-error
    const newDate = new Date(year, month - 1, day);
    return newDate.toLocaleDateString(undefined, options);
  } catch (e) {
    Bugsnag.notify(errorify(e));
    return dateString;
  }
};

/**
 * Convert an OPTA measurement into an abbreviation ( or the original string if we don't know about it )
 */
export const convertMeasurementStringToHumanReadable = (
  measurementString: $TSFixMe,
) => {
  switch (measurementString) {
    case 'SquareFeet':
      return 'square feet';
    case 'SquareMetres':
      return 'square metres';
    case 'Centimetres':
      return 'cm';
    case 'Metres':
      return 'm';
    case 'Feet':
      return 'feet';
    default:
      return measurementString;
  }
};

export const formatNumber = (number: $TSFixMe) => {
  try {
    return new Intl.NumberFormat('en-CA').format(number);
  } catch (e) {
    Bugsnag.notify(errorify(e));
    return number;
  }
};

export const formatBooleanWithUnknownToString = (value: $TSFixMe) => {
  switch (value) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    case null:
    case undefined:
      return NO_INFO_STRING;
    default:
      throw new Error(
        `Invalid value; must be one of true, false, undefined, or null. You provided ${value}`,
      );
  }
};

/**
 * Return the single item if it is not undefined or null or the NO_INFO_STRING otherwise
 */
export const returnSelfOrNoInfo = (value: $TSFixMe) =>
  value === undefined || value === null ? NO_INFO_STRING : value;

/**
 * Given an item which is either falsy or an array, either return:
 * "No information" if it doesn't exist
 * The single item if there is only one
 * A comma-separated list a, b, c if there is more than one
 */
export const displayAsSingleItemOrList = (potentialList: $TSFixMe) => {
  if (!potentialList || potentialList.length === 0) {
    return NO_INFO_STRING;
  }
  if (potentialList.length === 1) {
    return potentialList[0];
  }
  return potentialList.join(', ');
};

/**
 * Given an item which is either falsy or an array, either return:
 * "No information" if it doesn't exist
 * The single item if there is only one
 * A <ul> with each array element as an item if there is more than one
 */
export const displayAsSingleItemOrHtmlList = (potentialList: $TSFixMe) => {
  if (!potentialList || potentialList.length === 0) {
    return NO_INFO_STRING;
  }
  if (potentialList.length === 1) {
    return potentialList[0];
  }
  return (
    <ul>
      {potentialList.map((item: $TSFixMe) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};

/**
 * Given a string, capitalize the first letter and conver the rest to lowercase
 * @param str - the string to modify
 * @return - The earlier string, modified as described
 */
export const capitalizeFirstLetterOrNull = (str: $TSFixMe) => {
  if (!str) {
    return null;
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const titleCase = (str: $TSFixMe) => {
  if (!str || typeof str !== 'string') {
    return str;
  }
  return str
    .split(/\s+/)
    .map((chunk) => capitalizeFirstLetterOrNull(chunk))
    .join(' ');
};

export const formatPostalCodeOrIgnore = (str: $TSFixMe) => {
  if (!str) {
    return str;
  }
  if (str.length !== 6) {
    return str;
  }
  return `${str.slice(0, 3)} ${str.slice(3)}`.toUpperCase();
};
