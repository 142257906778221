import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import type { FormValues } from 'informed';

// types and classes
import {
  QuoteWizardQuestionInstance,
  QuoteWizardAnswerInstance,
} from '../classes';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';

/**
 * Given the informed errors object, extract the full names as a string (including the position in an array or as part of an object). Basically - this is a complicated flatten function.
 */
export function extractFullNamesFromErrors(errors: $TSFixMe): Array<string> {
  const names: Array<string> = [];

  try {
    for (let [k, v] of Object.entries(errors)) {
      if (typeof v === 'string') {
        names.push(k);
      } else if (Array.isArray(v)) {
        // handle the array case here
        const constructedErrors = v.reduce((acc, entry, index) => {
          acc[`${k}[${index}]`] = entry;
          return acc;
        }, {});
        names.push(...extractFullNamesFromErrors(constructedErrors));
      } else if (typeof v === 'object') {
        // handle the object case here
        const constructedErrors: { [k: string]: any } = {};
        for (let [subK, subV] of Object.entries(v as $TSFixMe)) {
          constructedErrors[`${k}.${subK}`] = subV;
        }
        names.push(...extractFullNamesFromErrors(constructedErrors));
      }
    }

    return names;
  } catch (e) {
    console.error('Error extracting the full question name', e);
    Bugsnag.notify(errorify(e));
    return [];
  }
}

function orderAnswers(
  answerArray: Array<QuoteWizardAnswerInstance>,
  questionInstances: Array<QuoteWizardQuestionInstance>,
) {
  return answerArray.sort(
    (a1, a2) =>
      questionInstances.findIndex(
        (questionInstance) => a1.questionInstance.id === questionInstance.id,
      ) -
      questionInstances.findIndex(
        (questionInstance) => a2.questionInstance.id === questionInstance.id,
      ),
  );
}

/**
 * Convert the *values* of the FormState (informed) to the matching array of QuoteWizardAnswerInstances using the original set of questions from which the form was generated
 * @param formState - The informed FormState *values*
 * @param questionInstances - The original set of questions from which the form was generated
 * @return The array of QuoteWizardAnswerInstances
 */
export function formStateToAnswerInstances(
  formState: FormValues,
  questionInstances: Array<QuoteWizardQuestionInstance>,
): Array<QuoteWizardAnswerInstance> {
  //Takes an informed formState and iterates through it, creating
  //the related answerInstance array

  if (!formState) {
    return [];
  }
  let answerArray = [];
  for (let [field, value] of Object.entries(formState)) {
    const questionInstance = questionInstances.find(
      (question) => question.apiName === field,
    );
    if (questionInstance === undefined) {
      // This should never happen - all questionInstances should have their equivalents in the FormState
      Bugsnag.notify(
        new Error(
          `Unable to find matching questionInstance for ${JSON.stringify(
            { field, value },
            null,
            4,
          )} in ${JSON.stringify(questionInstances, null, 4)}`,
        ),
      );
      continue;
    }
    // i.e. this is a repeatable set of questions
    if (Array.isArray(value)) {
      answerArray.push(
        ...value.map((eachInstantation, idx) => {
          return new QuoteWizardAnswerInstance({
            questionInstance: questionInstance,
            value: idx + 1,
            subAnswers: formStateToAnswerInstances(
              eachInstantation,
              questionInstance.subQuestions,
            ),
          });
        }),
      );
    } else {
      answerArray.push(
        new QuoteWizardAnswerInstance({
          questionInstance,
          value,
        }),
      );
    }
  }
  return orderAnswers(answerArray, questionInstances);
}

const getAnswerWithDefaults = (q: QuoteWizardAnswerInstance) => {
  if (
    (q.value === 'N/A' || q.value === undefined) &&
    q.questionInstance.defaultValue
  ) {
    return q.questionInstance.defaultValue;
  }
  return q.value;
};

export function answerInstancesToFormState(
  answers: Array<QuoteWizardAnswerInstance> = [],
  questions: Array<QuoteWizardQuestionInstance> = [],
): FormValues {
  let newFormState: GenericObject = {};
  for (let answer of answers) {
    if (!answer) {
      continue;
    }
    const matchingQuestion = questions.find((q) =>
      answer.matchQuoteWizardQuestionInstanceById(q),
    );
    if (!answer.subAnswers || answer.subAnswers.length === 0) {
      if (
        answer.questionInstance.subQuestions &&
        answer.questionInstance.subQuestions.length > 0
      ) {
        newFormState[answer.questionInstance.apiName] = [];
      } else {
        // newFormState[answer.questionInstance.apiName] = answer.value;
        newFormState[answer.questionInstance.apiName] =
          getAnswerWithDefaults(answer);
      }
    } else {
      if (
        newFormState &&
        newFormState.hasOwnProperty(answer.questionInstance.apiName)
      ) {
        newFormState[answer.questionInstance.apiName].push(
          answerInstancesToFormState(
            answer.subAnswers,
            matchingQuestion ? matchingQuestion.subQuestions : [],
          ),
        );
      } else {
        newFormState[answer.questionInstance.apiName] = [
          answerInstancesToFormState(
            answer.subAnswers,
            matchingQuestion ? matchingQuestion.subQuestions : [],
          ),
        ];
      }
    }
  }
  // now sort out any defaults for the answers *for which we don't already have answers*
  //console.log({ questions });
  questions
    .filter(
      (question) =>
        !answers.find((answer) =>
          answer.matchQuoteWizardQuestionInstanceById(question),
        ),
    )
    .forEach((unmatchedQuestion) => {
      //console.log('Processing', unmatchedQuestion);
      if (unmatchedQuestion.subQuestions.length === 0) {
        if (unmatchedQuestion.defaultValue) {
          newFormState[unmatchedQuestion.apiName] =
            unmatchedQuestion.defaultValue;
        }
      } else {
        if (newFormState.hasOwnProperty(unmatchedQuestion.apiName)) {
          newFormState[unmatchedQuestion.apiName].push(
            answerInstancesToFormState([], unmatchedQuestion.subQuestions),
          );
        } else {
          newFormState[unmatchedQuestion.apiName] = [
            answerInstancesToFormState([], unmatchedQuestion.subQuestions),
          ];
        }
      }
    });
  return newFormState;
}
