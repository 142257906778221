import { graphql } from '../gql';

export const GET_QUOTE_PDF = graphql(`
  query getQuotePdf(
    $token: String!
    $uniqueID: String!
    $archiveID: String
    $renewalOnly: Boolean
    $downloadType: String
  ) {
    quotePdf(
      token: $token
      uniqueID: $uniqueID
      archiveID: $archiveID
      renewalOnly: $renewalOnly
      downloadType: $downloadType
    ) {
      businessName
      pdfString
    }
  }
`);
