import { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, IconButton, Typography } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material/styles';

import { ProgramBuilderWizard } from '../../../../../../../store';
import { LeaveDirtyFormConfirmation } from './LeaveDirtyFormConfirmation';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const useStyles = makeStyles((theme: $TSFixMe) => ({
  formModal: {
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  formModalContents: {
    maxHeight: '90vh',
    maxWidth: '50rem',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: theme.spacing(6),
    position: 'relative',
    overflow: 'auto',
  },
}));

interface FormModalProps {
  navigate?: $TSFixMe;
  open: boolean;
  handleClose: $TSFixMe;
  FormComponent: $TSFixMe;
  title: string;
  clearPendingQuestion?: () => void;
  [k: string]: $TSFixMe;
}

/**
 * The modal used to create / edit a question
 * @param {Function} navigate - imperative command to navigate (from @reach/router)
 * @param  open - whether or not it is open
 * @param  classes - classes for Material UI Theming
 * @param  FormComponent - The actual form to display
 * @param  title - Title displayed at the top of the modal
 * @param  clearPendingQuestion - redux function to clear the pending question (question to which a question is to be added as a subquestion)
 */
export const FormModal = ({
  navigate,
  open,
  handleClose,
  FormComponent,
  title,
  clearPendingQuestion,
  ...otherProps
}: FormModalProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [leaveDirtyFormConfirmationOpen, setLeaveDirtyFormConfirmationOpen] =
    useState(false);

  // is the form dirty? Used to control whether or not the confirmation dialog gets displayed.
  const [formDirty, setFormDirty] = useState(false);
  return (
    <Modal // `disableBackdropClick` is removed by codemod.
      // You can find more details about this breaking change in [the migration guide](https://next.material-ui.com/guides/migration-v4/#modal)

      className={classes.formModal}
      open={open}
      onClose={handleClose}
      BackdropComponent={() => (
        <Backdrop
          data-testid='new-question-modal-backdrop'
          open={true}
          onClick={() => {
            if (formDirty) {
              setLeaveDirtyFormConfirmationOpen(true);
            } else {
              handleClose();
            }
          }}
        />
      )}
      onBackdropClick={() => {
        if (formDirty) {
          setLeaveDirtyFormConfirmationOpen(true);
        } else {
          handleClose();
        }
      }}
    >
      <div className={classes.formModalContents} data-testid='modal-contents'>
        <Typography variant='h6' color='textSecondary' align='center'>
          {title}
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            right: theme.spacing(3),
            top: theme.spacing(3),
            padding: 0,
          }}
          onClick={() => handleClose()}
          size='large'
        >
          <ClearIcon />
        </IconButton>
        <FormComponent
          handleClose={handleClose}
          navigate={navigate}
          setDirty={setFormDirty}
          {...otherProps}
        />
        <LeaveDirtyFormConfirmation
          open={leaveDirtyFormConfirmationOpen}
          setOpen={setLeaveDirtyFormConfirmationOpen}
          handleModalClose={handleClose}
        />
      </div>
    </Modal>
  );
};

/* Connect to Redux Store */

function mapDispatchToProps(dispatch: $TSFixMe) {
  return {
    clearPendingQuestion: function () {
      return dispatch(ProgramBuilderWizard.actions.clearPendingQuestion());
    },
  };
}

const ConnectedFormModal = connect(null, mapDispatchToProps)(FormModal);

/* Theming */
export default withStyles((_theme: $TSFixMe) => ({}))(ConnectedFormModal);
