import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { useLazyQuery } from '@apollo/client';

import { generateThanksNavigationDestination } from '../utility';
import { useHandleIframe } from './utility';
import { BUSINESS_TYPE } from '../../../queries';

// types and classes
import type { ActionButtonProps } from './types';
import Bugsnag from '@bugsnag/browser';

export const DefaultActionButton = ({
  formApi,
  businessId,
  policies,
  toBusiness,
  questionInstances,
}: ActionButtonProps) => {
  const [getBusiness, { error, data }] = useLazyQuery(BUSINESS_TYPE);
  // @ts-expect-error
  const handle = useHandleIframe({
    ...{
      formApi,
      businessId,
      policies,
      toBusiness,
      questionInstances,
      generateNavigationDestination: generateThanksNavigationDestination,
      useImperativeNavigateLink: true,
    },
    ...(data?.businessType ? { businessLine: data.businessType } : {}),
  });

  // get the business type, if applicable
  useEffect(() => {
    if (!businessId) {
      return;
    }
    let params: { id: string } | { internalName: string };
    if (Number.isNaN(Number(businessId))) {
      params = { internalName: businessId };
    } else {
      params = { id: businessId };
    }
    getBusiness({ variables: params });
  }, [businessId]);

  if (error) {
    Bugsnag.notify(error);
  }
  if (!formApi) {
    return null;
  }
  return (
    <Button
      variant='contained'
      type='button'
      onClick={async () => {
        // @ts-expect-error
        await handle();
      }}
    >
      Get a Quote!
    </Button>
  );
};
