import { graphql } from '../gql';

export const publishNewForm = graphql(`
  mutation publishNewForm(
    $questionInstances: [QuestionInstanceInput]!
    $businessLineId: ID!
    $policyId: ID
    $token: String!
    $qualifyingQuestion: QuestionInstanceInput
    $required: Boolean
    $policyExtension: [PolicyExtensionInput]
  ) {
    createFinalForm(
      questionInstances: $questionInstances
      businessLineId: $businessLineId
      policyId: $policyId
      token: $token
      qualifyingQuestion: $qualifyingQuestion
      required: $required
      policyExtension: $policyExtension
    ) {
      ok
      finalForm {
        id
        required
        businessLine {
          id
          displayName
          internalName
        }
        policy {
          id
          displayName
          internalName
        }
        qualifyingQuestion {
          ...QuestionInstanceFields
        }
        policyExtension {
          id
          label
          value
          questionId
          multiplier
        }
        questionInstances {
          ...QuestionInstanceFields
          subQuestions {
            ...QuestionInstanceFields
            subQuestions {
              ...QuestionInstanceFields
              subQuestions {
                ...QuestionInstanceFields
                subQuestions {
                  ...QuestionInstanceFields
                  subQuestions {
                    ...QuestionInstanceFields
                    subQuestions {
                      ...QuestionInstanceFields
                      subQuestions {
                        ...QuestionInstanceFields
                        subQuestions {
                          ...QuestionInstanceFields
                          subQuestions {
                            ...QuestionInstanceFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
