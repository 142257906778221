// get all of the unique location answers (including details) from a quote hash
import { graphql } from '../gql';

export const LOCATIONS_FROM_QUOTE_HASH = graphql(`
  query locationsFromQuoteHash($hash: String!) {
    locationsFromQuoteHash(hash: $hash) {
      ...AnswerInstanceFields
    }
  }
`);
