import { ProgramBuilderForm } from '../../../../classes';
import { ValidationFunction } from '../types';

export const validateFormLanguages: ValidationFunction = (
  form: ProgramBuilderForm,
) => {
  const formLanguages = form.getAvailableLanguages();
  if (formLanguages.length === 0) {
    return [
      'There is no language in which all questions are available (have Field Names and Question Prompts).',
    ];
  }
  return [];
};
