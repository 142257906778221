import { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { Suspense, lazy } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Skeleton, Card, CardHeader, CardContent } from '@mui/material';
// queries
import { DASHBOARD_LOCATION_DATA } from '../../../../../queries';
// hooks
import { useAuth, } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import LocationDataSkeleton from './skeletons/LocationDataSkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { LocationMapMock } from './Mock';

// ----------------------------------------------------------------------

// FIX DISPLAY ERROR ON PRODUCTION

//@ts-expect-error
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const THEMES = {
  streets: 'mapbox://styles/mapbox/streets-v11',
  outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const baseSettings = {
  mapboxApiAccessToken:
    'pk.eyJ1IjoicGhpbGxpcG9iaW9yYSIsImEiOiJja3JrdTE2ZHE1Zzl3MnZwZDI5dDNwejhoIn0.TrChCG-yO8uZ-v0Zzo0zwQ',
  width: '100%',
  height: '100%',
  minZoom: 1,
};

const MapWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: 560,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
}));

// ----------------------------------------------------------------------

type geoLocationType = {
  lat: number;
  long: number;
};

// ----------------------------------------------------------------------

const MapClusters = lazy(() => import('../../map/MapClusters'));

const SkeletonLoad = (
  <>
    <Skeleton
      width='100%'
      height={560}
      variant='rectangular'
      sx={{ borderRadius: 2, mb: 5 }}
    />
    <Skeleton
      width='100%'
      height={560}
      variant='rectangular'
      sx={{ borderRadius: 2 }}
    />
  </>
);

export default function LocationMap() {
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const { data, error, loading } = useQuery(DASHBOARD_LOCATION_DATA, {
    variables: { token},
    skip:
      hasDashboardPermissions !== true ||
      !token ||
      slug === 'lastnameinsurance',
  });
  const [geoLocations, setGeoLocations] = useState<geoLocationType[]>([]);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setGeoLocations(LocationMapMock());
    } else if (data && data.locationData) {
      setGeoLocations(
        // @ts-expect-error
        data.locationData.geoLocations ? data.locationData.geoLocations : [],
      );
    }
  }, [data, slug]);

  if (hasDashboardPermissions === false) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if (loading || hasDashboardPermissions === undefined) {
    return <LocationDataSkeleton />;
  }
  if (error) {
    Bugsnag.notify(`Could not load Locations component, ${error}`);
    return handleDashboardError(error);
  }

  return (
    <Suspense fallback={SkeletonLoad}>
      <Card data-testid='app-location-map'>
        <CardHeader title='Quote Map' />
        <CardContent>
          <MapWrapperStyle>
            <MapClusters
              data={geoLocations}
              {...baseSettings}
              mapStyle={THEMES.light}
            />
          </MapWrapperStyle>
        </CardContent>
      </Card>
    </Suspense>
  );
}
