import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { MARK_QUOTE_AS_CONFIRMATION_IN_PROGRESS } from '../../../queries';
import LoadingScreen from '../../ManagementPanel/components/LoadingScreen';

import { handleErrors } from '../../common/ErrorHandling';
import {
  InvalidHashError,
  NoMatchFoundError,
  InvalidConfirmationStatusError,
} from './ErrorComponents';
import { validateUuid4 } from '@calefy-inc/utility';

// types and claess
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const handleSpecificErrors = (error: $TSFixMe, hash: string) => {
  if (error.message.match(/is not a valid uuid/i)) {
    return <InvalidHashError hash={hash} />;
  }
  if (
    error.message.match(
      /quotehashcorrespondence matching query does not exist/i,
    )
  ) {
    return <NoMatchFoundError hash={hash} />;
  }
  if (error.message.match(/invalid status:/i)) {
    const status = error.message.match(/invalid status: (\w+)/i)[1];
    return <InvalidConfirmationStatusError hash={hash} status={status} />;
  }
};

interface QuoteConfirmProps extends RouteComponentProps {
  hash?: string;
}

export const QuoteConfirm = ({ hash }: QuoteConfirmProps) => {
  const navigate = useNavigate();
  const [markQuoteAsConfirmationInProgress, { loading, error, data }] =
    useMutation(MARK_QUOTE_AS_CONFIRMATION_IN_PROGRESS);

  // as soon as the hash is good, fire off the request
  useEffect(() => {
    if (hash && validateUuid4(hash)) {
      markQuoteAsConfirmationInProgress({ variables: { hash } });
    }
  }, [hash, markQuoteAsConfirmationInProgress]);

  // if the response is good, flip over to QuoteResume
  useEffect(() => {
    // @ts-expect-error
    if (data && data.markQuoteAsConfirmationInProgress.ok) {
      navigate(
        // @ts-expect-error
        `/insurtech/quote/resume/${data.markQuoteAsConfirmationInProgress.uuid}`,
      );
    }
  }, [data, navigate]);

  if (!hash) {
    return null;
  }

  if (hash && !validateUuid4(hash)) {
    return <InvalidHashError hash={hash} />;
  }

  if (!hash) {
    return null;
  }
  if (error) {
    const result = handleErrors(
      error,
      (
        (hash: string) => (error: $TSFixMe) =>
          handleSpecificErrors(error, hash)
      )(hash),
    );
    if (result) {
      return result;
    }
  }
  if (loading) {
    return <LoadingScreen />;
  }
  return null;
};
