import { TransformedData } from './cellUtils';

interface QuoteDetails {
  [key: string]: string | string[] | QuoteDetails;
}

const displayData = (
  obj: QuoteDetails,
  key: string,
  limits: boolean = false,
  level: number = 0,
) => {
  let result: string[] = [];
  let data = obj[key];
  if (
    ['string', 'number', 'boolean', 'undefined'].includes(typeof data) ||
    data === null
  ) {
    data = `${data}`;
    if (data.includes(':')) data = data.replaceAll(':', ';');
    if (level > 0) {
      result.push(`${' '.repeat(level)}${key}: ${data}`);
    } else {
      result.push(`${data}`);
    }
  } else {
    if (limits || level > 0) result.push(`${' '.repeat(level)}${key}:`);
    if (Array.isArray(data)) {
      data.forEach((elem, _ind) => {
        if (typeof elem == 'object') {
          // result.push(`${' '.repeat(level)}${ind + 1}:`);
          result.push(' ');
          Object.keys(elem).forEach((subkey) => {
            result = result.concat(
              displayData(elem, subkey, limits, level + 1),
            );
          });
        } else {
          result.push(`${' '.repeat(level + 1)}${elem}`);
          result.push(' ');
        }
      });
    } else if (typeof data == 'object') {
      Object.keys(data).forEach((subkey) => {
        result = result.concat(
          displayData(data as QuoteDetails, subkey, limits, level + 1),
        );
      });
    }
  }
  return result;
};

function countLeadingWhitespace(str: string) {
  let leadingWhitespace = str.match(/^\s+/);
  return leadingWhitespace ? leadingWhitespace[0].length : 0;
}

function getLimits(obj: QuoteDetails) {
  let result: QuoteDetails = {};
  Object.keys(obj).forEach((key) => {
    if (key.toLowerCase().includes('limit')) {
      result[key] = obj[key];
    } else if (
      !Array.isArray(obj[key]) && // find a way to format this later
      typeof obj[key] === 'object' &&
      obj[key] !== null
    ) {
      const nestedResult = getLimits(obj[key] as QuoteDetails);
      if (Object.keys(nestedResult).length > 0) {
        result[key] = nestedResult;
      }
    }
  });
  return result;
}

const transformData = (jsonData: QuoteDetails, quoteIdentifiers: string[]) => {
  let topLevelKeys = Object.keys(jsonData);
  let details: QuoteDetails;
  if (topLevelKeys.length === 1) {
    details = jsonData[topLevelKeys[0]] as QuoteDetails;
  } else {
    details = jsonData;
  }
  const keys: string[] = Object.keys(details);
  const rows: TransformedData[] = keys.map((key) => {
    const value = details[key];
    let transformedRow: TransformedData = { description: key };

    if (Array.isArray(value)) {
      transformedRow.value = value.join(', ');
    } else if (
      ['string', 'number', 'boolean', 'undefined'].includes(typeof value) ||
      value === null
    ) {
      transformedRow.value = `${value}`;
    } else if (typeof value === 'object') {
      if (quoteIdentifiers.length > 0) {
        quoteIdentifiers.forEach((quoteIdentifier) => {
          const quoteKey = Object.keys(value).find(
            (subKey) => subKey.toLowerCase() === quoteIdentifier.toLowerCase(),
          );
          if (quoteKey) {
            transformedRow[quoteKey] = (value as { [key: string]: string })[
              quoteKey
            ];
          }
        });
      }
    }
    return transformedRow;
  });
  return rows;
};

const validateRowData = (
  quoteJson: QuoteDetails,
  key: string | string[],
): string => {
  if (Array.isArray(key)) {
    let finalValue = 'N/A';
    key.forEach((k) => {
      if (quoteJson[k] && typeof quoteJson[k] === 'string')
        finalValue = quoteJson[k] as string;
    });
    return finalValue != '#' ? finalValue : 'N/A';
  } else {
    return quoteJson[key] &&
      typeof quoteJson[key] === 'string' &&
      quoteJson[key] != '#'
      ? (quoteJson[key] as string)
      : 'N/A';
  }
};

export {
  displayData,
  countLeadingWhitespace,
  getLimits,
  transformData,
  validateRowData,
};
export type { QuoteDetails };
