// types and classes
import { QuoteWizardAnswerInstance } from '../QuoteWizard/classes';

/**
 * Extract the slider data type from the props blob and format the value
 *
 * @param {object} propsBlob - question instance props blob
 * @param {*} value - value of current question instance
 */
export function numberFormat(
  propsBlob: QuoteWizardAnswerInstance['questionInstance']['propsBlob'],
  value: unknown,
) {
  const symbol = propsBlob.dataType;

  if (typeof value !== 'string' && typeof value !== 'number') {
    console.error(
      'Received non-number, non-string value to numberFormat:',
      value,
    );
    return '';
  }
  const stringified = typeof value === 'string' ? value : String(value);
  const numberOfDecimalPlaces =
    stringified.split('.').length > 1 ? stringified.split('.')[1].length : 0;
  const minimumFractionDigits =
    symbol === '$' && numberOfDecimalPlaces > 0 ? 2 : 0;
  const maximumFractionDigits =
    symbol !== '$'
      ? numberOfDecimalPlaces + 1
      : numberOfDecimalPlaces === 0
      ? 0
      : 2;

  const formatted = new Intl.NumberFormat('en-CA', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(Number(stringified));
  const withSymbol = !symbol
    ? formatted
    : symbol === '$'
    ? `$${formatted}`.trim()
    : `${formatted}${symbol === '%' ? '' : ' '}${symbol}`.trim();
  return withSymbol;
}
