import { BusinessType } from '../../Typescript';

export const FUSE_BLOCKED_BUSINESSES = [
  {
    id: '492',
    displayName: 'Commercial Condo - Office/Retail Occupancy',
    internalName:
      'condominiumstrata_lowrise_stories_mixed_residentialcommercial',
    keywords: [],
    aliases: '[]',
    industry: {
      id: '57',
      displayName: 'Commercial Realty',
    },
  },
  {
    id: '465',
    displayName: 'Condominium /Strata - Bareland',
    internalName: 'condominium_strata_bareland',
    keywords: [],
    aliases: '[]',
    industry: {
      id: '14',
      displayName: 'Realty',
      __typename: 'IndustryType',
    },
    __typename: 'BusinessTypeType',
  },
  {
    id: '490',
    displayName:
      'Condominium/Strata - Highrise (>10 Stories) - Mixed Residential/Commercial',
    internalName:
      'condominiumstrata_highrise_stories_mixed_residentialcommercial',
    keywords: [],
    aliases: '[]',
    industry: {
      id: '14',
      displayName: 'Realty',
      __typename: 'IndustryType',
    },
    __typename: 'BusinessTypeType',
  },
  {
    id: '489',
    displayName:
      'Condominium/Strata - Highrise (>10 Stories) - Residential Only',
    internalName: 'condominiumstrata_highrise_stories_residential_only',
    keywords: [],
    aliases: '[]',
    industry: {
      id: '14',
      displayName: 'Realty',
      __typename: 'IndustryType',
    },
    __typename: 'BusinessTypeType',
  },
  {
    id: '491',
    displayName:
      'Condominium/Strata - Lowrise (<10 stories) - Residential Only',
    internalName: 'condominiumstrata_lowrise_stories_residential_only',
    keywords: [],
    aliases: '[]',
    industry: {
      id: '14',
      displayName: 'Realty',
      __typename: 'IndustryType',
    },
    __typename: 'BusinessTypeType',
  },
  {
    id: '469',
    displayName:
      'Condominium/Strata - Mixed Commercial (No Residential) - Strip Malls, Offices, Etc.',
    internalName:
      'condominiumstrata_mixed_commercial_no_residential_strip_malls_offices_etc',
    keywords: [],
    aliases: '[]',
    industry: {
      id: '14',
      displayName: 'Realty',
      __typename: 'IndustryType',
    },
    __typename: 'BusinessTypeType',
  },
];

const BLOCKED_BUSINESS_INTERNAL_NAMES = new Set(
  FUSE_BLOCKED_BUSINESSES.map((b) => b.internalName),
);

export const fuseBusinessFilter = (business: BusinessType): boolean => {
  return (
    !!business.internalName &&
    !BLOCKED_BUSINESS_INTERNAL_NAMES.has(business.internalName)
  );
};
