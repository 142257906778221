import componentRegistry from '../QuoteComponents';
import Grid from '@mui/material/Grid';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

// types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { QuoteWizardQuestionInstance } from '../../QuoteWizard/classes';

type RenderedQuestionInstanceProps = {
  questionInstance: null | QuoteWizardQuestionInstance;
  classes?: GenericObject;
  blockAddSubquestions?: boolean;
  [key: string]: $TSFixMe;
};
export default function RenderedQuestionInstance({
  questionInstance,
  blockAddSubquestions,
  classes = {},
}: RenderedQuestionInstanceProps) {
  const theme = useTheme();
  const tooltipStyles = {
    tooltip: {
      fontSize: '0.9rem',
      backgroundColor: theme.palette.primary.main,
      padding: '8px',
    },
  };
  const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

  if (!questionInstance) {
    return null;
  }

  const QuoteComponent = componentRegistry.getQuoteComponent(
    questionInstance.component,
  );

  const HelpText = questionInstance.helpText;
  if (
    QuoteComponent &&
    HelpText &&
    (!questionInstance.subQuestions ||
      questionInstance.subQuestions.length === 0)
  ) {
    return (
      <Grid container alignItems='center'>
        <Grid item xs={11}>
          <QuoteComponent
            classes={classes}
            questionInstance={questionInstance}
            blockAddSubquestions={blockAddSubquestions}
          />
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomTooltip
            title={HelpText}
            enterDelay={100}
            leaveDelay={100}
            placement='right-start'
          >
            <HelpSharpIcon color='primary' fontSize='small' />
          </CustomTooltip>
        </Grid>
      </Grid>
    );
  } else if (QuoteComponent) {
    return (
      <QuoteComponent
        classes={classes}
        questionInstance={questionInstance}
        blockAddSubquestions={blockAddSubquestions}
      />
    );
  } else {
    console.error(
      `In RenderedQuestionInstance: Component not found for ${JSON.stringify(
        questionInstance,
        null,
        4,
      )}`,
    );
    return null;
  }
}
