import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from '@reach/router';
import { useMutation, useLazyQuery } from '@apollo/client';
// material
import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Typography } from '@mui/material';
// Informed
import { Form } from 'informed';
import { TextField, Select } from '@calefy-inc/informedMaterial';
// queries
import {
  CREATE_BUSINESS,
  GET_ALL_INDUSTRIES,
  UPDATE_BUSINESS,
  RELAY_BUSINESS,
} from '../../../../../../queries';
// error handler
import Bugsnag from '@bugsnag/js';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../../hooks';
// utils
import { converter } from '../../../../utils/formatInternalName';
import LoadingScreen from '../../../LoadingScreen';
// pages
import ErrorPage from '../../../../pages/Page500';
import { InsufficientPermissionsErrorPage } from '../../../../../common/ErrorHandling';
import { BusinessTypeAliases } from './BusinessTypeAliases';
import { BusinessTypeKeywords } from './BusinessTypeKeywords';
import { mergeExistingKeywordsWithNew } from './utility';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

type BusinessNewFormProps = {
  isEdit: boolean;
  currentBusiness: $TSFixMe;
};
export default function BusinessNewForm({
  isEdit,
  currentBusiness,
}: BusinessNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const hasCorrectPermissions = useAllowByPermission('edit:business');
  const [industries, setIndustries] = useState([]);
  const [keywords, setKeywords] = useState<Array<string>>(
    currentBusiness?.keywords ? currentBusiness.keywords : [],
  );
  const [aliases, setAliases] = useState<Array<string>>(
    currentBusiness?.aliases ? JSON.parse(currentBusiness.aliases) : [],
  );

  // queries
  const [getIndustries, { data: industryData, error: industryError }] =
    useLazyQuery(GET_ALL_INDUSTRIES);

  // mutations
  const [createBusiness, { data, loading, error }] =
    useMutation(CREATE_BUSINESS);

  const [
    updateBusiness,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_BUSINESS);

  // effects
  useEffect(() => {
    getIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (industryData) {
      // @ts-expect-error
      const industryOptions = industryData.allIndustries.map(
        (industry: $TSFixMe) => ({
          value: industry.id,
          label: industry.displayName,
          industry,
        }),
      );
      // @ts-expect-error
      setIndustries(industryOptions);
    }
  }, [industryData]);

  useEffect(() => {
    //console.log({ updateError });
    const anyError = error || updateError;
    const anyData = data || updateData;
    const anyLoading = loading || updateLoading;

    if (anyError) {
      enqueueSnackbar(!isEdit ? 'Create failed' : 'Update failed', {
        variant: 'error',
      });
      /*console.log('Error creating or updating business:', anyError); */
      Bugsnag.notify(anyError);
    }
    if (!anyLoading && !anyError && anyData) {
      enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', {
        variant: 'success',
      });
      navigate('/insurtech/manager/database/business');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, updateError, updateData]);

  // handlers
  const handleSubmit = (values: $TSFixMe) => {
    const displayName = values.displayName;

    const keywordsToSendBack = mergeExistingKeywordsWithNew(
      keywords,
      values.keywords,
    );
    const aliasesToSendBack = mergeExistingKeywordsWithNew(
      aliases,
      values.aliases,
    );

    if (isEdit) {
      updateBusiness({
        variables: {
          businessId: currentBusiness.id,
          displayName: displayName,
          internalName: converter(displayName),
          keywords: keywordsToSendBack,
          aliases: aliasesToSendBack,
          sicCode:
            typeof values.sicCode === 'string'
              ? parseInt(values.sicCode)
              : values.sicCode,
          industryId: values.industryId,
          token,
        },
      });
    } else {
      createBusiness({
        variables: {
          sicCode:
            typeof values.sicCode === 'string'
              ? parseInt(values.sicCode)
              : values.sicCode,
          displayName: displayName,
          internalName: converter(displayName),
          keywords: keywordsToSendBack,
          aliases: aliasesToSendBack,
          industryId: values.industryId,
          token,
        },
        update: (cache, { data }) => {
          let businesses;
          try {
            businesses = cache.readQuery({ query: RELAY_BUSINESS });
          } catch (e) {
            console.error('Failed to read business list from cache', e);
          }

          try {
            cache.writeQuery({
              query: RELAY_BUSINESS,
              data: {
                // @ts-expect-error
                relayBusinesses: [
                  // @ts-expect-error
                  ...businesses.relayBusinesses.edges,
                  // @ts-expect-error
                  data.createBusiness.business,
                ],
              },
            });
          } catch (e) {
            console.error('Failed to write to business list in cache', e);
          }
        },
      });
    }
  };

  if (isEdit && !currentBusiness && !industries) {
    return <LoadingScreen />;
  }

  if (industryError) {
    console.error('Error getting industries:', industryError);
    Bugsnag.notify(industryError);
    return <ErrorPage />;
  }

  return !!token && !hasCorrectPermissions ? (
    <InsufficientPermissionsErrorPage deniedIdentifier='Create / Edit Business page' />
  ) : (
    <Form
      noValidate
      autoComplete='off'
      initialValues={
        isEdit
          ? {
              sicCode: currentBusiness.sicCode,
              displayName: currentBusiness.displayName,
              internalName: currentBusiness.internalName,
              industryId: currentBusiness.industry.id,
            }
          : { sicCode: '', displayName: '', internalName: '', industryId: '' }
      }
      onSubmit={handleSubmit}
    >
      {() => (
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <TextField
                fullWidth
                variant='outlined'
                field='displayName'
                label='Display Name'
                required
              />
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <Select
                fullWidth
                name='industryId'
                field='industryId'
                aria-label='Select or type industry'
                placeholder='Select or type Industry'
                options={industries}
                defaultValue={
                  isEdit
                    ? {
                        value: currentBusiness.industry.id,
                        label: currentBusiness.industry.displayName,
                      }
                    : ''
                }
                validate={(value: $TSFixMe) => {
                  if (!value) {
                    return 'Must select a industry to continue';
                  }
                }}
                required
              />
              <TextField
                fullWidth
                variant='outlined'
                field='sicCode'
                label='SIC Code'
                validate={(value: $TSFixMe) => {
                  if (!value) {
                    return 'This field is required';
                  }
                  if (isNaN(value)) {
                    return 'SIC code must be a number';
                  }
                }}
                //disabled={isEdit && formState.values.sicCode}
                required
              />
            </Stack>
            <BusinessTypeKeywords
              keywords={keywords}
              setKeywords={setKeywords}
            />
            <BusinessTypeAliases aliases={aliases} setAliases={setAliases} />
            {error ? (
              <>
                <Typography sx={{ color: 'error.main' }}>
                  An error occurred when creating / updating a business.
                </Typography>
                {/aliases must be unique/i.test(error.message) ? (
                  <Typography sx={{ color: 'error.main' }}>
                    The following aliases are already in use:{' '}
                    {error.message.substring(
                      error.message.indexOf('Found duplicate aliases ') +
                        'Found duplicate aliases '.length,
                    )}
                  </Typography>
                ) : (
                  <Typography sx={{ color: 'error.main' }}>
                    Please try again in a minute, or send an email to{' '}
                    <a href='support@calefy.ca?subject=Error when creating / updating business'>
                      support@calefy.ca
                    </a>{' '}
                    explaining the problem. Our apologies for the inconvenience.
                  </Typography>
                )}
              </>
            ) : null}
          </Stack>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              type='submit'
              variant='contained'
              loading={loading || updateLoading}
            >
              {!isEdit ? 'Create Business' : 'Save Changes'}
            </LoadingButton>
          </Box>
        </Card>
      )}
    </Form>
  );
}
