import { graphql } from '../../gql';

export const GENERIC_BUSINESS_WORDCLOUD = graphql(`
  query genericBusinessWordcloud($token: String!) {
    genericBusinessWordcloud(token: $token) {
      value
      frequency
    }
  }
`);
