import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '../../../../hooks';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// types and classes
import type { StoreState } from '../../../../store/';
import { Policy } from '../../../../Typescript';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const getPremiumProposals = (store: StoreState) =>
  store.quoteWizard.premiumProposals.filter(
    (proposal) => proposal.status !== 'REJECTED',
  );
const getSubmittedPolicies = (store: StoreState) => {
  return Object.values(store.quoteWizard.formAnswers)
    .map((formAnswer) => formAnswer.form.policy)
    .filter((policy) => !!policy);
};

const DISPLAY_DIFFERENCE_THRESHOLD = 10; // minimum difference between the min and max to display different premiums; if the difference is less than this amount than *only* the minimum is displayed.

export const formatForListDisplay = (strings: Array<string>): string => {
  if (strings.length === 0) {
    return '';
  }
  if (strings.length === 1) {
    return strings[0];
  }
  if (strings.length === 2) {
    return strings.join(' or ');
  }
  return (
    strings.slice(0, -2).join(', ') + ', ' + strings.slice(-2).join(', or ')
  );
};

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const Bold = ({ children }: { children: $TSFixMe }) => {
  return (
    <Box sx={{ fontWeight: 'bold', display: 'inline', color: 'primary.main' }}>
      {children}
    </Box>
  );
};

interface PremiumProposalsDisplayProps {
  classes: $TSFixMe;
}
export const PremiumProposalsDisplay = ({
  classes,
}: PremiumProposalsDisplayProps) => {
  const { slug } = useSettings();
  const premiumProposals = useSelector(getPremiumProposals);
  const submittedPolicies = useSelector(getSubmittedPolicies);
  const [min, setMin] = useState<null | number>(null);
  const [max, setMax] = useState<null | number>(null);
  const [uncoveredPolicies, setUncoveredPolicies] = useState<Array<Policy>>([]);

  useEffect(() => {
    if (premiumProposals && premiumProposals.length > 0) {
      const allPremiumAmounts = premiumProposals.map(
        (proposal) => proposal.amountInCents / 100,
      );
      setMin(Math.min(...allPremiumAmounts));
      setMax(Math.max(...allPremiumAmounts));
    }
  }, [premiumProposals]);

  // set the policies which are not covered by the premium proposals (NB This is very rough - doesn't account at all for the fact that different proposals might overlap the policies in different ways. Treat with caution!)
  useEffect(() => {
    if (
      submittedPolicies.length > 0 &&
      premiumProposals.filter((proposal) => proposal.status !== 'REJECTED')
        .length > 0
    ) {
      const proposalPolicyInternalNames = premiumProposals
        .reduce(
          (allPolicies: Array<Policy>, proposal) => [
            ...allPolicies,
            ...proposal.policies,
          ],
          [],
        )
        .filter((policy) => !!policy)
        .map((proposal) => proposal.internalName);
      setUncoveredPolicies(
        // @ts-expect-error
        submittedPolicies.filter(
          (quotePolicy) =>
            // @ts-expect-error
            !proposalPolicyInternalNames.includes(quotePolicy.internalName),
        ),
      );
    }
  }, [premiumProposals, submittedPolicies]);

  if (
    !premiumProposals ||
    premiumProposals.length === 0 ||
    // if all of the costs are 0, something is wrong -> ignore it
    premiumProposals.filter((proposal) => proposal.amountInCents > 0).length ===
      0 ||
    min === null ||
    max === null ||
    submittedPolicies.length === 0
  ) {
    return null;
  }
  return (
    <>
      {slug === 'vago' ? null : (
        <Typography className={classes.content}>
          We estimate that your premium will be about{' '}
          <Bold>
            {max - min < DISPLAY_DIFFERENCE_THRESHOLD
              ? formatter.format(min)
              : `${formatter.format(min)} - ${formatter.format(max)}`}
            .
          </Bold>
        </Typography>
      )}

      {uncoveredPolicies.length > 0 ? (
        <Typography className={classes.content} sx={{ textAlign: 'center' }}>
          Note that this does not include coverage for{' '}
          <Bold>
            {formatForListDisplay(
              uncoveredPolicies.map((policy) => policy.displayName),
            )}
          </Bold>
          . One of our agents will contact you with additional information about{' '}
          {uncoveredPolicies.length === 1 ? 'this policy' : 'those policies'}.
        </Typography>
      ) : null}
    </>
  );
};
