import { useDispatch } from 'react-redux';
import { formStore } from '../../../../store';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { ProgramBuilderForm } from '../../classes';

interface RequiredToggleProps {
  form: ProgramBuilderForm;
}
const RequiredToggle = ({ form }: RequiredToggleProps) => {
  const dispatch = useDispatch();
  const { toggleFormRequired } = formStore.actions;

  return (
    <Tooltip
      title={form.policy ? '' : 'General information forms are always required'}
      placement='right'
    >
      <FormControlLabel
        control={
          <Switch
            onClick={() => dispatch(toggleFormRequired({ form }))}
            checked={form.policy ? form.required : true}
          />
        }
        label='Form Required'
        disabled={!form.policy}
      />
    </Tooltip>
  );
};

export default RequiredToggle;
