import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from '@reach/router';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermissions } from '../../../../hooks';
// components
import { InsufficientPermissionsErrorPage } from '../../../common/ErrorHandling';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  PolicyListToolbar,
  PolicyListHead,
  PolicyMoreMenu,
} from '../../components/_dashboard/database/policy';
import LoadingScreen from '../../components/LoadingScreen';
// error handler
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../Page500';
import { useSnackbar } from 'notistack';
// queries
import { GET_ALL_POLICIES, DELETE_POLICY } from '../../../../queries';

// types and classes
import type { RouteComponentProps } from '@reach/router';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'displayName', label: 'Display Name', alignRight: false },
  { id: 'internalName', label: 'Internal Name', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a: $TSFixMe, b: $TSFixMe, orderBy: $TSFixMe) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy: $TSFixMe) {
  return order === 'desc'
    ? (a: $TSFixMe, b: $TSFixMe) => descendingComparator(a, b, orderBy)
    : (a: $TSFixMe, b: $TSFixMe) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: Array<$TSFixMe>,
  comparator: $TSFixMe,
  query: $TSFixMe,
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (policy) =>
        policy.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

interface PolicyListProps extends RouteComponentProps {}

export default function PolicyList(_props: PolicyListProps) {
  const { themeStretch } = useSettings();
  const { token } = useAuth();
  const hasPolicyEditPermissions = useAllowByPermissions('edit:policy');
  const hasViewPolicyPermissions = useAllowByPermissions(
    'view:database:policy:table',
  );
  const { enqueueSnackbar } = useSnackbar();
  const [policyList, setPolicyList] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<$TSFixMe>('displayName');
  const [filterName, setFilterName] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [hiddenQueryLoading, setHiddenQueryLoading] = useState<boolean>(false);

  // queries
  const [getPolicies, { loading, data, error }] =
    useLazyQuery(GET_ALL_POLICIES);

  // mutations
  const [
    deletePolicy,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_POLICY);

  // Effects
  useEffect(() => {
    getPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      setPolicyList(data.allPolicies);
    }
  }, [data]);

  useEffect(() => {
    const anyLoading = loading || deleteLoading;
    if (anyLoading && policyList.length > 0) {
      setHiddenQueryLoading(true);
    } else {
      setHiddenQueryLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, deleteLoading]);

  useEffect(() => {
    if (deleteData) {
      // const deletedId = deleteData.deletePolicy.deletedId;
      // setPolicyList(policyList.filter((policy) => policy.id !== deletedId));
      enqueueSnackbar('Policy Deleted', { variant: 'success' });
    }
    if (deleteError) {
      console.error('Error:', deleteError);
      Bugsnag.notify(deleteError);
      enqueueSnackbar('Delete Failed', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData, deleteError]);

  // Loading actions
  if (loading && policyList.length === 0) {
    return <LoadingScreen />;
  }
  // Error actions
  if (error) {
    console.error('Error:', error);
    Bugsnag.notify(error);
    return <ErrorPage />;
  }

  // handlers
  const handleRequestSort = (_event: $TSFixMe, property: $TSFixMe) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: $TSFixMe, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: $TSFixMe) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: $TSFixMe) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const handleDeletePolicy = (id: $TSFixMe) => {
    deletePolicy({
      variables: {
        policyId: id,
        token: token,
      },
      update: (cache, { data }) => {
        let policies: $TSFixMe;
        try {
          policies = cache.readQuery({ query: GET_ALL_POLICIES });
        } catch (e) {
          console.error('Failed to read policies from cache', e);
        }

        if (policies && policies?.allPolicies) {
          try {
            cache.writeQuery({
              query: GET_ALL_POLICIES,
              data: {
                allPolicies: policies.allPolicies.filter(
                  (policy: $TSFixMe) =>
                    // @ts-expect-error
                    policy.id !== data.deletePolicy.deletedId,
                ),
              },
            });
          } catch (e) {
            console.error('Failed to write to policy list in cache', e);
          }
        }
      },
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - policyList.length) : 0;

  const filteredPolicies = applySortFilter(
    policyList,
    getComparator(order, orderBy),
    filterName,
  );

  const isPolicyNotFound = filteredPolicies.length === 0;

  return (
    <Page title='Database: Policy List | Manager'>
      <Container maxWidth={themeStretch ? false : 'lg'} data-testid='policy'>
        {token && !hasViewPolicyPermissions ? (
          <InsufficientPermissionsErrorPage />
        ) : (
          <>
            <HeaderBreadcrumbs
              heading='Policy List'
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
                { name: 'Policy List' },
              ]}
              action={
                <Button
                  variant='contained'
                  component={RouterLink}
                  to={'/insurtech/manager/database/policy/create'}
                  disabled={!hasPolicyEditPermissions}
                  startIcon={<Icon icon={plusFill} />}
                >
                  New Policy
                </Button>
              }
            />
            <Card>
              <PolicyListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
                isLoading={hiddenQueryLoading}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <PolicyListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={policyList.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredPolicies
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => {
                          const { id, displayName, internalName, isDeletable } =
                            row;

                          return (
                            <TableRow hover key={id} tabIndex={-1}>
                              <TableCell align='left'>{displayName}</TableCell>
                              <TableCell align='left'>{internalName}</TableCell>
                              <TableCell align='right'>
                                <PolicyMoreMenu
                                  policyId={id}
                                  handleDelete={() => handleDeletePolicy(id)}
                                  isDeletable={isDeletable}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isPolicyNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component='div'
                count={policyList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </Card>
          </>
        )}
      </Container>
    </Page>
  );
}
