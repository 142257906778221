import { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import { v4 as uuid4 } from 'uuid';
import Bugsnag from '@bugsnag/browser';

import {
  QuoteWizardForm,
  QuoteWizardAnsweredForm,
  QuoteWizardQuestionInstance,
} from '../../QuoteWizard/classes';
import {
  IframeParams,
  ORPHANED_ANSWERS_KEY,
  useSendContactInfoApplication,
} from '../../../hooks';
import { formStateToAnswerInstances } from '../../QuoteWizard/QuoteForms/utility';
import { hybridAnswers } from '../../util/Quote';
// types and classes
import type { FormApi } from 'informed';
import { BusinessType } from '../../../Typescript';
import { IMPERATIVE_NAVIGATE_ID } from '../constants';
import { errorify } from '../../../util';

const fakeGenericBusinessLine = {
  id: uuid4(),
  internalName: 'generic',
  displayName: 'Generic',
};

interface UseHandleIframeParameters extends IframeParams {
  formApi: FormApi;
  questionInstances: Array<QuoteWizardQuestionInstance>;
  generateNavigationDestination: (params: IframeParams) => string;
  businessLine?: BusinessType;
  useImperativeNavigateLink: boolean; // should the navigation use an existing imperative navigation link on the page?
}
type OverrideParameters = Pick<
  UseHandleIframeParameters,
  'toBusiness' | 'businessId' | 'businessLine'
>;
export const useHandleIframe = ({
  formApi,
  toBusiness,
  businessId,
  policies,
  questionInstances,
  generateNavigationDestination,
  businessLine,
  useImperativeNavigateLink,
}: UseHandleIframeParameters): (({
  toBusiness,
  businessId,
}: OverrideParameters) => Promise<void>) => {
  //console.log('in hook with', { businessLine });
  const navigate = useNavigate();
  const sendContactInfoApplication = useSendContactInfoApplication({
    storeExistingQuoteUuidOnComplete: true,
  });

  const externalToBusiness = toBusiness;
  const externalBusinessId = businessId;
  const defaultBusiness = businessLine || fakeGenericBusinessLine;
  const handle = useCallback(
    async (
      {
        toBusiness = externalToBusiness,
        businessId = externalBusinessId,
        businessLine = defaultBusiness,
      }: OverrideParameters = {
        toBusiness: externalToBusiness,
        businessId: externalBusinessId,
        businessLine: defaultBusiness,
      },
    ) => {
      try {
        //console.log('in handleIframe function with ', { businessLine });
        formApi.validate();
        const state = formApi.getState();
        if (Object.keys(state.errors).length !== 0) {
          return;
        }
        const { values } = state;
        //console.log({ values });
        const answerInstances = formStateToAnswerInstances(
          values,
          questionInstances,
        );
        const fakeForm = new QuoteWizardForm({
          id: uuid4(),
          businessLine,
          questionInstances,
          qualifyingQuestion: null,
          policy: null,
        });
        const fakeAnsweredForm = new QuoteWizardAnsweredForm({
          form: fakeForm,
          answers: answerInstances,
          inProgress: true,
        });
        window.localStorage.setItem(
          ORPHANED_ANSWERS_KEY,
          JSON.stringify(answerInstances),
        );
        //console.log('About to send contact info application')
        sendContactInfoApplication({
          formsWithAnswers: hybridAnswers([fakeAnsweredForm], [fakeForm]),
          businessType:
            businessLine === fakeGenericBusinessLine ? undefined : businessLine,
          // additionalInformation: 'test additional information',
        });
        const navigationDestination = generateNavigationDestination({
          businessId,
          policies: policies || [],
          toBusiness: toBusiness === true,
        });
        //console.log(`About to navigate to ${navigationDestination}`);
        if (useImperativeNavigateLink) {
          const a = document.getElementById(IMPERATIVE_NAVIGATE_ID);
          if (a) {
            // @ts-expect-error
            a.href = navigationDestination;
            a.click();
          } else {
            await navigate(navigationDestination);
          }
        } else {
          await navigate(navigationDestination);
        }
      } catch (e) {
        console.error('Error in iframe handling function:', e);
        Bugsnag.notify(errorify(e));
      }
    },
    [
      formApi,
      toBusiness,
      policies,
      questionInstances,
      navigate,
      sendContactInfoApplication,
      useImperativeNavigateLink,
      defaultBusiness,
      externalBusinessId,
      generateNavigationDestination,
      externalToBusiness,
    ],
  );

  return handle;
};
