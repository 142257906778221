import { Box } from '@mui/material';

import { Content, OptaAddress } from './OptaCommonClasses';
import { dateStringToLocaleString, returnSelfOrNoInfo } from './classUtility';
import { NO_INFO_STRING } from '../constants';

import { CarouselBasic4 } from '../../../../ManagementPanel/components/carousel';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../../../util';

export class DwellingProtectedGrade {
  // @ts-expect-error
  constructor(GradeInput, WaterSystemInput) {
    // @ts-expect-error
    this.Grade = GradeInput;
    // @ts-expect-error
    this.WaterSystem = WaterSystemInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new DwellingProtectedGrade(response.Grade, response.WaterSystem);
  }
  toString() {
    // @ts-expect-error
    if (this.Grade) {
      return (
        // @ts-expect-error
        ` ${this.Grade}` +
        // @ts-expect-error
        (this.WaterSystem ? ` (${this.WaterSystem} Water System)` : '')
      );
    } else {
      return NO_INFO_STRING;
    }
  }
}

export class DwellingGrades {
  constructor(
    // @ts-expect-error
    HydrantProtectedGradeInput,
    // @ts-expect-error
    FirehallProtectedGradeInput,
    // @ts-expect-error
    AllOtherAreasGradeInput,
  ) {
    // @ts-expect-error
    this.HydrantProtectedGrade =
      HydrantProtectedGradeInput && HydrantProtectedGradeInput.length > 0
        ? // @ts-expect-error
          HydrantProtectedGradeInput.map((input) =>
            DwellingProtectedGrade.createFromJson(input),
          )
        : [];
    // @ts-expect-error
    this.FirehallProtectedGrade =
      FirehallProtectedGradeInput && FirehallProtectedGradeInput.length > 0
        ? // @ts-expect-error
          FirehallProtectedGradeInput.map((input) =>
            DwellingProtectedGrade.createFromJson(input),
          )
        : [];
    // @ts-expect-error
    this.AllOtherAreasGrade =
      AllOtherAreasGradeInput && AllOtherAreasGradeInput.length > 0
        ? // @ts-expect-error
          AllOtherAreasGradeInput.map((input) =>
            DwellingProtectedGrade.createFromJson(input),
          )
        : [];
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new DwellingGrades(
      response.HydrantProtectedGrade,
      response.FirehallProtectedGrade,
      response.AllOtherAreasGrade,
    );
  }
  displayAsJsx() {
    return (
      <>
        <ul>
          <li key='hydrant'>
            Hydrant Protection Grade:{' '}
            {
              // @ts-expect-error
              this.HydrantProtectedGrade &&
              // @ts-expect-error
              this.HydrantProtectedGrade.length > 0 ? (
                // @ts-expect-error
                this.HydrantProtectedGrade.length === 1 ? (
                  // @ts-expect-error
                  this.HydrantProtectedGrade.toString()
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      this.HydrantProtectedGrade.map(
                        // @ts-expect-error
                        (protectedGrade, index) => (
                          <li key={'hydrant_' + index}>
                            {protectedGrade.toString()}
                          </li>
                        ),
                      )
                    }
                  </ul>
                )
              ) : (
                NO_INFO_STRING
              )
            }
          </li>
          <li key='firehall'>
            Firehall Protection Grade:{' '}
            {
              // @ts-expect-error
              this.FirehallProtectedGrade &&
              // @ts-expect-error
              this.FirehallProtectedGrade.length > 0 ? (
                // @ts-expect-error
                this.FirehallProtectedGrade.length === 1 ? (
                  // @ts-expect-error
                  this.FirehallProtectedGrade.toString()
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      this.FirehallProtectedGrade.map(
                        // @ts-expect-error
                        (protectedGrade, index) => (
                          <li key={'firehall_' + index}>
                            {protectedGrade.toString()}
                          </li>
                        ),
                      )
                    }
                  </ul>
                )
              ) : (
                NO_INFO_STRING
              )
            }
          </li>
          <li key='allOtherAreas'>
            All Other Areas Protection Grade:{' '}
            {
              // @ts-expect-error
              this.AllOtherAreasGrade && this.AllOtherAreasGrade.length > 0 ? (
                // @ts-expect-error
                this.AllOtherAreasGrade.length === 1 ? (
                  // @ts-expect-error
                  this.AllOtherAreasGrade.toString()
                ) : (
                  <ul>
                    {
                      // @ts-expect-error
                      this.AllOtherAreasGrade.map((protectedGrade, index) => (
                        <li key={'allOtherAreas_' + index}>
                          {protectedGrade.toString()}
                        </li>
                      ))
                    }
                  </ul>
                )
              ) : (
                NO_INFO_STRING
              )
            }
          </li>
        </ul>
      </>
    );
  }
}

export class ProtectedGradeTerritories {
  // @ts-expect-error
  constructor(CommercialInput, DwellingInput) {
    // @ts-expect-error
    this.Commercial = CommercialInput;
    // @ts-expect-error
    this.Dwelling = DwellingInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new ProtectedGradeTerritories(
      response.Commercial,
      response.Dwelling,
    );
  }
  toString() {
    // @ts-expect-error
    return `Commercial Grade: ${this.Commercial}; Dwelling Grade: ${this.Dwelling}`;
  }
  displayAsJsx() {
    return (
      <ul>
        <li key='commercialGrade'>
          Commercial Grade:{' '}
          {
            // @ts-expect-error
            this.Commercial
          }
        </li>
        <li key='dwellingGrade'>
          Dwelling Grade:{' '}
          {
            // @ts-expect-error
            this.Dwelling
          }
        </li>
      </ul>
    );
  }
}
export class FirefightersPerFireStation {
  // @ts-expect-error
  constructor(FullTimeInput, VolunteerInput) {
    // @ts-expect-error
    this.FullTime = FullTimeInput;
    // @ts-expect-error
    this.Volunteer = VolunteerInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new FirefightersPerFireStation(
      response.FullTime,
      response.Volunteer,
    );
  }
  toString() {
    // @ts-expect-error
    return `${this.FullTime === 0 ? 'No' : this.FullTime} Full Time, ${
      // @ts-expect-error
      this.Volunteer === 0 ? 'No' : this.Volunteer
    } Volunteer`;
  }
}
export class Firehall {
  constructor(
    // @ts-expect-error
    NameInput,
    // @ts-expect-error
    AddressInput,
    // @ts-expect-error
    RoadDistanceKilometresInput,
    // @ts-expect-error
    GoverningAgencyInput,
    // @ts-expect-error
    ProtectionGradeTerritoriesInput,
    // @ts-expect-error
    FirefightersPerFireStationInput,
    // @ts-expect-error
    TankerCapacityImperialGallonsInput,
    // @ts-expect-error
    PumperFlowImperialGallonsPerMinuteInput,
    // @ts-expect-error
    SuperiorTankerServiceAccreditationDateInput,
    // @ts-expect-error
    GradingDateInput,
  ) {
    // @ts-expect-error
    this.Name = NameInput;
    // @ts-expect-error
    this.Address = AddressInput;
    // @ts-expect-error
    this.RoadDistanceKilometres = RoadDistanceKilometresInput;
    // @ts-expect-error
    this.GoverningAgency = GoverningAgencyInput;
    // @ts-expect-error
    this.ProtectionGradeTerritories = ProtectedGradeTerritories.createFromJson(
      ProtectionGradeTerritoriesInput,
    );
    // @ts-expect-error
    this.FirefightersPerFireStation = FirefightersPerFireStation.createFromJson(
      FirefightersPerFireStationInput,
    );
    // @ts-expect-error
    this.TankerCapacityImperialGallons = TankerCapacityImperialGallonsInput;
    // @ts-expect-error
    this.PumperFlowImperialGallonsPerMinute =
      PumperFlowImperialGallonsPerMinuteInput;
    // @ts-expect-error
    this.SuperiorTankerServiceAccreditationDate =
      SuperiorTankerServiceAccreditationDateInput;
    // @ts-expect-error
    this.GradingDate = GradingDateInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new Firehall(
      response.Name,
      response.Address,
      response.RoadDistanceKilometres,
      response.GoverningAgency,
      response.ProtectionGradeTerritories,
      response.FirefightersPerFireStation,
      response.TankerCapacityImperialGallons,
      response.PumperFlowImperialGallonsPerMinute,
      response.SuperiorTankerServiceAccreditationDate,
      response.GradingDate,
    );
  }
  displayAsJsx() {
    let addressUrl;
    try {
      // @ts-expect-error
      if (this.Address) {
        addressUrl = encodeURI(
          // @ts-expect-error
          `https://www.google.com/maps/search/?api=1&query=${this.Address}`,
        );
      }
    } catch (e) {
      Bugsnag.notify(errorify(e));
      Bugsnag.notify(errorify(e));
      console.error('Error creating maps url to firehall address:', e);
    }
    return (
      <>
        <ul>
          <li key='name'>
            Name:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.Name)
            }
          </li>
          <li key='address'>
            Address:{' '}
            {
              // @ts-expect-error
              !this.Address ? (
                NO_INFO_STRING
              ) : addressUrl ? (
                <a href={addressUrl} target='_blank' rel='noopener noreferrer'>
                  {
                    // @ts-expect-error
                    this.Address
                  }
                </a>
              ) : (
                // @ts-expect-error
                this.Address
              )
            }
          </li>
          <li key='roadDistance'>
            Road Distance:{' '}
            {
              // @ts-expect-error
              this.RoadDistanceKilometres
                ? // @ts-expect-error
                  this.RoadDistanceKilometres + ' km'
                : NO_INFO_STRING
            }
          </li>
          <li key='governingAgency'>
            Governing Agency:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.GoverningAgency)
            }
          </li>
          <li key='protectedGradesTerritory'>
            Protection Grade Territories:{' '}
            {
              // @ts-expect-error
              this.ProtectionGradeTerritories
                ? // @ts-expect-error
                  this.ProtectionGradeTerritories.displayAsJsx()
                : NO_INFO_STRING
            }
          </li>
          <li key='firefightersPerFireState'>
            Firefighters per Station:{' '}
            {
              // @ts-expect-error
              this.FirefightersPerFireStation
                ? // @ts-expect-error
                  this.FirefightersPerFireStation.toString()
                : NO_INFO_STRING
            }
          </li>
          <li key='tankerCapacityImperialGallons'>
            Tanker Capacity:{' '}
            {
              // @ts-expect-error
              this.TankerCapacityImperialGallons
                ? // @ts-expect-error
                  this.TankerCapacityImperialGallons + ' imp gal'
                : NO_INFO_STRING
            }
          </li>
          <li key='pumperFlowImperialGallonsPerMinute'>
            Pumper Flow Rate:{' '}
            {
              // @ts-expect-error
              this.PumperFlowImperialGallonsPerMinute
                ? // @ts-expect-error
                  this.PumperFlowImperialGallonsPerMinute + ' imp gal / min'
                : NO_INFO_STRING
            }
          </li>
          <li key='superiorTankerServiceAccreditationDate'>
            Superior Tanker Service Accreditation Date:{' '}
            {
              // @ts-expect-error
              this.SuperiorTankerServiceAccreditationDate
                ? dateStringToLocaleString(
                    // @ts-expect-error
                    this.SuperiorTankerServiceAccreditationDate,
                  )
                : NO_INFO_STRING
            }
          </li>
          <li key='gradingDate'>
            Grading Date:{' '}
            {
              // @ts-expect-error
              this.GradingDate
                ? // @ts-expect-error
                  dateStringToLocaleString(this.GradingDate)
                : NO_INFO_STRING
            }
          </li>
        </ul>
      </>
    );
  }
}

export class Dwelling {
  constructor(
    // @ts-expect-error
    RespondingFirehallInput,
    // @ts-expect-error
    DwellingGradesInput,
    // @ts-expect-error
    SearchMessageInput,
  ) {
    // @ts-expect-error
    this.RespondingFirehall = Firehall.createFromJson(RespondingFirehallInput);
    // @ts-expect-error
    this.DwellingGrades = DwellingGrades.createFromJson(DwellingGradesInput);
    // @ts-expect-error
    this.SearchMessage = SearchMessageInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new Dwelling(
      response.RespondingFirehall,
      response.DwellingGrades,
      response.SearchMessage,
    );
  }
  displayAsJsx() {
    return (
      <>
        {
          // @ts-expect-error
          this.DwellingGrades
            ? // @ts-expect-error
              this.DwellingGrades.displayAsJsx()
            : NO_INFO_STRING
        }
      </>
    );
  }
}

export class RiskSCORAdjustment {
  // @ts-expect-error
  constructor(AdjustmentTypeInput, AdjustmentInput) {
    // @ts-expect-error
    this.AdjustmentType = AdjustmentTypeInput;
    // @ts-expect-error
    this.Adjustment = AdjustmentInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new RiskSCORAdjustment(response.AdjustmentType, response.Adjustment);
  }
  toString() {
    // @ts-expect-error
    if (this.Adjustment && this.AdjustmentType) {
      return (
        // @ts-expect-error
        this.Adjustment +
        // @ts-expect-error
        (this.AdjustmentType ? ` (${this.AdjustmentType})` : '')
      );
      // @ts-expect-error
    } else if (this.Adjustment) {
      // @ts-expect-error
      return this.Adjustment;
      // @ts-expect-error
    } else if (this.AdjustmentType) {
      // @ts-expect-error
      return `Type: ${this.AdjustmentType}`;
    } else {
      return NO_INFO_STRING;
    }
  }
}

export class RiskSCORDetail {
  // @ts-expect-error
  constructor(RiskSCORInput, TypeInput, AdjustmentInput) {
    // @ts-expect-error
    this.RiskSCOR = RiskSCORInput;
    // @ts-expect-error
    this.Type = TypeInput;
    // @ts-expect-error
    this.Adjustment =
      AdjustmentInput && AdjustmentInput.length > 0
        ? // @ts-expect-error
          AdjustmentInput.map((input) =>
            RiskSCORAdjustment.createFromJson(input),
          )
        : [];
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new RiskSCORDetail(
      response.RiskSCOR,
      response.Type,
      response.Adjustment,
    );
  }
  displayAsJsx() {
    return (
      <>
        <li key='riskSCOR'>
          Risk SCOR:{' '}
          {
            // @ts-expect-error
            this.RiskSCOR
              ? // @ts-expect-error
                this.RiskSCOR + (this.Type ? ` (${this.Type})` : '')
              : NO_INFO_STRING
          }
          <ul>
            <li key='adjustment'>
              {
                // @ts-expect-error
                !this.Adjustment || this.Adjustment.length === 0 ? (
                  'Adjustments: None'
                ) : (
                  <>
                    Adjustments:
                    <ul>
                      {
                        // @ts-expect-error
                        this.Adjustment.map((adjustment, index) => (
                          <li key={index}>{adjustment.toString()}</li>
                        ))
                      }
                    </ul>
                  </>
                )
              }
            </li>
          </ul>
        </li>
      </>
    );
  }
}

export class CommercialGrade {
  constructor(
    // @ts-expect-error
    PublicFireProtectionClassificationInput,
    // @ts-expect-error
    RiskSCORDetailInput,
    // @ts-expect-error
    WaterSystemInput,
  ) {
    // @ts-expect-error
    this.PublicFireProtectionClassification =
      PublicFireProtectionClassificationInput;
    try {
      // @ts-expect-error
      this.RiskSCORDetail =
        RiskSCORDetailInput && RiskSCORDetailInput.length > 0
          ? // @ts-expect-error
            RiskSCORDetailInput.map((riskSCORDetail) =>
              RiskSCORDetail.createFromJson(riskSCORDetail),
            )
          : [];
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error setting RiskSCORDetail:', e);
    }
    // @ts-expect-error
    this.WaterSystem = WaterSystemInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new CommercialGrade(
      response.PublicFireProtectionClassification,
      response.RiskSCORDetail,
      response.WaterSystem,
    );
  }
  displayAsJsx({ key } = { key: '0' }) {
    try {
      return (
        <ul key={key}>
          <li key='publicFireProtectionClassification'>
            Public Fire Protection Classification:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.PublicFireProtectionClassification)
            }
          </li>
          {
            // @ts-expect-error
            this.RiskSCORDetail && this.RiskSCORDetail.length > 0 ? (
              // @ts-expect-error
              this.RiskSCORDetail.length === 1 ? (
                // @ts-expect-error
                this.RiskSCORDetail[0].displayAsJsx()
              ) : (
                <li key='riskSCORDetail'>
                  Risk SCOR:{' '}
                  <ul>
                    {
                      // @ts-expect-error
                      this.RiskSCORDetail.map((detail) => detail.displayAsJsx())
                    }
                  </ul>
                </li>
              )
            ) : (
              <li key='riskSCORDetail'>Risk SCOR: {NO_INFO_STRING}</li>
            )
          }
          <li key='waterSystem'>
            Water System:{' '}
            {
              // @ts-expect-error
              returnSelfOrNoInfo(this.WaterSystem)
            }
          </li>
        </ul>
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(`Error generating JSX for CommercialGrade:`, e);
      return null;
    }
  }
}

export class Commercial {
  constructor(
    // @ts-expect-error
    CommercialGradeInput,
    // @ts-expect-error
    RespondingFirehallInput,
    // @ts-expect-error
    SearchMessageInput,
  ) {
    // @ts-expect-error
    this.CommercialGrade =
      CommercialGradeInput && CommercialGradeInput.length > 0
        ? // @ts-expect-error
          CommercialGradeInput.map((input) =>
            CommercialGrade.createFromJson(input),
          )
        : [];
    // @ts-expect-error
    this.RespondingFirehall = Firehall.createFromJson(RespondingFirehallInput);
    // @ts-expect-error
    this.SearchMessage = SearchMessageInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new Commercial(
      response.CommercialGrade,
      response.RespondingFirehall,
      response.SearchMessage,
    );
  }
  displayAsJsx() {
    return (
      <>
        {
          // @ts-expect-error
          this.CommercialGrade && this.CommercialGrade.length > 0
            ? // @ts-expect-error
              this.CommercialGrade.map((commercialGrade) =>
                commercialGrade.displayAsJsx(),
              )
            : NO_INFO_STRING
        }
      </>
    );
  }
}
export class EarthquakeCode {
  // @ts-expect-error
  constructor(DescriptionInput, SystemNameInput, ZoneInput) {
    // @ts-expect-error
    this.Description = DescriptionInput;
    // @ts-expect-error
    this.SystemName = SystemNameInput;
    // @ts-expect-error
    this.Zone = ZoneInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new EarthquakeCode(
      response.Description,
      response.SystemName,
      response.Zone,
    );
  }
  toString() {
    // @ts-expect-error
    return `Description: ${this.Description || 'None'}; System Name: ${
      // @ts-expect-error
      this.SystemName || 'None'
      // @ts-expect-error
    }; Zone: ${this.Zone || 'None'}`;
  }
  displayAsJsx() {
    return (
      <li
        key={
          // @ts-expect-error
          'earthquake_' + this.SystemName
        }
      >
        {
          // @ts-expect-error
          this.SystemName
        }{' '}
        {
          // @ts-expect-error
          this.Zone || this.Description ? (
            <ul>
              <li key='zone'>
                Zone:{' '}
                {
                  // @ts-expect-error
                  returnSelfOrNoInfo(this.Zone)
                }
              </li>
              <li key='description'>
                Description:{' '}
                {
                  // @ts-expect-error
                  returnSelfOrNoInfo(this.Description)
                }
              </li>
            </ul>
          ) : null
        }
      </li>
    );
  }
}
export class EarthquakeCodes {
  // @ts-expect-error
  constructor(SearchMessageInput, ...EarthquakeCodesInput) {
    // @ts-expect-error
    this.SearchMessage = SearchMessageInput;

    if (EarthquakeCodesInput.length === 0) {
      // @ts-expect-error
      this.EarthquakeCode = [];
    } else {
      // @ts-expect-error
      this.EarthquakeCode = [...EarthquakeCodesInput];
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new EarthquakeCodes(
      response.SearchMessage,
      // @ts-expect-error
      ...response.EarthquakeCode.map((input) =>
        EarthquakeCode.createFromJson(input),
      ),
    );
  }
  displayAsJsx() {
    return (
      <>
        {
          // @ts-expect-error
          this.EarthquakeCode.length === 0 && !this.SearchMessage ? (
            <p>{NO_INFO_STRING}</p>
          ) : (
            <ul>
              {
                // @ts-expect-error
                this.EarthquakeCode.map((earthquakeCode) =>
                  earthquakeCode.displayAsJsx(),
                )
              }
              {
                // @ts-expect-error
                this.SearchMessage ? (
                  <li key='searchMessage'>
                    Search Message:{' '}
                    {
                      // @ts-expect-error
                      this.SearchMessage
                    }
                  </li>
                ) : null
              }
            </ul>
          )
        }
      </>
    );
  }
}

export class HydrantCount {
  // @ts-expect-error
  constructor(NumberOfHydrantsInput, OwnershipInput, RadiusMetresInput) {
    // @ts-expect-error
    this.NumberOfHydrants = NumberOfHydrantsInput;
    // @ts-expect-error
    this.Ownership = OwnershipInput;
    // @ts-expect-error
    this.RadiusMetres = RadiusMetresInput;
  }
  toString() {
    // @ts-expect-error
    return `${this.Ownership} (${this.RadiusMetres} m): ${
      // @ts-expect-error
      this.NumberOfHydrants === 0 ? 'None' : this.NumberOfHydrants
    }`;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new HydrantCount(
      response.NumberOfHydrants,
      response.Ownership,
      response.RadiusMetres,
    );
  }
}

export class HydrantCounts {
  // @ts-expect-error
  constructor(SearchMessageInput, ...HydrantCountInput) {
    // @ts-expect-error
    this.SearchMessage = SearchMessageInput;

    if (!HydrantCountInput) {
      // @ts-expect-error
      this.HydrantCount = [];
    } else {
      // @ts-expect-error
      this.HydrantCount = [...HydrantCountInput];
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }

    return new HydrantCounts(
      response.SearchMessage,
      ...(response.HydrantCount
        ? // @ts-expect-error
          response.HydrantCount.map((input) =>
            HydrantCount.createFromJson(input),
          )
        : []),
    );
  }
  displayAsJsx() {
    return (
      <>
        {
          // @ts-expect-error
          this.SearchMessage ||
          // @ts-expect-error
          (this.HydrantCount && this.HydrantCount.length > 0) ? (
            <ul>
              {
                // @ts-expect-error
                this.HydrantCount.map((hydrantCount, index) => (
                  <li key={index}>{hydrantCount.toString()}</li>
                ))
              }
              {
                // @ts-expect-error
                this.SearchMessage ? (
                  <li>
                    Search Message:{' '}
                    {
                      // @ts-expect-error
                      this.SearchMessage
                    }
                  </li>
                ) : null
              }
            </ul>
          ) : (
            <p>{NO_INFO_STRING}</p>
          )
        }
      </>
    );
  }
}

export class FusMap {
  // @ts-expect-error
  constructor(BusinessInput, FusContentInput) {
    // @ts-expect-error
    this.Business = BusinessInput;
    // @ts-expect-error
    this.FusContent = Content.createFromJson(FusContentInput);
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new FusMap(response.Business, response.FusContent);
  }
}

export class FusMaps {
  // @ts-expect-error
  constructor(SearchMessageInput, ...FusMapInput) {
    // @ts-expect-error
    this.SearchMessage = SearchMessageInput;
    if (FusMapInput.length === 0) {
      // @ts-expect-error
      this.FusMap = [];
    } else {
      // @ts-expect-error
      this.FusMap = FusMapInput.map((mapInput) =>
        FusMap.createFromJson(mapInput),
      );
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      return null;
    }
    return new FusMaps(response.SearchMessage, ...response.FusMap);
  }

  displayAsJsx() {
    // @ts-expect-error
    if (this.FusMap.length === 0) {
      return <p>No FUS maps to display</p>;
    }

    // @ts-expect-error
    const carouselData = this.FusMap.map((fusMap, index) => {
      const title = fusMap.Business ? fusMap.Business : '';
      return {
        id: index,
        title: title,
        image: fusMap.FusContent.ContentURL,
        desciption: title,
      };
    });

    return (
      <>
        {
          // @ts-expect-error
          this.FusMap.length === 0 ? (
            <p>No FUS maps to display</p>
          ) : (
            <Box sx={{ width: '31vw' }}>
              <CarouselBasic4 images={carouselData} />
            </Box>
          )
        }
        {
          // @ts-expect-error
          this.SearchMessage ? (
            // @ts-expect-error
            <p>Search Message: {this.SearchMessage} </p>
          ) : null
        }
      </>
    );
  }
}

export class FusProduct {
  constructor(
    // @ts-expect-error
    Address,
    // @ts-expect-error
    DwellingInput,
    // @ts-expect-error
    CommercialInput,
    // @ts-expect-error
    EarthquakeCodesInput,
    // @ts-expect-error
    HydrantCountsInput,
    // @ts-expect-error
    FusMapsInput,
  ) {
    // @ts-expect-error
    this.Address = OptaAddress.createFromJson(Address);
    // @ts-expect-error
    this.Dwelling = Dwelling.createFromJson(DwellingInput);
    // @ts-expect-error
    this.Commercial = Commercial.createFromJson(CommercialInput);
    // @ts-expect-error
    this.EarthquakeCodes = EarthquakeCodes.createFromJson(EarthquakeCodesInput);
    // @ts-expect-error
    this.HydrantCounts = HydrantCounts.createFromJson(HydrantCountsInput);
    // @ts-expect-error
    this.FusMaps = FusMaps.createFromJson(FusMapsInput);
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response) {
      // @ts-expect-error
      return new FusProduct();
    }
    return new FusProduct(
      response.Address,
      response.Dwelling,
      response.Commercial,
      response.EarthquakeCodes,
      response.HydrantCounts,
      response.FusMaps,
    );
  }
}

export class FusProductsHighlights {
  // @ts-expect-error
  constructor(fusProduct) {
    // set the defaults; will be overwritten as appropriate
    // @ts-expect-error
    this.PublicFireProtectionClassification = null;
    // @ts-expect-error
    this.RiskSCOR = null;
    // @ts-expect-error
    this.HydrantProtectedGrade = null;
    // @ts-expect-error
    this.FirehallProtectedGrade = null;
    // @ts-expect-error
    this.AllOtherAreasGrade = null;

    if (!fusProduct) {
      return;
    }
    const commercialGrade =
      fusProduct.Commercial &&
      fusProduct.Commercial.CommercialGrade &&
      fusProduct.Commercial.CommercialGrade.length > 0
        ? fusProduct.Commercial.CommercialGrade[0]
        : null;
    // get the PublicFireProtectionClassification
    if (commercialGrade && commercialGrade.PublicFireProtectionClassification) {
      // @ts-expect-error
      this.PublicFireProtectionClassification =
        commercialGrade.PublicFireProtectionClassification;
    }
    // get the RiskSCOR
    const riskSCORInfo =
      commercialGrade &&
      commercialGrade.RiskSCORDetail &&
      commercialGrade.RiskSCORDetail.length > 0
        ? commercialGrade.RiskSCORDetail[0]
        : null;
    if (riskSCORInfo && riskSCORInfo.RiskSCOR) {
      // @ts-expect-error
      this.RiskSCOR = `${riskSCORInfo.RiskSCOR}${
        riskSCORInfo.Type ? ` (${riskSCORInfo.Type})` : ''
      }`;
    }

    const dwellingGradesInfo =
      fusProduct && fusProduct.Dwelling && fusProduct.Dwelling.DwellingGrades
        ? fusProduct.Dwelling.DwellingGrades
        : null;
    // get the HydrantProtectedGrade
    const hydrantProtectedGradeInfo =
      dwellingGradesInfo &&
      dwellingGradesInfo.HydrantProtectedGrade &&
      dwellingGradesInfo.HydrantProtectedGrade.length > 0
        ? dwellingGradesInfo.HydrantProtectedGrade[0]
        : null;
    if (hydrantProtectedGradeInfo && hydrantProtectedGradeInfo.Grade) {
      const grade = hydrantProtectedGradeInfo.Grade;
      const waterSystemString = hydrantProtectedGradeInfo.WaterSystem
        ? ` (${hydrantProtectedGradeInfo.WaterSystem} Water System)`
        : '';
      // @ts-expect-error
      this.HydrantProtectedGrade = grade + waterSystemString;
    }

    // get the FirehallProtectedGrade
    const firehallProtectedGradeInfo =
      dwellingGradesInfo &&
      dwellingGradesInfo.FirehallProtectedGrade &&
      dwellingGradesInfo.FirehallProtectedGrade.length > 0
        ? dwellingGradesInfo.FirehallProtectedGrade[0]
        : null;

    if (firehallProtectedGradeInfo && firehallProtectedGradeInfo.Grade) {
      const grade = firehallProtectedGradeInfo.Grade;
      const waterSystemString = firehallProtectedGradeInfo.WaterSystem
        ? ` (${firehallProtectedGradeInfo.WaterSystem} Water System)`
        : '';
      // @ts-expect-error
      this.FirehallProtectedGrade = grade + waterSystemString;
    }

    // get the AllOtherAreasGrade
    const allOtherAreasGradeInfo =
      dwellingGradesInfo &&
      dwellingGradesInfo.AllOtherAreasGrade &&
      dwellingGradesInfo.AllOtherAreasGrade.length > 0
        ? dwellingGradesInfo.AllOtherAreasGrade[0]
        : null;
    if (allOtherAreasGradeInfo && allOtherAreasGradeInfo.Grade) {
      const grade = allOtherAreasGradeInfo.Grade;
      const waterSystemString = allOtherAreasGradeInfo.WaterSystem
        ? ` (${allOtherAreasGradeInfo.WaterSystem} Water System)`
        : '';
      // @ts-expect-error
      this.AllOtherAreasGrade = grade + waterSystemString;
    }
  }
}
