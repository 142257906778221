import { Modal } from '@mui/material';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import QuoteFormDisplay from './DisplayPreview';
import { useTheme } from '@mui/material/styles';

// types and classes
import type { Theme } from '@mui/material/styles';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const generateStyle = (theme: Theme) =>
  createStyles({
    formModal: {
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    formModalContents: {
      maxHeight: '90vh',
      maxWidth: '60rem',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 8,
      //padding: theme.spacing(3),
      position: 'relative',
      overflow: 'auto',
    },
  });

interface FormPreviewModalProps
  extends WithStyles<ReturnType<typeof generateStyle>> {
  form: string;
  questionInstance: Array<$TSFixMe>;
  navigate: $TSFixMe;
  handleClose: $TSFixMe;
  open: $TSFixMe;
  [k: string]: $TSFixMe;
}
const FormPreviewModal = ({
  form,
  questionInstances,
  classes,
  // navigate,
  handleClose,
  open,
}: // ...otherProps
FormPreviewModalProps) => {
  const theme = useTheme();
  return (
    <Modal className={classes.formModal} open={open} onClose={handleClose}>
      <div className={classes.formModalContents} data-testid='modal-contents'>
        <IconButton
          sx={{
            position: 'absolute',
            right: theme.spacing(3),
            top: theme.spacing(3),
            padding: 0,
          }}
          onClick={() => handleClose()}
          size='large'
        >
          <ClearIcon />
        </IconButton>
        <QuoteFormDisplay
          formTitle={form}
          questionInstances={questionInstances}
        />
      </div>
    </Modal>
  );
};

export default withStyles(generateStyle)(FormPreviewModal);
