import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

type ErrorDialogProps = {
  messages: Array<string>;
  open: boolean;
  onClose: (event?: $TSFixMe, reason?: $TSFixMe) => void;
};
/**
 * Error dialog to display any errors from validating a form
 * @param messages - A list of the error messages - to be displayed to the user
 * @param open - whether the dialog is open
 * @param onClose - function to run on dialog close
 */
export const ErrorDialog = ({
  messages = [],
  open,
  onClose,
}: ErrorDialogProps) => {
  if (messages.length === 0) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      data-testid='form-errors'
    >
      <DialogTitle id='alert-dialog-title'>{'Form Error!'}</DialogTitle>
      <DialogContent>
        {messages.length === 1 ? (
          <DialogContentText id='alert-dialog-description'>
            {messages[0]}
          </DialogContentText>
        ) : (
          <>
            <DialogContentText>
              The following errors were found in the form:
            </DialogContentText>
            <List>
              {messages.map((message) => (
                <ListItem key={message}>
                  <ListItemText
                    primary={message}
                    primaryTypographyProps={{
                      color: 'textSecondary',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
