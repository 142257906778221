export const vagoQuoteListFormatMoney = (value?: number | null) => {
  if (value === null || value === undefined) {
    return null;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};
