import { useEffect, useState } from 'react';
import { GeocodingError } from './errors';

// for all the google stuff
import { useGoogle } from '@calefy-inc/hooks';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../../util';

// types and classes
import { $TSFixMe } from '@calefy-inc/utilityTypes';

interface DisplayDynamicStreetViewProps {
  details: $TSFixMe;
}
export const DisplayDynamicStreetView = ({
  details,
}: DisplayDynamicStreetViewProps) => {
  const [latLng, setLatLng] = useState<{ lat?: number; lng?: number }>({});
  const [geocodingError, setGeocodingError] = useState<boolean>(false);
  const { google } = useGoogle();

  // change the lat / lng if we get them from the details; otherwise get them from the Geocoder
  useEffect(() => {
    if (
      details.general.coordinates &&
      details.general.coordinates.lat &&
      details.general.coordinates.lng
    ) {
      const { lat, lng } = details.general.coordinates;
      setLatLng({
        lat,
        lng,
      });
    } else {
      if (google) {
        const addressString = [
          details.general.streetAddress,
          details.general.city,
          details.general.province,
          details.general.postalCode,
        ]
          .filter((elem) => elem && elem !== 'N/A')
          .join(', ');
        if (!addressString) {
          return;
        }
        try {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            { address: addressString },
            function (results: $TSFixMe, status: $TSFixMe) {
              if (status === 'OK') {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                setLatLng({ lat, lng });
              } else {
                console.error('Bad status: ', { status, results });
                setGeocodingError(true);
              }
            },
          );
        } catch (e) {
          Bugsnag.notify(errorify(e));
          console.error('Error creating geocode:', e);
          setGeocodingError(true);
        }
      }
    }
  }, [details, google]);

  // check if lat / lng change, and if so, set up the StreetViewPanorama
  useEffect(() => {
    if (!google) {
      return;
    }
    if (latLng.lat && latLng.lng) {
      new google.maps.StreetViewPanorama(
        document.getElementById('google-places-render'),
        {
          position: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        },
      );
    }
  }, [latLng, google]);

  if (geocodingError) {
    return <GeocodingError />;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <div
        id='google-places-render'
        data-testid='google-streetview-render'
        style={{
          display: 'inline-block',
          height: '70vh',
          borderRadius: 12,
          width: '100%',
        }}
      ></div>
    </div>
  );
};
