/**
 * Converts something that might be an error to definitely an error. Useful for passing to Bugsnag in a try/catch
 */
export const errorify = (possibleError: unknown): Error => {
  if (possibleError instanceof Error) {
    return possibleError;
  }
  try {
    return new Error(JSON.stringify(possibleError, null, 4));
  } catch (e) {
    return new Error(String(possibleError));
  }
};
