import { graphql } from '../../gql';
export const CREATE_AUTHORIZATION_TOKEN = graphql(`
  mutation createAuthorizationToken(
    $organizationId: ID!
    $description: String!
    $token: String!
  ) {
    createAuthorizationToken(
      organizationId: $organizationId
      description: $description
      token: $token
    ) {
      token {
        id
        token
        valid
      }
    }
  }
`);
