import { graphql } from '../../gql';

export const GEOGRAPHY_REPORT = graphql(`
  query geographyReport(
    $organizationName: String
    $startDate: DateTime
    $endDate: DateTime
    $token: String!
  ) {
    geographyReport(
      organizationName: $organizationName
      startDate: $startDate
      endDate: $endDate
      token: $token
    ) {
      uniqueId
      dateAdded
      mailingAddress {
        address
        city
        province
        postal
      }
    }
  }
`);
