// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderQuestionInstance } from '../components/FormManager/classes/ProgramBuilderQuestionInstance';

// TODO get rid of this - functionality should exist in ProgramBuilderQuestionInstance (or whatever)
/**
 * Takes a question and generates a QuestionInstanceObject compatible object
 * (Outputs should match QuestionInstanceInput in schema.py)
 */
// export const generateQuestionInstanceInputObject = (question: $TSFixMe) => {
//   if (!question) {
//     return null;
//   }
//   const {
//     apiName,
//     name,
//     label,
//     helpText,
//     component,
//     propsBlob,
//     dataType,
//     subQuestions,
//     required,
//     editAll,
//     id,
//     clientId,
//   } = question;

//   // generating the pk to send back
//   // We only ever want to send back an actually generated id (from the backend), not a UUIDv4 id
//   let pk = null;
//   if (id) {
//     pk = id;
//   } else if (clientId) {
//     pk = clientId;
//   }
//   // TEST - WHAT IF WE SEND BACK THE UUID AND THEN CONVERT OVER to an ID on the backend? Potential solution to the extensions issue
//   // if (id) {
//   //   pk = id;
//   // } else if (clientId && !clientId.includes('-')) {
//   //   pk = clientId;
//   // }

//   let jsPropsBlob;
//   if (typeof propsBlob === 'string') {
//     jsPropsBlob = propsBlob;
//   } else if (propsBlob === undefined || propsBlob === null) {
//     jsPropsBlob = JSON.stringify({});
//   } else {
//     jsPropsBlob = JSON.stringify(propsBlob);
//   }

//   return {
//     apiName,
//     name,
//     label,
//     helpText,
//     component,
//     propsBlob: jsPropsBlob,
//     dataType,
//     required,
//     editAll,
//     pk,
//     subQuestions: recursivelyGenerateQuestionInstanceObject(subQuestions),
//   };
// };

// // TODO get rid of this
// export const recursivelyGenerateQuestionInstanceObject = (
//   list: Array<$TSFixMe>,
// ): $TSFixMe => {
//   return list
//     ? list.map((question) => generateQuestionInstanceInputObject(question))
//     : [];
// };

/**
 * Merge a new set of answers with the old ones, overwriting as appropriate
 * @param {AnswerInstance[]} oldAnswers - the old answers, to be overwritten
 * @param {AnswerInstance[]} newAnswers - the new answers to overwrite
 */
export const mergeAnswers = (oldAnswers: $TSFixMe, newAnswers: $TSFixMe) => {
  // If confused Ask Eric
  if (!oldAnswers || oldAnswers.length === 0) {
    return newAnswers;
  }
  if (!newAnswers || newAnswers.length === 0) {
    return oldAnswers;
  }

  // eliminate any undefined answers
  oldAnswers = oldAnswers.filter((answer: $TSFixMe) => !!answer);
  newAnswers = newAnswers.filter((answer: $TSFixMe) => !!answer);

  const uniqueAnswerIds = newAnswers
    .map((answer: $TSFixMe) => answer.questionInstance.id)
    .filter(
      (id: $TSFixMe, index: $TSFixMe, allIds: Array<$TSFixMe>) =>
        allIds.indexOf(id) === index,
    );

  const mergedAnswers = uniqueAnswerIds.reduce(
    (acc: $TSFixMe, id: $TSFixMe) => {
      const relevantNewAnswers = newAnswers.filter(
        (answer: $TSFixMe) => answer && answer.questionInstance.id === id,
      );
      const firstExistingAnswerIndex = acc.findIndex(
        (old: $TSFixMe) => old && old.questionInstance.id === id,
      );

      if (firstExistingAnswerIndex === -1) {
        return [...acc, ...relevantNewAnswers];
      }

      const lastExistingAnswerIndex =
        acc.length -
        1 -
        [...acc]
          .reverse()
          .findIndex((answer) => answer.questionInstance.id === id);

      acc.splice(
        firstExistingAnswerIndex,
        lastExistingAnswerIndex - firstExistingAnswerIndex + 1,
        ...relevantNewAnswers,
      );

      return acc;
    },
    oldAnswers,
  );

  //console.log('in mergedAnswers - about to return', mergedAnswers);
  return mergedAnswers;
};

/**
 * Go through and ensure that the numbering for set of questions is correct; necessary because sometimes the answer can be undefined, and that throws off the numbers
 */
const adjustSetOfQuestionsNumbering = (answersList: Array<$TSFixMe>) => {
  if (!answersList || answersList.length === 0) {
    return [];
  }
  for (let i = 0; i < answersList.length; i++) {
    const answer = answersList[i];
    if (!answer) {
      continue;
    }
    if (answer.questionInstance.component === 'repeatable') {
      const targetId = answer.questionInstance.id;
      const numPreviousInstances = answersList
        .slice(0, i)
        .reduce(
          (numSoFar: $TSFixMe, answer: $TSFixMe) =>
            answer &&
            answer.questionInstance &&
            answer.questionInstance.id &&
            answer.questionInstance.id === targetId
              ? numSoFar + 1
              : numSoFar,
          0,
        );
      answer.value = numPreviousInstances + 1;
    }
  }
  return answersList;
};

/**
 * Sanitize a list of answers by removing any undefined ones and adjusting the numbering for sets of questions appropriately. This was motivated by the fact that if you create multiple instances of a set of questions and only answer later ones, the numbering for the repeatable question becomes skewed
 */
export const sanitizeAnswers = (answersList: Array<$TSFixMe>) => {
  if (!answersList || answersList.length === 0) {
    return [];
  }
  answersList = answersList.filter((answer) => !!answer);
  answersList = adjustSetOfQuestionsNumbering(answersList);
  answersList.forEach(
    (answer) => (answer.subAnswers = sanitizeAnswers(answer.subAnswers)),
  );
  return answersList;
};

export const recursiveSearchAndCopy = (
  list: Array<ProgramBuilderQuestionInstance>,
  questionInstance: ProgramBuilderQuestionInstance,
): boolean => {
  for (let i = 0; i < list.length; i++) {
    const ele = list[i];
    if (ele.matchById(questionInstance)) {
      const copy = ele.copyAsNew();
      list.splice(i + 1, 0, copy);
      return true;
    }
    if (ele.subQuestions && ele.subQuestions.length > 0) {
      const inserted = recursiveSearchAndCopy(
        ele.subQuestions,
        questionInstance,
      );
      if (inserted) {
        return true;
      }
    }
  }
  return false;
};
