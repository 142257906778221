import { graphql } from '../../src/gql';

// Quote wizard business query
export const allBusinessTypes = graphql(`
  query allBusinessTypes {
    allBusinessTypes {
      id
      displayName
      internalName
      keywords
      aliases
      industry {
        id
        displayName
      }
    }
  }
`);

// Relay Business Query
export const RELAY_BUSINESS = graphql(`
  query relayBusinesses($cursor: String, $first: Int = 2000) {
    relayBusinesses(first: $first, after: $cursor) {
      edges {
        node {
          id
          displayName
          internalName
          keywords
          aliases
          sicCode
          industry {
            id
            displayName
          }
          isDeletable
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

// Management panel business query
export const GET_ALL_BUSINESS = graphql(`
  query businessTypes {
    allBusinessTypes {
      id
      displayName
      internalName
      keywords
      aliases
      sicCode
      industry {
        id
        displayName
        internalName
      }
      isDeletable
    }
  }
`);

// Demo form business query
export const BUSINESS_TYPE = graphql(`
  query businessType($id: ID, $internalName: String) {
    businessType(id: $id, internalName: $internalName) {
      id
      internalName
      displayName
      keywords
      aliases
      industry {
        id
        internalName
        displayName
      }
      sicCode
    }
  }
`);

// All industries query
export const GET_ALL_INDUSTRIES = graphql(`
  query industryTypes {
    allIndustries {
      id
      displayName
      internalName
    }
  }
`);

// Create Business Mutation
export const CREATE_BUSINESS = graphql(`
  mutation createBusiness(
    $displayName: String!
    $internalName: String!
    $sicCode: Int!
    $industryId: ID!
    $keywords: [String]
    $aliases: [String]
    $token: String!
  ) {
    createBusiness(
      displayName: $displayName
      internalName: $internalName
      keywords: $keywords
      aliases: $aliases
      sicCode: $sicCode
      industryId: $industryId
      token: $token
    ) {
      ok
      business {
        id
        displayName
        internalName
        keywords
        aliases
        sicCode
        industry {
          id
          displayName
        }
        isDeletable
      }
    }
  }
`);

// Business Update Mutation
export const UPDATE_BUSINESS = graphql(`
  mutation updateBusinesses(
    $businessId: ID!
    $displayName: String!
    $internalName: String!
    $keywords: [String]
    $aliases: [String]
    $sicCode: Int!
    $industryId: ID!
    $token: String!
  ) {
    updateBusinessList(
      businessId: $businessId
      displayName: $displayName
      internalName: $internalName
      keywords: $keywords
      aliases: $aliases
      sicCode: $sicCode
      industryId: $industryId
      token: $token
    ) {
      ok
      business {
        id
        displayName
        internalName
        keywords
        aliases
        sicCode
        industry {
          id
          displayName
        }
        isDeletable
      }
    }
  }
`);

// Delete business Mutation
export const DELETE_BUSINESS = graphql(`
  mutation deleteBusiness($businessId: ID!, $token: String!) {
    deleteBusiness(businessId: $businessId, token: $token) {
      ok
      deletedId
    }
  }
`);
