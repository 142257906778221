import { useState, useEffect, useRef } from 'react';
import { Slider, YesNoToggle } from '@calefy-inc/informedMaterial';
// @ts-expect-error
import { ArrayField, Relevant } from 'informed';
import { Button, Grid, FormHelperText } from '@mui/material';
import RenderedQuestionInstance from './RenderedQuestionInstance';
import { v4 as createId } from 'uuid';
import { useTheme } from '@mui/material/styles';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';

import { valueOrMax, formatAsReadableList } from './utility';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language, LanguageAwareString } from '../../../Typescript/classes';
import {
  QuoteComponentProps,
  QuoteComponentValidationFunctionReturn,
} from './types';
import { Component } from './classes';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';

export const defaultInternationalRevenuePropsBlob = {
  dataType: '%',
  slider_max: 100,
};
export const defaultSubQuestions = [
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'international_revenue_bool',
    displayNames: [
      new LanguageAwareString('international_revenue_bool', Language.english),
    ],
    component: 'YesNoToggle',
    labels: [
      new LanguageAwareString(
        'Do you have any revenue from outside of Canada?',
        Language.english,
      ),
    ],
    helpTexts: [
      new LanguageAwareString(
        'The USA counts as outside of Canada.',
        Language.english,
      ),
    ],
    dataType: 'boolean',
    propsBlob: defaultInternationalRevenuePropsBlob,
    subQuestions: [],
  }),
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'canada',
    displayNames: [new LanguageAwareString('canada', Language.english)],
    component: 'slider',
    labels: [new LanguageAwareString('Canada', Language.english)],
    dataType: 'integer',
    propsBlob: defaultInternationalRevenuePropsBlob,
    required: true,
    subQuestions: [],
  }),
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'usa',
    displayNames: [new LanguageAwareString('usa', Language.english)],
    labels: [new LanguageAwareString('USA', Language.english)],
    component: 'slider',
    dataType: 'integer',
    propsBlob: defaultInternationalRevenuePropsBlob,
    required: true,
    subQuestions: [],
  }),
  new ProgramBuilderQuestionInstance({
    clientId: createId(),
    apiName: 'international',
    displayNames: [new LanguageAwareString('international', Language.english)],
    labels: [new LanguageAwareString('International', Language.english)],
    component: 'slider',
    dataType: 'integer',
    propsBlob: defaultInternationalRevenuePropsBlob,
    required: true,
    subQuestions: [],
  }),
];

function QuoteComponent({
  questionInstance,
  classes = {},
  ...props
}: QuoteComponentProps) {
  const theme = useTheme();

  const [revenueBool, setRevenueBool] = useState<boolean | undefined>();
  const [canada, setCanada] = useState<number | undefined>();
  const [usa, setUsa] = useState<number | undefined>();
  const [international, setInternational] = useState<number | undefined>();
  const [sum, setSum] = useState<number | undefined>();
  const sumRef = useRef<number | undefined>(sum);

  const validateSum = () => {
    const returnedValue =
      !sumRef.current || sumRef.current < 100 ? 'Sum must be 100%' : undefined;
    return returnedValue;
  };

  // set the sum whenever the constituents change
  useEffect(() => {
    let newSum = 0;
    [canada, usa, international].forEach((revenue) => {
      if (revenue !== undefined) {
        const numericalRevenue = Number(revenue);
        if (!Number.isNaN(numericalRevenue)) {
          newSum = newSum + numericalRevenue;
        }
      }
    });
    setSum(newSum);
    sumRef.current = newSum;
  }, [canada, usa, international]);

  const nonDefaultSubquestions =
    questionInstance &&
    questionInstance.subQuestions &&
    questionInstance.subQuestions.length > 0
      ? questionInstance.subQuestions.filter(
          (subQuestion: $TSFixMe) =>
            !defaultSubQuestions
              .map((defaultSubQuestion) => defaultSubQuestion.apiName)
              .includes(subQuestion.apiName),
        )
      : [];

  // Default variables for each slider
  const max = 100;
  const min = 0;
  const suffix = '%';
  const prefix = null;

  // Reset slider values to 0
  const resetSliders = () => {
    setCanada(0);
    setUsa(0);
    setInternational(0);
  };

  const sourceQuestion = questionInstance.subQuestions[0];

  return (
    <>
      <ArrayField field={questionInstance.generateFieldName()}>
        {(arrayFieldApi) => {
          if (arrayFieldApi.fields.length === 0) {
            arrayFieldApi.add();
          }
          return (
            <>
              {/* @ts-expect-error */}
              <ArrayField.Items>
                {(arrayFieldItemsApi: $TSFixMe) => {
                  const canadaFieldName = `${arrayFieldItemsApi.field}.canada`;
                  const usaFieldName = `${arrayFieldItemsApi.field}.usa`;
                  const internationalFieldName = `${arrayFieldItemsApi.field}.international`;
                  sourceQuestion.fieldPrefix = arrayFieldItemsApi.field;
                  setRevenueBool(() => {
                    const valueInFormState = arrayFieldItemsApi.getValue(
                      sourceQuestion.apiName,
                    );
                    return valueInFormState === undefined
                      ? undefined
                      : valueInFormState === 'yes'
                      ? true
                      : false;
                  });
                  if (canada === undefined) {
                    const foundValue = arrayFieldItemsApi.getValue('canada');
                    setCanada(foundValue);
                  }
                  if (usa === undefined) {
                    const foundValue = arrayFieldItemsApi.getValue('usa');
                    setUsa(foundValue);
                  }
                  if (international === undefined) {
                    const foundValue =
                      arrayFieldItemsApi.getValue('international');
                    setInternational(foundValue);
                  }
                  return (
                    <>
                      {/*
							<pre>Canada: {String(canada)}</pre>
							<pre>USA: {String(usa)}</pre>
							<pre>International: {String(international)}</pre>
							<pre>Sum (state): {String(sum)}</pre>
						*/}
                      <YesNoToggle
                        field={sourceQuestion.generateFieldName()}
                        value={
                          revenueBool === true
                            ? 'yes'
                            : revenueBool === false
                            ? 'no'
                            : undefined
                        }
                        label={sourceQuestion.label}
                        onChange={(e: $TSFixMe) => {
                          setRevenueBool(
                            e.target.value === 'yes' ? true : false,
                          );
                        }}
                      />
                      <Relevant
                        when={() => {
                          const booleanAnswer = arrayFieldItemsApi.getValue(
                            sourceQuestion.apiName,
                          );
                          const result = booleanAnswer === 'yes';
                          return result;
                        }}
                      >
                        <>
                          <div className={classes.slidergroup}>
                            <Slider
                              label='What percentage of your revenue is from Canada?'
                              field={canadaFieldName}
                              className={classes.slider}
                              classes={classes}
                              size='small'
                              max={max}
                              min={min}
                              validate={validateSum}
                              prefix={prefix}
                              suffix={suffix}
                              value={canada}
                              required={true}
                              onChange={(e: $TSFixMe) => {
                                setCanada(
                                  valueOrMax(e.target.value, canada, sum),
                                );
                              }}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                              {...props}
                            />
                            <Slider
                              label='What percentage of your revenue is from the USA?'
                              field={usaFieldName}
                              className={classes.slider}
                              classes={classes}
                              max={max}
                              min={min}
                              size='small'
                              validate={validateSum}
                              prefix={prefix}
                              suffix={suffix}
                              value={usa}
                              required={true}
                              onChange={(e: $TSFixMe) => {
                                setUsa(valueOrMax(e.target.value, usa, sum));
                              }}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                              {...props}
                            />
                            <Slider
                              label='What percentage of your revenue is from outside Canada and the USA?'
                              field={internationalFieldName}
                              className={classes.slider}
                              classes={classes}
                              max={max}
                              min={min}
                              size='small'
                              validate={validateSum}
                              prefix={prefix}
                              suffix={suffix}
                              value={international}
                              required={true}
                              onChange={(e: $TSFixMe) => {
                                setInternational(
                                  valueOrMax(
                                    e.target.value,
                                    international,
                                    sum,
                                  ),
                                );
                              }}
                              style={{
                                marginTop: 0,
                                marginBottom: 2,
                              }}
                              {...props}
                            />
                            <div className={classes.util}>
                              <Grid container spacing={3}>
                                <Grid item xs={10}>
                                  <Button
                                    size='medium'
                                    type='button'
                                    onClick={() => {
                                      resetSliders();
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Grid>

                                <Grid item xs={2}>
                                  <label>
                                    <span
                                      style={
                                        sum && sum >= 100
                                          ? {
                                              color: theme.palette.primary.main,
                                              fontWeight: 'bold',
                                            }
                                          : undefined
                                      }
                                    >
                                      Sum: {sum}%
                                    </span>
                                  </label>
                                </Grid>
                              </Grid>
                            </div>
                            <FormHelperText>
                              Sum of sliders must be 100%
                            </FormHelperText>
                          </div>
                          {nonDefaultSubquestions.map((subQuestion) => {
                            subQuestion.fieldPrefix = arrayFieldItemsApi.field;
                            return (
                              <RenderedQuestionInstance
                                key={subQuestion.apiName}
                                questionInstance={subQuestion}
                              />
                            );
                          })}
                        </>
                      </Relevant>
                    </>
                  );
                }}
                {/* @ts-expect-error */}
              </ArrayField.Items>
            </>
          );
        }}
      </ArrayField>
    </>
  );
}

function gatherSubQuestions(
  _formState: $TSFixMe,
  questionInstance: ProgramBuilderQuestionInstance,
) {
  return initialSubQuestions(questionInstance);
}

function initialSubQuestions(questionInstance: ProgramBuilderQuestionInstance) {
  questionInstance.subQuestions = [
    ...defaultSubQuestions,
    ...questionInstance.subQuestions.filter(
      (subq) =>
        !defaultSubQuestions
          .map((defaultSubQ) => defaultSubQ.apiName)
          .includes(subq.apiName),
    ),
  ];
  questionInstance.children = questionInstance.subQuestions.map((subq) =>
    subq.copy(),
  );
  return questionInstance;
}

function validateInternationalRevenue(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const errorMessages = [];
  const requiredSubquestionApiNames = defaultSubQuestions.map((q) => q.apiName);
  const missingComponentApiNames = requiredSubquestionApiNames.filter(
    (apiName) =>
      !questionInstance.subQuestions.find((subq) => subq.apiName === apiName),
  );
  if (missingComponentApiNames.length > 0) {
    const plural = missingComponentApiNames.length > 1;
    errorMessages.push(
      `International Revenue component with name "${questionInstance.generateDisplayName()}" is missing ${
        plural ? '' : 'a '
      }required subquestion${plural ? 's' : ''} with API name${
        plural ? 's' : ''
      } ${formatAsReadableList(
        missingComponentApiNames.map((s) => `"${s}"`),
      )}. The safest thing to do is to delete and re-create it to avoid problems later.`,
    );
  }
  return errorMessages;
}

const IconComponent = () => {
  return <PublicRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'internationalRevenue',
    gatherSubQuestions: gatherSubQuestions,
    typeLabel: 'International Revenue Check',
    quoteComponent: QuoteComponent,
    dataType: 'nested',
    programBuilderValidation: validateInternationalRevenue,
    icon: IconComponent,
  });
}

export { QuoteComponent };
