import {
  createLocalMockClient,
  createDefaultMocks,
  createQuestionGroupMocks,
  createBusinessAndIndustryMocks,
} from './createLocalMockClient';
import createRemoteClient from './createClient';
import { ApolloProvider } from '@apollo/client';
import { merge } from 'lodash';

const clientConfig = {
  useLocalMocks: process.env.REACT_APP_USE_LOCAL_APOLLO_MOCKS,
  uri: '/graphql/',
  test_var: process.env.REACT_APP_TEST_VAR,
};

export function createClient(configOverrides) {
  const config = merge(clientConfig, configOverrides || {});

  if (config.useLocalMocks === 'true') {
    // TODO can we pass in mock overrides here??
    return createLocalMockClient();
  }

  return createRemoteClient({ uri: config.uri });
}

export default function ClientProvider({ client, children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export {
  createLocalMockClient,
  createRemoteClient,
  createDefaultMocks,
  createQuestionGroupMocks,
  createBusinessAndIndustryMocks,
};
