import { graphql } from '../gql';

export const MARK_AS_RENEWAL_LINK_GENERATED = graphql(`
  mutation markAsRenewalLinkGenerated($uuid: UUID!, $token: String!) {
    markAsRenewalLinkGenerated(uuid: $uuid, token: $token) {
      ok
      link
    }
  }
`);
