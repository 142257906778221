import { $TSFixMe } from '@calefy-inc/utilityTypes';

/**
 * Format a list of strings for display using the Intl object
 */
export const formatListForDisplay = (
  list: string[],
  options: $TSFixMe & { locale?: string } = {},
): string => {
  const { locale = 'en', ...intlOptions } = options;
  // @ts-expect-error
  const formatter = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'conjunction',
    ...intlOptions,
  });
  return formatter.format(list);
};
