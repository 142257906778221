import { Peril } from '../../../../../Typescript/classes';

interface DisplayPerilOptions {
  displayUnit?: boolean;
}
interface DisplayPerilRateOptions extends DisplayPerilOptions {}
export const displayPerilRate = (
  rate: Peril['rate'],
  options: DisplayPerilRateOptions = {},
) => {
  if (typeof rate !== 'number') {
    return rate;
  }
  const formatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 4,
    style: options.displayUnit ? 'percent' : undefined,
  };
  const formatted = new Intl.NumberFormat(undefined, formatOptions).format(
    rate,
  );
  return formatted;
};

export const displayPerilLimits = (limit: Peril['limit']) => {
  if (limit === undefined || limit === null) {
    return '';
  }
  if (typeof limit !== 'number') {
    return limit;
  }
  return `$${limit.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })}`;
};

interface DisplayPerilPremiumOptions extends DisplayPerilOptions {}
/**
 * Display the premium, including the dollar sign, appropriate number of decimal places, &c.
 */
export const displayPerilPremium = (
  premium: Peril['premium'],
  options: DisplayPerilPremiumOptions = {},
) => {
  if (premium === undefined || premium === null) {
    return '';
  }
  if (typeof premium !== 'number') {
    return premium;
  }
  return `${options.displayUnit ? '$' : ''}${premium.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })}`;
};
