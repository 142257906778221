import { motion } from 'framer-motion';
import Button, { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const getBoxShadow = (color: unknown, theme: $TSFixMe) => {
  if (color === 'primary') {
    return theme.customShadows.primary;
  } else if (color === 'secondary') {
    return theme.customShadows.secondary;
  } else if (color === 'info') {
    return theme.customShadows.info;
  } else if (color === 'warning') {
    return theme.customShadows.warning;
  } else if (color === 'success') {
    return theme.customShadows.success;
  } else if (color === 'error') {
    return theme.customShadows.error;
  } else {
    return 'none';
  }
};

/**
 * Motion button component which increases scale on hover and decreases scale on click or tap.
 */
export const BounceButton = ({
  variant = 'text',
  color = 'primary',
  size = 'medium',
  onClick = () => {},
  disabled,
  type = 'button',
  children,
  endIcon,
  startIcon,
}: ButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      type={type}
      onClick={onClick}
      disabled={disabled}
      component={motion.button}
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 0.95 }}
      style={{
        boxShadow:
          !disabled && variant === 'contained'
            ? getBoxShadow(color, theme)
            : 'none',
      }}
      endIcon={endIcon ? endIcon : null}
      startIcon={startIcon ? startIcon : null}
    >
      {children}
    </Button>
  );
};
