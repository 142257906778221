import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

// types and classes
import type { PremiumProposal } from '../../../Typescript/backend/classes';

interface ReferralReasonsSummaryProps {
  reasons: PremiumProposal['referralReasons'];
}
export const ReferralReasonsSummary = ({
  reasons,
}: ReferralReasonsSummaryProps) => {
  return (
    <>
      <Typography
        gutterBottom
        align='center'
        component='h3'
        variant='h5'
        sx={{ marginTop: 2 }}
      >
        Referral Criteria Summary
      </Typography>
      {Object.keys(reasons).length === 0 ? (
        <Typography paragraph>
          No referral criteria associated with this rating.
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: 5,
          }}
        >
          <List disablePadding>
            {Object.entries(reasons)
              .slice(0, Math.ceil(Object.keys(reasons).length / 2))
              .map(([reason, applies]) => {
                return (
                  <ListItem
                    disablePadding
                    key={reason}
                    sx={{ alignItems: 'flex-start', overflow: 'hidden' }}
                  >
                    <ListItemIcon sx={{ marginTop: 0.5 }}>
                      {applies ? (
                        <CloseIcon sx={{ color: 'warning.main' }} />
                      ) : (
                        <CheckIcon sx={{ color: 'success.main' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText>{reason}</ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <List disablePadding>
            {Object.entries(reasons)
              .slice(Math.ceil(Object.keys(reasons).length / 2))
              .map(([reason, applies]) => {
                return (
                  <ListItem
                    disablePadding
                    key={reason}
                    sx={{ alignItems: 'flex-start', overflow: 'hidden' }}
                  >
                    <ListItemIcon sx={{ marginTop: 0.5 }}>
                      {applies ? (
                        <CloseIcon sx={{ color: 'warning.main' }} />
                      ) : (
                        <CheckIcon sx={{ color: 'success.main' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText>{reason}</ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      )}
    </>
  );
};
