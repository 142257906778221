import { useVagoOtherSubscribers } from '../../../hooks';
import { InformedMaterialAutocomplete } from '../InformedMaterialFills';

import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps } from './types';

const QuoteComponent = function ({
  questionInstance,
  classes,
  handleFilesChanged,
  ...props
}: QuoteComponentProps) {
  const vagoOptions = useVagoOtherSubscribers();

  return (
    <InformedMaterialAutocomplete
      label={questionInstance.label}
      field={questionInstance.generateFieldName()}
      id={questionInstance.id}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes && classes.textfield ? classes.textfield : ''}
      classes={classes}
      {...props}
      variant='standard'
      options={vagoOptions}
    />
  );
};

const IconComponent = () => {
  return <TextFieldsRoundedIcon fontSize='small' color='primary' />;
};

export function createVagoOtherSubscribersAutocompleteComponentRegistry() {
  return new Component({
    type: 'vagoOtherSubscribersAutocomplete',
    typeLabel: 'VaGo Other Subscribers Autocomplete',
    quoteComponent: QuoteComponent,
    dataType: 'string',
    // programBuilderComponent: ProgramBuilderComponent,
    icon: IconComponent,
  });
}

export { QuoteComponent };
