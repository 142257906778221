import { useState } from 'react';
import Button from '@mui/material/Button';
import { useUsingVagoSettings } from '../../../../../WhiteLabelSettings/VagoAssure/settings';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { useFormApi } from 'informed';
import {
  makeInformedFieldName,
  safelyGetAttributes,
} from '../../../../../util';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface VagoCopyLocationCoveragesButtonProps {
  index: number;
  apiName: string;
  arrayFieldItemApi: $TSFixMe;
}
export const VagoCopyLocationCoveragesButton = ({
  index,
  apiName,
  arrayFieldItemApi,
}: VagoCopyLocationCoveragesButtonProps) => {
  const onVago = useUsingVagoSettings();
  const formApi = useFormApi();
  // @ts-expect-error
  const [buildingLimit, setBuildingLimit] = useState<number>();

  // Not working ATM
  // return null;
  if (apiName !== 'risk_address') {
    return null;
  }
  if (!onVago) {
    return null;
  }
  if (index === 0) {
    return null;
  }
  return (
    <>
      <Button
        startIcon={<ContentCopyIcon />}
        variant='outlined'
        onClick={() => {
          const questionState = formApi.getValue(apiName);
          const firstQuestionState =
            questionState &&
            Array.isArray(questionState) &&
            questionState.length > 0
              ? questionState[0]
              : undefined;
          if (!firstQuestionState) {
            return;
          }

          // these are the sources from which to get the values to copy
          const attrsArray: Array<Array<string | number>> = [
            [
              'building_coverage',
              0,
              'building_coverage_conditional',
              0,
              'building_limits',
            ],
            ...['building_deductible', 'building_deductible_other'].map(
              (final) => [
                'building_coverage',
                0,
                'building_coverage_conditional',
                0,
                'building_deductible_conditional',
                0,
                final,
              ],
            ),
            ...['water_deductible', 'water_deductible_other'].map((final) => [
              'building_coverage',
              0,
              'water_deductible_conditional',
              0,
              final,
            ]),
            ['contents_coverage', 0, 'general_contents_limits'],
            ...['contents_deductible', 'contents_deductible_other'].map(
              (final) => [
                'contents_coverage',
                0,
                'contents_deductible_conditional',
                0,
                final,
              ],
            ),
            ['sewer_coverage', 0, 'sewer_limit'],
            ...['sewer_deductible', 'sewer_deductible_other'].map((final) => [
              'sewer_coverage',
              0,
              'sewer_backup_deductible_conditional',
              0,
              final,
            ]),
            [
              'additional_property_coverage',
              0,
              'business_interruption_conditional',
              0,
              'business_interruption_limit',
            ],
            ...[
              'business_interruption_deductible',
              'business_interruption_deductible_other',
            ].map((final) => [
              'additional_property_coverage',
              0,
              'business_interruption_conditional',
              0,
              'business_interruption_deductible_conditional',
              0,
              final,
            ]),
            ...['flood_deductible', 'flood_deductible_other'].map((final) => [
              'additional_property_coverage',
              0,
              'flood_deductible_conditional',
              0,
              final,
            ]),
            ...['earthquake_deductible', 'earthquake_deductible_other'].map(
              (final) => [
                'additional_property_coverage',
                0,
                'earthquake_deductible_conditional',
                0,
                final,
              ],
            ),
          ];
          attrsArray.forEach((attrs) => {
            const value = safelyGetAttributes(firstQuestionState, attrs);
            if (value === null) {
              return;
            }
            const fieldName = makeInformedFieldName(attrs);
            arrayFieldItemApi.setValue(fieldName, value);
          });
          // this is garbage - need to do this in a better way
          // problem is that for conditional questions, we need to wait for the next re-render to set the state
          setTimeout(() => {
            attrsArray.forEach((attrs) => {
              const value = safelyGetAttributes(firstQuestionState, attrs);
              if (value === null) {
                return;
              }
              const fieldName = makeInformedFieldName(attrs);
              arrayFieldItemApi.setValue(fieldName, value);
            });
          }, 100);
        }}
      >
        Copy Coverages, Limits, and Deductibles
      </Button>
    </>
  );
};
