import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { FORM_MODIFIER_RELEVANT_FORMS_FULL } from '../../../../../../../queries';
import { ALL_SIGNIFIER } from '../../classes/FinalFormModifier';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { ErrorView } from '../../../../../../common/ErrorView';
import QuestionInstanceForm from './CreateQuestionInstance/QuestionInstanceForm';
import FormModal from './FormModal';

// types and classes
import { FinalFormModifier } from '../../classes';
import {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../../../../../FormManager/classes';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface ModifyQuestionProps {
  form: FinalFormModifier;
}
export const ModifyQuestion = ({ form }: ModifyQuestionProps) => {
  const [modifyQuestionsModalOpen, setModifyQuestionsModalOpen] =
    useState<boolean>(false);
  return (
    <>
      <Button
        variant='contained'
        color='warning'
        onClick={() => setModifyQuestionsModalOpen(true)}
      >
        Modify Existing Question
      </Button>
      <ModifyExistingQuestionDialog
        form={form}
        open={modifyQuestionsModalOpen}
        onClose={() => setModifyQuestionsModalOpen(false)}
      />
    </>
  );
};

interface ModifyExistingQuestionDialogProps {
  form: FinalFormModifier;
  open: boolean;
  onClose: () => void;
}
const ModifyExistingQuestionDialog = ({
  form,
  open,
  onClose,
}: ModifyExistingQuestionDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Modify Existing Question</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          Select an existing question to modify.
        </Typography>
        <DisplayQuestionsToModify
          form={form}
          closeModifyExistingQuestionDialog={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

interface DisplayQuestionsToModifyProps {
  form: FinalFormModifier;
  closeModifyExistingQuestionDialog: ModifyExistingQuestionDialogProps['onClose'];
}
export const DisplayQuestionsToModify = ({
  form,
  closeModifyExistingQuestionDialog,
}: DisplayQuestionsToModifyProps) => {
  const [forms, setForms] = useState<Array<ProgramBuilderForm>>([]);
  const [getRelevantForms, { loading, error, data }] = useLazyQuery(
    FORM_MODIFIER_RELEVANT_FORMS_FULL,
  );
  useEffect(() => {
    getRelevantForms({
      variables: {
        businessId:
          form.business === ALL_SIGNIFIER ? undefined : form.business.id,
        policyId: form.policy ? form.policy.id : undefined,
      },
    });
  }, [form]);

  //convert to ProgramBuilderForm when everything is loaded
  useEffect(() => {
    if (data?.formModifierRelevantFinalForms) {
      setForms(
        data.formModifierRelevantFinalForms
          .map((backendResponse) =>
            ProgramBuilderForm.generateFromBackendResponse(backendResponse),
          )
          .sort((a, b) =>
            a.businessLine.displayName.localeCompare(
              b.businessLine.displayName,
            ),
          ),
      );
    }
  }, [data]);
  return (
    <div>
      {loading ? <LoadingScreen /> : null}
      {error ? <ErrorView /> : null}
      {forms ? (
        <DisplayRelevantForms
          formModifier={form}
          forms={forms}
          closeModifyExistingQuestionDialog={closeModifyExistingQuestionDialog}
        />
      ) : null}
    </div>
  );
};

interface DisplayRelevantFormsProps {
  formModifier: FinalFormModifier;
  forms: Array<ProgramBuilderForm>;
  closeModifyExistingQuestionDialog: DisplayQuestionsToModifyProps['closeModifyExistingQuestionDialog'];
}
const DisplayRelevantForms = ({
  formModifier,
  forms,
  closeModifyExistingQuestionDialog,
}: DisplayRelevantFormsProps) => {
  const [openQuestion, setOpenQuestion] = useState<
    ProgramBuilderQuestionInstance['id'] | undefined
  >(); // which modal to edit a question is open, if any?
  if (forms.length === 0) {
    return null;
  }
  if (forms.length === 0) {
    return (
      <>
        <Typography key='title' variant='subtitle1'>
          No Relevant Forms
        </Typography>
        <Typography key='subtitle' variant='body1'>
          There are no forms that would be affected by this Form Modifier.
        </Typography>
      </>
    );
  }
  return (
    <>
      {forms.map((form) => (
        <>
          <section key={form.id}>
            <Typography variant='subtitle2'>{`${
              form.businessLine?.displayName
            } - ${
              form.policy ? form.policy.displayName : 'General Information'
            }`}</Typography>
            <List>
              {form.questionInstances.map((question) => {
                const isDeleted = formModifier.apiNamesForRemoval.includes(
                  question.apiName,
                );
                return (
                  <ListItem key={question.id}>
                    <Tooltip
                      title={
                        isDeleted
                          ? `Questions with this API name (${question.apiName}) are already slated for deletion`
                          : 'Edit'
                      }
                    >
                      <span>
                        <ListItemButton
                          style={{ flexGrow: 0 }}
                          onClick={() => setOpenQuestion(question.id)}
                          disabled={isDeleted}
                        >
                          <ListItemIcon>
                            <ModeEditIcon />
                          </ListItemIcon>
                        </ListItemButton>
                      </span>
                    </Tooltip>
                    {question.labels[0].value}&nbsp;
                    <FormModal
                      open={openQuestion === question.id}
                      handleClose={() => {
                        setOpenQuestion(undefined);
                        closeModifyExistingQuestionDialog();
                      }}
                      FormComponent={(props: $TSFixMe) => (
                        <QuestionInstanceForm
                          parentForm={formModifier}
                          isEditing={false}
                          questionInstance={question.copyWithAmendments({
                            modified: true,
                          })}
                          {...props}
                        />
                      )}
                      title='Create Custom Question'
                    />
                  </ListItem>
                );
              })}
            </List>
          </section>
        </>
      ))}
    </>
  );
};
