import theme from './noContactInformationTheme';
import logo from './assets/MainLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';

// Calefy Config
const settings = new WebsiteSettings({
  slug: 'nocontactinformationfirst',
  host: 'secure.nocontactinformationfirst.ca',
  companyName: 'No Contact Information First Inc',
  hours: '8:30am - 5:30pm',
  phone: '1-844-4CA-LEFY (422-5339)',
  email: 'info@calefy.ca',
  chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo: logo,
  title: 'No Contact Information First',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: false,
  contactInformationFirst: false,
});

export default settings;
