import { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Box,
} from '@mui/material';

import { FloodScoreProduct, FloodScoreProductsHighlights } from './classes';
// components
import { CarouselBasic4 } from '../../../ManagementPanel/components/carousel';
import { DetailsCard } from './commonComponents';

import { NO_INFO_STRING } from './constants';
import { $TSFixMe } from '@calefy-inc/utilityTypes';

interface DisplayFloodScoreHighlightsProps {
  floodScoreProduct: $TSFixMe;
}
const DisplayFloodScoreHighlights = ({
  floodScoreProduct,
}: DisplayFloodScoreHighlightsProps) => {
  const [highlights, setHighlights] = useState(
    // @ts-expect-error
    new FloodScoreProductsHighlights(floodScoreProduct),
  );

  useEffect(() => {
    // @ts-expect-error
    setHighlights(new FloodScoreProductsHighlights(floodScoreProduct));
  }, [floodScoreProduct]);

  if (Object.values(highlights).every((elem) => elem === null)) {
    return NO_INFO_STRING;
  }
  return <>{highlights.displayAsJSx()}</>;
};

interface DisplayLoadedFloodScoreDataProps {
  product: $TSFixMe;
}
/**
 * Once the data is loaded, this component actually displays it
 */
export const DisplayLoadedFloodScoreData = ({
  product,
}: DisplayLoadedFloodScoreDataProps) => {
  const [carouselData, setCarouselData] = useState([]);

  useEffect(() => {
    if (
      product &&
      product.FloodScores &&
      product.FloodScores.FloodMap &&
      product.FloodScores.FloodMap.length > 0
    ) {
      setCarouselData(
        // @ts-expect-error
        product.FloodScores.FloodMap.map((floodMap: $TSFixMe, index) => {
          const titleString = floodMap.FloodEvent
            ? `${floodMap.FloodEvent}-Year Floods`
            : 'Flood Map';

          return {
            id: index,
            title: titleString,
            image: floodMap.Content.ContentURL,
            description: floodMap.Content.ContentURL,
          };
        }),
      );
    }
  }, [product]);

  if (!product) {
    return null;
  }
  return (
    <>
      <Typography
        variant='h6'
        align='left'
        color='textSecondary'
        sx={{ mt: 4, mb: 2 }}
      >
        Flood Score Data
      </Typography>

      <Grid container spacing={3} sx={{ textAlign: 'left' }}>
        <Grid item xs={12} key='flood-score-highlights'>
          <Card sx={{ backgroundColor: 'background.neutral' }}>
            <CardHeader
              title='Highlights'
              titleTypographyProps={{
                variant: 'h6',
                align: 'center',
                color: 'textSecondary',
              }}
            />
            <CardContent>
              {/*
                // @ts-expect-error */}
              <DisplayFloodScoreHighlights floodScoreProduct={product} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} key='flood-score-details'>
          <DetailsCard heading='Flood Score' key='flood-score'>
            {product.FloodScores && product.FloodScores.FloodScore
              ? // @ts-expect-error
                product.FloodScores.FloodScore.map((score, index) =>
                  score.displayAsJsx({ key: `floodscore-map-${index}` }),
                )
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>

        <Grid item xs={12} key='flood-score-maps'>
          <DetailsCard heading='Maps'>
            {product.FloodScores &&
            product.FloodScores.FloodMap &&
            product.FloodScores.FloodMap.length > 0 ? (
              <Box
                sx={{
                  margin: 'auto',
                  width: '31vw',
                }}
              >
                <CarouselBasic4 images={carouselData} />
              </Box>
            ) : (
              NO_INFO_STRING
            )}
          </DetailsCard>
        </Grid>
      </Grid>
    </>
  );
};

interface DisplayFloodScoreDataProps {
  details: $TSFixMe;
  setLocationData: $TSFixMe;
  loadQuotePdf: $TSFixMe;
}
/**
 * Display the FloodScore data for the given location
 */
export const DisplayFloodScoreData = ({
  details,
}: DisplayFloodScoreDataProps) => {
  const [floodScoreProduct, setFloodScoreProduct] =
    useState<null | FloodScoreProduct>(
      // @ts-expect-error
      new FloodScoreProduct(),
    );

  useEffect(() => {
    if (details && details.opta && details.opta.optaFloodscoreData) {
      setFloodScoreProduct(
        // @ts-expect-error
        FloodScoreProduct.createFromJson(details.opta.optaFloodscoreData),
      );
    } else {
      setFloodScoreProduct(null);
    }
  }, [details, details.opta, details.opta.optaFloodScoreData]);

  if (!details || !details.opta || !details.opta.optaFloodscoreData) {
    return null;
  }
  return <DisplayLoadedFloodScoreData product={floodScoreProduct} />;
};
