import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import red from '@mui/material/colors/red';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { QUERY_SHALLOW_PROSPECTS } from './queries';
const ShallowProspectsQuery = ({ province, municipalities, query, client }) => {
  const { loading, error, data } = useQuery(QUERY_SHALLOW_PROSPECTS, {
    variables: {
      province: province,
      municipalities: municipalities,
      query: query,
    },
    client: client,
  });
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color='primary' />
      </div>
    );
  }
  if (error) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PriorityHighIcon style={{ color: red[500] }} />
          <span>Error</span>
        </div>
      </>
    );
  }

  return (
    <p style={{ textAlign: 'center' }}>
      {data.shallowProspects.results} result
      {data.shallowProspects.results === 1 ? null : 's'}
    </p>
  );
};

export default ShallowProspectsQuery;
