// ----------------------------------------------------------------------

export const email = [
  'nannie_abernathy70@yahoo.com,',
  'ashlynn_ohara62@gmail.com,',
  'milo.farrell@hotmail.com,',
  'violet.ratke86@yahoo.com,',
  'letha_lubowitz24@yahoo.com,',
  'aditya_greenfelder31@gmail.com,',
  'lenna_bergnaum27@hotmail.com,',
  'luella.ryan33@gmail.com,',
  'joana.simonis84@gmail.com,',
  'marjolaine_white94@gmail.com,',
  'vergie_block82@hotmail.com,',
  'vito.hudson@hotmail.com,',
  'tyrel_greenholt@gmail.com,',
  'dwight.block85@yahoo.com,',
  'mireya13@hotmail.com,',
  'dasia_jenkins@hotmail.com,',
  'benny89@yahoo.com,',
  'dawn.goyette@gmail.com,',
  'zella_hickle4@yahoo.com,',
  'avery43@hotmail.com,',
  'olen_legros@gmail.com,',
  'jimmie.gerhold73@hotmail.com,',
  'genevieve.powlowski@hotmail.com,',
  'louie.kuphal39@gmail.com,',
  'enoch.cruickshank@gmail.com,',
  'arlo_mccullough@gmail.com,',
  'sadie18@yahoo.com,',
  'aric67@gmail.com,',
  'mack_deckow53@gmail.com,',
  'constantin91@yahoo.com,',
  'lonny84@hotmail.com,',
  'gus56@hotmail.com,',
  'brennon64@yahoo.com,',
  'hortense.streich@hotmail.com,',
  'kallie_powlowski57@hotmail.com,',
  'meghan.kemmer@hotmail.com,',
  'bella.mraz14@yahoo.com,',
  'barney88@gmail.com,',
  'diamond_johns@hotmail.com,',
  'gus80@hotmail.com,',
];
