import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { setQuestionsPerPage } from '../../../store/QuoteWizardState';
import { useAuth } from '@calefy-inc/authentication';
import { Theme } from '@mui/material';
import { StoreState } from '../../../store';
import { QuestionsPerPage } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {},
  selectedButton: {
    color: theme.palette.primary.main,
  },
  unSelectedButton: {
    color: theme.palette.text.secondary,
  },
}));
export const SelectQuestionsPerPage = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const classes = useStyles();
  const options: Array<{ value: QuestionsPerPage; label: string }> = [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 'all', label: 'All' },
  ];
  const currentQuestionsPerPage = useSelector(
    (state: StoreState) => state.quoteWizard.questionsPerPage,
  );

  return (
    <>
      {token ? (
        <div
          className={classes.outerContainer}
          data-testid='selectQuestionsPerPage'
        >
          <div className={classes.buttonContainer}>
            {options.map((option) => (
              <Button
                key={option.value}
                onClick={() => dispatch(setQuestionsPerPage(option.value))}
                className={
                  option.value === currentQuestionsPerPage
                    ? classes.selectedButton
                    : classes.unSelectedButton
                }
              >
                {option.label}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};
