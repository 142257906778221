import Typography from '@mui/material/Typography';

export function GeocodingError() {
  return (
    <div data-testid='geocodingError'>
      <Typography variant='subtitle1' gutterBottom sx={{ textAlign: 'center' }}>
        Unable to Display street view
      </Typography>
      <Typography variant='body2' sx={{ textAlign: 'center' }}>
        There was an error attempting to fetch the street view data. Please try
        again later.
      </Typography>
    </div>
  );
}
