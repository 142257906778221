import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { RateTableRate } from './types';
import { useMutation } from '@apollo/client';
import { DELETE_INSURANCE_RATE } from '../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSnackbar } from 'notistack';

interface DeleteRateButtonProps {
  rate: RateTableRate;
}
export const DeleteRateButton = ({ rate }: DeleteRateButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const hasDeletePermissions = useAllowByPermission('delete:insurance:rates');
  return hasDeletePermissions === true ? (
    <>
      <DeleteButton
        startIcon={<WarningAmberIcon />}
        onClick={() => setOpen(true)}
      >
        Delete Rate
      </DeleteButton>
      <DeleteRateDialog
        open={open}
        onClose={() => setOpen(false)}
        rate={rate}
      />
    </>
  ) : (
    <span></span>
  );
};

const DeleteButton = styled(Button)(({ theme }) => {
  return { color: theme.palette.error.main };
});

interface DeleteRateDialogProps {
  open: boolean;
  onClose: () => void;
  rate: RateTableRate;
}
const DeleteRateDialog = ({ open, onClose, rate }: DeleteRateDialogProps) => {
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [typedDisplayName, setTypedDisplayName] = useState<string>('');
  const [deleteInsuranceRate, { loading, error }] = useMutation(
    DELETE_INSURANCE_RATE,
    {
      onCompleted: () => {
        enqueueSnackbar('Successfully deleted rate', { variant: 'success' });
        onClose();
      },
      update: (cache, result) => {
        if (!result.data?.deleteRate?.id) {
          return;
        }
        const deletedReference = cache.identify({
          __typename: 'InsuranceRateType',
          id: result.data?.deleteRate?.id,
        });
        if (!deletedReference) {
          return;
        }
        cache.evict({ id: deletedReference });
        cache.gc();
      },
    },
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Rate</DialogTitle>
      <DialogContent style={{ paddingTop: '24px', width: '600px' }}>
        <Typography>
          Are you sure? To delete this rate please type in the rate's name:
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }}>{rate.displayName}</Typography>
        <TextField
          fullWidth
          label='Display Name'
          value={typedDisplayName}
          onChange={(e) => setTypedDisplayName(e.target.value)}
        />

        {error ? <ErrorP>{error.message}</ErrorP> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <LoadingButton
          disabled={typedDisplayName !== rate.displayName}
          loading={loading}
          onClick={() => {
            deleteInsuranceRate({
              variables: {
                token,
                rateId: rate.id,
              },
            });
          }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const ErrorP = styled('p')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
