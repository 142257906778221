import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, TextField } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';
// queries
import { DASHBOARD_MONTHLY_QUOTES } from '../../../../../queries';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import MonthlyQuotesSkeleton from './skeletons/MonthlyQuotesSkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { MonthlyQuotesMock } from './Mock';
// Types
import { monthlyQuotesType } from './AppTotalQuotes';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

export default function AppMonthlyQuotes() {
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const { data, loading, error } = useQuery(DASHBOARD_MONTHLY_QUOTES, {
    variables: { token },
    skip:
      hasDashboardPermissions !== true ||
      !token ||
      slug === 'lastnameinsurance',
  });
  const [monthlyQuotes, setMonthlyQuotes] = useState<$TSFixMe>([]);
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);
  const [seriesData, setSeriesData] = useState<number>();

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setMonthlyQuotes(MonthlyQuotesMock.monthlyQuotes);
      setOkToDisplay(true);
    } else if (data?.monthlyQuotesData?.monthlyQuotes) {
      setMonthlyQuotes(
        // @ts-expect-error
        getMonthlyQuotesOrder(data.monthlyQuotesData.monthlyQuotes),
      );
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  useEffect(() => {
    if (monthlyQuotes.length > 0) {
      setSeriesData(monthlyQuotes[0].year);
    }
  }, [monthlyQuotes]);

  const CHART_DATA = monthlyQuotes.length > 0 ? [...monthlyQuotes] : [];
  const percent =
    monthlyQuotes.length > 1 ? getYearlyPercentDifference(monthlyQuotes) : 0;

  const handleChangeSeriesData = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSeriesData(Number(event.target.value));
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = new Date().getMonth();
  const currentYearsMonths = months.slice(0, month + 1);
  const previousMonths = months.slice(month + 1);

  const output = [...previousMonths, ...currentYearsMonths];

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: seriesData === 0 ? output : months,
    },
  });

  if (hasDashboardPermissions === false) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if (
    (loading || !okToDisplay || hasDashboardPermissions === undefined) &&
    !error
  ) {
    return <MonthlyQuotesSkeleton />;
  }
  if (error) {
    Bugsnag.notify(`Could not load Monthly Quotes component, ${error}`);
    return handleDashboardError(error);
  }

  return (
    <Card data-testid='app-monthly-quotes'>
      <CardHeader
        title='Monthly Applications'
        subheader={
          monthlyQuotes.length > 1 && percent !== null
            ? `(${percent > 0 ? '+' : ''} ${percent}%) than last year`
            : ''
        }
        action={
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              '& fieldset': { border: '0 !important' },
              '& select': {
                pl: 1,
                py: 0.5,
                pr: '24px !important',
                typography: 'subtitle2',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: 0.75,
                bgcolor: 'background.neutral',
              },
              '& .MuiNativeSelect-icon': {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            {CHART_DATA.map((option) => (
              <option key={option.year} value={option.year}>
                {option.year !== 0 ? option.year : '-- --'}
              </option>
            ))}
          </TextField>
        }
      />

      {CHART_DATA.map((item) => (
        <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir='ltr'>
          {item.year === seriesData && (
            <ReactApexChart
              type='line'
              series={item.data}
              //@ts-expect-error
              options={chartOptions}
              height={364}
            />
          )}
        </Box>
      ))}
    </Card>
  );
}

// ----------------------------------------------------------------------
// Util

/**
 * Calculates the percent difference between the last two years of monthly quotes
 * @param {Object} monthlyQuotes - Monthly quote data for all the years
 * @return The percent difference between monthly quotes over the last 2 years
 */
const getYearlyPercentDifference = (monthlyQuotes: monthlyQuotesType[]) => {
  if (!monthlyQuotes || monthlyQuotes.length < 3) {
    return null;
  }

  // Get current month
  const currDate = new Date();
  const currMonth = currDate.getMonth();

  // Get data from current and previous year
  const currYearData = [...monthlyQuotes[1].data[0].data].slice(
    0,
    currMonth + 1,
  );
  const prevYearData = [...monthlyQuotes[2].data[0].data].slice(currMonth + 1);

  // sum both arrays
  const currSum = currYearData.reduce((a, b) => a + b, 0);
  const prevSum = prevYearData.reduce((a, b) => a + b, 0);

  const percentDifference = (currSum / prevSum - 1) * 100;
  if (!isFinite(percentDifference)) {
    return null;
  }
  return percentDifference.toPrecision(3);
};

/**
 * Adds the last 12 months object as the first in the list of monthly quotes
 * @param monthlyQuotes - Monthly quote data for all the years
 * @return monthly quotes for the last 12 month is a new array
 */
function getMonthlyQuotesOrder(monthlyQuotes: monthlyQuotesType[]) {
  if (monthlyQuotes.length === 0) {
    return {
      year: 0,
      data: [],
    };
  }

  // Organize the objects data according to the last twelve months data
  const month = new Date().getMonth();

  const currentYear = [...monthlyQuotes[0].data[0].data].slice(0, month + 1);

  const previousYear =
    monthlyQuotes.length > 1
      ? [...monthlyQuotes[1].data[0].data].slice(month + 1)
      : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].slice(month + 1);

  //  create twelve months object
  const lastTwelveMonths = {
    year: 0,
    data: [
      {
        name: 'Main',
        data: [...previousYear, ...currentYear],
      },
    ],
  };

  return [lastTwelveMonths, ...monthlyQuotes];
}
