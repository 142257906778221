import { graphql } from '../gql';

export const GET_OPTA_COMMERCIAL_PERIL_SCORE_DATA = graphql(`
  mutation getOptaCommercialPerilScoreData($token: String!, $answer: ID!) {
    getOptaCommercialPerilScoreData(token: $token, answer: $answer) {
      ok
      optaCommercialPerilScoreData {
        ...OptaCommercialPerilScoreDataFields
      }
    }
  }
`);
