import { Dispatch, SetStateAction, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import {
  useDownloadApplication,
  useDownloadVagoDocumentation,
} from '../../../hooks';
import { CircularProgress, ListItemIcon } from '@mui/material';
import { useSnackbar } from 'notistack';
import Bugsnag from '@bugsnag/browser';

interface VagoFileDownloadMenuButtonProps {
  quote: { uniqueId: string };
}
export const VagoFileDownloadMenuButton = ({
  quote,
}: VagoFileDownloadMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  if (!quote.uniqueId) {
    return null;
  }
  return (
    <>
      <IconButton
        size='small'
        aria-label='download'
        onClick={(e) => {
          setMenuOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        <DownloadRoundedIcon fontSize='small' />
      </IconButton>
      <VagoFileDownloadMenu
        open={menuOpen}
        setOpen={setMenuOpen}
        quote={quote}
        anchorEl={anchorEl}
      />
    </>
  );
};

interface VagoFileDownloadMenuProps extends MenuProps {
  quote: { uniqueId: string };
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const VagoFileDownloadMenu = ({
  open,
  setOpen,
  quote,
  ...menuProps
}: VagoFileDownloadMenuProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleRatingSuccess = useCallback(() => {
    enqueueSnackbar('Successfullly downloaded rating documents', {
      variant: 'success',
    });
    setOpen(false);
  }, [enqueueSnackbar]);
  const handleRatingFailure = useCallback(
    (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('Error downloading rating documents', {
        variant: 'error',
      });
      setOpen(false);
    },
    [enqueueSnackbar],
  );
  const handleApplicationSuccess = useCallback(() => {
    enqueueSnackbar('Successfullly downloaded application document', {
      variant: 'success',
    });
    setOpen(false);
  }, [enqueueSnackbar]);
  const handleApplicationFailure = useCallback(
    (e) => {
      console.error(e);
      Bugsnag.notify(e);
      enqueueSnackbar('Error downloading application document', {
        variant: 'error',
      });
      setOpen(false);
    },
    [enqueueSnackbar],
  );
  const [downloadRatingDocuments, { loading: ratingLoading }] =
    useDownloadVagoDocumentation({
      uuid: quote.uniqueId,
      onSuccess: handleRatingSuccess,
      onFailure: handleRatingFailure,
    });
  const [downloadApplicationDocuments, { loading: applicationLoading }] =
    useDownloadApplication({
      variables: { uuid: quote.uniqueId },
      onSuccess: handleApplicationSuccess,
      onFailure: handleApplicationFailure,
    });
  return (
    <Menu open={open} {...menuProps}>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <MenuList>
          {downloadRatingDocuments ? (
            <MenuItem
              disabled={ratingLoading || applicationLoading}
              onClick={() => {
                downloadApplicationDocuments();
              }}
            >
              <ListItemIcon>
                {applicationLoading ? (
                  <CircularProgress size='1rem' />
                ) : (
                  <LinearScaleIcon />
                )}
              </ListItemIcon>
              <ListItemText>Application</ListItemText>
            </MenuItem>
          ) : null}
          {downloadRatingDocuments ? (
            <MenuItem
              disabled={ratingLoading || applicationLoading}
              onClick={() => {
                downloadRatingDocuments && downloadRatingDocuments();
              }}
            >
              <ListItemIcon>
                {ratingLoading ? (
                  <CircularProgress size='1rem' />
                ) : (
                  <TrendingUpIcon />
                )}
              </ListItemIcon>
              <ListItemText>Rating Documents</ListItemText>
            </MenuItem>
          ) : null}
        </MenuList>
      </ClickAwayListener>
    </Menu>
  );
};
