import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { AnswerInstanceDetailsInput } from '../../../Typescript/backend/inputTypes';
import {
  BackendImage,
  GoogleDetails,
  OptaDetails,
  OpeningHours,
} from '../../../Typescript/backend/types';

import { cloneDeep } from 'lodash';

interface AnswerInstanceDetailsConstructorInput {
  lat?: null | number;
  lng?: null | number;
  google?: GoogleDetails;
  opta?: OptaDetails;
  openingHours?: Array<OpeningHours>;
  images?: Array<BackendImage>;
  [k: string]: $TSFixMe;
}
export class AnswerInstanceDetails {
  lat: null | number;
  lng: null | number;
  google: GoogleDetails;
  opta: OptaDetails;
  openingHours: Array<OpeningHours>;
  images: Array<BackendImage>;
  [k: string]: $TSFixMe;

  constructor(input: AnswerInstanceDetailsConstructorInput = {}) {
    //console.log('About to construct AnswerInstanceDetails with', input);
    const { lat, lng, google, opta, openingHours, images, ...rest } = input;

    this.lat = lat === null || lat === undefined ? null : lat;
    this.lng = lng === null || lng === undefined ? null : lng;
    this.google = google ? google : {};
    this.opta = opta ? opta : {};
    this.openingHours = openingHours ? openingHours : [];
    this.images = images ? images : [];

    Object.entries(rest).forEach(([k, v]) => {
      this[k] = v;
    });
  }

  copy() {
    return new AnswerInstanceDetails(cloneDeep(this));
  }

  toAnswerInstanceDetailsInput(): AnswerInstanceDetailsInput {
    return this.isEmpty()
      ? null
      : Object.entries(this).reduce((input: $TSFixMe, [k, v]) => {
          return k === 'opta' ? input : { ...input, [k]: v };
        }, {});
  }

  /**
   * Are all of the attributes empty?
   */
  isEmpty() {
    return (
      !this.lat &&
      !this.lng &&
      Object.keys(this.google).length === 0 &&
      Object.keys(this.opta).length === 0 &&
      this.openingHours.length === 0 &&
      this.images.length === 0
    );
  }
}
