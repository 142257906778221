import { graphql } from '../gql';

export const BUSINESS_AND_POLICY_SPECIFIC_FORM = graphql(`
  query businessAndPolicySpecificForm(
    $businessId: ID!
    $policyId: ID
    $token: String!
  ) {
    businessAndPolicySpecificForm(
      businessId: $businessId
      policyId: $policyId
      token: $token
    ) {
      ...FinalFormFields
    }
  }
`);
