import { graphql } from '../../gql';

export const SEND_VAGO_BORDEREAU_EMAIL = graphql(`
  mutation sendVagoBordereauEmail(
    $token: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    sendVagoBordereauEmail(
      token: $token
      startDate: $startDate
      endDate: $endDate
    ) {
      emails
      startDate
      endDate
    }
  }
`);
