import { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { RateChanger } from './RateChanger';
import { NewRateButton } from './NewRateButton';
import { useLazyQuery } from '@apollo/client';
import { ORGANIZATION_SPECIFIC_INSURANCE_RATES } from '../../../../../queries/queries/organizationSpecificInsuranceRates';
import { useAuth } from '@calefy-inc/authentication';
import LoadingScreen from '../../../components/LoadingScreen';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import { OrganizationSpecificInsuranceRateQuery } from '../../../../../gql/graphql';
import { NoRates } from './NoRates';
import { RateEditorErrors } from './RateEditorErrors';
import { useAllowByPermission } from '../../../../../hooks';
import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';

interface RatesProps extends RouteComponentProps {}
// EditableNumbersPage Component
export const RatesEditor = (_props: RatesProps) => {
  const { token } = useAuth();
  const hasViewPermissions = useAllowByPermission('view:insurance:rates:table');
  const [aggregatedRates, setAggregatedRates] = useState<
    Array<
      NonNullable<
        ArrayElement<
          OrganizationSpecificInsuranceRateQuery['organizationSpecificInsuranceRates']
        >
      >
    >
  >([]);

  const [organizationSpecificInsuranceRates, { loading, error, data }] =
    useLazyQuery(ORGANIZATION_SPECIFIC_INSURANCE_RATES);

  // get the rates once we can
  useEffect(() => {
    if (hasViewPermissions === true && token) {
      organizationSpecificInsuranceRates({ variables: { token } });
    }
  }, [token, hasViewPermissions]);

  // aggregate the data as soon as we get it
  useEffect(() => {
    if (data?.organizationSpecificInsuranceRates) {
      setAggregatedRates(
        // @ts-expect-error
        data.organizationSpecificInsuranceRates.reduce(
          (
            acc: Record<
              string,
              Array<
                NonNullable<
                  ArrayElement<
                    OrganizationSpecificInsuranceRateQuery['organizationSpecificInsuranceRates']
                  >
                >
              >
            >,
            rate,
          ) => {
            if (!rate) {
              return acc;
            } else if (!(rate.group in acc)) {
              acc[rate.group] = [rate];
            } else {
              acc[rate.group] = [...acc[rate.group], rate];
            }
            return acc;
          },
          {},
        ),
      );
    }
  }, [data]);

  if (hasViewPermissions === false) {
    return <InsufficientPermissionsErrorPage />;
  }
  return (
    <PageContainer data-testid='rates-editor'>
      <TopBar>
        <Typography variant='h4' gutterBottom>
          Rates Manager
        </Typography>
        <NewRateButton
          groups={Object.keys(aggregatedRates).filter((key) => !!key)}
        />
      </TopBar>
      {loading ? <LoadingScreen /> : null}
      {error ? <RateEditorErrors error={error} /> : null}
      {!loading &&
      !error &&
      data?.organizationSpecificInsuranceRates &&
      data.organizationSpecificInsuranceRates.length === 0 ? (
        <NoRates />
      ) : null}
      {Object.entries(aggregatedRates)
        .sort(([aGroup, ..._restA], [bGroup, ..._restB]) =>
          aGroup.localeCompare(bGroup),
        )
        .map(([group, entries]) => {
          return (
            <RateGroupContainer key={group}>
              <Typography
                variant='h6'
                style={{ borderBottom: '1px solid #ddd' }}
              >
                {group}
              </Typography>

              <Grid container spacing={2} sx={{ pt: 2 }}>
                {
                  // @ts-expect-error
                  entries.map((rate) => {
                    return (
                      <RateChanger
                        key={rate.id}
                        rate={rate}
                        onComplete={() => {
                          if (token) {
                            organizationSpecificInsuranceRates({
                              variables: { token },
                            });
                          }
                        }}
                        groups={Object.keys(aggregatedRates).filter((k) => !!k)}
                      />
                    );
                  })
                }
              </Grid>
            </RateGroupContainer>
          );
        })}
      {/*
        Object.entries(categories).map(([categoryName, rates]) => (
        <div key={categoryName} style={{ marginBottom: '20px' }}>
          <Grid container spacing={2} style={{ paddingTop: '10px' }}>
            {rates.map((rate, index) => (
            ))}
          </Grid>
        </div>
      ))
         */}
    </PageContainer>
  );
};

const RateGroupContainer = styled('div')(({ theme }) => {
  return { padding: theme.spacing(1), marginBottom: theme.spacing(5) };
});
const PageContainer = styled('div')(({ theme }) => {
  return {
    padding: theme.spacing(3),
  };
});
const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

// const categories = {
//   Property: [
//     { label: 'High Risk', value: 0.267 },
//     { label: 'Medium Risk', value: 0.213 },
//     { label: 'Low Risk', value: 0.16 },
//     // Add more Property rates as needed
//   ],
//   Sewer: [
//     { label: 'Base Rate', value: 0.15 },
//     { label: 'Minimum', value: 500, symbol: '$' },
//     // Add more Property rates as needed
//   ],
//   'Commercial General Liability': [
//     { label: 'Low Premium', value: 0.03 },
//     { label: 'Medium Premium', value: 0.04 },
//     { label: 'High Premium', value: 0.05 },
//     { label: 'Low Minimum', value: 750, symbol: '$' },
//     { label: 'Medium Minimum', value: 1000, symbol: '$' },
//     { label: 'High Minimum', value: 1250, symbol: '$' },
//     // Add more Property rates as needed
//   ],
//   Earthquake: [
//     { label: 'E1 Low Risk', value: 0.09 },
//     { label: 'E1 Medium Risk', value: 0.11 },
//     { label: 'E1 High Risk', value: 0.14 },
//     { label: 'E2 Low Risk', value: 0.07 },
//     { label: 'E2 Medium Risk', value: 0.08 },
//     { label: 'E2 High Risk', value: 0.11 },
//     { label: 'E3 Low Risk', value: 0.06 },
//     { label: 'E3 Medium Risk', value: 0.07 },
//     { label: 'E3 High Risk', value: 0.09 },
//     { label: 'E4 Low Risk', value: 0.05 },
//     { label: 'E4 Medium Risk', value: 0.06 },
//     { label: 'E4 High Risk', value: 0.08 },
//     { label: 'E5 Low Risk', value: 0.03 },
//     { label: 'E5 Medium Risk', value: 0.04 },
//     { label: 'E5 High Risk', value: 0.05 },
//     { label: 'E6 Low Risk', value: 0.02 },
//     { label: 'E6 Medium Risk', value: 0.02 },
//     { label: 'E6 High Risk', value: 0.03 },
//     // Add more Earthquake rates as needed
//   ],
//   Flood: [{ label: 'Flood', value: 0 }],
// };
// };
