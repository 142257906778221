/**
 *
 *
 *
 *
 *
 *
 *
 * THIS IS BROKEN - CHANGES NEED TO BE MADE TO REFLECT THE FACT THAT POLICYEXTENSIONS ARE CREATED ATTACHED TO FORMS / QUESTIONS, BUT THEN NEED TO BE INSTANTIATED TO ANSWERS. THERE NEEDS TO BE A WAY TO LINK THEM!
 *
 *
 *
 *
 *
 *
 */
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Pagebreak } from '../utils';
import { Box, Typography, Divider } from '@mui/material';
// Number formatter
import currency from 'currency.js';

// classes and types
import { UnifiedAnswerInstance } from '../../../../Typescript/classes';
import { PolicyExtension } from '../../../../Typescript/backend/types';
import { QuotePdfComponentPageProps } from './types';

export const ExtensionsPage = ({ quote }: QuotePdfComponentPageProps) => {
  return null;
  // eslint-disable-next-line no-unreachable
  const hasExtensions = quote.completedForms.some(
    (form) => form.extensions.length > 0,
  );

  if (!hasExtensions) {
    return null;
  }

  return (
    <Box>
      <Typography variant='subtitle1' color='textSecondary'>
        Sub-Limits
      </Typography>

      <Divider sx={{ my: 2 }} />

      {quote.completedForms.map((form) => {
        if (!form.policy) {
          return null;
        }

        // Extract question instances
        const answers = flattenAnswers(form.answers);

        return form.extensions.map((extension) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              pb: 1,
            }}
          >
            <Typography variant='body2'>{extension.label}</Typography>
            <Typography variant='body2'>
              {getValue(extension, answers.flat())}
            </Typography>
          </Box>
        ));
      })}

      <Pagebreak />
    </Box>
  );
};

//------------------------------------------------------------------------------
// Util functions

/**
 * Formats any number entered to a currency and returns it as a string
 * @param {Number} number - dollar amount to be formatted
 */
function formatMoney(number: number) {
  return currency(number, { precision: 0 }).format();
}

/**
 * Check if the extension is missing a value, if so then compare its
 * id to the list of ids and values provided with questions. Once a match is found
 * display the value of that id.
 *
 * If no match is found. display an error message.
 *
 * @param {Object} extension - The current extension being iterated over
 * @param {Object} questions - An object containing the list of possible value and ids
 */
function getValue(
  extension: PolicyExtension,
  answers: Array<{
    id: UnifiedAnswerInstance['id'];
    value: UnifiedAnswerInstance['value'];
  }>,
) {
  if (extension.value) {
    return extension.value;
  } else if (extension.questionId) {
    // find object with matching id
    let extValue = answers.find(
      (question: $TSFixMe) => question.id === extension.questionId,
    );

    if (!extValue || isNaN(Number(extValue.value))) {
      return <Typography color='red'>Invalid Number Provided</Typography>;
    }

    // return formated value multiplied by multiplier
    return formatMoney(
      // @ts-expect-error
      extValue.value ? extValue.value * extension.multiplier : 0,
    );
  } else {
    return <Typography color='red'>Invalid Number Provided</Typography>;
  }
}

function flattenAnswers(answers: Array<UnifiedAnswerInstance>) {
  const flattenedAns: Array<{
    id: UnifiedAnswerInstance['id'];
    value: UnifiedAnswerInstance['value'];
  }> = [];
  answers.forEach((answer) => {
    // @ts-expect-error
    const id = answer.questionInstance.id;
    const value = answer.value;
    const obj = { id, value };
    flattenedAns.push(obj);

    const subAns = flattenAnswers(answer.subAnswers);
    flattenedAns.push(...subAns);
  });

  return flattenedAns;
}
