import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { InputToggle } from '@calefy-inc/informedMaterial';
import { useMutation } from '@apollo/client';
import { CREATE_INSURANCE_RATE } from '../../../../../queries';
import Autocomplete from '@mui/material/Autocomplete';
import {
  withFormStyles,
  WithFormStyles,
} from '../../../../../util/withFormStyles';
import { apiNameify } from '../../../../../util';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useSnackbar } from 'notistack';
// import { SizedDialog } from './components';

interface NewRateButtonProps {
  groups: Array<string>;
}
export const NewRateButton = ({ groups }: NewRateButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const hasCreatePermissions = useAllowByPermission('create:insurance:rates');
  return hasCreatePermissions === true ? (
    <>
      <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>
        Add Rate
      </Button>
      <NewRateDialog
        open={open}
        onClose={() => setOpen(false)}
        groups={groups}
      />
    </>
  ) : null;
};

interface NewRateDialogProps extends WithFormStyles {
  open: boolean;
  onClose: () => void;
  groups: Array<string>;
}
const NewRateDialog = withFormStyles()(
  ({ open, onClose, groups, classes }: NewRateDialogProps) => {
    const { token } = useAuth();
    const [displayName, setDisplayName] = useState<string>('');
    const [internalName, setInternalName] = useState<string>('');
    const [value, setValue] = useState<string>('0');
    const [group, setGroup] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('%');
    const [errors, setErrors] = useState<
      Record<'internalName' | 'displayName' | 'value', boolean>
    >({ internalName: false, displayName: false, value: false });
    const [touched, setTouched] = useState<
      Record<'internalName' | 'displayName' | 'value', boolean>
    >({ internalName: false, displayName: false, value: false });
    const { enqueueSnackbar } = useSnackbar();
    const [createInsuranceRate, { loading, error }] = useMutation(
      CREATE_INSURANCE_RATE,
      {
        onCompleted: () => {
          setDisplayName('');
          setInternalName('');
          setValue('0');
          setGroup('');
          setSymbol('%');
          setErrors({ internalName: false, displayName: false, value: false });
          setTouched({ internalName: false, displayName: false, value: false });

          enqueueSnackbar('Successfully created rate', { variant: 'success' });
          onClose();
        },
        update: (cache, results) => {
          const newRate = results.data?.createInsuranceRate?.rate;
          if (!newRate) {
            return;
          }
          cache.modify({
            id: cache.identify(newRate),
            broadcast: true,
            fields: {
              internalName: () => newRate.internalName,
              displayName: () => newRate.displayName,
              group: () => newRate.group,
              symbol: () => newRate.symbol,
              value: () => newRate.value,
            },
          });
          // now update the query
          // @ts-expect-error
          Object.keys(cache.data.data.ROOT_QUERY).forEach((key) => {
            if (key.match(/organizationSpecificInsuranceRates/i)) {
              cache.modify({
                fields: {
                  [key]: (previous, { toReference }) => {
                    const updatedReference = toReference(newRate);
                    if (!updatedReference) {
                      return previous;
                    }
                    return [...previous, updatedReference];
                  },
                },
              });
            }
          });
        },
      },
    );

    useEffect(() => {
      setInternalName(apiNameify(displayName));
    }, [displayName]);

    // when the required values change, check that they are still good
    useEffect(() => {
      setErrors({
        internalName: internalName.length === 0,
        displayName: displayName.length === 0,
        value:
          value === '' ||
          Number.isNaN(Number(value)) ||
          !Number.isFinite(Number(value)),
      });
    }, [value, internalName, displayName]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add New Rate</DialogTitle>
        <DialogContent style={{ paddingTop: '24px', width: '600px' }}>
          <Autocomplete
            className={classes.textfield}
            value={group}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label='Group'
                onChange={(e) => setGroup(e.target.value)}
              />
            )}
            options={groups}
          />
          <InputToggle
            className={classes.yesNoToggle}
            field='symbol'
            value={symbol}
            onChange={
              // @ts-expect-error
              (e) => setSymbol(e.target.value)
            }
            options={[
              { value: '$', label: '$' },
              { value: '%', label: '%' },
            ]}
          />
          <TextField
            className={classes.textfield}
            label='Display Name'
            value={displayName}
            required={true}
            onChange={(e) => {
              setDisplayName(e.target.value);
              setTouched((old) => ({ ...old, displayName: true }));
            }}
          />
          {touched.displayName && errors.displayName ? (
            <ErrorP>This field is required</ErrorP>
          ) : null}
          <TextField
            className={classes.textfield}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setTouched((old) => ({ ...old, value: true }));
            }}
            label='Value'
            required={true}
            InputProps={{
              startAdornment:
                symbol === '$' ? (
                  <InputAdornment position='start'>{symbol}</InputAdornment>
                ) : (
                  ''
                ),
              endAdornment:
                symbol === '%' ? (
                  <InputAdornment position='end'>{symbol}</InputAdornment>
                ) : (
                  ''
                ),
            }}
          />
          {touched.value && errors.value ? (
            value === '' ? (
              <ErrorP>This field is required</ErrorP>
            ) : (
              <ErrorP>{value} is not a valid number.</ErrorP>
            )
          ) : null}
          <TextField
            className={classes.textfield}
            value={internalName}
            required={true}
            label='Internal Name'
            onChange={(e) => {
              setInternalName(e.target.value);
              setTouched((old) => ({ ...old, internalName: true }));
            }}
          />
          {touched.internalName && errors.internalName ? (
            <ErrorP>This field is required</ErrorP>
          ) : null}
          {error ? <ErrorP>{error.message}</ErrorP> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
          <LoadingButton
            disabled={Object.values(errors).some((error) => error)}
            loading={loading}
            onClick={() => {
              createInsuranceRate({
                variables: {
                  internalName,
                  displayName,
                  token,
                  group,
                  symbol,
                  value,
                },
              });
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  },
);

const ErrorP = styled('p')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
