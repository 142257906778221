import { Box } from '@mui/material';
import {
  SummaryPage,
  FormPage,
  ExtensionsPage,
  SignaturePage,
  LocationsPage,
} from './components';
import { A4Width } from './utils';

// classes and types
import { Quote } from '../../../Typescript/backend/classes';

interface AssemberProps {
  quote: Quote;
}
/**
 * A component which assembles quotes based on certain quote data i.e organizations
 * Each organization is assigned a code. The assembler gets the organization from
 * the quote. Finds its matching code in the organization dictionary and structures
 * the pdf based on the code.
 *
 * @param quote - Quote to be converted to a pdf
 */
export const Assembler = ({ quote }: AssemberProps) => {
  return (
    <Box
      sx={{
        printingPaper: {
          width: A4Width,
        },
      }}
    >
      <SummaryPage quote={quote} />
      <FormPage quote={quote} />
      <SignaturePage quote={quote} />
      <LocationsPage quote={quote} />
      <ExtensionsPage quote={quote} />
    </Box>
  );
};
