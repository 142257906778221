import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import ReactApexChart from 'react-apexcharts';
import { getIcon } from '../../../utils/percentIcon';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
// utils
import { fNumber, fPercent } from '../../../utils/formatNumber';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import TotalTodaySkeleton from './skeletons/TotalTodaySkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { TotalQuotesMock } from './Mock';
// queries
import { DASHBOARD_TOTAL_QUOTES } from '../../../../../queries';

// ----------------------------------------------------------------------

export type monthlyQuotesType = {
  year: number;
  data: { name: string; data: number[] }[];
};

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

export default function AppTotalQuotes() {
  const theme = useTheme();
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const { data, loading, error } = useQuery(DASHBOARD_TOTAL_QUOTES, {
    variables: { token },
    skip:
      hasDashboardPermissions !== true ||
      !token ||
      slug === 'lastnameinsurance',
  });
  const [monthlyQuotes, setMonthlyQuotes] = useState<monthlyQuotesType[]>([]);
  const [percent, setPercent] = useState<number>();
  const [totalQuotes, setTotalQuotes] = useState<number>();
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setMonthlyQuotes(TotalQuotesMock.monthlyQuotes);
      setPercent(TotalQuotesMock.percent);
      setTotalQuotes(TotalQuotesMock.totalQuotes);
      setOkToDisplay(true);
    } else if (data && data.totalQuotesData) {
      // @ts-expect-error
      setMonthlyQuotes(data.totalQuotesData.monthlyQuotes);
      // @ts-expect-error
      setPercent(data.totalQuotesData.monthlyQuotePercentDiff);
      // @ts-expect-error
      setTotalQuotes(data.totalQuotesData.totalQuotes);
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  if (hasDashboardPermissions === false) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if (
    (loading || !okToDisplay || hasDashboardPermissions === undefined) &&
    !error
  )
    return <TotalTodaySkeleton />;
  if (error) {
    Bugsnag.notify(`Could not load Total Quotes component, ${error}`);
    return handleDashboardError(error);
  }

  const monthly =
    monthlyQuotes.length > 0 &&
    monthlyQuotes[monthlyQuotes.length - 1]?.data?.length !== 0
      ? monthlyQuotes[monthlyQuotes.length - 1]?.data[0]?.data
      : [];
  const CHART_DATA = [{ data: monthly }];

  const chartOptions = {
    colors: [theme.palette.info.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    labels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (_seriesName: string) => `Monthly Applications`,
        },
      },
      marker: { show: false },
    },
  };

  return (
    <Card
      sx={{ display: 'flex', alignItems: 'center', p: 3 }}
      data-testid='app-total-quotes'
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2'>Total Applications</Typography>

        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{ mt: 2, mb: 1 }}
        >
          {percent !== null && percent !== undefined ? (
            <>
              <IconWrapperStyle
                sx={{
                  ...(percent < 0 && {
                    color: 'error.main',
                    bgcolor: alpha(theme.palette.error.main, 0.16),
                  }),
                  ...(percent === 0 && {
                    color: theme.palette.grey[500],
                    bgcolor: alpha(theme.palette.grey[400], 0.16),
                  }),
                }}
              >
                <Icon width={16} height={16} icon={getIcon(percent)} />
              </IconWrapperStyle>
              <Typography component='span' variant='subtitle2'>
                {percent > 0 && '+'}
                {fPercent(percent)}
              </Typography>
              <Typography component='span' variant='caption'>
                Last 30 days
              </Typography>
            </>
          ) : (
            <Icon width={24} height={24} icon={getIcon(percent)} />
          )}
        </Stack>

        <Typography variant='h3'>{fNumber(totalQuotes)}</Typography>
      </Box>

      <ReactApexChart
        type='bar'
        series={CHART_DATA}
        //@ts-expect-error
        options={chartOptions}
        width={60}
        height={36}
      />
    </Card>
  );
}
