// material
import { Card, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function ErrorComponent() {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        height: '100%',
      }}
    >
      <Stack direction='column' alignItems='center' spacing={1}>
        <Typography variant='subtitle2' color='textSecondary'>
          Something went wrong
        </Typography>
      </Stack>
    </Card>
  );
}

/**
 * Mini error component for the dashboard - replaces a graph / whatever if the permissions are not correct
 */
export const InsufficientPermissionsErrorComponent = () => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        height: '100%',
      }}
    >
      <Stack direction='column' alignItems='center' spacing={1}>
        <Typography variant='subtitle2' color='textSecondary'>
          You don't have permission to view this
        </Typography>
      </Stack>
    </Card>
  );
};
