import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
  },
  text: {},
  container: {},
}));

export const OptaError = () => {
  const classes = useStyles();

  return (
    <div data-testid='optaError'>
      <Typography variant='h3' gutterBottom className={classes.heading}>
        Unable to Fetch Data
      </Typography>
      <Typography component='p' className={classes.text}>
        This may indicate that there is no data available for this property or
        that there is an error with the provider. You may be able to try again
        later.
      </Typography>
    </div>
  );
};
