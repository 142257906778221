import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderQuestionInstance } from '../../../FormManager/classes';

const useStyles = makeStyles((theme: $TSFixMe) => ({
  questionWrapper: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    display: 'inline',
  },
  successQuestionWrapper: {
    backgroundColor: theme.palette.success.lighter,
  },
  fallbackQuestionWrapper: {
    backgroundColor: theme.palette.error.lighter,
  },
}));

interface DisplayFormattedQuestionIdentifierProps {
  questionInstance: undefined | null | ProgramBuilderQuestionInstance;
  fallback?: string;
}
/**
 * Textually display a question instance by identifier (displayName, &c.) in a visually pleasing way
 * @param questionInstance - The questionInstance to display. If this is null or undefined, then the fallback is presented (if present) - otherwise null
 * @param fallback - The text to be displayed in the place of the questionInstance identifier if the passed-in questionInstance is null or undefined
 */
export const DisplayFormattedQuestionIdentifier = ({
  questionInstance,
  fallback,
}: DisplayFormattedQuestionIdentifierProps) => {
  const classes = useStyles();

  if (!questionInstance && !fallback) {
    return null;
  }
  let textToDisplay: string | undefined;
  let displayTextFromQuestion: boolean = false;
  if (!questionInstance && fallback) {
    textToDisplay = fallback;
  }
  if (questionInstance) {
    textToDisplay = questionInstance.generateDisplayName();
    displayTextFromQuestion = true;
  }
  if (!textToDisplay) {
    return null;
  }
  return (
    <Typography
      className={[
        classes.questionWrapper,
        displayTextFromQuestion
          ? classes.successQuestionWrapper
          : classes.fallbackQuestionWrapper,
      ].join(' ')}
    >
      {textToDisplay}
    </Typography>
  );
};
