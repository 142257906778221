// types
import type {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../../../classes';
import { ValidationFunction } from '../types';

const findQuestionInstanceById = (
  questionInstances: Array<ProgramBuilderQuestionInstance>,
  id: string,
): ProgramBuilderQuestionInstance | undefined => {
  for (const questionInstance of questionInstances) {
    if (questionInstance.id === id || questionInstance.clientId === id) {
      return questionInstance;
    }
    const foundInSubquestions = findQuestionInstanceById(
      questionInstance.subQuestions,
      id,
    );
    if (foundInSubquestions) {
      return foundInSubquestions;
    }
  }
  return undefined;
};

/**
 * Compares the current form extentions with its question instances and
 * makes sure all extension question instances still exist with the form.
 *
 * @param form - Form whos extension will be checked for any errors.
 */
export const validateExtensions: ValidationFunction = (
  form: ProgramBuilderForm,
) => {
  // Get this current form extensions and question instances
  const extensions = form.policyExtensions;

  if (!extensions) {
    return [];
  }

  const errors: Array<string> = [];

  // filter the extensions with question ids
  const extensionsWithQuestionId = extensions.filter(
    // @ts-expect-error
    (extension) => extension.questionId,
  );

  // Check that each extensions question still exist in the
  // question instance list
  extensionsWithQuestionId.forEach((ext) => {
    const matchingQuestion = findQuestionInstanceById(
      form.questionInstances,
      // @ts-expect-error
      ext.questionId,
    );
    if (!matchingQuestion) {
      errors.push(
        `Extension with label "${ext.label}" refers to a question which no longer exists on the form. Please fix this before submitting the form.`,
      );
    }
  });

  return errors;
};
