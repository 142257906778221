import { graphql } from '../../gql';

export const VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION = graphql(`
  query vagoDownloadApplicationDocumentation($token: String!, $quoteUuid: ID!) {
    vagoDownloadApplicationDocumentation(token: $token, quoteUuid: $quoteUuid) {
      filename
      data
    }
  }
`);
