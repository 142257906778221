// material
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// routes

// hooks
import { useState, useEffect, ChangeEvent, BaseSyntheticEvent } from 'react';
import {
  ApolloError,
  // useLazyQuery,
  useQuery,
  useMutation,
} from '@apollo/client';
// components
import { TextField } from '@calefy-inc/informedMaterial';
import { useSnackbar } from 'notistack';
// queries
import { CREATE_INSURER_QUOTE } from '../../../../../queries/mutations/createInsurerQuote';
import { DELETE_INSURER_QUOTES } from '../../../../../queries/mutations/deleteInsurerQuotes';
import { APPLICATION_SPECIFIC_INSURER_QUOTES } from '../../../../../queries/queries/applicationSpecificInsurerQuotes';
// error handler

// pages

// classes and types
import { headCells, createRowData } from './cellUtils';
import { InsurerQuoteRowData } from './cellUtils';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Link as RouterLink } from '@reach/router';
import { CreateInsurerQuoteMutation } from '../../../../../gql/graphql';
import { validateRowData } from './jsonUtility';
import { useAuth } from '@calefy-inc/authentication';

interface insurerQuoteDetailsTableProps {
  uniqueID?: string;
  selectedInsurerQuotes: readonly InsurerQuoteRowData[];
  setSelectedInsurerQuotes: React.Dispatch<
    React.SetStateAction<readonly InsurerQuoteRowData[]>
  >;
  RootStyle: $TSFixMe;
}

export const InsurerQuoteDetailsTable = ({
  uniqueID,
  selectedInsurerQuotes,
  setSelectedInsurerQuotes,
  RootStyle,
}: insurerQuoteDetailsTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const [rows, setRows] = useState<InsurerQuoteRowData[]>([]);
  const [openInvalidLLMResponseModal, setOpenInvalidLLMResponseModal] =
    useState(false);
  const [
    createInsurerQuote,
    { loading: createLoading, error: createError, data: newInsurerQuoteData },
  ] = useMutation(CREATE_INSURER_QUOTE);
  const {
    loading: queryLoading,
    // @ts-expect-error
    error: queryError,
    data: insurerQuotesData,
  } = useQuery(APPLICATION_SPECIFIC_INSURER_QUOTES, {
    variables: {
      token,
      quoteUuid: uniqueID as string,
      skip: !token,
    },
  });
  const [
    deleteInsurerQuotes,
    {
      data: deleteInsurerQuotesData,
      // @ts-expect-error
      loading: deleteInsurerQuotesLoading,
      // @ts-expect-error
      error: deleteInsurerQuotesError,
    },
  ] = useMutation(DELETE_INSURER_QUOTES);

  useEffect(() => {
    if (
      insurerQuotesData &&
      insurerQuotesData.applicationSpecificInsurerQuotes
    ) {
      insurerQuotesData.applicationSpecificInsurerQuotes.forEach(
        (insurerQuote, index) => {
          const quoteData = JSON.parse(insurerQuote?.llmResponseBlob);
          //console.log(quoteData);
          setRows((prevRows) => [
            ...prevRows,
            createRowData(
              index,
              validateRowData(quoteData, 'Quote Issuer'),
              validateRowData(quoteData, ['Date', 'Policy Effective Date']),
              validateRowData(quoteData, 'Total Premium'),
              validateRowData(quoteData, 'Total Limit'),
              insurerQuote?.insurerQuoteIdentifier ?? 'N/A',
              insurerQuote?.isValid ?? false,
            ),
          ]);
        },
      );
    }
  }, [insurerQuotesData]);

  useEffect(() => {
    //console.log('in useeffect');
    if (newInsurerQuoteData) {
      //console.log('file detected');
      const quoteData = JSON.parse(
        newInsurerQuoteData?.createInsurerQuote?.insurerQuote?.llmResponseBlob,
      );
      //console.log(JSON.stringify(quoteData, null, 2));
      setRows((prevRows) => [
        ...prevRows,
        createRowData(
          rows.length,
          validateRowData(quoteData, 'Quote Issuer'),
          validateRowData(quoteData, ['Date', 'Policy Effective Date']),
          validateRowData(quoteData, 'Total Premium'),
          validateRowData(quoteData, 'Total Limit'),
          newInsurerQuoteData?.createInsurerQuote?.insurerQuote
            ?.insurerQuoteIdentifier ?? 'N/A',
          newInsurerQuoteData?.createInsurerQuote?.insurerQuote?.isValid ??
            false,
        ),
      ]);
      if (!newInsurerQuoteData.createInsurerQuote?.insurerQuote?.isValid) {
        //console.log('Warning: insurer quote is invalid');
        setOpenInvalidLLMResponseModal(true);
      }
    }
  }, [newInsurerQuoteData]);

  useEffect(() => {
    if (deleteInsurerQuotesData) {
      //console.log('file deleted');
      const deletedInsurerQuotes =
        deleteInsurerQuotesData.deleteInsurerQuotes?.deletedInsurerQuotes;
      if (deletedInsurerQuotes && deletedInsurerQuotes.length > 0) {
        let deletedIDs = deletedInsurerQuotes.map(
          (insurerQuote) => insurerQuote?.insurerQuoteIdentifier,
        );
        const updatedRows = rows.filter(
          (row) => !deletedIDs.includes(row.quoteIdentifier),
        );
        //console.log({ updatedRows });
        setRows(updatedRows);
        setSelectedInsurerQuotes([]);
      }
    }
  }, [deleteInsurerQuotesData]);

  useEffect(() => {
    if (createError) {
      let errorMessage = 'Error uploading quote';
      if (createError.message)
        errorMessage = errorMessage.concat(': ', createError.message);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
    //console.log({ createError });
  }, [createError, enqueueSnackbar]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const rowsWithoutInvalid = rows.filter((row) => row.isValid);
      setSelectedInsurerQuotes(rowsWithoutInvalid);
      return;
    }
    setSelectedInsurerQuotes([]);
  };

  const handleSelectClick = (
    event: BaseSyntheticEvent,
    row: InsurerQuoteRowData,
  ) => {
    if (event.target.closest('button')) {
      return;
    }
    const selectedIndex = selectedInsurerQuotes.findIndex(
      (rowInSelected) => rowInSelected.tempTableID === row.tempTableID,
    );
    let newSelected: readonly InsurerQuoteRowData[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedInsurerQuotes, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedInsurerQuotes.slice(1));
    } else if (selectedIndex === selectedInsurerQuotes.length - 1) {
      newSelected = newSelected.concat(selectedInsurerQuotes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedInsurerQuotes.slice(0, selectedIndex),
        selectedInsurerQuotes.slice(selectedIndex + 1),
      );
    }
    setSelectedInsurerQuotes(newSelected);
  };

  const isSelected = (id: number) =>
    selectedInsurerQuotes.findIndex(
      (rowInSelected) => rowInSelected.tempTableID === id,
    ) !== -1;

  const handleDeleteInsurerQuote = async (
    _event: React.MouseEvent<unknown>,
    quoteID: string,
  ) => {
    try {
      await deleteInsurerQuotes({
        variables: {
          parentQuoteUuid: uniqueID,
          insurerQuoteIds: [quoteID],
        },
      });
      //console.log(deleteInsurerQuotesData);
    } catch (e) {
      enqueueSnackbar('Error uploading file', { variant: 'error' });
      console.error('Error uploading file:', e);
    }
  };

  return (
    <>
      <RootStyle>
        <Typography variant='h4'>Quotes</Typography>
        <UploadQuoteButton
          createInsurerQuote={createInsurerQuote}
          loading={createLoading}
          error={createError}
          data={newInsurerQuoteData}
          quoteUuid={uniqueID}
          existingQuoteIdentifiers={rows.map((row) => row.quoteIdentifier)}
        />
      </RootStyle>
      <Typography variant='body2'>
        WARNING: The data displayed on this page was extracted from quotes using
        generative AI.
      </Typography>
      <Typography variant='body2' sx={{ mb: 4 }}>
        Please cross check important information with the original pdf, as AI
        can make mistakes.
      </Typography>

      <TableContainer sx={{ minWidth: 800, minHeight: 200 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  indeterminate={
                    selectedInsurerQuotes.length > 0 &&
                    selectedInsurerQuotes.length <
                      rows.filter((row) => row.isValid).length
                  }
                  checked={
                    rows.length > 0 &&
                    selectedInsurerQuotes.length ===
                      rows.filter((row) => row.isValid).length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={`head-cell-${headCell.tempTableID}`}
                  align={headCell.icon ? 'center' : 'left'}
                  padding='normal'
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {queryLoading ? (
              <TableRowsLoader rowsNum={3} colsNum={8} />
            ) : (
              rows.map((row, index) => {
                const isItemSelected = isSelected(Number(row.tempTableID));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleSelectClick(event, row)}
                    role='checkbox'
                    tabIndex={-1}
                    key={`table-row-${row.tempTableID}`}
                    selected={isItemSelected}
                    // sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        disabled={!row.isValid}
                        onClick={(event) => handleSelectClick(event, row)}
                      />
                    </TableCell>
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='none'
                      sx={{ color: !row.isValid ? 'error.main' : '' }}
                    >
                      {row.quoteIssuer}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ color: !row.isValid ? 'error.main' : '' }}
                    >
                      {row.date}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ color: !row.isValid ? 'error.main' : '' }}
                    >
                      {row.premium}
                    </TableCell>
                    <TableCell
                      align='left'
                      sx={{ color: !row.isValid ? 'error.main' : '' }}
                    >
                      {row.limit}
                    </TableCell>
                    <TableCell align='left'>
                      <Link
                        component={RouterLink}
                        to={`/insurtech/manager/quotes/details/${uniqueID}/${row.quoteIdentifier}`}
                        sx={{ color: !row.isValid ? 'error.main' : '' }}
                      >
                        <Stack direction='row' spacing={1}>
                          <Box>{row.quoteIdentifier}</Box>
                          <Box sx={{ display: 'inline', height: '100%' }}>
                            <CallMadeRoundedIcon fontSize='small' />
                          </Box>
                        </Stack>
                      </Link>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        aria-label='Delete Quote File'
                        onClick={(event) =>
                          handleDeleteInsurerQuote(event, row.quoteIdentifier)
                        }
                        // component='span'
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        aria-label='View Quote File'
                        // component='span'
                      >
                        <SaveAltRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InvalidLLMResponseModal
        openModal={openInvalidLLMResponseModal}
        handleCloseModal={() => setOpenInvalidLLMResponseModal(false)}
      />
    </>
  );
};

const TableRowsLoader = ({
  rowsNum,
  colsNum,
}: {
  rowsNum: number;
  colsNum: number;
}) => {
  return (
    <>
      {[...Array(rowsNum)].map((_row, rowIndex) => {
        return (
          <TableRow key={`loader-row-${rowIndex}`}>
            {[...Array(colsNum)].map((_col, colIndex) => (
              <TableCell key={`loader-col-${rowIndex}-${colIndex}`}>
                <Skeleton animation='wave' variant='text' />
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface UploadQuoteButtonProps {
  createInsurerQuote: any;
  loading: boolean;
  error: ApolloError | undefined;
  data: CreateInsurerQuoteMutation | null | undefined;
  quoteUuid: string | undefined;
  existingQuoteIdentifiers: string[];
}

interface ManualInputProps {
  insurerQuoteUniqueID: string;
}

const defaultInputValues = {
  insurerQuoteUniqueID: '',
};

const UploadQuoteButton = ({
  createInsurerQuote,
  loading,
  // @ts-expect-error
  error,
  // @ts-expect-error
  data,
  quoteUuid,
  existingQuoteIdentifiers,
}: UploadQuoteButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fileB64, setFileB64] = useState<string | null>(null);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [inputValues, setInputValues] =
    useState<ManualInputProps>(defaultInputValues);

  const handleCloseFileModal = () => setOpenFileModal(false);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      //console.log('just received file');
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileB64(reader.result as string);
      };
      reader.onerror = (error) => {
        console.error('Error: ', error);
      };
      //console.log('just set file');
      setOpenFileModal(true);
    }
  };

  const handleFileSubmit = async (event: any) => {
    event.preventDefault();
    handleCloseFileModal();
    if (fileB64 && quoteUuid) {
      //console.log('fileb64 and quoteuuid exist');
      //console.log('Insurer quote unique id:', inputValues.insurerQuoteUniqueID);
      try {
        await createInsurerQuote({
          variables: {
            quoteUuid,
            insurerQuoteIdentifier:
              !inputValues.insurerQuoteUniqueID ||
              inputValues.insurerQuoteUniqueID.trim() === ''
                ? 'noIdentifier'
                : inputValues.insurerQuoteUniqueID,
            pdfFile: fileB64,
          },
        });
      } catch (e) {
        enqueueSnackbar('Error uploading file', { variant: 'error' });
        console.error('Error uploading file:', e);
      }
    }
    setInputValues(defaultInputValues);
  };

  return (
    <>
      <LoadingButton
        component='label'
        role={undefined}
        variant='contained'
        startIcon={<CloudUploadIcon />}
        loading={loading}
      >
        Upload Quote
        <VisuallyHiddenInput
          type='file'
          accept='application/pdf'
          onChange={handleFileUpload}
        />
        <ConfirmUploadQuoteModal
          openModal={openFileModal}
          handleCloseModal={handleCloseFileModal}
          onClickAction={handleFileSubmit}
          inputValues={inputValues}
          setInputValues={setInputValues}
          disallowedIdentifiers={existingQuoteIdentifiers}
        />
      </LoadingButton>
    </>
  );
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const ConfirmUploadQuoteModal = ({
  openModal,
  handleCloseModal,
  onClickAction,
  inputValues,
  setInputValues,
  disallowedIdentifiers,
}: {
  openModal: boolean;
  handleCloseModal: () => void;
  onClickAction: (event: any) => Promise<void>;
  inputValues: ManualInputProps;
  setInputValues: React.Dispatch<React.SetStateAction<ManualInputProps>>;
  disallowedIdentifiers: string[];
}) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        <Typography variant='h6' component='h2'>
          Please enter any additional info about this quote.
        </Typography>
        <Tooltip
          placement='left'
          title='Leave this field blank to have a quote identifier generated for you.'
        >
          <TextField
            fullWidth
            field='uniqueID'
            label='Quote Unique Identifier'
            helperText=''
            variant='standard'
            value={inputValues.insurerQuoteUniqueID}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInputValues((oldInputValues) => ({
                ...oldInputValues,
                insurerQuoteUniqueID: event.target.value,
              }))
            }
          />
        </Tooltip>
        <Box sx={{ mt: 2 }}>
          <Button
            onClick={onClickAction}
            disabled={disallowedIdentifiers.includes(
              inputValues.insurerQuoteUniqueID,
            )}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const InvalidLLMResponseModal = ({
  openModal,
  handleCloseModal,
}: {
  openModal: boolean;
  handleCloseModal: () => void;
}) => {
  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        <Typography variant='h6' component='h2'>
          Warning: the quote summary for this PDF was invalid.
        </Typography>
        <Box>
          <Typography variant='body1' color='textSecondary'>
            Please check that the PDF uploaded has readable text. If it doesn't
            it cannot be parsed by AI.
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant='body1' color='textSecondary'>
            Note that you will be able to download the quote but be unable to
            view its summary or compare it with other quotes.
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button onClick={handleCloseModal}>Ok</Button>
        </Box>
      </Box>
    </Modal>
  );
};
