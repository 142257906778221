import { useState, useEffect, useMemo, useRef } from 'react';
import NProgress from 'nprogress';
import { motion } from 'framer-motion';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';
import Typography from '@mui/material/Typography';
//

import Logo from './Logo';
// ----------------------------------------------------------------------

// @ts-expect-error
const RootStyle = styled('div')(({ theme }) => ({
  height: '60vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'transparent',
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle() {
  const theme = useTheme();
  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`,
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
          },
        },
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

/**
 * This is the spinning one with the Calefy (or whatever) logo in the middle
 * @param name - description
 * @returns - description
 */
export default function LoadingScreen({ ...other }) {
  const [longTime, setLongTime] = useState<boolean>(false); // has it been a long time
  const longTimeTimeout = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    const timeout = setTimeout(() => setLongTime(true), 5000);
    longTimeTimeout.current = timeout;
    return () => {
      if (longTimeTimeout.current) clearTimeout(longTimeTimeout.current);
    };
  }, []);
  return (
    <>
      <ProgressBar />

      <RootStyle {...other}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          data-testid='loading-screen'
        >
          <motion.div
            initial={{ rotateY: 0 }}
            animate={{ rotateY: 360 }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <Logo sx={{ width: 44, height: 44 }} />
          </motion.div>

          <Box
            component={motion.div}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 70,
              height: 70,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) =>
                `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />

          <Box
            component={motion.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 90,
              height: 90,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) =>
                `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />
        </div>
        <Typography
          sx={{ pt: '45px' }}
          style={{
            opacity: longTime ? 1 : 0,
            transition: 'opacity 1s',
          }}
          color='textSecondary'
        >
          This is taking longer than usual...
        </Typography>
      </RootStyle>
    </>
  );
}
