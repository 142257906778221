import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { EditPerilInput } from '../../../../../gql/graphql';
import { Peril } from '../../../../../Typescript/classes';
import { StyledTr, StyledTd } from './PremiumOverridesTable';
import MaterialTextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import { stringToNumber } from '../../../../../util';
import {
  displayPerilPremium,
  // displayPerilRate,
  displayPerilLimits,
} from './utility';
import { useAuth } from '@calefy-inc/authentication';
import { AlreadyBoundTooltip } from './AlreadyBoundTooltip';

interface CombinedPerilsRowProps {
  perils: Array<Peril>;
  updatePerils: Dispatch<SetStateAction<Array<EditPerilInput>>>;
  sumPremiums?: boolean; // whether to sum each premium for the combined premium. This is to guard against the fact that some perils have the full premium applied to each propert.
  bound?: boolean;
}
/**
 * This component combines the premiums from a bunch of perils and then distributes any edited premium among them. This is useful for instance in the case where there are perils associated with different locations but the coverage is paid for as one.
 */
export const CombinedPerilsRow = ({
  perils: originalPerils,
  updatePerils,
  sumPremiums = true,
  bound,
}: CombinedPerilsRowProps) => {
  const { token } = useAuth();
  const [perils, setPerils] = useState<typeof originalPerils>(originalPerils);
  const [displayValue, setDisplayValue] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [limit, setLimit] = useState<number>(0);
  const [override, setOverride] = useState<boolean>(
    originalPerils.some((peril) => peril.override),
  );
  const [combinedPremium, setCombinedPremium] = useState<number>(0);
  const [isMatch, setIsMatch] = useState<boolean>(true);

  const calculateCombinedPremium = useCallback(
    (perils: typeof originalPerils) => {
      if (sumPremiums) {
        return perils.reduce((acc, peril) => {
          return acc + peril.premium;
        }, 0);
      } else {
        return perils.length === 0 ? 0 : perils[0].premium;
      }
    },
    [sumPremiums],
  );

  useEffect(() => {
    updatePerils((oldPerils) => {
      const updated = perils.reduce(
        (acc, peril) => {
          // remove any matching perils from the list to update;
          acc = acc.filter(
            (originalPeril) => originalPeril.perilId !== peril.id,
          );

          // them add this in if it's different from every original peril
          if (
            originalPerils.every((originalPeril) => !peril.match(originalPeril))
          ) {
            acc.push({
              perilId: peril.id,
              premium: String(peril.premium),
              override: peril.override,
            });
          }
          return acc;
        },
        [...oldPerils],
      );
      return updated;
    });
  }, [perils]);

  // update the perils whenever the original ones change
  useEffect(() => {
    setPerils([...originalPerils]);
  }, [originalPerils]);

  // set the various attributes whenever the perils change
  useEffect(() => {
    // combined value
    const combinedPremium = calculateCombinedPremium(perils);
    setCombinedPremium(combinedPremium);

    // display name
    setDisplayName(
      perils.length > 0 ? perils[0].displayName || perils[0].name : '',
    );

    // limits
    setLimit(perils.length > 0 ? perils[0].limit : 0);

    // override
    setOverride(perils.some((peril) => peril.override));
  }, [perils]);

  // update the display value whenever the combined premium changes
  useEffect(() => {
    setDisplayValue(displayPerilPremium(combinedPremium));
  }, [combinedPremium]);

  // whenever we change anything, check to see if we're matching
  useEffect(() => {
    setIsMatch(
      originalPerils.length === perils.length &&
        perils.every((peril) =>
          originalPerils.some((originalPeril) => peril.match(originalPeril)),
        ),
    );
  }, [originalPerils, perils]);

  if (perils.length === 0) {
    return null;
  }
  return (
    <StyledTr
      key={perils.map((peril) => peril.id).join('_')}
      sx={
        !isMatch
          ? {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }
          : {}
      }
    >
      <StyledTd>
        <AlreadyBoundTooltip bound={bound}>
          <span>{displayName}</span>
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={bound}>
          <span>{displayPerilLimits(limit)}</span>
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>{/* rates */}</StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={bound}>
          <span>
            {override ? (
              <MaterialTextField
                fullWidth
                variant='standard'
                value={displayValue}
                disabled={bound}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setDisplayValue(e.target.value);
                }}
                onBlur={() => {
                  const converted = stringToNumber(displayValue);
                  if (converted === null) {
                    setDisplayValue(
                      displayPerilPremium(calculateCombinedPremium(perils)),
                    );
                  } else {
                    setDisplayValue(displayPerilPremium(converted));
                    const portion = sumPremiums
                      ? converted / perils.length
                      : converted;
                    setPerils((oldPerils) =>
                      oldPerils.map((oldPeril) =>
                        oldPeril.copyWithAmendments({ premium: portion }),
                      ),
                    );
                  }
                }}
              />
            ) : (
              displayPerilPremium(combinedPremium, { displayUnit: true })
            )}
          </span>
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={bound}>
          <Checkbox
            disabled={
              bound || !token || originalPerils.some((peril) => peril.override)
            }
            checked={override}
            onChange={(e) => {
              setPerils((oldPerils) => {
                const checked = e.target.checked;
                return oldPerils.map((oldPeril) => {
                  if (checked) {
                    return oldPeril.copyWithAmendments({
                      override: checked,
                    });
                  } else {
                    const matching = originalPerils.find((original) =>
                      original.match(oldPeril),
                    );
                    if (matching) {
                      return matching.copy();
                    }
                    // we should never be here, but just in case...
                    return oldPeril.copy();
                  }
                });
              });
            }}
          />
        </AlreadyBoundTooltip>
      </StyledTd>
    </StyledTr>
  );
};
