import {
  ThemeProvider,
  StyledEngineProvider,
  useTheme,
} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Link, useMediaQuery } from '@mui/material';
import { useSettings } from '../../hooks';
import { useUsingWalnutSettings } from '../../WhiteLabelSettings/Walnut/useUsingWalnutSettings';
import { SaveQuoteButton } from './SaveQuoteButton/SaveQuoteButton';
import { navigate } from '@reach/router';
import { useIsManager } from '../../hooks';
import { styled } from '@mui/material';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const useStyle = () => {
  const onWalnut = useUsingWalnutSettings();
  const styles = makeStyles((theme: $TSFixMe) => ({
    wizard: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'space-between',
      overflowX: 'hidden',
      /* backgroundColor: (isManager) => !isManager ? theme.palette.background.neutral : theme.palette.background.neutral, */
    },
    header: {
      position: 'sticky',
      top: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxHeight: theme.spacing(12),
      // margin: `0 ${theme.spacing(6)}`,
      margin: 0,
      padding: theme.spacing(8),
      '& img': {
        maxWidth: theme.spacing(37.5),
        alignSelf: 'center',
        [theme.breakpoints.down('xl')]: {
          maxWidth: theme.spacing(27.5),
        },
      },
      '& button': {
        alignSelf: 'center',
      },
      [theme.breakpoints.down('xl')]: {
        maxHeight: theme.spacing(8),
        margin: 0,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between',
      },
    },
    container: {
      flex: 1,
      minHeight: onWalnut ? '80vh' : '60vh',
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    calefy: {
      alignSelf: 'center',
      paddingBottom: theme.spacing(1),
    },
  }));
  return styles();
};

/**
 * A Component which renders a quote wizard with specific theme and settings
 * @param  children - child that is wrapped by this component
 * @returns Quote Wizard
 */
const WhiteLabel = ({ children }: { children: $TSFixMe }) => {
  const { isManager } = useIsManager();
  const { companyTheme, logo, title, favicon, ...settings } = useSettings();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyle();

  if (!isManager) {
    document.getElementsByTagName('title')[0].innerHTML = title;
    const iconElement = document.querySelector(
      "link[rel*='icon']",
    ) as HTMLLinkElement;
    if (iconElement) {
      iconElement.href = favicon;
    }
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={!isManager ? companyTheme : theme}>
        <div className={classes.wizard}>
          <StyledHeader
            className={classes.header}
            sx={settings.headerCss ? settings.headerCss : undefined}
          >
            {!isManager ? (
              settings.slug === 'walnut-atb' ? (
                <img src={logo} alt='Logo' style={{ height: '125px' }} />
              ) : (
                <img src={logo} alt='Logo' />
              )
            ) : null}
            {matches ? (
              <div style={{ paddingTop: theme.spacing(2) }}></div>
            ) : null}
            <SaveQuoteButton />
          </StyledHeader>
          <Container fixed className={classes.container}>
            {children}
          </Container>
          {settings.Footer ? (
            <settings.Footer />
          ) : (
            <div className={classes.calefy}>
              <Typography
                variant='caption'
                color='textSecondary'
                style={{ fontWeight: 600 }}
              >
                {!isManager ? (
                  <Link
                    color='inherit'
                    underline='none'
                    onClick={() => navigate('/insurtech/manager/dashboard')}
                  >
                    Power
                  </Link>
                ) : (
                  'Power'
                )}
                ed by{' '}
                <Link
                  color='primary'
                  underline='none'
                  href='https://calefy.ca/'
                  target='_blank'
                  rel='noopener'
                >
                  Calefy
                </Link>
              </Typography>
            </div>
          )}
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default WhiteLabel;

const StyledHeader = styled('header')({});
