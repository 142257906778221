import { EmailInput } from '@calefy-inc/informedMaterial';

import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

// types and classes
import { Component } from './classes';
import { QuoteComponentProps } from './types';

const ProgramBuilderComponent = () => {
  return <EmailInput field='defaultValue' label='Default Value' />;
};
const quoteComponent = function ({
  questionInstance,
  classes,
  ...props
}: QuoteComponentProps) {
  return (
    <EmailInput
      label={questionInstance.label}
      field={questionInstance.generateFieldName()}
      id={questionInstance.id}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes && classes.emailinput ? classes.emailinput : ''}
      classes={classes}
      {...props}
    />
  );
};

const IconComponent = () => {
  return <EmailRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'emailinput',
    typeLabel: 'Email Input',
    quoteComponent,
    programBuilderComponent: ProgramBuilderComponent,
    dataType: 'string',
    icon: IconComponent,
  });
}

export { quoteComponent };
