import { useEffect, useState, useCallback } from 'react';
import { Theme } from '@mui/material';
import { scaleLog } from '@visx/scale';
import useTheme from '@mui/material/styles/useTheme';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { WordcloudType } from '../../../../../../gql/graphql';

/*************** INTERFACES, &C. ***************/

export interface WordData {
  text: string;
  value: number;
}

type SpiralType = 'archimedean' | 'rectangular';

/*************** CONSTANTS ***************/
export const WORDCLOUD_HEIGHT = 300;
export const WORDCLOUD_WIDTH = 300;
export const WORDCLOUD_SPIRAL_TYPE: SpiralType = 'rectangular';
export const WORDCLOUD_PADDING = 2;
const WORDCLOUD_MIN_FONT_SIZE = 15;
const WORDCLOUD_MAX_FONT_SIZE = 30;

/*************** FUNCTIONS ***************/
export const generateColours = (theme: Theme) => {
  return [
    theme.palette.primary.dark,
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.secondary.dark,
    theme.palette.secondary.light,
    theme.palette.secondary.main,
  ];
};

export function getRotationDegree(word: WordData, _index: number) {
  const rand = Math.random();
  const { text } = word;
  const degree = text.length > 4 ? 0 : rand > 0.5 ? 90 : 0;
  return degree;
}

export const generateFontScale = (words: Array<WordData>) => {
  if (words.length > 0) {
    const sizes = words.map(({ value }) => value);
    const fontScale = scaleLog({
      domain: [Math.min(...sizes), Math.max(...sizes)],
      range: [WORDCLOUD_MIN_FONT_SIZE, WORDCLOUD_MAX_FONT_SIZE],
    });
    return fontScale;
  }
  return () => WORDCLOUD_MIN_FONT_SIZE;
};
export const generateFontSizeSetter = (words: Array<WordData>) => {
  const scaler = generateFontScale(words);
  return (datum: WordData) => {
    const fontSize = scaler(datum.value);
    return fontSize;
  };
};

export const fixedValueGenerator = () => 0.5;

export const generateWordcloudFont = (theme: Theme) => {
  return theme.typography.h1.fontFamily;
};

export const convertToWordData = (
  returned: Array<WordcloudType>,
): Array<WordData> =>
  // @ts-expect-error
  returned
    .filter((backendWordcloud) => !!backendWordcloud.value)
    .map((backendWordcloud) => ({
      text: backendWordcloud.value,
      value: Number(backendWordcloud.frequency),
    }));

/*************** FIXED OPTIONS ***************/

export const WORDCLOUD_FIXED_OPTIONS = {
  width: WORDCLOUD_WIDTH,
  height: WORDCLOUD_HEIGHT,
  spiral: WORDCLOUD_SPIRAL_TYPE,
  random: fixedValueGenerator,
  padding: WORDCLOUD_PADDING,
  rotate: getRotationDegree,
};

export const useWordcloudOptions = (words: Array<WordData>) => {
  const theme = useTheme();

  const fontSizeSetter = useCallback(generateFontSizeSetter(words), [words]);
  const [font, setFont] =
    useState<ReturnType<typeof generateWordcloudFont>>('Impact');

  // generate the theme-specific properties
  useEffect(() => {
    if (theme) {
      setFont(generateWordcloudFont(theme));
    }
  }, [theme]);

  return {
    ...WORDCLOUD_FIXED_OPTIONS,
    words,
    fontSize: fontSizeSetter,
    font,
  };
};

export const useGetWordcloudTextOptions = () => {
  const [colours, setColours] = useState<Array<string>>(['black']);
  const theme = useTheme();
  const generateTextOptions = useCallback(
    (word: $TSFixMe, index: number) => {
      return {
        fontFamily: word.font,
        fontSize: word.size,
        textAnchor: 'middle',
        transform: `translate(${word.x}, ${word.y}) rotate(${word.rotate})`,
        fill: colours[index % colours.length],
      };
    },
    [colours],
  );

  useEffect(() => {
    if (theme) {
      setColours(generateColours(theme));
    }
  }, [theme]);

  return generateTextOptions;
};
