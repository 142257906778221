type Predicate<T> = (elem: T, index: number, entireArray: Array<T>) => boolean;

/**
 * Find the index of the nth occurrence matching the predicate (1 - indexed) (-1 if the nth is not found)
 */
export function indexNth<T>(
  arr: Array<T>,
  nth: number,
  predicate: Predicate<T>,
): number {
  let count = 0;

  for (let i = 0; i < arr.length; i++) {
    const elem = arr[i];
    if (predicate(elem, i, arr)) {
      count += 1;
    }
    if (count >= nth) {
      return i;
    }
  }
  return -1;
}

/**
 * Find the value of the nth occurrence matching the predicate (1 - indexed) (undefined if the nth is not found)
 */
export function findNth<T>(
  arr: Array<T>,
  nth: number,
  predicate: Predicate<T>,
): T | undefined {
  const index = indexNth(arr, nth, predicate);
  if (index === -1) {
    return undefined;
  }
  return arr[index];
}
