import { IframeParams } from '../../hooks';
import { generateBusinessLinkUrl } from '../BusinessLink/BusinessLink';

/**
 * Higher-order function to return a function which amends a base url with the different Iframe query params
 */
export const generateGenerateNavigationDestination =
  (base: string) =>
  ({ businessId, policies, toBusiness }: IframeParams) => {
    let destination = base;
    if (toBusiness) {
      destination = `${destination}?toBusiness=true`;
    } else if (businessId) {
      destination = `${destination}?businessId=${businessId}`;
      if (policies && policies.length > 0) {
        destination =
          destination + policies.map((policy) => `&policy=${policy}`).join('');
      }
    }
    return destination;
  };

/**
 * Generate the appropriate navigation destination (going to the thanks page)
 */
export const generateThanksNavigationDestination =
  generateGenerateNavigationDestination('/insurtech/iframe/thanks/contact');

/**
 * Generate the navigation destination to end the whole iframe process
 */
export const generateFinalNavigationDestination = ({
  toBusiness,
  businessId,
  policies,
}: IframeParams) => {
  let destination = '/insurtech/quote';
  if (toBusiness) {
    destination = `${destination}/type/commercial`;
  } else if (businessId) {
    destination = generateBusinessLinkUrl(businessId, policies);
  }
  return destination;
};
