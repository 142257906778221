import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { FORM_MODIFIER_RELEVANT_FORMS } from '../../../../../../../queries';
import {
  addApiNameForRemoval,
  removeApiNameForRemoval,
} from '../../../../../../../store/FormModifierStore';

// types and classes
import { FinalFormModifier } from '../../classes';
import { ALL_SIGNIFIER } from '../../classes/FinalFormModifier';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { ErrorView } from '../../../../../../common/ErrorView';
import { FormControlLabel, Typography } from '@mui/material';
import { QuestionInstanceType } from '../../../../../../../gql/graphql';
import { StoreState } from '../../../../../../../store';

interface DeleteQuestionsProps {
  form: FinalFormModifier;
}
export const DeleteQuestions = ({ form }: DeleteQuestionsProps) => {
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        variant='contained'
        color='error'
        onClick={() => setDeletionModalOpen(true)}
      >
        Remove Questions
      </Button>
      <FormDeletionDialog
        form={form}
        open={deletionModalOpen}
        onClose={() => setDeletionModalOpen(false)}
        businessId={
          form.business === ALL_SIGNIFIER ? undefined : form.business.id
        }
        policyId={form.policy ? form.policy.id : undefined}
      />
    </>
  );
};

interface FormDeletionDialogProps {
  form: FinalFormModifier;
  open: boolean;
  onClose: () => void;
  businessId?: string;
  policyId?: string;
}
const FormDeletionDialog = ({
  form,
  open,
  onClose,
  businessId,
  policyId,
}: FormDeletionDialogProps) => {
  const [getRelevantForms, { loading, error, data }] = useLazyQuery(
    FORM_MODIFIER_RELEVANT_FORMS,
  );
  useEffect(() => {
    getRelevantForms({
      variables: {
        businessId,
        policyId,
      },
    });
  }, [businessId, policyId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Questions</DialogTitle>
      <DialogContent>
        {loading ? <LoadingScreen /> : null}
        {error ? <ErrorView /> : null}
        {data?.formModifierRelevantFinalForms ? (
          <DisplayRelevantForms
            form={form}
            // @ts-expect-error
            forms={[...data.formModifierRelevantFinalForms].sort((a, b) =>
              a?.businessLine?.displayName.localeCompare(
                // @ts-expect-error
                b?.businessLine?.displayName,
              ),
            )}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

const getApiNamesForRemoval = (state: StoreState) =>
  state.formModifierStore.currentlyEditingFinalFormModifier
    ?.apiNamesForRemoval || [];

interface DisplayRelevantFormsProps {
  form: FinalFormModifier;
  forms: Array<{
    id: string;
    policy: null | {
      displayName: string;
    };
    businessLine: null | {
      displayName: string;
    };
    questionInstances: Array<QuestionInstanceType>;
  }> | null;
}
const DisplayRelevantForms = ({ form, forms }: DisplayRelevantFormsProps) => {
  const apiNamesForRemoval = useSelector(getApiNamesForRemoval);
  const dispatch = useDispatch();
  if (!forms) {
    return null;
  }
  if (forms.length === 0) {
    return (
      <>
        <Typography variant='subtitle1'>No Relevant Forms</Typography>
        <Typography variant='body1'>
          There are no existing forms that would be changed by this Form
          Modifier. This could be because your organization has an existing form
          matching this one, or it could be that there is a Generic form
          matching it instead.
        </Typography>
      </>
    );
  }
  return (
    <>
      {forms.map((backendForm) => (
        <>
          <section key={backendForm.id}>
            <Typography variant='subtitle2'>{`${
              backendForm.businessLine?.displayName
            } - ${
              backendForm.policy
                ? backendForm.policy.displayName
                : 'General Information'
            }`}</Typography>
            {backendForm.questionInstances.map((question) => {
              // does a question with this API name already exist on the formModifier (and is slated to be added / modified)?
              const isAdded = form.questionInstances
                .map((q) => q.apiName)
                .includes(
                  // @ts-expect-error
                  question.apiName,
                );
              return (
                <>
                  <Tooltip
                    title={
                      isAdded
                        ? 'A question with this API name is already slated to be added / modified'
                        : ''
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isAdded}
                          checked={apiNamesForRemoval.includes(
                            // @ts-expect-error
                            question.apiName,
                          )}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              dispatch(
                                removeApiNameForRemoval(
                                  // @ts-expect-error
                                  question.apiName,
                                ),
                              );
                            } else {
                              dispatch(
                                addApiNameForRemoval(
                                  // @ts-expect-error
                                  question.apiName,
                                ),
                              );
                            }
                          }}
                        />
                      }
                      label={question.labels[0].value}
                    />
                  </Tooltip>
                  <br />
                </>
              );
            })}
          </section>
        </>
      ))}
    </>
  );
};
