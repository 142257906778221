import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type TablePaginationActionsProps = {
  count: number;
  onPageChange: (page: number) => any;
  page: number;
};

/**
 * Provides the pagination buttons and their functionality
 * @param {number} count - Total number of rows in the table
 * @param {function} onPageChange - Changes the current page
 * @param {number} page - The current page
 */
const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, onPageChange } = props;

  // first page
  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  // Previous page
  const handleBackButtonClick = () => {
    onPageChange(page - 1);
  };

  // Next page
  const handleNextButtonClick = () => {
    onPageChange(page + 1);
  };

  // Last page
  const handleLastPageButtonClick = () => {
    onPageChange(count - 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= count - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= count - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
