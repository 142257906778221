import { useState } from 'react';
// material
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
// // routes

// hooks

// components
import Scrollbar from '../../../components/Scrollbar';
import { InsurerQuoteDetailsTable } from './InsurerQuoteDetailsTable';
import { InsurerQuoteComparisonTable } from './InsurerQuoteComparisonTable';
// queries

// error handler

// pages

// classes and types
import { InsurerQuoteRowData } from './cellUtils';
interface InsurerQuoteComparisonProps {
  uniqueID: string;
}

export const InsurerQuoteComparison = ({
  uniqueID,
}: InsurerQuoteComparisonProps) => {
  const [selectedInsurerQuotes, setSelectedInsurerQuotes] = useState<
    readonly InsurerQuoteRowData[]
  >([]);

  const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(5),
  }));

  return (
    <Scrollbar>
      <InsurerQuoteDetailsTable
        uniqueID={uniqueID}
        selectedInsurerQuotes={selectedInsurerQuotes}
        setSelectedInsurerQuotes={setSelectedInsurerQuotes}
        RootStyle={RootStyle}
      />

      <InsurerQuoteComparisonTable
        selectedInsurerQuotes={selectedInsurerQuotes}
        RootStyle={RootStyle}
      />
    </Scrollbar>
  );
};
