// harvest these by filling out the forms, and then examine the redux state, pin, and choose `raw`
export const generalInformationAnswers = [
  {
    questionInstance: {
      id: '41388',
      apiName: 'company_info',
      displayName: 'Company Info',
      label: 'Company Information',
      helpText: null,
      component: 'repeatable',
      dataType: 'nested',
      propsBlob: {},
      required: false,
      subQuestions: [
        {
          id: '41389',
          apiName: 'company_name',
          displayName: 'Company Name',
          label: "What is your company's legal name?",
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
          fieldPrefix: 'company_info[0]',
        },
        {
          id: '41390',
          apiName: 'trade_or_operating_name',
          displayName: 'Trade or Operating Name',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '41391',
              apiName: 'operating_name',
              displayName: 'Operating Name',
              label: 'Does your company use a trade or operating name?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
              fieldPrefix: 'company_info[0].trade_or_operating_name[0]',
            },
            {
              id: '41392',
              apiName: 'trade_name',
              displayName: 'trade name',
              label: 'What is the name?',
              helpText: null,
              component: 'textfield',
              dataType: 'string',
              propsBlob: {},
              required: true,
              subQuestions: [],
              fieldPrefix: 'company_info[0].trade_or_operating_name[0]',
            },
          ],
          fieldPrefix: 'company_info[0]',
        },
      ],
    },
    value: 1,
    subAnswers: [
      {
        questionInstance: {
          id: '41389',
          apiName: 'company_name',
          displayName: 'Company Name',
          label: "What is your company's legal name?",
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
          fieldPrefix: 'company_info[0]',
        },
        value: "Simon's Super Sunflowers",
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41390',
          apiName: 'trade_or_operating_name',
          displayName: 'Trade or Operating Name',
          label: '...',
          helpText: null,
          component: 'logic',
          dataType: 'nested',
          propsBlob: {
            operator: 'equals',
            condition: 'yes',
          },
          required: false,
          subQuestions: [
            {
              id: '41391',
              apiName: 'operating_name',
              displayName: 'Operating Name',
              label: 'Does your company use a trade or operating name?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
              fieldPrefix: 'company_info[0].trade_or_operating_name[0]',
            },
            {
              id: '41392',
              apiName: 'trade_name',
              displayName: 'trade name',
              label: 'What is the name?',
              helpText: null,
              component: 'textfield',
              dataType: 'string',
              propsBlob: {},
              required: true,
              subQuestions: [],
              fieldPrefix: 'company_info[0].trade_or_operating_name[0]',
            },
          ],
          fieldPrefix: 'company_info[0]',
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '41391',
              apiName: 'operating_name',
              displayName: 'Operating Name',
              label: 'Does your company use a trade or operating name?',
              helpText: null,
              component: 'YesNoToggle',
              dataType: 'string',
              propsBlob: {
                options: [
                  {
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    value: 'no',
                    label: 'No',
                  },
                ],
              },
              required: true,
              subQuestions: [],
              fieldPrefix: 'company_info[0].trade_or_operating_name[0]',
            },
            value: 'no',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '41393',
      apiName: 'client_contact_info',
      displayName: 'Client Contact Info',
      label: 'Contact Information',
      helpText: null,
      component: 'repeatable',
      dataType: 'nested',
      propsBlob: {},
      required: true,
      subQuestions: [
        {
          id: '41472',
          apiName: 'client_contact_name',
          displayName: 'Client Contact Name',
          label:
            'What is the first and last name of the primary contact regarding insurance at your company?',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        {
          id: '41473',
          apiName: 'contact_email',
          displayName: 'Contact Email',
          label: 'What is the best email to send the quote to?',
          helpText: null,
          component: 'emailinput',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        {
          id: '41474',
          apiName: 'primary_contact_phone_number',
          displayName: 'Primary Contact Phone Number',
          label:
            "What is the best phone number to reach your company's primary contact?",
          helpText: null,
          component: 'phonefield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        {
          id: '41475',
          apiName: 'mailing_address',
          displayName: 'Mailing address',
          label: 'What is your mailing address?',
          helpText: null,
          component: 'location',
          dataType: 'nested',
          propsBlob: {},
          required: true,
          subQuestions: [
            {
              id: '41476',
              apiName: 'address',
              displayName: 'address',
              label: 'Address',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            {
              id: '41477',
              apiName: 'city',
              displayName: 'city',
              label: 'City',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            {
              id: '41478',
              apiName: 'province',
              displayName: 'province',
              label: 'Province',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            {
              id: '41479',
              apiName: 'postal',
              displayName: 'postal',
              label: 'Postal Code',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
          ],
        },
      ],
    },
    value: 1,
    subAnswers: [
      {
        questionInstance: {
          id: '41472',
          apiName: 'client_contact_name',
          displayName: 'Client Contact Name',
          label:
            'What is the first and last name of the primary contact regarding insurance at your company?',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        value: 'Simon Hlywa',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41473',
          apiName: 'contact_email',
          displayName: 'Contact Email',
          label: 'What is the best email to send the quote to?',
          helpText: null,
          component: 'emailinput',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        value: 'simon.hlywa@gmail.com',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41474',
          apiName: 'primary_contact_phone_number',
          displayName: 'Primary Contact Phone Number',
          label:
            "What is the best phone number to reach your company's primary contact?",
          helpText: null,
          component: 'phonefield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        value: '+1 (844) 422-5339',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41475',
          apiName: 'mailing_address',
          displayName: 'Mailing address',
          label: 'What is your mailing address?',
          helpText: null,
          component: 'location',
          dataType: 'nested',
          propsBlob: {},
          required: true,
          subQuestions: [
            {
              id: '41476',
              apiName: 'address',
              displayName: 'address',
              label: 'Address',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            {
              id: '41477',
              apiName: 'city',
              displayName: 'city',
              label: 'City',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            {
              id: '41478',
              apiName: 'province',
              displayName: 'province',
              label: 'Province',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            {
              id: '41479',
              apiName: 'postal',
              displayName: 'postal',
              label: 'Postal Code',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
          ],
        },
        value: '1',
        subAnswers: [
          {
            questionInstance: {
              id: '41476',
              apiName: 'address',
              displayName: 'address',
              label: 'Address',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            value: '557 36 Avenue Southeast',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '41477',
              apiName: 'city',
              displayName: 'city',
              label: 'City',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            value: 'Calgary',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '41478',
              apiName: 'province',
              displayName: 'province',
              label: 'Province',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            value: 'Alberta',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
          {
            questionInstance: {
              id: '41479',
              apiName: 'postal',
              displayName: 'postal',
              label: 'Postal Code',
              helpText: null,
              component: 'textField',
              dataType: 'string',
              propsBlob: {},
              required: false,
              subQuestions: [],
            },
            value: 'T2G 1W5',
            subAnswers: [],
            details: {
              lat: null,
              lng: null,
              google: {},
              opta: {},
              openingHours: [],
              images: [],
            },
          },
        ],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '41480',
      apiName: 'company_websites',
      displayName: 'Company Websites',
      label: '...',
      helpText: null,
      component: 'logic',
      dataType: 'nested',
      propsBlob: {
        operator: 'equals',
        condition: 'yes',
      },
      required: true,
      subQuestions: [
        {
          id: '44220',
          apiName: 'website',
          displayName: 'Website',
          label: 'Does your company have a website or an online storefront?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '44221',
          apiName: 'website_address',
          displayName: 'Website Address',
          label: 'What is the website address or online store link?',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '44220',
          apiName: 'website',
          displayName: 'Website',
          label: 'Does your company have a website or an online storefront?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        value: 'no',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '2660',
      apiName: 'employee_count',
      displayName: 'Employee Count',
      label: 'How many employees do you have including yourself?',
      helpText: null,
      component: 'textfield',
      dataType: 'string',
      propsBlob: {},
      required: false,
      subQuestions: [],
    },
    value: '8',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '17885',
      apiName: 'years_established',
      displayName: 'Years Established',
      label: 'What year was this business established?',
      helpText: null,
      component: 'yearPicker',
      dataType: 'number',
      propsBlob: {
        include: true,
        yearsAfter: 0,
        yearsBefore: 100,
      },
      required: true,
      subQuestions: [],
    },
    value: '2005',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '41397',
      apiName: 'revenue_information',
      displayName: 'Revenue Information',
      label: 'Revenue Information',
      helpText: null,
      component: 'repeatable',
      dataType: 'nested',
      propsBlob: {},
      required: true,
      subQuestions: [
        {
          id: '42117',
          apiName: 'rev_past',
          displayName: 'Rev past',
          label:
            'What was your revenue from business operations in the past 12 months?',
          helpText: null,
          component: 'slider',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
            slider_max: 10000000,
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '42118',
          apiName: 'rev_proj',
          displayName: 'Rev Proj',
          label:
            'What is your projected revenue from business operations in the next 12 months?',
          helpText: null,
          component: 'slider',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
            slider_max: 10000000,
          },
          required: true,
          subQuestions: [],
        },
      ],
    },
    value: 1,
    subAnswers: [
      {
        questionInstance: {
          id: '42117',
          apiName: 'rev_past',
          displayName: 'Rev past',
          label:
            'What was your revenue from business operations in the past 12 months?',
          helpText: null,
          component: 'slider',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
            slider_max: 10000000,
          },
          required: true,
          subQuestions: [],
        },
        value: '150000',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '42118',
          apiName: 'rev_proj',
          displayName: 'Rev Proj',
          label:
            'What is your projected revenue from business operations in the next 12 months?',
          helpText: null,
          component: 'slider',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
            slider_max: 10000000,
          },
          required: true,
          subQuestions: [],
        },
        value: '55000',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '44222',
      apiName: 'international_revenue_check',
      displayName: 'International Revenue Check',
      label: '.',
      helpText: null,
      component: 'internationalRevenue',
      dataType: 'nested',
      propsBlob: {},
      required: true,
      subQuestions: [
        {
          id: '44223',
          apiName: 'international_revenue_bool',
          displayName: 'international_revenue_bool',
          label: 'Do you have any revenue from outside of Canada?',
          helpText: 'The USA counts as outside of Canada.',
          component: 'YesNoToggle',
          dataType: 'boolean',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
            dataType: '%',
            slider_max: 100,
          },
          required: false,
          subQuestions: [],
        },
        {
          id: '44224',
          apiName: 'canada',
          displayName: 'canada',
          label: 'Canada',
          helpText: null,
          component: 'slider',
          dataType: 'integer',
          propsBlob: {
            dataType: '%',
            slider_max: 100,
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '44225',
          apiName: 'usa',
          displayName: 'usa',
          label: 'USA',
          helpText: null,
          component: 'slider',
          dataType: 'integer',
          propsBlob: {
            dataType: '%',
            slider_max: 100,
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '44226',
          apiName: 'international',
          displayName: 'international',
          label: 'International',
          helpText: null,
          component: 'slider',
          dataType: 'integer',
          propsBlob: {
            dataType: '%',
            slider_max: 100,
          },
          required: true,
          subQuestions: [],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '44223',
          apiName: 'international_revenue_bool',
          displayName: 'international_revenue_bool',
          label: 'Do you have any revenue from outside of Canada?',
          helpText: 'The USA counts as outside of Canada.',
          component: 'YesNoToggle',
          dataType: 'boolean',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
            dataType: '%',
            slider_max: 100,
          },
          required: false,
          subQuestions: [],
        },
        value: 'yes',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44224',
          apiName: 'canada',
          displayName: 'canada',
          label: 'Canada',
          helpText: null,
          component: 'slider',
          dataType: 'integer',
          propsBlob: {
            dataType: '%',
            slider_max: 100,
          },
          required: true,
          subQuestions: [],
        },
        value: '74',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '44225',
          apiName: 'usa',
          displayName: 'usa',
          label: 'USA',
          helpText: null,
          component: 'slider',
          dataType: 'integer',
          propsBlob: {
            dataType: '%',
            slider_max: 100,
          },
          required: true,
          subQuestions: [],
        },
        value: '26',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
];
