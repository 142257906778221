import { Quote } from './Quote';
import type { Organization } from '../types';
import type { Policy } from '../../Policy';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { StructuredQuoteData } from '.';

type PremiumProposalStatus = string; // NB this will change once it does on the backend

interface PremiumProposalInput {
  quote?: Quote;
  identifier: string;
  status: PremiumProposalStatus;
  insurer: Organization;
  client?: Organization; // optional because basically it will be the one for the site that we're on, so we won't always return it from the backend
  policies: Array<Policy>;
  amountInCents: number;
  notes: string;
  referralReasons: Record<string, boolean>;
  rejectedReasons: Record<string, boolean>;
  quoteStructuredData?: StructuredQuoteData;
  quoteBusinessLine?: Pick<Quote, 'businessLine'>;
}

export class PremiumProposal {
  quote?: Quote;
  identifier: string;
  status: PremiumProposalStatus;
  insurer: Organization;
  client?: Organization; // optional because basically it will be the one for the site that we're on, so we won't always return it from the backend
  policies: Array<Policy>;
  amountInCents: number;
  notes: string;
  referralReasons: Record<string, boolean>;
  rejectedReasons: Record<string, boolean>;
  quoteStructuredData?: StructuredQuoteData;
  quoteBusinessLine?: Pick<Quote, 'businessLine'>;

  constructor({
    quote,
    identifier,
    status,
    insurer,
    client,
    policies,
    amountInCents,
    notes,
    referralReasons,
    rejectedReasons,
    quoteStructuredData,
    quoteBusinessLine,
  }: PremiumProposalInput) {
    this.quote = quote;
    this.identifier = identifier;
    this.status = status;
    this.insurer = insurer;
    this.client = client;
    this.policies = policies;
    this.amountInCents = amountInCents;
    this.notes = notes;
    this.referralReasons = referralReasons;
    this.rejectedReasons = rejectedReasons;
    if (quoteStructuredData) {
      this.quoteStructuredData = quoteStructuredData;
    }
    if (quoteBusinessLine) {
      this.quoteBusinessLine = quoteBusinessLine;
    }
  }

  static generateFromBackendResponse(response: $TSFixMe) {
    return new PremiumProposal({
      quote: response.quote
        ? Quote.generateFromBackendResponse(response.quote)
        : undefined,
      identifier: response.identifier,
      status: response.status,
      insurer: response.insurer,
      client: response.client ? response.client : undefined,
      policies: response.policies,
      amountInCents: response.amountInCents,
      notes: response.notes,
      referralReasons: JSON.parse(response.referralReasons),
      rejectedReasons: JSON.parse(response.rejectedReasons),
      quoteStructuredData: response.quoteStructuredData
        ? StructuredQuoteData.generateFromBackendResponse(
            response.quoteStructuredData,
          )
        : undefined,
      quoteBusinessLine: response.quoteBusinessLine
        ? response.quoteBusinessLine
        : undefined,
    });
  }
}
