/**
 * This moves the logic of styling the tile selector based on the org settings and whatnot into a reuseable hook, rather than having it live right in the informedMaterial library or whatnot.
 */
import { makeStyles } from '@mui/styles';
import { useSettings } from './useSettings/useSettings';
import { alpha } from '@mui/material/styles';

const useStyle = makeStyles((theme) => ({
  buttonRoot: {
    //border: 'none',
    // @ts-expect-error
    background: (settings) => settings.tileBackground,
    backgroundPositionY: '15px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    // @ts-expect-error
    border: `1px solid ${alpha(theme.palette.action.active, 0.12)}`,
    // @ts-expect-error
    marginLeft: theme.spacing(3),
    // @ts-expect-error
    marginRight: theme.spacing(3),
    width: '200px',
    height: '170px',
    // @ts-expect-error
    color: `${theme.palette.primary.main}`,
    fontSize: '0.78rem',
  },
  buttonLabel: {
    fontWeight: 500,
    alignItems: 'flex-end',
  },
}));

export const useTileSelectorSettings = () => {
  const settings = useSettings();
  const tileClasses = useStyle(settings);
  return {
    settings,
    tileClasses,
  };
};
