// material
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { useSettings } from '../../../hooks';
import { useIsManager } from '../../../hooks';

// import testSettings from '../../../WhiteLabelSettings/Calefy/settings'
// import testSettings from '../../../WhiteLabelSettings/FuseInsurance/settings'
// import testSettings from '../../../WhiteLabelSettings/VapeInsurance/settings'
// import testSettings from '../../../WhiteLabelSettings/CannabisInsurance/settings'
// import testSettings from '../../../WhiteLabelSettings/LastnameInsurance/settings';

// ----------------------------------------------------------------------

const CALEFY_SQUARE_LOGO_LOCATION = '/static/brand/CalefyIconLogo.png';

type LogoProps = {
  sx?: SxProps;
};
/**
 * Returns a square logo for the current organization / website
 * @param sx - the MaterialUI sx
 */
export default function Logo({ sx }: LogoProps) {
  const settings = useSettings();
  const { isManager } = useIsManager();

  const { squareLogo } = settings;
  const src =
    !isManager && squareLogo ? squareLogo : CALEFY_SQUARE_LOGO_LOCATION;
  // const src =
  //   !isManager && testSettings.squareLogo
  //     ? testSettings.squareLogo
  //     : CALEFY_SQUARE_LOGO_LOCATION;
  return (
    <Box
      component='img'
      alt='logo'
      src={src}
      sx={{ width: 40, height: 40, ...sx }}
    />
  );
}
