// import { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField } from '@calefy-inc/informedMaterial';

// types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';

const validateVin = (value: GenericObject) => {
  return value && (value.length < 17 || value.length > 17)
    ? 'VIN must have exactly 17 characters'
    : undefined;
};

const fillInVehicle = (
  vinState: $TSFixMe,
  fillFields: $TSFixMe,
  formApi: $TSFixMe,
) => {
  if (!vinState) {
    return;
  }

  if (vinState.length === 17) {
    axios
      .get(
        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vinState}?format=json`,
      )
      .then((response) => {
        const {
          ModelYear,
          Make,
          Model,
          BodyClass,
          EngineCylinders,
          GVWR,
          ErrorCode,
        } = response.data.Results[0];

        if (ErrorCode === '0') {
          formApi.setValue(fillFields['modelYear'], ModelYear);
          formApi.setTouched(fillFields['modelYear'], true);

          formApi.setValue(fillFields['make'], Make);
          formApi.setTouched(fillFields['make'], true);

          formApi.setValue(fillFields['model'], Model);
          formApi.setTouched(fillFields['model'], true);

          formApi.setValue(fillFields['bodyType'], BodyClass);
          formApi.setTouched(fillFields['bodyType'], true);

          formApi.setValue(fillFields['engineSize'], EngineCylinders);
          formApi.setTouched(fillFields['engineSize'], true);

          formApi.setValue(fillFields['grossVehicleWeight'], GVWR);
          formApi.setTouched(fillFields['grossVehicleWeight'], true);
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(errorify(error));
      });
  } else {
    return;
  }
};

type VinDecoderProps = {
  field: string;
  formApi: $TSFixMe;
  classes: GenericObject;
  vin: $TSFixMe;
  fillFields: $TSFixMe;
};

/**
 * Allows use to type in VIN and queried vehicle api to automatically fill in other related fields
 * @param {string} field - The Informed field for the InformedAutocomplete component which forms the basis of this component
 * @param {object}  formApi - The API for the form this component is a part of. Used to set the values of other related fields once the different vehicle components have been received.
 * @param {object} classes - component styling
 * @param {object} vin - vin object for creating the informed field
 * @param {object: {vin, modelYear, make, model, bodyType, engineSize,grossVehicleWeight}} fillFields - Mapping from the vin components that vehicle api gives us to the `field` of the Informed field that will be filled in with that value
 */
export const VINDecoder = ({
  field,
  formApi,
  classes = {},
  vin,
  fillFields,
}: VinDecoderProps) => {
  // const vinField = formApi.getValue(field);

  // const [vinState, setVinState] = useState();

  // useEffect(() => {
  //   setVinState(vinField);
  // }, [vinField]);

  // useEffect(() => {
  //   fillInVehicle(vinState, fillFields, formApi);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [vinState]);

  return (
    <>
      <TextField
        label={'V.I.N. (Enter V.I.N. to automatically populate fields)'}
        field={field}
        id={vin.apiName}
        helperText={vin.helpText}
        className={classes.textfield}
        classes={classes}
        validate={(value: $TSFixMe) => validateVin(value)}
        validateOnChange
        variant='standard'
        onChange={(e: $TSFixMe) => {
          fillInVehicle(e.target.value, fillFields, formApi);
        }}
      />
    </>
  );
};
