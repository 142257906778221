import { graphql } from '../../gql';

export const APPLICATION_SPECIFIC_INSURER_QUOTES = graphql(`
  query applicationSpecificInsurerQuotes($quoteUuid: String!) {
    applicationSpecificInsurerQuotes(quoteUuid: $quoteUuid) {
      id
      insurerQuoteIdentifier
      llmResponseBlob
      pdfFilePath
      isValid
    }
  }
`);
