import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Bugsnag from '@bugsnag/browser';

import { generateFinalNavigationDestination } from '../utility';
import { BUSINESS_TYPE } from '../../../queries';
import { useLazyQuery } from '@apollo/client';
// types and classes
import type { ActionButtonProps } from './types';
import { useHandleIframe } from './utility';
import { ImperativeNavigateLink } from './ImperativeNavigateLink';

export const PersonalCommercialChoiceButtons = ({
  formApi,
  businessId,
  policies,
  toBusiness,
  questionInstances,
}: ActionButtonProps) => {
  const handle = useHandleIframe({
    formApi,
    toBusiness: toBusiness === true,
    businessId,
    policies: policies || [],
    questionInstances,
    generateNavigationDestination: generateFinalNavigationDestination,
    useImperativeNavigateLink: true,
  });

  const [getPersonalBusiness, { error, data }] = useLazyQuery(BUSINESS_TYPE);

  useEffect(() => {
    getPersonalBusiness({
      variables: {
        internalName: 'personal',
      },
    });
  }, []);

  if (error) {
    Bugsnag.notify(error);
  }

  if (!formApi) {
    return null;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography gutterBottom>
        What kind of insurance are you looking for?
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          sx={{ mr: 2 }}
          variant='contained'
          type='button'
          onClick={async () => await handle({ toBusiness: true })}
        >
          Business
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant='contained'
          onClick={async () => {
            const params: Parameters<typeof handle>[0] = {
              toBusiness: false,
              businessId: 'personal',
            };
            if (data?.businessType) {
              // @ts-expect-error
              params.businessLine = data.businessType;
            }
            await handle(params);
          }}
        >
          Personal
        </Button>
        <ImperativeNavigateLink target='_blank' />
      </div>
    </div>
  );
};
