// classes and types
import Bugsnag from '@bugsnag/browser';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { UnifiedAnswerInstance } from '../../../Typescript/classes';
import { errorify } from '../../../util';
import { QuoteReviewLocationDetails } from '../../ManagementPanel/pages/dashboard/types';

export const findValueByNameInAnswerList = (
  name: $TSFixMe,
  answerList: Array<$TSFixMe>,
): $TSFixMe => {
  if (!answerList || answerList.length === 0) {
    return undefined;
  }
  for (let index = 0; index < answerList.length; index++) {
    const answer = answerList[index];
    if (answer.name === name) {
      return answer.data;
    }
    const foundInSubAnswers = findValueByNameInAnswerList(
      name,
      answer.subAnswers,
    );
    if (foundInSubAnswers !== undefined) {
      return foundInSubAnswers;
    }
  }
  return undefined;
};

// TODO This is used in the QuotePDF - it should be removed in the same manner as we did here
export const extractLocationsFromAnswerList = (
  answerList: Array<$TSFixMe>,
): $TSFixMe => {
  let foundLocations: Array<$TSFixMe> = [];
  if (!answerList || answerList.length === 0) {
    return [];
  }
  answerList.forEach((answer) => {
    if (answer.component === 'location' && answer.data !== 'N/A') {
      foundLocations.push(answer);
    }
    const subanswerLocations = extractLocationsFromAnswerList(
      answer.subAnswers,
    );
    if (subanswerLocations.length > 0) {
      foundLocations.push(...subanswerLocations);
    }
  });
  return foundLocations;
};

// TODO This is used in the QuotePDF - it should be removed in the same manner as we did here
export const extractLocationsFromQuote = (quote: $TSFixMe) => {
  let allLocations: Array<$TSFixMe> = [];
  quote.completedForms.forEach((completedForm: $TSFixMe) => {
    allLocations = [
      ...allLocations,
      ...extractLocationsFromAnswerList(completedForm.answers),
    ];
  });
  return allLocations;
};

export const extractLocationDetailsFromAnswerInstance = (
  locationAnswer: UnifiedAnswerInstance,
) => {
  const details: QuoteReviewLocationDetails = {
    opta: {},
    google: {},
    images: [],
    general: {
      id: locationAnswer.id as string,
      streetAddress: locationAnswer.findValueByAttributeInSubanswers(
        'apiName',
        'address',
      ) as string | undefined,
      city: locationAnswer.findValueByAttributeInSubanswers(
        'apiName',
        'city',
      ) as string | undefined,
      province: locationAnswer.findValueByAttributeInSubanswers(
        'apiName',
        'province',
      ) as string | undefined,
      postalCode: locationAnswer.findValueByAttributeInSubanswers(
        'apiName',
        'postal',
      ) as string | undefined,
      openingHours:
        locationAnswer && locationAnswer.details
          ? locationAnswer.details.openingHours
          : undefined,
      coordinates: {},
    },
  };

  // set the lat/long, if we know them
  if (
    locationAnswer?.details?.lat !== undefined &&
    locationAnswer?.details?.lng !== undefined
  ) {
    details.general.coordinates = {
      lat:
        locationAnswer.details.lat === null
          ? undefined
          : locationAnswer.details.lat,
      lng:
        locationAnswer.details.lng === null
          ? undefined
          : locationAnswer.details.lng,
    };
  }
  if (locationAnswer?.details?.opta) {
    details.opta = locationAnswer.details.opta;
  }

  // attach the images, if possible
  if (locationAnswer?.details?.images) {
    details.images = [...locationAnswer.details.images];
  }

  // now the Google details
  if (locationAnswer?.details?.google) {
    details.google = locationAnswer.details.google;
  }

  return details;
};

/**
 * Count how many times the question with id id appears in the list of anwers answer
 * @param {str (convertible to int)} id - the id of the question
 * @param {Array} answers - a list of answer instances
 */
export const countQuestionInAnswers = (id: string, answers: Array<$TSFixMe>) =>
  answers.reduce(
    (count, answer) => (answer.questionInstance.id === id ? count + 1 : count),
    0,
  );

// returns the current form label
export const getLabel = (completedForm: $TSFixMe) => {
  let label;
  if (completedForm.policy) {
    label = completedForm.policy.displayName;
  } else {
    label = 'Company Information';
  }

  return label;
};

export const convertFromReturnedAnswerToAnswerInState = (
  returnedAnswer: $TSFixMe,
) => {
  const { __typename, subAnswers, data, details, ...rest } = returnedAnswer;
  // NB we need to kludge the '1' for the case of a repeated question - the PDF is expecting an integer, not the string that is actually being returned
  return {
    questionInstance: rest,
    value: data === '1' ? 1 : data,
    details,
    subAnswers: subAnswers.map((answer: $TSFixMe) =>
      convertFromReturnedAnswerToAnswerInState(answer),
    ),
  };
};

// converts from a completed form to a `formWithAnswers`
// completedForm: {
// 	policy
// 	id
// 	answers
// }
// formWithAnswers: {
// 	form: {
// 		required
// 		id
// 		questionInstances
// 		qualifyingQuestion
// 		businessLine {
// 			id
// 			displayName
// 			internalName
// 			industry: {
// 				id
// 				displayName
// 			}
// 		}
// 		policy
// 		policyExtension
// 	}
// 	answers: [ (remember: `value` key in formWithAnswers is `data` in the returned one) ]
// }
export const convertFromQuoteToFormsWithAnswers = (quote: $TSFixMe) => {
  return quote.completedForms.map((completedForm: $TSFixMe) => ({
    form: {
      businessLine: quote.businessLine,
      policy: completedForm.policy,
      policyExtension: completedForm.policyExtension,
    },
    answers: completedForm.answers.map(
      convertFromReturnedAnswerToAnswerInState,
    ),
  }));
};

/**
 * After a brief pause, smoothly scroll to the element with id idString
 */
export const scrollToId = (idString: string) => {
  setTimeout(() => {
    const target = document.getElementById(idString);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, 100);
};

export async function downloadPdfFile(data: string, fileName: string) {
  if (!data) {
    return;
  }
  try {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const response = await fetch(`data:application/pdf;base64,${data}`);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    a.href = url;
    a.setAttribute('download', fileName);

    a.click();

    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  } catch (e) {
    Bugsnag.notify(errorify(e));
    console.error('Error downloading PDF file: ', e);
  }
}
