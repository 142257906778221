import { useState, useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
// hooks
import useSettings from '../../../hooks/useSettings';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermissions } from '../../../../../hooks';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { EnhancedTable } from './components/EnhancedFormModifiersTable';
// mui
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { GET_ALL_FORM_MODIFIERS } from '../../../../../queries';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import LoadingScreen from '../../../components/LoadingScreen';
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../../Page500';
import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';

// types and classes
import type { RouteComponentProps } from '@reach/router';

// Column settings
const columns: Array<{
  Header: string;
  accessor: keyof ParsedFormModifier;
  width?: number;
  Cell?: $TSFixMe;
}> = [
  {
    Header: 'Business Type',
    accessor: 'businessName',
    // width: 300,
    Cell: ({ value }: { value: string }) => (
      <Typography variant='subtitle2' noWrap>
        {value}
      </Typography>
    ),
  },
  {
    Header: 'Policy',
    accessor: 'policyName',
  },
  {
    Header: 'Masked',
    accessor: 'masked',
    Cell: ({ value }: { value: ParsedFormModifier['masked'] }) => (
      <Typography
        variant='subtitle2'
        color={value ? 'error' : 'warning'}
        noWrap
      >
        {value ? 'Masked' : 'Not Masked'}
      </Typography>
    ),
  },
];

type ParsedFormModifier = {
  businessName: string;
  policyName: string;
  businessId: string | null;
  policyId: string | null;
  masked: boolean;
};

interface FormModifiersListProps extends RouteComponentProps {}

/**
 * Provides features for viewing all the form modifiers for the current organization
 */
export const FormModifiersList = (_props: FormModifiersListProps) => {
  const { themeStretch } = useSettings();
  const { token } = useAuth();
  const hasFormEditPermissions = useAllowByPermissions('edit:forms');
  const [formModifiersList, setFormModifiersList] = useState<
    Array<ParsedFormModifier>
  >([]);

  // Get all final forms
  const [getFormModifiers, { loading, data, error }] = useLazyQuery(
    GET_ALL_FORM_MODIFIERS,
  );

  // Data
  const formsData = useMemo(() => [...formModifiersList], [formModifiersList]);

  useEffect(() => {
    getFormModifiers();
  }, [getFormModifiers]);

  useEffect(() => {
    if (data?.allFinalFormModifiers) {
      // New form object with data recieved
      const forms = data.allFinalFormModifiers
        .filter((form) => !!form)
        .map((form) => ({
          // @ts-expect-error
          businessName: form.business ? form.business.displayName : 'All',
          // @ts-expect-error
          policyName: form.policy
            ? // @ts-expect-error
              form.policy.displayName
            : 'General Information',
          // @ts-expect-error
          businessId: form.business ? form.business.id : null,
          // @ts-expect-error
          policyId: form.policy ? form.policy.id : null,
          // @ts-expect-error
          masked: !!form.masked,
        }));

      setFormModifiersList(forms);
    }
  }, [data]);

  if ((loading && formModifiersList.length === 0) || !token) {
    return <LoadingScreen />;
  }

  if (error) {
    Bugsnag.notify(error);
  }

  return (
    <Page title='Form Modifiers | Manager'>
      <Container maxWidth={themeStretch ? false : 'lg'} data-testid='forms'>
        {error && /invalid permissions/i.test(error.message) ? (
          <InsufficientPermissionsErrorPage deniedIdentifier='Form List' />
        ) : error ? (
          <ErrorPage />
        ) : (
          <>
            <HeaderBreadcrumbs
              heading='Form Modifiers List'
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
                { name: 'Form Modifiers' },
              ]}
              action={
                <Tooltip
                  title={
                    hasFormEditPermissions
                      ? 'New Form Modifier'
                      : "You don't have permission to create a new form modifier"
                  }
                >
                  <span>
                    <Button
                      variant='contained'
                      component={RouterLink}
                      to={'/insurtech/manager/formmodifiers/create'}
                      startIcon={<Icon icon={plusFill} />}
                      disabled={!hasFormEditPermissions}
                    >
                      New Form Modifier
                    </Button>
                  </span>
                </Tooltip>
              }
            />

            <EnhancedTable
              columns={columns}
              data={formsData}
              getRowProps={(row: ParsedFormModifier) => {
                // Row needs to have all of these in order to navigate
                if (
                  !row.businessName ||
                  !row.policyName ||
                  !hasFormEditPermissions
                ) {
                  return {};
                } else {
                  return {
                    component: RouterLink,
                    to: `/insurtech/manager/formmodifiers/edit/${
                      row.businessId ? row.businessId : 'all'
                    }/${row.policyId ? row.policyId : 'none'}`,
                    sx: { textDecoration: 'none' },
                  };
                }
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
};
