// material
import { Container, Card } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { FormNewForm } from '../../components/_dashboard/forms/create';

// types and classes
import type { RouteComponentProps } from '@reach/router';
// ----------------------------------------------------------------------

interface PolicyCreateProps extends RouteComponentProps {}
export default function PolicyCreate(_props: PolicyCreateProps) {
  return (
    <Page title='Form: Create Form | Manager'>
      <Container maxWidth='md' data-testid='new form'>
        <HeaderBreadcrumbs
          heading={'New Form'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Forms', href: PATH_DASHBOARD.forms.root },
            { name: 'New Form' },
          ]}
        />

        <Card>
          <FormNewForm />
        </Card>
      </Container>
    </Page>
  );
}
