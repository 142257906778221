import { graphql } from '../gql';

export const GET_ALL_FORM_MODIFIERS = graphql(`
  query allFinalFormModifiers {
    allFinalFormModifiers {
      id
      masked
      business {
        id
        displayName
      }
      policy {
        id
        displayName
      }
    }
  }
`);
