export const TotalQuotesTodayMock = {
  percent: 5.4,
  quotesToday: 3,
  dailyQuotes: [4, 1, 0, 9, 10, 4, 2, 6],
};

export const TotalPoliciesTodayMock = {
  percent: 7.4,
  policiesToday: 10,
  dailyPolicies: [5, 3, 0, 11, 12, 6, 3, 8, 10, 11],
};

export const TotalQuotesMock = {
  percent: 12,
  totalQuotes: 14569,
  monthlyQuotes: [
    {
      year: 2021,
      data: [
        {
          name: 'Main',
          data: [
            386, 345, 542, 674, 409, 667, 243, 914, 2033, 2856, 3456, 4234,
          ],
        },
      ],
    },
  ],
};

export const TopBusinessesMock = {
  topBusinesses: [
    {
      name: 'Amusement Parks, Playgrounds - Not Amusement Devices',
      totalCount: 256,
    },
    { name: 'Veterinarians - incl. Veterinary Hospitals', totalCount: 103 },
    { name: 'Paper, Paper Products', totalCount: 45 },
    { name: 'Retail', totalCount: 31 },
  ],
};

export const MonthlyQuotesMock = {
  monthlyQuotes: [
    {
      year: 2021,
      data: [
        {
          name: 'Main',
          data: [
            386, 345, 542, 674, 409, 667, 243, 914, 2033, 2856, 3456, 4234,
          ],
        },
      ],
    },
  ],
};

export const SubmissionTimesMock = [
  3, 45, 23, 1, 45, 534, 704, 467, 1254, 987, 445, 456, 788, 213, 735, 234,
  3463, 1345, 23, 467, 234, 456, 103, 5,
];

export const LocationMapMock = () => {
  const baseCoords = [
    { lat: 51.0447, long: -114.0719 },
    { lat: 49.2827, long: -123.1207 },
    { lat: 43.6532, long: -79.3832 },
  ];
  const newCoords = [];
  for (let baseCoord of baseCoords) {
    const perturbed = new Array(Math.round(200 * Math.random()))
      .fill(0)
      .map((_) => ({
        lat: baseCoord.lat + Math.random() * 2 - 1,
        long: baseCoord.long + Math.random() * 2 - 1,
      }));
    newCoords.push(...perturbed);
  }
  return newCoords;
};

export const NewQuotesMock = [
  {
    id: 3,
    status: 'COMPLETE',
    businessLine: {
      id: 256,
      displayName: 'Automobile Dealer',
      internalName: 'automobile_dealer',
    },
    uniqueID: '3ca45f01-16ed-4d20-8537-48caa3072116',
    structuredData: {
      businessLegalName: 'Tesla',
      businessName: 'Tesla Gas Trucks',
      businessTradeName: null,
    },
  },
  {
    id: 5,
    status: 'COMPLETE',
    businessLine: {
      id: 256,
      displayName: 'Automobile Dealer',
      internalName: 'automobile_dealer',
    },
    uniqueID: 'r5435f01-16ed-4d20-8537-48caa3076633',
    structuredData: {
      businessLegalName: 'Ford',
      businessName: 'Ford Electric Trucks',
      businessTradeName: null,
    },
  },
];
