import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
// queries
import { DASHBOARD_TOP_BUSINESSES } from '../../../../../queries';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import TopBusinessesSkeleton from './skeletons/TopBusinessesSkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { TopBusinessesMock } from './Mock';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

type topBusinessesType = {
  name: string;
  totalCount: number;
};

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 125;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    flexDirection: 'column',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
  '& .apexcharts-legend-text': {
    marginBottom: theme.spacing(0.5),
  },
}));

// ----------------------------------------------------------------------

export default function AppTopBusinessTypes() {
  const theme = useTheme();
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const { data, loading, error } = useQuery(DASHBOARD_TOP_BUSINESSES, {
    variables: { token },
    skip:
      hasDashboardPermissions !== true ||
      !token ||
      slug === 'lastnameinsurance',
  });
  const [topBusinesses, setTopBusinesses] = useState<topBusinessesType[]>([]);
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setTopBusinesses(TopBusinessesMock.topBusinesses);
      setOkToDisplay(true);
    } else if (data && data.topBusinessesData) {
      // @ts-expect-error
      setTopBusinesses(data.topBusinessesData.topBusinesses);
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  const CHART_DATA = topBusinesses.map((business) => business.totalCount);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      //@ts-expect-error
      theme.palette.primary.darker,
      theme.palette.primary.main,
      theme.palette.primary.light,
      //@ts-expect-error
      theme.palette.primary.lighter,
    ],
    labels: topBusinesses.map((business) => business.name),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'left' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) =>
            `${
              seriesName.length > 42
                ? `${seriesName.substring(0, 42)}...`
                : seriesName
            }`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            name: {
              formatter: (name: string) =>
                name.length > 40 ? `${name.substring(0, 25)}...` : name,
            },
            value: {
              formatter: (val: number) => fNumber(val),
            },
            total: {
              formatter: (w: $TSFixMe) => {
                const sum = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0,
                );
                return fNumber(sum);
              },
            },
          },
        },
      },
    },
  });

  if (hasDashboardPermissions === false) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if (
    (loading ||
      !okToDisplay ||
      InsufficientPermissionsErrorComponent === undefined) &&
    !error
  ) {
    return <TopBusinessesSkeleton />;
  }
  if (error) {
    Bugsnag.notify(`Could not load Top Businesses component, ${error}`);
    return handleDashboardError(error);
  }
  return (
    <Card data-testid='app-top-business-types'>
      <CardHeader title='Top Business Types' />

      <ChartWrapperStyle dir='ltr'>
        <ReactApexChart
          type='donut'
          series={CHART_DATA}
          //@ts-expect-error
          options={chartOptions}
          height={280}
        />
      </ChartWrapperStyle>
    </Card>
  );
}
