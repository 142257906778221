import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useAuth } from '@calefy-inc/authentication';
import { GET_REFERRER_XLSX } from '../../../../../../queries';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../../../../util';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const ExportReferrers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const [loadReferrerFile, { data, error, loading }] =
    useLazyQuery(GET_REFERRER_XLSX);

  useEffect(() => {
    if (data) {
      /*console.log('excel doc downloaded '); */
      downloadXlsxFile(data.referrerXlsx, 'Referrrer List.xlsx');
      enqueueSnackbar('Download Complete', { variant: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error('Unable to download referrer: ', error);
      Bugsnag.notify(
        `Unable to download referrers: ${JSON.stringify(error, null, 4)}`,
      );
      enqueueSnackbar('Download Failed', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleExportReferrers = () => {
    if (token) {
      loadReferrerFile({
        variables: {
          token,
        },
      });
    }
  };

  return (
    <LoadingButton
      loadingPosition='start'
      startIcon={<FileDownloadOutlinedIcon />}
      loading={loading}
      onClick={handleExportReferrers}
    >
      Export Referrers
    </LoadingButton>
  );
};

// converts the referrer base64 to an xlsx document
async function downloadXlsxFile(data: $TSFixMe, fileName: string) {
  if (!data) {
    return;
  }

  try {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    const response = await fetch(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`,
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    a.href = url;
    a.setAttribute('download', fileName);

    a.click();

    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading excel file: ', error);
    Bugsnag.notify(errorify(error));
  }
}

export default ExportReferrers;
