import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';

import {
  FloodScoreProduct,
  FloodScoreProductsHighlights,
} from '../../../QuoteLoader/LocationDetails/classes';
import { DetailsCard } from './commonComponents';
import { ImageCard, AddressDisplay } from './commonComponents';
import { Pagebreak } from '../../utils';
import { NO_INFO_STRING } from '../../../QuoteLoader/LocationDetails/constants';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// @ts-expect-error
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DisplayFloodScoreHighlights = ({ floodScoreProduct }: $TSFixMe) => {
  const [highlights, setHighlights] =
    useState<FloodScoreProductsHighlights | null>(
      // @ts-expect-error
      new FloodScoreProductsHighlights(floodScoreProduct),
    );

  useEffect(() => {
    // @ts-expect-error
    setHighlights(new FloodScoreProductsHighlights(floodScoreProduct));
  }, [floodScoreProduct]);

  if (
    highlights &&
    Object.values(highlights).every((elem: $TSFixMe) => elem === null)
  ) {
    return NO_INFO_STRING;
  }
  return <>{highlights ? highlights.displayAsJSx() : null}</>;
};

const useDisplayStyles = makeStyles((theme: $TSFixMe) => ({
  highlights: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2, 2, 2),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    backgroundColor: 'rgba(0, 0, 255, 0.05)',
  },
  rowContainer: {
    margin: 0,
  },
  maps: {
    padding: theme.spacing(2, 0.5, 0, 0.5),
    width: '100%',
  },
}));

interface DisplayLoadedFloodScoreDataProps {
  product: $TSFixMe;
}
/**
 * Once the data is loaded, this component actually displays it
 */
export const DisplayLoadedFloodScoreData = ({
  product,
}: DisplayLoadedFloodScoreDataProps) => {
  const classes = useDisplayStyles();
  if (!product) {
    return null;
  }
  return (
    <>
      <Typography
        variant='subtitle1'
        align='center'
        color='textSecondary'
        gutterBottom
      >
        FloodScore
      </Typography>
      {product && product.Address ? (
        <AddressDisplay>{product.Address.toString()}</AddressDisplay>
      ) : null}
      {/* <Card className={classes.highlights} elevation={ELEVATION} raised={true}> */}
      {/*   <Typography */}
      {/*     component='h3' */}
      {/*     variant='h5' */}
      {/*     style={{ textAlign: 'center', fontWeight: 'bold' }} */}
      {/*     gutterBottom */}
      {/*   > */}
      {/*     Highlights */}
      {/*   </Typography> */}
      {/*   <DisplayFloodScoreHighlights floodScoreProduct={product} /> */}
      {/* </Card> */}

      <Grid item xs={12} style={{ pageBreakAfter: 'always' }}>
        <DetailsCard
          heading='Flood Score'
          childrenWrapperStyle={{ columns: 2 }}
        >
          {product.FloodScores && product.FloodScores.FloodScore
            ? product.FloodScores.FloodScore.map((score: $TSFixMe) =>
                score.displayAsJsx(),
              )
            : NO_INFO_STRING}
        </DetailsCard>
      </Grid>

      <Pagebreak />

      <div className={classes.maps}>
        <DetailsCard
          heading='Maps'
          childrenWrapperStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {product.FloodScores &&
          product.FloodScores.FloodMap &&
          product.FloodScores.FloodMap.length > 0 ? (
            <Grid container spacing={2}>
              {product.FloodScores.FloodMap.map((map: $TSFixMe) => (
                <ImageCard
                  key={map.FloodEvent}
                  heading={`${map.FloodEvent}-Year Floods`}
                  url={map.Content.ContentURL}
                />
              ))}
            </Grid>
          ) : (
            NO_INFO_STRING
          )}
        </DetailsCard>
      </div>
    </>
  );
};

interface DisplayOptaFloodscoreDataProps {
  location: $TSFixMe;
  classes: object;
}
/**
 * Display the FloodScore data for the given location
 */
const UnthemedDisplayOptaFloodscoreData = ({
  location,
}: DisplayOptaFloodscoreDataProps) => {
  const [floodScoreProduct, setFloodScoreProduct] = useState<
    null | undefined | FloodScoreProduct
    // @ts-expect-error
  >(new FloodScoreProduct());

  useEffect(() => {
    if (
      location &&
      location.details &&
      location.details.opta &&
      location.details.opta.optaFloodscoreData
    ) {
      setFloodScoreProduct(
        FloodScoreProduct.createFromJson(
          location.details.opta.optaFloodscoreData,
        ),
      );
    } else {
      setFloodScoreProduct(null);
    }
  }, [
    location,
    location.details,
    location.details.opta,
    location.details.opta.optaFloodScoreData,
  ]);

  return <DisplayLoadedFloodScoreData product={floodScoreProduct} />;
};

export const DisplayOptaFloodscoreData = withStyles((theme) => ({
  ul: {
    margin: '0px',
    padding: '0px',
  },
  floodScoreNotFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
}))((props: $TSFixMe) => <UnthemedDisplayOptaFloodscoreData {...props} />);
