import { useCallback /*useEffect*/ } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_QUOTE_PDF } from '../queries';
import { useAuth } from '@calefy-inc/authentication';
import { downloadPdfFile } from '@calefy-inc/utility';
import { GetQuotePdfQuery } from '../gql/graphql';

interface UseDownloadApplicationParams {
  variables: {
    uuid: string;
    archiveId?: string | null;
    renewalOnly?: boolean;
    downloadType?: string;
  };
  onSuccess?: (data: GetQuotePdfQuery) => void;
  onFailure?: (e: Error) => void;
}
export const useDownloadApplication = ({
  variables,
  onSuccess,
  onFailure,
}: UseDownloadApplicationParams) => {
  const { token } = useAuth();
  const [getPdf, { loading, error, data }] = useLazyQuery(GET_QUOTE_PDF, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data && data.quotePdf?.pdfString) {
        const quotePdf = data.quotePdf;
        downloadPdfFile(
          // @ts-expect-error
          quotePdf.pdfString,
          `${quotePdf.businessName || 'New Application'}.pdf`,
        );
        onSuccess && onSuccess(data);
      }
    },
    onError: (error) => {
      onFailure && onFailure(error);
    },
  });

  const downloadPdf = useCallback(() => {
    const { uuid, archiveId, renewalOnly, downloadType } = variables;
    getPdf({
      variables: {
        token,
        uniqueID: uuid,
        archiveID: archiveId,
        renewalOnly: renewalOnly || false,
        downloadType,
      },
    });
  }, [variables, token]);

  // download and handle the data when it is here
  // useEffect(() => {
  //   if (data && data.quotePdf?.pdfString) {
  //     const quotePdf = data.quotePdf;
  //     downloadPdfFile(
  //       // @ts-expect-error
  //       quotePdf.pdfString,
  //       `${quotePdf.businessName || 'New Application'}.pdf`,
  //     );
  //     onSuccess && onSuccess(data);
  //   }
  // }, [data, onSuccess]);

  // handle the error
  // useEffect(() => {
  //   if (error) {
  //     onFailure && onFailure(error);
  //   }
  // }, [error, onFailure]);

  return [downloadPdf, { loading, error, data }] as [
    typeof downloadPdf,
    { loading: typeof loading; error: typeof error; data: typeof data },
  ];
};
