import Bugsnag from '@bugsnag/browser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { QuoteWizardAnswerInstance } from '../components/QuoteWizard/classes';
import { setOrphanedAnswerInstances } from '../store/QuoteWizardState';
import { errorify } from '../util';

export const ORPHANED_ANSWERS_KEY = 'orphanedAnswerInstances';
/**
 * On page load, load in any orphaned answer instances from localStorage and put them into the redux state, clearing them thereafter
 * @param key - The key to use in localStorage
 * @return - Whether the loading process is complete. Note: doesn't indicate success or failure, just that the attempt was made
 */
export const useLoadOrphanedAnswers = (
  key: string = ORPHANED_ANSWERS_KEY,
): { loaded: boolean } => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const orphanedAnswerInstancesString = window.localStorage.getItem(key);
    let orphanedAnswerInstances;
    if (orphanedAnswerInstancesString) {
      try {
        orphanedAnswerInstances = JSON.parse(orphanedAnswerInstancesString);
      } catch (e) {
        console.error(e);
        Bugsnag.notify(errorify(e));
      }
    }
    if (orphanedAnswerInstances && Array.isArray(orphanedAnswerInstances)) {
      const answerInstances = orphanedAnswerInstances.map((obj) =>
        QuoteWizardAnswerInstance.generateFromObject(obj),
      );
      //console.log('Dispatched action to set orphaned answer instances');
      dispatch(setOrphanedAnswerInstances(answerInstances));
      window.localStorage.removeItem(key);
    }
    setLoaded(true);
  }, [dispatch]);

  return { loaded };
};
