import {
  useAllowByPermissionsGenerator,
  useAllowByPermissionGenerator,
} from '@calefy-inc/authentication';

// types and classes
import type { Permission } from '../components/Authentication/types';

const useAllowByPermissions = (permission: Permission | Array<Permission>) =>
  useAllowByPermissionsGenerator<Permission>(permission);
const useAllowByPermission = (permission: Permission | Array<Permission>) =>
  useAllowByPermissionGenerator<Permission>(permission);
const useAllowByAdminPermissions = () => {
  return useAllowByPermissionGenerator('is:admin');
  // return false;
};

export {
  useAllowByPermissions,
  useAllowByPermission,
  useAllowByAdminPermissions,
};
