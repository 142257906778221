import { /* useEffect, */ useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Quote } from '../Typescript/backend/classes';
import { useUsingVagoSettings } from './useUsingSettings';
import { useAuth } from '@calefy-inc/authentication';
import { VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION } from '../queries';
import { downloadZipFile } from '@calefy-inc/utility';
import { VagoDownloadApplicationDocumentationQuery } from '../gql/graphql';

interface UseDownloadVagoDocumentationParameters {
  uuid: Quote['uniqueId'];
  onSuccess?: (data: VagoDownloadApplicationDocumentationQuery) => void;
  onFailure?: (error: Error) => void;
}
export const useDownloadVagoDocumentation = ({
  uuid,
  onSuccess,
  onFailure,
}: UseDownloadVagoDocumentationParameters) => {
  const onVago = useUsingVagoSettings();
  const { token } = useAuth();
  const [vagoDownloadApplicationDocumentation, { loading, error, data }] =
    useLazyQuery(VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        // download the file once we get it
        if (
          data?.vagoDownloadApplicationDocumentation?.data &&
          data.vagoDownloadApplicationDocumentation.filename
        ) {
          (async () => {
            await downloadZipFile(
              data.vagoDownloadApplicationDocumentation?.data || '',
              data.vagoDownloadApplicationDocumentation?.filename || '',
            );
          })();
          onSuccess && onSuccess(data);
        }
      },
      onError: (error) => {
        onFailure && onFailure(error);
      },
    });

  const downloadDocumentation = useCallback(() => {
    vagoDownloadApplicationDocumentation({
      variables: { token, quoteUuid: uuid },
    });
  }, [token, uuid, onVago]);

  return [
    token && onVago && uuid ? downloadDocumentation : null,
    { loading, error, data },
  ] as [
    null | typeof downloadDocumentation,
    { loading: typeof loading; error: typeof error; data: typeof data },
  ];
};
