import { useFormState } from 'informed';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

// types and classes
import type { QuestionInstanceFormError } from './types';

const ErrorDiv = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ErrorUl = styled('ul')(() => ({
  listStyle: 'disc inside none',
}));

export function DisplayFormErrors() {
  const formState = useFormState();
  const error = formState.error as QuestionInstanceFormError;
  const allErrorStrings: Array<string> = !error
    ? []
    : typeof error === 'string'
    ? [error]
    : Object.values(error).reduce((acc: Array<string>, errorList) => {
        return [...acc, ...errorList];
      });

  if (allErrorStrings.length === 0) {
    return null;
  }
  if (allErrorStrings.length === 1) {
    return (
      <Typography sx={{ color: 'error.main' }}>{allErrorStrings[0]}</Typography>
    );
  }
  if (allErrorStrings.length > 1) {
    return (
      <ErrorDiv>
        <Typography sx={{ color: 'inherit' }}>
          There were errors with the form:
        </Typography>
        <ErrorUl>
          {allErrorStrings.map((errorString) => (
            <li key={errorString}>{errorString}</li>
          ))}
        </ErrorUl>
      </ErrorDiv>
    );
  }
  return null;
}
