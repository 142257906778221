import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

/**
 * Dialog / Alert for when the user leaves a dirty form. It warns them that they are about to lose data and gives them the option to go back to the form or to continue without saving
 * @param  open - Whether or not the confirmation dialog is open
 * @param  setOpen - set the value of the 'open' variable ( whether or not it is open is controlled by the parent component )
 * @param  handleModalClose - whatever is run when the parent modal closes. This exists because confirming that they want to leave the form should close both the dialog and the underlying modal
 * @param  handleClose - handles the closing of the dialog
 * @returns  - The alert itself
 */
interface leaveDirtyFormConfirmationProps {
  open: boolean;
  setOpen: $TSFixMe;
  handleModalClose: $TSFixMe;
  handleClose?: $TSFixMe;
  warningText?: $TSFixMe;
}
export const LeaveDirtyFormConfirmation = ({
  open,
  setOpen,
  handleModalClose,
  handleClose = () => {},
  warningText = 'The form contains unsaved data which will be lost if you leave. Are you sure?',
}: leaveDirtyFormConfirmationProps) => {
  const backToForm = () => {
    setOpen(false);
  };
  const leaveModal = () => {
    setOpen(false);
    handleModalClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        data-testid='leave-dirty-form-confirmation'
      >
        <DialogTitle id='alert-dialog-title'>Are You Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' sx={{ mt: 2 }}>
            {warningText}
          </DialogContentText>
          <DialogActions sx={{ pr: '0 !important' }}>
            <Button onClick={leaveModal}>I'm Sure</Button>
            <Button variant='contained' onClick={backToForm}>
              Go Back
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
