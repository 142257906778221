import theme from './WalnutAtbTheme';
import logo from './assets/MainLogo.svg';
import squareLogo from './assets/squareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../../hooks/useSettings/WebsiteSettings';
import { WalnutPartnersFooter } from '../Footer';

// Walnut (commercial) Config
export const WalnutAtbSettings = new WebsiteSettings({
  slug: 'walnut-atb',
  host: 'atb.gowalnut.com',
  companyName: 'Walnut',
  privacyLink: 'https://www.gowalnut.com/privacy-atb',
  // hours: '8:30am - 5:30pm',
  phone: '(403) 768-2227',
  email: 'atb.help@gowalnut.com',
  // chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo,
  squareLogo,
  title: 'Walnut | Unlock embedded insurance',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: true,
  contactInformationFirst: true,
  // personalInsurance: true,
  headerCss: { '& img': { maxHeight: '100px' } },
  thankYouText:
    'A licensed insurance advisor from Walnut and partners will be in touch with you in the next 24 - 48 hours.',
  Footer: WalnutPartnersFooter,
});
