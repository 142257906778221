import { graphql } from '../../gql';

export const INSURANCE_RATE_AUDITS = graphql(`
  query insuranceRateAudits(
    $token: String!
    $organizationName: String
    $startDate: Date
    $endDate: Date
  ) {
    getRateTableAudit(
      token: $token
      organizationName: $organizationName
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      organization {
        name
      }
      actualRateId
      oldValue
      oldGroup
      oldSymbol
      oldDisplayName
      newValue
      newGroup
      newSymbol
      newDisplayName
      userEmail
      createdAt
      action
      headers
    }
  }
`);
