import { Link } from '@reach/router';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

interface InsufficientPermissionsErrorPageProps {
  deniedIdentifier?: string;
}

/**
 * A page / element to show in place of something that the user does not have the correct permissions to view.
 * @param deniedIdenitifier - An identifier for what they are trying to view in order to make the error message clearer.
 */
export const InsufficientPermissionsErrorPage = ({
  deniedIdentifier,
}: InsufficientPermissionsErrorPageProps) => {
  return (
    <>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
        }}
        data-testid='insufficient-permissions-error-page'
      >
        <Box
          sx={{
            maxWidth: 480,
            margin: 'auto',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h2'
            component='h2'
            gutterBottom
            sx={{ textAlign: 'center' }}
          >
            Permission Denied
          </Typography>{' '}
          <Typography
            variant='body1'
            component='p'
            gutterBottom
            sx={{ textAlign: 'left' }}
          >
            You don't have permission to view{' '}
            {deniedIdentifier ? `the ${deniedIdentifier}` : 'this'}! If you
            believe this is an error, please send an email to{' '}
            <a
              href={`support@calefy.ca?subject=Additional Permissions${
                deniedIdentifier ? ` for ${deniedIdentifier}` : ''
              }`}
            >
              support@calefy.ca
            </a>{' '}
            clearly explaining why you need access.
          </Typography>
          <Button
            to='/insurtech/manager/dashboard'
            size='large'
            variant='contained'
            component={Link}
          >
            Go to Dashboard
          </Button>
        </Box>
      </Container>
    </>
  );
};
