import { graphql } from '../gql';

export const GET_OPTA_ICLARIFY_COMMERCIAL_CONSTRUCTION_DATA = graphql(`
  mutation getOptaIclarifyCommercialConstructionFields(
    $token: String!
    $answer: ID!
  ) {
    getOptaIclarifyCommercialConstructionData(token: $token, answer: $answer) {
      ok
      optaIclarifyCommercialConstructionData {
        ...OptaIclarifyCommercialConstructionDataFields
      }
    }
  }
`);
