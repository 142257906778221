import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { Form } from 'informed';
import { connect, useDispatch, useSelector } from 'react-redux';
import { QuoteWizardState } from '../../../store';
import RenderedQuestionInstance from '../../common/QuoteComponents/RenderedQuestionInstance';
import {
  setSaveButtonState,
  setCurrentFormApi,
} from '../../../store/QuoteWizardState';
// for saving the quote in an unfinished state
import { NextButton, BackButton } from '../NavigationButtons';

import { useWarnBeforeUnloadWithAnswers } from '../../../hooks';
import { useScrollToTopOnInitialMount } from '@calefy-inc/hooks';
import { hybridAnswers } from '../../util/Quote';
import {
  extractFullNamesFromErrors,
  answerInstancesToFormState,
  formStateToAnswerInstances,
} from './utility';

// classes and types
import { FormValues, FormApi } from 'informed';
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import {
  QuoteWizardQuestionInstance,
  QuoteWizardForm,
  QuoteWizardAnswerInstance,
} from '../classes';
import type { StepWizardChildProps } from 'react-step-wizard';
import type { StoreState, Dispatch } from '../../../store';
import { withFormStyles } from '../../../util/withFormStyles';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';
import { useWalnutGoogleAnalytics } from '../../../hooks';
import {
  GAEventNames,
  EventActions,
} from '../../../hooks/useWalnutGoogleAnalytics';
import { createPageLoadThunk } from '../../../store/analyticsStore';

/* Utility Functions - Form Handling */
function getSkipPolicySelection(state: StoreState): boolean {
  return state.quoteWizard.skipPolicySlection;
}
function getFirstFormStep(state: StoreState): any {
  return state.quoteWizard.stepWizard.componentMapping;
}
function hasContactInformationQuoteBeenSubmitted(state: StoreState) {
  // has the application with status CONTACT_INFO_ONLY been submitted? Used to determine whether or not to fire off the query
  return (
    state.quoteWizard.submittedQuotes.find(
      (quote) => quote.status === 'CONTACT_INFO_ONLY',
    ) !== undefined
  );
}

function getHasContactInformationBeenSubmitted(state: StoreState) {
  return Object.values(state.quoteWizard.formAnswers).some((answeredForm) =>
    answeredForm.answers.some(
      (answer) => answer.questionInstance.apiName === 'client_contact_info',
    ),
  );
}

const getFurthestState = (state: StoreState) =>
  state.quoteWizard.stepWizard.furthestStep;

const handleSubmitFailure = (errors: $TSFixMe) => {
  const erroredNames = extractFullNamesFromErrors(errors);
  let foundElement;
  for (let field of erroredNames) {
    foundElement = document.getElementById(field);
    if (!foundElement) {
      const foundElements = document.getElementsByName(field);
      if (foundElements.length > 0) {
        foundElement = foundElements[0];
      }
    }
    if (foundElement) {
      foundElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      break;
    }
  }
  if (!foundElement) {
    document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

/* The Component */
interface QuoteFormDisplayProps extends StepWizardChildProps {
  onSubmit: (values: FormValues) => void;
  form: QuoteWizardForm;
  classes: GenericObject;
  initialFormData: $TSFixMe;
  sendContactEmailOnSubmit: boolean;
  isContactInformationForm: boolean;
  submitContactInfoApplication: () => void;
  hasBeenMoved: boolean;
}
/**
 * Display a form (or part thereof) in the quote wizard
 * @param sendContactEmailOnSubmit - A boolean to indicate whether the component needs to send the contact information email query on submit. Some additional processing need to happen in the component (e.g. ensuring that we only fire it once, &c.)
 * @param hasBeenMoved - Has this quoteform had its position changed? ATM this only happens if it's a contact information form and the whitelabel wants it moved before the policy page. In this case, no save quote button should be visible
 */
export function QuoteFormDisplay({
  onSubmit,
  form,
  classes = {},
  initialFormData,
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  goToStep,
  sendContactEmailOnSubmit,
  isContactInformationForm, // does this form contain the contact information question?
  submitContactInfoApplication,
  hasBeenMoved,
}: QuoteFormDisplayProps) {
  useScrollToTopOnInitialMount();
  const dispatch = useDispatch();

  const { questionInstances } = form;

  const [blocked, setBlocked] = useState<boolean>(true);
  const [pendingNavType, setPendingNavType] = useState<
    'forward' | 'backward' | undefined
  >();
  const [formChangesPresent, setFormChangesPresent] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false); // has the form been submitted? i.e. are the answers in the redux store
  const [hasApplicationBeenSent, setHasApplicationBeenSent] =
    useState<boolean>(false); //have we already sent off the application? If so, we don't want to send it again

  useWarnBeforeUnloadWithAnswers(formChangesPresent);

  const formApiRef = useRef<FormApi | null>(null);

  const furthestStep = useSelector(getFurthestState);
  const skipPolicySelection = useSelector(getSkipPolicySelection);
  const componentMapping = useSelector(getFirstFormStep);
  const contactInformationQuoteSubmitted = useSelector(
    hasContactInformationQuoteBeenSubmitted,
  );
  /* const reconcileState = useSelector(getReconcileState); */
  const hasContactInformationBeenSubmitted = useSelector(
    getHasContactInformationBeenSubmitted,
  );
  const googleAnalytics = useWalnutGoogleAnalytics();

  const handleFormSubmission = useCallback(
    (data: FormValues) => {
      const answers = formStateToAnswerInstances(data, questionInstances);
      onSubmit({ answers });
    },
    [onSubmit, questionInstances],
  );

  // clear the pending nav type on initial mount
  useEffect(() => {
    setPendingNavType(undefined);
  }, []);

  // send the analytics on initial mount
  useEffect(() => {
    if (!dispatch) {
      return;
    }
    if (!form) {
      return;
    }
    const pageLoadData = {
      page: form.policy ? 'Policy Form' : 'General Information',
      pageNumber: currentStep,
      totalPages: totalSteps,
      formType: form.getName(),
      topLevelQuestions: form.questionInstances.map(({ apiName }) => apiName),
      leafQuestions: form.getLeafQuestions().map(({ apiName }) => apiName),
    };
    // @ts-expect-error
    dispatch(createPageLoadThunk(pageLoadData));
  }, [dispatch]);

  // send the appropriate signal through Google analytics
  useEffect(() => {
    if (!googleAnalytics || !form) {
      return;
    }
    // is this the contact information page?
    if (isContactInformationForm) {
      googleAnalytics.triggerEvent(
        GAEventNames.Contact,
        EventActions.PageLoad,
        '',
      );
    }
    // is this a general information page or a different policy page?
    if (form.policy === null) {
      googleAnalytics.triggerEvent(
        GAEventNames.GeneralInformation,
        EventActions.PageLoad,
        '',
      );
    }
    if (form.policy?.internalName === 'property') {
      googleAnalytics.triggerEvent(
        GAEventNames.Property,
        EventActions.PageLoad,
        '',
      );
    }
    if (form.policy?.internalName === 'general_liability') {
      googleAnalytics.triggerEvent(
        GAEventNames.GeneralLiability,
        EventActions.PageLoad,
        '',
      );
    }
  }, [googleAnalytics, form]);

  // set the formstate and savebutton state in the redux store
  useEffect(() => {
    const formState =
      formApiRef && formApiRef.current
        ? formApiRef.current.getState()
        : { values: {} };
    dispatch(
      setSaveButtonState({
        visible: !hasBeenMoved, // if it's been moved we don't want them to be able to save here - the lack of a policy form messes up the save / resume process
        reconcileState: () => handleFormSubmission(formState.values),
      }),
    );
  }, [dispatch, hasBeenMoved]);

  // Set the blocked state based on the submission and sendContactEmail confirmation state
  useEffect(() => {
    //console.log('About to set blocked with',{
    //   formSubmitted,
    //   sendContactEmailOnSubmit,
    //   contactInformationQuoteSubmitted,
    //   hasApplicationBeenSent
    // })
    if (
      formSubmitted &&
      (!sendContactEmailOnSubmit ||
        contactInformationQuoteSubmitted ||
        (sendContactEmailOnSubmit && hasApplicationBeenSent))
    ) {
      //console.log('set blocked to false')
      setBlocked(false);
    } else {
      //console.log('set blocked to true')
      setBlocked(true);
    }
  }, [
    formSubmitted,
    sendContactEmailOnSubmit,
    hasApplicationBeenSent,
    contactInformationQuoteSubmitted,
  ]);

  // navigate when not blocked
  useEffect(() => {
    //console.log('In navigation useEffect with', {blocked, pendingNavType})
    if (!blocked) {
      if (pendingNavType === 'forward') {
        nextStep();
      } else if (pendingNavType === 'backward') {
        previousStep();
      } else {
        throw new Error(`Invalid pending nav type ${String(pendingNavType)}`);
      }
    }
  }, [blocked, pendingNavType, nextStep, previousStep]);

  useLayoutEffect(() => {
    const instance = formApiRef.current;
    if (instance) {
      const formState = instance.getState();
      return () => {
        if (instance) {
          handleFormSubmission(formState.values);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the contact information has been stored in state, submit the form
  useEffect(() => {
    if (
      sendContactEmailOnSubmit &&
      hasContactInformationBeenSubmitted &&
      !contactInformationQuoteSubmitted &&
      !hasApplicationBeenSent
    ) {
      submitContactInfoApplication();
      setHasApplicationBeenSent(true);
    }
  }, [
    sendContactEmailOnSubmit,
    hasContactInformationBeenSubmitted,
    hasApplicationBeenSent,
    contactInformationQuoteSubmitted,
    submitContactInfoApplication,
  ]);

  return (
    <>
      <Form
        id='Forms'
        noValidate
        autoComplete='off'
        allowEmptyStrings
        preventEnter
        getApi={(api) => {
          formApiRef.current = api;
          dispatch(setCurrentFormApi(api));
        }}
        className={classes.form}
        initialValues={initialFormData}
        onSubmit={(values: FormValues) => {
          handleFormSubmission(values);
          setFormSubmitted(true);
        }}
        onChange={(formState: $TSFixMe) => {
          const formTouched = Object.keys(formState.touched).length > 0;
          setFormChangesPresent(formTouched);
        }}
        onSubmitFailure={handleSubmitFailure}
      >
        {({ formState }) => (
          <>
            {questionInstances.map(
              (questionInstance: QuoteWizardQuestionInstance) => (
                <RenderedQuestionInstance
                  questionInstance={questionInstance}
                  key={questionInstance.id}
                  classes={classes}
                />
              ),
            )}
            {/* <pre>{JSON.stringify(formState, null, 4)}</pre> */}
            <div className={classes.navButtons}>
              {furthestStep === currentStep ? (
                <BackButton
                  onClick={() => {
                    if (
                      skipPolicySelection &&
                      currentStep === componentMapping['General Information']
                    ) {
                      goToStep(1);
                    } else {
                      handleFormSubmission(formState.values);
                      previousStep();
                    }
                  }}
                  type='button'
                />
              ) : (
                <BackButton
                  onClick={() => {
                    if (
                      skipPolicySelection &&
                      currentStep === componentMapping['General Information']
                    ) {
                      goToStep(1);
                    } else {
                      setPendingNavType('backward');
                    }
                  }}
                  type='submit'
                />
              )}

              <NextButton
                onClick={() => {
                  setPendingNavType('forward');
                }}
                type='submit'
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
}

/* Theming */
const QuoteFormDisplayWithStyles = withFormStyles()(QuoteFormDisplay);

/* Connect Layer */
function mapStateToProps(state: StoreState, ownProps: $TSFixMe) {
  let formData: $TSFixMe;
  formData = answerInstancesToFormState(
    state.quoteWizard.formAnswers[ownProps.form.id] &&
      state.quoteWizard.formAnswers[ownProps.form.id].answers
      ? state.quoteWizard.formAnswers[ownProps.form.id].answers
      : [],
    ownProps.form.questionInstances,
  );
  // if (
  //   state.quoteWizard.formAnswers[ownProps.form.id] &&
  //   state.quoteWizard.formAnswers[ownProps.form.id].answers
  // ) {
  //   formData = answerInstancesToFormState(
  //     state.quoteWizard.formAnswers[ownProps.form.id].answers,
  //   );
  // }
  const allSelectedPolicyIds = state.quoteWizard.selectedPolicies.map(
    (fullPolicy) => fullPolicy.id,
  );
  const selectedBusinessType = state.quoteWizard.selectedBusinessType;

  const formsWithAnswers = Object.values(state.quoteWizard.formAnswers).filter(
    ({ form }) => {
      const selectedBusinessTypeId = !selectedBusinessType
        ? undefined
        : 'id' in selectedBusinessType
        ? selectedBusinessType.id
        : selectedBusinessType?.original.id;
      return (
        ((!form.policy ||
          allSelectedPolicyIds.some((id) => form?.policy?.id === id)) &&
          form.businessLine &&
          selectedBusinessType &&
          selectedBusinessTypeId &&
          form.businessLine.id === selectedBusinessTypeId) ||
        form.businessLine.internalName === 'generic'
      );
    },
  );
  const allRelevantForms = [
    state.quoteWizard.businessForm,
    ...state.quoteWizard.policyForms,
  ].filter(
    // @ts-expect-error
    (form) => !form.policy || allSelectedPolicyIds.includes(form.policy.id),
  );

  // where to navigate to
  return {
    initialFormData: formData ? formData : [],
    selectedBusinessType,
    formsWithAnswers,
    allRelevantForms,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const { submitAnswersForForm, confirmAnswersForForms } =
    QuoteWizardState.actions;

  function onSubmit({
    form,
    answers,
  }: {
    form: $TSFixMe;
    answers: Array<QuoteWizardAnswerInstance>;
  }): void {
    //console.log('in onSubmit', JSON.stringify({ form, answers }, null, 4));
    dispatch(submitAnswersForForm({ form, answers }));
  }

  const submitForms = (args: $TSFixMe) => {
    dispatch(confirmAnswersForForms(args));
  };

  return { onSubmit, submitForms };
}

function mergeProps(
  mappedState: ReturnType<typeof mapStateToProps>,
  mappedDispatch: ReturnType<typeof mapDispatchToProps>,
  ownProps: $TSFixMe,
) {
  return {
    ...mappedState,
    ...mappedDispatch,
    ...ownProps,
    onSubmit: (args: $TSFixMe) => {
      mappedDispatch.onSubmit({
        ...args,
        form: ownProps.form,
      });
    },
    submitContactInfoApplication: () => {
      const formsWithAnswers = hybridAnswers(
        mappedState.formsWithAnswers,
        // @ts-expect-error
        mappedState.allRelevantForms,
      );
      try {
        mappedDispatch.submitForms({
          formsWithAnswers: formsWithAnswers,
          status: 'CONTACT_INFO_ONLY',
          businessType: mappedState.selectedBusinessType,
          renewalInProgress: false,
        });
      } catch (e) {
        console.error(e);
        Bugsnag.notify(errorify(e));
      }
    },
  };
}

export const QuoteFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
);

export default QuoteFormConnect((mappedState) => (
  <QuoteFormDisplayWithStyles {...mappedState} />
));
