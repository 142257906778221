import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery, useMutation } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../hooks';
import { useSnackbar } from 'notistack';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../components/LoadingScreen';
import {
  BusinessListToolbar,
  BusinessListHead,
  BusinessMoreMenu,
} from '../../components/_dashboard/database/business';
// queries
import { RELAY_BUSINESS, DELETE_BUSINESS } from '../../../../queries';
// pages
import ErrorPage from '../Page500';
import { InsufficientPermissionsErrorPage } from '../../../common/ErrorHandling';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { BusinessType } from '../../../../Typescript';
import type { RouteComponentProps } from '@reach/router';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sicCode', label: 'SIC', alignRight: false },
  { id: 'displayName', label: 'Business Type', alignRight: false },
  { id: 'internalName', label: 'Internal Name', alignRight: false },
  { id: 'industry', label: 'Industry', alignRight: false },
  { id: 'keywords', label: 'Keywords', alignRight: false },
  { id: 'aliases', label: 'Aliases', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a: $TSFixMe, b: $TSFixMe, orderBy: $TSFixMe) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: $TSFixMe, orderBy: $TSFixMe) {
  return order === 'desc'
    ? (a: $TSFixMe, b: $TSFixMe) => descendingComparator(a, b, orderBy)
    : (a: $TSFixMe, b: $TSFixMe) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: $TSFixMe,
  comparator: $TSFixMe,
  query: $TSFixMe,
) {
  const stabilizedThis = array.map((el: $TSFixMe, index: $TSFixMe) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a: $TSFixMe, b: $TSFixMe) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (business) =>
        business.displayName.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        business.industry.displayName
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1,
    );
  }
  return stabilizedThis.map((el: $TSFixMe) => el[0]);
}

// ----------------------------------------------------------------------

interface BusinessListProps extends RouteComponentProps {}
export default function BusinessList(_props: BusinessListProps) {
  const { themeStretch } = useSettings();
  const { token } = useAuth();
  const hasEditBusinessPermission = useAllowByPermission('edit:business');
  const hasViewBusinessListPermission = useAllowByPermission(
    'view:database:business:table',
  );
  const { enqueueSnackbar } = useSnackbar();
  const [businessList, setBusinessList] = useState<Array<BusinessType>>([]);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof BusinessType>('displayName');
  const [filterName, setFilterName] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [endCursor, setEndcursor] = useState<$TSFixMe>();
  const [hasNextPage, setHasNextPage] = useState();
  const [hiddenQueryLoading, setHiddenQueryLoading] = useState<
    boolean | undefined
  >();

  // queries
  const [getBusinesses, { loading, data, error }] =
    useLazyQuery(RELAY_BUSINESS);

  // mutations
  const [
    deleteBusiness,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_BUSINESS);

  // effects
  useEffect(() => {
    getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasNextPage && rowsPerPage * (page + 2) >= businessList.length) {
      getBusinesses({
        variables: {
          first: 2000,
          cursor: endCursor,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessList, rowsPerPage, page]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      const newBusinesses = data.relayBusinesses.edges.map(
        (business: $TSFixMe) => ({
          ...business.node,
        }),
      );

      setBusinessList([...businessList, ...newBusinesses]);
    }
  }, [data, setBusinessList]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      setEndcursor(data.relayBusinesses.pageInfo.endCursor);
      // @ts-expect-error
      setHasNextPage(data.relayBusinesses.pageInfo.hasNextPage);
    }
  }, [data]);

  useEffect(() => {
    const anyLoading = loading || deleteLoading;
    if (anyLoading && businessList.length > 0) {
      setHiddenQueryLoading(true);
    } else {
      setHiddenQueryLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, deleteLoading]);

  useEffect(() => {
    if (deleteData) {
      // @ts-expect-error
      const deletedId = deleteData.deleteBusiness.deletedId;
      setBusinessList(
        businessList.filter((business) => business.id !== deletedId),
      );
      enqueueSnackbar('Business Deleted', { variant: 'success' });
    }
    if (deleteError) {
      console.error('Error:', deleteError);
      Bugsnag.notify(deleteError);
      enqueueSnackbar('Delete Failed', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData, deleteError]);

  // Loading actions
  if (loading && businessList.length === 0) {
    return <LoadingScreen />;
  }

  // Error actions
  if (error) {
    const anyError = error;
    console.error('Error:', anyError);
    Bugsnag.notify(anyError);
    return <ErrorPage />;
  }

  // handlers
  // @ts-expect-error
  const handleRequestSort = (event: $TSFixMe, property: $TSFixMe) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // @ts-expect-error
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // @ts-expect-error
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // @ts-expect-error
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  // @ts-expect-error
  const handleDeleteBusiness = (id) => {
    deleteBusiness({
      variables: {
        businessId: id,
        token,
      },
      update: (cache, { data }) => {
        let businesses;
        try {
          businesses = cache.readQuery({ query: RELAY_BUSINESS });
        } catch (e) {
          console.error('Failed to read business list from cache');
        }

        try {
          cache.writeQuery({
            query: RELAY_BUSINESS,
            data: {
              // @ts-expect-error
              relayBusinesses: businesses.relayBusinesses.edges.filter(
                (business: $TSFixMe) =>
                  // @ts-expect-error
                  business.id !== data.deleteBusiness.deletedId,
              ),
            },
          });
        } catch (e) {
          console.error('Failed to write business list in cache');
        }
      },
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - businessList.length) : 0;

  const filteredBusiness = applySortFilter(
    businessList,
    getComparator(order, orderBy),
    filterName,
  );

  const isBusinessNotFound = filteredBusiness.length === 0;

  return (
    <Page title='Database: Business List | Manager'>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        data-testid='business-table'
      >
        {hasViewBusinessListPermission === false ? (
          <InsufficientPermissionsErrorPage deniedIdentifier='Business List' />
        ) : (
          <>
            <HeaderBreadcrumbs
              heading='Business List'
              links={[
                { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
                { name: 'Business List' },
              ]}
              action={
                <Tooltip
                  title={
                    hasEditBusinessPermission
                      ? 'New Business'
                      : "You don't have the correct permissions to create a new business"
                  }
                >
                  <span>
                    <Button
                      variant='contained'
                      component={RouterLink}
                      to={'/insurtech/manager/database/business/create'}
                      startIcon={<Icon icon={plusFill} />}
                      disabled={!hasEditBusinessPermission}
                    >
                      New Business
                    </Button>
                  </span>
                </Tooltip>
              }
            />
            <Card>
              <BusinessListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
                isLoading={hiddenQueryLoading}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <BusinessListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={businessList.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredBusiness
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row: BusinessType) => {
                          const {
                            id,
                            displayName,
                            internalName,
                            sicCode,
                            industry,
                            keywords,
                            aliases,
                            isDeletable,
                          } = row;
                          //console.log({ keywords });
                          return (
                            <>
                              <TableRow hover key={id} tabIndex={-1}>
                                <TableCell align='left'>{sicCode}</TableCell>
                                <TableCell align='left'>
                                  {displayName}
                                </TableCell>
                                <TableCell
                                  align='left'
                                  sx={{ overflowWrap: 'anywhere' }}
                                >
                                  {internalName}
                                </TableCell>
                                <TableCell align='left'>
                                  {industry?.displayName || ''}
                                </TableCell>
                                <TableCell>
                                  {
                                    // @ts-expect-error
                                    keywords ? keywords.join(', ') : ''
                                  }
                                </TableCell>
                                <TableCell>
                                  {aliases
                                    ? // @ts-expect-error
                                      JSON.parse(aliases).join(', ')
                                    : ''}
                                </TableCell>
                                <TableCell align='right'>
                                  <BusinessMoreMenu
                                    business={row}
                                    handleDelete={() =>
                                      handleDeleteBusiness(id)
                                    }
                                    isDeletable={isDeletable}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isBusinessNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component='div'
                count={businessList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </Card>
          </>
        )}
      </Container>
    </Page>
  );
}
