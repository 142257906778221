// types and classes
import { GenericObject } from '@calefy-inc/utilityTypes';
import { Language, LanguageInterface } from '../Language';
/**
 * Ensure that any language-aware attributes of the propsBlob are converted to the language-unaware version. If the propsBlob already has a language imposed, then just use it as is
 */
export function imposeLanguageOnPropsBlob(
  propsBlob: GenericObject,
  language: Language,
): GenericObject {
  //console.log(
  //   `in imposeLanguageOnPropsBlob with ${JSON.stringify(propsBlob, null, 4)}`,
  // );
  const newPropsBlob = Object.entries(propsBlob).reduce(
    (acc: GenericObject, [k, v]) => {
      if (
        k === 'options' &&
        Array.isArray(v) &&
        v.length > 0 &&
        'value' in v[0] &&
        'labels' in v[0]
      ) {
        const newOptions = v.map(
          (option: {
            value: unknown;
            labels: Array<{ value: string; language: LanguageInterface }>;
          }) => {
            const matchedLabel = option.labels.find((label) =>
              language.equals(label.language),
            );
            if (!matchedLabel) {
              throw new Error(
                `Unable to find label matching language ${language} in ${JSON.stringify(
                  option,
                  null,
                  4,
                )}`,
              );
            }
            return { value: option.value, label: matchedLabel.value };
          },
        );
        return { ...acc, options: newOptions };
      }
      return { ...acc, [k]: v };
    },
    {},
  );
  return newPropsBlob;
}
