import { useState } from 'react';
import { Button } from '@mui/material';
import PreviewModal from './PreviewModal';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';

import { ErrorDialog } from '../../util/ErrorDialog';
import { validateForm } from '../../FormBuilder/components/validateForm';

// types and classes
import type { QuoteWizardForm } from '../../../QuoteWizard/classes';
import { ProgramBuilderForm } from '../../classes';

interface FormPreviewButtonProps {
  formTitle: string;
  questionInstances: QuoteWizardForm['questionInstances'];
  originalForm?: ProgramBuilderForm;
}
const FormPreviewButton = ({
  formTitle,
  questionInstances,
  originalForm,
}: FormPreviewButtonProps) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        endIcon={<Icon icon={eyeFill} />}
        onClick={() => {
          if (originalForm) {
            const formErrors = validateForm(originalForm);
            if (formErrors.length > 0) {
              setErrorMessages(formErrors);
              return;
            }
          }
          setPreviewOpen(true);
        }}
        disabled={!formTitle || questionInstances.length === 0}
      >
        Preview
      </Button>
      <PreviewModal
        formTitle={formTitle}
        questionInstances={questionInstances}
        open={previewOpen}
        handleClose={() => setPreviewOpen(false)}
      />
      <ErrorDialog
        open={errorMessages.length > 0}
        onClose={() => {
          setErrorMessages([]);
        }}
        messages={errorMessages}
      />
    </>
  );
};

export default FormPreviewButton;
