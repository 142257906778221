import {
  validateFormLanguages,
  validateFormModifierQuestionInstances,
  validateFormModifierNoDeletedAndAddedQuestions,
} from './validationFunctions';
import { validateQuestionBasics } from '../../../../../../../../FormManager/FormBuilder/components/validateForm/validationFunctions';

// types
import { ValidationFunction } from './types';
import { FinalFormModifier } from '../../../../classes';

/**
 * Takes in a list of validators and returns a function which applies each validator in turn. Should be kep in line with the check_form_integrity resolver on the backend (errors only)
 * @param  ...validators - A list of validators to apply in turn. Each function should have the signature (form: Form) => undefined | string (undefined if it is valid, string if it is invalid; the string should be some sort of error message )
 * @return - A combined validation function which takes in a single form
 */
export function applyValidators(
  ...validators: Array<ValidationFunction>
): ValidationFunction {
  return (form: FinalFormModifier) => {
    const errorMessages: Array<string> = [];
    for (let validator of validators) {
      const validateState = validator(form);
      errorMessages.push(...validateState);
    }
    // return only the *unique* error messages
    return [...new Set(errorMessages)];
  };
}

const relevantValidators: Array<ValidationFunction> = [
  validateFormLanguages,
  validateQuestionBasics,
  validateFormModifierQuestionInstances,
  validateFormModifierNoDeletedAndAddedQuestions,
];

export const validateFormModifier = applyValidators(...relevantValidators);
