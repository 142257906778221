import theme from './RockChainInsuranceTheme';
import logo from './assets/MainLogo.png';
import squareLogo from './assets/AmiRockChainSquareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import { Icon } from '@iconify/react';
import dumpTruck from '@iconify-icons/mdi/dump-truck';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const businessIcons = (businessName: string) => {
  switch (businessName) {
    case 'quarrying_sand_gravel_digging':
      return <Icon icon={dumpTruck} width={'35px'} />;

    default:
      return <Icon icon={dumpTruck} width={'35px'} />;
  }
};

const businessFilter = (business: $TSFixMe) => {
  const allowedBusinessNames = ['quarrying_sand_gravel_digging'];
  if (
    process.env.NODE_ENV !== 'production' ||
    window.location.origin === 'https://staging-calefy.fuseinsurance.ca'
  ) {
    allowedBusinessNames.push('testing_business');
  }
  return allowedBusinessNames.includes(business.internalName);
};

// Config
const settings = new WebsiteSettings({
  slug: 'rockchaininsurance',
  host: 'secure.engineersinsurance.ca',
  companyName: 'RockChain Insurance',
  hours: '8:30am - 5:30pm',
  phone: '1-844-4CA-LEFY (422-5339)',
  email: 'info@calefy.ca',
  chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo: logo,
  businessFilter: businessFilter,
  tileIcons: businessIcons,
  title: 'RockChain Insurance',
  favicon,
  squareLogo,
});

export default settings;
