import { useParams } from '@reach/router';
// material
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import { NewProgramBuilder } from '../../../../components/ProgramBuilder';
import { FormManager } from '../../../FormManager';

// types and classes
import type { RouteComponentProps } from '@reach/router';

// ----------------------------------------------------------------------

interface PolicyCreateProps extends RouteComponentProps {}
export default function PolicyCreate(_props: PolicyCreateProps) {
  const { themeStretch } = useSettings();
  const { businessId, policyId } = useParams();

  return (
    <>
      <Page title={'Forms: Edit Form | Manager'}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={'Edit form'}
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Forms', href: PATH_DASHBOARD.forms.root },
              { name: 'Edit form' },
            ]}
          />

          <FormManager businessId={businessId} policyId={policyId} />
        </Container>
      </Page>
    </>
  );
}
