import { useState } from 'react';
import { InputToggle, TextField } from '@calefy-inc/informedMaterial';
import { ArrayField } from 'informed';
import { Typography, IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

// types and classes
import { Component } from './classes';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language } from '../../../Typescript/classes';
import {
  QuoteComponentProps,
  ProgramBuilderComponentProps,
  QuoteComponentValidationFunctionReturn,
} from './types';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';
import { formatAsReadableList } from './utility';

// create program builder component
function ProgramBuilderComponent({
  classes = {},
  languages,
}: ProgramBuilderComponentProps) {
  const [previousLabels, setPreviousLabels] = useState({}); // we need this to tell when a label switches from a number -> not, in which case we should clear the associated label (since it is almost surely not correct)
  return (
    <>
      <Typography variant='caption' sx={{ mt: 2 }} color='textSecondary'>
        At least 2 options are required.
      </Typography>
      <Typography variant='caption' color='textSecondary'>
        If you are entering a numerical value, do not enter any formatting
        (spaces, $, %).
      </Typography>
      <Typography variant='caption' color='textSecondary'>
        If a label is longer than 16 characters a dropdown menu will be
        displayed instead.
      </Typography>
      <Typography variant='caption' sx={{ mb: 2 }} color='textSecondary'>
        If you have more than 4 options, a dropdown menu is the default.
      </Typography>
      <ArrayField field='props_blob_options'>
        {(arrayFieldProps) => {
          // @ts-expect-error
          const { add, fields, arrayFieldApi } = arrayFieldProps;
          if (fields.length < 2) {
            add();
          }
          return (
            <>
              {/* @ts-expect-error */}
              <ArrayField.Items>
                {(arrayFieldItemsProps: $TSFixMe) => {
                  const { remove, field, index, setValue } =
                    arrayFieldItemsProps;
                  //console.log({ arrayFieldItemsProps });
                  return (
                    <>
                      <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        spacing={1}
                        style={{
                          marginBottom: '2em',
                        }}
                      >
                        <Grid item xs={5}>
                          {languages.map((language) => {
                            return (
                              <TextField
                                onChange={
                                  language.equals(Language.english)
                                    ? (e: $TSFixMe) => {
                                        // if the label is a number, set the value to that automatically
                                        if (
                                          e &&
                                          e.target &&
                                          typeof e.target.value === 'string'
                                        ) {
                                          const convertedLabel =
                                            e.target.value.replace(
                                              /[$,]/gi,
                                              '',
                                            );
                                          const isNumber =
                                            convertedLabel !== '' &&
                                            !Number.isNaN(
                                              Number(convertedLabel),
                                            );
                                          const wasPreviousNumber =
                                            // @ts-expect-error
                                            previousLabels[index] !== '' &&
                                            !Number.isNaN(
                                              // @ts-expect-error
                                              Number(previousLabels[index]),
                                            );
                                          if (isNumber) {
                                            setValue('value', convertedLabel);
                                          } else if (
                                            !isNumber &&
                                            wasPreviousNumber
                                          ) {
                                            setValue('value', '');
                                          }
                                        }
                                        setPreviousLabels((old) => ({
                                          ...old,
                                          [index]: e.target.value,
                                        }));
                                      }
                                    : undefined
                                }
                                className={classes.textField}
                                classes={classes}
                                label={`Label #${index + 1} (${
                                  language.fullName
                                })`}
                                //helperText="Enter the label for the option. This will be displayed on a button or as a select option"
                                field={`${field}.${language.generatePrefixedString(
                                  'labels',
                                )}`}
                                variant='standard'
                              />
                            );
                          })}
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            className={classes.textField}
                            classes={classes}
                            label={`Value #${index + 1}`}
                            helperText='Enter the value for the option. This is the actual value of the button or select option'
                            field={`${field}.value`}
                            variant='standard'
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip title='Move option up'>
                            <span>
                              <IconButton
                                aria-label='move option up'
                                disabled={index === 0}
                                onClick={() => {
                                  try {
                                    arrayFieldApi.swap(index, index - 1);
                                  } catch (e) {
                                    Bugsnag.notify(errorify(e));
                                    console.error(
                                      `Error swapping fields ${
                                        index - 1
                                      }, ${index}:`,
                                      e,
                                    );
                                  }
                                }}
                              >
                                <ArrowUpwardIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title='Move option down'>
                            <span>
                              <IconButton
                                aria-label='move option down'
                                disabled={index === fields.length - 1}
                                onClick={() => {
                                  try {
                                    arrayFieldApi.swap(index, index + 1);
                                  } catch (e) {
                                    Bugsnag.notify(errorify(e));
                                    console.error(
                                      `Error swapping fields ${index}, ${
                                        index + 1
                                      }:`,
                                      e,
                                    );
                                  }
                                }}
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            disabled={fields.length <= 2}
                            aria-label='delete option'
                            color='primary'
                            size='small'
                            onClick={remove}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
                {/* @ts-expect-errors */}
              </ArrayField.Items>

              <Tooltip title='Add Option'>
                <IconButton
                  aria-label='Add Option'
                  type='button'
                  color='primary'
                  onClick={add}
                  sx={{
                    my: 2,
                    alignSelf: 'center',
                  }}
                  size='large'
                >
                  <AddCircleIcon color='primary' sx={{ fontSize: 32 }} />
                </IconButton>
              </Tooltip>
            </>
          );
        }}
      </ArrayField>
      <TextField
        field='defaultValue'
        label='Default Value (ensure that it is associated with a label)'
      />
    </>
  );
}

const quoteComponent = function ({
  questionInstance,
  classes = {},
  ...props
}: QuoteComponentProps) {
  const { options } = questionInstance.propsBlob;

  return (
    <InputToggle
      field={questionInstance.generateFieldName()}
      label={questionInstance.label}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      classes={classes}
      className={classes.yesNoToggle}
      options={options}
      {...props}
    />
  );
};

export function validateInputToggle(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const errors: Array<string> = [];
  if (!questionInstance.propsBlob) {
    return [
      `Input toggle with name "${questionInstance.generateDisplayName()}" is missing some required attributes.`,
    ];
  }
  const { options } = questionInstance.propsBlob;
  if (!options || options.length === 0) {
    return [
      `Input toggle with name "${questionInstance.generateDisplayName()}" is missing some options. You must provide at least two.`,
    ];
  }
  if (options.length === 1) {
    return [
      `Input toggle with name "${questionInstance.generateDisplayName()}" is missing an option. You must provide at least two.`,
    ];
  }

  // ensure that all values are unique
  // @ts-expect-error
  const valueCountMapping = options.reduce((acc, option) => {
    const { value } = option;
    acc[value] = value in acc ? acc[value] + 1 : 1;
    return acc;
  }, {});
  const valuesWithMultiples = Object.entries(valueCountMapping).reduce(
    (acc: Array<string>, [value, count]) => {
      // @ts-expect-error
      if (count > 1) {
        acc.push(value);
      }
      return acc;
    },
    [],
  );
  if (valuesWithMultiples.length > 0) {
    errors.push(
      `Input toggle with API name ${questionInstance.apiName} has ${
        valuesWithMultiples.length == 1 ? 'a value' : 'some values'
      } which appear more than once: ${formatAsReadableList(
        valuesWithMultiples.map((value) => `"${value}"`),
      )}. Eah value can appear only once.`,
    );
  }

  if (questionInstance.defaultValue) {
    const values: Array<string> = Array.from(
      // @ts-expect-error
      new Set(options.map((option) => option.value)),
    );
    if (!values.includes(questionInstance.defaultValue)) {
      errors.push(
        `Input toggle with API name ${
          questionInstance.apiName
        } has a default value ("${
          questionInstance.defaultValue
        }") which is not associated with any label. The options are ${formatAsReadableList(
          values.map((value) => `"${value}"`),
        )}.`,
      );
    }
  }

  return errors;
}

const IconComponent = () => {
  return <RadioButtonCheckedRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'InputToggle',
    typeLabel: 'Input Toggle (Facultative)',
    quoteComponent,
    dataType: 'string',
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateInputToggle,
    icon: IconComponent,
  });
}

export { quoteComponent };
