import { useEffect, useState } from 'react';

export interface IframeParams {
  businessId?: string | number;
  policies: Array<string>;
  toBusiness: boolean;
}

export const useIframeQueryParams = (): IframeParams => {
  const [toBusiness, setToBusiness] = useState<boolean>(false); // should the navigation be directly to the commercial business selection?
  const [businessId, setBusinessId] = useState<string | undefined>(); // what is the requested business id? Can be the ID or internal name
  const [requestedPolicyInternalNames, setRequestedPolicyInternalNames] =
    useState<Array<string>>([]); // optional list of internal names of policies to automatically have selected

  // on initial load, get the requested policies, business id, and whether we're going directly to a business
  useEffect(() => {
    // get the policy search params
    const params = new URLSearchParams(window.location.search);
    const paramBusinessId = params.get('businessId');
    const paramPolicies = params.getAll('policy');
    const paramToBusiness = params.get('toBusiness');
    setRequestedPolicyInternalNames(paramPolicies);
    setToBusiness(paramToBusiness === 'true');
    setBusinessId(paramBusinessId === null ? undefined : paramBusinessId);
  }, []);

  return {
    businessId,
    policies: requestedPolicyInternalNames,
    toBusiness,
  };
};
