import { theme } from './theme';
import logo from './assets/logo.png';
import logo2 from './assets/logo_2.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';

// Boardwalk Config
export const boardwalkSettings = new WebsiteSettings({
  slug: 'boardwalk',
  host: 'secure.myboardwalk.ca',
  companyName: 'Boardwalk',
  phone: '(416) 477-9771',
  email: 'contact@myboardwalk.ca',
  companyTheme: theme,
  logo: logo,
  squareLogo: logo2,
  title: 'Boardwalk Insurance',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: true,
  contactInformationFirst: true,
  headerCss: { '& img': { maxHeight: '120px' } },
  // personalInsurance: true,
});
