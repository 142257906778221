import Button, { ButtonProps } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface AddItemButtonProps extends ButtonProps {
  text: string;
}
export const AddItemButton = ({ text, ...rest }: AddItemButtonProps) => {
  return (
    <Tooltip title={text}>
      <Button
        variant='contained'
        color='primary'
        startIcon={<AddCircleIcon />}
        sx={{
          flexDirection: 'column', // Arrange icon and text in a column
          '& .MuiButton-startIcon': {
            // Style adjustments for the icon
            marginTop: 1,
          },
        }}
        {...rest}
      >
        {text}
      </Button>
    </Tooltip>
  );
};
