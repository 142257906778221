// this is the raw redux state from the quote wizard
export const glAnswers = [
  {
    questionInstance: {
      id: '278',
      apiName: 'cgl_limit',
      displayName: 'CGL Limit',
      label: 'How much General Liability would you like?',
      helpText: null,
      component: 'InputToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          {
            value: '2000000',
            label: '$2,000,000',
          },
          {
            value: '3000000',
            label: '$3,000,000',
          },
          {
            value: '5000000',
            label: '$5,000,000',
          },
          {
            value: '10000000',
            label: '$10,000,000',
          },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: '3000000',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '38064',
      apiName: 'policy_effective_date',
      displayName: 'Policy Effective Date',
      label: 'Desired Policy Effective Date:',
      helpText: null,
      component: 'calendar',
      dataType: 'string',
      propsBlob: {},
      required: true,
      subQuestions: [],
    },
    value: '06/15/2022',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '8017',
      apiName: 'operations_description_generic',
      displayName: 'Operations Description Generic',
      label:
        'Please provide a 2 - 5 sentence description of all business operations:',
      helpText: null,
      component: 'textarea',
      dataType: 'string',
      propsBlob: {},
      required: true,
      subQuestions: [],
    },
    value:
      'I sell super sunflowers. Slinging some sweet sunflowers. Slamming sunflower sales solely on Sundays.',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '40797',
      apiName: 'experiences',
      displayName: 'Experiences',
      label: '...',
      helpText: null,
      component: 'logic',
      dataType: 'nested',
      propsBlob: {
        operator: 'equals',
        condition: 'yes',
      },
      required: false,
      subQuestions: [
        {
          id: '42020',
          apiName: 'years_in_business',
          displayName: 'Years in Business',
          label: 'Have you been in business for less than 3 years?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '42021',
          apiName: 'years_experience',
          displayName: 'Years Experience',
          label:
            'Please detail out any relevant prior experience in related operations:',
          helpText: null,
          component: 'textarea',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '42020',
          apiName: 'years_in_business',
          displayName: 'Years in Business',
          label: 'Have you been in business for less than 3 years?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        value: 'no',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '44227',
      apiName: 'subcontractors_',
      displayName: 'Subcontractors ',
      label: '...',
      helpText: null,
      component: 'logic',
      dataType: 'nested',
      propsBlob: {
        operator: 'equals',
        condition: 'yes',
      },
      required: false,
      subQuestions: [
        {
          id: '44228',
          apiName: 'subcontractors_boolean',
          displayName: 'Subcontractors Boolean',
          label: 'Do you subcontract out any of your work?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: false,
          subQuestions: [],
        },
        {
          id: '44229',
          apiName: 'subcontract_percentage',
          displayName: 'Subcontract Percentage',
          label: 'What percentage of your work is subcontracted out?',
          helpText: 'As a percentage of revenue',
          component: 'slider',
          dataType: 'number',
          propsBlob: {
            dataType: '%',
            slider_max: 100,
          },
          required: false,
          subQuestions: [],
        },
        {
          id: '44230',
          apiName: 'type_of_subcontracted_work',
          displayName: 'Type of Subcontracted Work',
          label: 'What kind of work do you subcontract out?',
          helpText: null,
          component: 'textarea',
          dataType: 'string',
          propsBlob: {},
          required: false,
          subQuestions: [],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '44228',
          apiName: 'subcontractors_boolean',
          displayName: 'Subcontractors Boolean',
          label: 'Do you subcontract out any of your work?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: false,
          subQuestions: [],
        },
        value: 'no',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '36840',
      apiName: 'insurer_and_broker',
      displayName: 'Insurer and Broker',
      label: '...',
      helpText: null,
      component: 'logic',
      dataType: 'nested',
      propsBlob: {
        operator: 'equals',
        condition: 'yes',
      },
      required: false,
      subQuestions: [
        {
          id: '41433',
          apiName: 'current_insurer',
          displayName: 'Current Insurer',
          label: 'Do you currently carry insurance for this business?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '41434',
          apiName: 'broker',
          displayName: 'Broker',
          label: 'Current Broker:',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        {
          id: '41435',
          apiName: 'insurer',
          displayName: 'Insurer',
          label: 'Current Insurer:',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        {
          id: '41436',
          apiName: 'premium',
          displayName: 'Premium',
          label: 'Current Premium:',
          helpText: null,
          component: 'numberinput',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '41437',
          apiName: 'limits',
          displayName: 'Limits',
          label: 'Current General Liability limit:',
          helpText: null,
          component: 'numberinput',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
          },
          required: true,
          subQuestions: [],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '41433',
          apiName: 'current_insurer',
          displayName: 'Current Insurer',
          label: 'Do you currently carry insurance for this business?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        value: 'yes',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41434',
          apiName: 'broker',
          displayName: 'Broker',
          label: 'Current Broker:',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        value: 'First Name Insurance',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41435',
          apiName: 'insurer',
          displayName: 'Insurer',
          label: 'Current Insurer:',
          helpText: null,
          component: 'textfield',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
        },
        value: 'Bob Badbroker',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41436',
          apiName: 'premium',
          displayName: 'Premium',
          label: 'Current Premium:',
          helpText: null,
          component: 'numberinput',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
          },
          required: true,
          subQuestions: [],
        },
        value: '47000',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41437',
          apiName: 'limits',
          displayName: 'Limits',
          label: 'Current General Liability limit:',
          helpText: null,
          component: 'numberinput',
          dataType: 'number',
          propsBlob: {
            dataType: '$',
          },
          required: true,
          subQuestions: [],
        },
        value: '200000',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      id: '40825',
      apiName: '5_years_claim',
      displayName: '5 years claim',
      label: '...',
      helpText: null,
      component: 'logic',
      dataType: 'nested',
      propsBlob: {
        operator: 'equals',
        condition: 'yes',
      },
      required: false,
      subQuestions: [
        {
          id: '41971',
          apiName: 'claim_check_5',
          displayName: 'Claim Check 5',
          label:
            'Have you had any commercial insurance claims in the past 5 years?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        {
          id: '41972',
          apiName: 'claims_information',
          displayName: 'Claims Information',
          label: 'Please detail the following for each claim:',
          helpText: null,
          component: 'repeatable',
          dataType: 'nested',
          propsBlob: {
            repeatable: true,
          },
          required: true,
          subQuestions: [
            {
              id: '41973',
              apiName: 'claims_description',
              displayName: 'Claims Description',
              label: 'Description of the Claim:',
              helpText: null,
              component: 'textfield',
              dataType: 'string',
              propsBlob: {},
              required: true,
              subQuestions: [],
            },
            {
              id: '41974',
              apiName: 'claim_amount',
              displayName: 'Claim Amount',
              label: 'Claim Amount:',
              helpText: null,
              component: 'numberinput',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '41975',
              apiName: 'insurance_payout',
              displayName: 'Insurance Payout',
              label: 'Total Insurance payout for the claim:',
              helpText: null,
              component: 'numberinput',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
      ],
    },
    value: '1',
    subAnswers: [
      {
        questionInstance: {
          id: '41971',
          apiName: 'claim_check_5',
          displayName: 'Claim Check 5',
          label:
            'Have you had any commercial insurance claims in the past 5 years?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              {
                value: 'yes',
                label: 'Yes',
              },
              {
                value: 'no',
                label: 'No',
              },
            ],
          },
          required: true,
          subQuestions: [],
        },
        value: 'no',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
      {
        questionInstance: {
          id: '41972',
          apiName: 'claims_information',
          displayName: 'Claims Information',
          label: 'Please detail the following for each claim:',
          helpText: null,
          component: 'repeatable',
          dataType: 'nested',
          propsBlob: {
            repeatable: true,
          },
          required: true,
          subQuestions: [
            {
              id: '41973',
              apiName: 'claims_description',
              displayName: 'Claims Description',
              label: 'Description of the Claim:',
              helpText: null,
              component: 'textfield',
              dataType: 'string',
              propsBlob: {},
              required: true,
              subQuestions: [],
            },
            {
              id: '41974',
              apiName: 'claim_amount',
              displayName: 'Claim Amount',
              label: 'Claim Amount:',
              helpText: null,
              component: 'numberinput',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
              },
              required: true,
              subQuestions: [],
            },
            {
              id: '41975',
              apiName: 'insurance_payout',
              displayName: 'Insurance Payout',
              label: 'Total Insurance payout for the claim:',
              helpText: null,
              component: 'numberinput',
              dataType: 'number',
              propsBlob: {
                dataType: '$',
              },
              required: true,
              subQuestions: [],
            },
          ],
        },
        value: 1,
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
];
