import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, take, call, spawn, select } from 'redux-saga/effects';
import { submitAnswerMutation } from '../queries';
import Bugsnag from '@bugsnag/js';
import { isEqual } from 'lodash';
import { RELAY_QUOTES } from '../queries';

import { mergeAnswers, sanitizeAnswers } from './utility';

// types and classes
import { Language, UnifiedCompletedForm } from '../Typescript/classes';
import {
  QuoteWizardQuote,
  QuoteWizardForm,
  QuoteWizardAnswerInstance,
  QuoteWizardAnsweredForm,
} from '../components/QuoteWizard/classes';
// import { UnifiedAnswerInstance } from '../Typescript/classes';
import type {
  QuestionsPerPage,
  PolicySummary,
} from '../components/QuoteWizard/types';
import type { BusinessType, Policy } from '../Typescript';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { SagaArgument } from './types';
import type { StepWizardChildProps } from 'react-step-wizard';
import type { ComponentMapping } from '../components/QuoteWizard/types';
import type { QuoteStatus } from '../Typescript/backend/types';
import { PremiumProposal } from '../Typescript/backend/classes';
import { BusinessTypeAlias } from '../Typescript/classes';
import { StoreState } from '.';

import {
  createApplicationBegunThunk,
  createApplicationFinishedThunk,
  createApplicationSavedThunk,
} from './analyticsStore';
import { errorify } from '../util';
import { indexNth } from '../util/findNth';
import { getAuthTokenFromLocalStorage } from '../components/Authentication';
import {
  answerInstancesToFormState,
  formStateToAnswerInstances,
} from '../components/QuoteWizard/QuoteForms/utility';

const PossibleErrorTypes = {
  incompleteQuoteSubmission: 'incompleteQuoteSubmission',
  completeQuoteSubmission: 'completeQuoteSubmission',
} as const;
type PossibleError = typeof PossibleErrorTypes[keyof typeof PossibleErrorTypes];
export type PossibleErrorValues = null | Error | string;

interface SaveButtonState {
  visible: boolean;
  reconcileState: () => void;
}

interface StepWizardState {
  currentStep: StepWizardChildProps['currentStep'];
  furthestStep: StepWizardState['currentStep'];
  goToStep?: StepWizardChildProps['goToStep'];
  componentMapping: ComponentMapping;
}
export interface QuoteWizardState {
  selectedInsurType: null | 'personal' | 'commercial' | 'both';
  selectedBusinessType: null | BusinessType | BusinessTypeAlias;
  selectedPolicies: Array<PolicySummary>;
  formAnswers: Record<string, QuoteWizardAnsweredForm>;
  quoteSubmissionTasks: $TSFixMe;
  submittedQuotes: Array<QuoteWizardQuote>;
  // TODO This is dumb now - it should be a form or null. Fix this!
  businessForm: QuoteWizardForm | {};
  policyForms: Array<QuoteWizardForm>;
  clientEmail: null | string;
  clientName: null | string;
  message: null | string;
  province: null | string;
  okToSubmitIncompleteQuote: boolean;
  currentQuoteUUID: null | string;
  errors: { [k in PossibleError]: PossibleErrorValues };
  saveButton: SaveButtonState;
  stepWizard: StepWizardState;
  resumingQuote: boolean;
  resumeToStep: null | number | string; // which step (numerical or string in componentMapping) to resume to
  currentFormApi: undefined | $TSFixMe;
  questionsPerPage: QuestionsPerPage;
  status: QuoteStatus | null;
  referralCode: string;
  selectedLanguage: Language;
  renewalInProgress: boolean;
  additionalInformation: string;
  confirmationInProgress: boolean; // whether the current quote is in the process of being confirmed by the user
  premiumProposals: Array<PremiumProposal>; // any received proposals for the current quote
  skipPolicySlection: boolean;
  blockContactInfoFirst: boolean; // hard stop to putting contact information before policy selection
  orphanedAnswerInstances: Array<QuoteWizardAnswerInstance>; // answer to questions which are not part of a form (e.g. they answer the contact info questions before the business selection). These should be merged into the actual form answers as soon as they are loaded.
  producerId: string | null; // producer ID associated with the quote
}
export const defaultInitialState: QuoteWizardState = {
  selectedInsurType: null,
  selectedBusinessType: null,
  selectedPolicies: [],
  formAnswers: {},
  quoteSubmissionTasks: {},
  submittedQuotes: [],
  businessForm: {},
  policyForms: [],
  clientEmail: null,
  clientName: null,
  message: null,
  province: null,
  okToSubmitIncompleteQuote: false,
  currentQuoteUUID: null,
  errors: {
    completeQuoteSubmission: null,
    incompleteQuoteSubmission: null,
  },
  saveButton: {
    visible: false,
    reconcileState: () => {},
  },
  stepWizard: {
    currentStep: 1,
    furthestStep: 1,
    goToStep: () => {},
    componentMapping: {},
  },
  resumingQuote: false,
  resumeToStep: null, // which step (numerical) to resume to
  currentFormApi: undefined,
  questionsPerPage: 5,
  referralCode: '',
  status: null,
  selectedLanguage: Language.english,
  renewalInProgress: false,
  additionalInformation: '',
  confirmationInProgress: false,
  premiumProposals: [],
  skipPolicySlection: false,
  blockContactInfoFirst: false,
  orphanedAnswerInstances: [],
  producerId: null,
};

const slice = createSlice({
  name: 'quote',
  initialState: defaultInitialState,
  reducers: {
    /**
     * Select a insurType and clear the rest of the QuoteWizard state
     * @param state - the current state
     * @param action - The action. It should have a payload { selectedInsurType: string }
     */
    selectInsurType: (
      state,
      action: PayloadAction<{
        selectedInsurType: null | 'personal' | 'commercial';
      }>,
    ) => {
      const { selectedInsurType } = action.payload;
      const previousSelectedInsurType = state.selectedInsurType;

      state.selectedInsurType = selectedInsurType;

      //if the insurance type is different, clear the rest of the state
      if (
        previousSelectedInsurType &&
        previousSelectedInsurType !== selectedInsurType
      ) {
        //console.log(
        //   `Differing insurance types old=${previousSelectedInsurType} - new ${selectedInsurType} - clearing state`,
        // );
        state.selectedBusinessType = null;
        state.selectedPolicies = [];
        state.formAnswers = {};
        state.quoteSubmissionTasks = {};
        state.submittedQuotes = [];
        state.businessForm = {};
        state.policyForms = [];
        state.clientEmail = null;
        state.clientName = null;
        state.currentQuoteUUID = null;
        state.message = null;
        state.okToSubmitIncompleteQuote = false;
        if (state.errors) {
          Object.keys(state.errors).forEach((key) => {
            // @ts-expect-error
            state.errors[key] = null;
          });
        } else {
          // @ts-expect-error
          state.errors = {};
        }
        state.stepWizard = {
          furthestStep: 1,
          currentStep: 1,
          goToStep: () => {},
          componentMapping: {},
        };
        state.currentFormApi = undefined;
        state.skipPolicySlection = false;
        state.blockContactInfoFirst = false;
      }
    },
    /**
     * Select a business and clear the rest of the QuoteWizard state
     * @param state - the current state
     * @param action - The action. It should have a payload { selectedBusinessType: BusinessLine }
     */
    selectBusinessType: (
      state,
      action: PayloadAction<{
        selectedBusinessType: BusinessType | BusinessTypeAlias;
      }>,
    ) => {
      const { selectedBusinessType } = action.payload;
      const previousSelectedBusinessType = state.selectedBusinessType;
      const previousSelectedBusinessTypeId = !previousSelectedBusinessType
        ? undefined
        : 'id' in previousSelectedBusinessType
        ? previousSelectedBusinessType.id
        : previousSelectedBusinessType?.original.id;

      state.selectedBusinessType = selectedBusinessType;

      const newlySelectedBusinessTypeId = !selectBusinessType
        ? undefined
        : 'id' in selectedBusinessType
        ? selectedBusinessType.id
        : selectedBusinessType.original.id;

      //if the business type is different, clear the rest of the state
      if (
        previousSelectedBusinessTypeId &&
        previousSelectedBusinessTypeId !== newlySelectedBusinessTypeId
      ) {
        for (let [k, v] of Object.entries(defaultInitialState).filter(
          ([k, _v]) =>
            ![
              'selectedBusinessType',
              'selectedInsurType',
              'questionsPerPage',
            ].includes(k),
        )) {
          // @ts-expect-error
          state[k] = v;
        }
      }
    },
    /**
     * Set the policies, business information form, and relevant policy information forms selected by the user
     * @param state - state
     * @param action - the action. If the options.overwrite is set to false, then we *only* add in new forms if a form matching it is not already in the list of loaded forms
     */
    setPolicies: (
      state,
      action: PayloadAction<{
        selectedPolicies: QuoteWizardState['selectedPolicies'];
        businessForm: QuoteWizardState['businessForm'];
        policyForms: QuoteWizardState['policyForms'];
        options?: {
          overwrite: boolean;
        };
      }>,
    ) => {
      const { selectedPolicies, businessForm, policyForms, options } =
        action.payload;
      //console.log('QUOTEWIZARDSTATE: in reduced with', action.payload);

      // NB - we only want to change it if they are actually different - otherwise we might end up infinitely rerendering
      if (!isEqual(state.selectedPolicies, selectedPolicies)) {
        state.selectedPolicies = selectedPolicies;
      }
      if (!isEqual(state.businessForm, businessForm)) {
        if (
          !options ||
          options?.overwrite ||
          // @ts-expect-error
          state.businessForm?.businessLine?.id !== businessForm.businessLine.id
        ) {
          state.businessForm = businessForm;
        }
      }
      if (!isEqual(state.policyForms, policyForms)) {
        if (!options || options?.overwrite) {
          state.policyForms = policyForms;
        } else {
          const unmatchedIncomingForms = policyForms.filter(
            (incomingForm) =>
              !state.policyForms.some((extantForm) =>
                incomingForm.matchByPolicyAndBusiness(extantForm),
              ),
          );
          state.policyForms = [...state.policyForms, ...unmatchedIncomingForms];
        }
      }

      // handle any default values. We put them in state here in case the user e.g. saves the application before they get to that page in the quote wizard
      [state.businessForm, ...state.policyForms].forEach((form) => {
        if (!(form instanceof QuoteWizardForm)) {
          return;
        }
        if (!state.formAnswers[form.id]) {
          const defaultAnswers = formStateToAnswerInstances(
            answerInstancesToFormState([], form.questionInstances),
            form.questionInstances,
          );
          state.formAnswers[form.id] = new QuoteWizardAnsweredForm({
            form,
            answers: defaultAnswers,
            inProgress: true,
            errors: [],
            complete: false,
          });
        }
      });

      // merge in any orphaned answer instances
      slice.caseReducers.mergeOrphanedAnswerInstances(
        state,
        slice.actions.mergeOrphanedAnswerInstances(),
      );
      //console.log('QUOTEWIZARDSTATE: done with setPolicies');
    },

    /**
     * Submit answers for form and mark that one as incomplete
     * @param state - state
     * @param action - action. Should have payload { form: Form, answers: [Answer] }
     */

    saveDraftAnswers: (
      state,
      action: PayloadAction<{
        form: QuoteWizardForm;
        answers: Array<QuoteWizardAnswerInstance>;
        errors: $TSFixMe;
      }>,
    ) => {
      const { form, answers, errors } = action.payload;

      state.formAnswers[form.id] = new QuoteWizardAnsweredForm({
        form,
        answers,
        inProgress: true,
        complete: !errors,
        errors: errors,
      });
    },

    /**
     * Submit answers for form and mark that one as complete
     * @param state - state
     * @param action - action. Should have payload { form: Form, answers: [Answer] }
     */
    submitAnswersForForm: (
      state,
      action: PayloadAction<{
        form: QuoteWizardForm;
        answers: Array<QuoteWizardAnswerInstance>;
        replace?: boolean; // if true, then the answers sent in will replace matching ones in state (instead of a wholesale replacement)
        nth?: number; // the instance of the answer to replace. Mostly for
      }>,
    ) => {
      let { form, answers, replace = false, nth } = action.payload;

      answers = sanitizeAnswers(answers); // get rid of the undefined ones and fix numbering

      const existingAnswers = state.formAnswers[form.id]
        ? state.formAnswers[form.id].answers
        : [];
      let mergedAnswers: Array<QuoteWizardAnswerInstance> = [];
      if (replace) {
        if (typeof nth !== 'number') {
          throw new Error(`Error with nth ${nth}`);
        }
        if (answers.length > 1) {
          throw new Error(
            `In submitAnswersForForm, when replacing can only replace a single answer at a time, not ${
              answers.length
            } - ${JSON.stringify(answers, null, 4)}`,
          );
        }
        const answer = answers[0];
        if (answer.subAnswers.length > 0) {
          answer.value = nth; // because it comes from the process with value = 1; this'll be a nested question of some sort
        }
        // do the replacement here
        const matchingIndex = indexNth(existingAnswers, nth, (elem) => {
          return answer.matchQuoteWizardQuestionInstanceById(
            elem.questionInstance,
          );
        });
        if (matchingIndex === -1) {
          // i.e. there is no matching answer -> create a new one
          mergedAnswers = [...existingAnswers, answer];
        } else {
          // replace the existing one
          mergedAnswers = [...existingAnswers];
          mergedAnswers.splice(matchingIndex, 1, answer);
        }
      } else {
        mergedAnswers = mergeAnswers(existingAnswers, answers);
      }

      // find the form matching the policy that is coming in (since the one coming in contains just a subset of the questions in the real form)
      let matchingForm;
      if (!form.policy && Object.keys(state.businessForm).length > 0) {
        matchingForm = state.businessForm;
      } else {
        matchingForm = state.policyForms.find((policyForm) =>
          policyForm.matchByPolicy(form),
        );
      }
      // fall back to the one that's sent in
      matchingForm = matchingForm ? matchingForm : form;

      state.formAnswers[form.id] = new QuoteWizardAnsweredForm({
        // @ts-expect-error
        form: matchingForm,
        answers: mergedAnswers,
        inProgress: false,
        complete: true,
      });
    },
    /**
     * Once the user has finished filling out the forms, this marks all of the forms as being in progress. It also commits the blob and email to the store.
     * @param state - state
     * @param action - Action. Should have a payload { formsWithAnswers: [Form], quoteBlob: str (the base64 blob of the quote pdf), clientEmail: str, complete: Boolean (whether the quote is complete or should be saved for later) }. formsWithAnswers comes from `formAnswers` in the store, but as an array and filtered by the currently selected business (or generic, since those forms can be used for any business). Has almost the same shape as formAnswers:
     * [
     * 	{
     * 		form: Form,
     * 		answers: [Answer],
     * 		inProgress: bool,
     * 		complete: bool
     * 	 }
     * 	]
     *
     * 	See <QuoteReview /> for more details
     *
     * 	Additional attributes in the payload are used by the Saga that handles this action (`handleQuoteSubmission`)
     */
    confirmAnswersForForms: (
      state,
      action: PayloadAction<{
        formsWithAnswers: Array<UnifiedCompletedForm>;
        clientEmail?: string;
        clientName?: string;
        message?: string;
        status?: QuoteStatus;
        businessType?: BusinessType;
        currentQuoteUUID: QuoteWizardState['currentQuoteUUID'];
        selectedPolicies: Array<Policy>;
        signature: $TSFixMe;
        additionalInformation?: string;
        renewalInProgress: QuoteWizardState['renewalInProgress'];
        storeExistingQuoteUuidOnComplete: boolean;
      }>,
    ) => {
      //console.log('In QuoteWizardState confirmAnswersForForms');
      const forms = action.payload.formsWithAnswers.map(({ form }) => form);

      forms.forEach((form) => {
        // we need this check because e.g. when saving an incomplete form, we generate a `fake` form answer that doesn't exist in state
        if (state.formAnswers[form.id]) {
          state.formAnswers[form.id].inProgress = true;
        }
      });

      state.clientEmail = action.payload.clientEmail || null;
      state.clientName = action.payload.clientName || null;
      state.message = action.payload.message || null;
      state.status = action.payload.status || 'INCOMPLETE';
    },
    /**
     * Marks a quote (set of forms) as submitted by marking all of the `inProgress` flags as false, setting the `completedQuote` property of all of the formAnswers to the current quote, and adds the quote to the `submittedQuotes` state attribute
     * Called through the Saga that deals with the `confirmAnswersForForms` action
     * @param state - State
     * @param action - Action. Should have a payload of {
     *    formsWithsAnswers: Same as for confirmAnswersForForms reducer
     *    quote: Quote
     *    businessType: BusinessLine
     * }
     */
    markQuoteAsSubmitted: (
      state,
      action: PayloadAction<{
        formsWithAnswers: Array<QuoteWizardAnsweredForm>;
        quote: QuoteWizardQuote;
        businessType: BusinessType;
      }>,
    ) => {
      //console.log('Beginning markQuoteAsSubmitted reducer');
      const forms = action.payload.formsWithAnswers.map(({ form }) => form);

      forms.forEach((form) => {
        if (state.formAnswers[form.id]) {
          // we need this check because when saving an incomplete form, sometimes we generate a `fake` form answer that doesn't exist in state
          state.formAnswers[form.id].inProgress = false;
          state.formAnswers[form.id].completedQuote = action.payload.quote;
        }
      });

      state.submittedQuotes.push(action.payload.quote);
      //console.log('Finished markQuoteAsSubmitted reducer');
    },
    /**
     * Reset the Quote Wizard state to its initial value
     * @param state - State
     * @param action - action;
     */
    resetSelections: (
      state,
      action: PayloadAction<
        Array<keyof typeof defaultInitialState> | undefined
      >,
    ) => {
      for (let [k, v] of Object.entries(defaultInitialState).filter(
        ([k, _v]) => {
          if (action?.payload) {
            // @ts-expect-error
            return !action.payload.includes(k);
          }
          return true;
        },
      )) {
        // @ts-expect-error
        state[k] = v;
      }
    },
    /**
     * Sets a flag which allows the SaveQuoteButton to fire off the submission of an incomplete quote
     * @param state - state
     * @param action - action. Should have payload { okToSubmitIncompleteQuote: bool }
     */
    setOkToSubmitIncompleteQuote: (
      state,
      action: PayloadAction<{ okToSubmitIncompleteQuote: boolean }>,
    ) => {
      const newValue = action.payload.okToSubmitIncompleteQuote;

      if (newValue !== true && newValue !== false) {
        throw new Error(
          `Must provide 'true' or 'false' to setOkToSubmitIncompleteQuote; you provided ${newValue}, a ${typeof newValue}`,
        );
      }

      state.okToSubmitIncompleteQuote = newValue;
    },
    /**
     * Sets the UUID of the quote currently being completed
     * @param state - the state
     * @param action - action. Should have payload { uuid: The uuid which is to be set }
     */
    setCurrentQuoteUUID: (
      state,
      action: PayloadAction<{
        uuid: string;
      }>,
    ) => {
      const { uuid } = action.payload;
      state.currentQuoteUUID = uuid;
    },
    /**
     * Updates the errors part of the store
     * @param state - state
     * @param action - Action. Payload should be { type: Error | null, type: Error | null, ... }. e.g. { completeQuoteSubmission: null, incompleteQuoteSubmission: new Error('Bang!') }. null -> a cleared / handled error
     */
    updateErrors: (
      state,
      action: PayloadAction<
        Partial<Record<PossibleError, PossibleErrorValues>>
      >,
    ) => {
      Object.keys(action.payload).forEach((key) => {
        // @ts-expect-error
        state.errors[key] = action.payload[key];
      });
    },
    /**
     * Sets the 'inProgress' flag of all forms to false - we're done trying to submit them!
     * @param state - state
     * @param action - action. No payload needed
     */
    cancelAllSubmissions: (state) => {
      const { formAnswers } = state;
      for (let answer of Object.values(formAnswers)) {
        answer.inProgress = false;
      }
    },
    /**
     * Sets the province flag if no location could be found in the province component
     * @param state - state
     * @param action - action. Action payload should {province: the selected province}
     */
    addProvince: (
      state,
      action: PayloadAction<{
        province: $TSFixMe;
      }>,
    ) => {
      const { province } = action.payload;
      state.province = province;
    },
    /**
     * Sets the save button state to visible or invisible
     * @param state - state
     * @param action - Action payload should be visible true or false
     */
    setSaveButtonVisibility: (
      state,
      action: PayloadAction<{ visible: boolean }>,
    ) => {
      const { visible } = action.payload;
      state.saveButton.visible = visible;
    },
    /**
     * Sets the save button state to visible or invisible and
     * stores the current form state in the store then saves it to quote
     * @param action - Action payload should set reconcile state function and visible true or false
     */
    setSaveButtonState: (state, action: PayloadAction<SaveButtonState>) => {
      state.saveButton = action.payload;
    },
    /**
     * Hide the SaveQuote button
     */
    hideSaveButton: (state) => {
      state.saveButton.visible = false;
      state.saveButton.reconcileState = () => {};
    },
    /**
     * Update State wizard state
     * @param state - state
     * @param action - Action payload will have functions from the step wizard and other information
     */
    updateStepWizardState: (
      state,
      action: PayloadAction<Partial<StepWizardState>>,
    ) => {
      state.stepWizard = { ...state.stepWizard, ...action.payload };
      if (action.payload.currentStep) {
        const newCurrentStep = action.payload.currentStep;
        const currentFurthestStep = state.stepWizard.furthestStep;
        state.stepWizard.furthestStep =
          newCurrentStep > currentFurthestStep
            ? newCurrentStep
            : currentFurthestStep;
      }
    },
    /**
     * Sets the resuming quotes to true
     * @param state - state
     * @param action - Action payload contains the resuming quote status
     */
    setResumingQuote: (state, action: PayloadAction<boolean>) => {
      state.resumingQuote = action.payload;
    },
    /**
     * Set the resumeToStep attribute with either a number or null
     */
    setResumeToStep: (
      state,
      action: PayloadAction<QuoteWizardState['resumeToStep']>,
    ) => {
      const resumeToStep = action.payload;
      state.resumeToStep = resumeToStep;
    },
    /**
     * Set the the current furthest step
     * @param state - state
     * @param action - Action payload contains current furthest step
     */
    setFurthestStep: (
      state,
      action: PayloadAction<StepWizardState['furthestStep']>,
    ) => {
      state.stepWizard.furthestStep = action.payload;
    },
    /**
     * Set Current form api
     *  @param state - state
     * @param action - Action payload contains current form api
     */
    setCurrentFormApi: (state, action: PayloadAction<$TSFixMe>) => {
      state.currentFormApi = action.payload;
    },
    /**
     * Set the number of questions to display per page on the quote wizard
     */
    setQuestionsPerPage: (state, action: PayloadAction<QuestionsPerPage>) => {
      const newQuestions = action.payload;
      if (newQuestions !== 'all' && typeof newQuestions !== 'number') {
        throw new TypeError(
          `Incorrect value for number of questions per page: it must be a number or the special string "all". You supplied ${newQuestions} of type ${typeof newQuestions}`,
        );
      }
      state.questionsPerPage = newQuestions;
    },

    /**
     * Set quote the quote referral code to be sent to the backend
     */
    setReferralCode: (
      state,
      action: PayloadAction<{
        referralCode: QuoteWizardState['referralCode'];
      }>,
    ) => {
      const { referralCode } = action.payload;
      //console.log(action.payload);
      if (typeof referralCode !== 'string') {
        throw new TypeError(
          'Incorrect value for referral code: it must be a string',
        );
      }
      state.referralCode = referralCode;
    },
    /**
     * Set the selected language
     */
    setLanguage: (state, action: PayloadAction<Language>) => {
      //console.log('QuoteWizardState: about to set language to', action.payload);
      state.selectedLanguage = action.payload;
    },
    /**
     * Indicate whether the current quote is currently being renewed
     */
    setRenewalInProgress: (state, action: PayloadAction<boolean>) => {
      state.renewalInProgress = action.payload;
    },
    /**
     * Adds additional information data to redux state
     */
    setAdditionalInformation: (state, action: PayloadAction<string>) => {
      state.additionalInformation = action.payload;
    },
    setConfirmationInProgress: (state, action: PayloadAction<boolean>) => {
      state.confirmationInProgress = action.payload;
    },
    /**
     * Set the premimium proposals for the current quote
     */
    setPremiumProposals: (
      state,
      action: PayloadAction<Array<PremiumProposal>>,
    ) => {
      //console.log(
      //   'About to set premiumProposals to',
      //   JSON.stringify(action.payload, null, 4),
      // );
      state.premiumProposals = action.payload;
    },
    /**
     * Set skip the skip policy state to true
     */
    setSkipPolicySelection: (state) => {
      state.skipPolicySlection = true;
    },
    /**
     * Add a form (or forms) to the redux store
     * @param action - payload. Has {forms: }, which is either the single form you want to add or an array of all of them. Also has {overwrite}, which indicates if you want to overwrite forms that match (have the same business type and policy)
     */
    addForms: (
      state,
      action: PayloadAction<{
        forms: QuoteWizardForm | Array<QuoteWizardForm>;
        overwrite?: boolean;
      }>,
    ) => {
      const overwrite =
        action.payload.overwrite === undefined
          ? true
          : action.payload.overwrite;
      const formsToAdd = Array.isArray(action.payload.forms)
        ? action.payload.forms
        : [action.payload.forms];

      // business form
      const newBusinessForm = formsToAdd.find((form) => form.policy === null);
      if (newBusinessForm) {
        if (
          overwrite ||
          Object.keys(state.businessForm).length === 0 ||
          !newBusinessForm.matchByBusiness(state.businessForm)
        ) {
          state.businessForm = newBusinessForm;
        } else {
          //console.log(
          //   'Found existing business form and overwrite is false - not replacing',
          // );
        }
      }

      // policy forms
      const newPolicyForms = formsToAdd.filter((form) => form.policy !== null);
      const unmatchedIncomingForms = newPolicyForms.filter(
        (incoming) =>
          !state.policyForms.some((extant) =>
            extant.matchByPolicyAndBusiness(incoming),
          ),
      );
      const matchedIncomingForms = newPolicyForms.filter((incoming) =>
        state.policyForms.some((extant) =>
          extant.matchByPolicyAndBusiness(incoming),
        ),
      );
      const unmatchedExtantForms = state.policyForms.filter(
        (extant) =>
          !newPolicyForms.some((incoming) =>
            extant.matchByPolicyAndBusiness(incoming),
          ),
      );
      const matchedExtantForms = state.policyForms.filter((extant) =>
        newPolicyForms.some((incoming) =>
          extant.matchByPolicyAndBusiness(incoming),
        ),
      );
      state.policyForms = [
        ...unmatchedIncomingForms,
        ...unmatchedExtantForms,
        ...(overwrite ? matchedIncomingForms : matchedExtantForms),
      ];
      // merge in any orphaned answer instances
      slice.caseReducers.mergeOrphanedAnswerInstances(
        state,
        slice.actions.mergeOrphanedAnswerInstances(),
      );
    },
    setBlockContactInfoFirst: (state, action: PayloadAction<boolean>) => {
      state.blockContactInfoFirst = action.payload;
    },
    /**
     * Set the orphaned answer instances in state
     * @param action - payload - should consist of an array of answer instances to set in state
     */
    setOrphanedAnswerInstances: (
      state,
      action: PayloadAction<Array<QuoteWizardAnswerInstance>>,
    ) => {
      state.orphanedAnswerInstances = action.payload;
    },
    /**
     * Clear the orphaned answer instances from state
     */
    clearOrphanedAnswerInstances: (
      state,
      _action: PayloadAction<undefined>,
    ) => {
      state.orphanedAnswerInstances = [];
    },
    /**
     * If the orphaned answer instances match any of the questions in the loaded forms, set them as answers to those forms and remove them from the list
     * @param state - Redux state
     * @param action - Empty action
     */
    mergeOrphanedAnswerInstances: (
      state,
      _action: PayloadAction<undefined>,
    ) => {
      // if there are orphaned answers, add them in
      if (state.orphanedAnswerInstances.length === 0) {
        return;
      }
      const mergedAnswers: Array<QuoteWizardAnswerInstance> = []; //the ones that we've dealt with; we can remove these later
      state.orphanedAnswerInstances.forEach((answer) => {
        const allForms = [state.businessForm, ...state.policyForms].filter(
          QuoteWizardForm.isQuoteWizardForm,
        );
        const matchingForm = allForms.find((form) =>
          form.questionInstances.some(
            (formQuestion) =>
              formQuestion.apiName === answer.questionInstance.apiName,
          ),
        );
        if (matchingForm) {
          slice.caseReducers.submitAnswersForForm(
            state,
            slice.actions.submitAnswersForForm({
              form: matchingForm,
              answers: [answer],
              replace: true,
              nth: 0,
            }),
          );
          mergedAnswers.push(answer);
        }
      });
      // now remove all the answers that we've merged
      state.orphanedAnswerInstances = state.orphanedAnswerInstances.filter(
        (orphaned) => !mergedAnswers.some((merged) => merged === orphaned),
      );
    },
    /**
     * Set the value of producer id associated with the quote
     */
    setProducerId: (
      state,
      action: PayloadAction<QuoteWizardState['producerId']>,
    ) => {
      state.producerId = action.payload;
    },
  },
});

/* Utility Functions */
const getReferralCode = (state: StoreState) => {
  return state.quoteWizard.referralCode;
};
const getRenewalInProgress = (state: StoreState) => {
  return state.quoteWizard.renewalInProgress;
};
const getProducerId = (state: StoreState) => {
  return state.quoteWizard.producerId;
};

/**
 * Format the questions received from a form for the GraphQL query
 * @param {[ Answer ]} answers - a list of the answers to be formatted
 * @returns {[]} - A list of the answers, formatted for sending to the backend
 */
// const formatAnswers = (
//   answers: Array<QuoteWizardAnswerInstance>,
// ): Array<AnswerInstanceInput> => {
//   if (answers === undefined || answers === null || answers.length === 0) {
//     return [];
//   } else {
//     return answers.map((answer) => answer.toAnswerInstanceInput());
// return answers.map((answer) => ({
//   data: `${answer.value}`,
//   dataType: answer.questionInstance.dataType,
//   details: answer.details,
//   questionInstanceId: answer.questionInstance.id,
//   subAnswers: formatAnswers(answer.subAnswers),
// }));
// }
// };

/**
 * Thunks
 */

export const selectBusinessTypeThunk =
  (selectedBusiness: NonNullable<QuoteWizardState['selectedBusinessType']>) =>
  (dispatch: $TSFixMe, _getState: $TSFixMe) => {
    dispatch(
      slice.actions.selectBusinessType({
        selectedBusinessType: selectedBusiness,
      }),
    );
    dispatch(createApplicationBegunThunk());
  };

/* Saga Generators */

/**
 * Redux Saga generator to process a list of forms and their answers, send the results as a quote to the backend, and then mark that quote has having been submitted
 * @param client - the Apollo client from which to send the mutation
 */
function* handleQuoteSubmission({ client }: SagaArgument) {
  while (true) {
    //console.log('fired handleQuoteSubmission');
    const {
      payload: {
        formsWithAnswers,
        businessType,
        clientEmail,
        clientName,
        message,
        status,
        currentQuoteUUID,
        selectedPolicies,
        signature,
        additionalInformation,
        storeExistingQuoteUuidOnComplete = false,
      },
    } = yield take(slice.actions.confirmAnswersForForms.type);
    //console.log('Just called handleQuoteSubmission - just got payload');

    // @ts-expect-error
    const referralCode = yield select(getReferralCode);
    // @ts-expect-error
    const renewalInProgress = yield select(getRenewalInProgress);
    // @ts-expect-error
    const producerId = yield select(getProducerId);

    const completedFormInputs = formsWithAnswers.map(
      (form: UnifiedCompletedForm) => {
        return form.toCompletedFormInput();
      },
    );
    const selectedLanguage: QuoteWizardState['selectedLanguage'] = yield select(
      (state) => state.quoteWizard.selectedLanguage,
    );
    const token = getAuthTokenFromLocalStorage();

    try {
      //console.log('About to call mutation');
      const { data, error } = yield call(client.mutate, {
        mutation: submitAnswerMutation,
        variables: {
          languageCode: selectedLanguage.shortName,
          businessLineId: !businessType
            ? null
            : 'id' in businessType
            ? businessType.id
            : businessType.original.id,
          completedFormInputs,
          clientEmail,
          clientName,
          message,
          status,
          currentQuoteUUID,
          selectedPolicies,
          signature,
          referralCode,
          additionalInformation,
          renewalInProgress,
          token,
          producerId: producerId || '',
        },
        update: (cache, { data }) => {
          let quotes;

          //console.log(data);

          try {
            quotes = cache.readQuery({
              query: RELAY_QUOTES,
              // @ts-expect-error
              variables: { token: localStorage.getItem('accessToken') },
            });
          } catch (e) {
            console.error('Failed to read quote list from cache', e);
          }

          try {
            cache.writeQuery({
              query: RELAY_QUOTES,
              // @ts-expect-error
              variables: { token: localStorage.getItem('accessToken') },
              data: {
                // @ts-expect-error
                relayQuotes: {
                  // @ts-expect-error
                  edges: [...quotes.relayQuotes.edges, data.createQuote.quote],
                },
              },
            });
          } catch (e) {
            //console.log('Failed to write to quote list in cache');
          }
        },
      });
      //console.log('Done calling mutation');

      if (error) {
        Bugsnag.notify(error);
        console.error(error);
      }

      if (error) {
        console.error(error);
        Bugsnag.notify(error);
      }
      const isOk = data && data.createQuote && data.createQuote.ok;

      if (isOk) {
        if (storeExistingQuoteUuidOnComplete) {
          try {
            window.localStorage.setItem(
              'existingQuoteUuid',
              data.createQuote.quote.uniqueID,
            );
          } catch (e) {
            console.error(e);
            Bugsnag.notify(errorify(e));
          }
        }

        yield put(
          slice.actions.markQuoteAsSubmitted({
            formsWithAnswers,
            businessType,
            quote: data.createQuote.quote,
          }),
        );
        yield put(
          slice.actions.setPremiumProposals(
            data.createQuote.quote.premiumProposals,
          ),
        );
        yield put(
          slice.actions.setCurrentQuoteUUID({
            uuid: data.createQuote.quote.uniqueID,
          }),
        );

        // sort out the analytics
        //console.log('About to dispatch application-related thunk');
        switch (status) {
          case 'COMPLETE':
            //console.log('About to dispatch createApplicationFinishedThunk');
            // @ts-expect-error
            yield put(createApplicationFinishedThunk());
            break;
          case 'INCOMPLETE':
            //console.log('About to dispatch createApplicationSavedThunk');
            // @ts-expect-error
            yield put(createApplicationSavedThunk());
            break;
          // case 'CONTACT_INFO_ONLY':
          //   // @ts-expect-error
          //   yield put(createApplicationBegunThunk());
          //   break;
        }
      } else {
        console.error(data);
        Bugsnag.notify(new Error(JSON.stringify(data)));
        if (['COMPLETE', 'RENEWED'].includes(status)) {
          yield put(
            slice.actions.updateErrors({
              completeQuoteSubmission: data || 'Unknown error',
            }),
          );
        } else {
          yield put(
            slice.actions.updateErrors({
              incompleteQuoteSubmission: data || 'Unknown error',
            }),
          );
        }
      }
    } catch (error) {
      console.error(error);
      Bugsnag.notify(errorify(error));
      if (['COMPLETE', 'RENEWED'].includes(status)) {
        yield put(
          // @ts-expect-error
          slice.actions.updateErrors({ completeQuoteSubmission: error }),
        );
      } else {
        yield put(
          // @ts-expect-error
          slice.actions.updateErrors({ incompleteQuoteSubmission: error }),
        );
      }
    }
  }
}

export function* QuoteWizardStateSaga({ client }: SagaArgument) {
  yield spawn(handleQuoteSubmission, { client });
}

export default slice;

/* Action Creators */
export const {
  selectInsurType,
  selectBusinessType,
  setPolicies,
  submitAnswersForForm,
  addProvince,
  setSaveButtonState,
  hideSaveButton,
  updateStepWizardState,
  updateErrors,
  setResumingQuote,
  setResumeToStep,
  setFurthestStep,
  setCurrentFormApi,
  setQuestionsPerPage,
  setReferralCode,
  setRenewalInProgress,
  setAdditionalInformation,
  resetSelections,
  setSkipPolicySelection,
  confirmAnswersForForms,
  setBlockContactInfoFirst,
  setOrphanedAnswerInstances,
  clearOrphanedAnswerInstances,
  setCurrentQuoteUUID,
  setProducerId,
} = slice.actions;
export const { actions } = slice;
