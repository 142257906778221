// @ts-nocheck
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { WithFormStyles, withFormStyles } from '../../../util/withFormStyles';
import { submitAnswersForForm } from '../../../store/QuoteWizardState';
import { Form } from '@calefy-inc/informedMaterial';
import { Policy } from '../../../Typescript';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store';
import { answerInstancesToFormState } from '../QuoteForms/utility';
import RenderedQuestionInstance from '../../common/QuoteComponents/RenderedQuestionInstance';
import { formStateToAnswerInstances } from '../QuoteForms/utility';

const getFormMatchingPolicy = (policy: Policy | null) => {
  return (store: StoreState) => {
    if (!policy) {
      return Object.values(store.quoteWizard.businessForm).length > 0
        ? store.quoteWizard.businessForm
        : null;
    }
    const matching = store.quoteWizard.policyForms.find((form) =>
      form.matchByPolicy(policy),
    );
    return matching || null;
  };
};
const getInitialValuesMatchingPolicy = (policy: Policy | null) => {
  return (store: StoreState) => {
    const matching = Object.values(store.quoteWizard.formAnswers).find(
      (answeredForm) => answeredForm.form.matchByPolicy(policy),
    );
    const initialValues = matching
      ? answerInstancesToFormState(matching.answers)
      : undefined;
    return initialValues;
  };
};

interface FormEditButtonProps {
  policy: Policy | null;
}
export const FormEditButton = ({ policy }: FormEditButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const label = `Edit ${
    policy ? policy.displayName : 'General Information'
  } Form`;
  return (
    <>
      <Tooltip
        title={label}
        enterDelay={100}
        leaveDelay={100}
        placement='right'
      >
        <IconButton
          aria-label={label}
          onClick={() => {
            setOpen(true);
          }}
        >
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <EditFormDialog
        open={open}
        onClose={() => setOpen(false)}
        policy={policy}
      />
    </>
  );
};

interface EditFormDialogProps extends WithFormStyles {
  open: boolean;
  onClose: () => void;
  policy: FormEditButtonProps['policy'];
}
const EditFormDialog = withFormStyles()(
  ({ open, onClose, classes, policy }: EditFormDialogProps) => {
    const form = useSelector(getFormMatchingPolicy(policy));
    const initialValues = useSelector(getInitialValuesMatchingPolicy(policy));
    // @ts-expect-error
    const dispatch = useDispatch();
    if (!form) {
      return null;
    }
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {policy ? policy.displayName : 'General Information'}
        </DialogTitle>
        <Form
          className={classes.dialogForm}
          initialValues={initialValues}
          // @ts-expect-error
          onSubmit={(values) => {
            dispatch(
              submitAnswersForForm({
                form,
                answers: formStateToAnswerInstances(
                  values,
                  form.questionInstances,
                ),
              }),
            );
            onClose();
          }}
        >
          <DialogContent sx={{ minWidth: '600px' }}>
            {form.questionInstances.map((questionInstance) => (
              <RenderedQuestionInstance
                questionInstance={questionInstance}
                classes={classes}
                key={questionInstance.id}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type='submit'>Submit</Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
