import { useEffect, useState } from 'react';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import { Icon } from '@iconify/react';
import fileAddFill from '@iconify/icons-eva/file-add-fill';
// material
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
// error handler
import Bugsnag from '@bugsnag/js';
// queries
import {
  GET_ALL_POLICIES,
  GET_MISSING_POLICIES,
  allBusinessTypes as allBusinessTypesQuery,
} from '../../../../../queries';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
// utils
import LoadingScreen from '../../../components/LoadingScreen';
// pages
import ErrorPage from '../../Page500';
// // components
// import { CopyExistingFormButton } from '../../../../../FormManager/components/CopyExistingFormButton';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { BusinessType, Policy } from '../../../../../Typescript';
import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';
import { RouteComponentProps } from '@reach/router';
import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------

const FAKE_GENERAL_INFORMATION_ID = '0';
const generalInformationOption = {
  value: FAKE_GENERAL_INFORMATION_ID,
  label: 'General Information',
};

interface FormModifierCreateProps extends RouteComponentProps {}
/**
 * This is the component where they select to business and policy. Basically sets the stage for the actual FormModifier creation
 */
export function FormModifierCreate(_props: FormModifierCreateProps) {
  // @ts-expect-error
  const dispatch = useDispatch();
  const { token } = useAuth();
  const hasFormEditPermissions = useAllowByPermission('edit:forms');
  const [businessOptions, setBusinessOptions] = useState<Array<$TSFixMe>>([]);
  const [policyOptions, setPolicyOptions] = useState<
    Array<{
      value: Policy['id'];
      label: Policy['displayName'];
      policy?: Policy;
    }>
  >([]);
  const [appliesToAllBusinesses, setAppliesToAllBusinesses] =
    useState<boolean>(true);
  const [business, setBusiness] = useState<BusinessType | null>(null);
  const [policyId, setPolicyId] = useState<Policy['id'] | null>(null);
  const [navigationDestination, setNavigationDestination] =
    useState<string>('');

  // queries
  const [getAllBusinessTypes, { loading, data, error }] = useLazyQuery(
    allBusinessTypesQuery,
  );

  const [
    getEmptyPolicies,
    { loading: policiesLoading, data: policiesData, error: policiesError },
  ] = useLazyQuery(GET_MISSING_POLICIES, { fetchPolicy: 'no-cache' });
  const [
    getAllPolicies,
    {
      loading: allPoliciesLoading,
      data: allPoliciesData,
      error: allPoliciesError,
    },
  ] = useLazyQuery(GET_ALL_POLICIES);

  // effects
  useEffect(() => {
    getAllBusinessTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (business) {
      getEmptyPolicies({
        variables: {
          businessId: business.id,
          token,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  useEffect(() => {
    if (data && data.allBusinessTypes) {
      setBusinessOptions(
        data.allBusinessTypes.map((businessType: $TSFixMe) => ({
          value: businessType.id,
          label: businessType.displayName,
          businessType,
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (policiesData) {
      const newGeneralInformation = !policiesData.doesGeneralInfoExist
        ? [generalInformationOption]
        : [];
      const newPolicyOptions =
        // @ts-expect-error
        policiesData.allEmptyBusinessSpecificPolicies.map(
          (policy: $TSFixMe) => ({
            value: policy.id,
            label: policy.displayName,
            policy,
          }),
        );

      setPolicyOptions([...newGeneralInformation, ...newPolicyOptions]);
    }
  }, [policiesData]);

  // if they want to modify the forms for all businesses, allow them to choose any policy
  useEffect(() => {
    //console.log('in useEffect:', appliesToAllBusinesses);
    if (appliesToAllBusinesses) {
      //console.log('About to call getAllPolicies');
      getAllPolicies();
    }
  }, [appliesToAllBusinesses]);

  // set the list of all policies as the allowed policies in state
  useEffect(() => {
    if (allPoliciesData?.allPolicies) {
      //console.log('About to set policy options in state');
      setPolicyOptions([
        generalInformationOption,
        // @ts-expect-error
        ...allPoliciesData.allPolicies.map((policy) => ({
          // @ts-expect-error
          value: policy.id,
          label: policy?.displayName,
          policy,
        })),
      ]);
    }
  }, [allPoliciesData]);

  // set the navigation destination once we know the policy and business types
  useEffect(() => {
    const base = `/insurtech/manager/formmodifiers/edit`;
    let destination: string;
    if ((appliesToAllBusinesses || business) && policyId) {
      // @ts-expect-error
      destination = `${base}/${appliesToAllBusinesses ? 'all' : business.id}${
        policyId
          ? `/${policyId !== FAKE_GENERAL_INFORMATION_ID ? policyId : 'none'}`
          : ``
      }`;
    } else {
      destination = base;
    }
    setNavigationDestination(destination);
  }, [policyId, business, appliesToAllBusinesses]);

  // handlers
  const handleChange = (event: $TSFixMe) => {
    setPolicyId(event.target.value);
  };

  // status
  if (loading) {
    return <LoadingScreen />;
  }
  if (error || policiesError || allPoliciesError) {
    const anyError = error || policiesError || allPoliciesError;
    //console.error('Error:', anyError);
    // @ts-expect-error
    Bugsnag.notify(anyError);
    return <ErrorPage />;
  }
  if (hasFormEditPermissions === false) {
    return (
      <InsufficientPermissionsErrorPage deniedIdentifier='Form Creation page' />
    );
  }

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Stack direction='row' spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                id='applies-to-all-businesses'
                checked={appliesToAllBusinesses}
                onChange={(e) => {
                  setAppliesToAllBusinesses(e.target.checked);
                  setBusiness(null);
                }}
              />
            }
            label='Apply changes to forms for all business types'
          />
          <Autocomplete
            fullWidth
            options={businessOptions}
            clearOnBlur={true}
            value={
              appliesToAllBusinesses
                ? ''
                : businessOptions.find(
                    (option) => option.value === business?.id,
                  )
            }
            placeholder='Start typing your line of business'
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select a specific business'
                variant='outlined'
              />
            )}
            sx={{
              '& input': {
                fontSize: '0.9rem',
              },
            }}
            ListboxProps={{
              // @ts-expect-error
              sx: {
                '& .MuiAutocomplete-option': {
                  fontSize: '0.9rem',
                  py: 2,
                },
              },
            }}
            onChange={(_event, value) => {
              setPolicyId(null);
              setBusiness(value ? value.businessType : null);
              setAppliesToAllBusinesses(false);
            }}
          />
        </Stack>

        {!policiesLoading && !allPoliciesLoading ? (
          (business || appliesToAllBusinesses) && (
            <FormControl>
              <FormLabel>Select a policy</FormLabel>
              <RadioGroup value={policyId} onChange={handleChange}>
                <Grid container spacing={0} direction='row'>
                  {policyOptions.map((policy) => (
                    <Grid item xs={12} sm={4} md={3}>
                      <FormControlLabel
                        value={policy.value}
                        control={<Radio />}
                        label={policy.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          )
        ) : (
          <>
            <Grid container spacing={1} direction='row'>
              {Array(16)
                .fill(0)
                .map(() => (
                  <Grid item xs={12} sm={4} md={3}>
                    <Skeleton width='90%' />
                  </Grid>
                ))}
            </Grid>
          </>
        )}

        <Stack
          direction={{ xs: 'column', sm: 'row-reverse' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <Button
            variant='contained'
            disabled={!(business || appliesToAllBusinesses) || !policyId}
            component={RouterLink}
            endIcon={<Icon icon={fileAddFill} />}
            to={navigationDestination}
          >
            New Form Modifier
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
