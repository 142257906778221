export const makeInformedFieldName = (
  attrs: Array<string | number>,
): string => {
  return attrs.reduce((acc: string, attr, index) => {
    if (index === 0) {
      return String(attr);
    }
    if (typeof attr === 'string') {
      return `${acc}.${attr}`;
    } else {
      return `${acc}[${attr}]`;
    }
  }, '');
};
