import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';

import {
  CommercialPerilScoreProduct,
  CommercialPerilScoreHighlights,
} from '../../../QuoteLoader/LocationDetails/classes';
import { DetailsCard } from './commonComponents';
import { NO_INFO_STRING } from '../../../QuoteLoader/LocationDetails/constants';
import { AddressDisplay } from './commonComponents';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface CommercialPerilScoreHighlightsProps {
  commercialPerilScoreProduct: $TSFixMe;
  classes: object;
}
const UnthemedDisplayCommercialPerilScoreHighlights = ({
  commercialPerilScoreProduct,
  classes = {},
}: CommercialPerilScoreHighlightsProps) => {
  const [highlights, setHighlights] = useState(
    new CommercialPerilScoreHighlights(commercialPerilScoreProduct),
  );

  useEffect(() => {
    setHighlights(
      new CommercialPerilScoreHighlights(commercialPerilScoreProduct),
    );
  }, [commercialPerilScoreProduct]);

  const toDisplay = highlights.generateHighlightsToDisplay();

  if (!toDisplay || toDisplay.length === 0) {
    return 'No highlights';
  }
  return (
    // @ts-expect-error
    <Grid container xs={12} className={classes.highlightsContainer} spacing={1}>
      {toDisplay}
    </Grid>
  );
};

const DisplayCommercialPerilScoreHighlights = withStyles((theme) => ({
  highlightsContainer: {
    margin: theme.spacing(0),
    '& li': {
      breakInside: 'avoid',
    },
    '& li:before': {
      content: '"\\233E"',
      display: 'inline-block',
      width: '1em',
      marginLeft: '-1em',
    },
    '& ul': {
      paddingLeft: '1em',
      listStyle: 'none',
    },
  },
}))((props: $TSFixMe) => (
  // @ts-expect-error
  <UnthemedDisplayCommercialPerilScoreHighlights {...props} />
));

interface DisplayOptaCommercialPerilDataProps {
  location: $TSFixMe;
  classes: object;
}
/**
 * Display the CommercialConstruction data for the given location
 */
const UnthemedDisplayOptaCommercialPerilData = ({
  location,
  classes = {},
}: DisplayOptaCommercialPerilDataProps) => {
  const [commercialPerilScoreProduct, setCommercialPerilScoreProduct] =
    useState<CommercialPerilScoreProduct | null>(
      // @ts-expect-error
      new CommercialPerilScoreProduct(),
    );

  useEffect(() => {
    if (
      location &&
      location.details &&
      location.details.opta &&
      location.details.opta.optaCommercialPerilScoreData
    ) {
      setCommercialPerilScoreProduct(
        CommercialPerilScoreProduct.createFromJson(
          location.details.opta.optaCommercialPerilScoreData,
        ),
      );
    } else {
      setCommercialPerilScoreProduct(null);
    }
  }, [
    location,
    location.details,
    location.details.opta,
    location.details.opta.optaCommercialPerilScoreData,
  ]);

  return (
    <div>
      <Typography
        variant='subtitle1'
        align='center'
        color='textSecondary'
        gutterBottom
      >
        Commercial Peril Score
      </Typography>
      {commercialPerilScoreProduct && commercialPerilScoreProduct.Address ? (
        <AddressDisplay>
          {commercialPerilScoreProduct.Address.toString()}
        </AddressDisplay>
      ) : null}
      {/*
      // @ts-expect-error */}
      <Box className={classes.highlights}>
        <Typography
          variant='subtitle1'
          style={{ textAlign: 'center' }}
          color='textSecondary'
          gutterBottom
        >
          Highlights
        </Typography>
        <DisplayCommercialPerilScoreHighlights
          commercialPerilScoreProduct={commercialPerilScoreProduct}
        />
      </Box>

      {/*
        // @ts-expect-error */}
      <Grid container xs={12} className={classes.rowContainer} spacing={1}>
        <Grid item xs={12}>
          <DetailsCard heading='All Perils'>
            {commercialPerilScoreProduct &&
            commercialPerilScoreProduct.AllPerilsScore
              ? commercialPerilScoreProduct.AllPerilsScore.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
        <Grid item xs={6}>
          <DetailsCard heading='Property'>
            {commercialPerilScoreProduct &&
            commercialPerilScoreProduct.PropertyPerilScores
              ? commercialPerilScoreProduct.PropertyPerilScores.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
        <Grid item xs={6}>
          <DetailsCard heading='Casualty'>
            {commercialPerilScoreProduct &&
            commercialPerilScoreProduct.CasualtyPerilScores
              ? commercialPerilScoreProduct.CasualtyPerilScores.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
      </Grid>
    </div>
  );
};

export const DisplayOptaCommercialPerilData = withStyles((theme) => ({
  ul: {
    margin: '0px',
    padding: '0px',
  },
  highlights: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2, 2, 2),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    border: '1px solid grey',
  },
  maps: {
    padding: theme.spacing(2, 0.5, 0, 0.5),
    width: '100%',
  },
  rowContainer: {
    margin: 0,
  },
  buildingDetailsContainer: {
    marginLeft: theme.spacing(0.125),
  },
  productNotFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  notFoundButton: {
    marginTop: theme.spacing(2),
  },
  loadingMarker: {
    marginTop: theme.spacing(2),
    transition: 'opacity 1s',
  },
  animated1: {
    animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
  },
  animated2: {
    animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
    animationDelay: '1s',
  },
  animated3: {
    animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
    animationDelay: '2s',
  },
  '@global': {
    '@keyframes fadeInOut': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  },
}))((props: $TSFixMe) => <UnthemedDisplayOptaCommercialPerilData {...props} />);
