import { RouteComponentProps } from '@reach/router';
import { useMutation } from '@apollo/client';
import { SEND_VAGO_BORDEREAU_EMAIL } from '../../queries';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '@calefy-inc/authentication';
import { Form, Calendar } from '@calefy-inc/informedMaterial';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import Stack from '@mui/material/Stack';
import { withFormStyles, WithFormStyles } from '../../util/withFormStyles';
import { styled } from '@mui/material';

interface VagoAdminActionsProps extends RouteComponentProps, WithFormStyles {}
export const VagoAdminActions = withFormStyles()(
  ({ classes }: VagoAdminActionsProps) => {
    const [sendVagoBordereauEmail, { loading, error, data }] = useMutation(
      SEND_VAGO_BORDEREAU_EMAIL,
    );
    const { token } = useAuth();
    return (
      <>
        <h1>VaGo Admin Actions</h1>
        <Form
          initialValues={getPreviousMonthDates()}
          onSubmit={(values: $TSFixMe) => {
            const { startDate, endDate } = values;
            sendVagoBordereauEmail({
              variables: {
                token,
                startDate: convertFormTimeToCorrect(startDate),
                endDate: convertFormTimeToCorrect(endDate),
              },
            });
          }}
        >
          <Stack spacing={3}>
            <Calendar
              field='startDate'
              label='Start Date'
              required={true}
              classes={classes.calendar}
            />
            <Calendar
              field='endDate'
              label='End Date'
              required={true}
              classes={classes.calendar}
            />
            <SubmitButtonContainer>
              <LoadingButton
                type='submit'
                loading={loading}
                variant='contained'
              >
                Send Bordereau Email
              </LoadingButton>
            </SubmitButtonContainer>
          </Stack>
        </Form>
        {data ? (
          <p>
            Sent bordeau document for {data.sendVagoBordereauEmail?.startDate} -{' '}
            {data.sendVagoBordereauEmail?.endDate} to{' '}
            {data.sendVagoBordereauEmail?.emails
              ? // @ts-expect-error
                new Intl.ListFormat('en', {
                  style: 'long',
                  type: 'conjunction',
                }).format(data.sendVagoBordereauEmail.emails)
              : 'unknown recipients'}
          </p>
        ) : null}
        {error ? <p>ERROR: {JSON.stringify(error, null, 4)}</p> : null}
      </>
    );
  },
);

const SubmitButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const convertFormTimeToCorrect = (rawDate: string | Date) => {
  if (typeof rawDate === 'string') {
    const [month, day, year] = rawDate.split('/');
    return [year, month, day].join('-');
  }
  const year = rawDate.getFullYear();
  const month = String(rawDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(rawDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const getPreviousMonthDates = () => {
  const now = new Date();

  const year = now.getFullYear();
  const prevMonth = now.getMonth() - 1;
  // Get the first day of the previous month
  const startDate = new Date(year, prevMonth, 1);

  // Get the last day of the previous month
  const endDate = new Date(year, prevMonth + 1, 0);

  return { startDate, endDate };
};
