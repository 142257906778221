// material
import { Container, Card } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { QuoteWizard } from '../../../QuoteWizard';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { RouteComponentProps } from '@reach/router';

interface QuoteCreateProps extends RouteComponentProps {}
const QuoteCreate = (_props: QuoteCreateProps) => {
  const { themeStretch } = useSettings();
  return (
    <Page title='Applications: Create a new application | Manager'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='New Application'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
            { name: 'Applications', href: PATH_DASHBOARD.quotes.root },
            { name: 'New Application' },
          ]}
        />
        <Card>
          <QuoteWizard />
        </Card>
      </Container>
    </Page>
  );
};

export default QuoteCreate;
