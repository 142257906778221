import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SignatureCanvas from 'react-signature-canvas';
import { ErrorBoundary } from '../../../common/ErrorHandling';
import { SignatureErrorFallback } from './ErrorFallback';
import {
  SIGNATURE_DISCLAIMER_TOPLINE,
  SIGNATURE_DISCLAIMER_BODY,
} from './constants';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const useStyles = makeStyles((_theme) => ({
  signatureDisclaimer: {
    alignSelf: 'flex-start',
  },
  signatureCanvas: {
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    height: '200px',
    borderRadius: '10px',
  },
  clearButton: {},
}));

interface SignatureProps {
  handleImageData: (imageData: $TSFixMe) => void;
  onError: (error: $TSFixMe) => void;
}

/**
 * SignaturePad element
 * @param handleImageData - function to get the image data from the canvas; whenever the signature data changes, this function is called
 * @param onError - returns the element to display when there is an error with the signature component
 */
export const BaseSignature = ({ handleImageData }: SignatureProps) => {
  const canvasRef = useRef<$TSFixMe>();
  const [empty, setEmpty] = useState<boolean>(true);
  const [imageData, setImageData] = useState<string | null>(null);

  const classes = useStyles();

  // whenever the image data changes, call the handler
  useEffect(() => {
    handleImageData(imageData);
  }, [imageData, handleImageData]);

  // when the canvas is cleared, clear the image data.
  useEffect(() => {
    if (empty) {
      setImageData(null);
    }
  }, [empty]);

  const getImageData = () => {
    if (canvasRef.current) {
      const data = canvasRef.current.toDataURL('image/jpeg');
      setImageData(data);
    }
  };
  /* {confirmationInProgress ? ( */
  /*   <Typography */
  /*     variant='subtitle1' */
  /*     sx={{ */
  /*       textAlign: 'center', */
  /*     }} */
  /*     key={1} */
  /*   > */
  /*     Please review the below information carefully, and submit when you are */
  /*     sure that it is correct. */
  /*   </Typography> */
  /* ) : null} */

  return (
    <>
      <Typography
        className={classes.signatureDisclaimer}
        gutterBottom
        paragraph
        color='textSecondary'
        variant='subtitle2'
      >
        {SIGNATURE_DISCLAIMER_TOPLINE}{' '}
      </Typography>
      <Typography
        className={classes.signatureDisclaimer}
        paragraph
        color='textSecondary'
        variant='subtitle2'
      >
        {SIGNATURE_DISCLAIMER_BODY}
      </Typography>
      <SignatureCanvas
        ref={(ref) => {
          canvasRef.current = ref;
        }}
        canvasProps={{
          className: classes.signatureCanvas,
          // @ts-expect-error
          'data-testid': 'signature-canvas',
        }}
        onEnd={() => {
          if (canvasRef.current) {
            setEmpty(canvasRef.current.isEmpty());
            getImageData();
          }
        }}
        backgroundColor='#f2f2f2'
        clearOnResize={false}
      />
      <Tooltip title='Clear Signature'>
        <IconButton
          className={classes.clearButton}
          onClick={() => {
            if (canvasRef.current) {
              canvasRef.current.clear();
              setEmpty(true);
            }
          }}
          disabled={!canvasRef.current}
          aria-label='Clear signature'
          component='span'
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const Signature = (outerProps: SignatureProps) => {
  return (
    // @ts-expect-error
    <ErrorBoundary
      FallbackComponent={(innerProps: $TSFixMe) => (
        <SignatureErrorFallback {...innerProps} onError={outerProps.onError} />
      )}
    >
      <BaseSignature {...outerProps} />
    </ErrorBoundary>
  );
};
