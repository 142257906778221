import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from '@reach/router';
// material
import { useTheme } from '@mui/material/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CardHeader from '@mui/material/CardHeader';
import TableContainer from '@mui/material/TableContainer';
// utils
import { findLegalOrTradeNameFromStructuredData } from '../../../utils/AnswerSearch';
//
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
// queries
import { DASHBOARD_NEW_QUOTES } from '../../../../../queries';
import { GetNewQuotesQuery } from '../../../../../gql/graphql';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useQuery } from '@apollo/client';
import { useAllowByPermission, useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import NewQuotesSkeleton from './skeletons/NewQuotesSkeleton';
import { handleDashboardError } from './handleError';
// Mock
import { NewQuotesMock } from './Mock';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';

// ----------------------------------------------------------------------

type newQuotesType = ArrayElement<
  NonNullable<GetNewQuotesQuery['newQuotesData']>['newQuotes']
>;

// ----------------------------------------------------------------------

export default function AppNewQuotes() {
  const theme = useTheme();
  const { token } = useAuth();
  const { slug } = useSettings();
  const hasViewPermissions = useAllowByPermission('view:dashboard:queries');
  const { data, error, loading } = useQuery(DASHBOARD_NEW_QUOTES, {
    variables: { token },
    skip: hasViewPermissions !== true || !token || slug === 'lastnameinsurance',
  });
  const [newQuotes, setNewQuotes] = useState<newQuotesType[]>([]);
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setNewQuotes(NewQuotesMock);
      setOkToDisplay(true);
    } else if (data && data.newQuotesData) {
      // @ts-expect-error
      setNewQuotes(data.newQuotesData.newQuotes);
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  if (hasViewPermissions === false) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if ((loading || !okToDisplay) && !error) return <NewQuotesSkeleton />;
  if (error) {
    Bugsnag.notify(`Could not load Total New Quotes component, ${error}`);
    return handleDashboardError(error);
  }
  return (
    <Card data-testid='app-new-quotes'>
      <CardHeader title='New Quotes' sx={{ mb: 3 }} />
      <Scrollbar sx={{}}>
        <TableContainer sx={{ minWidth: 720, minHeight: 400 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Line</TableCell>
                <TableCell>Unique ID</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newQuotes.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {findLegalOrTradeNameFromStructuredData(
                      row.structuredData,
                      row.businessLine.displayName,
                    )}
                  </TableCell>
                  <TableCell>{row.businessLine.displayName}</TableCell>
                  <TableCell>{row.friendlyId || row.uniqueID}</TableCell>
                  <TableCell>
                    <Label
                      variant={
                        theme.palette.mode === 'light' ? 'ghost' : 'filled'
                      }
                      color={
                        (row.status === 'INCOMPLETE' && 'error') || 'success'
                      }
                    >
                      {sentenceCase(row.status)}
                    </Label>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to='/insurtech/manager/quotes'
          size='small'
          color='inherit'
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box>
    </Card>
  );
}
