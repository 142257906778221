// @ts-expect-error
import { FormState, Relevant, useFormState } from 'informed';
import { Calendar, Checkbox, YesNoToggle } from '@calefy-inc/informedMaterial';
import Grid from '@mui/material/Grid';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps, ProgramBuilderComponentProps } from './types';
import { QuoteComponentValidationFunction } from './types';

// TODO: validate that minimum date is less than max date and max date is greater than minimum date
// better to use a date range picker
function ProgramBuilderComponent({
  classes = {},
}: ProgramBuilderComponentProps) {
  const formState = useFormState();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Calendar
            classes={classes}
            className={classes.calendar}
            label='Minimum Date'
            field='props_blob_date_min'
            id='props_blob_date_min'
            helperText='What is the minimum date of calendar?'
            disabled={formState.values.props_blob_date_min_today}
          />
        </Grid>
        <Grid item xs={5}>
          <Calendar
            classes={classes}
            className={classes.calendar}
            label='Maximum Date'
            field='props_blob_date_max'
            id='props_blob_date_max'
            helperText='What is the maximum date of calendar?'
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            classes={classes}
            label='Set minimum date to when the form is filled out'
            field='props_blob_date_min_today'
            helperText='The user will not be able to enter dates in the past'
          />
        </Grid>
        <Grid item xs={12}>
          <YesNoToggle
            field='default_boolean'
            label='Would you like to set a default value?'
          />
        </Grid>
        <Grid item xs={12}>
          <Relevant
            when={(formState: FormState) => {
              return formState.values['default_boolean'] === 'yes';
            }}
          >
            <Calendar
              classes={classes}
              className={classes.calendar}
              fullWidth
              field='defaultValue'
              label='Default Value'
              minDate={
                formState.values['props_blob_date_min']
                  ? // @ts-expect-error
                    new Date(formState.values['props_blob_date_min'])
                  : undefined
              }
              maxDate={
                formState.values['props_blob_date_max']
                  ? // @ts-expect-error
                    new Date(formState.values['props_blob_date_max'])
                  : undefined
              }
            />
          </Relevant>
        </Grid>
      </Grid>
    </>
  );
}

const validateCalendar: QuoteComponentValidationFunction = (
  questionInstance,
) => {
  const errors: Array<string> = [];
  if (!questionInstance.defaultValue) {
    return errors;
  }
  const { date_min_today } = questionInstance.propsBlob;
  const dateMin = date_min_today
    ? new Date()
    : questionInstance.propsBlob.date_min
    ? new Date(questionInstance.propsBlob.date_min)
    : undefined;
  const dateMax = questionInstance.propsBlob.date_max
    ? new Date(questionInstance.propsBlob.date_max)
    : undefined;
  const defaultValue = new Date(questionInstance.defaultValue);

  if (dateMin && defaultValue < dateMin) {
    errors.push(
      `Default value for Calendar question with API name ${
        questionInstance.apiName
      } (${defaultValue.toLocaleDateString()}) is before the required earliest date (${dateMin.toLocaleDateString()})`,
    );
  }
  if (dateMax && defaultValue > dateMax) {
    errors.push(
      `Default value for Calendar question with API name ${
        questionInstance.apiName
      } (${defaultValue.toLocaleDateString()}) is after the required latest date (${dateMax.toLocaleDateString()})`,
    );
  }
  return errors;
};

const QuoteComponent = ({
  questionInstance,
  classes = {},
  ...props
}: QuoteComponentProps) => {
  const { date_min_today } = questionInstance.propsBlob;
  const date_min = date_min_today
    ? new Date()
    : questionInstance.propsBlob.date_min
    ? new Date(questionInstance.propsBlob.date_min)
    : undefined;
  const date_max = questionInstance.propsBlob.date_max
    ? new Date(questionInstance.propsBlob.date_max)
    : undefined;

  return (
    <Calendar
      field={questionInstance.generateFieldName()}
      label={questionInstance.label}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes.calendar}
      classes={classes}
      minDate={date_min}
      maxDate={date_max}
      {...props}
    />
  );
};

const IconComponent = () => {
  return <CalendarMonthRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'calendar',
    typeLabel: 'Calendar',
    quoteComponent: QuoteComponent,
    dataType: 'string',
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateCalendar,
    icon: IconComponent,
  });
}

export { QuoteComponent, ProgramBuilderComponent };
