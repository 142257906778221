import { withStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import { useNavigate } from '@reach/router';

// types and classes
import type { Quote } from '../../../../Typescript/backend/classes';
import type { GenericObject } from '@calefy-inc/utilityTypes';

interface NoMatchFoundErrorProps {
  hash: Quote['uniqueId'];
  classes: GenericObject;
}
export const UnthemedNoMatchFoundError = ({
  hash,
  classes = {},
}: NoMatchFoundErrorProps) => {
  const navigate = useNavigate();
  const quoteLocation = '/insurtech/quote';
  return (
    <div className={classes.outerContainer} data-testid='no-match-found-error'>
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          No Application Found
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          There is no application associated with the identifier {hash}. This
          could be because the associated application has already been
          confirmed, because the link expired, or because there is a typo. If
          you believe that this is an error, please contact your agent or
          contact Calefy support at{' '}
          <a
            href={`mailto:support@calefy.ca?subject=Unable to Confirm Application ${hash} - No Correspondence Found`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>

        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => navigate(quoteLocation)}
        >
          Return to Applications
        </Button>
      </div>
    </div>
  );
};

export const NoMatchFoundError = withStyles((theme) => ({
  outerContainer: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))(UnthemedNoMatchFoundError);
