import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

interface PremiumOverrideConfirmationDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export const PremiumOverrideConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
}: PremiumOverrideConfirmationDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Premium Override Confirmation</DialogTitle>
      <DialogContent>
        Are you sure you want to override the premiums? Once they have been
        changed, you will need to re-rate the application in order to recover
        the original values.
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
