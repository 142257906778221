import { useState, useEffect, useRef } from 'react';
import { useParams } from '@reach/router';
import { Form } from 'informed';
import { connect, useDispatch, useSelector } from 'react-redux';
import { QuoteWizardState, StoreState } from '../../../store';
import { useLazyQuery } from '@apollo/client';
// material
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { Select, TileSelector } from '@calefy-inc/informedMaterial';
import {
  allBusinessTypes as allBusinessTypesQuery,
  GET_GENERAL_INFORMATION_FORM_FOR_BUSINESS,
} from '../../../queries';
import { BackButton, NextButton } from '../NavigationButtons';
import { hideSaveButton } from '../../../store/QuoteWizardState';
import LoadingScreen from '../../ManagementPanel/components/LoadingScreen';
import { handleErrors, DisconnectionError } from '../../common/ErrorHandling';
import { useWarnBeforeUnloadWithAnswers, useSettings } from '../../../hooks';
import { DataLossWarning } from './DataLossWarning';
import { StepWizardChildProps } from 'react-step-wizard';
import {
  businessOptionsMatcher,
  BUSINESS_NOT_FOUND_LABEL,
  generateHighlightedLabel,
  unmangleId,
} from './utility';
import bugsnag from '@bugsnag/js';
import { useLoadOrphanedAnswers } from '../../../hooks';

import { actions } from '../../../store/QuoteWizardState';
import { selectBusinessTypeThunk } from '../../../store/QuoteWizardState';
import {
  requestNewStatisticalSessionThunk,
  createBusinessSelectionInputThunk,
  createBusinessSelectionSelectedThunk,
} from '../../../store/analyticsStore';
import { useTileSelectorSettings } from '../../../hooks';
import { useWalnutGoogleAnalytics } from '../../../hooks';
import {
  GAEventNames,
  EventActions,
} from '../../../hooks/useWalnutGoogleAnalytics';
import { createPageLoadThunk } from '../../../store/analyticsStore';

//types and classes
/* import { QuoteWizardForm } from '../classes'; */
import type { $TSFixMe, ArrayElement } from '@calefy-inc/utilityTypes';
import { BusinessType } from '../../../Typescript';
import { ProgramBuilderForm } from '../../FormManager/classes';
import type { Dispatch } from '../../../store';
import { BusinessTypeAlias } from '../../../Typescript/classes';
import {
  vagoSettings,
  VAGO_BUSINESS_INTERNAL_NAME,
} from '../../../WhiteLabelSettings/VagoAssure';

const BUSINESS_SELECTION_DISPATCH_INTERVAL = 400; // time to wait after a key is pressed before sending off the record of the input

interface QuoteBusinessSelectionDisplayProps
  extends StepWizardChildProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  allBusinessTypes: Array<BusinessType | BusinessTypeAlias>;
  nextStep: $TSFixMe;
  nextFormStep: $TSFixMe;
  classes: $TSFixMe;
  loading: $TSFixMe;
  error: $TSFixMe;
}

/**
 * Component which allows the user to select the business type for the quote
 * @param allBusinessTypes - A list of all of the possible business types
 * @param selectedBusinessType - The selected business type
 * @param onBusinessSelection - the function to be called when a business is selected
 */
export function QuoteBusinessSelectionDisplay({
  allBusinessTypes,
  selectedBusinessType,
  selectedInsurType,
  onBusinessSelection,
  nextStep,
  classes = {},
  language,
  generalInformationFormLoaded,
  setAdditionalInformation,
  currentStep,
  totalSteps,
}: QuoteBusinessSelectionDisplayProps) {
  useWarnBeforeUnloadWithAnswers();
  useLoadOrphanedAnswers('orphanedAnswerInstances');

  const dispatch = useDispatch();
  const { personalInsurance, contactInformationFirst } = useSettings();
  const { settings, tileClasses } = useTileSelectorSettings();
  const params = useParams();
  const [warningInfo, setWarningInfo] = useState({
    open: false,
    message: '',
    isNavBack: false,
  });
  // has a selection been made, even if it is not in state
  const [hasBusinessBeenSelected, setHasBusinessBeenSelected] =
    useState<boolean>(Boolean(selectedBusinessType));

  const [pendingNavType, setPendingNavType] = useState<
    undefined | 'forward' | 'backward'
  >(); // which way are they attempting to navigate?
  const [waitingToNavigate, setWaitingToNavigate] = useState<boolean>(false); // has the user clicked next and now we're waiting for something to finish?
  const [waitingForWarning, setWaitingForWarning] = useState<boolean>(false); // are we waiting for the warning modal when you switch businesses?
  const [blocked, setBlocked] = useState<boolean>(contactInformationFirst); // is the component blocked from navigating? Currently only done for navigation forward.
  const [selectedBusinessTypeId, setSelectedBusinessTypeId] = useState<
    undefined | BusinessType['id']
  >();
  const [onVago, setOnVago] = useState<boolean>(false);
  const businessInputTimerIdRef = useRef<ReturnType<typeof setTimeout>>();
  const [getGeneralInformationForm, { error, data }] = useLazyQuery(
    GET_GENERAL_INFORMATION_FORM_FOR_BUSINESS,
  );
  const googleAnalytics = useWalnutGoogleAnalytics();

  // check if we're on vago; if so, try to navigate past this
  useEffect(() => {
    if (settings === vagoSettings) {
      setOnVago(true);
    }
  }, [settings]);

  // if we're on vago, set the business type
  useEffect(() => {
    if (onVago) {
      const matchingBusinessType = allBusinessTypes.find(
        (businessType) =>
          businessType.internalName === VAGO_BUSINESS_INTERNAL_NAME,
      );
      if (matchingBusinessType) {
        onBusinessSelection(matchingBusinessType);
        setPendingNavType('forward');
        setWaitingToNavigate(true);
        setBlocked(false);
      }
    }
  }, [onVago]);

  // create the statistical sessionId
  useEffect(() => {
    dispatch(requestNewStatisticalSessionThunk());
    dispatch(
      createPageLoadThunk({
        page: 'Business Selection',
        pageNumber: currentStep,
        totalPages: totalSteps,
      }),
    );
  }, []);

  // hide the save button
  useEffect(() => {
    dispatch(hideSaveButton());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // send a notification about loading into the page
  useEffect(() => {
    if (googleAnalytics) {
      googleAnalytics.triggerEvent(
        GAEventNames.Business,
        EventActions.PageLoad,
        '',
      );
    }
  }, [googleAnalytics]);

  // set the selected business id
  useEffect(() => {
    if (!selectedBusinessType) {
      setSelectedBusinessTypeId(undefined);
    } else if ('id' in selectedBusinessType) {
      setSelectedBusinessTypeId(selectedBusinessType.id);
    } else if ('getMangledId' in selectedBusinessType) {
      setSelectedBusinessTypeId(selectedBusinessType.getMangledId());
    }
  }, [selectedBusinessType]);

  const businessOptions = allBusinessTypes.map((business) => ({
    value: 'getMangledId' in business ? business.getMangledId() : business.id,
    label: business.displayName,
    tileIdentifier: business.internalName,
  }));
  const genericOption = businessOptions.find((option) => {
    return /^generic$/i.test(option.label);
  });
  if (genericOption) {
    businessOptions.push({
      label: BUSINESS_NOT_FOUND_LABEL,
      value: `generic_${genericOption.value}`,
      tileIdentifier: undefined,
    });
  }

  // once we get the form in, put it in the redux store (or just navigate if there's an error)
  useEffect(() => {
    if (error) {
      bugsnag.notify(
        new Error(
          `Error getting general information form. ${JSON.stringify(
            error,
            null,
            4,
          )}`,
        ),
      );
      setBlocked(false);
    } else if (data && data.getGeneralInformationFormForBusiness) {
      const generalInformationForm =
        ProgramBuilderForm.generateFromBackendResponse(
          data.getGeneralInformationFormForBusiness,
        ).toQuoteWizardForm(language);
      dispatch(
        actions.addForms({ forms: generalInformationForm, overwrite: false }),
      );
    }
  }, [contactInformationFirst, error, data, language]);

  // if we are waiting for the general information form to be loaded and then it finished, we are no longer blocked
  useEffect(() => {
    if (
      !contactInformationFirst ||
      (contactInformationFirst && generalInformationFormLoaded)
    ) {
      setBlocked(waitingForWarning);
    }
  }, [
    contactInformationFirst,
    generalInformationFormLoaded,
    waitingForWarning,
  ]);

  // navigate when you can
  useEffect(() => {
    if (waitingToNavigate && !blocked && !waitingForWarning) {
      if (pendingNavType === 'forward') {
        setWaitingToNavigate(false); // so if we navigate backwards we don't immediately go forward again
        nextStep();
      }
    }
  }, [waitingToNavigate, blocked, pendingNavType]);

  /**
   * Saves the selected business in the store state
   * @param values - The values for the fields. In this case, just the businessType
   */
  const handleBusinessSubmission = (values: $TSFixMe) => {
    let selectedBusinessType: BusinessType | BusinessTypeAlias | undefined;
    if (values.businessType) {
      if (/generic_/i.test(values.businessType)) {
        selectedBusinessType = allBusinessTypes.find((business) =>
          /^generic$/i.test(business.displayName),
        );
        setAdditionalInformation(
          "I couldn't find my business in the list provided. I would describe my business as...",
        );
      } else {
        selectedBusinessType = allBusinessTypes.find(
          (selectableBusinessType: ArrayElement<typeof allBusinessTypes>) => {
            const businessId =
              'id' in selectableBusinessType
                ? selectableBusinessType.id
                : selectableBusinessType.getMangledId();
            return values.businessType === businessId;
          },
        );
      }
    }
    if (selectedBusinessType) {
      onBusinessSelection(selectedBusinessType);
    }
  };

  /**
   * Closes business change alert dialog
   */
  const handleClose = () => {
    setWarningInfo((prevState) => ({
      ...prevState,
      open: false,
      isNavBack: false,
    }));
    setWaitingForWarning(false);
  };

  return (
    <Form
      className={classes.form}
      autoComplete='off'
      id='Business'
      noValidate
      onSubmit={(values: $TSFixMe) => {
        if (googleAnalytics) {
          const businessId = values.businessType;
          const matchingOption = businessOptions.find(
            (option) => option.value === businessId,
          );
          const description = matchingOption
            ? matchingOption.label
            : 'Unable to determine';
          googleAnalytics.triggerEvent(
            GAEventNames.Business,
            EventActions.Selection,
            description,
          );
        } else if (process.env.NODE_ENV === 'development') {
          //console.log('No Google Analytics!');
        }
        handleBusinessSubmission(values);
      }}
      data-testid='business-selection-wizard-step'
      initialValues={{
        businessType: !selectedBusinessType
          ? undefined
          : 'id' in selectedBusinessType
          ? selectedBusinessType.id
          : selectedBusinessType.getMangledId(),
      }}
    >
      {({ formState, formApi }) => {
        return (
          <>
            <FormControl>
              <FormLabel htmlFor='business-type'>
                <Typography variant='h5'>
                  What is your line of business?
                </Typography>
              </FormLabel>
              {businessOptions.length <= 20 ? (
                <TileSelector
                  name='business-type'
                  inputId='business-type'
                  aria-label='Business Type'
                  field='businessType'
                  onChange={() => {
                    setHasBusinessBeenSelected(true);
                    dispatch(
                      createBusinessSelectionInputThunk(
                        // @ts-expect-error
                        allBusinessTypes.find(
                          (business) =>
                            ('id' in business
                              ? business.id
                              : business.original.id) ===
                            formState.values['businessType'],
                        )?.displayName,
                      ),
                    );
                  }}
                  options={businessOptions}
                  settings={settings}
                  tileClasses={tileClasses}
                />
              ) : (
                <>
                  <Select
                    name='business-type'
                    inputId='business-type'
                    isDisabled={!businessOptions}
                    isClearable={false}
                    field='businessType'
                    aria-label='Business Type'
                    options={businessOptions}
                    placeholder='Start typing your line of business'
                    validate={(value: $TSFixMe) => {
                      if (!value) {
                        return 'Must select a business or industry to continue';
                      }
                    }}
                    onChange={() => {
                      setHasBusinessBeenSelected(true);
                    }}
                    onInputChange={(
                      _event: React.SyntheticEvent,
                      value: string,
                      _reason: string,
                    ) => {
                      // fires when the value in the input changes (i.e. they type something)
                      if (businessInputTimerIdRef.current) {
                        clearTimeout(businessInputTimerIdRef.current);
                      }
                      const timerId = setTimeout(
                        () =>
                          dispatch(createBusinessSelectionInputThunk(value)),
                        BUSINESS_SELECTION_DISPATCH_INTERVAL,
                      );
                      businessInputTimerIdRef.current = timerId;
                    }}
                    filterOptions={businessOptionsMatcher}
                    isOptionEqualToValue={(
                      option: $TSFixMe,
                      value: $TSFixMe,
                    ) => {
                      return option.value === value.value;
                    }}
                    getOptionLabel={(option: $TSFixMe) => option.label}
                    renderOption={(
                      props: $TSFixMe,
                      option: $TSFixMe,
                      _state: $TSFixMe,
                    ) => {
                      return (
                        <li {...props}>{generateHighlightedLabel(option)}</li>
                      );
                    }}
                  />
                </>
              )}
            </FormControl>

            <div className={classes.navButtons}>
              {personalInsurance && params?.directTo !== 'commercial' ? (
                <BackButton
                  onClick={() => {
                    if (selectedBusinessType) {
                      setWarningInfo({
                        open: true,
                        message:
                          'Returning to insurance type selection will delete any data entered. This action is irreversible.',
                        isNavBack: true,
                      });
                    } else {
                      dispatch(
                        actions.selectInsurType({
                          selectedInsurType: null,
                        }),
                      );
                    }
                  }}
                />
              ) : (
                <Box />
              )}
              <NextButton
                onClick={() => {
                  setWaitingToNavigate(true);
                  setPendingNavType('forward');
                  const selectedBusinessTypeId = !selectedBusinessType
                    ? undefined
                    : 'id' in selectedBusinessType
                    ? selectedBusinessType.id
                    : selectedBusinessType.getMangledId();
                  if (
                    !selectedBusinessType ||
                    formState.values.businessType === selectedBusinessTypeId
                  ) {
                    formApi.submitForm();
                    if (
                      contactInformationFirst &&
                      formState.values.businessType
                    ) {
                      let businessId = unmangleId(
                        formState.values.businessType,
                      );
                      getGeneralInformationForm({
                        variables: {
                          businessId,
                        },
                      });
                    }
                  } else {
                    setWaitingForWarning(true);
                    setWarningInfo({
                      open: true,
                      message:
                        'Selecting a new business type will delete any data entered in the previous business type. This action is irreversible.',
                      isNavBack: false,
                    });
                  }
                }}
                disabled={
                  !hasBusinessBeenSelected ||
                  (personalInsurance && !selectedInsurType)
                }
              />
            </div>

            <DataLossWarning
              warningInfo={warningInfo}
              onBack={() => {
                setPendingNavType(undefined);
                formApi.setValue('businessType', selectedBusinessTypeId);
                handleClose();
              }}
              onContinue={() => {
                handleClose();
                if (!warningInfo.isNavBack) {
                  formApi.submitForm();
                  const businessId = formState.values.businessType;
                  if (businessId) {
                    getGeneralInformationForm({
                      variables: {
                        businessId: unmangleId(businessId),
                      },
                    });
                  }
                } else {
                  dispatch(
                    actions.selectInsurType({
                      selectedInsurType: null,
                    }),
                  );
                }
              }}
            />
          </>
        );
      }}
    </Form>
  );
}

/* Theming Layer */
const BusinessSelectionDisplayWithStyles = withStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
    margin: 'auto',
    maxWidth: '60rem',
    '& label': {
      alignSelf: 'center',
      marginBottom: theme.spacing(1),
    },
    // Smaller Screens
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
    },
    [theme.breakpoints.only('md')]: {
      padding: `${theme.spacing(3)} ${theme.spacing(12)}`,
      maxWidth: '50rem',
    },
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    width: '100%',
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  // @ts-expect-error
}))((props) => <QuoteBusinessSelectionDisplay {...props} />);

/* GraphQL Layer */
export function QuoteBusinessSelectionQueryLayer({
  children,
  ...mappedState
}: $TSFixMe) {
  const [getAllBusinessTypes, { loading, error, data }] = useLazyQuery(
    allBusinessTypesQuery,
  );
  const { businessFilter, personalInsurance } = useSettings();
  const selectedInsurType = useSelector(
    (state: StoreState) => state.quoteWizard.selectedInsurType,
  );

  // load the business types on initial mount
  useEffect(() => {
    if (
      !personalInsurance ||
      (personalInsurance && selectedInsurType === 'commercial')
    ) {
      getAllBusinessTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || error) {
    return children({ loading, error, getAllBusinessTypes });
  }

  let allBusinessTypes: Array<$TSFixMe> = [];
  if (data && data.allBusinessTypes) {
    allBusinessTypes = data.allBusinessTypes.reduce(
      (
        acc: Array<BusinessType | BusinessTypeAlias>,
        individualBusinessInfo: $TSFixMe,
      ) => {
        const aliases = JSON.parse(individualBusinessInfo.aliases);
        return [
          ...acc,
          individualBusinessInfo,
          ...aliases.map(
            (alias: string) =>
              new BusinessTypeAlias(individualBusinessInfo, alias),
          ),
        ];
      },
      [],
    );
    allBusinessTypes = allBusinessTypes.filter(businessFilter);
  }

  return children({
    allBusinessTypes,
    getAllBusinessTypes,
    ...mappedState,
  });
}

/* Redux Layer */
function mapStateToProps(state: StoreState) {
  const selectedBusinessType = state.quoteWizard.selectedBusinessType;
  const selectedInsurType = state.quoteWizard.selectedInsurType;
  const language = state.quoteWizard.selectedLanguage;
  const generalInformationFormLoaded =
    Object.keys(state.quoteWizard.businessForm).length > 0;

  return {
    selectedBusinessType,
    selectedInsurType,
    language,
    generalInformationFormLoaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const {
    // selectBusinessType,
    setAdditionalInformation: setAdditionalInformationAction,
  } = QuoteWizardState.actions;

  function onBusinessSelection(
    selectedBusinessType: BusinessType | BusinessTypeAlias,
  ) {
    // @ts-expect-error
    dispatch(selectBusinessTypeThunk(selectedBusinessType));
    dispatch(
      // @ts-expect-error
      createBusinessSelectionSelectedThunk(selectedBusinessType.displayName),
    );
  }

  return {
    onBusinessSelection,
    setAdditionalInformation: (additionalInformation: string) =>
      dispatch(setAdditionalInformationAction(additionalInformation)),
  };
}

export const QuoteBusinessSelectionConnectLayer = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default QuoteBusinessSelectionConnectLayer((mappedState) => (
  <QuoteBusinessSelectionQueryLayer {...mappedState}>
    {(props: $TSFixMe) => {
      const { loading, error, getAllBusinessTypes, ...passThroughProps } =
        props;

      const handleDisconnectionError = (error: $TSFixMe) => {
        if (error?.networkError) {
          return (
            <DisconnectionError
              reset={() => {
                getAllBusinessTypes();
              }}
            />
          );
        }
      };

      if (loading) {
        return <LoadingScreen />;
      }

      if (error) {
        return handleErrors(error, handleDisconnectionError);
      }

      return <BusinessSelectionDisplayWithStyles {...passThroughProps} />;
    }}
  </QuoteBusinessSelectionQueryLayer>
));
