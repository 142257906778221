import { graphql } from '../gql';

// All Forms Query
export const GET_ALL_FORMS = graphql(`
  query allFinalForms($token: String!) {
    allFinalForms(token: $token) {
      id
      businessLine {
        id
        displayName
      }
      policy {
        id
        displayName
      }
      required
    }
  }
`);
