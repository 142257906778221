import { graphql } from '../gql';

export const CREATE_STATISTICAL_SESSION = graphql(`
  mutation createStatisticalSession {
    createStatisticalSession {
      session {
        sessionId
      }
    }
  }
`);

export const CREATE_STATISTICAL_EVENT = graphql(`
  mutation createStatisticalEvent(
    $sessionId: UUID
    $eventType: StatisticalEventTypes!
    $data: JSONString
    $userEmail: String
    $quoteUuid: String
  ) {
    createStatisticalEvent(
      sessionId: $sessionId
      eventType: $eventType
      data: $data
      userEmail: $userEmail
      quoteUuid: $quoteUuid
    ) {
      session {
        sessionId
      }
      event {
        eventId
      }
    }
  }
`);
