import { graphql } from '../../gql';
export const EDIT_BINDINGS = graphql(`
  mutation editBindings(
    $token: String!
    $bindingsToEdit: [EditBindingInput!]!
  ) {
    editBindings(token: $token, bindingsToEdit: $bindingsToEdit) {
      bindings {
        id
        label
        bound
        unboundReason
      }
    }
  }
`);
