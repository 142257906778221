import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setReferralCode } from '../../store/QuoteWizardState';
import { redirectTo, RouteComponentProps } from '@reach/router';

// Types
//-----------------------------------------------------------------
interface ReferralsLinkProps extends RouteComponentProps {
  businessId?: number;
  referralCode?: string;
}

//-----------------------------------------------------------------

export const ReferralsLink = (props: ReferralsLinkProps) => {
  // get the referralCode and businessId params props
  const { referralCode, businessId } = props;
  const dispatch = useDispatch();

  const [okToNavigate, setOkToNavigate] = useState<boolean>(false);

  // dispatch referral code to redux state
  useEffect(() => {
    if (referralCode) {
      dispatch(setReferralCode({ referralCode: referralCode }));
      setOkToNavigate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  // navigate to appropriate url if business id exists
  if (okToNavigate) {
    // navigate to business link if businessid is passed

    if (businessId) {
      redirectTo(`/insurtech/businessLink/${businessId}`);
    }
    // otherwise navigate to business selection page
    else {
      redirectTo(`/insurtech/quote`);
    }
  }
  return null;
};
