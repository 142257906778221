// classes and types
import {
  QuoteWizardForm,
  QuoteWizardQuote,
} from '../../components/QuoteWizard/classes';
import { UnifiedAnswerInstance } from '.';
// import { CompletedFormInput } from '../../Typescript/backend/inputTypes';
import { CompletedFormInput } from '../../gql/graphql';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface UnifiedCompletedFormInput {
  form: QuoteWizardForm;
  answers: Array<UnifiedAnswerInstance>;
  inProgress: boolean;
  errors?: Array<$TSFixMe>;
  complete?: boolean;
  completedQuote?: QuoteWizardQuote;
}

export class UnifiedCompletedForm {
  form: QuoteWizardForm;
  answers: Array<UnifiedAnswerInstance>;
  inProgress: boolean;
  errors?: Array<$TSFixMe>;
  complete?: boolean;
  completedQuote?: QuoteWizardQuote;

  [k: string]: $TSFixMe;

  constructor(input: UnifiedCompletedFormInput) {
    //console.log('Constructing a new UnifiedCompletedForm with', input);
    this.form = input.form.copy();
    this.answers = input.answers.map((answer) => answer.copy());
    this.inProgress = input.inProgress;
    this.errors = input.errors;
    this.complete = input.complete;
    this.completedQuote = input.completedQuote;
    //console.log('Done constructing UnifiedCompletedForm:', this);
  }

  matchQuoteWizardFormByPolicy(quoteWizardForm: QuoteWizardForm) {
    return this.form.matchByPolicy(quoteWizardForm);
  }

  /**
   * Convert this to a CompletedFormInput
   */
  toCompletedFormInput(): CompletedFormInput {
    return {
      policy: this.form.policy ? this.form.policy.id : null,
      answers: this.answers.map((answer) => answer.toAnswerInstanceInput()),
    };
  }
}
