import { graphql } from '../../gql';

export const CREATE_INSURANCE_RATE = graphql(`
  mutation createInsuranceRate(
    $displayName: String!
    $group: String!
    $internalName: String!
    $symbol: String!
    $token: String!
    $value: String!
  ) {
    createInsuranceRate(
      displayName: $displayName
      group: $group
      internalName: $internalName
      token: $token
      value: $value
      symbol: $symbol
    ) {
      rate {
        id
        internalName
        displayName
        value
        group
        symbol
      }
    }
  }
`);
