import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  UnifiedAnswerInstance,
  UnifiedCompletedForm,
} from '../../../Typescript/classes';
import { QuoteWizardForm } from '../classes';
import { QuoteWizardQuestionInstance } from '../classes';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Form } from '@calefy-inc/informedMaterial';
import RenderedQuestionInstance from '../../common/QuoteComponents/RenderedQuestionInstance';
import { WithFormStyles, withFormStyles } from '../../../util/withFormStyles';
import { submitAnswersForForm } from '../../../store/QuoteWizardState';
import {
  answerInstancesToFormState,
  formStateToAnswerInstances,
} from '../QuoteForms/utility';
import { Language } from '../../../Typescript/classes';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

interface QuestionEditButtonProps {
  form?: UnifiedCompletedForm;
  answer?: UnifiedAnswerInstance;
  topLevelAnswer?: UnifiedAnswerInstance;
}
export const QuestionEditButton = ({
  form,
  topLevelAnswer,
}: QuestionEditButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [matchingForm, setMatchingForm] = useState<QuoteWizardForm | undefined>(
    undefined,
  );
  const [matchingQuestion, setMatchingQuestion] =
    useState<QuoteWizardQuestionInstance | null>(null);

  // find the matching form whenever we can
  useEffect(() => {
    if (form) {
      setMatchingForm(form.form);
    }
  }, [form]);

  // find the matching question whenever we can
  useEffect(() => {
    if (matchingForm && topLevelAnswer) {
      const matching = matchingForm.questionInstances.find((questionInstance) =>
        topLevelAnswer.matchesQuestion(questionInstance),
      );
      if (matching) {
        setMatchingQuestion(matching);
      } else {
      }
    }
  }, [matchingForm, topLevelAnswer]);

  if (!matchingForm || !matchingQuestion || !topLevelAnswer) {
    return null;
  }
  return (
    <>
      <IconButton
        aria-label={`${matchingQuestion.label}`}
        className='answerEditButton'
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditIcon fontSize='small' />
      </IconButton>
      <EditAnswerDialog
        open={open}
        onClose={() => setOpen(false)}
        form={matchingForm}
        topLevelAnswer={topLevelAnswer}
        matchingQuestion={matchingQuestion}
        initialValues={
          !topLevelAnswer || topLevelAnswer?.value === 'N/A'
            ? undefined
            : answerInstancesToFormState([
                topLevelAnswer.toQuoteWizardAnswerInstance(
                  Language.english,
                  true,
                ),
              ])
        }
      />
    </>
  );
};

interface EditAnswerDialogProps extends WithFormStyles {
  open: boolean;
  onClose: () => void;
  form: QuoteWizardForm;
  matchingQuestion: QuoteWizardQuestionInstance;
  topLevelAnswer: UnifiedAnswerInstance;
  initialValues: $TSFixMe; // appropriate for the informed form
}
const EditAnswerDialog = withFormStyles()(
  ({
    open,
    onClose,
    form,
    classes,
    matchingQuestion,
    topLevelAnswer,
    initialValues,
  }: EditAnswerDialogProps) => {
    const dispatch = useDispatch();
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{form.getName()}</DialogTitle>
        <Form
          initialValues={initialValues}
          className={classes.dialogForm}
          // @ts-expect-error
          onSubmit={(values) => {
            dispatch(
              submitAnswersForForm({
                form,
                answers: formStateToAnswerInstances(values, [matchingQuestion]),
                replace: true,
                nth:
                  topLevelAnswer.subAnswers.length > 0
                    ? Number(topLevelAnswer.value)
                    : 1,
              }),
            );
            onClose();
          }}
        >
          <DialogContent sx={{ minWidth: '600px' }}>
            <RenderedQuestionInstance
              questionInstance={matchingQuestion}
              classes={classes}
              blockAddSubquestions={true}
              key={matchingQuestion.apiName}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type='submit'>Submit</Button>
          </DialogActions>
        </Form>
      </Dialog>
    );
  },
);
