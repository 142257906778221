import { useEffect, useState, ComponentProps } from 'react';
import { Select } from '@calefy-inc/informedMaterial';
import { useLazyQuery } from '@apollo/client';
import { ALL_ORGANIZATIONS } from '../../../queries';
import { useAllowByPermission } from '../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
import { styled } from '@mui/material';

export const OrganizationSelect = (
  props: Exclude<ComponentProps<typeof Select>, 'options'> & {
    additionalOptions?: Array<{ value: string; label: string }>;
  },
) => {
  const [options, setOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const { token } = useAuth();
  const isAdmin = useAllowByPermission('is:admin');
  const [getOrganizations, { error, data }] = useLazyQuery(ALL_ORGANIZATIONS);

  // get the organization
  useEffect(() => {
    if (token) {
      getOrganizations({
        variables: {
          token,
        },
      });
    }
  }, [token]);

  // once we have the data, set the options based on them
  useEffect(() => {
    if (!data?.allOrganizations) {
      return;
    }
    setOptions(
      data.allOrganizations.reduce(
        (acc, organization) => {
          if (!organization || !organization.id || !organization.name) {
            return acc;
          }
          return [
            ...acc,
            {
              ...organization,
              label: organization.name,
              value: organization.name,
            },
          ];
        },
        props.additionalOptions ? props.additionalOptions : [],
      ),
    );
  }, [data]);

  if (!token || !isAdmin) {
    return null;
  }
  if (error) {
    return (
      <ErrorDisplay>
        Error getting organizations: <pre>{JSON.stringify(error, null, 4)}</pre>
      </ErrorDisplay>
    );
  }
  // @ts-expect-error
  return <Select options={options} {...props} />;
};

const ErrorDisplay = styled('p')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
