import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
// queries
import { DASHBOARD_SUBMISSION_TIMES } from '../../../../../queries';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// Error
import Bugsnag from '@bugsnag/js';
import SubmissionTimesSkeleton from './skeletons/SubmissionTimesSkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { SubmissionTimesMock } from './Mock';

// ----------------------------------------------------------------------

export default function SubmissionTimes() {
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const { data, loading, error } = useQuery(DASHBOARD_SUBMISSION_TIMES, {
    variables: { token },
    skip:
      hasDashboardPermissions !== true ||
      !token ||
      slug === 'lastnameinsurance',
  });
  const [submissionTimes, setSubmissionTimes] = useState<number[]>([]);
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setSubmissionTimes(SubmissionTimesMock);
      setOkToDisplay(true);
    } else if (data && data.submissionTimesData) {
      // @ts-expect-error
      setSubmissionTimes(data.submissionTimesData.submissionTimes);
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  const CHART_DATA = [
    {
      data: submissionTimes,
    },
  ];

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (_seriesName: string) => `Applications`,
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: [
        '',
        '',
        '',
        '3am',
        '',
        '',
        '6am',
        '',
        '',
        '9am',
        '',
        '',
        '12pm',
        '',
        '',
        '3pm',
        '',
        '',
        '6pm',
        '',
        '',
        '9pm',
        '',
        '',
      ],
    },
  });

  if (hasDashboardPermissions === false) {
    return <InsufficientPermissionsErrorComponent />;
  }
  if (
    (loading || !okToDisplay || hasDashboardPermissions === undefined) &&
    !error
  ) {
    return <SubmissionTimesSkeleton />;
  }
  if (error) {
    Bugsnag.notify(`Could not load Submission Times component, ${error}`);
    return handleDashboardError(error);
  }

  return (
    <Card data-testid='app-submission-times'>
      <CardHeader title='Submission Times' subheader='Mountain Time (MT)' />
      <Box sx={{ mx: 3 }} dir='ltr'>
        <ReactApexChart
          type='bar'
          series={CHART_DATA}
          //@ts-expect-error
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
