import { alpha } from '@mui/material';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';

export const LoadingIconButton = styled(LoadingButton)(({ theme }) => ({
  padding: '5px',
  borderRadius: '50%',
  minWidth: '30px',
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.secondary, 0.08),
  },
}));
