import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import saveFill from '@iconify/icons-eva/save-fill';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../../../../../store';
import { validateFormModifier } from './validateFormModifier';
import { ErrorDialog } from '../../utility/ErrorDialog';
import { CREATE_FINAL_FORM_MODIFIER } from '../../../../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  ALL_SIGNIFIER,
  FinalFormModifier,
} from '../../../classes/FinalFormModifier';
import { setCurrentlyEditingFinalFormModifier } from '../../../../../../../../store/FormModifierStore';

const getCurrentlyEditingFormModifer = (state: StoreState) =>
  state.formModifierStore.currentlyEditingFinalFormModifier;

interface UpdateButtonProps {}
const UpdateButton = ({}: UpdateButtonProps) => {
  const dispatch = useDispatch();
  const form = useSelector(getCurrentlyEditingFormModifer);
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const [createFinalFormModifier, { loading, error, data }] = useMutation(
    CREATE_FINAL_FORM_MODIFIER,
  );

  const handleErrorClose = (_event: $TSFixMe, reason: $TSFixMe) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessages([]);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }, [error]);

  // update everything once the data is back
  useEffect(() => {
    if (data?.createFinalFormModifier?.finalFormModifier) {
      enqueueSnackbar(`Form Modifier ${form?.id ? 'Created' : 'Updated'}`, {
        variant: 'success',
      });
      dispatch(
        setCurrentlyEditingFinalFormModifier(
          FinalFormModifier.generateFromBackendResponse(
            // @ts-expect-error
            data.createFinalFormModifier?.finalFormModifier,
          ),
        ),
      );
    }
  }, [data]);

  if (!form) {
    return null;
  }
  return (
    <>
      <LoadingButton
        loading={loading}
        loadingPosition='end'
        size='small'
        endIcon={<Icon icon={saveFill} />}
        variant='contained'
        disabled={
          form.questionInstances.length === 0 &&
          form.apiNamesForRemoval.length === 0
        }
        onClick={() => {
          const formErrors = validateFormModifier(form);
          setErrorMessages(formErrors);
          if (formErrors.length === 0) {
            createFinalFormModifier({
              variables: {
                existingFormId: form.id,
                businessId:
                  form.business === ALL_SIGNIFIER ? null : form.business.id,
                policyId: form.policy ? form.policy.id : null,
                questionInstances: form.questionInstances.map((q) =>
                  q.toQuestionInstanceInput(),
                ),
                apiNamesForRemoval: form.apiNamesForRemoval,
                token,
              },
            });
          }
        }}
      >
        {form.isNew() ? 'Create Form Modifier' : 'Update Form Modifier'}
      </LoadingButton>
      <ErrorDialog
        messages={errorMessages}
        open={errorMessages.length > 0}
        onClose={handleErrorClose}
      />
    </>
  );
};

export default UpdateButton;
