import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderQuestionInstance } from '../../../classes';

interface EditAllSwitchProps {
  editAll: boolean;
  setEditAll: (editAll: boolean) => void;
  questionInstance: ProgramBuilderQuestionInstance;
  disabled: boolean;
  form: $TSFixMe;
}
export const EditAllSwitch = ({
  editAll,
  setEditAll,
  questionInstance,
  disabled,
  form,
}: EditAllSwitchProps) => {
  const otherForms =
    questionInstance?.ancillary?.parentForms && form?.id
      ? questionInstance?.ancillary?.parentForms.filter(
          (parentForm) => parentForm.id !== form.id,
        )
      : [];
  if (
    !questionInstance?.ancillary?.parentForms ||
    !Array.isArray(questionInstance.ancillary.parentForms) ||
    otherForms.length === 0
  ) {
    return null;
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid='EditAllSwitch'
          disabled={disabled}
          checked={editAll}
          onChange={(e) => setEditAll(e.target.checked)}
        />
      }
      label={`Edit all instances of this question across ${
        otherForms.length
      } other form${otherForms.length > 1 ? 's' : ''}`}
    />
  );
};
