import { useFormApi } from 'informed';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { TextField } from '@calefy-inc/informedMaterial';
import { mergeExistingKeywordsWithNew } from './utility';
// types and classes
import type { Dispatch, SetStateAction } from 'react';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface BusinessTypeAliasesProps {
  aliases: Array<string>;
  setAliases: Dispatch<SetStateAction<Array<string>>>;
}
export const BusinessTypeAliases = ({
  aliases,
  setAliases,
}: BusinessTypeAliasesProps) => {
  const formApi = useFormApi();
  return (
    <>
      <TextField
        field='aliases'
        label='Enter a comma-separated list of aliases for the business. These will appear as separate businesses in the selection page.'
        fullWidth
        variant='outlined'
        onBlur={(e: $TSFixMe) => {
          formApi.setValue('aliases', '');
          setAliases((oldAliases) =>
            mergeExistingKeywordsWithNew(oldAliases, e.target.value),
          );
        }}
      />
      {aliases.length > 0 ? (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 3, sm: 2 }}
        >
          {aliases.map((alias) => (
            <Chip
              key={alias}
              label={alias}
              variant='outlined'
              onDelete={() =>
                setAliases((oldAliases) =>
                  oldAliases.filter((oldAlias) => oldAlias !== alias),
                )
              }
            />
          ))}
        </Stack>
      ) : null}
    </>
  );
};
