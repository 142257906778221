import { useEffect } from 'react';
import DragDropFormBuilder from './DragDrop/DragDropFormBuilder';
import { connect } from 'react-redux';
import { formStore } from '../../../store';

import { ProgramBuilderWizard } from '../../../store';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// components
import UpdateButton from './components/UpdateButton';
import { FormPreviewButton } from '../components/FormPreview';
import { CreateQuestionInstance } from '../components/CreateQuestionInstance';
import FormSettings from '../components/FormSettings';
import { ErrorBoundary } from '../../common/ErrorHandling';
import { ErrorView } from '../../common/ErrorView';

import { useLazyQuery } from '@apollo/client';
import { ALL_LANGUAGES } from '../../../queries';
import { DeleteFormButton } from '../components';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language } from '../../../Typescript/classes';
import { ProgramBuilderForm, ProgramBuilderQuestionInstance } from '../classes';
import { Dispatch } from '../../../store';

/* Helper Function */

type FormDisplayProps = {
  form: ProgramBuilderForm;
  setLanguages: (languages: Array<Language>) => void;
  [key: string]: $TSFixMe;
};
export function FormDisplay({ form, setLanguages }: FormDisplayProps) {
  // NB we will set a default language in the ProgramBuilderWizard
  const [loadLanguages, { data }] = useLazyQuery(ALL_LANGUAGES);

  // get the list of all available languages in the ProgramBuilder
  useEffect(() => {
    loadLanguages();
  }, [loadLanguages]);

  // once the languages are loaded, set them in the state
  useEffect(() => {
    if (data && data.allLanguages) {
      setLanguages(
        data.allLanguages.map((backendLanguage: $TSFixMe) =>
          Language.createFromObject(backendLanguage),
        ),
      );
    }
  }, [data, setLanguages]);

  if (!form) {
    return null;
  }

  const { policy } = form;

  return (
    <>
      <Stack
        data-testid='form-display'
        mb={3}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Typography
          data-testid={`sub-form-prompt-${form.id}`}
          color='textSecondary'
          variant='h6'
          sx={{ mb: 2 }}
        >
          {policy
            ? `${form.businessLine.displayName} - ${policy?.displayName}`
            : `${form.businessLine.displayName} - General Information`}
        </Typography>
        <FormSettings form={form} />
      </Stack>

      <Stack
        mb={3}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='flex-end'
        spacing={2}
      >
        <CreateQuestionInstance form={form} />

        <FormPreviewButton
          questionInstances={
            form && form.getAvailableLanguages().length > 0
              ? form.questionInstances.map((q) =>
                  q.toQuoteWizardQuestionInstance(
                    form.getAvailableLanguages()[0],
                  ),
                )
              : []
          }
          formTitle={
            form.policy ? form.policy.displayName : 'General Information'
          }
          originalForm={form}
        />

        <UpdateButton form={form} />
        <DeleteFormButton form={form} />
      </Stack>

      {/* @ts-expect-error */}
      <ErrorBoundary FallbackComponent={ErrorView}>
        {/* @ts-expect-error */}
        <DragDropFormBuilder form={form} />
      </ErrorBoundary>
    </>
  );
}

/* Redux connect */

function mapStateToProps(_state: $TSFixMe, _ownProps: $TSFixMe) {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  const { removeQuestionInstance, toggleFormRequired } = formStore.actions;

  const { requestPublishForm, requestUpdateForm } =
    ProgramBuilderWizard.actions;

  /* const { addQualifyingQuestion, updateQualifyingQuestion } = formStore.actions; */

  function onQuestionInstanceRemoved({ questionInstance }: $TSFixMe) {
    // @ts-expect-error
    return dispatch(removeQuestionInstance({ questionInstance }));
  }

  function onFormPublishRequested({ ...args }: $TSFixMe) {
    dispatch(requestPublishForm({ ...args }));
  }

  function onFormSaveRequested({ ...args }: $TSFixMe) {
    dispatch(requestUpdateForm({ ...args }));
  }

  /* function onQualifyingQuestionAdd(...args: $TSFixMe) { */
  /*   // @ts-expect-error */
  /*   dispatch(addQualifyingQuestion(...args)); */
  /* } */

  /* function onQualifyingQuestionUpdate(...args: $TSFixMe) { */
  /*   // @ts-expect-error */
  /*   dispatch(updateQualifyingQuestion(...args)); */
  /* } */

  function setQualifyingQuestion(
    qualifyingQuestion: ProgramBuilderQuestionInstance,
  ) {
    dispatch(formStore.actions.setQualifyingQuestion({ qualifyingQuestion }));
  }

  function onSetFormRequired(...args: $TSFixMe) {
    // @ts-expect-error
    dispatch(toggleFormRequired(...args));
  }

  function setLanguages(languages: Array<Language>) {
    dispatch(ProgramBuilderWizard.actions.setLanguages(languages));
  }

  return {
    onQuestionInstanceRemoved,
    onFormPublishRequested,
    onFormSaveRequested,
    setQualifyingQuestion,
    onSetFormRequired,
    setLanguages,
  };
}

function mergeProps(
  stateProps: $TSFixMe,
  dispatchProps: $TSFixMe,
  ownProps: $TSFixMe,
) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onFormPublishRequested: (args: $TSFixMe) =>
      dispatchProps.onFormPublishRequested({
        ...args,
        form: stateProps.selectedPolicyForm,
        questionInstances: stateProps.questionInstances,
      }),
    onFormSaveRequested: (args: $TSFixMe) =>
      dispatchProps.onFormSaveRequested({
        ...args,
        form: stateProps.form,
        questionInstances: stateProps.questionInstances,
      }),
  };
}

export const PolicyFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
);

export default PolicyFormConnect((mappedState) => (
  <FormDisplay {...mappedState} />
));
