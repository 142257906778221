import { Box, Card, Typography, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TotalTodaySkeleton() {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2'>
          <Skeleton />
        </Typography>

        <Skeleton width='50%' />

        <Typography variant='h3'>
          <Skeleton width='30%' />
        </Typography>
      </Box>

      <Skeleton width='70px' height='40px' />
    </Card>
  );
}
