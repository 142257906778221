import { graphql } from '../gql';

export const AVAILABLE_FORM_POLICIES = graphql(`
  query availablePolicies($businessId: ID!, $token: String!) {
    availableFormPolicies(businessId: $businessId, token: $token) {
      id
      internalName
      displayName
    }
  }
`);
