//classes and types
import type { QuoteStatus } from '../../../../Typescript/backend/types';

export const generateColourFromQuoteStatus = (status: QuoteStatus | null) => {
  if (
    status === 'INCOMPLETE' ||
    status === 'INCOMPLETE_RENEWAL' ||
    status === 'INCOMPLETE_CONFIRMATION'
  ) {
    return 'error';
  }
  if (status === 'COMPLETE' || status === 'RENEWED' || status === 'CONFIRMED') {
    return 'success';
  }
  if (status === 'RENEWAL_EMAIL_SENT' || status === 'RENEWAL_LINK_GENERATED') {
    return 'info';
  }
  if (status === 'RENEWAL_IN_PROGRESS') {
    return 'warning';
  }
  return 'default';
};
