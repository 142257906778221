import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ALL_AUTHORIZATION_TOKENS,
  ALL_ORGANIZATIONS,
  CREATE_AUTHORIZATION_TOKEN,
  SET_AUTHORIZATION_TOKEN_VALIDITY,
} from '../../queries';
import { useAuth } from '@calefy-inc/authentication';
import LoadingScreen from '../ManagementPanel/components/LoadingScreen';
import { ErrorView } from '../common/ErrorView';
import { styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Form, Select, TextField } from '@calefy-inc/informedMaterial';
import { withFormStyles, WithFormStyles } from '../../util/withFormStyles';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from '@calefy-inc/utility';
import { AllAuthorizationTokensQuery } from '../../gql/graphql';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import LoadingButton from '@mui/lab/LoadingButton';

export const AuthorizationTokens = (
  _authorizationTokenProps: RouteComponentProps,
) => {
  const { token } = useAuth();
  const [getAllTokens, { loading, error, data, refetch: refetchAllTokens }] =
    useLazyQuery(ALL_AUTHORIZATION_TOKENS);
  useEffect(() => {
    if (token) {
      getAllTokens({
        variables: {
          token,
        },
      });
    }
  }, [token]);

  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorView />;
  }

  return (
    <>
      <h1>Authorization Tokens</h1>
      <CreateNewTokenButtonContainer>
        <CreateNewTokenButton
          onSuccess={() => {
            if (token && refetchAllTokens) {
              refetchAllTokens();
            }
          }}
        />
      </CreateNewTokenButtonContainer>
      {!data ? null : (
        <>
          <TokenTable>
            <thead>
              <tr>
                <th>Description</th>
                <th>Token</th>
                <th>Organization</th>
                <th>Created</th>
                <th>Valid</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.allAuthorizationTokens?.map((authToken) => {
                if (!authToken) {
                  return null;
                }
                return (
                  <tr key={authToken.id}>
                    <td>{authToken.description}</td>
                    <td>
                      {authToken.token} <CopyButton text={authToken.token} />
                    </td>
                    <td>{authToken.organization.name}</td>
                    <td>
                      {authToken.createdAt
                        ? Intl.DateTimeFormat('en-CA', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }).format(new Date(authToken.createdAt))
                        : ''}
                    </td>
                    <td>{authToken.valid ? 'Yes' : 'No'}</td>
                    <td>
                      <TokenActions
                        authorizationToken={authToken}
                        onSuccess={() => refetchAllTokens && refetchAllTokens()}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TokenTable>
        </>
      )}
    </>
  );
};

interface CreateNewTokenButtonProps {
  onSuccess: () => void;
}
const CreateNewTokenButton = ({ onSuccess }: CreateNewTokenButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => {
          setOpen((open) => !open);
        }}
      >
        Create New Token
      </Button>
      <CreateNewTokenDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSuccess={onSuccess}
      />
    </>
  );
};

interface CreateNewTokenDialogProps extends WithFormStyles {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const CreateNewTokenDialog = withFormStyles()(
  ({ open, onClose, onSuccess, classes }: CreateNewTokenDialogProps) => {
    const { token } = useAuth();
    const [getOrganizations, { data }] = useLazyQuery(ALL_ORGANIZATIONS);
    const [
      createAuthorizationToken,
      { loading: createLoading, error: createError, data: createData },
    ] = useMutation(CREATE_AUTHORIZATION_TOKEN);

    useEffect(() => {
      if (token) {
        getOrganizations({
          variables: {
            token,
          },
        });
      }
    }, [token]);

    useEffect(() => {
      if (createData) {
        onSuccess();
        onClose();
      }
    }, [createData]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create New Token</DialogTitle>
        <Form
          className={classes.form}
          onSubmit={
            // @ts-expect-error
            (values) => {
              const { organization: organizationId, description = '' } = values;
              createAuthorizationToken({
                variables: { token, organizationId, description },
              });
            }
          }
        >
          <DialogContent>
            <DialogContentText>
              Please describe the token and select the organization to which it
              applies below.
            </DialogContentText>
            <TextField
              field='description'
              label='Description'
              className={classes.textfield}
              required={true}
              fullWidth
            />
            <Select
              field='organization'
              label='Organization'
              className={classes.selectField}
              required={true}
              options={
                data
                  ? data.allOrganizations?.map((org) => {
                      return {
                        value: org?.id,
                        label: `${org?.name} (${org?.category})`,
                      };
                    })
                  : []
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={createLoading}
            >
              Create
            </Button>
          </DialogActions>
        </Form>
        {createError ? (
          <div>
            <Typography variant='body1'>Error when creating token</Typography>
            <pre>{JSON.stringify(createError, null, 4)}</pre>
          </div>
        ) : null}
      </Dialog>
    );
  },
);

interface TokenActionsProps {
  authorizationToken: ArrayElement<
    AllAuthorizationTokensQuery['allAuthorizationTokens']
  >;
  onSuccess?: () => void;
}
const TokenActions = ({ authorizationToken, onSuccess }: TokenActionsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { token } = useAuth();
  const [setAuthorizationTokenValidity, { loading, data }] = useMutation(
    SET_AUTHORIZATION_TOKEN_VALIDITY,
  );

  useEffect(() => {
    if (data && onSuccess) {
      onSuccess();
    }
  }, [onSuccess, data]);

  return (
    <>
      {authorizationToken?.valid ? (
        <>
          <Button onClick={() => setOpen(true)}>Deactivate</Button>
          <DeactivateConfirmation
            authorizationToken={authorizationToken}
            open={open}
            loading={loading}
            onClose={() => setOpen(false)}
            onConfirm={() =>
              setAuthorizationTokenValidity({
                variables: {
                  token,
                  authorizationTokenId: authorizationToken.id,
                  validity: false,
                },
              })
            }
          />
        </>
      ) : null}
    </>
  );
};

interface DeactivateConfirmationProps {
  authorizationToken: ArrayElement<
    AllAuthorizationTokensQuery['allAuthorizationTokens']
  >;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeactivateConfirmation = ({
  authorizationToken,
  open,
  loading,
  onClose,
  onConfirm,
}: DeactivateConfirmationProps) => {
  if (!authorizationToken) {
    return null;
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Deactivate Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant='body1'>
            Are you sure you want to deactivate this authorization token?
          </Typography>
          <ul>
            {authorizationToken.description ? (
              <li>{authorizationToken.description}</li>
            ) : null}
            {authorizationToken.token ? (
              <li>{authorizationToken.token}</li>
            ) : null}
            {authorizationToken.organization.name ? (
              <li>{authorizationToken.organization.name}</li>
            ) : null}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={onConfirm}
          color='primary'
          autoFocus
        >
          Deactivate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

interface CopyButtonProps {
  text: string;
}
const CopyButton = ({ text }: CopyButtonProps) => {
  return (
    <IconButton
      onClick={async () => {
        await copyTextToClipboard(text);
      }}
    >
      <ContentCopyIcon />
    </IconButton>
  );
};

const TokenTable = styled('table')(() => {
  return {
    '& td': {
      textAlign: 'center',
    },
  };
});

const CreateNewTokenButtonContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
