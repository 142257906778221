import { TextField } from '@calefy-inc/informedMaterial';
import Typography from '@mui/material/Typography';
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps } from './types';

function ProgramBuilderComponent() {
  return (
    <>
      <TextField field='defaultValue' label='Default Value' />
      <Typography variant='caption' sx={{ my: 2 }} color='textSecondary'>
        Textfield Question Prompts longer than 79 characters can cause visual
        discrepencies between textfields.
      </Typography>
    </>
  );
}

const quoteComponent = function ({
  questionInstance,
  classes,
  ...props
}: QuoteComponentProps) {
  return (
    <TextField
      label={questionInstance.label}
      field={questionInstance.generateFieldName()}
      id={questionInstance.id}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes && classes.textfield ? classes.textfield : ''}
      classes={classes}
      {...props}
      variant='standard'
    />
  );
};

const IconComponent = (): JSX.Element => {
  return <TextFieldsRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'textfield',
    typeLabel: 'Text Field',
    quoteComponent,
    dataType: 'string',
    programBuilderComponent: ProgramBuilderComponent,
    icon: IconComponent,
  });
}

export { quoteComponent };
