import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { determineBaseURL, useAuth } from '@calefy-inc/authentication';

import { useLocation } from '@reach/router';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const generateOnClick = (logout: ReturnType<typeof useAuth>['logout']) => {
  return () => {
    const to = `${determineBaseURL()}/insurtech/login?to=${determineBaseURL()}/manager/dashboard`;
    //console.log('Logout: about to return to', to);
    logout({
      returnTo: to,
    });
  };
};

type Classes = { [k: string]: string };

interface LoginErrorProps {
  classes: Classes;
}

interface GenericLoginErrorProps extends LoginErrorProps {}
/* Generic Login Error */
export const GenericLoginError = ({ classes = {} }: GenericLoginErrorProps) => {
  const location = useLocation();
  const { logout } = useAuth();
  return (
    <div className={classes.main}>
      <Typography
        variant='h6'
        color='primary'
        gutterBottom
        sx={{ alignSelf: 'center' }}
      >
        An Error Occured While Logging You In
      </Typography>

      <Typography variant='body2' color='textSecondary'>
        An error occurred while logging you in. Please try again in a few
        minutes. If the error persists, contact{' '}
        <a
          href={`mailto:support@calefy.ca?subject=Error logging in&body=Failed URL: ${location.href}`}
        >
          support@calefy.ca
        </a>{' '}
        and include the current address in the email.
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          variant='contained'
          component='a'
          href='quote'
        >
          Return to Application
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          onClick={generateOnClick(logout)}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

/* Unauthorized Error */
interface UnauthorizedLoginProps extends LoginErrorProps {}
export const UnauthorizedLogin = ({ classes = {} }: UnauthorizedLoginProps) => {
  const { logout } = useAuth();
  return (
    <div className={classes.main} data-testid='UnauthorizedLogin'>
      <Typography
        variant='h6'
        color='primary'
        gutterBottom
        sx={{ alignSelf: 'center' }}
      >
        Unauthorized Login!
      </Typography>

      <Typography variant='body2' color='textSecondary'>
        You are not authorized to log in using that email address. This usually
        means that you tried to log in with an email address that does not
        belong to the same organization as the domain to which you tried to log
        in. If you believe this to be in error, contact{' '}
        <a href={`mailto:support@calefy.ca?subject=Login authorization error`}>
          support@calefy.ca
        </a>
        .
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          variant='contained'
          component='a'
          href='quote'
        >
          Return to Application
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          onClick={generateOnClick(logout)}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

/* Unverified Email Error */
interface UnthemedUnverifiedEmailLoginErrorProps extends LoginErrorProps {}
export const UnthemedUnverifiedEmailLoginError = ({
  classes = {},
}: UnthemedUnverifiedEmailLoginErrorProps) => {
  const { logout } = useAuth();
  return (
    <div className={classes.main} data-testid='unverified-email-login-error'>
      <Typography
        variant='h6'
        color='primary'
        gutterBottom
        sx={{ alignSelf: 'center' }}
      >
        Unverified Email
      </Typography>

      <Typography variant='body2' color='textSecondary'>
        You are not authorized to log in using that email address. This is
        because your email has not yet been verified. There are a few thing you
        can do:
      </Typography>
      <ul>
        <li>
          <Typography variant='body2' color='textSecondary'>
            If you just verified your email, wait a few minutes and try again.
            It can sometimes take up to 5 minutes for changes to take effect.
          </Typography>
        </li>
        <li>
          <Typography variant='body2' color='textSecondary'>
            If you have not yet received the verification, please check your
            spam folder. The email should come from{' '}
            <Typography
              variant='body2'
              color='textSecondary'
              sx={{ fontFamily: 'monospace', display: 'inline' }}
            >
              no-reply@auth0user.net
            </Typography>
            .
          </Typography>
        </li>
        <li>
          <Typography variant='body2' color='textSecondary'>
            If you have not yet received the verification and the email is not
            in the spam folder or otherwise blocked, please send an email to{' '}
            <a href='mailto:support@calefy.ca?subject=Missing Verification Email'>
              support@calefy.ca
            </a>{' '}
            from the email address with which you are attempting to log in.
          </Typography>
        </li>
      </ul>

      <Typography variant='body2' color='textSecondary'>
        Our apologies for any inconvenience.
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          variant='contained'
          component='a'
          href='quote'
        >
          Return to Application
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          onClick={generateOnClick(logout)}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export const UnverifiedEmailErrorLogin = withStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))(({ classes }: { classes: Classes }) => (
  <UnthemedUnverifiedEmailLoginError classes={classes} />
));
/* Theming */

export const GenericLoginErrorWithThemes = withStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))(({ classes }: { classes: Classes }) => (
  <GenericLoginError classes={classes} />
));

export const UnauthorizedLoginWithThemes = withStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))(({ classes }: { classes: Classes }) => (
  <UnauthorizedLogin classes={classes} />
));

const useDifferentDomainStyles = makeStyles((theme: $TSFixMe) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const DifferentDomainsError = () => {
  const classes = useDifferentDomainStyles();
  const location = useLocation();
  const { logout } = useAuth();
  return (
    <div className={classes.main} data-testid='DifferentDomainsError'>
      <Typography
        variant='h6'
        color='primary'
        gutterBottom
        sx={{ alignSelf: 'center' }}
      >
        Domain Conflict
      </Typography>

      <Typography variant='body2' color='textSecondary'>
        An error occurred while logging you in. It looks like you tried to log
        in with an email address that is not associated with this URL. Please
        try again with an email that belongs to the same organization as the
        URL. If the problem persists, contact{' '}
        <a
          href={`mailto:support@calefy.ca?subject=Error logging in - different domains&body=Failed URL: ${location.href}`}
        >
          support@calefy.ca
        </a>{' '}
        and include the current address in the email.
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          component='a'
          href='quote'
        >
          Return to Application
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={generateOnClick(logout)}
        >
          Login
        </Button>
      </div>
    </div>
  );
};
