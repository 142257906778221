import { FinalFormModifierType } from '../../../../../../gql/graphql';
import { BusinessType, Policy } from '../../../../../../Typescript';
import { BooleanWithUnknown } from '@calefy-inc/utilityTypes';
import { cloneDeep } from 'lodash';
import { ProgramBuilderQuestionInstance } from '../../../../../FormManager/classes';
import { getAvailableLanguagesFromQuestionInstanceList } from '../../../../../FormManager/classes/ProgramBuilderForm/utility';

export const ALL_SIGNIFIER = '__all__' as const;
type AllSignifier = typeof ALL_SIGNIFIER;

interface FinalFormModifierInput {
  id?: FinalFormModifierType['id'];
  business?: BusinessType | AllSignifier;
  policy?: Policy | null;
  questionInstances: Array<ProgramBuilderQuestionInstance>;
  apiNamesForRemoval?: Array<string>;
  masked?: BooleanWithUnknown;
}
export class FinalFormModifier {
  id?: FinalFormModifierType['id'];
  business: BusinessType | typeof ALL_SIGNIFIER;
  policy: Policy | null;
  questionInstances: Array<ProgramBuilderQuestionInstance>;
  apiNamesForRemoval: Array<string>;
  masked: BooleanWithUnknown;

  constructor({
    id,
    business,
    policy,
    questionInstances,
    apiNamesForRemoval,
    masked,
  }: FinalFormModifierInput) {
    this.id = id;
    this.business = business || ALL_SIGNIFIER;
    this.policy = policy || null;
    // ensure that the ordering for questionInstances is modified, then added
    this.questionInstances = [
      ...[...questionInstances].filter((q) => q.modified === true),
      ...[...questionInstances].filter((q) => q.modified !== true),
    ];
    this.apiNamesForRemoval =
      apiNamesForRemoval === undefined ? [] : apiNamesForRemoval;
    this.masked = masked === undefined ? 'unknown' : masked;
  }

  isNew() {
    return this.id === undefined;
  }

  copyWithAmendments(input: Partial<FinalFormModifierInput>) {
    return new FinalFormModifier({
      ...this,
      ...input,
    });
  }

  getAvailableLanguages() {
    return getAvailableLanguagesFromQuestionInstanceList(
      this.questionInstances,
    );
  }

  copy() {
    return new FinalFormModifier({
      ...this,
      policy: cloneDeep(this.policy),
      business: cloneDeep(this.business),
      questionInstances: this.questionInstances.map((q) => q.copy()),
    });
  }

  /* Static Function */
  static generateFromBackendResponse(
    response: Exclude<FinalFormModifierType, 'organization'>,
  ) {
    return new FinalFormModifier({
      id: response.id,
      // @ts-expect-error
      business: response.business || ALL_SIGNIFIER,
      policy: response.policy || undefined,
      questionInstances: response.questionInstances.map(
        (questionInstanceResponse) =>
          ProgramBuilderQuestionInstance.generateFromBackendResponse(
            questionInstanceResponse,
          ),
      ),
      // @ts-expect-error
      apiNamesForRemoval: response.apiNamesForRemoval,
    });
  }
}
