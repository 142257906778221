import { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Pagebreak } from '../utils';
import { QuestionAnswerDisplay } from '../../QuestionAnswerDisplay';
import { sortForms } from '../../QuotePdf/components/utility';

// classes and types
import { BackendCompletedForm } from '../../../../Typescript/backend/classes';
import { QuotePdfComponentPageProps } from './types';

// ---------------------------------------------------------------------
const DLStyle = styled('dl')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '[question] 2fr [answer] 2fr',
  '& dt': {
    display: 'inline',
    ...theme.typography.body2,
  },
  '& dd': {
    display: 'inline-flex',
    flexDirection: 'row',
    ...theme.typography.body2,
    // justifyContent: 'flex-end',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  '& dd:after': {
    content: '" "',
    display: 'block',
  },
}));

// ---------------------------------------------------------------------

export const FormPage = ({ quote }: QuotePdfComponentPageProps) => {
  const [forms, setForms] = useState<Array<BackendCompletedForm>>(
    quote.completedForms,
  );

  const renewalOnly = !!new URLSearchParams(window.location.search)
    .get('renewalOnly')
    ?.match(/true/i);

  useEffect(() => {
    if (quote.completedForms.length > 0) {
      setForms([...quote.completedForms]);
    }
  }, [quote]);

  return (
    <>
      <Box>
        {sortForms(forms).map((form) => {
          return (
            <>
              <Typography variant='subtitle1' color='textSecondary'>
                {form.label()}
              </Typography>

              <Typography>{renewalOnly}</Typography>

              <Divider sx={{ my: 2 }} />

              <DLStyle>
                {form.answers.map((answer, index, answers) => {
                  if (!answer.askOnRenewal && renewalOnly) {
                    return null;
                  }

                  return (
                    <QuestionAnswerDisplay
                      answer={answer}
                      answers={answers}
                      indent={0}
                      displayLocation='quotePdf'
                      key={answer.id + '_' + index}
                    />
                  );
                })}
              </DLStyle>

              <Pagebreak />
            </>
          );
        })}
      </Box>
    </>
  );
};
