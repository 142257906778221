import { BounceButton } from '../../../Shared';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const NextButton = ({
  onClick = () => {},
  disabled = false,
  type = 'button',
}) => (
  <BounceButton
    type={type}
    onClick={onClick}
    disabled={disabled}
    variant='contained'
    color='primary'
    size='medium'
    endIcon={<ChevronRightIcon />}
  >
    Next
  </BounceButton>
);

export const BackButton = ({
  onClick = () => {},
  disabled = false,
  type = 'button',
}) => (
  <BounceButton
    type={type}
    onClick={onClick}
    disabled={disabled}
    variant='outlined'
    color='primary'
    size='medium'
    startIcon={<ChevronLeftIcon />}
  >
    Back
  </BounceButton>
);
