import FuseInsuranceSettings from '../../WhiteLabelSettings/FuseInsurance/settings';
import CannabisInsuranceSettings from '../../WhiteLabelSettings/CannabisInsurance/settings';
import VapeInsuranceSettings from '../../WhiteLabelSettings/VapeInsurance/settings';
import LastnameInsuranceSettings from '../../WhiteLabelSettings/LastnameInsurance/settings';
import CalefySettings from '../../WhiteLabelSettings/Calefy/settings';
import EngineerInsuranceSettings from '../../WhiteLabelSettings/EnigneerInsurance/settings';
import RockChainInsuranceSettings from '../../WhiteLabelSettings/RockChainInsurance/settings';
import MillerInsuranceSettings from '../../WhiteLabelSettings/MillerInsurance/settings';
import KrywoltSettings from '../../WhiteLabelSettings/Krywolt/settings';
import YoungHaggisSettings from '../../WhiteLabelSettings/YoungHaggis/settings';
import noContactInformationFirstSettings from '../../WhiteLabelSettings/noContactInformationFirst/settings';
import OraclermsSettings from '../../WhiteLabelSettings/OracleRMS/settings';
import VaGoAssureSettings from '../../WhiteLabelSettings/VagoAssure/settings';
import {
  WalnutNeoSettings,
  WalnutCommercialSettings,
  WalnutAtbSettings,
} from '../../WhiteLabelSettings/Walnut';
import { boardwalkSettings } from '../../WhiteLabelSettings/Boardwalk/settings';

import { WebsiteSettings } from './WebsiteSettings';

// slug -> settings. This should really be automatic - that's why the slug field exists in the first place
const domainSettingsMap = new Map<string, WebsiteSettings>([
  ['localhost', CalefySettings],
  ['calefy', CalefySettings],
  ['fuseinsurance', FuseInsuranceSettings],
  ['cannabisinsurance', CannabisInsuranceSettings],
  ['vapeinsurance', VapeInsuranceSettings],
  ['lastnameinsurance', LastnameInsuranceSettings],
  ['engineersinsurance', EngineerInsuranceSettings],
  ['rockchaininsurance', RockChainInsuranceSettings],
  ['millerinsurance', MillerInsuranceSettings],
  ['krywolt', KrywoltSettings],
  ['younghaggis', YoungHaggisSettings],
  ['nocontactinformationfirst', noContactInformationFirstSettings],
  ['oraclerms', OraclermsSettings],
  ['vagoassure', VaGoAssureSettings],
  [WalnutAtbSettings.slug, WalnutAtbSettings],
  [WalnutNeoSettings.slug, WalnutNeoSettings],
  [WalnutCommercialSettings.slug, WalnutCommercialSettings],
  [boardwalkSettings.slug, boardwalkSettings],
]);

/**
 * Returns white label settings based on url hostname
 * @return settings objects
 */
export const useSettings: () => WebsiteSettings = () => {
  // first check if we are listening to the search param whitelabel=. We only want this to happen locally or on staging - everyone else needs to listen to their domain
  const originsWithSearchParams = [
    'http://localhost:3000',
    'https://staging-calefy.fuseinsurance.ca',
    'https://secure.lastnameinsurance.ca',
    'https://secure.engineersinsurance.ca',
  ];
  if (originsWithSearchParams.includes(window.location.origin)) {
    const whitelabelFromParams = new URLSearchParams(
      window.location.search,
    ).get('whitelabel');
    if (whitelabelFromParams && domainSettingsMap.has(whitelabelFromParams)) {
      return domainSettingsMap.get(whitelabelFromParams) as WebsiteSettings;
    }
  }

  // otherwise, just get it from the host
  const host = window.location.host;
  switch (host) {
    case 'secure.fuseinsurance.ca':
    case 'staging-calefy.fuseinsurance.ca':
      return FuseInsuranceSettings;

    case 'secure.cannabisinsurance.ca':
      return CannabisInsuranceSettings;

    case 'secure.vapeinsurance.ca':
      return VapeInsuranceSettings;

    case 'secure.lastnameinsurance.ca':
      return LastnameInsuranceSettings;

    case 'secure.engineersinsurance.ca':
      return EngineerInsuranceSettings;

    case 'secure.young-haggis.com':
      return YoungHaggisSettings;

    case 'secure.krywolt.com':
      return KrywoltSettings;

    case 'secure.vagoassure.com':
      return VaGoAssureSettings;

    case 'commercial.gowalnut.com':
      return WalnutCommercialSettings;

    case 'neopartners.commercial.gowalnut.com':
      return WalnutNeoSettings;

    case 'atb.commercial.gowalnut.com':
      return WalnutAtbSettings;

    case boardwalkSettings.host:
      return boardwalkSettings;

    default:
      return CalefySettings;
  }
};
