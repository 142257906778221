// @ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from './useAllowByPermissions';

interface ToReturn {
  allowed?: boolean;
  apiNames: Array<string>;
  canEditQuestion: (q: ProgramBuilderQuestionInstance | string) => boolean;
}
export const useAllowByQuestionEditPermissions = () => {
  const [toReturn, setToReturn] = useState<ToReturn>({
    allowed: undefined,
    apiNames: [],
    canEditQuestion: (q) => {
      return false;
    },
  });
  const [allowed, setAllowed] = useState<boolean | undefined>(undefined);
  const [allowedApiNames, setAllowedApiNames] = useState<Array<string>>([]);
  //console.log({ allowedApiNames });
  const { token, userPermissions: permissions } = useAuth();
  const hasFormEditPermissions = useAllowByPermission('edit:forms');

  const canEditQuestion = useCallback(
    (question: ProgramBuilderQuestionInstance | string) => {
      if (hasFormEditPermissions) {
        return true;
      }
      if (allowed !== true) {
        return false;
      }
      const apiName = typeof question === 'string' ? apiName : question.apiName;
      return allowedApiNames.includes(apiName);
    },
    [allowedApiNames, hasFormEditPermissions, allowed],
  );

  useEffect(() => {
    // const timer = setTimeout(() => {
    if (token) {
      // @ts-expect-error
      const apiNames = (permissions || []).reduce((acc, permission) => {
        const match = permission.match(/edit:question:(.+)/i);
        if (match && match.length > 1) {
          return [...acc, match[1]];
        }
        return acc;
      }, []);
      // @ts-expect-error
      if (Array.isArray(permissions)) {
        setAllowed(apiNames.length > 0);
      }
      setAllowedApiNames(apiNames);
    }
    // }, 0);
    // return () => clearTimeout(timer);
  }, [permissions, token]);

  useEffect(() => {
    setToReturn({
      allowed,
      apiNames: allowedApiNames,
      canEditQuestion,
    });
  }, [allowed, allowedApiNames, canEditQuestion]);

  return toReturn;
};
