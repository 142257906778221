// classes and types
import type { BackendCompletedForm } from '../../../../Typescript/backend/classes';

export const sortForms = (forms: Array<BackendCompletedForm>) => {
  const generalInformation = forms.filter((form) => form.policy === null);

  const generalLiablility = forms.filter(
    (form) => form.policy?.displayName === 'General Liability',
  );
  const property = forms.filter(
    (form) => form.policy?.displayName === 'Property',
  );
  const formsFound = [...generalInformation, ...generalLiablility, ...property];
  const otherForms = forms
    .filter(
      (form) =>
        formsFound.find((innerForm) => form === innerForm) === undefined,
    )
    .sort((a, b) =>
      a.policy && b.policy
        ? a.policy.displayName.localeCompare(b.policy.displayName)
        : 0,
    );

  return [...formsFound, ...otherForms];
};
