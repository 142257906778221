import { graphql } from '../gql';

export const ALL_STATISTICAL_SESSIONS = graphql(`
  query allStatisticalSessions($token: String!, $organization: String) {
    allStatisticalSessions(token: $token, organization: $organization) {
      sessionId
      timeCreated
      organization {
        name
      }
      events {
        eventId
        timeCreated
        eventType
        data
        userEmail
        quote {
          uniqueID
        }
      }
    }
  }
`);
