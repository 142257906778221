import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import { LoadingIconButton } from './LoadingIconButton';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';

import { copyTextToClipboard } from '@calefy-inc/utility';
import { MARK_AS_RENEWAL_LINK_GENERATED } from '../../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';

// types and classes
import type { Quote } from '../../../../../../Typescript/backend/classes/';

interface CopyRenewalLinkButtonProps {
  uuid: Quote['uniqueId'];
  disabled?: boolean;
  setStatus: Dispatch<SetStateAction<Quote['status'] | null>>;
}

export const CopyRenewalLinkButton = ({
  uuid,
  disabled = false,
  setStatus,
}: CopyRenewalLinkButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [renewalLink, setRenewalLink] = useState<string>('');
  const [copyError, setCopyError] = useState<Error | null>(null);
  const [markAsRenewalLinkGenerated, { loading, error, data }] = useMutation(
    MARK_AS_RENEWAL_LINK_GENERATED,
    { fetchPolicy: 'no-cache' },
  );
  const { token } = useAuth();

  // stop the process if the backend fails
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error when generating renewal link: ${error}`, {
        variant: 'error',
      });
    }
  }, [error, enqueueSnackbar]);

  // if the response is good, then copy the renewal link to the clipboard
  useEffect(() => {
    if (
      data?.markAsRenewalLinkGenerated?.ok &&
      data?.markAsRenewalLinkGenerated?.link
    ) {
      setRenewalLink(data.markAsRenewalLinkGenerated.link);
    }
  }, [data]);

  // if the data is there and the renewal link as well, copy it to the clipboard
  useEffect(() => {
    if (data?.markAsRenewalLinkGenerated?.ok && renewalLink) {
      (async () => {
        await copyTextToClipboard(
          renewalLink,
          () => {
            enqueueSnackbar('Renewal link successfully copied!', {
              variant: 'success',
            });
            setStatus('RENEWAL_LINK_GENERATED');
          },
          (error: Error) => {
            setCopyError(error);
            enqueueSnackbar(`Failed to copy link: ${error}`);
          },
        );
      })();
    }
  }, [data, renewalLink, enqueueSnackbar, setCopyError, setStatus]);

  if (!uuid) {
    return null;
  }
  if (!token) {
    return null;
  }
  return (
    <>
      <Tooltip
        title={
          disabled
            ? 'Unable to copy link at this time'
            : 'Copy Renewal Link to Clipboard'
        }
      >
        <span>
          <LoadingIconButton
            size='small'
            loading={loading}
            disabled={disabled}
            loadingPosition='center'
            aria-label='copy renewal link'
            onClick={() => {
              markAsRenewalLinkGenerated({ variables: { uuid, token } });
            }}
          >
            <FileCopyRoundedIcon fontSize='small' />
          </LoadingIconButton>
        </span>
      </Tooltip>
      {copyError ? (
        <span>
          Renewal Link: <pre>{renewalLink}</pre>
        </span>
      ) : null}
    </>
  );
};
