import { useEffect, useState } from 'react';
import { useParams, useLocation } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
// material
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import BusinessNewForm from '../../components/_dashboard/database/business/BusinessNewForm';
import LoadingScreen from '../../components/LoadingScreen';
// queries
import { BUSINESS_TYPE } from '../../../../queries';
// error handler
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../Page500';

import { RouteComponentProps } from '@reach/router';

// ----------------------------------------------------------------------

interface BusinessCreateProps extends RouteComponentProps {}
export default function BusinessCreate(_props: BusinessCreateProps) {
  const { pathname } = useLocation();
  const { businessId } = useParams();
  const isEdit = pathname.includes('edit');
  const [currentBusiness, setCurrentBusiness] = useState(null);

  // queries
  const [getBusiness, { loading, data, error }] = useLazyQuery(BUSINESS_TYPE);

  // effects
  useEffect(() => {
    if (isEdit && businessId) {
      getBusiness({
        variables: {
          id: businessId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, businessId]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      setCurrentBusiness(data.businessType);
    }
  }, [data]);

  // Error actions
  if (error) {
    console.error('Error getting business:', error);
    Bugsnag.notify(error);
    return <ErrorPage />;
  }

  // Loading actions
  if (loading || (isEdit && !currentBusiness)) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Page
        title={
          !isEdit
            ? 'Database: Create a new business type | Manager'
            : 'Database: Edit business | Manager'
        }
      >
        <Container maxWidth='md' data-testid='business-create'>
          <HeaderBreadcrumbs
            heading={!isEdit ? 'Create a new business' : 'Edit business'}
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Business List', href: PATH_DASHBOARD.database.business },
              { name: !isEdit ? 'New Business' : 'Edit business' },
            ]}
          />

          <BusinessNewForm isEdit={isEdit} currentBusiness={currentBusiness} />
        </Container>
      </Page>
    </>
  );
}
