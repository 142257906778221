import theme from './CannabisInsuranceTheme';
import logo from './assets/MainLogo.png';
import squareLogo from './assets/CannabisSquareLogo.png';
import favicon from './assets/Favicon.jpg';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Icon } from '@iconify/react';
import cannabisIcon from '@iconify-icons/fa-solid/cannabis';
import leafIcon from '@iconify-icons/fa-solid/leaf';

import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const tileIcons = (internalName: string) => {
  switch (internalName) {
    case 'retail_cannabis':
      return <AttachMoneyIcon color='inherit' fontSize='large' />;
    case 'microprocessing_cannabis_':
      return <Icon icon={leafIcon} width={'35px'} />;
    case 'cannabis_microcultivator_or_licensed_producer':
      return <Icon icon={cannabisIcon} width={'35px'} />;

    default:
      return <Icon icon={cannabisIcon} width={'35px'} />;
  }
};

const businessFilter = (business: $TSFixMe) => {
  const allowedBusinessNames = [
    'retail_cannabis',
    'microprocessing_cannabis_',
    'cannabis_microcultivator_or_licensed_producer',
  ];
  if (
    process.env.NODE_ENV !== 'production' ||
    window.location.origin === 'https://staging-calefy.fuseinsurance.ca'
  ) {
    allowedBusinessNames.push('testing_business');
  }
  return allowedBusinessNames.includes(business.internalName);
};

// Cannabis Insurance Config
const settings = new WebsiteSettings({
  slug: 'cannabisinsurance',
  host: 'secure.cannabisinsurance.ca',
  companyName: 'Fuse Insurance Ltd',
  hours: '8:30am - 5:30pm',
  phone: '1-866-387-3873',
  email: 'cannabis@fuseinsurance.ca',
  chatLink: 'https://www.facebook.com/FuseInsuranceCA',
  companyTheme: theme,
  logo: logo,
  businessFilter: businessFilter,
  tileIcons: tileIcons,
  title: 'Cannabis Insurance: Cannabis Business Insurance In Canada',
  favicon: favicon,
  squareLogo,
});

export default settings;
