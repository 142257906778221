import { graphql } from '../gql';

export const FINAL_FORM_MODIFIER = graphql(`
  query finalFormModifier(
    $policyId: ID
    $businessId: ID
    $raiseErrors: Boolean!
  ) {
    finalFormModifier(
      policyId: $policyId
      businessId: $businessId
      raiseErrors: $raiseErrors
    ) {
      id
      business {
        id
        displayName
      }
      policy {
        id
        displayName
      }
      questionInstances {
        ...QuestionInstanceFields
        subQuestions {
          ...QuestionInstanceFields
          subQuestions {
            ...QuestionInstanceFields
            subQuestions {
              ...QuestionInstanceFields
              subQuestions {
                ...QuestionInstanceFields
                subQuestions {
                  ...QuestionInstanceFields
                  subQuestions {
                    ...QuestionInstanceFields
                    subQuestions {
                      ...QuestionInstanceFields
                      subQuestions {
                        ...QuestionInstanceFields
                        subQuestions {
                          ...QuestionInstanceFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      apiNamesForRemoval
    }
  }
`);
