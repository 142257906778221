import { Checkbox } from '@calefy-inc/informedMaterial';
import { Typography, IconButton, Tooltip, Divider } from '@mui/material';
import { ArrayField } from 'informed';
import RenderedQuestionInstance from './RenderedQuestionInstance';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeMotionRoundedIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddItemButton } from './common';

import { Component } from './classes';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  QuoteComponentProps,
  ProgramBuilderComponentProps,
  QuoteComponentValidationFunctionReturn,
} from './types';
import { ProgramBuilderQuestionInstance } from '../../FormManager/classes';

const ProgramBuilderComponent = ({
  classes = {},
  questionInstance,
}: ProgramBuilderComponentProps) => {
  const propsBlob =
    questionInstance && questionInstance.propsBlob
      ? questionInstance.propsBlob
      : {};
  const repeatable = propsBlob.repeatable ? true : false;

  return (
    <>
      <Checkbox
        classes={classes}
        className={classes.checkbox}
        field='props_blob_repeatable'
        label='Repeatable set'
        value={repeatable}
      />
    </>
  );
};

const QuoteComponent = function ({
  questionInstance,
  blockAddSubquestions,
  classes = {},
}: QuoteComponentProps) {
  //Render the top level question, then call RenderQuestionInstance on all the other questions so they look nice
  // Define a callback function that fires on clicking the "Add new" button
  // The "Add new" button should add a new set of fieldsets to the form's state using the Informed field API

  /* {
    form : {
      fieldset: [
        [fieldset1:
          q1: a1
          q2: a2],
        [fieldset2: //so this is what we'd add
          q1: a1:
          q2: a2]
      ]

    }
  }*/
  const { propsBlob } = questionInstance;
  const { repeatable } = propsBlob;

  /*console.log('SET OF QUESTIONS: about to render ', questionInstance); */

  return (
    <>
      <Typography
        variant='subtitle1'
        color='textSecondary'
        className={classes.center}
      >
        {questionInstance.label}
      </Typography>
      <ArrayField field={questionInstance.generateFieldName()}>
        {(arrayFieldApi) => {
          // debugger;
          if (arrayFieldApi.fields.length === 0) {
            // debugger;
            arrayFieldApi.add();
            // arrayFieldApi.add();
          }
          return (
            <>
              {/* @ts-expect-error */}
              <ArrayField.Items>
                {(arrayFieldItemsApi: $TSFixMe) => {
                  // debugger;
                  return (
                    <>
                      {arrayFieldApi.fields.length > 1 &&
                      arrayFieldItemsApi.index !== 0 ? (
                        <Divider className={classes.divider} />
                      ) : null}
                      <div className={classes.addressHeader}>
                        {arrayFieldApi.fields.length > 1 ? (
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            className={classes.start}
                          >
                            {/* `${
                              questionInstance.realName
                                ? questionInstance.realName
                                : questionInstance.name
                                } ${arrayFieldItemsApi.index + 1}` */}
                            {`${questionInstance.displayName} ${
                              arrayFieldItemsApi.index + 1
                            }`}
                          </Typography>
                        ) : null}
                        <div className={classes.removeAddress}>
                          {arrayFieldApi.fields.length > 1 ? (
                            <IconButton
                              color='default'
                              size='small'
                              onClick={() => {
                                arrayFieldItemsApi.remove();
                              }}
                              type='button'
                            >
                              <Tooltip
                                enterDelay={100}
                                leaveDelay={100}
                                placement='right'
                                title={`Remove ${questionInstance.displayName}`}
                              >
                                <CloseIcon />
                              </Tooltip>
                            </IconButton>
                          ) : undefined}
                        </div>
                      </div>
                      {questionInstance.subQuestions.map((subQ) => {
                        subQ.fieldPrefix = arrayFieldItemsApi.field;
                        /* let newSubQ = Object.assign({}, subQ, { */
                        /*   name: `${arrayFieldItemsApi.field}.${subQ.name}`, */
                        /*   realName: subQ.name, */
                        /* }); */
                        return (
                          <RenderedQuestionInstance
                            questionInstance={subQ}
                            key={subQ.id}
                            classes={classes}
                          />
                        );
                      })}
                    </>
                  );
                }}
                {/* @ts-expect-error */}
              </ArrayField.Items>
              {repeatable && !blockAddSubquestions ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddItemButton
                    text={`Add ${questionInstance.displayName}`}
                    aria-label='Add'
                    className={classes.button}
                    type='button'
                    color='primary'
                    onClick={() => {
                      // debugger;
                      arrayFieldApi.add();
                    }}
                    size='large'
                  />
                </div>
              ) : null}
            </>
          );
        }}
      </ArrayField>
    </>
  );
};

//The point of this component is to gather all the subQuestions and add them
//to the questionInstance, then return that instance. The remaining steps should
//happen in formStore
/* const gatherSubQuestions = ( */
/*   formState: $TSFixMe, */
/*   questionInstance: $TSFixMe, */
/* ) => { */
/*   //look at form state, pick out the first question */
/*   //push it into the first slot in the subQuestions array */
/*   let temp_arr = formState.subQuestions; */
/*   questionInstance.subQuestions = temp_arr; */
/*   return questionInstance; */
/* }; */

export function validateRepeatable(
  questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  const { subQuestions } = questionInstance;
  const errorMessages = [];
  if (!subQuestions || subQuestions.length === 0) {
    errorMessages.push(
      `Set of Questions with name "${questionInstance.generateDisplayName()}" missing a subquestion; there must be at least one.`,
    );
  }
  return errorMessages;
}

const IconComponent = () => {
  return <AutoAwesomeMotionRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'repeatable',
    typeLabel: 'Set of Questions',
    /* gatherSubQuestions: gatherSubQuestions, */
    quoteComponent: QuoteComponent,
    dataType: 'nested',
    programBuilderComponent: ProgramBuilderComponent,
    programBuilderValidation: validateRepeatable,
    icon: IconComponent,
  });
}

export { QuoteComponent };
