import { useState, useCallback, useEffect, useRef } from 'react';
import { Form, EmailInput } from '@calefy-inc/informedMaterial';
import { useLazyQuery } from '@apollo/client';
// material
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { CHECK_FORM_INTEGRITY } from '../../../../../../queries';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

export const FormIntegrityCheckerButton = () => {
  const [open, setOpen] = useState<boolean>(false); // is the dialog open?

  return (
    <>
      <Tooltip title='Verify form integrity'>
        <IconButton
          type='button'
          onClick={() => setOpen(true)}
          aria-label='verify form integrity'
        >
          <FactCheckIcon />
        </IconButton>
      </Tooltip>
      <IntegrityCheckerDialog open={open} setOpen={setOpen} />
    </>
  );
};

interface IntegrityCheckerDialogProps {
  open: boolean;
  setOpen: $TSFixMe;
}
function IntegrityCheckerDialog({
  open,
  setOpen,
}: IntegrityCheckerDialogProps) {
  const [currentEmail, setCurrentEmail] = useState<string>(''); // the email which was just submitted - used for the notification
  const apiRef = useRef<$TSFixMe>();
  const [checkFormIntegrity, { loading, error, data }] =
    useLazyQuery(CHECK_FORM_INTEGRITY);
  const onSubmit = useCallback(
    (email: string) => {
      if (email) {
        setCurrentEmail(email);
        checkFormIntegrity({ variables: { email } });
      }
    },
    [data, checkFormIntegrity, setCurrentEmail],
  );

  useEffect(() => {
    if (data && apiRef.current) {
      apiRef.current.reset();
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        if (apiRef.current) {
          apiRef.current.reset();
        }
      }}
      aria-labelledby='form-integrity-modal-title'
      // @ts-expect-error
      BackdropProps={{ 'data-testid': 'form-integrity-modal-backdrop' }}
    >
      <DialogTitle title='form-integrity-modal-title'>
        Check Forms for Integrity
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will check all of your forms for potential errors or
          inconsistencies and email you the results.
        </DialogContentText>
        <Form
          onSubmit={(values: $TSFixMe) => onSubmit(values.email)}
          preventEnter={false}
          getApi={(api) => {
            apiRef.current = api;
          }}
        >
          <EmailInput
            field='email'
            id='email'
            required={true}
            disabled={loading}
            autoFocus
            margin='dense'
            label='Email Address'
            fullWidth
            variant='standard'
          />
          <Button type='submit' disabled={loading}>
            Submit
          </Button>
        </Form>
        <DialogContentText sx={{ marginTop: '2' }}>
          {loading ? (
            <p>
              Checking forms. Note that this may take a few minutes to complete.
              It is now safe to close this form - the email with the results
              will be sent regardless.
            </p>
          ) : error ? (
            <div>
              <p>
                An error occurred when checking the forms. Please send the
                following to{' '}
                <a
                  href={`mailto:support@calefy.ca?subject=Error when verifying form integrity&body=${JSON.stringify(
                    error,
                    null,
                    4,
                  )}`}
                >
                  support@calefy.ca
                </a>
              </p>
              <pre>{JSON.stringify(error, null, 4)}</pre>
            </div>
          ) : data?.checkFormIntegrity ? (
            <p>Forms checked - please check {currentEmail} for the report.</p>
          ) : null}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
