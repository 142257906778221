import { graphql } from '../gql';

// Get all referrals
export const GET_ALL_REFERRERS = graphql(`
  query getAllReferrers($token: String!) {
    allReferrers(token: $token) {
      id
      name
      referralCode
    }
  }
`);

export const CREATE_REFERRER = graphql(`
  mutation createReferrer(
    $name: String!
    $referralCode: String!
    $token: String!
  ) {
    createReferrer(name: $name, referralCode: $referralCode, token: $token) {
      ok
      referrer {
        id
        name
        referralCode
      }
    }
  }
`);

export const CHECK_REFERRAL_CODE_VALIDITY = graphql(`
  query checkReferralCodeValidity($referralCode: String!) {
    checkReferralCodeValidity(referralCode: $referralCode)
  }
`);

// Get base64 for referrer excel doc
export const GET_REFERRER_XLSX = graphql(`
  query getReferrerXlsx($token: String!) {
    referrerXlsx(token: $token)
  }
`);
