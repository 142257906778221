import React from 'react';

import Bugsnag from '@bugsnag/js';

import { ErrorView } from '../ErrorView';
import { DisconnectionError } from './index';

/* Types */
import { ApolloError } from '@apollo/client/errors';

export type HandleableError = Error | ApolloError;

type ErrorCallback = (error: HandleableError) => React.ReactNode | undefined;

/**
 * Function to provide some sort of generalizable error handling
 * Basic idea: you feed it an error. If you passed it a callback and that callback returns a component when fed that error, it returns it. Otherwise it checks if there is a specific error component to handle it, and otherwise just returns the generic error component.
 * @param error - the error to sort out
 * @param callback - Function to be initiall called to see if it handles the error; if not (it returns undefined), control passes back to the handleErrors function to see if it can deal with it. This allows handleErrors to provide a base level of error handling while also allowing some customizable behaviour.
 */
export const handleErrors = (
  error: HandleableError,
  callback?: ErrorCallback,
) => {
  if (!error || !error.message) {
    Bugsnag.notify(
      new Error(
        `Unknown error being handled ${JSON.stringify(error, null, 4)}`,
      ),
    );
    return null;
  }
  // console.error(error.message);
  Bugsnag.notify(error);
  if (callback) {
    const handledComponent = callback(error);
    if (handledComponent) {
      return handledComponent;
    }
  } else if (error instanceof ApolloError && error?.networkError) {
    return <DisconnectionError />;
  }
  return <ErrorView />;
};
