// TODO insurtype -> application type
import { useState, useEffect, ReactNode, useMemo } from 'react';
import StepWizard from 'react-step-wizard';
import QuoteTypeSelection from './QuoteTypeSelection';
import QuotePolicySelection from './QuotePolicySelection';
import QuoteForm from './QuoteForms';
import QuoteReview from './QuoteReview';
import QuoteDone from './QuoteDone';
import { useSelector, useDispatch } from 'react-redux';
import QuoteWizardWhiteLabel from './QuoteWizardWhiteLabel';
import { splitIntoGroupsOfSize, areMappingsIdentical } from './utility';
import {
  setResumingQuote,
  setResumeToStep,
  updateStepWizardState,
} from '../../store/QuoteWizardState';
import { ErrorView } from '../common/ErrorView';
import { Nav } from './Nav';
import {
  useAuth,
  // Authenticated
} from '@calefy-inc/authentication';
import { Authenticated } from '../Authentication/Authenticated';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { QuestionsPerPage, ComponentMapping } from './types';
import { QuoteWizardQuestionInstance, QuoteWizardForm } from './classes';
import type { RouteComponentProps } from '@reach/router';
import type { StoreState } from '../../App';
import { useSetQuestionsPerPage, useSettings } from '../../hooks';
import { useListenForExistingUuid } from '../../hooks/useListenForExistingUuid';

const sortByPolicyName = (a: QuoteWizardForm, b: QuoteWizardForm) => {
  if (!a.policy) {
    return -1;
  }
  if (!b.policy) {
    return 1;
  }
  if (a.policy.displayName < b.policy.displayName) {
    return -1;
  }
  if (a.policy.displayName > b.policy.displayName) {
    return 1;
  }
  return 0;
};

const getAndOrderAllForms = (
  state: StoreState,
): null | Array<QuoteWizardForm> => {
  const selectedPolicyIds = state.quoteWizard.selectedPolicies.map(
    (policy) => policy.id,
  );

  if (
    Object.keys(state.quoteWizard.businessForm).length === 0 &&
    state.quoteWizard.policyForms.length === 0
  ) {
    return null;
  }
  const unorderedForms = [
    state.quoteWizard.businessForm,
    ...state.quoteWizard.policyForms,
  ].filter(
    (form) =>
      Object.keys(form).length > 0 &&
      // @ts-expect-error
      (!form.policy || selectedPolicyIds.includes(form.policy.id)),
  );
  // @ts-expect-error
  const orderedForms = [...unorderedForms].sort(sortByPolicyName);
  // @ts-expect-error
  return orderedForms;
};

const getStepWizard = (state: StoreState) => state.quoteWizard.stepWizard;
const getResumingQuote = (state: StoreState) => state.quoteWizard.resumingQuote;
const getResumeToStep = (state: StoreState) => state.quoteWizard.resumeToStep;
const getQuestionsPerPage = (state: StoreState): QuestionsPerPage =>
  state.quoteWizard.questionsPerPage;
const getExistingUuid = (state: StoreState) => {
  return state.quoteWizard.currentQuoteUUID;
};
const getLastSubmittedQuote = (state: StoreState) => {
  return state.quoteWizard.submittedQuotes.length > 0
    ? state.quoteWizard.submittedQuotes[
        state.quoteWizard.submittedQuotes.length - 1
      ]
    : null;
};
const getBlockContactInfoFirst = (state: StoreState) => {
  return state.quoteWizard.blockContactInfoFirst;
};

const initialComponentMapping: ComponentMapping = {
  business: 1,
  policy: 2,
  forms: 3,
  review: 4,
  done: 5,
};

interface QuoteWizardProps extends RouteComponentProps {}
// TODO replace this with the existing useIsManager hook
const QuoteWizard = (_props: QuoteWizardProps) => {
  const dispatch = useDispatch();
  const { token } = useAuth();

  const [splitForms, setSplitForms] = useState<null | Array<$TSFixMe>>(null);
  const [wizardComponents, setWizardComponents] = useState<Array<ReactNode>>(
    [],
  ); // the components that we're going to display inside the wizard
  const {
    slug,
    personalInsurance,
    sendEmailOnContactInformationCompleted,
    contactInformationFirst,
  } = useSettings();
  useListenForExistingUuid();
  useSetQuestionsPerPage();

  /* Redux Selectors */
  const { goToStep } = useSelector(getStepWizard);
  const resumingQuote = useSelector(getResumingQuote);
  const resumeToStep = useSelector(getResumeToStep);
  const allForms = useSelector(getAndOrderAllForms);
  const questionsPerPage = useSelector(getQuestionsPerPage);
  const existingQuoteUuid = useSelector(getExistingUuid);
  const lastSubmittedQuote = useSelector(getLastSubmittedQuote);
  const blockContactInfoFirst = useSelector(getBlockContactInfoFirst);

  //console.log({ resumeToStep });

  /* State */
  const insurType = useMemo(() => {
    return personalInsurance ? 'Insurance Type' : 'business';
  }, [personalInsurance]);
  const [componentMapping, setComponentMapping] = useState<ComponentMapping>(
    initialComponentMapping,
  );

  const [internalGoToStep, setInternalGoToStep] = useState<
    null | ((step: number) => void)
  >(null);

  function splitQuestionsIntoSteps(
    questionInstances: Array<QuoteWizardQuestionInstance>,
  ) {
    if (typeof questionsPerPage === 'number') {
      return splitIntoGroupsOfSize(questionInstances, questionsPerPage);
    } else if (questionsPerPage === 'all') {
      return [questionInstances];
    } else {
      throw new TypeError(
        `Invalid questionsPerPage: must be a number or the special string "all". You supplied ${questionsPerPage} of type ${typeof questionsPerPage}`,
      );
    }
  }

  /* State Modifiers */

  // generate the split forms whenever the base forms change
  useEffect(() => {
    const newSplitForms = allForms
      ? allForms.reduce((acc: Array<QuoteWizardForm>, form) => {
          const formQuestionInstances = splitQuestionsIntoSteps(
            form.questionInstances,
          );
          const newForms = formQuestionInstances.map((questionInstances) => {
            const newForm = new QuoteWizardForm({ ...form, questionInstances });
            return newForm;
          });

          return [...acc, ...newForms];
        }, [])
      : null;
    setSplitForms(newSplitForms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allForms]);

  // whenever the component mapping changes, update it in the redux store
  useEffect(() => {
    /*console.log('About to update component mapping'); */
    dispatch(updateStepWizardState({ componentMapping }));
  }, [componentMapping, dispatch]);

  // if we are resuming a quote, go to the first general information form page
  useEffect(() => {
    if (resumingQuote && goToStep && componentMapping['General Information']) {
      dispatch(setResumingQuote(false));
      goToStep(componentMapping['General Information']);
    }
  }, [componentMapping, goToStep, resumingQuote, dispatch]);

  // if we are resuming to a step
  useEffect(() => {
    if (resumeToStep === null) {
      return;
    }
    const preliminariesLoaded =
      !!resumeToStep && !!goToStep && !!dispatch && !!internalGoToStep;
    const desiredDestination =
      typeof resumeToStep === 'number'
        ? resumeToStep
        : typeof resumeToStep === 'string' && resumeToStep in componentMapping
        ? componentMapping[resumeToStep]
        : undefined;
    const shouldWaitForComponentMappingToSettle =
      desiredDestination !== undefined && desiredDestination > 2;
    const componentMappingSettled =
      Object.keys(componentMapping).length >
      Object.keys(initialComponentMapping).length;
    if (
      preliminariesLoaded &&
      desiredDestination &&
      (!shouldWaitForComponentMappingToSettle ||
        (shouldWaitForComponentMappingToSettle && componentMappingSettled))
    ) {
      //console.log(`About to resume to step ${desiredDestination}`);
      dispatch(setResumeToStep(null));
      internalGoToStep(desiredDestination);
    } else {
      //console.log('not navigating for some reason');
    }
  }, [goToStep, resumeToStep, dispatch, componentMapping, internalGoToStep]);

  // build the components of the wizard and the component mapping at the same time
  useEffect(() => {
    const elements: Array<ReactNode> = [];
    const newComponentMapping: ComponentMapping = {};
    // @ts-expect-error
    elements.push(<QuoteTypeSelection />);
    newComponentMapping[insurType] = 1;
    elements.push(
      // @ts-expect-error
      <QuotePolicySelection formsLoaded={allForms && allForms.length > 0} />,
    );
    newComponentMapping['policy'] = 2;
    if (splitForms && splitForms.length > 0) {
      splitForms.forEach((form, index) => {
        /*console.log( */
        /*   `Processing splitForms ${index + 1} / ${splitForms.length}`, */
        /* ); */
        const isContactInformationForm = !!form.questionInstances.find(
          (question: $TSFixMe) => question.apiName === 'client_contact_info',
        );
        const contactEmailRelevant =
          isContactInformationForm &&
          sendEmailOnContactInformationCompleted &&
          !token &&
          !existingQuoteUuid;
        const quoteFormParams = {
          form,
          path: `/${index}`,
          key: `${form.getName()}-${form.questionInstances[0]?.apiName}`,
          isContactInformationForm: isContactInformationForm,
          sendContactEmailOnSubmit: contactEmailRelevant,
        };
        if (
          !blockContactInfoFirst &&
          isContactInformationForm &&
          contactInformationFirst &&
          !token && // don't ask contact info if you're a broker
          (!existingQuoteUuid ||
            (existingQuoteUuid === lastSubmittedQuote?.uniqueID &&
              lastSubmittedQuote.status === 'CONTACT_INFO_ONLY')) // don't move things if you've just submitted the application
        ) {
          /*console.log('Splicing in quoteForm element with contact information'); */
          elements.splice(
            newComponentMapping['policy'] - 1,
            0,
            <QuoteForm {...quoteFormParams} hasBeenMoved={true} />,
          );
          // bump up the step number of any existing forms, &c.
          Object.entries(newComponentMapping).forEach(([step, stepNumber]) => {
            if (stepNumber >= newComponentMapping['policy']) {
              newComponentMapping[step]++;
            }
          });
          newComponentMapping['contact'] = newComponentMapping['policy'] - 1;
        } else {
          elements.push(
            <QuoteForm {...quoteFormParams} hasBeenMoved={false} />,
          );
          if (form.getName() in newComponentMapping === false) {
            newComponentMapping[form.getName()] = elements.length;
            /*console.log( */
            /*   `Setting componentMappings ${form.getName()} to ${ */
            /*     elements.length */
            /*   } - ${JSON.stringify(newComponentMapping, null, 4)}`, */
            /* ); */
          }
        }
      });
    } else {
      elements.push(<ErrorView />);
      newComponentMapping['forms'] = elements.length;
    }

    elements.push(<QuoteReview componentMapping={componentMapping} />);
    newComponentMapping['review'] = elements.length;
    // @ts-expect-error
    elements.push(<QuoteDone />);
    newComponentMapping['done'] = elements.length;
    setWizardComponents(elements);
    setComponentMapping((oldComponentMapping) =>
      areMappingsIdentical(newComponentMapping, oldComponentMapping)
        ? oldComponentMapping
        : newComponentMapping,
    );
  }, [
    splitForms,
    allForms,
    insurType,
    blockContactInfoFirst,
    token,
    componentMapping,
    contactInformationFirst,
    existingQuoteUuid,
    lastSubmittedQuote?.status,
    lastSubmittedQuote?.uniqueID,
    sendEmailOnContactInformationCompleted,
  ]);

  let quote_jsx = (
    <QuoteWizardWhiteLabel>
      <StepWizard
        instance={(instance) => {
          dispatch(
            updateStepWizardState({
              // TODO This actually seems concerning - probably worth a closer look
              // @ts-expect-error
              goToStep: instance.goToStep,
            }),
          );
          // @ts-expect-error
          setInternalGoToStep(() => instance.goToStep);
        }}
        isLazyMount
        nav={<Nav componentMapping={componentMapping} />}
      >
        {/* 
      // @ts-expect-error */}
        {wizardComponents}
        {/*{/1* */}
        {/*<QuoteTypeSelection /> */}
        {/*{/1* @ts-expect-error */}
        {/*<QuotePolicySelection formsLoaded={allForms && allForms.length > 0} /> */}
        {/*{splitForms && splitForms.length > 0 ? ( */}
        {/*  splitForms.map((form, index) => { */}
        {/*    const isContactInformationForm = !!form.questionInstances.find( */}
        {/*      (question: $TSFixMe) => */}
        {/*        question.apiName === 'client_contact_info', */}
        {/*    ); */}
        {/*    const contactEmailRelevant = */}
        {/*      isContactInformationForm && */}
        {/*      sendEmailOnContactInformationCompleted && */}
        {/*      !token; */}
        {/*    return ( */}
        {/*      <QuoteForm */}
        {/*        form={form} */}
        {/*        path={`/${index}`} */}
        {/*        sendContactEmailOnSubmit={contactEmailRelevant} */}
        {/*      /> */}
        {/*    ); */}
        {/*  }) */}
        {/*) : ( */}
        {/*  <ErrorView /> */}
        {/*)} */}
        {/*{ */}
        {/*  /* */}
        {/*{/1* @ts-expect-error */}
        {/*} */}
        {/*<QuoteReview componentMapping={componentMapping} /> */}
        {/*{/1* */}
        {/*    null */}
        {/*<QuoteDone /> */}
      </StepWizard>
    </QuoteWizardWhiteLabel>
  );

  if (slug === 'vago') {
    // @ts-expect-error
    return <Authenticated>{quote_jsx}</Authenticated>;
  } else {
    return <>{quote_jsx}</>;
  }
};

export default QuoteWizard;
