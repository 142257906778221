import type { $TSFixMe } from '@calefy-inc/utilityTypes';

export const splitIntoTopLevelGroupsOfSize = (
  questionsList: $TSFixMe,
  size: number,
) => {
  const splitQuestions = [];
  let currentGroup = [];
  for (let i = 0; i < questionsList.length; i++) {
    const currentQuestion = questionsList[i];
    currentGroup.push(currentQuestion);

    // potentially start a new list
    if (currentGroup.length === size || i === questionsList.length - 1) {
      splitQuestions.push(currentGroup);
      currentGroup = [];
    }
  }
  return splitQuestions;
};

/**
 * Given a question, determine the total number of questions that will be displayed when that one is (that question + subquestions)
 */
export const determineTotalQuestions = (question: $TSFixMe) => {
  let questionSoFar = 1;
  if (!question.subQuestions || question.subQuestions.length === 0) {
    return questionSoFar;
  }
  return question.subQuestions.reduce(
    (totalQuestions: number, currentQuestion: $TSFixMe) => {
      totalQuestions += determineTotalQuestions(currentQuestion);
      return totalQuestions;
    },
    questionSoFar,
  );
};

/**
 * Split a list of questions into a list of lists of questions of the indicated size, where each sublist has approximately size number of questions (including subquestions)
 */
export const splitIntoGroupsOfSize = (
  questionsList: Array<$TSFixMe>,
  size: number | 'all',
) => {
  if (typeof size !== 'number' && size !== 'all') {
    throw new Error(
      `Invalid input ${size} of type ${typeof size} passed to splitIntoGroupsOfSize function as size; expected a number or the special string "all"`,
    );
  }
  if (size === 'all') {
    return [questionsList];
  }
  const splitQuestions = [];
  let currentGroup = [];
  let combinedCurrentGroupWeight = 0;
  for (let i = 0; i < questionsList.length; i++) {
    const currentQuestion = questionsList[i];
    const currentQuestionWeight = determineTotalQuestions(currentQuestion);
    if (
      currentGroup.length === 0 ||
      combinedCurrentGroupWeight + currentQuestionWeight <= size
    ) {
      currentGroup.push(currentQuestion);
      combinedCurrentGroupWeight += currentQuestionWeight;
    } else {
      splitQuestions.push(currentGroup);
      currentGroup = [currentQuestion];
      combinedCurrentGroupWeight = currentQuestionWeight;
    }
    if (i === questionsList.length - 1) {
      splitQuestions.push(currentGroup);
      currentGroup = [];
      combinedCurrentGroupWeight = 0;
    }
  }
  return splitQuestions;
};

/**
 * Given a stepnumber with corresponds to oldComponentMapping, find the equivalent step number in newComponentMapping (go to earliest step)
 */
export function findEquivalentStep(
  oldComponentMapping: $TSFixMe,
  newComponentMapping: $TSFixMe,
  step: $TSFixMe,
) {
  const oldStepKey = Object.keys(oldComponentMapping).reduce(
    (acc: $TSFixMe, key: $TSFixMe) => {
      if (!acc && oldComponentMapping[key] <= step) {
        return key;
      } else if (
        oldComponentMapping[key] > oldComponentMapping[acc] &&
        oldComponentMapping[key] <= step
      ) {
        return key;
      }
      return acc;
    },
    null,
  );
  const equivalent = newComponentMapping[oldStepKey]
    ? newComponentMapping[oldStepKey]
    : 3;
  return equivalent;
}

/**
 * Tests whether two componentMappings are functionally identical
 */
export const areMappingsIdentical = (
  oldComponentMapping: $TSFixMe,
  newComponentMapping: $TSFixMe,
) => {
  if (
    Object.keys(oldComponentMapping).length !==
    Object.keys(newComponentMapping).length
  ) {
    return false;
  }
  if (
    Object.keys(oldComponentMapping).some(
      (key) => newComponentMapping[key] !== oldComponentMapping[key],
    )
  ) {
    return false;
  }
  return true;
};
