import { FinalFormModifier } from '../../../../../classes';
import { ValidationFunction } from '../types';
import { validateQuestionInstances } from '../../../../../../../../../FormManager/FormBuilder/components/validateForm/validationFunctions';

export const validateFormModifierQuestionInstances: ValidationFunction = (
  form: FinalFormModifier,
) => {
  if (form.questionInstances.length === 0) {
    return [];
  }
  return validateQuestionInstances(form);
};
