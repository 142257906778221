import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from '@reach/router';

// classes and types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';

interface BaseInvalidUUIDErrorProps {
  uuid: string;
  classes: GenericObject;
}
export const BaseInvalidUUIDError = ({
  uuid,
  classes = {},
}: BaseInvalidUUIDErrorProps) => {
  const navigate = useNavigate();
  const quoteLocation = '/insurtech/quote';

  return (
    <div className={classes.outerContainer} data-testid='Invalid UUID Error'>
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          Invalid UUID
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          The unique identifier that you provided ({uuid}) is not valid. Please
          double check that you used the right link to get here. If you believe
          that you are receiving this message in error, please contact us at{' '}
          <a
            href={`mailto:support@calefy.ca?subject=Application ${uuid} error`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>

        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => navigate(quoteLocation)}
        >
          Return to Applications
        </Button>
      </div>
    </div>
  );
};

export const InvalidUUIDError = withStyles((theme) => ({
  outerContainer: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <BaseInvalidUUIDError {...props} />);
