import { graphql } from '../gql';

export const BusinessSpecificFormQuery = graphql(`
  query getBusinessSpecificForms($businessId: ID!) {
    someFinalForms(business: $businessId) {
      required
      id
      questionInstances {
        ...QuestionInstanceFields
        subQuestions {
          ...QuestionInstanceFields
          subQuestions {
            ...QuestionInstanceFields
            subQuestions {
              ...QuestionInstanceFields
              subQuestions {
                ...QuestionInstanceFields
                subQuestions {
                  ...QuestionInstanceFields
                  subQuestions {
                    ...QuestionInstanceFields
                    subQuestions {
                      ...QuestionInstanceFields
                      subQuestions {
                        ...QuestionInstanceFields
                        subQuestions {
                          ...QuestionInstanceFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      qualifyingQuestion {
        ...QuestionInstanceFields
      }
      businessLine {
        id
        displayName
        internalName
        industry {
          id
          displayName
        }
      }
      policy {
        id
        displayName
        internalName
      }
      policyExtension {
        label
        value
        questionId
        multiplier
      }
    }
  }
`);
