import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Typography,
  Stack,
} from '@mui/material';
import { VagoClientSummary } from './VagoClientSummary';
import { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // @ts-expect-error
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    // @ts-expect-error
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

interface VagoQuoteListToolbarProps {
  filterName: $TSFixMe;
  onFilterName: $TSFixMe;
  isLoading: boolean;
  generatingPdf: boolean;
}
export function VagoQuoteListToolbar({
  filterName,
  onFilterName,
  isLoading,
  generatingPdf,
}: VagoQuoteListToolbarProps) {
  return (
    <RootStyle>
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder='Search applications...'
        aria-label='Search applications'
        startAdornment={
          <InputAdornment position='start'>
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: 'text.disabled' }}
            />
          </InputAdornment>
        }
      />
      <VagoClientSummary />
      {isLoading && (
        <CircularProgress size='1.5rem' data-testid='search-quotes-loading' />
      )}
      {generatingPdf && (
        <Stack direction={'row'} spacing={2}>
          <Typography color='textSecondary' variant='body2'>
            Generating Pdf
          </Typography>
          <CircularProgress size='1.5rem' />
        </Stack>
      )}
    </RootStyle>
  );
}
