import withStyles, { WithStyles } from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

const generateFormStyles = (theme: Theme) => {
  return createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
      margin: 'auto',
      '& h6': {
        marginTop: theme.spacing(5),
      },
      maxWidth: '60rem',
      // Smaller Screens
      [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
      },
      [theme.breakpoints.only('md')]: {
        padding: `${theme.spacing(3)} ${theme.spacing(12)}`,
        maxWidth: '50rem',
      },
    },
    dialogForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 0,
      // padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
      margin: 'auto',
      '& h6': {
        marginTop: theme.spacing(5),
      },
      maxWidth: '60rem',
      // Smaller Screens
      [theme.breakpoints.down('lg')]: {
        // padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
      },
      [theme.breakpoints.only('md')]: {
        // padding: `${theme.spacing(3)} ${theme.spacing(12)}`,
        maxWidth: '50rem',
      },
    },
    selectField: {
      marginTop: theme.spacing(2),
    },
    navButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: theme.spacing(3),
    },
    textfield: {
      display: 'flex',
      width: '100%',
      margin: `${theme.spacing(2)} 0`,
    },

    textarea: {
      display: 'flex',
      maxWidth: '100%',
      margin: `${theme.spacing(2)} 0`,
    },

    phonefield: {
      display: 'flex',
      maxWidth: '100%',
      margin: `${theme.spacing(2)} 0`,
    },

    emailinput: {
      display: 'flex',
      maxWidth: '100%',
      margin: `${theme.spacing(2)} 0`,
    },

    yesNoToggle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      margin: `${theme.spacing(2)} 0`,
    },

    obligateInputToggle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      margin: `${theme.spacing(2)} 0`,
    },

    yearPicker: {
      display: 'flex',
      maxWidth: '100%',
      margin: `${theme.spacing(2)} 0`,
    },
    calendar: {
      display: 'flex',
      maxWidth: '100%',
      margin: `${theme.spacing(2)} 0`,
    },
    addressContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: `${theme.spacing(2)} 0`,
      width: '100%',

      '& h6': {
        textAlign: 'center',
      },

      // Next policy button
      '& > button[type="button"]': {
        marginTop: theme.spacing(2),
        alignSelf: 'center',
      },
    },
    addressHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    sameAsMailingAddressButton: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignSelf: 'center',
      marginBottom: theme.spacing(2),
    },
    removeAddress: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    inputlabel: {
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
    button: {
      margin: `${theme.spacing(2)} 0`,
      alignSelf: 'center',
    },
    slider: {
      '& > label': {
        marginBottom: theme.spacing(1.5),
      },
      display: 'flex',
      marginBottom: theme.spacing(0.7),
      marginTop: theme.spacing(2),
      maxWidth: '100%',
    },
    slidergroup: {
      display: 'flex',
      flexDirection: 'column',
      // padding: theme.spacing(3),
      margin: `${theme.spacing(2)} 0`,
      maxWidth: '100%',
      '& > label': {
        margin: `${theme.spacing(2)} 0`,
      },
    },
    center: {
      alignSelf: 'center',
    },
    divider: {
      margin: `${theme.spacing(7)} 0`,
    },
    start: {
      alignSelf: 'flex-start',
    },
    util: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      maxWidth: '100%',
    },
  });
};

export type FormStyles = ReturnType<typeof generateFormStyles>;
export type WithFormStyles<T = {}> = WithStyles<FormStyles & T>;

/**
 * Higher-order function over withStyles which automatically injects the classes required to display a form / InformedMaterial components / RenderedQuestionInstance
 * @param overrides - Either a styles object or a function taking the theme and returning a styles object. Can also be left undefined to just get the form styles
 * @example
 * Only base styles
 * const MyComponent = (props) => <div className={props.classes.someClass}>something</div>
 * export StyledComponent = withFormStyles()(MyComponent)
 * @example
 * const MyComponent = (props) => <div className={props.classes.someClass}>something</div>
 * export StyledComponent = withStyles({someClass: {textAlign: 'center'}})(MyComponent)
 * @example
 * const MyComponent = (props) => <div className={props.classes.someClass}>something</div>
 * export StyledComponent = withStyles((theme) => ( {someClass: {color: theme.palette.main}} ))(MyComponent)
 */
export const withFormStyles = (
  overrides: object | ((theme: Theme) => object) = {},
) => {
  return withStyles((theme) => ({
    ...generateFormStyles(theme),
    ...(typeof overrides === 'object' ? overrides : overrides(theme)),
  }));
};
