import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from '@reach/router';

// classes and types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';

interface BaseInvalidRenewalStatusErrorProps {
  uuid: string;
  classes: GenericObject;
}
export const BaseInvalidRenewalStatusError = ({
  uuid,
  classes = {},
}: BaseInvalidRenewalStatusErrorProps) => {
  const navigate = useNavigate();
  const quoteLocation = '/insurtech/manager/quotes';
  return (
    <div
      className={classes.outerContainer}
      data-testid='invalidRenewalStatusError'
    >
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          Invalid Status
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          The application which you requested with ID {uuid} cannot be renewed
          at the current time as it has an invalid status. This usually means
          that it has already been renewed or has been saved for later. If you
          believe that this is an error, please contact your agent or contact
          Calefy support at
          <a
            href={`mailto:support@calefy.ca?subject=Unable to Renew Application ${uuid} Due to Invalid Status`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>

        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => navigate(quoteLocation)}
        >
          Return to Applications
        </Button>
      </div>
    </div>
  );
};

export const InvalidRenewalStatusError = withStyles((theme) => ({
  outerContainer: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <BaseInvalidRenewalStatusError {...props} />);
