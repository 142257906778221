// @ts-nocheck
import { useState, useEffect } from 'react';
import { ProgramBuilderQuestionInstance } from '../components/FormManager/classes';
import { useAllowByPermission } from './useAllowByPermissions';
import { useAllowByQuestionEditPermissions } from './useAllowByQuestionEditPermissions';

interface QuestionPermissionType {
  specific?: boolean; // can they only edit a limited subset of questions, not the entire form?
  global?: boolean; // can they edit the entire form?
}
interface ToReturn extends QuestionPermissionType {
  canEdit?: boolean;
}
export const useCanEditQuestion = (
  question: ProgramBuilderQuestionInstance | null | undefined,
) => {
  const [toReturn, setToReturn] = useState<ToReturn>({
    canEdit: undefined,
    global: undefined,
    specific: undefined,
  });
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [permissionType, setPermissionType] = useState<QuestionPermissionType>({
    specific: undefined,
    global: undefined,
  });
  const hasFormEditPermission = useAllowByPermission('edit:forms');
  const { apiNames: editableApiNames } = useAllowByQuestionEditPermissions();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasFormEditPermission) {
        setCanEdit(true);
        setPermissionType({ specific: false, global: true });
      } else if (question) {
        const editable = editableApiNames.includes(question.apiName);
        setCanEdit(editable);
        setPermissionType({
          global: false,
          specific: editable,
        });
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [hasFormEditPermission, editableApiNames]);

  // update to return
  useEffect(() => {
    setToReturn({
      canEdit,
      ...permissionType,
    });
  }, [permissionType, canEdit]);

  return toReturn;
};
