import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { QuestionAnswerDisplay } from '../../../../../common/QuestionAnswerDisplay';

//------------------------------------------------------------------------------------
const DLStyle = styled('dl')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '[question] 2fr [answer] 2fr',
  columnGap: theme.spacing(5),
  '& dt': {
    display: 'inline',
    ...theme.typography.body2,
  },
  '& dd': {
    display: 'inline-flex',
    flexDirection: 'row',
    ...theme.typography.body2,
    // justifyContent: 'flex-start',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  '& dd:after': {
    content: '" "',
    display: 'block',
  },
}));

//------------------------------------------------------------------------------------
export default function CollapsibleTable({
  form,
  index,
  quoteStatus,
  renewalOnly,
}) {
  const [open, setOpen] = useState(false);
  const formAnswers = form.answers;
  return (
    <>
      <TableRow
        key={index}
        sx={{
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <TableCell align='left'>
          <Box sx={{ maxWidth: 560 }}>
            <Typography variant='subtitle2'>{form.label()}</Typography>
          </Box>
        </TableCell>

        <TableCell align='center'>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            <Icon icon={open ? arrowIosUpwardFill : arrowIosDownwardFill} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DLStyle>
                {formAnswers.map((answer, index, answers) => {
                  if (
                    (quoteStatus === 'RENEWED' ||
                      quoteStatus === 'INCOMPLETE_RENEWAL' ||
                      quoteStatus === 'RENEWAL_IN_PROGRESS') &&
                    !answer.askOnRenewal &&
                    renewalOnly
                  ) {
                    return null;
                  }
                  return (
                    <QuestionAnswerDisplay
                      answer={answer}
                      answers={answers}
                      indent={3}
                      key={answer.id}
                    />
                  );
                })}
              </DLStyle>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
