import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { useFormApi, useFormState } from 'informed';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import {
  getMailingAddressFromFormStateAnswers,
  mailingAddressRegex,
} from './utility';

// classes and types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { BasicAddress } from '../../../../Typescript';
import type {
  QuoteWizardQuestionInstance,
  QuoteWizardAnswerInstance,
} from '../../../QuoteWizard/classes';
/* import { UnifiedAnswerInstance } from '../../../../Typescript/classes'; */
import { StoreState } from '../../../../store';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../../util';

/**
 * A button to fill the location question with the address components (street address, city, province, postal code) of the Mailing Address question
 * @param mailingAddress - The mailing address found in the Redux state. Shape: {
 *   address: string | undefined,
 *   city: string | undefined,
 *   province: string | undefined,
 *   postal: string | undefined
 * }
 * @param fillFields - the names of the fields (in the informed FormState) to be filled in. Shape: {
 *   address: string | undefined,
 *   city: string | undefined,
 *   province: string | undefined,
 *   postalCode: string | undefined
 * }
 * @param questionInstanceApiName - The name of the current location question being rendered; used to check that it is not itself the mailing address question
 * @param classes - The theming classes
 */
export interface SameAsMailingAddressButtonProps {
  mailingAddress?: BasicAddress;
  fillFields: {
    address?: string;
    city?: string;
    province?: string;
    postalCode?: string;
  };
  questionInstanceApiName?: QuoteWizardQuestionInstance['apiName'];
  classes?: GenericObject;
}
export const UnconnectedSameAsMailingAddressButton = ({
  mailingAddress,
  fillFields,
  questionInstanceApiName,
  classes = {},
}: SameAsMailingAddressButtonProps) => {
  const formApi = useFormApi();
  const formState = useFormState();

  let mailingAddressInForm;
  try {
    mailingAddressInForm = getMailingAddressFromFormStateAnswers(
      formState.values,
    );
  } catch (e) {
    console.error('Error getting mailing address from form:', e);
    Bugsnag.notify(errorify(e));
  }
  const actualMailingAddress = mailingAddressInForm
    ? mailingAddressInForm
    : mailingAddress;

  if (
    !actualMailingAddress ||
    !questionInstanceApiName ||
    mailingAddressRegex.exec(questionInstanceApiName)
  ) {
    return null;
  }
  const formattedAddress = actualMailingAddress
    ? [
        actualMailingAddress.address,
        actualMailingAddress.city,
        actualMailingAddress.province,
        actualMailingAddress.postal,
      ]
        .filter((elem) => elem && elem !== 'N/A')
        .join(', ')
    : null;

  // check if the fields are already filled in with the values from the mailing address
  const addressFieldName = fillFields.address;
  const cityFieldName = fillFields.city;
  const provinceFieldName = fillFields.province;
  const postalFieldName = fillFields.postalCode;

  let isUsingMailingAddress = false; // if the current field already matches the mailing address
  if (
    actualMailingAddress &&
    // @ts-expect-error
    formApi.getValue(addressFieldName) === actualMailingAddress.address &&
    // @ts-expect-error
    formApi.getValue(cityFieldName) === actualMailingAddress.city &&
    // @ts-expect-error
    formApi.getValue(provinceFieldName) === actualMailingAddress.province &&
    // @ts-expect-error
    formApi.getValue(postalFieldName) === actualMailingAddress.postal
  ) {
    isUsingMailingAddress = true;
  } else {
    isUsingMailingAddress = false;
  }

  const buttonText = isUsingMailingAddress
    ? 'Already Using Mailing Address'
    : 'Use Mailing Address';

  const handleClick = () => {
    if (!formApi) {
      console.error('No valid form api; exiting');
      return;
    }
    if (!actualMailingAddress) {
      console.error('No valid mailing address; exiting');
      return;
    }

    // now fill in the fields
    // TODO this is so stupid. Why is it postal in one and postalCode in the other? What was I thinking. Honestly.
    for (let fieldName of ['address', 'city', 'province']) {
      // @ts-expect-error
      formApi.setValue(fillFields[fieldName], actualMailingAddress[fieldName]);
      // @ts-expect-error
      formApi.setTouched(fillFields[fieldName], true);
    }
    // (and now the postal code)
    // @ts-expect-error
    formApi.setValue(fillFields['postalCode'], actualMailingAddress.postal);
    // @ts-expect-error
    formApi.setTouched(fillFields['postalCode'], true);
  };
  return (
    <Tooltip title={formattedAddress || 'No mailing address defined'}>
      <Button
        className={classes.sameAsMailingAddressButton}
        variant='outlined'
        type='button'
        disabled={isUsingMailingAddress}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

const recursiveMailingAddressSearch = (
  answersList: Array<QuoteWizardAnswerInstance>,
): QuoteWizardAnswerInstance | undefined => {
  if (answersList.length === 0) {
    return;
  }
  for (let answer of answersList) {
    if (
      answer.questionInstance.component === 'location' &&
      mailingAddressRegex.exec(answer.questionInstance.apiName)
    ) {
      return answer;
    }
    const foundInSubAnswers = recursiveMailingAddressSearch(answer.subAnswers);
    if (foundInSubAnswers) {
      return foundInSubAnswers;
    }
  }
  return undefined;
};

const mapStateToProps = (state: StoreState) => {
  const formsWithAnswers = Object.values(state.quoteWizard.formAnswers);
  let mailingAddress;
  let mailingAddressAnswer;
  for (let form of formsWithAnswers) {
    mailingAddressAnswer = recursiveMailingAddressSearch(form.answers);
    if (mailingAddressAnswer) {
      break;
    }
  }
  if (mailingAddressAnswer) {
    const addressAnswer = mailingAddressAnswer.subAnswers.find(
      (subAnswer) => subAnswer.questionInstance.apiName === 'address',
    );
    const cityAnswer = mailingAddressAnswer.subAnswers.find(
      (subAnswer) => subAnswer.questionInstance.apiName === 'city',
    );
    const provinceAnswer = mailingAddressAnswer.subAnswers.find(
      (subAnswer) => subAnswer.questionInstance.apiName === 'province',
    );
    const postalAnswer = mailingAddressAnswer.subAnswers.find(
      (subAnswer) => subAnswer.questionInstance.apiName === 'postal',
    );
    mailingAddress = {
      address: addressAnswer ? addressAnswer.value : undefined,
      city: cityAnswer ? cityAnswer.value : undefined,
      province: provinceAnswer ? provinceAnswer.value : undefined,
      postal: postalAnswer ? postalAnswer.value : undefined,
    };
  }

  return {
    mailingAddress,
  };
};
const ConnectedSameAsMailingAddressButton = connect(
  mapStateToProps,
  null,
)((props) => <UnconnectedSameAsMailingAddressButton {...props} />);

export const SameAsMailingAddressButton = withStyles((_theme: $TSFixMe) => ({
  sameAsMailingAddressButton: {
    //marginBottom: theme.spacing(2),
  },
  // @ts-expect-error
}))((props: $TSFixMe) => <ConnectedSameAsMailingAddressButton {...props} />);
