import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  ComponentInterface,
  QuoteComponentProps,
  ProgramBuilderComponentProps,
  QuoteComponentValidationFunction,
  QuoteComponentValidationFunctionReturn,
} from '../types';
import type { ProgramBuilderQuestionInstance } from '../../../FormManager/classes';

/* Default values */
export function defaultProgramBuilderComponent() {
  return null;
}

export const defaultDataType = 'default';

export const defaultGatherSubQuestions = (
  _formState: $TSFixMe,
  questionInstance: ProgramBuilderQuestionInstance,
) => {
  return questionInstance;
};

export function defaultProgramBuilderValidation(
  _questionInstance: ProgramBuilderQuestionInstance,
): QuoteComponentValidationFunctionReturn {
  return [];
}

export const defaultIcon = () => {
  return null;
};

/* Related types */
// the object passed into the constructor must have the type, typeLabel, and quoteComponent attributes; the rest are optional
type ComponentConstructorArgument = Required<{
  type: ComponentInterface['type'];
  typeLabel: ComponentInterface['typeLabel'];
  quoteComponent: ComponentInterface['quoteComponent'];
}> &
  Partial<ComponentInterface>;

/**
 * This validates that the constructor argument is correct. In theory it is unnecessary, but since we might be calling the constructor from a JS file we can't always rely on the typing to save us. Throws an error if a required attribute is missing; does nothing if it is all good.
 */
function validateConstructorArgument(arg: ComponentConstructorArgument): void {
  const requiredAttributes: Array<keyof ComponentInterface> = [
    'type',
    'quoteComponent',
  ];
  requiredAttributes.forEach((attribute) => {
    if (!arg[attribute]) {
      throw new Error(
        `Component constructor missing required argument '${attribute}': ${JSON.stringify(
          arg,
          null,
          4,
        )}`,
      );
    }
  });
}

/**
 * A QuoteComponent
 *
 * @param type - A slug for the question type
 * @param typeLabel - The label for this question type to display in the ProgramBuilder
 * @param quoteComponent - The component to display in the QuoteWizard
 * @param programBuilderComponent - The extra questions and whatnot to be displayed on the question instance form (used for adding extra questions or information)
 * @param dataType - ?????
 * @param gatherSubQuestions - Function used to finalize / add any default subquestions or to modify the FormState (e.g. for Logic questions to move the conditional question from being a regular form question to being a subquestion). Returns a ProgramBuilderQuestionInstance
 * @param programBuilderValidation - A function used to validate that the current question is valid. It does not need to recurse on its own (but may in the case of certain question types)
 * @param onComponentSave - ???????
 * @param initialSubQuestions - ???????
 * @param icon - Icon component type to be displayed next to question for form editor
 */
export class Component implements ComponentInterface {
  type: string;
  typeLabel: string;
  quoteComponent: (props: QuoteComponentProps) => JSX.Element;
  programBuilderComponent: (
    props: ProgramBuilderComponentProps,
  ) => JSX.Element | null = defaultProgramBuilderComponent;
  dataType: string;
  gatherSubQuestions: (
    formState: $TSFixMe,
    questionInstance: ProgramBuilderQuestionInstance,
  ) => ProgramBuilderQuestionInstance = defaultGatherSubQuestions;
  programBuilderValidation: QuoteComponentValidationFunction =
    defaultProgramBuilderValidation;
  onComponentSave: undefined | ((arg: $TSFixMe) => $TSFixMe);
  initialSubQuestions: undefined | $TSFixMe;
  icon?: (() => null) | (() => JSX.Element);

  constructor(args: ComponentConstructorArgument) {
    validateConstructorArgument(args);

    const {
      type,
      typeLabel,
      quoteComponent,
      programBuilderComponent,
      dataType,
      gatherSubQuestions,
      programBuilderValidation,
      onComponentSave,
      initialSubQuestions,
      icon,
    } = args;

    this.type = type;
    this.typeLabel = typeLabel ? typeLabel : type;
    this.quoteComponent = quoteComponent;
    this.programBuilderComponent = programBuilderComponent
      ? programBuilderComponent
      : defaultProgramBuilderComponent;
    this.dataType = dataType ? dataType : defaultDataType;
    this.gatherSubQuestions = gatherSubQuestions
      ? gatherSubQuestions
      : defaultGatherSubQuestions;
    this.programBuilderValidation = programBuilderValidation
      ? programBuilderValidation
      : defaultProgramBuilderValidation;
    this.onComponentSave = onComponentSave;
    this.initialSubQuestions = initialSubQuestions;
    this.icon = icon ? icon : defaultIcon;
  }
}
