import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    outerContainer: {
      margin: `${theme.spacing(2)} 0`,
      justifyContent: 'center',
      alignSelf: 'center',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '40rem',
      minHeight: '60vh',
      margin: 'auto',
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
      textAlign: 'center',
    },
    content: {
      textAlign: 'left',
      marginBottom: theme.spacing(2),
    },
  };
});

export const NoAvailablePolicies = ({ previousStep }) => {
  const classes = useStyles();
  return (
    <div data-testid='NoAvailablePolicies'>
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          No Available Policies
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          Unfortunately, there are no policies available for this line. If you
          believe that you are receiving this message in error, please contact
          us at{' '}
          <a
            href={`mailto:support@calefy.ca?subject=No Policy Forms Available`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>
        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => previousStep()}
        >
          Return to line Selection
        </Button>
      </div>
    </div>
  );
};
