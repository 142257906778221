import { RouteComponentProps } from '@reach/router';
import { ALL_STATISTICAL_SESSIONS } from '../../queries';
import { useLazyQuery } from '@apollo/client';
import { useAuth } from '@calefy-inc/authentication';
import Button from '@mui/material/Button';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { styled } from '@mui/material';
import { OrganizationSelect } from './components/OrganizationSelect';
import { Form } from '@calefy-inc/informedMaterial';
import Grid from '@mui/material/Grid';
import { withFormStyles, WithFormStyles } from '../../util/withFormStyles';

interface StatisticalEventDisplay {
  business: string;
  sessionId: string;
  created: string;
  type: string;
  data: string;
  userEmail: string | null;
  quote: string | null;
}
interface AnalyticsProps extends RouteComponentProps, WithFormStyles {}
export const Analytics = withFormStyles()(({ classes }: AnalyticsProps) => {
  const [getSessions, { loading, error, data }] = useLazyQuery(
    ALL_STATISTICAL_SESSIONS,
  );
  const { token } = useAuth();

  return (
    <>
      <h2>Analytics</h2>
      <SearchContainer>
        <Form
          className={classes.form}
          // @ts-expect-error
          onSubmit={(values) => {
            const { organization } = values;
            getSessions({
              variables: {
                token,
                organization,
              },
            });
          }}
        >
          <FilterContainer>
            <Grid container xs={12}>
              {/*
            // @ts-expect-error */}
              <OrganizationSelect
                field='organization'
                fullWidth
                label='Organization'
                className={classes.selectField}
                additionalOptions={[{ value: '', label: 'All' }]}
              />
            </Grid>
          </FilterContainer>
          <ButtonContainer>
            <Button variant='outlined' type='submit' disabled={!token}>
              Search
            </Button>
          </ButtonContainer>
        </Form>
      </SearchContainer>
      <ResultsContainer>
        {loading ? <p>Loading...</p> : null}
        {error ? (
          <>
            <p>Error getting sessions:</p>
            <pre>{JSON.stringify(error, null, 4)}</pre>
          </>
        ) : null}
        {data && data.allStatisticalSessions?.length === 0 ? (
          <p>No results found</p>
        ) : null}
        {data?.allStatisticalSessions &&
        data.allStatisticalSessions?.length > 0 ? (
          <div data-testid='analytics'>
            <button
              type='button'
              onClick={() => {
                const csv =
                  'data:text/csv;charset=utf-8,' +
                  `business,session_id,time_created,type,data,user_email,quote_unique_id\n` +
                  (data?.allStatisticalSessions
                    ? data.allStatisticalSessions
                        .reduce(
                          (
                            acc: Array<StatisticalEventDisplay>,
                            session: $TSFixMe,
                          ) => {
                            session.events.forEach((event: $TSFixMe) => {
                              acc.push({
                                business: session?.organization?.name,
                                sessionId: session.sessionId,
                                created: event.timeCreated,
                                type: event.eventType,
                                data: event.data,
                                userEmail: event.userEmail,
                                quote: event?.quote?.uniqueID,
                              });
                            });
                            return acc;
                          },
                          [],
                        )
                        .map((displayEvent: StatisticalEventDisplay) =>
                          [
                            displayEvent.business
                              ? `"${displayEvent.business}"`
                              : '',
                            `"${displayEvent.sessionId}"`,
                            `"${displayEvent.created}"`,
                            `"${displayEvent.type}"`,
                            `"${JSON.stringify(displayEvent.data)
                              .replace(/'/g, "''")
                              .replace(/"/g, '""')}"`,
                            displayEvent.userEmail
                              ? `"${displayEvent.userEmail}"`
                              : '',
                            displayEvent.quote ? `"${displayEvent.quote}"` : '',
                          ].join(','),
                        )
                        .join('\n')
                    : '');
                const uri = window.encodeURI(csv);
                window.open(uri);
              }}
            >
              Download
            </button>
            <table
              style={{
                border: 'solid 1px black',
              }}
            >
              <thead>
                <tr>
                  <th scope='row'>Organization</th>
                  <th scope='row'>Session ID</th>
                  <th scope='row'>Created</th>
                  <th scope='row'>Type</th>
                  <th scope='row'>Data</th>
                  <th scope='row'>User</th>
                  <th scope='row'>Quote ID</th>
                </tr>
              </thead>
              <tbody>
                {data?.allStatisticalSessions
                  ? data.allStatisticalSessions
                      .reduce(
                        (
                          acc: Array<StatisticalEventDisplay>,
                          session: $TSFixMe,
                        ) => {
                          session.events.forEach((event: $TSFixMe) => {
                            acc.push({
                              business: session?.organization?.name,
                              sessionId: session.sessionId,
                              created: event.timeCreated,
                              type: event.eventType,
                              data: event.data,
                              userEmail: event.userEmail,
                              quote: event?.quote?.uniqueID,
                            });
                          });
                          return acc;
                        },
                        [],
                      )
                      .map((displayEvent: StatisticalEventDisplay) => (
                        <tr>
                          <td>{displayEvent.business}</td>
                          <td>{`${displayEvent.sessionId.slice(
                            0,
                            6,
                          )}...${displayEvent.sessionId.slice(-6)}`}</td>
                          <td>{displayEvent.created}</td>
                          <td
                            style={{
                              color:
                                displayEvent.type === 'APPLICATION_BEGUN'
                                  ? 'red'
                                  : displayEvent.type === 'APPLICATION_SAVED'
                                  ? 'orange'
                                  : displayEvent.type === 'APPLICATION_RESUMED'
                                  ? 'yellow'
                                  : 'green',
                            }}
                          >
                            {displayEvent.type}
                          </td>
                          <td>{displayEvent.data}</td>
                          <td>{displayEvent.userEmail}</td>
                          <td>
                            {displayEvent.quote
                              ? `${displayEvent.quote.slice(
                                  0,
                                  6,
                                )}...${displayEvent.quote.slice(-6)}`
                              : null}
                          </td>
                        </tr>
                      ))
                  : null}
              </tbody>
            </table>
          </div>
        ) : null}
      </ResultsContainer>
    </>
  );
});

const FilterContainer = styled('div')();
const SearchContainer = styled('div')();
const ResultsContainer = styled('div')();
const ButtonContainer = styled('div')();
