import { useSnackbar } from 'notistack';
// @ts-expect-error
import { Form, ArrayField, Relevant } from 'informed';
// Informed Material
import { TextField, Select } from '@calefy-inc/informedMaterial';
// Material Ui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// Redux
import { connect, useDispatch } from 'react-redux';
import {
  createExtensions,
  updateExtensions,
} from '../../../../store/FormStore';
// Store
import { formStore } from '../../../../store';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../../classes';

/* Style*/
const ExtensionsWithStyles = withStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    margin: 'auto',
    maxWidth: '60rem',
    '& > h6': {
      marginTop: 0,
      alignSelf: 'center',
    },
  },

  formContents: {
    maxWidth: '50rem',
    width: '100%',
    overflow: 'auto',
  },
  textField: {
    width: '100%',
    marginBottom: 0,
  },
  selectField: {
    width: '100%',
    // marginLeft: theme.spacing(3),
    marginBottom: 0,
    '& > label': {
      ...theme.typography.label,
      marginBottom: theme.spacing(1),
    },
  },
  buttonContainer: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1.5),
  },
  button: {
    margin: `${theme.spacing(2)} 0`,
    alignSelf: 'center',
  },
}))((props: $TSFixMe) => <Extensions {...props} />);

/* Constants */

/* Helper Functions */
const validateForm = (values: $TSFixMe) => {
  let badExtension;

  if (values.props_blob_extensions) {
    badExtension = values.props_blob_extensions.map(
      (extension: $TSFixMe) => !extension.value && !extension.questionId,
    );
  } else {
    return undefined;
  }

  if (badExtension.includes(true)) {
    return 'Error';
  } else {
    return undefined;
  }
};

const validateMultiplier = (value: $TSFixMe) => {
  return !value || isNaN(value) || value < 0 || value > 10
    ? 'Multiplier must be a number from 0 to 10.'
    : undefined;
};

/**
 * Flattens all question instances and subquestions by adding parentId
 * to questions with parents(unlike batman).
 * @param questions - Array of question instances
 * @param parent - parent of current question instance
 * @param result - final results
 */
function flattenTree(
  questions: Array<ProgramBuilderQuestionInstance>,
  parent: ProgramBuilderQuestionInstance | null = null,
  result: Array<ProgramBuilderQuestionInstance> = [],
  numParent: number = 0,
): Array<ProgramBuilderQuestionInstance> {
  questions.forEach((question) => {
    if (question.propsBlob.repeatable) {
      // do nothing
    } else {
      const flattenedQuestion = question.copy();
      flattenedQuestion.updateAncillary({ numParent });
      if (parent) {
        flattenedQuestion.updateAncillary({ parentId: parent.id });
      }
      result.push(flattenedQuestion);
      /* parent */
      /*   ? result.push({ */
      /*       ...question, */
      /*       // @ts-expect-error */
      /*       parentId: parent.id, */
      /*       numParent, */
      /*     }) */
      /*   : // @ts-expect-error */
      /*     result.push({ ...question, numParent }); */
      if (question.subQuestions) {
        flattenTree(question.subQuestions, question, result, numParent + 1);
      }
    }
  });
  return result;
}

function groupPropProperties(formState: $TSFixMe): $TSFixMe {
  const propsBlob = Object.entries(formState).reduce((acc, [key, value]) => {
    const propsBlobRegex = /^props_blob_(.+)/;

    const regexMatch = propsBlobRegex.exec(key);

    if (!regexMatch) {
      return acc;
    }

    const capturedPropertyName = regexMatch[1];

    // This will strip the props_blob prefix from the key, and store it in this new obj
    return {
      ...acc,
      [capturedPropertyName]: value,
    };
  }, {});
  return propsBlob;
}

/* Component */

type ExtensionsProps = {
  parentForm: ProgramBuilderForm;
  policyExtensions: $TSFixMe;
  isEditing?: boolean;
  onExtensionsCreated?: $TSFixMe;
  onExtensionsUpdated?: $TSFixMe;
  newExtensionsId?: $TSFixMe;
  questionInstances: $TSFixMe;
  classes?: $TSFixMe;
};
const Extensions = ({
  parentForm,
  policyExtensions,
  isEditing,
  // @ts-expect-error
  onExtensionsCreated,
  // @ts-expect-error
  onExtensionsUpdated,
  newExtensionsId,
  // @ts-expect-error
  questionInstances,
  classes = {},
}: ExtensionsProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let extensionsProps = {};

  if (policyExtensions) {
    extensionsProps = {
      props_blob_extensions: policyExtensions,
    };
  }

  let questionTree: Array<ProgramBuilderQuestionInstance> = [];
  let options: Array<{
    value:
      | ProgramBuilderQuestionInstance['id']
      | ProgramBuilderQuestionInstance['clientId'];
    label: ReturnType<ProgramBuilderQuestionInstance['generateLabel']>;
  }> = [];

  if (parentForm.questionInstances) {
    questionTree = flattenTree(parentForm.questionInstances);
    options = questionTree.map((question) => ({
      value: question.id ? question.id : question.clientId,
      label: question.generateLabel(),
    }));
  }

  /*console.log('Extensions: initialValues:', extensionsProps); */
  /*console.log('Extensions: options:', options); */

  return (
    <div className={classes.formContents}>
      <Form
        className={classes.form}
        autoComplete='off'
        validate={validateForm}
        initialValues={extensionsProps}
        onSubmit={(formState: $TSFixMe) => {
          const propsBlob = groupPropProperties(formState);

          let newExtensions = isEditing
            ? {
                ...propsBlob.extensions,
                form: parentForm,
              }
            : {
                ...propsBlob.extensions,
                form: parentForm,
                clientId: newExtensionsId,
              };

          if (isEditing) {
            dispatch(
              updateExtensions({
                extensionList: newExtensions,
              }),
            );
          } else {
            dispatch(
              createExtensions({
                extensionList: newExtensions,
              }),
            );
          }

          enqueueSnackbar('Update form to save changes', {
            variant: 'info',
          });
        }}
      >
        {({ formState }) => (
          <>
            <ArrayField field='props_blob_extensions'>
              {
                // @ts-expect-error
                ({ add, reset, fields }) => (
                  <>
                    {/* @ts-expect-error */}
                    <ArrayField.Items>
                      {({ remove, field, index }: $TSFixMe) => {
                        return (
                          <>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                              spacing={3}
                              sx={{
                                mb: 2,
                              }}
                            >
                              <Grid item xs={8}>
                                <TextField
                                  className={classes.textField}
                                  classes={classes}
                                  label='label'
                                  field={`${field}.label`}
                                  //helperText="Enter the label for the option. This will be displayed on a button or as a select option"
                                  required
                                  variant='standard'
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Relevant
                                  when={({ values }: { values: $TSFixMe }) =>
                                    values.props_blob_extensions &&
                                    values.props_blob_extensions[index] &&
                                    !values.props_blob_extensions[index]
                                      .questionId
                                  }
                                >
                                  <TextField
                                    className={classes.textField}
                                    classes={classes}
                                    label='Value'
                                    field={`${field}.value`}
                                    variant='standard'
                                  />
                                </Relevant>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label='delete extension'
                                  color='primary'
                                  size='small'
                                  onClick={remove}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                              <Grid item xs={8}>
                                <Select
                                  label='Question Instance Link'
                                  field={`${field}.questionId`}
                                  isClearable={true}
                                  className={classes.selectField}
                                  classes={classes}
                                  options={options}
                                  placeholder='Select a question...'
                                  variant='standard'
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Relevant
                                  when={({ values }: { values: $TSFixMe }) =>
                                    values.props_blob_extensions &&
                                    values.props_blob_extensions[index] &&
                                    values.props_blob_extensions[index]
                                      .questionId
                                  }
                                >
                                  <TextField
                                    className={classes.textField}
                                    classes={classes}
                                    defaultValue='1'
                                    label='Multiplier'
                                    field={`${field}.multiplier`}
                                    validate={validateMultiplier}
                                    variant='standard'
                                  />
                                </Relevant>
                              </Grid>
                            </Grid>

                            {fields.length - 1 !== index ? (
                              <Divider light sx={{ my: 3 }} />
                            ) : null}
                          </>
                        );
                      }}
                      {/* @ts-expect-error */}
                    </ArrayField.Items>
                    {formState.invalid ? (
                      <Typography
                        variant='subtitle2'
                        sx={{
                          pt: 4,
                          color: 'red',
                        }}
                      >
                        Extensions require a value or question - please double
                        check and make sure both aren't empty on any.
                      </Typography>
                    ) : null}

                    <IconButton
                      className={classes.button}
                      type='button'
                      color='primary'
                      onClick={add}
                      size='large'
                      aria-label='add extension'
                    >
                      <Tooltip title='Add Extension'>
                        <AddCircleIcon color='primary' sx={{ fontSize: 32 }} />
                      </Tooltip>
                    </IconButton>
                  </>
                )
              }
            </ArrayField>
            <div className={classes.buttonContainer}>
              <Button variant='contained' type='submit'>
                Update Extensions
              </Button>
            </div>
          </>
        )}
        {/* <FormState /> */}
      </Form>
    </div>
  );
};

/* Redux Layer */
function mapDispatchToProps(dispatch: $TSFixMe) {
  function onExtensionsCreated({ extensionList }: { extensionList: $TSFixMe }) {
    return dispatch(formStore.actions.createExtensions({ extensionList }));
  }

  function onExtensionsUpdated({ extensionList }: { extensionList: $TSFixMe }) {
    return dispatch(formStore.actions.updateExtensions({ extensionList }));
  }

  return {
    onExtensionsCreated,
    onExtensionsUpdated,
  };
}

export const ExtensionsConnect = connect(null, mapDispatchToProps);

export default ExtensionsConnect((mappedState) => {
  return <ExtensionsWithStyles {...mappedState} />;
});
