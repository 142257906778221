import { useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { actions } from '../../../store/QuoteWizardState';
import { BounceButton } from '../../../Shared';
import { makeStyles } from '@mui/styles';
import { hideSaveButton } from '../../../store/QuoteWizardState';
import { PremiumProposalsDisplay } from './components/PremiumProposalsDisplay';
import { useSettings } from '../../../hooks';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { StepWizardChildProps } from 'react-step-wizard';
import type { StoreState } from '../../../store';
import type { RouteComponentProps } from '@reach/router';
import { useWalnutGoogleAnalytics } from '../../../hooks';
import {
  EventActions,
  GAEventNames,
} from '../../../hooks/useWalnutGoogleAnalytics';
import { VagoAutomaticDocumentDownloadComponent } from './components';
import { createPageLoadThunk } from '../../../store/analyticsStore';

interface ReturnToQuoteButtonProps extends StepWizardChildProps {
  classes: Record<string, string>;
}
const ReturnToQuoteButton = ({
  goToStep,
  classes = {},
}: ReturnToQuoteButtonProps) => (
  <BounceButton
    className={classes.content}
    color='primary'
    variant='contained'
    onClick={() => goToStep(1)}
  >
    Submit Another Application
  </BounceButton>
);

const useStyles = makeStyles((theme: $TSFixMe) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  content: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

interface ConnectedQuoteSavedProps extends StepWizardChildProps {}

// onResetRequested is passed from the quote wizard
const ConnectedQuoteSaved = ({
  goToStep,
  currentStep,
  totalSteps,
}: ConnectedQuoteSavedProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const savedQuoteUUID = useSelector((state: StoreState) =>
    state.quoteWizard.submittedQuotes &&
    state.quoteWizard.submittedQuotes.length > 0
      ? state.quoteWizard.submittedQuotes[
          state.quoteWizard.submittedQuotes.length - 1
        ].uniqueID
      : null,
  );
  const clientEmail = useSelector(
    (state: StoreState) => state.quoteWizard.clientEmail,
  );

  // send the analytics here
  useEffect(() => {
    dispatch(
      createPageLoadThunk({
        page: 'Done-Saved',
        pageNumber: currentStep,
        totalPages: totalSteps,
      }),
    );
  }, []);

  if (!savedQuoteUUID) {
    return null;
  }
  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.content} variant='h6' color='primary'>
        Application Saved Successfully!
      </Typography>
      {savedQuoteUUID && clientEmail ? (
        <Typography className={classes.content} variant='body1'>
          Your application has been saved successfully! You can view and edit
          the saved application{' '}
          <a href={`/insurtech/quote/resume/${savedQuoteUUID}`}>here</a>. An
          email has been sent to{' '}
          <a href={`mailto:${clientEmail}`}>{clientEmail}</a> with a link to the
          application.
        </Typography>
      ) : null}
      <div className={classes.button}>
        {/* 
        // @ts-expect-error */}
        <ReturnToQuoteButton goToStep={goToStep} />
      </div>
    </div>
  );
};

/* Theming */
export const QuoteSaved = withStyles((_theme) => ({}))((props) => (
  // @ts-expect-error
  <ConnectedQuoteSaved {...props} />
));

interface QuoteCompleteProps extends StepWizardChildProps {}

/**
 * The component to show when the user has successfully complete and submitted a quote
 * @return - The final page of the Quote Wizard.
 */
export const QuoteComplete = ({
  goToStep,
  isActive,
  currentStep,
  totalSteps,
}: QuoteCompleteProps) => {
  const googleAnalytics = useWalnutGoogleAnalytics();
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSettings();

  // send off the google analytics event
  useEffect(() => {
    if (!isActive || !googleAnalytics) {
      return;
    }
    googleAnalytics.triggerEvent(
      GAEventNames.Success,
      EventActions.PageLoad,
      '',
    );
  }, [isActive, googleAnalytics]);

  // send off the analytics on load
  useEffect(() => {
    dispatch(
      createPageLoadThunk({
        page: 'Done-Completed',
        pageNumber: currentStep,
        totalPages: totalSteps,
      }),
    );
  }, []);

  return (
    <>
      <div data-testid='QuoteComplete' className={classes.mainContainer}>
        <Typography className={classes.content} variant='h6' color='primary'>
          Thank you for taking the time to fill out our application forms!
        </Typography>
        <PremiumProposalsDisplay classes={classes} />
        <Typography className={classes.content} variant='body1'>
          {settings.thankYouText
            ? settings.thankYouText
            : 'Someone from our team will be in touch with you in the next 24-48 hours.'}
        </Typography>
        <VagoAutomaticDocumentDownloadComponent />
        <div className={classes.button}>
          {/*
            // @ts-expect-error */}
          <ReturnToQuoteButton classes={classes} goToStep={goToStep} />
        </div>
      </div>
    </>
  );
};

interface QuoteContactInfoOnlyProps extends StepWizardChildProps {}

/**
 * The component to show when the user has successfully complete and submitted a quote
 * @return - The final page of the Quote Wizard.
 */
export const QuoteContactInfoOnly = ({
  goToStep,
  currentStep,
  totalSteps,
}: QuoteContactInfoOnlyProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSettings();

  useEffect(() => {
    dispatch(
      createPageLoadThunk({
        page: 'Done-ContactInfoOnly',
        pageNumber: currentStep,
        totalPages: totalSteps,
      }),
    );
  }, []);

  return (
    <>
      <div
        data-testid='quote-contact-info-only'
        className={classes.mainContainer}
      >
        <Typography className={classes.content} variant='h6' color='primary'>
          Thank you for taking the time to fill out our application forms!
        </Typography>
        <PremiumProposalsDisplay classes={classes} />
        <Typography className={classes.content} variant='body1'>
          {settings.thankYouText
            ? settings.thankYouText
            : 'Someone from our team will be in touch with you in the next 24-48 hours.'}
        </Typography>
        <div className={classes.button}>
          {/*
            // @ts-expect-error */}
          <ReturnToQuoteButton classes={classes} goToStep={goToStep} />
        </div>
      </div>
    </>
  );
};

interface BaseQuoteWizardDoneProps
  extends StepWizardChildProps,
    RouteComponentProps {
  quoteComplete: boolean;
  quoteIncomplete: boolean;
}
/**
 * The final page of the Quote Wizard, once they've submitted all their forms. Either completed the form or saved an incomplete one, decided on the basis of whether the quote in the store has its complete flag set to true or false
 * @param quoteComplete - Whether the currently submitted form is complete or now; determines which component to display. Comes from the form state. NB If the status is CONTACT_INFO_ONLY this is also true
 *
 * @return The final page of the Quote Wizard.
 */
export const BaseQuoteWizardDone = ({
  /* quoteComplete, */
  quoteIncomplete,
  goToStep,
  isActive,
}: BaseQuoteWizardDoneProps) => {
  const dispatch = useDispatch();
  dispatch(hideSaveButton());

  const onResetRequested = useCallback(
    () => dispatch(actions.resetSelections(['questionsPerPage'])),
    [dispatch],
  );

  // when they navigate away, clear the form state
  // @ts-expect-error
  useEffect(() => {
    return onResetRequested;
  }, [onResetRequested]);

  return quoteIncomplete ? (
    // @ts-expect-error
    <QuoteSaved goToStep={goToStep} />
  ) : (
    // @ts-expect-error
    <QuoteComplete goToStep={goToStep} isActive={isActive} />
  );
};

const mapStateToBaseProps = (state: StoreState) => {
  return {
    quoteComplete:
      state.quoteWizard.submittedQuotes.length > 0
        ? ['COMPLETE', 'RENEWED', 'CONFIRMED'].includes(
            state.quoteWizard.submittedQuotes[
              state.quoteWizard.submittedQuotes.length - 1
            ].status,
          )
        : false,
    quoteIncomplete:
      state.quoteWizard.submittedQuotes.length > 0
        ? [
            'INCOMPLETE',
            'INCOMPLETE_RENEWAL',
            'INCOMPLETE_CONFIRMATION',
          ].includes(
            state.quoteWizard.submittedQuotes[
              state.quoteWizard.submittedQuotes.length - 1
            ].status,
          )
        : false,
  };
};
export default connect(
  mapStateToBaseProps,
  null,
)((props) => <BaseQuoteWizardDone {...props} />);
