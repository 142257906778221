import { Card, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TopBusinessesSkeleton() {
  return (
    <Card>
      <Skeleton width='30%' sx={{ mt: 3, mx: 3 }} />
      <Skeleton variant='rectangular' height='370px' sx={{ m: 3 }} />
    </Card>
  );
}
