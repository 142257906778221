import { RouteComponentProps, useNavigate } from '@reach/router';
import { useIframeQueryParams, useLoadOrphanedAnswers } from '../../hooks';
import LoadingScreen from '../ManagementPanel/components/LoadingScreen';
import { generateNavigationDestination } from './components/ContactInfo';

interface LoadProps extends RouteComponentProps {}

export const Load = (_props: LoadProps) => {
  const { loaded } = useLoadOrphanedAnswers();
  const iframeQueryParams = useIframeQueryParams();
  const navigate = useNavigate();

  if (loaded) {
    navigate(generateNavigationDestination(iframeQueryParams));
  }
  return <LoadingScreen />;
};
