import { useState } from 'react';
// material
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
// // routes

// hooks
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
// components
import { useSnackbar } from 'notistack';
// queries
import { CREATE_INSURER_QUOTE_COMPARISON } from '../../../../../queries/mutations/createInsurerQuoteComparison';
// error handler

// pages

// classes and types
import {
  transformData,
  displayData,
  countLeadingWhitespace,
} from './jsonUtility';
import {
  createComparisonHeadCells,
  InsurerQuoteRowData,
  TransformedData,
} from './cellUtils';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

interface InsurerQuoteComparisonTableProps {
  selectedInsurerQuotes: readonly InsurerQuoteRowData[];
  RootStyle: $TSFixMe;
}

export const InsurerQuoteComparisonTable = ({
  selectedInsurerQuotes,
  RootStyle,
}: InsurerQuoteComparisonTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [transformedJsonData, setTransformedJsonData] = useState<
    TransformedData[]
  >([]);
  const [tableHeaderCells, setTableHeaderCells] = useState<string[]>(['']);

  const [
    createInsurerQuoteComparison,
    {
      loading: comparisonLoading,
      error: comparisonError,
      data: comparisonData,
    },
  ] = useMutation(CREATE_INSURER_QUOTE_COMPARISON);

  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const handleOpenModal = () => {
    if (selectedInsurerQuotes.length > 1) {
      setOpenWarningModal(true);
    } else {
      setOpenErrorModal(true);
    }
  };
  const handleCloseWarningModal = () => setOpenWarningModal(false);

  const handleComparisonProceed = async (forceRegen: boolean = false) => {
    handleCloseWarningModal();
    try {
      const selectedInsurerQuotesIds = selectedInsurerQuotes.map(
        (quote) => quote.quoteIdentifier,
      );
      await createInsurerQuoteComparison({
        variables: {
          insurerQuoteIds: selectedInsurerQuotesIds,
          forceRegen,
        },
      });
    } catch (e) {
      console.error('Error creating insurer quote comparison:', e);
    }
  };

  useEffect(() => {
    if (comparisonData) {
      const llmJsonString =
        comparisonData.createInsurerQuoteComparison?.insurerQuoteComparison
          ?.llmResponseBlob;
      //console.log(llmJsonString);
      const llmJson = JSON.parse(llmJsonString);
      //console.log(JSON.stringify(llmJson, null, 2));
      const sortedSelectedInsurerQuoteIds = selectedInsurerQuotes
        // @ts-ignore
        .toSorted(
          (a: InsurerQuoteRowData, b: InsurerQuoteRowData) =>
            a.tempTableID - b.tempTableID,
        )
        .map((quote: InsurerQuoteRowData) => quote.quoteIdentifier);
      const rows = transformData(llmJson, sortedSelectedInsurerQuoteIds);
      setTransformedJsonData(rows);
      setTableHeaderCells(
        createComparisonHeadCells(sortedSelectedInsurerQuoteIds),
      );
      //console.log(rows);
    }
  }, [comparisonData]);

  useEffect(() => {
    if (comparisonError) {
      let errorMessage = 'Error uploading quote';
      if (comparisonError.message)
        errorMessage = errorMessage.concat(': ', comparisonError.message);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
    //console.log({ comparisonError });
  }, [comparisonError]);

  return (
    <>
      <RootStyle>
        <Typography variant='h4'>Quote Details/Comparison</Typography>
        <LoadingButton
          component='label'
          role={undefined}
          variant='outlined'
          onClick={handleOpenModal}
          loading={comparisonLoading}
          loadingPosition='start'
          startIcon={<AutoAwesomeRoundedIcon />}
        >
          Generate Comparison
        </LoadingButton>
      </RootStyle>
      {comparisonError ? (
        <Typography>
          There was an error Please try again. {comparisonError.message}
        </Typography>
      ) : null}
      <TableContainer sx={{ minWidth: 800, minHeight: 400 }}>
        {!comparisonData ? null : (
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaderCells.map((headCell) => (
                  <TableCell key={headCell} align='left'>
                    {headCell}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transformedJsonData.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={`comparison-row-${index}`}>
                    {Object.keys(row).map((entry, subIndex) => (
                      <TableCell
                        component='th'
                        key={`row-${index}-col-${subIndex}`}
                        scope='row'
                        padding='normal'
                        align='left'
                      >
                        {displayData(row, entry).map((line, boxIndex) => {
                          const splitLine = line.split(':', 2);
                          return (
                            <Box
                              my={1}
                              ml={countLeadingWhitespace(line)}
                              key={`box-${boxIndex}`}
                            >
                              <Typography
                                sx={{
                                  textDecoration: line.endsWith(':')
                                    ? 'underline'
                                    : 'none',
                                }}
                                fontSize={14}
                                // fontWeight={line.endsWith(':') ? 600 : 400}
                              >
                                {splitLine.length === 1 ||
                                !splitLine[1].includes('$') ? (
                                  line
                                ) : (
                                  <>
                                    {splitLine[0]}:{' '}
                                    <Typography
                                      component='span'
                                      color='primary'
                                      fontSize={14}
                                    >
                                      {splitLine[1]}
                                    </Typography>
                                  </>
                                )}
                              </Typography>
                            </Box>
                          );
                        })}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal open={openWarningModal} onClose={handleCloseWarningModal}>
        <Box sx={modalStyle}>
          <Typography variant='h6' component='h2'>
            This will use AI to generate a quote comparison.
          </Typography>
          <Typography sx={{ my: 2 }}>
            Would you like to proceed? This may take a minute.
          </Typography>
          <Stack direction='row' spacing={2} justifyContent='center'>
            <Button
              variant='contained'
              onClick={(_event) => handleComparisonProceed(false)}
            >
              Proceed
            </Button>
            <Button
              variant='outlined'
              onClick={(_event) => handleComparisonProceed(true)}
            >
              Force Regenerate
            </Button>
            <Button variant='outlined' onClick={handleCloseWarningModal}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
        <Box sx={modalStyle}>
          <Typography variant='h6' component='h2'>
            Error
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Please select two or more quotes to compare.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button variant='outlined' onClick={handleCloseErrorModal}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
