// import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import IconButton from '@mui/material/IconButton';
// import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import InputAdornment from '@mui/material/InputAdornment';
// import EditIcon from '@mui/icons-material/Edit';
// import CheckIcon from '@mui/icons-material/Check';
// import CloseIcon from '@mui/icons-material/Close';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import { OrganizationSpecificInsuranceRateQuery } from '../../../../../gql/graphql';
// import { styled } from '@mui/material';
import { EditRateButton } from './EditRateButton';
import { EditRateMutation } from '../../../../../gql/graphql';
import { RateTableRate } from './types';
import { symbolify } from './utility';
import { styled } from '@mui/material';

// EditableNumber Component
export interface RateChangerProps {
  rate: NonNullable<
    ArrayElement<
      OrganizationSpecificInsuranceRateQuery['organizationSpecificInsuranceRates']
    >
  >;
  onComplete: (data: EditRateMutation) => void;
  groups: Array<string>;
}
export const RateChanger = ({ rate, onComplete, groups }: RateChangerProps) => {
  return (
    <Grid
      item
      xs={4}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        gap: 2,
      }}
    >
      <RateDisplay rate={rate} />
      <EditRateButton rate={rate} onComplete={onComplete} groups={groups} />
    </Grid>
  );
};

interface RateDisplayProps {
  rate: RateTableRate;
}
const RateDisplay = ({ rate }: RateDisplayProps) => {
  return (
    <RateDisplayContainer>
      <Typography variant='h6'>{rate.displayName}</Typography>
      <Typography>
        {symbolify(String(Number(rate.value)), rate.symbol)}
      </Typography>
    </RateDisplayContainer>
  );
};

const RateDisplayContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  };
});
