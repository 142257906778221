import { graphql } from '../gql';

// NB we deliberately just get the first level - if need be, we can get the relevant question instance with another query
export const FORM_MODIFIER_RELEVANT_FORMS = graphql(`
  query formModifierRelevantFinalForms($businessId: ID, $policyId: ID) {
    formModifierRelevantFinalForms(
      businessId: $businessId
      policyId: $policyId
    ) {
      id
      policy {
        displayName
      }
      businessLine {
        displayName
      }
      questionInstances {
        ...QuestionInstanceFields
      }
    }
  }
`);

// this one gets the full form
export const FORM_MODIFIER_RELEVANT_FORMS_FULL = graphql(`
  query formModifierRelevantFinalFormsFull($businessId: ID, $policyId: ID) {
    formModifierRelevantFinalForms(
      businessId: $businessId
      policyId: $policyId
    ) {
      ...FinalFormFields
    }
  }
`);
