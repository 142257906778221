import { useState } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// components
import { FormModal } from '../FormModal';
import QuestionInstanceForm from './QuestionInstanceForm';

// types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { ProgramBuilderForm } from '../../classes';
import { useAllowByPermission } from '../../../../hooks';

interface CreateQuestionInstanceProps {
  form: ProgramBuilderForm;
}
const CreateQuestionInstance = ({ form }: CreateQuestionInstanceProps) => {
  const [open, setOpen] = useState(false);
  const hasEditFormPermissions = useAllowByPermission('edit:forms');

  // handler
  const handleClose = () => {
    //console.log('Calling handleClose (CreateQuestionInstance)');
    setOpen(false);
    // dispatch(programBuilderWizard.actions.clearPendingQuestion());
  };

  return (
    <>
      <FormModal
        open={open}
        handleClose={handleClose}
        FormComponent={(props: $TSFixMe) => (
          <QuestionInstanceForm
            parentForm={form}
            isEditing={false}
            {...props}
          />
        )}
        title='Create Custom Question'
      />
      <Tooltip
        title={
          !hasEditFormPermissions
            ? 'You do not have permission to create a new question'
            : ''
        }
      >
        <span>
          <Button
            data-testid='create-new-question-instance'
            size='small'
            variant='contained'
            onClick={() => setOpen(true)}
            endIcon={<Icon icon={plusFill} />}
            disabled={!hasEditFormPermissions}
          >
            Create Question
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default CreateQuestionInstance;
