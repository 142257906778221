import { graphql } from '../gql';

export const PREMIUM_PROPOSALS_FROM_HASH = graphql(`
  query premiumProposalsFromHash($hash: UUID!, $insurerName: String!) {
    premiumProposalsFromHash(hash: $hash, insurerName: $insurerName) {
      quoteStructuredData {
        businessName
        contactName
        contactEmail
        contactPhoneNumber
        revenue
        contactAddress {
          formattedAddress
        }
      }
      quoteBusinessLine {
        displayName
      }
      insurer {
        name
        logoUrl
      }
      status
      amountInCents
      identifier
      notes
      referralReasons
      rejectedReasons
    }
  }
`);
