import { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';

import { ReferralReasonsSummary } from './ReferralReasonsSummary';
import { RejectedReasonsSummary } from './RejectedReasonsSummary';
import { PageBreak } from './PageBreak';
import { toTitleCase } from './utility';

// types and classes
import { PremiumProposal } from '../../../Typescript/backend/classes';

interface PremiumProposalsSummaryPageProps {
  proposals: Array<PremiumProposal>;
}

const currencyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const PremiumProposalsSummaryPage = ({
  proposals,
}: PremiumProposalsSummaryPageProps) => {
  const [structuredData, setStructuredData] =
    useState<PremiumProposal['quoteStructuredData']>();
  const [businessLine, setBusinessLine] = useState<string | null>(null);
  const [generalInformation, setGeneralInformation] = useState<
    Array<[string, string]>
  >([]);
  const [premiumMinMax, setPremiumMinMax] = useState<{
    min: string;
    max: string;
  }>({ min: '', max: '' }); // these should be the formatted min / max premiums. In the case where they are the same, only one will be displayed

  // update the structuredData when we get the proposals in
  useEffect(() => {
    if (proposals && proposals.length > 0) {
      setStructuredData(proposals[0].quoteStructuredData);
    }
  }, [proposals]);

  // set the business line when we get the proposals in
  useEffect(() => {
    if (proposals && proposals.length > 0) {
      // @ts-expect-error
      setBusinessLine(proposals[0].quoteBusinessLine.displayName || null);
    }
  }, [proposals]);

  // update the summarized general information once we have the structured data
  useEffect(() => {
    if (structuredData) {
      setGeneralInformation(
        // @ts-expect-error
        [
          ['Line', businessLine],
          ['Business Name', structuredData.businessName],
          ['Client Name', structuredData.contactName],
          ['Client Email', structuredData.contactEmail],
          ['Client Phone Number', structuredData.contactPhoneNumber],
          [
            'Revenue',
            structuredData.revenue
              ? currencyFormatter.format(structuredData.revenue)
              : undefined,
          ],
          ['Address', structuredData.contactAddress?.formattedAddress],
        ].filter(([_title, value]) => !!value),
      );
    }
  }, [structuredData, setGeneralInformation, businessLine]);

  // Find the min and max premium
  useEffect(() => {
    if (!proposals || proposals.length === 0) {
      return;
    }
    const premiums = proposals.map((proposal) => proposal.amountInCents / 100);
    const [min, max] = [Math.min(...premiums), Math.max(...premiums)];
    setPremiumMinMax({
      min: currencyFormatter.format(min),
      max: currencyFormatter.format(max),
    });
  }, [proposals]);

  if (proposals.length === 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ border: 'solid 1px black', borderRadius: 2, p: 4 }}>
        <Typography
          align='center'
          gutterBottom
          variant='h2'
          sx={{ fontWeight: 600 }}
        >
          Summary
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='h4' component='p' sx={{ m: 2 }}>
            Status:{' '}
            <Box
              sx={{
                display: 'inline',
                color:
                  proposals[0].status === 'REJECTED'
                    ? 'error.main'
                    : proposals[0].status === 'REFERRAL'
                    ? 'warning.main'
                    : 'success.main',
              }}
            >
              {toTitleCase(proposals[0].status)}
            </Box>
          </Typography>
          {proposals[0].status !== 'REJECTED' ||
          !premiumMinMax.min ||
          !premiumMinMax.max ? (
            <Typography variant='h4' component='p'>
              Premium:{' '}
              {premiumMinMax.min === premiumMinMax.max
                ? premiumMinMax.min
                : `${premiumMinMax.min} - ${premiumMinMax.max}`}
            </Typography>
          ) : null}
        </Box>
        {generalInformation.length > 0 ? (
          <>
            <Typography variant='h5' align='center' gutterBottom>
              General Information
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: 5,
              }}
            >
              <List disablePadding>
                {
                  // TODO this is atrocious - find a better solution. The same kludge shows up in the referral and rejection sections as well
                  generalInformation
                    .slice(0, Math.ceil(generalInformation.length / 2))
                    .map(([title, value]) => {
                      return (
                        <ListItem
                          disablePadding
                          sx={{
                            alignItems: 'flex-start',
                            overflow: 'hidden',
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              lineHeight: 1,
                              marginTop: 'calc(0.75rem - 2px)',
                            }}
                          >
                            <CircleIcon sx={{ fontSize: 10 }} />
                          </ListItemIcon>
                          <ListItemText>
                            {title}: {value}
                          </ListItemText>
                        </ListItem>
                      );
                    })
                }
              </List>
              <List disablePadding>
                {generalInformation
                  .slice(Math.ceil(generalInformation.length / 2))
                  .map(([title, value]) => {
                    return (
                      <ListItem
                        disablePadding
                        sx={{
                          alignItems: 'flex-start',
                          overflow: 'hidden',
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            lineHeight: 1,
                            marginTop: 'calc(0.75rem - 2px)',
                          }}
                        >
                          <CircleIcon sx={{ fontSize: 10 }} />
                        </ListItemIcon>
                        <ListItemText>
                          {title}: {value}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </>
        ) : null}
        <RejectedReasonsSummary reasons={proposals[0].rejectedReasons} />
        <ReferralReasonsSummary reasons={proposals[0].referralReasons} />
      </Box>
      <PageBreak />
    </>
  );
};
