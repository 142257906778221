import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import formStore from './FormStore';
import { enablePatches } from 'immer';
import {
  getSelectedBusinessForm,
  getSelectedPolicyForms,
  getFormQuestionInstances,
  getSelectedFormQuestionInstances,
} from './FormStore';
import ProgramBuilderWizard, {
  ProgramBuilderWizardSaga,
} from './ProgramBuilderWizard';
import QuoteWizardState, { QuoteWizardStateSaga } from './QuoteWizardState';
import ManagementPanelStore from './ManagementPanelStore';
import { analysticsStoreSaga, analyticsStore } from './analyticsStore';
import { formModifierStore } from './FormModifierStore';

import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// you can preload the application state by copying and pasting the redux state from the redux developer tools.
//  This saves you from having to constantly start over in the wizard
// TODO it would be really nice to be able to use the .env files to target save application state profiles.

const _initialState = {};
enablePatches();

export function initializeStore({
  initialState = _initialState,
  client,
}: $TSFixMe = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const reducer = combineReducers({
    formStore: formStore.reducer,
    programBuilder: ProgramBuilderWizard.reducer,
    quoteWizard: QuoteWizardState.reducer,
    managementPanelStore: ManagementPanelStore.reducer,
    analyticsStore: analyticsStore.reducer,
    formModifierStore: formModifierStore.reducer,
  });

  //console.log('Initial state', initialState);
  const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: [sagaMiddleware, thunk],
  });

  sagaMiddleware.run(function* () {
    yield* ProgramBuilderWizardSaga({ client });
    yield* QuoteWizardStateSaga({ client });
    yield* analysticsStoreSaga({ client });
  });

  return store;
}

export type { Dispatch, StoreState } from '../App';
export {
  formStore,
  ProgramBuilderWizard,
  getSelectedBusinessForm,
  getSelectedPolicyForms,
  getSelectedFormQuestionInstances,
  getFormQuestionInstances,
  QuoteWizardState,
  ManagementPanelStore,
  formModifierStore,
};
