export function shallowEquals(question, comparisonQuestion) {
  const fields_to_compare = [
    'name',
    'label',
    'helpText',
    'component',
    'propsBlob',
    'dataType',
    'required',
  ];
  for (let i = 0; i < fields_to_compare; i++) {
    const field = fields_to_compare[i];
    if (question[field] !== comparisonQuestion[field]) {
      //console.log(
      //   `These questions are not shallowly equal because ${question[field]} is not equal to ${comparisonQuestion[field]}`,
      // );
      return false;
    }
  }
  return true;
}
