import { graphql } from '../../gql';

export const EDIT_PERILS = graphql(`
  mutation editPerils($token: String!, $perils: [EditPerilInput!]!) {
    editPerils(token: $token, perils: $perils) {
      perils {
        ...PerilFields
      }
    }
  }
`);
