import { Form } from 'informed';
import { Typography } from '@mui/material';
import RenderedQuestionInstance from '../../../common/QuoteComponents/RenderedQuestionInstance';
import {
  WithFormStyles,
  withFormStyles,
} from '../../../../util/withFormStyles';

// types and classes
import { QuoteWizardQuestionInstance } from '../../../QuoteWizard/classes';

interface QuoteFormDisplayProps extends WithFormStyles {
  formTitle: string;
  questionInstances: Array<QuoteWizardQuestionInstance>;
}
function QuoteFormDisplay({
  classes,
  formTitle,
  questionInstances,
}: QuoteFormDisplayProps) {
  return (
    <Form className={classes.form}>
      <Typography variant='h6' color='textSecondary' align='center'>
        {formTitle}
      </Typography>
      <div>
        {questionInstances.map((questionInstance) => (
          <RenderedQuestionInstance
            questionInstance={questionInstance}
            key={questionInstance.id}
            classes={classes}
          />
        ))}
      </div>
    </Form>
  );
}

export default withFormStyles()(QuoteFormDisplay);
