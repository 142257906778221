import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { BusinessType, Policy } from '../../../../../../Typescript';
import { useLazyQuery } from '@apollo/client';
import { AVAILABLE_FORM_POLICIES } from '../../../../../../queries';
import { useAuth, determineBaseURL } from '@calefy-inc/authentication';
import { copyTextToClipboard } from '@calefy-inc/utility';

// types and classes
import type { Dispatch, SetStateAction } from 'react';
import { generateBusinessLinkUrl } from '../../../../../BusinessLink/BusinessLink';

interface GenerateBusinessLinkWithPolicyProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  business: BusinessType;
}
/**
 * Menu to generate a link with business, policy/policies, and optionally a producer
 * @param name - description
 * @returns - description
 */
export const GenerateBusinessLinkWithPolicy = ({
  open,
  setOpen,
  onClose,
  business,
}: GenerateBusinessLinkWithPolicyProps) => {
  const { token } = useAuth();
  const [requestedPolicyInternalNames, setRequestedPolicyInternalNames] =
    useState<Array<Policy['internalName']>>([]);
  const [availablePolicies, setAvailablePolicies] = useState<
    Array<Pick<Policy, 'id' | 'internalName' | 'displayName'>>
  >([]);
  const [producerId, setProducerId] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [copyButtonTitle, setCopyButtonTitle] = useState<string>('Copy');
  const [copyError, setCopyError] = useState<Error | undefined>();

  // @ts-expect-error
  const [getAvailableFormPolicies, { loading, error, data }] = useLazyQuery(
    AVAILABLE_FORM_POLICIES,
  );

  // get the list of available policies
  useEffect(() => {
    if (business && token) {
      getAvailableFormPolicies({
        variables: {
          businessId: business.id,
          token,
        },
      });
    }
  }, [token, business]);

  // set the policies once we have the data
  useEffect(() => {
    if (data) {
      //console.log({ data });
      // @ts-expect-error
      setAvailablePolicies(data.availableFormPolicies);
    }
  }, [data]);

  // whenever policies are added / removed or the producer id changes, update the url
  useEffect(() => {
    setUrl(
      determineBaseURL() +
        generateBusinessLinkUrl(
          business.id,
          // @ts-expect-error
          [...requestedPolicyInternalNames].filter((name) => !!name),
          producerId,
        ),
    );
  }, [requestedPolicyInternalNames, producerId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid='generate-business-link-with-policies'
    >
      <DialogTitle>
        Generate Business Link for {business.displayName}
      </DialogTitle>
      <DialogContent>
        <Typography>Which coverages should be included?</Typography>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            ...(availablePolicies.length > 5 ? { columns: 2 } : {}),
          }}
        >
          {availablePolicies.map((policy) => (
            <FormControlLabel
              sx={{ display: 'block', marginLeft: 0 }}
              label={policy.displayName}
              control={
                <Checkbox
                  color='primary'
                  onClick={(e) => {
                    //console.log({ e });
                    // @ts-expect-error
                    if (e.target.checked) {
                      setRequestedPolicyInternalNames((oldNames) => [
                        ...oldNames,
                        policy.internalName,
                      ]);
                    } else {
                      setRequestedPolicyInternalNames((oldNames) =>
                        [...oldNames].filter(
                          (name) => name !== policy.internalName,
                        ),
                      );
                    }
                  }}
                />
              }
            />
          ))}
        </div>
        <TextField
          label='Producer Id'
          value={producerId}
          onChange={(e) => setProducerId(e.target.value)}
          fullWidth
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'monospace',
              flexGrow: 1,
              wordBreak: 'break-all',
            }}
          >
            {url}
          </Typography>
          <Tooltip title={copyButtonTitle}>
            <Button
              variant='text'
              onClick={async () => {
                await copyTextToClipboard(
                  url,
                  () => {
                    setCopyButtonTitle('Copied!');
                    setTimeout(() => setCopyButtonTitle('Copy'), 2000);
                  },
                  (error) => {
                    setCopyError(error);
                  },
                );
              }}
              sx={{ flexGrow: 0 }}
            >
              Copy
              <ContentCopyIcon />
            </Button>
          </Tooltip>
        </div>
        {copyError ? (
          <Typography color='error.main'>
            Error copying URL to clipboard - please copy manually
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
