import { useRef, useEffect, useState } from 'react';
import { useGoogle } from '@calefy-inc/hooks';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { $TSFixMe } from '@calefy-inc/utilityTypes';

interface GoogleMapProps {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  searchCirclesRef: $TSFixMe;
  updateSearchCircles: $TSFixMe;
  error: boolean | undefined;
}
const GoogleMap = ({
  center = { lat: 51.0447, lng: -114.0719 },
  zoom = 8,
  searchCirclesRef,
  updateSearchCircles,
  error,
}: GoogleMapProps) => {
  let centre = useRef();
  let circle = useRef();
  const { google } = useGoogle();
  const [map, setMap] = useState();
  const googleMapRef = useRef();

  const createGoogleMap = (google: $TSFixMe) => {
    const newMap = new google.maps.Map(googleMapRef.current, {
      zoom: zoom,
      center: center,
    });
    setMap(newMap);
  };

  // listen for click
  const setCircleCentre = (e: $TSFixMe) => {
    const latLng = e.latLng;
    const coord = {
      lat: latLng.lat(),
      long: latLng.lng(),
    };
    // @ts-expect-error
    centre.current = coord;
    if (map) {
      circle.current = new google.maps.Circle({
        map: map,
        center: e.latLng,
        radius: 1000,
      });

      // @ts-expect-error
      circle.current.addListener('mousemove', listenForRadius);
      // @ts-expect-error
      map.addListener('mousemove', listenForRadius);

      // @ts-expect-error
      circle.current.addListener('click', finishCircle);
      // @ts-expect-error
      map.addListener('click', finishCircle);
    } else {
    }
  };

  const makeToggleEditable = (c: $TSFixMe) => {
    google.maps.event.addListener(c, 'click', () => {
      c.setEditable(!c.getEditable());
    });
  };

  const makeElemEventTransparent = (elem: $TSFixMe, event: $TSFixMe) => {
    elem.addListener(event, (e: $TSFixMe) => {
      google.maps.event.trigger(map, event, {
        latLng: e.latLng,
      });
    });
  };

  //listen for second click
  const finishCircle = (_e: $TSFixMe) => {
    //remove mousemove event listeners from the circle and tthe map
    google.maps.event.clearListeners(map, 'mousemove');
    google.maps.event.clearListeners(circle.current, 'mousemove');

    google.maps.event.clearListeners(map, 'click');
    google.maps.event.clearListeners(circle.current, 'click');

    // allow it to be toggled
    makeToggleEditable(circle.current);

    //make it event transparent
    // makeElemEventTransparent(circle.current, 'click'); // Not this because we want a click on a circle to select it
    makeElemEventTransparent(circle.current, 'mousemove');

    // prepare for the next circle
    // @ts-expect-error
    map.addListener('click', setCircleCentre);

    updateSearchCircles([...searchCirclesRef.current, circle.current]);
  };

  //listen for mousemove
  const listenForRadius = (e: $TSFixMe) => {
    const centreLatLng = new google.maps.LatLng(
      // @ts-expect-error
      centre.current.lat,
      // @ts-expect-error
      centre.current.long,
    );
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      e.latLng,
      centreLatLng,
    );
    // @ts-expect-error
    circle.current.setRadius(distance);
  };

  const removeEditableCircles = () => {
    for (let c of searchCirclesRef.current) {
      if (c.getEditable() === true) {
        c.setMap(null);
        c = null;
      }
    }
    const remaining = searchCirclesRef.current.filter(
      (c: $TSFixMe) => c.getEditable() !== true,
    );
    updateSearchCircles(remaining);
  };

  useEffect(() => {
    if (google) {
      createGoogleMap(google);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google]);

  useEffect(() => {
    if (map) {
      // @ts-expect-error
      map.addListener('click', setCircleCentre);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    // @ts-expect-error
    document.getElementById('google-map').addEventListener('keyup', (e) => {
      if (e.key === 'Delete') {
        removeEditableCircles();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <FormLabel error={error}>
              Select Search Areas{' '}
              {searchCirclesRef.current.length === 0
                ? null
                : `(${searchCirclesRef.current.length} selected)`}
            </FormLabel>
          </AccordionSummary>
          <AccordionDetails>
            <div
              id='google-map'
              // @ts-expect-error
              ref={googleMapRef}
              style={{
                width: '100%',
                height: '500px',
                backgroundColor: 'grey',
                display: 'inline-block',
              }}
            />
          </AccordionDetails>
        </Accordion>
        {error ? (
          <FormHelperText error={true}>
            Must select at least one search area!
          </FormHelperText>
        ) : null}
      </div>
    </>
  );
};

export default GoogleMap;
