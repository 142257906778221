import { Language } from '../../../../Typescript/classes';
import { ProgramBuilderQuestionInstance } from '../ProgramBuilderQuestionInstance';

export const getAvailableLanguagesFromQuestionInstanceList = (
  questions: Array<ProgramBuilderQuestionInstance>,
): Array<Language> => {
  const languageLists = questions.map((q) => q.getAvailableLanguages());
  if (languageLists.length === 0) {
    return [];
  }
  let availableLanguages: Array<Language> = [...languageLists[0]];
  languageLists.slice(1).forEach((languageList) => {
    availableLanguages = availableLanguages.filter((language) =>
      language.inLanguageList(languageList),
    );
  });
  return availableLanguages;
};
