import { ProgramBuilderQuestionInstance } from '../../FormManager/classes/ProgramBuilderQuestionInstance';

export const transformQuestionListToTreeData = (
  list: Array<ProgramBuilderQuestionInstance>,
): Array<ProgramBuilderQuestionInstance> => {
  const treeData = list.map((ele) => {
    try {
      return new ProgramBuilderQuestionInstance({
        ...ele,
        title: ele.generateDisplayName(),
        children: transformQuestionListToTreeData(ele.subQuestions),
      });
    } catch (e) {
      console.error(e);
    }
  });
  //@ts-expect-error
  return treeData;
};
