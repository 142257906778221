// Unified AnswerInstance (for use in all parts of the frontend!)

import { cloneDeep, isEqual } from 'lodash';
import { imposeLanguageOnPropsBlob } from './utility';

// classes and types
import { Language } from '../classes';
import { AnswerInstanceDetails } from '../backend/classes';
// import type { AnswerInstanceInput } from '../backend/inputTypes';
import type { AnswerInstanceInput } from '../../gql/graphql';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  QuoteWizardQuestionInstance,
  QuoteWizardQuestionInstanceInput,
} from '../../components/QuoteWizard/classes/QuoteWizardQuestionInstance';
import { QuoteWizardAnswerInstance } from '../../components/QuoteWizard/classes';

interface FindByAttributeInSubanswersOptions {
  recurse: boolean;
}

export interface UnifiedAnswerInstanceInput {
  id?: string;
  apiName: string;
  displayName: string;
  label: string;
  helpText: string | null;
  component: string;
  propsBlob?: object;
  dataType: string;
  details: AnswerInstanceDetails | null;
  required: boolean;
  value: unknown;
  date?: string;
  method?: string;
  askOnRenewal?: boolean;
  prefillOnRenewal?: boolean;
  subAnswers: Array<UnifiedAnswerInstance>;
  defaultValue?: string;
}

export class UnifiedAnswerInstance {
  id?: string;
  apiName: string;
  displayName: string;
  label: string;
  helpText: string | null;
  component: string;
  propsBlob: object;
  dataType: string;
  details: AnswerInstanceDetails | null;
  required: boolean;
  value: unknown;
  date?: string;
  method?: string;
  askOnRenewal: boolean;
  prefillOnRenewal: boolean;
  subAnswers: Array<UnifiedAnswerInstance>;
  defaultValue: string;

  constructor(input: UnifiedAnswerInstanceInput) {
    //console.log(
    //   `Just constructed a new UnifiedAnswerInstance with ${JSON.stringify(
    //     input,
    //     null,
    //     4,
    //   )}`,
    // );
    if (input.id) {
      this.id = input.id;
    }
    this.apiName = input.apiName;
    this.displayName = input.displayName;
    this.label = input.label;
    this.helpText = input.helpText === undefined ? null : input.helpText;
    this.component = input.component;
    this.propsBlob = input.propsBlob ? input.propsBlob : {};
    this.dataType = input.dataType;
    this.details = input.details;
    this.required = input.required;
    this.value = input.value;
    if (input.date) {
      this.date = input.date;
    }
    if (input.method) {
      this.method = input.method;
    }
    this.askOnRenewal =
      input.askOnRenewal === undefined ? false : input.askOnRenewal;
    this.prefillOnRenewal =
      input.prefillOnRenewal === undefined ? false : input.prefillOnRenewal;
    this.subAnswers = input.subAnswers.map((subAnswer) => subAnswer.copy());
    this.defaultValue = input.defaultValue || '';
  }

  /**
   * Make a copy of this UnifiedAnswerInstance object
   */
  copy() {
    const input: Partial<UnifiedAnswerInstanceInput> = {};
    Object.entries(this).forEach(([key, value]) => {
      if (key === 'propsBlob') {
        input[key] = cloneDeep(value);
      } else if (key === 'details') {
        input[key] = value ? value.copy() : value;
      } else if (key === 'subAnswers') {
        // @ts-expect-error
        input[key] = value.map((subA) => subA.copy());
      } else {
        // @ts-expect-error
        input[key] = value;
      }
    });
    // @ts-expect-error
    const copied: UnifiedAnswerInstance = new UnifiedAnswerInstance(input);
    return copied;
    // {
    //   id: this.id,
    //   apiName: this.apiName,
    //   displayName: this.displayName,
    //   label: this.label,
    //   helpText: this.helpText,
    //   component: this.component,
    //   propsBlob: cloneDeep(this.propsBlob),
    //   dataType: this.dataType,
    //   details: this.details ? this.details.copy() : null,
    //   required: this.required,
    //   value: this.value,
    //   date: this.date,
    //   method: this.method,
    //   askOnRenewal: this.askOnRenewal,
    //   prefillOnRenewal: this.prefillOnRenewal,
    //   subAnswers: this.subAnswers.map((subAnswer) => subAnswer.copy()),
    // }
  }

  /**
   * Convert to an AnswerInstanceInput (backend type)
   */
  toAnswerInstanceInput(): AnswerInstanceInput {
    return {
      apiName: this.apiName,
      displayName: this.displayName,
      label: this.label,
      helpText: this.helpText === null ? undefined : this.helpText,
      component: this.component,
      propsBlob: JSON.stringify(this.propsBlob || {}),
      dataType: this.dataType,
      details: this.details
        ? this.details.toAnswerInstanceDetailsInput()
        : undefined,
      required: this.required,
      value: String(this.value),
      method: this.method,
      askOnRenewal: this.askOnRenewal,
      prefillOnRenewal: this.prefillOnRenewal,
      subAnswers: this.subAnswers.map((subAnswer) =>
        subAnswer.toAnswerInstanceInput(),
      ),
      defaultValue: this.defaultValue,
    };
  }

  /**
   * Generate the default value in the case where this value is empty
   */
  generateDefaultValue() {
    if (this.component === 'checkbox') {
      return false;
    } else if (this.subAnswers.length > 0) {
      return 1;
    } else {
      return 'N/A';
    }
  }

  /**
   * Return the number of times this answer (or an answer that is probably an answer to the same question) appears in a list of answers
   */
  countInAnswersList(answers: Array<UnifiedAnswerInstance>) {
    return answers.reduce((count: number, other) => {
      return count + (this.weaklyMatchesAnswer(other) ? 1 : 0);
    }, 0);
  }

  /**
   * Does this answer weakly match another - i.e. is it plausible that they are both answers to the same question?
   */
  weaklyMatchesAnswer(other: UnifiedAnswerInstance) {
    const fieldsToMatch: Array<keyof UnifiedAnswerInstance> = [
      'apiName',
      'displayName',
      'label',
      'component',
      'dataType',
      'required',
    ];
    return fieldsToMatch.every((field) => this[field] === other[field]);
  }

  static generateFromBackendResponse(response: $TSFixMe) {
    const fromResponse: UnifiedAnswerInstanceInput = {
      ...response,
      details: response.details
        ? new AnswerInstanceDetails(response.details)
        : null,
      propsBlob:
        response.propsBlob && typeof response.propsBlob === 'string'
          ? JSON.parse(response.propsBlob)
          : {},
      subAnswers: response.subAnswers
        ? response.subAnswers.map((subAnswerResponse: $TSFixMe) =>
            UnifiedAnswerInstance.generateFromBackendResponse(
              subAnswerResponse,
            ),
          )
        : [],
    };
    return new UnifiedAnswerInstance(fromResponse);
  }

  /**
   * Does this answer match a QuoteWizardQuestionInstance - i.e. could this be the answer to the question?
   * @param question - The QuoteWizardQuestionInstance to match against this one
   */
  matchesQuestion(question: QuoteWizardQuestionInstance): boolean {
    const fieldsToMatch: Array<
      keyof (UnifiedAnswerInstance | QuoteWizardQuestionInstance)
    > = [
      'apiName',
      'displayName',
      'label',
      'component',
      'dataType',
      'required',
    ];
    return fieldsToMatch.every((field) =>
      isEqual(this[field], question[field]),
    );
  }

  /**
   * Make a rough copy of what the original QuoteWizardQuestionInstance that generated the AnswerInstance would have looked like
   */
  toQuoteWizardQuestionInstance(language: Language) {
    const fieldsToCopy: Array<
      keyof (UnifiedAnswerInstance | QuoteWizardQuestionInstance)
    > = [
      'component',
      'dataType',
      'helpText',
      'label',
      'apiName',
      'displayName',
      'method',
      'askOnRenewal',
      'prefillOnRenewal',
      'propsBlob',
      'required',
    ];
    // @ts-expect-error
    const input = fieldsToCopy.reduce(
      (acc: QuoteWizardQuestionInstanceInput, field) => {
        // @ts-expect-error
        acc[field] = this[field];
        return acc;
      },
      {},
    );

    // now sort out the propsBlob
    // @ts-expect-error
    input['propsBlob'] = imposeLanguageOnPropsBlob(
      // @ts-expect-error
      input['propsBlob'],
      language,
    );

    // now the subquestions
    // @ts-expect-error
    input.subQuestions = this.subAnswers.map((suba) =>
      suba.toQuoteWizardQuestionInstance(language),
    );

    // @ts-expect-error
    return new QuoteWizardQuestionInstance(input);
  }

  /**
   * Make a rough guess as to the QuoteWizardAnswerInstance equivalent to this one
   * @param stripNa - Whether to remove any N/A answers from any subanswers (recurses, but doesn't affect whether the parent answer is itself converted - it always is)
   */
  toQuoteWizardAnswerInstance(
    language: Language,
    stripNa: boolean = false,
  ): QuoteWizardAnswerInstance {
    const subAnswers = this.subAnswers
      .filter((suba) => !(stripNa && suba.value === 'N/A'))
      .map((suba) => suba.toQuoteWizardAnswerInstance(language, stripNa));
    const guess = new QuoteWizardAnswerInstance({
      questionInstance: this.toQuoteWizardQuestionInstance(language),
      value: this.value,
      subAnswers,
    });
    return guess;
  }

  /**
   * Is this answer for a location question with at least one answered location subquestion?
   */
  isAnsweredLocation() {
    return (
      this.component === 'location' &&
      this.subAnswers
        .filter((subAnswer) =>
          ['address', 'city', 'province', 'postal'].includes(subAnswer.apiName),
        )
        .some((subAnswer) => subAnswer.value !== 'N/A')
    );
  }

  findAnswerByAttributeInSubanswers(
    attribute: keyof UnifiedAnswerInstance,
    value: unknown,
    options: FindByAttributeInSubanswersOptions = { recurse: false },
  ) {
    let foundAnswer: UnifiedAnswerInstance | undefined;
    for (let subanswer of this.subAnswers) {
      if (subanswer[attribute] === value) {
        foundAnswer = subanswer;
        break;
      }
      if (options.recurse) {
        const foundInSubAnswers = subanswer.findAnswerByAttributeInSubanswers(
          attribute,
          value,
          options,
        );
        if (foundInSubAnswers) {
          foundAnswer = foundInSubAnswers;
          break;
        }
      }
    }
    return foundAnswer ? foundAnswer : undefined;
  }

  findValueByAttributeInSubanswers(
    attribute: keyof UnifiedAnswerInstance,
    value: unknown,
    options: FindByAttributeInSubanswersOptions = { recurse: false },
  ) {
    const foundAnswer = this.findAnswerByAttributeInSubanswers(
      attribute,
      value,
      options,
    );
    return foundAnswer ? foundAnswer.value : undefined;
  }
}
