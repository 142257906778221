import { Box, Typography, Grid, Divider } from '@mui/material';

import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { PropsWithChildren } from 'react';

interface ImageCardProps {
  heading: $TSFixMe;
  url: $TSFixMe;
}
export function ImageCard({ heading, url }: ImageCardProps) {
  return (
    <Grid
      xs={6}
      item
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pageBreakInside: 'avoid',
      }}
    >
      <Typography variant='caption' align='center' color='textSecondary'>
        {heading}
      </Typography>
      <img alt={heading} src={url} width='85%' height='85%' />
    </Grid>
  );
}

export function AddressDisplay({ children }: PropsWithChildren<{}>) {
  return (
    <Typography
      variant='subtitle2'
      color='textSecondary'
      gutterBottom
      style={{ textAlign: 'center' }}
    >
      {children}
    </Typography>
  );
}

/**
 * Display a heading and some additional information
 * @param {ReactNode} children - the children (additional information to display)
 * @param {string | null} heading - The heading of the card
 * @param {Object | null} childrenWrapperStyle - styles to apply to the children wrapper
 */
export const DetailsCard = ({ children, heading }: $TSFixMe) => {
  return (
    <Box
      sx={{
        p: 1,
        height: '100%',
        '& ul': {
          paddingLeft: '1em',
          listStyle: 'none',
        },
        '& li': {
          breakInside: 'avoid',
          py: 0.8,
        },
        '& li:before': {
          content: '"-"',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
      }}
    >
      {heading ? (
        <Typography variant='subtitle2' align='center' color='textSecondary'>
          {heading}
        </Typography>
      ) : null}
      <Typography variant='body2' color='textSecondary'>
        {children}
      </Typography>
    </Box>
  );
};

interface InfoChunkProps {
  heading: $TSFixMe;
  information: $TSFixMe;
  backgroundColor?: $TSFixMe;
}
export const InfoChunk = ({ heading, information }: InfoChunkProps) => {
  return (
    <Grid xs={6} md={4} item>
      <Typography
        variant='subtitle2'
        align='center'
        gutterBottom
        noWrap
        color='textSecondary'
      >
        {heading}
      </Typography>
      <Divider />
      <Typography
        variant='body2'
        align='center'
        sx={{
          mt: 2,
          '& ul': {
            paddingLeft: '1em',
            listStyle: 'none',
          },
          '& li': {
            py: 1,
          },
          '& li:before': {
            content: '"-"',
            display: 'inline-block',
            width: '1em',
            marginLeft: '-1em',
          },
        }}
      >
        {information}
      </Typography>
    </Grid>
  );
};
