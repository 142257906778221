import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { isBackendLogicQuestionConditionTrue } from '../QuoteComponents/Logic';
/* import { defaultInternationalRevenuePropsBlob } from '../../QuoteComponents/InternationalRevenue'; */
import { numberFormat } from '../../util/NumberUtils';
import {
  generateDisplayValue,
  generateDisplayLabel,
  /* countQuestionInAnswers, */
  isInternationalRevenueAnswerTrue,
} from './utility';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  UnifiedAnswerInstance,
  UnifiedCompletedForm,
} from '../../../Typescript/classes';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../util';
import { QuestionEditButton } from '../../QuoteWizard/QuoteReview/QuestionEditButton';
import { styled } from '@mui/material';

const QUESTION_INDENT_PIXELS = 30; // the amount to indent each additional level

// @ts-expect-error
const useQuestionAnswerPairStyles = makeStyles((theme: $TSFixMe) => ({
  label: {
    paddingLeft: (indent) => indent,
    marginBottom: theme.spacing(2),
  },
  value: {
    overflowWrap: 'anywhere',
  },
}));

type QuestionAnswerPairProps = {
  label: string;
  value: string;
  indent: QuestionAnswerDisplayProps['indent'];
  EditComponent?: $TSFixMe;
};

const QUESTION_ANSWER_LOCATIONS = ['quotePdf', 'review'] as const;
export type QuestionAnswerLocation = typeof QUESTION_ANSWER_LOCATIONS[number];

type QuestionAnswerDisplayProps = {
  form?: UnifiedCompletedForm;
  topLevelAnswer?: UnifiedAnswerInstance;
  edit?: boolean;
  answer: UnifiedAnswerInstance;
  indent: number;
  answers: Array<UnifiedAnswerInstance>;
  displayLocation?: QuestionAnswerLocation;
};

const QuestionAnswerPair = ({
  label,
  value,
  indent,
  EditComponent,
}: QuestionAnswerPairProps) => {
  const classes = useQuestionAnswerPairStyles(indent);
  return (
    <>
      <StyledDt className={classes.label}>
        {label}
        {EditComponent && <EditComponent />}
      </StyledDt>
      <dd className={classes.value}>{value}</dd>
    </>
  );
};

/**
 * Recursively navigates through the answer object and
 * their sub questions.
 *
 * @param answer The current answer instance
 * @param indent Current indentation value
 */
export function QuestionAnswerDisplay({
  form,
  edit = false,
  topLevelAnswer,
  answer,
  indent = 0,
  answers,
  displayLocation,
}: QuestionAnswerDisplayProps) {
  let displayLabel = generateDisplayLabel(answer, answers);
  let displayValue = generateDisplayValue(answer);
  let subAnswersToDisplay = answer.subAnswers ? answer.subAnswers : []; // because some question types will take care of some of the subquestions themselves
  if (answer.component === 'internationalRevenue') {
    subAnswersToDisplay = isInternationalRevenueAnswerTrue(answer)
      ? answer.subAnswers.slice(1)
      : [];
  } else if (answer.component === 'location') {
    subAnswersToDisplay = answer.subAnswers.filter(
      (subAnswer) =>
        !['address', 'city', 'province', 'postal'].includes(subAnswer.apiName),
    );
  } else if (answer.component === 'logic') {
    try {
      const isConditionTrue = isBackendLogicQuestionConditionTrue(answer);
      subAnswersToDisplay = isConditionTrue ? answer.subAnswers.slice(1) : [];
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(e);
      subAnswersToDisplay = answer.subAnswers.slice(1);
    }
  } else if (answer.component === 'checkbox') {
    displayValue =
      answer.value === 'true' || answer.value === true
        ? 'Yes'
        : answer.value === 'false' || answer.value === false
        ? 'No'
        : 'INVALID ANSWER';
  } else {
    if (
      answer.dataType &&
      answer.propsBlob &&
      (answer.dataType === 'number' || answer.dataType === 'integer') &&
      answer.component === 'slider' &&
      !Number.isNaN(Number(answer.value))
    ) {
      displayValue = numberFormat(answer.propsBlob, answer.value);
    }
  }

  const locationsToHide =
    'hideInLocations' in answer.propsBlob
      ? //@ts-expect-error
        answer.propsBlob.hideInLocations
      : [];
  // return JSON.stringify({ locationsToHide, displayLocation }, null, 4);
  if (displayLocation && locationsToHide.includes(displayLocation)) {
    return null;
  }
  // deal with repeated questions differently
  if (answer.countInAnswersList(answers) > 1) {
    /* countQuestionInAnswers(answer.questionInstance.id, answers) > 1) { */
    // indent += QUESTION_INDENT_PIXELS;

    if (answer.value === 1) {
      // Need to insert the heading here and then continue on with the rendering, all indented one further level
      return (
        <>
          <Typography
            component='h4'
            variant='body2'
            color='textSecondary'
            style={{ textDecoration: 'underline', marginBottom: '16px' }}
          >
            {answer.label}
          </Typography>
          <span></span>
          <QuestionAnswerPair
            EditComponent={
              edit
                ? () => (
                    <QuestionEditButton
                      form={form}
                      answer={answer}
                      topLevelAnswer={topLevelAnswer}
                    />
                  )
                : null
            }
            label={displayLabel}
            value={displayValue}
            indent={indent}
          />
          {subAnswersToDisplay.length > 0
            ? subAnswersToDisplay.map((subAnswer, index) => (
                <QuestionAnswerDisplay
                  form={form}
                  topLevelAnswer={topLevelAnswer}
                  answer={subAnswer}
                  edit={edit}
                  answers={answer.subAnswers}
                  indent={indent + QUESTION_INDENT_PIXELS}
                  key={subAnswer.id + '_' + index}
                />
              ))
            : null}
        </>
      );
    }
  }
  return (
    <>
      <QuestionAnswerPair
        EditComponent={
          edit
            ? () => (
                <QuestionEditButton
                  form={form}
                  answer={answer}
                  topLevelAnswer={topLevelAnswer}
                />
              )
            : null
        }
        label={displayLabel}
        value={displayValue}
        indent={indent}
      />
      {subAnswersToDisplay.length > 0
        ? subAnswersToDisplay.map((subAnswer, index, allSubAnswers) => (
            <QuestionAnswerDisplay
              form={form}
              topLevelAnswer={topLevelAnswer}
              answer={subAnswer}
              edit={edit}
              answers={allSubAnswers}
              indent={indent + QUESTION_INDENT_PIXELS}
              displayLocation={displayLocation}
              key={subAnswer.id + '_' + index}
            />
          ))
        : null}
    </>
  );
}

const StyledDt = styled('dt')(() => {
  return {
    '& .answerEditButton': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out, visibility 0s linear 0.2s',
    },
    '&:hover .answerEditButton': {
      visibility: 'visible',
      opacity: 1,
    },
  };
});
