import { Checkbox } from '@calefy-inc/informedMaterial';

import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps } from './types';

const ProgramBuilderComponent = () => {
  return (
    <Checkbox
      field='defaultValue'
      label='Should question be initially checked?'
    />
  );
};

const QuoteComponent = function ({
  questionInstance,
  classes,
  ...props
}: QuoteComponentProps) {
  return (
    <Checkbox
      field={questionInstance.generateFieldName()}
      label={questionInstance.label}
      helperText={questionInstance.helpText}
      classes={classes}
      {...props}
    />
  );
};

const IconComponent = () => {
  return <CheckBoxRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'checkbox',
    typeLabel: 'Checkbox',
    quoteComponent: QuoteComponent,
    programBuilderComponent: ProgramBuilderComponent,
    dataType: 'boolean',
    icon: IconComponent,
  });
}

export { QuoteComponent as quoteComponent };
