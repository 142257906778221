import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
// import { useAllowByPermission } from '../../../../Authentication';
import { BUSINESS_COMPLETION_PERCENTAGES } from '../../../../../queries';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ReactApexChart from 'react-apexcharts';
import { merge } from 'lodash';
import { BaseOptionChart } from '../../charts';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const CI_DISPLAY_THRESHOLD = 0.2; // if the difference between the upper and lower intervals is less than 2%, don't display them

interface BusinessCompletionPercentage {
  rawPercentage: number;
  businessDisplayName: string;
  rawBegun: number;
  rawCompleted: number;
  maximumAPosteriori: number;
  lowerCi: number;
  upperCi: number;
}

const generateIntervalDisplay = ({
  lowerCi,
  upperCi,
}: BusinessCompletionPercentage) => {
  let ciDisplay = '';
  if (
    lowerCi !== undefined &&
    upperCi !== undefined &&
    upperCi - lowerCi > CI_DISPLAY_THRESHOLD
  ) {
    // @ts-expect-error
    ciDisplay = `(${lowerCi.toFixed(2) * 100}% - ${upperCi.toFixed(2) * 100}%)`;
  }
  return ciDisplay;
};

interface ApplicationCompletionPercentageProps {
  businessCompletionPercentages: Array<BusinessCompletionPercentage>;
}
export const ApplicationCompletionPercentageDisplay = ({
  businessCompletionPercentages,
}: ApplicationCompletionPercentageProps) => {
  const sortedData = [...businessCompletionPercentages]
    .sort((a, b) => b.rawPercentage - a.rawPercentage)
    .slice(0, 10);
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: {
      bar: { horizontal: false, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      type: 'category',
    },
    yaxis: {
      labels: {
        formatter: (value: number) => `${Math.round(value * 100)}%`,
      },
    },
    tooltip: {
      custom: ({ dataPointIndex }: $TSFixMe) => {
        const instance = sortedData[dataPointIndex];
        return `<div style='padding: 4px;'>
        <div style='padding: 4px; background-color: #b4b8b4; border-radius: 8px;'>${
          instance.businessDisplayName
        }</div><div>
        ${instance.rawCompleted} / ${
          instance.rawBegun
        }: <span style="font-weight: bold;">${
          // @ts-expect-error
          instance.rawPercentage.toFixed(2) * 100
        }%</span>&nbsp<span>${generateIntervalDisplay(instance)}</span>
        </div></div>`;
      },
      y: {
        formatter: (value: number) => `${Math.round(value * 100)}%`,
      },
      x: {
        show: true,
      },
    },
  });
  return (
    <>
      <Card data-testid='business-completion-percentage'>
        <CardHeader
          title='Application Completion Percentage'
          subheader='By Type'
        />
        <Box sx={{ mx: 3 }} dir='ltr'>
          <ReactApexChart
            type='bar'
            series={[
              {
                name: 'Application Completion Percentage',
                type: 'column',
                data: sortedData.map((datum) => ({
                  x: datum.businessDisplayName,
                  y: datum.rawPercentage,
                  goals:
                    datum.lowerCi !== undefined &&
                    datum.upperCi !== undefined &&
                    datum.upperCi - datum.lowerCi > CI_DISPLAY_THRESHOLD
                      ? [
                          {
                            name: 'Lower',
                            value: datum.lowerCi,
                            strokeHeight: 5,
                            strokeColor: '#775DD0',
                          },
                          {
                            name: 'Upper',
                            value: datum.upperCi,
                            strokeHeight: 5,
                            strokeColor: '#775DD0',
                          },
                        ]
                      : [],
                })),
              },
            ]}
            // @ts-expect-error
            options={chartOptions}
            height='364'
          />
        </Box>
      </Card>
    </>
  );
};

export const ApplicationCompletionPercentage = () => {
  const [getApplicationCompletionPercentage, { loading, error, data }] =
    useLazyQuery(BUSINESS_COMPLETION_PERCENTAGES);

  useEffect(() => {
    getApplicationCompletionPercentage();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  } else if (error) {
    return (
      <>
        <p>Error:</p>
        <pre>{JSON.stringify(error, null, 4)}</pre>
      </>
    );
  } else if (data?.businessCompletionPercentages) {
    return (
      <ApplicationCompletionPercentageDisplay
        businessCompletionPercentages={data.businessCompletionPercentages}
      />
    );
  }
  return null;
};
