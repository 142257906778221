import { extractAllAnsweredLocationAnswers } from './utility';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Policy } from '../../index';
import { UnifiedAnswerInstance } from '../../classes';
import { PolicyExtension } from '../types';

interface BackendCompletedFormInput {
  id: string;
  policy?: null | Policy;
  answers?: Array<UnifiedAnswerInstance>;
  extensions?: Array<PolicyExtension>;
}
/**
 * This is the frontend analogue to the backend CompletedForm
 */
export class BackendCompletedForm {
  id: string;
  policy: null | Policy;
  answers: Array<UnifiedAnswerInstance>;
  extensions: Array<PolicyExtension>;

  constructor({ id, policy, answers, extensions }: BackendCompletedFormInput) {
    this.id = id;
    this.policy = policy ? policy : null;
    this.answers = answers ? answers : [];
    this.extensions = extensions ? extensions : [];
  }

  static generateFromBackendResponse(response: $TSFixMe) {
    const input: BackendCompletedFormInput = {
      ...response,
      answers: response.answers.map((answerResponse: $TSFixMe) =>
        UnifiedAnswerInstance.generateFromBackendResponse(answerResponse),
      ),
    };
    return new BackendCompletedForm(input);
  }

  /**
   * Takes in a form-like object and checks whether its policy matches this one's. Note that it is possible for both of the to be nullish - this should match. Otherwise they match by ID of the policy
   */
  matchByPolicy({ policy }: { policy: null | Policy }): boolean {
    if (!this.policy && !policy) {
      return true;
    }
    if (this.policy && policy && this.policy.id === policy.id) {
      return true;
    }
    return false;
  }

  /**
   * Takes in an array of form-like objects and return the one that matches the policy (if present). Note that this also matches businessForms (with policy set to null)
   */
  findMatchByPolicy<T extends { policy: null | Policy }>(formsList: Array<T>) {
    return formsList.find((form) => this.matchByPolicy(form));
  }

  /**
   * Find all of the location answers with at least one valid resonse in address, city, province, postal
   */
  allAnsweredLocationAnswers() {
    return extractAllAnsweredLocationAnswers(this.answers);
  }

  /**
   * Returns the label (display name) for this form
   */
  label() {
    return this.policy ? this.policy.displayName : 'General Information';
  }
}
