import { Icon } from '@iconify/react';
import { useState } from 'react';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {
  Box,
  Table,
  Collapse,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';
// Location components
import { DisplayGeneralInformation } from '../../../../../common/QuoteLoader/LocationDetails/DisplayGeneralInformation';
import { DisplayDynamicStreetView } from '../../../../../common/QuoteLoader/LocationDetails/DisplayDynamicStreetView';
import { DisplayFusData } from '../../../../../common/QuoteLoader/LocationDetails/DisplayFusData';
import { DisplayFloodScoreData } from '../../../../../common/QuoteLoader/LocationDetails/DisplayFloodScoreData';
import { DisplayIclarifyCommercialConstructionData } from '../../../../../common/QuoteLoader/LocationDetails/DisplayiClarifyCommercialConstructionData';
import { DisplayCommercialPerilScoreData } from '../../../../../common/QuoteLoader/LocationDetails/DisplayOptaCommercialPerilScoreData';
// ----------------------------------------------------------------------

export default function LocationTable({
  location,
  index,
  setLocationData,
  loadQuotePdf,
  isArchived,
}) {
  const [open, setOpen] = useState(false);
  const address = [
    location.general.streetAddress,
    location.general.city,
    location.general.province,
    location.general.postalCode,
  ]
    .filter((ele) => ele !== 'N/A')
    .join(', ');

  return (
    <>
      <TableRow
        key={index}
        sx={{
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <TableCell align='left'>
          <Box sx={{ maxWidth: 560 }}>
            <Typography variant='subtitle2'>{address}</Typography>
          </Box>
        </TableCell>

        <TableCell align='center'>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            <Icon icon={open ? arrowIosUpwardFill : arrowIosDownwardFill} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='locations'>
                <TableBody>
                  <LocationDetails
                    title='General'
                    location={location}
                    setLocationData={setLocationData}
                    LocationComponent={DisplayGeneralInformation}
                  />
                  <LocationDetails
                    title='Street View'
                    location={location}
                    setLocationData={setLocationData}
                    LocationComponent={DisplayDynamicStreetView}
                  />
                  <LocationDetails
                    title='Opta'
                    LocationComponent={() => (
                      <>
                        <DisplayFusData
                          details={location}
                          setLocationData={setLocationData}
                          loadQuotePdf={loadQuotePdf}
                          isArchived={isArchived}
                        />
                        <DisplayFloodScoreData
                          details={location}
                          setLocationData={setLocationData}
                          loadQuotePdf={loadQuotePdf}
                        />
                        <DisplayIclarifyCommercialConstructionData
                          details={location}
                          setLocationData={setLocationData}
                          loadQuotePdf={loadQuotePdf}
                        />
                        <DisplayCommercialPerilScoreData
                          details={location}
                          setLocationData={setLocationData}
                          loadQuotePdf={loadQuotePdf}
                        />
                      </>
                    )}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// ----------------------------------------------------------------------

function LocationDetails({
  title,
  location,
  setLocationData,
  LocationComponent,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell align='left'>
          <Box sx={{ maxWidth: 560 }}>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              {title}
            </Typography>
          </Box>
        </TableCell>

        <TableCell width={40} align='center'>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            <Icon icon={open ? arrowIosUpwardFill : arrowIosDownwardFill} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align='center'>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ maxWidth: 'auto', padding: 1 }}>
              <LocationComponent
                details={location}
                setLocationData={setLocationData}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
