import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import Bugsnag from '@bugsnag/js';

const MAX_RETRIES = 5;
const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: (count, _operation, error) => {
    //console.log('Notifying on bad attempt');
    Bugsnag.notify(error);
    if (count <= MAX_RETRIES) {
      return true;
    }
    return false;
  },
});

type createRemoteClientArgument = {
  uri: string;
};
export default function createRemoteClient({
  uri,
}: createRemoteClientArgument) {
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({ uri });

  return new ApolloClient({
    cache,
    link: from([retryLink, httpLink]),
  });
}
