import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Pagebreak } from '../utils';
import { fCurrency } from '../../../ManagementPanel/utils/formatNumber';
import { format } from 'date-fns';
import { generateBlobUrlFromBase64String } from '@calefy-inc/utility';
// hooks
import { useSettings, useUsingVagoSettings } from '../../../../hooks';

import { SummaryPageDataDisplay } from './SummaryPageDataDisplay';

// classes and types
import type { QuotePdfComponentPageProps } from './types';
import type { BackendImage } from '../../../../Typescript/backend/types';
//-------------------------------------------------------------------------

interface ImageWithUrl extends BackendImage {
  url: string;
}

export const SummaryPage = ({ quote }: QuotePdfComponentPageProps) => {
  const { logo } = useSettings();
  const onVago = useUsingVagoSettings();
  const [locationImages, setLocationImages] = useState<Array<BackendImage>>([]);
  const [imagesWithURLs, setImagesWithURLs] = useState<Array<ImageWithUrl>>([]);

  // set the locations to display images for
  useEffect(() => {
    if (quote) {
      // VaGo doesn't want the image
      if (onVago) {
        return;
      }
      const allLocations = quote.allUniqueAnsweredLocationAnswers();
      const locationsWithImages = allLocations.filter(
        (location) =>
          location?.details?.images && location.details.images.length > 0,
      );
      if (locationsWithImages.length === 0) {
        setLocationImages([]);
        return;
      }

      const mailingAddress = locationsWithImages.find((locationAnswer) =>
        locationAnswer.apiName.match(/mailing address/i),
      );
      const addressToDisplay = mailingAddress
        ? mailingAddress
        : locationsWithImages[0];

      // actually we want to grab:
      // low magnification
      // medium magnification
      setLocationImages(
        [
          ...(addressToDisplay && addressToDisplay.details
            ? addressToDisplay.details.images
            : []),
        ]
          .sort((a, b) => {
            if (a.shortDescription.match(/low magnification/i)) {
              return -1;
            }
            if (b.shortDescription.match(/low magnification/i)) {
              return 1;
            }

            if (
              a.shortDescription.match(/medium magnification/i) &&
              !b.shortDescription.match(/low magnification/i)
            ) {
              return -1;
            }
            if (
              b.shortDescription.match(/medium magnification/i) &&
              !a.shortDescription.match(/low magnification/i)
            ) {
              return 1;
            }
            return a.shortDescription.localeCompare(b.shortDescription);
          })
          .slice(0, 2),
      );
    }
  }, [quote, onVago]);

  // to generate the image URLs
  useEffect(() => {
    if (locationImages.length > 0) {
      (async () => {
        const amendedImages = [];
        for (let image of locationImages) {
          const imageURL = await generateBlobUrlFromBase64String(image.data);
          amendedImages.push({
            ...image,
            url: imageURL,
          });
        }
        setImagesWithURLs(amendedImages);
      })();
    }
  }, [locationImages]);

  return (
    <Box
      sx={{
        '& img': {
          maxWidth: 300,
        },
      }}
    >
      <img src={logo} alt='logo' />

      <Divider sx={{ my: 2 }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container alignItems='center' spacing={1}>
          <SummaryPageDataDisplay
            title='Line:'
            content={quote.businessLine.displayName}
          />
          <SummaryPageDataDisplay
            title='Company Name:'
            content={quote.structuredData.businessName}
          />
          <SummaryPageDataDisplay
            title='Client Name:'
            content={quote.structuredData.contactName}
          />
          <SummaryPageDataDisplay
            title='Address:'
            content={quote.structuredData.contactAddress?.formattedAddress}
          />
          <SummaryPageDataDisplay
            title='Email:'
            content={quote.structuredData.contactEmail}
          />
          <SummaryPageDataDisplay
            title='Phone:'
            content={quote.structuredData.contactPhoneNumber}
          />
          <SummaryPageDataDisplay
            title='Revenue:'
            content={
              quote.structuredData.revenue
                ? fCurrency(quote.structuredData.revenue)
                : undefined
            }
          />
          <SummaryPageDataDisplay
            title='Submission Date:'
            content={
              quote.dateAdded
                ? format(new Date(quote.dateAdded), 'MMMM d, yyyy')
                : undefined
            }
          />
          <SummaryPageDataDisplay
            title='Broker: '
            content={
              quote.structuredData.alreadyContactedBrokerName ?? undefined
            }
          />
        </Grid>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Grid container direction='row' spacing={1} justifyContent='center'>
        {imagesWithURLs.map((image) => (
          <Grid
            item
            xs={6}
            sx={{
              '& img': {
                maxWidth: '100%',
              },
            }}
          >
            <img
              src={image.url}
              alt={image.shortDescription}
              style={{ borderRadius: '10px' }}
            />
          </Grid>
        ))}
      </Grid>

      <Pagebreak />
    </Box>
  );
};
