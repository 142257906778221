import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { FinalFormModifier } from '../../classes';
import { useDispatch } from 'react-redux';
import {
  setCurrentlyEditingFinalFormModifier,
  setBusiness,
  setPolicy,
} from '../../../../../../../store/FormModifierStore';

interface UploadFormModifierFromJsonProps {
  originalForm: FinalFormModifier;
}
export const UploadFormModifierFromJson = ({
  originalForm,
}: UploadFormModifierFromJsonProps) => {
  const [jsonData, setJsonData] = useState<$TSFixMe>();
  const [formModifier, setFormModifier] = useState<FinalFormModifier>();
  const [formModifierDialogOpen, setFormModifierDialogOpen] =
    useState<boolean>(false);
  const [error, setError] = useState<$TSFixMe>();
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  // when there's an error, open the modal
  useEffect(() => {
    if (error) {
      setErrorDialogOpen(true);
    }
  }, [error]);

  // when we have the data, try to parse it into a form modifier
  useEffect(() => {
    if (jsonData) {
      try {
        const data = jsonData['data']['finalFormModifier'];
        const form = FinalFormModifier.generateFromBackendResponse(data);
        form.business = originalForm.business;
        form.policy = originalForm.policy;
        setFormModifier(form);
      } catch (e) {
        setError(e);
      }
    } else {
      setFormModifier(undefined);
    }
  }, [jsonData, originalForm]);

  // if we have the form modifier, open the confirmation form; otherwise no
  useEffect(() => {
    if (!formModifier) {
      setFormModifierDialogOpen(false);
    } else if (
      formModifier &&
      originalForm &&
      formModifier.id !== originalForm.id
    ) {
      setFormModifierDialogOpen(true);
    }
  }, [formModifier, originalForm]);

  return (
    <>
      <Button
        disabled={!!originalForm.id}
        component='label'
        role={undefined}
        variant='contained'
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Create from Upload
        <HiddenInput
          type='file'
          onChange={(e) => {
            //@ts-expect-error
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
              try {
                // @ts-expect-error
                const parsedJson = JSON.parse(e.target?.result);
                setJsonData(parsedJson);
              } catch (e) {
                setError(e);
                console.error(e);
              }
            };

            reader.readAsText(file);
          }}
        />
      </Button>
      <ErrorDialog
        error={error}
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
      />
      <ConfirmUploadDialog
        open={formModifierDialogOpen}
        onClose={() => setFormModifierDialogOpen(false)}
        formModifier={formModifier}
        onConfirm={(form) => {
          setJsonData(undefined);
          dispatch(setBusiness(form.business));
          dispatch(setPolicy(form.policy));
          dispatch(
            setCurrentlyEditingFinalFormModifier(
              form.copyWithAmendments({ id: undefined }),
            ),
          );
        }}
      />
    </>
  );
};

interface UploadDialogCommonProps {
  open: boolean;
  onClose: () => void;
}

interface ErrorDialogProps extends UploadDialogCommonProps {
  error: $TSFixMe;
}
const ErrorDialog = ({ error, open, onClose }: ErrorDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Error Uploading Form Modifier</DialogTitle>
      <DialogContent>
        {error ? (
          <>
            {error.message ? (
              <>
                <code>{error.message}</code>
                <br />
              </>
            ) : null}
            {error.stack ? (
              <>
                <code>{error.stack}</code>
                <br />
              </>
            ) : null}{' '}
            {!error.message && !error.stack ? (
              <>
                <code>{JSON.stringify(error, null, 4)}</code>
                <br />
              </>
            ) : null}
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConfirmUploadDialogProps extends UploadDialogCommonProps {
  formModifier?: FinalFormModifier;
  onConfirm: (f: FinalFormModifier) => void;
}
const ConfirmUploadDialog = ({
  open,
  onClose,
  formModifier,
  onConfirm,
}: ConfirmUploadDialogProps) => {
  if (!formModifier) {
    return null;
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Confirm Final Form Modifier</DialogTitle>
      <DialogContent>
        {formModifier.apiNamesForRemoval.length > 0 ? (
          <>
            <Typography component='h4' variant='subtitle1'>
              API Names for Removal
            </Typography>
            <ul>
              {formModifier.apiNamesForRemoval.map((apiName) => (
                <li key={apiName}>
                  <Typography>{apiName}</Typography>
                </li>
              ))}
            </ul>
          </>
        ) : null}
        {formModifier.questionInstances.length > 0 ? (
          <>
            <Typography component='h4' variant='subtitle1'>
              Questions for Addition / Modification
            </Typography>
            <ul>
              {formModifier.questionInstances.map((q) => (
                <li key={q.apiName}>
                  <Typography>
                    {q.labels.map((label) => label.value).join(' / ')} (
                    {q.apiName})
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onClose();
            onConfirm(formModifier);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
