import { graphql } from '../gql';

export const submitAnswerMutation = graphql(`
  mutation submitAnswers(
    $languageCode: String!
    $businessLineId: ID
    $completedFormInputs: [CompletedFormInput]!
    $clientEmail: String
    $clientName: String
    $message: String
    $status: String
    $currentQuoteUUID: String
    $signature: String
    $referralCode: String
    $additionalInformation: String
    $renewalInProgress: Boolean!
    $token: String
    $producerId: String
  ) {
    createQuote(
      languageCode: $languageCode
      businessLineId: $businessLineId
      completedForms: $completedFormInputs
      clientEmail: $clientEmail
      clientName: $clientName
      message: $message
      status: $status
      uniqueId: $currentQuoteUUID
      signature: $signature
      referralCode: $referralCode
      additionalInformation: $additionalInformation
      renewalInProgress: $renewalInProgress
      token: $token
      producerId: $producerId
    ) {
      ok
      quote {
        id
        uniqueID
        status
        dateAdded
        businessLine {
          id
          displayName
        }
        structuredData {
          businessLegalName
          businessName
          businessTradeName
        }
        premiumProposals {
          identifier
          status
          insurer {
            name
          }
          policies {
            ...PolicyFields
          }
          amountInCents
        }
      }
    }
  }
`);
