/**Function which correctly takes the modulo of a number. Note that the modulo must be positive. */
export function correctMod(num: number, modulo: number) {
  if (modulo <= 0) {
    throw new TypeError(`Received modulo ${modulo} which was 0 or negative`);
  }
  if (num < 0) {
    return (num % modulo) + modulo;
  }
  return num % modulo;
}
