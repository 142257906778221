import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

// for the full-screen loading page as everything happens
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { BUSINESS_TYPE, SOME_FINAL_FORMS } from '../../queries';
import {
  selectBusinessType,
  setResumeToStep,
  setPolicies,
  setBlockContactInfoFirst,
  setProducerId,
} from '../../store/QuoteWizardState';
import { validateBusinessId } from './utility';

// classes and types
import type { $TSFixMe, ArrayElement } from '@calefy-inc/utilityTypes';
import type { RouteComponentProps } from '@reach/router';
import type { StoreState } from '../../store';
import { QuoteWizardForm } from '../QuoteWizard/classes';
import { ProgramBuilderForm } from '../FormManager/classes';
import { PolicySummary } from '../QuoteWizard/types';
import Bugsnag from '@bugsnag/browser';
import {
  createApplicationBegunThunk,
  createFormLoadedThunk,
} from '../../store/analyticsStore';

export interface BusinessLinkProps extends RouteComponentProps {
  businessId?: number | string; // although it says id, it can be the id or the internal name
}
/**
 * Component which loads in a business and (optionally) a set of policies, puts those in state, and then redirects the user to the quote wizard with the appropriate business and policies selected. The business ID (or internal name) is determined from the URL, and the optional list of policies are sent using search params of the form ?policy=internal_name_1&policy=internal_name_2&...
 */
export const BusinessLink = ({ businessId }: BusinessLinkProps) => {
  const dispatch = useDispatch();

  const [isBusinessIdValid, setIsBusinessIdValid] = useState<
    boolean | 'unknown'
  >('unknown');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [requestedPolicyInternalNames, setRequestedPolicyInternalNames] =
    useState<Array<string>>([]);
  const [producerIdReady, setProducerIdReady] = useState<boolean>(false); // check that we've dealt with with the producer id (put it in state if relevant)
  const [readyToResume, setReadyToResume] = useState<boolean>(false);
  const [readyToAbort, setReadyToAbort] = useState<boolean>(false); // has something gone wrong in a way that we need to abort out to the quotes page?

  // need to be dumped into the store
  const [business, setBusiness] = useState<$TSFixMe | null>(null);
  const [forms, setForms] = useState<Array<QuoteWizardForm>>([]);
  const [selectedPolicies, setSelectedPolicies] = useState<
    Array<PolicySummary>
  >([]);

  // Check the store - if all of these exist then we are done loading
  const selectedBusiness = useSelector(
    (state: StoreState) => state.quoteWizard.selectedBusinessType,
  );
  const resumeToStep = useSelector(
    (state: StoreState) => state.quoteWizard.resumeToStep,
  );
  const language = useSelector(
    (state: StoreState) => state.quoteWizard.selectedLanguage,
  );
  const determineIfAnyRelevantFormsLoaded = useCallback(
    (state: StoreState) => {
      if (requestedPolicyInternalNames.length == 0) {
        //console.log(
        // 'determineIfAnyRelevantFormsLoaded: No requested policies - returning true',
        // );
        return true;
      }
      const loaded = requestedPolicyInternalNames.some(
        (requestedPolicyInternalName) =>
          state.quoteWizard.policyForms
            .map((form) => form.policy?.internalName)
            .includes(requestedPolicyInternalName),
      );
      //console.log(
      //   `determineIfAnyRelevantFormsLoaded: loaded at least one of ${requestedPolicyInternalNames}- returning true`,
      // );
      return loaded;
    },
    [requestedPolicyInternalNames],
  );
  const anyRelevantFormsLoaded = useSelector(determineIfAnyRelevantFormsLoaded);

  const [
    getBusiness,
    { loading: businessLoading, error: businessError, data: businessData },
  ] = useLazyQuery(BUSINESS_TYPE);
  const [
    getBusinessForms,
    {
      // called: businessFormsCalled,
      loading: businessFormsLoading,
      error: businessFormsError,
      data: businessFormsData,
    },
  ] = useLazyQuery(SOME_FINAL_FORMS);

  // on initial load, get the requested policies, whitelabel (for testing), and producer id
  useEffect(() => {
    // get the policy search params
    const params = new URLSearchParams(window.location.search);
    const policies = params.getAll('policy');
    //console.log({ policies });
    setRequestedPolicyInternalNames(policies);

    const producerId = params.get('producerId');
    if (producerId) {
      dispatch(setProducerId(producerId));
    }
    setProducerIdReady(true);
  }, []);

  // determine whether or not the id is valid
  useEffect(() => {
    const isValid = validateBusinessId(businessId);
    setIsBusinessIdValid(isValid);
  }, [businessId, setIsBusinessIdValid]);

  // get the business as soon as we know the id is generateFromBackendResponse
  useEffect(() => {
    if (businessId !== undefined && isBusinessIdValid === true && getBusiness) {
      getBusiness({
        // @ts-expect-error
        variables: Number.isNaN(Number(businessId)) // if we can convert it to a number it's an id; otherwise an internal name (the query can take either)
          ? { internalName: businessId }
          : {
              id: businessId,
            },
      });
    }
  }, [businessId, isBusinessIdValid, getBusiness]);

  // set the business type as soon as we know it
  useEffect(() => {
    if (businessData && businessData.businessType) {
      setBusiness(businessData.businessType);
    }
    // check for no businessType returned
    if (businessData && !businessData.businessType) {
      setError(
        new Error(
          `No business data returned: ${JSON.stringify(businessData, null, 4)}`,
        ),
      );
    }
  }, [businessData]);

  // once we have the business, set it in the store and dispatch the application begun thunk
  useEffect(() => {
    if (business) {
      dispatch(selectBusinessType({ selectedBusinessType: business }));
      dispatch(createApplicationBegunThunk());
    }
  }, [business, dispatch]);

  // if there are requested policies, get those forms from the backend
  useEffect(() => {
    //console.log({ requestedPolicyInternalNames, isBusinessIdValid });
    if (
      requestedPolicyInternalNames.length > 0 &&
      isBusinessIdValid === true &&
      selectedBusiness
    ) {
      //console.log('About to get business forms');
      getBusinessForms({
        variables: {
          businessId:
            'id' in selectedBusiness
              ? selectedBusiness.id
              : selectedBusiness.original.id,
        },
      });
    }
  }, [
    requestedPolicyInternalNames,
    businessId,
    isBusinessIdValid,
    selectedBusiness,
  ]);

  // once the forms are gotten from the backend, load them into state and set the selected policies
  useEffect(() => {
    if (businessFormsData) {
      //console.log('About to process business forms data');
      const programBuilderForms: Array<ProgramBuilderForm> =
        // @ts-expect-error
        businessFormsData.someFinalForms
          .filter(
            (response: $TSFixMe) =>
              !response.policy ||
              requestedPolicyInternalNames.includes(
                response.policy.internalName,
              ),
          )
          .map((response: $TSFixMe) =>
            ProgramBuilderForm.generateFromBackendResponse(response),
          );

      // check that all requested policies were loaded, and notify (but don't abort) if not
      const loadedPolicies = programBuilderForms.reduce(
        (acc: Array<string>, form) => {
          if (form.policy) {
            return [...acc, form.policy.displayName];
          }
          return acc;
        },
        [],
      );
      if (loadedPolicies.length === 0) {
        // in this case, there is a mismatch between the policy forms available and the requested ones - none of the requested ones are valid for this business type. Time to abort and rely on the bugsnag to let us know that something is wrong here.
        setReadyToAbort(true);
      } else if (loadedPolicies.length < requestedPolicyInternalNames.length) {
        Bugsnag.notify(
          new Error(
            `Error: unable to load all requested policies in Businesslink. Requested: ${requestedPolicyInternalNames}; loaded: ${loadedPolicies}`,
          ),
        );
      }
      dispatch(setBlockContactInfoFirst(true));

      const selectedPolicies: Array<PolicySummary> = [];
      programBuilderForms.forEach(
        (pbForm: ArrayElement<typeof programBuilderForms>) => {
          if (
            pbForm.policy &&
            pbForm.policy.internalName &&
            requestedPolicyInternalNames.includes(pbForm.policy.internalName)
          ) {
            selectedPolicies.push(pbForm.generatePolicySummary());
          }
        },
      );
      //console.log('Set selected policies to', selectedPolicies);
      setSelectedPolicies(selectedPolicies);

      const quoteWizardForms = programBuilderForms.map((pbForm) =>
        pbForm.toQuoteWizardForm(language),
      );

      setForms(quoteWizardForms);
    }
  }, [businessFormsData]);

  // once the forms are loaded, set them into state (including setting selectedPolicies)
  useEffect(() => {
    //console.log({ forms, selectedPolicies });
    if (forms.length === 0 || selectedPolicies.length === 0) {
      return;
    }

    // dispatch the thunk
    forms.forEach((form) => dispatch(createFormLoadedThunk(form)));

    let businessForm: QuoteWizardForm;
    let policyForms: Array<QuoteWizardForm> = [];
    forms.forEach((form) => {
      if (!form.policy) {
        businessForm = form;
      } else {
        policyForms.push(form);
      }
    });
    // @ts-expect-error
    if (!businessForm) {
      setError(
        new Error(
          `Unable to load a general information form from ${JSON.stringify(
            forms,
            null,
            4,
          )}`,
        ),
      );
    }
    //console.log('About to dispatch setPolicies');
    dispatch(
      setPolicies({
        selectedPolicies,
        // @ts-expect-error
        businessForm,
        policyForms,
      }),
    );
  }, [forms, selectedPolicies]);

  // once the business is in state and the forms have been loaded, set where to resume to
  useEffect(() => {
    if (selectedBusiness && anyRelevantFormsLoaded) {
      // i.e. this is only the business link
      if (requestedPolicyInternalNames.length == 0) {
        //console.log('dispatching resume to policy');
        dispatch(setResumeToStep('policy'));
      } // business & policy
      else {
        //console.log('dispatching resume to General Information');
        dispatch(setResumeToStep('General Information'));
      }
    }
  }, [
    dispatch,
    selectedBusiness,
    anyRelevantFormsLoaded,
    requestedPolicyInternalNames,
  ]);

  // once everything is in state, indicate that we are about to resume the quote
  useEffect(() => {
    //console.log({ selectedBusiness, anyRelevantFormsLoaded });
    if (
      selectedBusiness &&
      anyRelevantFormsLoaded &&
      resumeToStep &&
      producerIdReady
    ) {
      setReadyToResume(true);
    }
  }, [
    selectedBusiness,
    resumeToStep,
    anyRelevantFormsLoaded,
    dispatch,
    producerIdReady,
  ]);

  // if any of the errors are present, set the error flag
  useEffect(() => {
    setError(
      businessError ||
        businessFormsError ||
        (isBusinessIdValid === false
          ? new Error(`Error: ${businessId} is not a valid business id`)
          : false) ||
        undefined,
    );
  }, [businessError, isBusinessIdValid, businessFormsError, businessId]);

  // if anything is loading, set that as the state
  useEffect(() => {
    const possibleLoading = [businessLoading, businessFormsLoading];
    setLoading(possibleLoading.some((loading) => !!loading));
  }, [businessLoading, businessFormsLoading]);

  if (readyToAbort) {
    //console.log(`Error in businesslink - aborting`);
    Bugsnag.notify(
      new Error(
        `Aborting load in businesslink: businessId=${businessId}, requestedPolicyInternalNames=${requestedPolicyInternalNames}`,
      ),
    );
    if (selectedBusiness) {
      dispatch(setResumeToStep('policy'));
    }
    navigate('/insurtech/quote');
  }

  if (readyToResume) {
    navigate(`/insurtech/quote`);
  }

  if (error) {
    Bugsnag.notify(error);
    console.error('Error setting up businessLink', error);
    navigate('/insurtech/quote');
    // return <pre>{JSON.stringify(error, null, 4)}</pre>;
  }

  if (loading) {
    return (
      <>
        <Backdrop open={true}>
          <CircularProgress color='secondary' size='2rem' />
        </Backdrop>
      </>
    );
  }

  return null;
};

/**
 * Generate the BusinessLink URL for a given business and (optionally) set of policies
 * @param businessId - The id or internal name of the business
 * @param policy - optinally, a list of internal names for the requested policies
 * @returns - the BusinessLink URL
 */
export const generateBusinessLinkUrl = (
  businessId: string | number,
  policies: Array<string> = [],
  producerId?: string,
): string => {
  const paramsAsObject: object = {};
  if (policies.length > 0) {
    // @ts-expect-error
    paramsAsObject['policy'] = policies;
  }
  if (producerId) {
    // @ts-expect-error
    paramsAsObject['producerId'] = producerId;
  }
  return (
    `/insurtech/businessLink/${businessId}` +
    (Object.keys(paramsAsObject).length > 0
      ? `?${convertToUrlParams(paramsAsObject)}
      `
      : '')
  );
  // policies
  //   .map((name, index) => `${index === 0 ? '?' : '&'}policy=${name}`)
  //   .join('') +
  // (producerId ? `&producerId=${producerId}` : '')
};

const convertToUrlParams = (obj: object) => {
  const params = Object.entries(obj).reduce(
    (acc: Array<string>, [key, value]) => {
      if (Array.isArray(value)) {
        return [
          ...acc,
          ...value.map((eachValue) => encodeUrlParamPair(key, eachValue)),
        ];
      }
      return [...acc, encodeUrlParamPair(key, value)];
    },
    [],
  );
  return params.join('&');
};

const encodeUrlParamPair = (key: string, value: string) => {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};
