import { useState, useEffect, useRef } from 'react';
import { Form, ArrayField } from 'informed';
import { useLazyQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import ClearIcon from '@mui/icons-material/Clear';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';

import {
  Checkbox,
  Select,
  MultiSelect,
  TextField,
  EmailInput,
} from '@calefy-inc/informedMaterial';
import GoogleMap from './GoogleMap';
import ShallowProspectsQuery from './ShallowProspectsQuery';

import { provinceOptions } from './constants';
import { QUERY_MUNICIPALITIES } from './queries';

const ProspectsForm = ({ client, onSubmit }) => {
  const [municipalities, setMunicipalities] = useState();
  const [fieldTimers, setFieldTimers] = useState([]);
  const [fieldSearchOk, setFieldSearchOk] = useState([]);
  const [searchCircles, setSearchCircles] = useState([]);
  const asyncSearchCircles = useRef(searchCircles);
  const updateSearchCircles = (newSearchCircles) => {
    asyncSearchCircles.current = newSearchCircles;
    setSearchCircles(asyncSearchCircles.current);
  };

  const [submitMunicipalitiesQuery, municipalityStatus] = useLazyQuery(
    QUERY_MUNICIPALITIES,
    {
      client: client,
    },
  );

  const doneTypingInterval = 1000;
  const fieldHandleKeyUp = (index) => {
    //stop searching on this field
    const newFieldSearchOk = [...fieldSearchOk];
    newFieldSearchOk[index] = false;
    setFieldSearchOk(newFieldSearchOk);

    //set a timeout to make it OK to search on this field in a bit
    clearTimeout(fieldTimers[index]);
    const newFieldTimers = [...fieldTimers];
    newFieldTimers[index] = setTimeout(
      handleDoneTyping,
      doneTypingInterval,
      index,
    );
    setFieldTimers(newFieldTimers);
  };
  const handleDoneTyping = (index) => {
    clearTimeout(fieldTimers[index]);

    const newFieldSearchOk = [...fieldSearchOk];
    newFieldSearchOk[index] = true;
    setFieldSearchOk(newFieldSearchOk);
  };

  //check if we already know the munnicipalities for that province, and if not go get them
  const handleProvinceChange = (e) => {
    if (!municipalities || !municipalities[e.target.value]) {
      submitMunicipalitiesQuery({ variables: { province: e.target.value } });
    }
  };

  //handle when the selected province changes
  useEffect(() => {
    if (municipalityStatus.called && municipalityStatus.data) {
      setMunicipalities((municipalities) =>
        Object.assign({}, municipalities, {
          [municipalityStatus.data.municipalities.province]:
            municipalityStatus.data.municipalities.municipalities,
        }),
      );
    }
  }, [municipalityStatus.data, municipalityStatus.called]);

  return (
    <Form
      onSubmit={(values) => {
        const amendedValues = Object.assign({}, values, {
          searchCircles: asyncSearchCircles.current.map((c) => ({
            centre: {
              lat: c.getCenter().lat(),
              long: c.getCenter().lng(),
            },
            radius: c.getRadius(),
          })),
        });
        onSubmit(amendedValues);
      }}
      validate={(values) =>
        asyncSearchCircles.current.length === 0
          ? 'Must select at least one search circle'
          : undefined
      }
      style={{ marginBottom: '20px' }}
    >
      {({ formState, formApi }) => {
        if (formState.values.province === undefined) {
          const defaultProvince = 'AB';
          formApi.setValue('province', defaultProvince);
          submitMunicipalitiesQuery({
            variables: {
              province: defaultProvince,
            },
          });
        }
        return (
          <>
            <EmailInput
              field='email'
              type='email'
              label='Email: '
              fullWidth
              required
            />

            <br />
            <br />

            <GoogleMap
              searchCirclesRef={asyncSearchCircles}
              updateSearchCircles={updateSearchCircles}
              error={
                formState.submits > 0 && asyncSearchCircles.current.length === 0
              }
            />

            <Grid container style={{ alignItems: 'center' }}>
              <ArrayField field='queries'>
                {({ add, arrayFieldState, arrayFieldApi, ...rest }) => {
                  if (arrayFieldState.fields.length === 0) {
                    arrayFieldApi.add();
                  }
                  return (
                    <>
                      <ArrayField.Items>
                        {({ remove, field, index }) => {
                          // debugger;
                          return (
                            <>
                              <Grid item xs={9}>
                                <TextField
                                  field={field}
                                  label='Query Term'
                                  onKeyUp={(e) => fieldHandleKeyUp(index)}
                                  fullWidth
                                  required={index === 0}
                                  variant='standard'
                                />
                              </Grid>
                              <Grid item xs={2}>
                                {fieldSearchOk[index] &&
                                formState.values.queries &&
                                formState.values.queries[index] ? (
                                  <ShallowProspectsQuery
                                    client={client}
                                    province={formState.values.province || 'AB'}
                                    municipalities={
                                      formState.values.municipalities &&
                                      formState.values.municipalities.length ===
                                        0
                                        ? undefined
                                        : formState.values.municipalities
                                    }
                                    query={formState.values.queries[index]}
                                  />
                                ) : null}
                              </Grid>
                              <Grid item xs={1}>
                                <Tooltip title='Remove query'>
                                  <span>
                                    <IconButton
                                      variant='contained'
                                      onClick={remove}
                                      disabled={
                                        arrayFieldState.fields.length === 1
                                      }
                                    >
                                      <ClearIcon
                                        style={{
                                          color:
                                            arrayFieldState.fields.length === 1
                                              ? grey[500]
                                              : red[500],
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </>
                          );
                        }}
                      </ArrayField.Items>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={add}
                        style={{ marginTop: '1em' }}
                      >
                        Add Query
                      </Button>
                    </>
                  );
                }}
              </ArrayField>
            </Grid>

            <Select
              field='province'
              label='Province / Territory'
              required={true}
              interiorLabel={true}
              options={provinceOptions}
              onChange={(e) => {
                handleProvinceChange(e);
                formApi.setValue('municipalities', []);
              }}
              fullWidth
              variant='standard'
            />

            {municipalityStatus.loading ? (
              <Skeleton variant='rect' width='100%'>
                <MultiSelect
                  fullWidth
                  field='dummy'
                  label='dummy'
                  variant='standard'
                />
              </Skeleton>
            ) : (
              <MultiSelect
                field='municipalities'
                label='Municipalities'
                interiorLabel={true}
                disabled={!formState.values.province}
                fullWidth
                options={
                  municipalities &&
                  formState.values.province &&
                  municipalities[formState.values.province]
                    ? municipalities[formState.values.province].map(
                        (city, index) => ({ value: city, label: city }),
                      )
                    : undefined
                }
                variant='standard'
              />
            )}

            <Checkbox field='strict' label='Strict?' />

            <br />

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                visibility: formState.values.strict ? 'visible' : 'hidden',
              }}
            >
              <WarningIcon color='secondary' />
              <p style={{ marginTop: 0, marginLeft: '0.5rem' }}>
                Warning! Setting Strict mode will aggressively filter the
                results to ensure that they are in the search area. This may
                result in discarding many valid results. Use with care!
              </p>
            </div>

            <br />

            <Button variant='contained' type='submit'>
              Submit Search
            </Button>
          </>
        );
      }}
    </Form>
  );
};

export default ProspectsForm;
