import theme from './VapeInsuranceTheme';
import logo from './assets/LogoLight.png';
import favicon from './assets/favicon.ico';
import squareLogo from './assets/VapeInsuranceSquareLogo.png';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import { Icon } from '@iconify/react';
import smokeIcon from '@iconify-icons/wi/smoke';
import DomainIcon from '@mui/icons-material/Domain';
import StoreIcon from '@mui/icons-material/Store';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

const tileIcons = (internalName: string) => {
  switch (internalName) {
    case 'manufacturer_of_vape_ecigarettes':
      return <DomainIcon color='inherit' fontSize='large' />;
    case 'retail_vape_ecigarette':
      return <StoreIcon color='inherit' fontSize='large' />;
    case 'wholesaler_of_vape_ecigarette_products':
      return <Icon icon={smokeIcon} width={'35px'} />;

    default:
      return <Icon icon={smokeIcon} width={'35px'} />;
  }
};

const businessFilter = (business: $TSFixMe) => {
  const allowedBusinessNames = [
    'manufacturer_of_vape_ecigarettes',
    'retail_vape_ecigarette',
    'wholesaler_of_vape_ecigarette_products',
  ];
  if (
    process.env.NODE_ENV !== 'production' ||
    window.location.origin === 'https://staging-calefy.fuseinsurance.ca'
  ) {
    allowedBusinessNames.push('testing_business');
  }
  return allowedBusinessNames.includes(business.internalName);
};

// Vape Insurance Config
const settings = new WebsiteSettings({
  slug: 'vapeinsurance',
  host: 'secure.vapeinsurance.ca',
  companyName: 'Fuse Insurance Ltd',
  hours: '8:30am - 5:30pm',
  phone: '1-866-387-3873',
  email: 'vape@fuseinsurance.ca',
  chatLink: 'https://www.facebook.com/FuseInsuranceCA',
  companyTheme: theme,
  logo: logo,
  businessFilter: businessFilter,
  tileIcons: tileIcons,
  title: 'Vape Insurance: Vape Business Insurance In Canada',
  favicon,
  squareLogo,
});

export default settings;
