import { BusinessType } from '../BusinessType';

export class BusinessTypeAlias implements Omit<BusinessType, 'id'> {
  original: BusinessType;
  displayName: BusinessType['displayName'];
  internalName: BusinessType['internalName'];

  constructor(business: BusinessType, alias: string) {
    this.original = business;
    this.displayName = alias;
    this.internalName = business.internalName;
  }

  getMangledId() {
    return `${this.original.id}_${this.displayName}`;
  }
}
