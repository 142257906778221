import { useEffect } from 'react';
import DragDropFormModifierBuilder from './DragDrop/DragDropFormModifierBuilder';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../../../../store';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// components
import UpdateButton from './components/UpdateButton';
// import { FormPreviewButton } from '../components/FormPreview';
import { CreateQuestionInstance } from '../components/CreateQuestionInstance';
// import FormSettings from '../components/FormSettings';
import { ErrorBoundary } from '../../../../../../common/ErrorHandling';
import { ErrorView } from '../../../../../../common/ErrorView';

import { useLazyQuery } from '@apollo/client';
import { ALL_LANGUAGES } from '../../../../../../../queries';
// import { DeleteFormButton } from '../components';

// types and classes
import { Language } from '../../../../../../../Typescript/classes';
import { setLanguages } from '../../../../../../../store/FormModifierStore';
import { DeleteQuestions, ModifyQuestion } from '../components';
import { UploadFormModifierFromJson } from './UploadFormModifierFromJson';

/* Helper Function */
const getCurrentlyEditingForm = (state: StoreState) =>
  state.formModifierStore.currentlyEditingFinalFormModifier;

type FormModifierBuilderProps = {};
export function FormModifierBuilder({}: FormModifierBuilderProps) {
  const dispatch = useDispatch();
  const form = useSelector(getCurrentlyEditingForm);
  // NB we will set a default language in the ProgramBuilderWizard
  const [loadLanguages, { data }] = useLazyQuery(ALL_LANGUAGES);

  // get the list of all available languages in the ProgramBuilder
  useEffect(() => {
    loadLanguages();
  }, [loadLanguages]);

  // once the languages are loaded, set them in the state
  useEffect(() => {
    if (data?.allLanguages) {
      dispatch(
        setLanguages(
          data.allLanguages.map((backendLanguage) =>
            // @ts-expect-error
            Language.createFromObject(backendLanguage),
          ),
        ),
      );
    }
  }, [data]);

  if (!form) {
    return null;
  }

  return (
    <>
      <Stack
        data-testid='form-display'
        mb={3}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Typography
          data-testid={`sub-form-prompt-${form.id}`}
          color='textSecondary'
          variant='h6'
          sx={{ mb: 2 }}
        >
          {`${
            typeof form.business != 'string'
              ? form.business.displayName
              : 'All Businesses'
          } - ${form.policy ? form.policy.displayName : 'General Information'}`}
        </Typography>
        {/* <FormSettings form={form} /> */}
      </Stack>

      <Stack
        mb={3}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent='flex-end'
        spacing={2}
      >
        <UploadFormModifierFromJson originalForm={form} />
        <CreateQuestionInstance form={form} />
        <DeleteQuestions form={form} />
        <ModifyQuestion form={form} />
        <UpdateButton />
      </Stack>

      {/* @ts-expect-error */}
      <ErrorBoundary FallbackComponent={ErrorView}>
        <DragDropFormModifierBuilder form={form} />
      </ErrorBoundary>
    </>
  );
}
