// material
import { Container, Grid } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import {
  AppNewQuotes,
  AppSubmissionTimes,
  AppMonthlyQuotes,
  AppTotalQuotes,
  AppTotalPoliciesToday,
  AppTopBusinessTypes,
  AppTotalQuotesToday,
  AppLocationMap,
  // ApplicationCompletionPercentage,
  GenericBusinessWordcloud,
} from '../../components/_dashboard/general-dashboard';

// types and classes
import { RouteComponentProps } from '@reach/router';
import { PropsWithChildren } from 'react';

// ----------------------------------------------------------------------

interface GeneralAppProps extends RouteComponentProps {}
export default function GeneralApp(_props: GeneralAppProps) {
  const { themeStretch } = useSettings();

  return (
    <Page title='General: Dashboard'>
      <Container maxWidth={themeStretch ? false : 'lg'} data-testid='dashboard'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppTotalQuotesToday />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppTotalPoliciesToday />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppTotalQuotes />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppTopBusinessTypes />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppMonthlyQuotes />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppLocationMap />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppSubmissionTimes />
          </Grid>

          <GenericBusinessWordcloud
            Container={({ children }: PropsWithChildren<{}>) => (
              <Grid item xs={12} md={6} lg={6} sx={{ m: 2 }}>
                {children}
              </Grid>
            )}
          />

          {/* <Grid item xs={12} lg={12}> */}
          {/*   <ApplicationCompletionPercentage /> */}
          {/* </Grid> */}

          <Grid item xs={12} lg={12}>
            <AppNewQuotes />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
