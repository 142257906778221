/**
 * All of these functions should have been superseded
 *
 *
 *
 *
 *
 *
 *
 */

import { GqlAnswerInstance } from '../../../queries/Types/Types';

/**
 * @deprecated Use the appropriate function on the BackendCompletedForm (or wherever you are getting the answersList) instead
 */
export function findValueByNameInAnswerList(
  name: string,
  answerList: GqlAnswerInstance[],
): string | undefined {
  if (!answerList || answerList.length === 0) {
    return undefined;
  }
  for (let index = 0; index < answerList.length; index++) {
    const answer = answerList[index];
    if (answer.name === name) {
      return answer.data;
    }
    const foundInSubAnswers = findValueByNameInAnswerList(
      name,
      answer.subAnswers,
    );
    if (foundInSubAnswers !== undefined) {
      return foundInSubAnswers;
    }
  }
  return undefined;
}

/**
 *
 * @deprecated Use the quote.structuredData.companyName field instead - that's why it exists!
 * @param name - name of the answers to be found
 * @param answers - list of answers to search for a specific value
 * @param findValueByNameInAnswerList - function that searches for a value within a list of answers
 * @returns - business name or not found message
 */
export function findLegalOrTradeName(answers: GqlAnswerInstance[]) {
  const legalName = findValueByNameInAnswerList('Company Name', answers);
  const tradeName = findValueByNameInAnswerList('trade name', answers);
  let businessName: string;

  if (legalName !== undefined && tradeName !== undefined) {
    businessName = `${legalName} o/a ${tradeName}`;
  } else if (legalName !== undefined) {
    businessName = legalName;
  } else if (tradeName !== undefined) {
    businessName = tradeName;
  } else {
    businessName = '...';
  }

  return businessName;
}

/**
 *
 * @deprecated Use the quote.structuredData.companyName field instead - that's why it exists!
 * @param structuredData - object containing quote data calculated on back end
 * @param businessLine - current business lines used to check if the current line is personal
 * @returns - business name or not found message
 */
//@ts-ignore
export function findLegalOrTradeNameFromStructuredData(structuredData, line) {
  if (!structuredData) {
    return '...';
  }
  const legalName = structuredData.businessLegalName;
  const tradeName = structuredData.businessTradeName;
  const businessName = structuredData.businessName;
  const clientName = structuredData.contactName;

  let name;

  if (line === 'Personal') {
    if (clientName) {
      name = clientName;
    } else {
      name = '...';
    }
  } else {
    if (businessName) {
      name = businessName;
    } else if (legalName) {
      name = legalName;
    } else if (tradeName) {
      name = tradeName;
    } else {
      name = '...';
    }
  }

  return name;
}
