import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useUsingWalnutSettings } from '../../WhiteLabelSettings/Walnut/useUsingWalnutSettings';

import {
  Environments,
  Products,
  GAEventNames,
  EventActions,
  GoogleAnalyticsEvent,
} from './types';

const WALNUT_GA_CODE = 'G-BLZ6Y5C0CG';

class GoogleAnalyticsProvider {
  private sponsorCode: string;
  private product: Products;
  private event: any;
  private environment: Environments;

  constructor(
    environment: Environments,
    sponsorCode: string,
    product: Products,
  ) {
    this.environment = environment;
    this.event = ReactGA.event;
    this.sponsorCode = sponsorCode;
    this.product = product;
  }
  triggerEvent(
    eventName: GAEventNames,
    action: EventActions,
    description: string,
  ): void {
    const eventData: GoogleAnalyticsEvent = {
      action: action,
      sponsorCode: this.sponsorCode,
      description: description,
      product: this.product,
      environment: this.environment,
    };

    if (process.env.NODE_ENV === 'production') {
      this.event(eventName, eventData);
    } else {
      //console.log(
      //   `MOCKED trigger GA call with eventName=${eventName}`,
      //   eventData,
      // );
    }
  }
}

ReactGA.initialize(WALNUT_GA_CODE);

export const useWalnutGoogleAnalytics = () => {
  const onWalnut = useUsingWalnutSettings();
  const [sponsorCode, setSponsorCode] = useState<string>('');
  const [analytics, setAnalytics] = useState<GoogleAnalyticsProvider>();

  // set the sponsor code on load (NB won't work on development)
  useEffect(() => {
    const host = window.location.host.toLowerCase();
    const sponsorCode = host.split('.')[0];
    setSponsorCode(sponsorCode);
  }, []);

  // set the provider once we have the sponsor code
  useEffect(() => {
    if (!onWalnut) {
      //console.log('Not on walnut; aborting google analytics initialization')
      return;
    }
    if (sponsorCode) {
      setAnalytics(
        new GoogleAnalyticsProvider(
          process.env.NODE_ENV === 'production'
            ? Environments.Production
            : Environments.Development,
          sponsorCode,
          Products.Commercial,
        ),
      );
    }
  }, [sponsorCode, onWalnut]);

  //console.log('About to return analytics', analytics);
  return analytics;
};
