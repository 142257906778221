import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAuth } from '@calefy-inc/authentication';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { QUOTE_INSURANCE_POLICIES } from '../../../../../queries';
import { Quote } from '../../../../../Typescript/backend/classes';
import { InsurancePolicy } from '../../../../../Typescript/classes';
import { InsurancePolicyDisplay } from './InsurancePolicyDisplay';
import { NoInsurancePolicies } from './NoInsurancePolicies';
import { SupportLink } from '../../../../common/SupportLink';

interface PremiumOverridesProps {
  quote: Quote;
}
export const PremiumOverrides = ({ quote }: PremiumOverridesProps) => {
  const { token } = useAuth();
  const [
    getQuoteInsurancePolicies,
    { loading: getLoading, error: getError, data: getData },
  ] = useLazyQuery(QUOTE_INSURANCE_POLICIES);
  const [insurancePolicies, setInsurancePolicies] = useState<
    Array<InsurancePolicy>
  >([]);

  useEffect(() => {
    if (token && !quote.allBound()) {
      getQuoteInsurancePolicies({
        variables: {
          token,
          uniqueId: quote.uniqueId,
        },
      });
    }
  }, [token, quote]);

  useEffect(() => {
    if (
      getData?.quoteInsurancePolicies &&
      getData.quoteInsurancePolicies.length > 0
    ) {
      const insurancePolicies = getData.quoteInsurancePolicies.map(
        (backendInsurancePolicy) => {
          const policy = InsurancePolicy.createFromBackendResponse(
            backendInsurancePolicy,
          );
          policy.coverages.forEach((coverage) => {
            coverage.perils.forEach((peril) => {
              peril.premium = Math.round(peril.premium);
            });
          });
          return policy;
        },
      );
      setInsurancePolicies(insurancePolicies);
    }
  }, [getData]);

  if (!token) {
    return null;
  }
  if (
    quote.bindings.length > 0 &&
    quote.bindings.every((binding) => binding.bound)
  ) {
    return <QuoteBoundComponent quote={quote} />;
  }
  return (
    <PremiumOverridesContainer>
      {getError ? <GetErrorComponent quote={quote} error={getError} /> : null}
      {getLoading ? <CircularProgress /> : null}
      {!getError && !getLoading && insurancePolicies.length === 0 ? (
        <NoInsurancePolicies quote={quote} />
      ) : (
        insurancePolicies.map((insurancePolicy) => {
          return (
            <InsurancePolicyDisplay
              quote={quote}
              insurancePolicy={insurancePolicy}
              key={insurancePolicy.id}
            />
          );
        })
      )}
    </PremiumOverridesContainer>
  );
};

interface GetErrorComponentProps {
  quote: Quote;
  error: Error;
}
const GetErrorComponent = ({ quote, error }: GetErrorComponentProps) => {
  return (
    <>
      <ErrorP>
        Error when attempting to load insurance policies for{' '}
        {quote.getDisplayId()}
      </ErrorP>
      <ErrorPre>{JSON.stringify(error, null, 4)}</ErrorPre>
    </>
  );
};

const PremiumOverridesContainer = styled('div')(({ theme }) => {
  return {
    paddingBottom: theme.spacing(3),
  };
});

interface QuoteBoundComponentProps {
  quote: Quote;
}
const QuoteBoundComponent = ({ quote }: QuoteBoundComponentProps) => {
  return (
    <Typography sx={{ mb: 3 }}>
      This quote has been bound. As a result, the premiums can no longer be
      overridden. If you believe this to be an error, please contact{' '}
      <SupportLink
        subject={`Editing premiums for bound quote ${quote.uniqueId}`}
      />
      .
    </Typography>
  );
};

const ErrorP = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
const ErrorPre = styled('pre')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});
