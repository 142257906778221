import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import { TextField, TextArea } from '@calefy-inc/informedMaterial';
import { useFormApi, useFormState } from 'informed';
import { sanitizeForApiName } from './utility';
import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material';

// types and classes
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { Language } from '../../../../../../../../../Typescript/classes';

const useAccordionStyles = makeStyles((theme: Theme) => {
  return {
    accordionSummaryUnanswered: {
      backgroundColor: grey[200],
    },
    accordionSummaryPartiallyAnswered: {
      // @ts-expect-error
      backgroundColor: theme.palette.warning.lighter,
    },
    accordionSummaryFullyAnswered: {
      // @ts-expect-error
      backgroundColor: theme.palette.success.lighter,
    },
    accordionSummaryError: {
      // @ts-expect-error
      backgroundColor: theme.palette.error.lighter,
    },
    accordionDetails: {},
  };
});

interface LanguageFieldsProps {
  setApiNameWhenNameSet?: boolean;
  languages: Array<Language>;
  language: Language;
  classes?: GenericObject;
}
export function LanguageFields({
  setApiNameWhenNameSet,
  languages,
  language,
  classes = {},
}: LanguageFieldsProps) {
  const accordionClasses = useAccordionStyles();
  const formState = useFormState();
  const [requiredQuestionsAnswered, setRequiredQuestionsAnswered] =
    useState<boolean>(false);
  const [anyQuestionsAnswered, setAnyQuestionsAnswered] =
    useState<boolean>(false);
  const [accordionSummaryCompletionClass, setAccordionSummaryCompletionClass] =
    // TODO restrict this to the actual classes in useAccordionStyles
    useState<string>('');

  const [open, setOpen] = useState<boolean>(
    language.equals(Language.defaultLanguage),
  );

  // see if any questions relevant to this language have been answered
  useEffect(() => {
    const languageAnyRegex = new RegExp(`(${language.shortName})_.+`);
    const languageAnyAnswered =
      formState.values &&
      Object.entries(formState.values).some(
        ([k, v]) => v && languageAnyRegex.exec(k),
      );
    setAnyQuestionsAnswered(languageAnyAnswered);
  }, [formState, formState.values, language]);

  // see if the required questions have been answered
  useEffect(() => {
    const languageDisplayNameRegex = new RegExp(
      `(${language.shortName})_displayNames`,
      'i',
    );
    const languageLabelRegex = new RegExp(
      `(${language.shortName})_labels`,
      'i',
    );

    const languageDisplayNamesAnswered =
      formState.values &&
      Object.entries(formState.values).some(
        ([k, v]) => v && languageDisplayNameRegex.exec(k),
      );
    const languageLabelAnswered =
      formState.values &&
      Object.entries(formState.values).some(
        ([k, v]) => v && languageLabelRegex.exec(k),
      );
    setRequiredQuestionsAnswered(
      languageDisplayNamesAnswered && languageLabelAnswered,
    );
  }, [formState, formState.values, language]);

  // set the appropriate completion class on the accordion summary
  useEffect(() => {
    let newClass: string;
    if (requiredQuestionsAnswered) {
      newClass = accordionClasses.accordionSummaryFullyAnswered;
    } else if (anyQuestionsAnswered) {
      newClass = accordionClasses.accordionSummaryPartiallyAnswered;
    } else {
      newClass = accordionClasses.accordionSummaryUnanswered;
    }
    setAccordionSummaryCompletionClass(newClass);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredQuestionsAnswered, anyQuestionsAnswered]);

  const relevantErrors =
    formState.error &&
    typeof formState.error !== 'string' &&
    language.shortName in formState.error &&
    formState.error[language.shortName].length > 0
      ? formState.error[language.shortName]
      : [];
  const mergedClasses = { ...classes, ...accordionClasses };
  const toggleOpen = () => setOpen((oldValue: boolean) => !oldValue);

  return languages.length === 0 ? null : languages.length === 1 ? (
    <>
      <DisplayIndividualLanguageErrors
        errors={relevantErrors}
        language={language}
      />
      <Questions
        setApiNameWhenNameSet={setApiNameWhenNameSet}
        language={language}
        classes={mergedClasses}
      />
    </>
  ) : (
    <Accordion expanded={open} onChange={toggleOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={[
          relevantErrors.length > 0 ? mergedClasses.accordionSummaryError : '',
          accordionSummaryCompletionClass,
        ].join(' ')}
      >
        <Typography>
          {`${relevantErrors.length > 0 ? `Invalid - ` : ''}${
            language.fullName
          } Fields`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DisplayIndividualLanguageErrors
          errors={relevantErrors}
          language={language}
        />
        <Questions
          setApiNameWhenNameSet={setApiNameWhenNameSet}
          language={language}
          classes={mergedClasses}
        />
      </AccordionDetails>
    </Accordion>
  );
}

// The actual contents - textfields for the questions
interface QuestionsProps {
  language: Language;
  setApiNameWhenNameSet?: boolean;
  classes?: GenericObject;
}
function Questions({
  language,
  setApiNameWhenNameSet = true,
  classes = {},
}: QuestionsProps) {
  const formApi = useFormApi();

  return (
    <>
      <TextField
        fullWidth
        className={classes.textField}
        classes={classes}
        label={`Field Name (${language.fullName})`}
        field={language.generatePrefixedString('displayNames')}
        id={language.generatePrefixedString('displayNames')}
        name={language.generatePrefixedString('displayNames')}
        variant='standard'
        onChange={
          setApiNameWhenNameSet && language.equals(Language.defaultLanguage)
            ? (e: $TSFixMe) => {
                formApi.setValue('apiName', sanitizeForApiName(e.target.value));
              }
            : undefined
        }
      />

      <TextField
        fullWidth
        id={language.generatePrefixedString('labels')}
        name={language.generatePrefixedString('labels')}
        field={language.generatePrefixedString('labels')}
        className={classes.textField}
        classes={classes}
        label={`Question Prompt (${language.fullName})`}
        helperText={`Enter the question label displayed to the client in ${language.fullName}`}
        maxRows='4'
        variant='standard'
      />
      <TextArea
        fullWidth
        id={language.generatePrefixedString('helpTexts')}
        name={language.generatePrefixedString('helpTexts')}
        field={language.generatePrefixedString('helpTexts')}
        className={classes.textarea}
        classes={classes}
        label={`Question Help Text (${language.fullName})`}
        helperText='Enter any additional instructions that will be helpful to the user'
        rowsMax='4'
      />
    </>
  );
}

const StyledUl = styled('ul')(({ theme }) => ({
  color: theme.palette.error.main,
  listStyle: 'disc inside none',
}));

interface DisplayIndividualLanguageErrorsProps {
  language: Language;
  errors: Array<string>;
}
function DisplayIndividualLanguageErrors({
  language,
  errors,
}: DisplayIndividualLanguageErrorsProps) {
  return errors.length === 0 ? null : errors.length === 1 ? (
    <Typography color='error'>{errors[0]}</Typography>
  ) : (
    <>
      <Typography color='error'>
        There were errors associated with the {language.fullName} fields:
      </Typography>
      <StyledUl>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </StyledUl>
    </>
  );
}
