//types and classes
import { FormValues } from 'informed';
import { BasicAddress } from '../../../../Typescript';
import { QuoteWizardAnswerInstance } from '../../../QuoteWizard/classes';

export const mailingAddressRegex = /^mailing.address$/i;

const searchFormStateArrayForMailingAddress = (
  answersArray: Array<QuoteWizardAnswerInstance>,
): BasicAddress | undefined => {
  if (answersArray.length === 0) {
    return undefined;
  }
  for (let answersObject of answersArray) {
    const foundInAnswer = searchFormStateObjectForMailingAddress(answersObject);
    if (foundInAnswer) {
      return foundInAnswer;
    }
  }
  return undefined;
};

const searchFormStateObjectForMailingAddress = (
  answersObject: object,
): BasicAddress | undefined => {
  if (!answersObject || Object.keys(answersObject).length !== 0) {
    return;
  }
  for (let key of Object.keys(answersObject)) {
    // if (key === 'Mailing Address') {
    if (mailingAddressRegex.exec(key)) {
      const mailingAddress = {
        // @ts-expect-error
        address: answersObject[key]['address'],
        // @ts-expect-error
        city: answersObject[key]['city'],
        // @ts-expect-error
        province: answersObject[key]['province'],
        // @ts-expect-error
        postal: answersObject[key]['postalCode'],
      };
      return mailingAddress;
    }
    // @ts-expect-error
    if (Array.isArray(answersObject[key])) {
      const foundInAnswer = searchFormStateArrayForMailingAddress(
        // @ts-expect-error
        answersObject[key],
      );
      if (foundInAnswer) {
        return foundInAnswer;
      }
    } else if (
      // @ts-expect-error
      typeof answersObject[key] === 'object' &&
      // @ts-expect-error
      answersObject[key] !== null
    ) {
      const foundInAnswer = searchFormStateObjectForMailingAddress(
        // @ts-expect-error
        answersObject[key],
      );
      if (foundInAnswer) {
        return foundInAnswer;
      }
    }
  }
  return undefined;
};

export const getMailingAddressFromFormStateAnswers = (
  answers: FormValues,
): BasicAddress | undefined => {
  if (!answers || Object.keys(answers).length === 0) {
    return;
  }
  for (let key of Object.keys(answers)) {
    // if (key === 'Mailing Address') {
    if (mailingAddressRegex.exec(key)) {
      let mailingAddress: BasicAddress;
      if (Array.isArray(answers[key])) {
        mailingAddress = {
          // @ts-expect-error
          address: answers[key][0]['address'],
          // @ts-expect-error
          city: answers[key][0]['city'],
          // @ts-expect-error
          province: answers[key][0]['province'],
          // @ts-expect-error
          postal: answers[key][0]['postal'],
        };
      } else {
        mailingAddress = {
          // @ts-expect-error
          address: answers[key]['address'],
          // @ts-expect-error
          city: answers[key]['city'],
          // @ts-expect-error
          province: answers[key]['province'],
          // @ts-expect-error
          postal: answers[key]['postal'],
        };
      }
      return mailingAddress;
    }
    if (Array.isArray(answers[key])) {
      // @ts-expect-error
      const foundInAnswer = searchFormStateArrayForMailingAddress(answers[key]);
      if (foundInAnswer) {
        return foundInAnswer;
      }
    } else if (typeof answers[key] === 'object' && answers[key] !== null) {
      const foundInAnswer = searchFormStateObjectForMailingAddress(
        // @ts-expect-error
        answers[key],
      );
      if (foundInAnswer) {
        return foundInAnswer;
      }
    }
  }
  return undefined;
};
