import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { useLazyQuery } from '@apollo/client';

import { useIframeQueryParams } from '../../../hooks';
import { generateBusinessLinkUrl } from '../../BusinessLink/BusinessLink';
import { ActionButtonProps } from './types';
import { useHandleIframe } from './utility';
import { BUSINESS_TYPE } from '../../../queries';
import Bugsnag from '@bugsnag/browser';
import { ImperativeNavigateLink } from './ImperativeNavigateLink';

export const GenericBusinessGetQuoteButton = ({
  formApi,
  questionInstances,
}: ActionButtonProps) => {
  const [getBusiness, { data, error }] = useLazyQuery(BUSINESS_TYPE);
  const { businessId, policies } = useIframeQueryParams();
  // @ts-expect-error
  const handle = useHandleIframe({
    ...{
      formApi,
      toBusiness: false,
      businessId,
      policies,
      questionInstances,
      generateNavigationDestination: ({ businessId, policies }) =>
        !businessId
          ? '/insurtech/quote'
          : generateBusinessLinkUrl(businessId, policies),
      useImperativeNavigateLink: true,
    },
    ...(data?.businessType ? { businessLine: data.businessType } : {}),
  });

  useEffect(() => {
    if (businessId) {
      getBusiness({
        variables: {
          [Number.isNaN(Number(businessId)) ? 'internalName' : 'id']:
            businessId,
        },
      });
    }
  }, [businessId]);

  if (error) {
    Bugsnag.notify(error);
  }
  return (
    <>
      <Button
        variant='contained'
        onClick={() => {
          // @ts-expect-error
          handle();
        }}
      >
        Get A Quote
      </Button>
      <ImperativeNavigateLink target='_blank' />
    </>
  );
};
