import { graphql } from '../gql';

export const MARK_QUOTE_AS_CONFIRMATION_IN_PROGRESS = graphql(`
  mutation markQuoteAsConfirmationInProgress($hash: UUID!) {
    markQuoteAsConfirmationInProgress(hash: $hash) {
      ok
      uuid
    }
  }
`);
