import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from '@reach/router';

// classes and types
import type { GenericObject } from '@calefy-inc/utilityTypes';
import type { Quote } from '../../../../Typescript/backend/classes';

interface BaseInvalidHashErrorProps {
  hash: Quote['uniqueId'];
  classes: GenericObject;
}
export const BaseInvalidHashError = ({
  hash,
  classes = {},
}: BaseInvalidHashErrorProps) => {
  const navigate = useNavigate();
  const quoteLocation = '/insurtech/quote';

  return (
    <div className={classes.outerContainer} data-testid='invalid-hash-error'>
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          Invalid Link
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          The unique identifier that you provided ({hash}) is not valid. Please
          double check that you used the right link to get here. If you believe
          that you are receiving this message in error, please contact us at{' '}
          <a
            href={`mailto:support@calefy.ca?subject=Application Correspondence ( ${hash} ) error`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>

        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => navigate(quoteLocation)}
        >
          Return to Applications
        </Button>
      </div>
    </div>
  );
};

export const InvalidHashError = withStyles((theme) => ({
  outerContainer: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))(BaseInvalidHashError);
