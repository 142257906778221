import { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate } from '@reach/router';
import { setProducerId } from '../../store/QuoteWizardState';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';

const getProducerId = (store: StoreState) => {
  return store.quoteWizard.producerId;
};

interface ProducerIdLinkProps extends RouteComponentProps {
  producerId?: string;
}
export const ProducerIdLink = ({ producerId }: ProducerIdLinkProps) => {
  const dispatch = useDispatch();
  const producerIdInState = useSelector(getProducerId);
  const [readyToNavigate, setReadyToNavigate] = useState<boolean>(false);

  // initially, dispatch the producer id
  useEffect(() => {
    if (producerId) {
      dispatch(setProducerId(producerId));
    } else {
      // if not producer id, then just go
      setReadyToNavigate(true);
    }
  }, [producerId]);

  // check if the producer id is set in state; if so, then navigate
  useEffect(() => {
    if (producerId && producerId === producerIdInState) {
      setReadyToNavigate(true);
    }
  }, [producerId, producerIdInState]);

  // navigate when we're ready
  useEffect(() => {
    if (readyToNavigate) {
      navigate('/insurtech/quote');
    }
  }, [readyToNavigate]);

  return (
    <>
      <h1>Producer Link!</h1>
      <pre>{JSON.stringify(producerId, null, 4)}</pre>
    </>
  );
};
