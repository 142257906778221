import { useState, useEffect } from 'react';
import { useParams } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import { styled } from '@mui/system';
import {
  useSettings,
  useTileSelectorSettings,
  useWarnBeforeUnloadWithAnswers,
} from '../../../hooks';
import QuoteBusinessSelection from '../QuoteBusinessSelection';
// import QuotePolicySelection from '../QuotePolicySelection';
import { StepWizardChildProps } from 'react-step-wizard';
import { Form } from 'informed';
import { FormControl, FormLabel, Typography } from '@mui/material';
import { TileSelector } from '@calefy-inc/informedMaterial';
import { NextButton } from '../NavigationButtons';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { useDispatch } from 'react-redux';
import {
  actions,
  selectBusinessType,
  // setResumeToStep,
} from '../../../store/QuoteWizardState';
import { useSelector } from 'react-redux';
import { BUSINESS_TYPE } from '../../../queries';
import { handleErrors, DisconnectionError } from '../../common/ErrorHandling';
import { ErrorView } from '../../common/ErrorView';
import LoadingScreen from '../../ManagementPanel/components/LoadingScreen';

// types and classes
import { StoreState } from '../../../store';

//--------------------------------------------------------------
// Styling

const StyledForm = styled(Form)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
  margin: 'auto',
  maxWidth: '60rem',
  '& label': {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
  },
  // Smaller Screens
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
  },
  [theme.breakpoints.only('md')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(12)}`,
    maxWidth: '50rem',
  },
}));

const NavButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(2),
  width: '100%',
}));

//--------------------------------------------------------------
// Typescript
interface QuoteTypeSelectionProps extends StepWizardChildProps {}

interface QuoteTypeSelectorProps extends StepWizardChildProps {
  companyInsurType: string;
}

const options = [
  {
    value: 'personal',
    label: 'Personal',
    tileIdentifier: 'personal',
  },
  {
    value: 'commercial',
    label: 'Commercial/Business',
    tileIdentifier: 'commercial',
  },
];

//--------------------------------------------------------------
// Component

const TypeSelector = ({
  nextStep,
  goToStep,
  companyInsurType,
}: QuoteTypeSelectorProps) => {
  useWarnBeforeUnloadWithAnswers();
  const dispatch = useDispatch();
  const params = useParams();
  const { settings, tileClasses } = useTileSelectorSettings();

  //--------------------------------------------------------------
  // State
  const [hasInsurTypeBeenSelected, setHasInsurTypeBeenSelected] =
    useState<boolean>(false);

  //--------------------------------------------------------------
  // Selectors

  const selectedInsurType = useSelector(
    (state: StoreState) => state.quoteWizard.selectedInsurType,
  );

  //--------------------------------------------------------------
  // Queries & Mutations

  const [loadPersonalBusinessType, { data, loading, error }] = useLazyQuery(
    BUSINESS_TYPE,
    {
      variables: {
        internalName: 'personal',
      },
    },
  );

  //--------------------------------------------------------------
  // State Managers

  // if a selectedInsurType has been set, fetch the personal business
  useEffect(() => {
    if (selectedInsurType && selectedInsurType === 'personal') {
      loadPersonalBusinessType();
    }
  }, [selectedInsurType, loadPersonalBusinessType]);

  // if we have the personal business type, set business in store to personal and resume at policy
  useEffect(() => {
    if (data?.businessType) {
      dispatch(
        selectBusinessType({
          // @ts-expect-error
          selectedBusinessType: data.businessType,
        }),
      );
      goToStep(2);
    }
  }, [data, dispatch, goToStep]);

  // if there is only one option set selected insurance type
  useEffect(() => {
    const type =
      params?.directTo &&
      (params?.directTo === 'personal' || params?.directTo === 'commercial')
        ? params?.directTo === companyInsurType || companyInsurType === 'both'
          ? params?.directTo
          : companyInsurType
        : companyInsurType;
    if (type === 'personal' || type === 'commercial') {
      dispatch(actions.selectInsurType({ selectedInsurType: type }));
    }
  }, [companyInsurType, dispatch, params?.directTo]);

  //--------------------------------------------------------------
  // Handlers

  const handleTypeSelectorSubmission = (values: $TSFixMe) => {
    dispatch(actions.selectInsurType({ selectedInsurType: values.insurType }));
  };

  const handlePersonalError = (error: $TSFixMe) => {
    if (error?.networkError) {
      return (
        <DisconnectionError
          reset={() => {
            loadPersonalBusinessType();
          }}
        />
      );
    } else {
      return <ErrorView />;
    }
  };

  const handleOptions = (companyInsurType: string) => {
    if (companyInsurType === 'personal') {
      return [options[0]];
    } else if (companyInsurType === 'commercial') {
      return [options[1]];
    } else {
      return [...options];
    }
  };

  //--------------------------------------------------------------
  // Status

  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return handleErrors(error, handlePersonalError);
  }

  //console.log('QuoteTypeSelection:', { selectedInsurType });
  return (
    <>
      {!selectedInsurType ? (
        <StyledForm
          id='InsuranceType'
          noValidate
          onSubmit={(values: $TSFixMe) => {
            handleTypeSelectorSubmission(values);
          }}
        >
          {({ formState, formApi }) => (
            <FormControl sx={{ mb: 3 }}>
              <FormLabel htmlFor='quote-type'>
                <Typography variant='h5'>
                  What would you like to insure?
                </Typography>
              </FormLabel>

              <TileSelector
                name='insur-type'
                inputId='insur-type'
                aria-label='insur Type'
                field='insurType'
                onChange={() => {
                  setHasInsurTypeBeenSelected(true);
                }}
                options={handleOptions(companyInsurType)}
                settings={settings}
                tileClasses={tileClasses}
              />

              <NavButtons>
                <NextButton
                  onClick={() => {
                    //@ts-expect-error
                    if (formState.values.insurType) {
                      formApi.submitForm();
                    }
                  }}
                  disabled={!hasInsurTypeBeenSelected}
                />
              </NavButtons>
            </FormControl>
          )}
        </StyledForm>
      ) : (
        //@ts-expect-error
        <QuoteBusinessSelection nextStep={nextStep} />
      )}
    </>
  );
};

/**
 * The first component of the quote wizard, this page checks the white label to see if
 * the brokerage has personal lines then updates the store and displays the apporpriate component
 *
 * @return - quote type selector or quote business selection component
 */
const QuoteTypeSelection = ({
  nextStep,
  goToStep,
}: QuoteTypeSelectionProps) => {
  const { personalInsurance, companyInsurType } = useSettings();

  return personalInsurance ? (
    // @ts-expect-error
    <TypeSelector
      nextStep={nextStep}
      goToStep={goToStep}
      companyInsurType={companyInsurType}
    />
  ) : (
    <QuoteBusinessSelection
      // @ts-expect-error
      nextStep={nextStep}
      // @ts-expect-error
      companyInsurType={companyInsurType}
    />
  );
};

export default QuoteTypeSelection;
