/**
 * Determines whether or not a potential business id is valid. Roughly: if it's a number, can we convert it to an integer? (More detailed validation will take place on the backend, where if it is invalid we just won't get a business back)
 */
export function validateBusinessId(businessId: unknown): boolean {
  if (typeof businessId !== 'string') {
    return false;
  }
  if (businessId === '') {
    return false;
  }
  if (!/^\w+$/i.test(businessId)) {
    return false;
  }
  const asNumber = Number(businessId);
  if (!Number.isNaN(asNumber)) {
    if (!Number.isInteger(asNumber)) {
      return false;
    }
    if (asNumber < 0) {
      return false;
    }
  }
  return true;
}
