import { useState, useEffect } from 'react';
import { Card, Grid, CardHeader, CardContent, Typography } from '@mui/material';

import {
  CommercialPerilScoreProduct,
  CommercialPerilScoreHighlights,
} from './classes';

import { DetailsCard } from './commonComponents';

import { NO_INFO_STRING } from './constants';
import { $TSFixMe } from '@calefy-inc/utilityTypes';

interface DisplayCommercialPerilScoreHighlightsProps {
  commercialPerilScoreProduct: $TSFixMe;
}
const DisplayCommercialPerilScoreHighlights = ({
  commercialPerilScoreProduct,
}: DisplayCommercialPerilScoreHighlightsProps) => {
  const [highlights, setHighlights] = useState<CommercialPerilScoreHighlights>(
    new CommercialPerilScoreHighlights(commercialPerilScoreProduct),
  );

  useEffect(() => {
    setHighlights(
      new CommercialPerilScoreHighlights(commercialPerilScoreProduct),
    );
  }, [commercialPerilScoreProduct]);

  const toDisplay = highlights.generateHighlightsToDisplay();

  if (!toDisplay || toDisplay.length === 0) {
    return 'No highlights';
  }
  return (
    <Grid
      container
      spacing={3}
      sx={{
        margin: 0,
        '& li': {
          breakInside: 'avoid',
          py: 1,
        },
        '& li:before': {
          content: '"-"',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
        '& ul': {
          paddingLeft: '1em',
          listStyle: 'none',
        },
      }}
    >
      {toDisplay}
    </Grid>
  );
};

interface DisplayCommercialPerilScoreDataProps {
  details: $TSFixMe;
}
/**
 * Display the CommercialConstruction data for the given location
 */
export const DisplayCommercialPerilScoreData = ({
  details,
}: DisplayCommercialPerilScoreDataProps) => {
  const [commercialPerilScoreProduct, setCommercialPerilScoreProduct] =
    useState<null | CommercialPerilScoreProduct>(
      // @ts-expect-error
      new CommercialPerilScoreProduct(),
    );

  useEffect(() => {
    if (details && details.opta && details.opta.optaCommercialPerilScoreData) {
      setCommercialPerilScoreProduct(
        CommercialPerilScoreProduct.createFromJson(
          details.opta.optaCommercialPerilScoreData,
        ),
      );
    } else {
      setCommercialPerilScoreProduct(null);
    }
  }, [details, details.opta, details.opta.optaCommercialPerilScoreData]);

  if (!details || !details.opta || !details.opta.optaCommercialPerilScoreData) {
    return null;
  }
  return (
    <>
      <Typography
        variant='h6'
        align='left'
        color='textSecondary'
        sx={{ mt: 4, mb: 2 }}
      >
        Commercial Peril Score Data
      </Typography>

      <Grid container sx={{ textAlign: 'left' }} spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: 'background.neutral' }}>
            <CardHeader
              title='Highlights'
              titleTypographyProps={{
                variant: 'h6',
                align: 'center',
                color: 'textSecondary',
              }}
            />
            <CardContent>
              {/*
                // @ts-expect-error */}
              <DisplayCommercialPerilScoreHighlights
                commercialPerilScoreProduct={commercialPerilScoreProduct}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <DetailsCard heading='All Perils'>
            {commercialPerilScoreProduct &&
            commercialPerilScoreProduct.AllPerilsScore
              ? commercialPerilScoreProduct.AllPerilsScore.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DetailsCard heading='Property'>
            {commercialPerilScoreProduct &&
            commercialPerilScoreProduct.PropertyPerilScores
              ? commercialPerilScoreProduct.PropertyPerilScores.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DetailsCard heading='Casualty'>
            {commercialPerilScoreProduct &&
            commercialPerilScoreProduct.CasualtyPerilScores
              ? commercialPerilScoreProduct.CasualtyPerilScores.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
      </Grid>
    </>
  );
};
