import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useLocation, navigate } from '@reach/router';

// types and classes
import type { GenericObject } from '@calefy-inc/utilityTypes';

interface BaseQuoteCompletedErrorProps {
  uuid: string;
  classes: GenericObject;
}

export const BaseQuoteCompletedError = ({
  uuid,
  classes = {},
}: BaseQuoteCompletedErrorProps) => {
  const location = useLocation();
  const quoteLocation =
    location.href.slice(0, location.href.indexOf('resume')) +
    'business-selection';

  return (
    <div className={classes.outerContainer} data-testid='Quote Completed Error'>
      <div className={classes.main}>
        <Typography variant='h6' color='primary' className={classes.title}>
          Application Complete
        </Typography>

        <Typography
          variant='body2'
          color='textSecondary'
          className={classes.content}
        >
          The application with unique identifier {uuid} has already been
          completed and submitted. If you would like to make further changes,
          please contact us at{' '}
          <a
            href={`mailto:support@calefy.ca?subject=Application ${uuid} error`}
          >
            support@calefy.ca
          </a>
          .
        </Typography>

        <Button
          variant='contained'
          sx={{ alignSelf: 'center' }}
          onClick={() => navigate(quoteLocation)}
        >
          Return to Application
        </Button>
      </div>
    </div>
  );
};

export const QuoteCompletedError = withStyles((theme) => ({
  outerContainer: {
    margin: `${theme.spacing(2)} 0`,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '40rem',
    minHeight: '60vh',
    margin: 'auto',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  content: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}))((props: BaseQuoteCompletedErrorProps) => (
  <BaseQuoteCompletedError {...props} />
));
