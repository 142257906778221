import { Quote } from '../../../../../Typescript/backend/classes';
import Typography from '@mui/material/Typography';
import { SupportLink } from '../../../../common/SupportLink';

interface NoInsurancePoliciesProps {
  quote: Quote;
}
export const NoInsurancePolicies = ({ quote }: NoInsurancePoliciesProps) => {
  return (
    <Typography>
      No insurance policies found for application {quote.getDisplayId()}. You
      may need to complete the quote or manually force a re-rating. If you
      believe this to be an error, please contact support at{' '}
      <SupportLink
        subject={`Missing insurance policy for application ${quote.getDisplayId()}`}
      />
      .
    </Typography>
  );
};
