import { ComponentInterface } from './types';
import { Component } from './classes';

export default function initializeComponentRegistry() {
  const componentByType = new Map<string, Component>();

  /* Each possible field component that can be displayed in a form should be registered using a string key 
  (ie Slider, Textfield, TextArea). Additionally, some components will allow the program builder to customize 
  specific attributes. For example, the Slider will allow the program builder to set a max, min value. The UI 
  that allows for customization can be provided as a second optional componet using the programBuilderComponent 
  parameter. If not provided, then the QuestionInstance Form will allow the program builder user to only 
  provide basic label and prompt attributes */

  function registerComponent(component: Component) {
    if (!component.type || !component.quoteComponent) {
      throw Error('Must provide type and quoteComponent');
    }

    componentByType.set(component.type, component);
  }

  function getQuoteComponent(type: ComponentInterface['type']) {
    const components = componentByType.get(type);

    return components && components.quoteComponent;
  }

  function getProgramBuilderComponent(type: ComponentInterface['type']) {
    const components = componentByType.get(type);

    return components && components.programBuilderComponent;
  }

  function getComponentDataType(type: ComponentInterface['type']) {
    const components = componentByType.get(type);

    return components && components.dataType;
  }

  function getComponentTypes() {
    return Array.from(componentByType.entries()).map(([type, values]) => ({
      type,
      typeLabel: values.typeLabel || type,
    }));
  }

  function getCustomizedSaveFunction(type: ComponentInterface['type']) {
    const components = componentByType.get(type);

    return components && components.onComponentSave;
  }

  function getGatherSubQuestionsFunction(type: ComponentInterface['type']) {
    const component = componentByType.get(type);
    return component && component.gatherSubQuestions;
  }

  function getInitialSubQuestionsFunction(type: ComponentInterface['type']) {
    const component = componentByType.get(type);
    // @ts-expect-error
    return component && component.initialSubQuestionsFunction;
  }

  function getProgramBuilderValidationFunction(
    type: ComponentInterface['type'],
  ) {
    const component = componentByType.get(type);
    return component && component.programBuilderValidation;
  }

  function getIcon(type: ComponentInterface['type']) {
    const component = componentByType.get(type);
    return component && component.icon;
  }

  function getTypeLabel(type: ComponentInterface['type']) {
    const component = componentByType.get(type);
    return component && (component.typeLabel || type);
  }

  return {
    getComponentTypes,
    registerComponent,
    getQuoteComponent,
    getProgramBuilderComponent,
    getComponentDataType,
    getCustomizedSaveFunction,
    getGatherSubQuestionsFunction,
    getInitialSubQuestionsFunction,
    getProgramBuilderValidationFunction,
    getIcon,
    getTypeLabel,
  };
}
