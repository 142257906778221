import { Content, OptaAddress } from './OptaCommonClasses';
import {
  dateStringToLocaleString,
  formatBooleanWithUnknownToString,
  returnSelfOrNoInfo,
} from './classUtility';
import { NO_INFO_STRING } from '../constants';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '../../../../../util';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

export class FloodDetail {
  Key: $TSFixMe;
  Value: $TSFixMe;
  constructor(KeyInput: $TSFixMe, ValueInput: $TSFixMe) {
    this.Key = KeyInput;
    this.Value = ValueInput;
  }
  static createFromJson(response: $TSFixMe) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new FloodDetail(response.Key, response.Value);
  }
  displayAsJsx() {
    return (
      <li key={`${this.Key}_${this.Value}`}>
        {this.Key}: {this.Value}
      </li>
    );
  }
}

export class MultiResidentialConstructionFeatures {
  // @ts-expect-error
  constructor(FloorLevelInput, ConstructionTypeInput, BasementPresentInput) {
    // @ts-expect-error
    this.FloorLevel = FloorLevelInput;
    // @ts-expect-error
    this.ConstructionType = ConstructionTypeInput;
    // @ts-expect-error
    this.BasementPresent = BasementPresentInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new MultiResidentialConstructionFeatures(
      response.FloorLevel,
      response.ConstructionType,
      response.BasementPresent,
    );
  }
  displayAsJsx() {
    return (
      <ul>
        <li key='FloorLevel'>
          {/*
          // @ts-expect-error */}
          Floor Level: {returnSelfOrNoInfo(this.FloorLevel)}
        </li>
        <li key='ConstructionType'>
          {/*
          // @ts-expect-error */}
          Construction Type: {returnSelfOrNoInfo(this.ConstructionType)}
        </li>
        <li key='BasementPresent'>
          Basement Present:{' '}
          {/*
          // @ts-expect-error */}
          {formatBooleanWithUnknownToString(this.BasementPresent)}
        </li>
      </ul>
    );
  }
}

export class InspectionDetails {
  // @ts-expect-error
  constructor(SponsoredInspectionInput, InspectionDateInput) {
    // @ts-expect-error
    this.SponsoredInspection = SponsoredInspectionInput;
    // @ts-expect-error
    this.InspectionDate = InspectionDateInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new InspectionDetails(
      response.SponsoredInspection,
      response.InspectionDate,
    );
  }
  displayAsJsx() {
    return (
      <ul>
        <li key='SponsoredInspection'>
          Sponsored Inspection:{' '}
          {/*
          // @ts-expect-error */}
          {formatBooleanWithUnknownToString(this.SponsoredInspection)}
        </li>
        <li key='InspectionDate'>
          Inspection Date:{' '}
          {/*
          // @ts-expect-error */}
          {this.InspectionDate
            ? // @ts-expect-error
              dateStringToLocaleString(this.InspectionDate)
            : NO_INFO_STRING}
        </li>
      </ul>
    );
  }
}

export class ResidentialConstructionFeatures {
  constructor(
    // @ts-expect-error
    YearBuiltInput,
    // @ts-expect-error
    SquareFootageInput,
    // @ts-expect-error
    FoundationTypeInput,
    // @ts-expect-error
    ArchitecturalStyleTypeInput,
    // @ts-expect-error
    StoreyCountInput,
    // @ts-expect-error
    KitchenCountInput,
    // @ts-expect-error
    BathroomCountInput,
    // @ts-expect-error
    ExteriorWallTypeInput,
    // @ts-expect-error
    RoofTypeInput,
    // @ts-expect-error
    FinishedBasementInput,
    // @ts-expect-error
    GarageTypeInput,
    // @ts-expect-error
    GarageNumberOfCarsInput,
    // @ts-expect-error
    SwimmingPoolTypeInput,
    // @ts-expect-error
    PrimaryHeatingTypeInput,
    // @ts-expect-error
    AuxiliaryHeatingTypeInput,
    // @ts-expect-error
    PlumbingTypeInput,
    // @ts-expect-error
    CommercialIndicatorInput,
    // @ts-expect-error
    OutbuildingPresentInput,
    // @ts-expect-error
    InspectionDetailsInput,
  ) {
    // @ts-expect-error
    this.YearBuilt = YearBuiltInput;
    // @ts-expect-error
    this.SquareFootage = SquareFootageInput;
    // @ts-expect-error
    this.FoundationType = FoundationTypeInput;
    // @ts-expect-error
    this.ArchitecturalStyleType = ArchitecturalStyleTypeInput;
    // @ts-expect-error
    this.StoreyCount = StoreyCountInput;
    // @ts-expect-error
    this.KitchenCount = KitchenCountInput;
    // @ts-expect-error
    this.BathroomCount = BathroomCountInput;
    // @ts-expect-error
    this.ExteriorWallType = ExteriorWallTypeInput;
    // @ts-expect-error
    this.RoofType = RoofTypeInput;
    // @ts-expect-error
    this.FinishedBasement = FinishedBasementInput;
    // @ts-expect-error
    this.GarageType = GarageTypeInput;
    // @ts-expect-error
    this.GarageNumberOfCars = GarageNumberOfCarsInput;
    // @ts-expect-error
    this.SwimmingPoolType = SwimmingPoolTypeInput;
    // @ts-expect-error
    this.PrimaryHeatingType = PrimaryHeatingTypeInput;
    // @ts-expect-error
    this.AuxiliaryHeatingType = AuxiliaryHeatingTypeInput;
    // @ts-expect-error
    this.PlumbingType = PlumbingTypeInput;
    // @ts-expect-error
    this.CommercialIndicator = CommercialIndicatorInput;
    // @ts-expect-error
    this.OutbuildingPresent = OutbuildingPresentInput;
    // @ts-expect-error
    this.InspectionDetails = InspectionDetails.createFromJson(
      InspectionDetailsInput,
    );
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new ResidentialConstructionFeatures(
      response.YearBuilt,
      response.SquareFootage,
      response.FoundationType,
      response.ArchitecturalStyleType,
      response.StoreyCount,
      response.KitchenCount,
      response.BathroomCount,
      response.ExteriorWallType,
      response.RoofType,
      response.FinishedBasement,
      response.GarageType,
      response.GarageNumberOfCars,
      response.SwimmingPoolType,
      response.PrimaryHeatingType,
      response.AuxiliaryHeatingType,
      response.PlumbingType,
      response.CommercialIndicator,
      response.OutbuildingPresent,
      response.InspectionDetails,
    );
  }
  displayAsJsx() {
    return (
      <ul>
        <li key='YearBuilt'>
          {/*
          // @ts-expect-error */}
          Year Built: {returnSelfOrNoInfo(this.YearBuilt)}
        </li>
        <li key='SquareFootage'>
          {/*
          // @ts-expect-error */}
          Square Footage: {returnSelfOrNoInfo(this.SquareFootage)}
        </li>
        <li key='FoundationType'>
          {/*
          // @ts-expect-error */}
          Foundation Type: {returnSelfOrNoInfo(this.FoundationType)}
        </li>
        <li key='ArchitecturalStyle'>
          {/*
          // @ts-expect-error */}
          Architectural Style: {returnSelfOrNoInfo(this.ArchitecturalStyleType)}
        </li>
        <li key='StoreyCount'>
          {/*
          // @ts-expect-error */}
          Storeys: {returnSelfOrNoInfo(this.StoreyCount)}
        </li>
        <li key='KitchenCount'>
          {/*
          // @ts-expect-error */}
          Kitchens: {returnSelfOrNoInfo(this.KitchenCount)}
        </li>
        <li key='BathroomCount'>
          {/*
          // @ts-expect-error */}
          Bathrooms: {returnSelfOrNoInfo(this.BathroomCount)}
        </li>
        <li key='ExteriorWallType'>
          {/*
          // @ts-expect-error */}
          Exterior Wall Type: {returnSelfOrNoInfo(this.ExteriorWallType)}
        </li>
        {/*
          // @ts-expect-error */}
        <li key='RoofType'>Roof Type: {returnSelfOrNoInfo(this.RoofType)}</li>
        <li key='FinishedBasement'>
          Finished Basement:{' '}
          {/*
          // @ts-expect-error */}
          {formatBooleanWithUnknownToString(this.FinishedBasement)}
        </li>
        <li key='GarageType'>
          {/*
          // @ts-expect-error */}
          Garage Type: {returnSelfOrNoInfo(this.GarageType)}
        </li>
        <li key='GarageNumberOfCars'>
          Garage Size:{' '}
          {
            // @ts-expect-error
            this.GarageNumberOfCars
              ? // @ts-expect-error
                `${this.GarageNumberOfCars} Car${
                  // @ts-expect-error
                  this.GarageNumberOfCars === 1 ? '' : 's'
                }`
              : NO_INFO_STRING
          }
        </li>
        <li key='SwimmingPoolType'>
          {/*
          // @ts-expect-error */}
          Swimming Pool Type: {returnSelfOrNoInfo(this.SwimmingPoolType)}
        </li>
        <li key='PrimaryHeatingType'>
          {/*
          // @ts-expect-error */}
          Primary Heating Type: {returnSelfOrNoInfo(this.PrimaryHeatingType)}
        </li>
        <li key='AuxiliaryHeatingType'>
          Auxiliary Heating Type:{' '}
          {/*
          // @ts-expect-error */}
          {returnSelfOrNoInfo(this.AuxiliaryHeatingType)}
        </li>
        <li key='PlumbingType'>
          {/*
          // @ts-expect-error */}
          PlumbingType: {returnSelfOrNoInfo(this.PlumbingType)}
        </li>
        <li key='CommercialIndicator'>
          Business Present:{' '}
          {/*
          // @ts-expect-error */}
          {formatBooleanWithUnknownToString(this.CommercialIndicator)}
        </li>
        <li key='OutbuildingPresent'>
          Outbuilding Present:{' '}
          {/*
          // @ts-expect-error */}
          {formatBooleanWithUnknownToString(this.OutbuildingPresent)}
        </li>
        <li key='InspectionDetails'>
          Inspection Details:{' '}
          {/*
          // @ts-expect-error */}
          {this.InspectionDetails
            ? // @ts-expect-error
              this.InspectionDetails.displayAsJsx()
            : NO_INFO_STRING}
        </li>
      </ul>
    );
  }
}
export class FloodValue {
  // @ts-expect-error
  constructor(BuildingInput, ContentsInput, TotalInput) {
    // @ts-expect-error
    this.Building = BuildingInput;
    // @ts-expect-error
    this.Contents = ContentsInput;
    // @ts-expect-error
    this.Total = TotalInput;
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new FloodValue(response.Building, response.Contents, response.Total);
  }
  displayAsJsx() {
    return (
      <ul>
        {/*
          // @ts-expect-error */}
        <li key='Building'>Building: {returnSelfOrNoInfo(this.Building)}</li>
        {/*
          // @ts-expect-error */}
        <li key='Contents'>Contents: {returnSelfOrNoInfo(this.Contents)}</li>
        {/*
          // @ts-expect-error */}
        <li key='Total'>Total: {returnSelfOrNoInfo(this.Total)}</li>
      </ul>
    );
  }
}

export class FloodScore {
  constructor(
    // @ts-expect-error
    ZoneInput,
    // @ts-expect-error
    PremiumInput,
    // @ts-expect-error
    LimitInput,
    // @ts-expect-error
    DeductibleInput,
    // @ts-expect-error
    FloodScoreInput,
    // @ts-expect-error
    AverageAnnualLossScoreInput,
    // @ts-expect-error
    AverageAnnualLossValuesInput,
    // @ts-expect-error
    RateFactorInput,
    // @ts-expect-error
    DefaultInput,
    // @ts-expect-error
    ResidentialModifiersInput,
    // @ts-expect-error
    MultiResidentialModifiersInput,
    // @ts-expect-error
    AdditionalDetailsInput,
  ) {
    // @ts-expect-error
    this.Zone = ZoneInput;
    // @ts-expect-error
    this.Premium = FloodValue.createFromJson(PremiumInput);
    // @ts-expect-error
    this.Limit = LimitInput;
    // @ts-expect-error
    this.Deductible = DeductibleInput;
    // @ts-expect-error
    this.FloodScore = FloodScoreInput;
    // @ts-expect-error
    this.AverageAnnualLossScore = AverageAnnualLossScoreInput;
    // @ts-expect-error
    this.AverageAnnualLossValues = FloodValue.createFromJson(
      AverageAnnualLossValuesInput,
    );
    // @ts-expect-error
    this.RateFactor = RateFactorInput;
    // @ts-expect-error
    this.Default = DefaultInput;
    // @ts-expect-error
    this.ResidentialModifiers = ResidentialConstructionFeatures.createFromJson(
      ResidentialModifiersInput,
    );
    // @ts-expect-error
    this.MultiResidentialModifiers =
      MultiResidentialConstructionFeatures.createFromJson(
        MultiResidentialModifiersInput,
      );
    // @ts-expect-error
    this.AdditionalDetails =
      AdditionalDetailsInput && AdditionalDetailsInput.length > 0
        ? // @ts-expect-error
          AdditionalDetailsInput.map((input) =>
            FloodDetail.createFromJson(input),
          )
        : [];
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new FloodScore(
      response.Zone,
      response.Premium,
      response.Limit,
      response.Deductible,
      response.FloodScore,
      response.AverageAnnualLossScore,
      response.AverageAnnualLossValues,
      response.RateFactor,
      response.Default,
      response.ResidentialModifiers,
      response.MultiResidentialModifiers,
      response.AdditionalDetails,
    );
  }
  displayAsJsx({ key } = { key: '0' }) {
    return (
      <ul key={key}>
        {/*
          // @ts-expect-error */}
        <li key='Zone'>Zone: {returnSelfOrNoInfo(this.Zone)}</li>
        <li key='Premium'>
          {/*
          // @ts-expect-error */}
          Premium: {this.Premium ? this.Premium.displayAsJsx() : NO_INFO_STRING}
        </li>
        {/*
          // @ts-expect-error */}
        <li key='Limit'>Limit: {returnSelfOrNoInfo(this.Limit)}</li>
        <li key='Deductible'>
          {/*
          // @ts-expect-error */}
          Deductible: {returnSelfOrNoInfo(this.Deductible)}
        </li>
        <li key='FloodScore'>
          {/*
          // @ts-expect-error */}
          Flood Score: {returnSelfOrNoInfo(this.FloodScore)}
        </li>
        <li key='AverageAnnualLossScore'>
          Average Annual Loss Score:{' '}
          {/*
          // @ts-expect-error */}
          {returnSelfOrNoInfo(this.AverageAnnualLossScore)}
        </li>
        <li key='RateFactor'>
          {/*
          // @ts-expect-error */}
          Rate Factor: {returnSelfOrNoInfo(this.RateFactor)}
        </li>
        <li key='AverageAnnualLossValues'>
          Average Annual Loss Values:{' '}
          {/*
          // @ts-expect-error */}
          {this.AverageAnnualLossValues
            ? // @ts-expect-error
              this.AverageAnnualLossValues.displayAsJsx()
            : NO_INFO_STRING}
        </li>
        <li key='Default'>
          {/*
          // @ts-expect-error */}
          Default: {formatBooleanWithUnknownToString(this.Default)}
        </li>
        <li key='ResidentialModifiers'>
          Residential Modifiers:{' '}
          {/*
          // @ts-expect-error */}
          {this.ResidentialModifiers
            ? // @ts-expect-error
              this.ResidentialModifiers.displayAsJsx()
            : NO_INFO_STRING}
        </li>
        <li key='MultiResidentialModifiers'>
          MultiResidential Modifiers:{' '}
          {/*
          // @ts-expect-error */}
          {this.MultiResidentialModifiers
            ? // @ts-expect-error
              this.MultiResidentialModifiers.displayAsJsx()
            : NO_INFO_STRING}
        </li>
        <li key='AdditionalDetails'>
          Additional Details:{' '}
          {/*
          // @ts-expect-error */}
          {this.AdditionalDetails && this.AdditionalDetails.length > 0 ? (
            <ul>
              {/*
          // @ts-expect-error */}
              {this.AdditionalDetails.map((detail) => detail.displayAsJsx())}
            </ul>
          ) : (
            'None'
          )}
        </li>
      </ul>
    );
  }
}
export class FloodMap {
  // @ts-expect-error
  constructor(FloodEventInput, ContentInput) {
    // @ts-expect-error
    this.FloodEvent = FloodEventInput;
    // @ts-expect-error
    this.Content = Content.createFromJson(ContentInput);
  }
  // @ts-expect-error
  static createFromJson(response) {
    if (!response || typeof response !== 'object') {
      return null;
    }
    return new FloodMap(response.FloodEvent, response.Content);
  }
}
export class FloodScores {
  // @ts-expect-error
  constructor(FloodScoreInput, FloodMapInput) {
    // create the floodscore from the array
    if (FloodScoreInput && FloodScoreInput.length > 0) {
      // @ts-expect-error
      this.FloodScore = FloodScoreInput.map((input) =>
        FloodScore.createFromJson(input),
      );
    }

    // create the floodmap from the array
    if (FloodMapInput && FloodMapInput.length > 0) {
      // @ts-expect-error
      this.FloodMap = FloodMapInput.map((input) =>
        FloodMap.createFromJson(input),
      );
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response || typeof response !== 'object') {
        return null;
      }
      return new FloodScores(response.FloodScore, response.FloodMap);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating FloodScores with response', response, e);
      return null;
    }
  }
}

export class FloodScoreProduct {
  // @ts-expect-error
  constructor(Address, FloodScoresInput) {
    try {
      // @ts-expect-error
      this.Address = OptaAddress.createFromJson(Address);
      // @ts-expect-error
      this.FloodScores = FloodScores.createFromJson(FloodScoresInput);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error('Error creating new FloodScoreProduct:', e);
    }
  }
  // @ts-expect-error
  static createFromJson(response) {
    try {
      if (!response || typeof response !== 'object') {
        return null;
      }

      return new FloodScoreProduct(response.Address, response.FloodScores);
      // return new FloodScoreProduct(fakeData.FloodScores);
    } catch (e) {
      Bugsnag.notify(errorify(e));
      console.error(
        `Error creating new FloodScoreProduct from json`,
        response,
        e,
      );
    }
  }
}

export class FloodScoreProductsHighlights {
  displayAsJSx() {
    return <p>No highlights</p>;
  }
}
