import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setQuestionsPerPage } from '../store/QuoteWizardState';
import { useAuth } from '@calefy-inc/authentication';

export const useSetQuestionsPerPage = () => {
  const dispatch = useDispatch();
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      dispatch(setQuestionsPerPage('all'));
    } else {
      dispatch(setQuestionsPerPage(5));
    }
  }, [token, dispatch]);
};
