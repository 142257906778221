import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAllowByPermission } from '../../../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';

import { GET_QUOTE_PDF } from '../../../../../../queries';

import { Quote } from '../../../../../../Typescript/backend/classes';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { downloadPdfFile } from '@calefy-inc/utility';

import Bugsnag from '@bugsnag/js';

interface SpireDocumentDownloadButtonProps {
  quote: Quote | null;
  renewalOnly: $TSFixMe;
  selectedVersion: $TSFixMe;
}
export const SpireDocumentDownloadButton = ({
  quote,
  renewalOnly,
  selectedVersion,
}: SpireDocumentDownloadButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const hasAdminPermissions = useAllowByPermission('is:admin');
  const [
    loadQuotePdf,
    { data: pdfData, loading: pdfLoading, error: pdfError },
  ] = useLazyQuery(GET_QUOTE_PDF, {
    fetchPolicy: 'no-cache',
  });
  const handlePdfDownload = () => {
    if (token && quote) {
      loadQuotePdf({
        variables: {
          token,
          uniqueID: quote.uniqueId,
          archiveID: selectedVersion !== '0' ? selectedVersion : null,
          renewalOnly: renewalOnly,
          // downloadType: 'python-docx',
          downloadType: 'spire',
        },
      });
    }
  };

  useEffect(() => {
    if (pdfData && pdfData.quotePdf) {
      const quotePdf = pdfData.quotePdf;
      downloadPdfFile(
        // @ts-expect-error
        quotePdf.pdfString,
        `${quotePdf.businessName || 'New Application'}.pdf`,
      );
      enqueueSnackbar('Download Complete', { variant: 'success' });
    }

    if (pdfData && pdfData?.quotePdf === null) {
      enqueueSnackbar('PDF Not Found!', { variant: 'info' });
    }
  }, [pdfData, enqueueSnackbar]);

  // when there is an error getting the pdf, display error message and notify bugsnag
  useEffect(() => {
    if (pdfError) {
      enqueueSnackbar('Download Failed', { variant: 'error' });
      Bugsnag.notify(`Pdf download failed: ${pdfError}`);
    }
  }, [pdfError, enqueueSnackbar]);

  if (!quote || hasAdminPermissions !== true) {
    return null;
  }
  return (
    <>
      <LoadingButton
        size='small'
        aria-label='download'
        loadingPosition='start'
        loading={pdfLoading}
        onClick={handlePdfDownload}
      >
        <div style={{ paddingLeft: pdfLoading ? 30 : 0 }}>
          Download Spire Quote
        </div>
      </LoadingButton>
    </>
  );
};
