// material
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { styled } from '@mui/material/styles';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import { RouteComponentProps, useParams } from '@reach/router';

// utils
import { displayData, countLeadingWhitespace, getLimits } from './jsonUtility';

// components
import LoadingScreen from '../../../components/LoadingScreen';
// hooks
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useAllowByPermission } from '../../../../../hooks';
import { useDisplayQuoteComparison } from '../QuoteDetails';
// error handlers
import {
  // handleErrors,
  InsufficientPermissionsErrorPage,
} from '../../../../common/ErrorHandling';
// import type { HandleableError } from '../../../../../components/common/ErrorHandling';
import { ErrorView } from '../../../../../components/common/ErrorView';
// queries
import { GET_INSURER_QUOTE } from '../../../../../queries/queries/getInsurerQuote';
// types
import { QuoteDetails } from './jsonUtility';

interface InsurerQuoteDetailsProps extends RouteComponentProps {}

const cardStyle = {
  //   maxWidth: 200,
  m: 1,
  p: 0.1,
};

const DLStyle = styled('dl')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '[key] minmax(50%, auto) [value] 1fr',
  columnGap: theme.spacing(2),
  '& dt': {
    display: 'inline-block',
    gridColumn: '1 / 2',
    ...theme.typography.body2,
    marginBottom: theme.spacing(1.1),
    lineHeight: theme.spacing(2),
  },
  '& dd': {
    display: 'inline-block',
    gridColumn: '2 / 3',
    marginLeft: 0,
    // paddingTop: theme.spacing()
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    lineHeight: theme.spacing(2),
  },
  '& dd:after': {
    content: '" "',
    display: 'block',
  },
}));

const hardcodedKeys = ['Quote Issuer', 'Total Premium'];

export default function InsurerQuoteDetails(_props: InsurerQuoteDetailsProps) {
  const { uniqueID, insurerQuoteID } = useParams();
  const hasViewQuoteDetailsPermission =
    useAllowByPermission('view:quote:details');
  const hasViewQuoteComparisonPermission = useDisplayQuoteComparison();

  const { loading, error, data } = useQuery(GET_INSURER_QUOTE, {
    variables: {
      quoteUuid: uniqueID,
      insurerQuoteIdentifier: insurerQuoteID,
    },
  });

  const [jsonData, setJsonData] = useState<QuoteDetails>({});
  const [otherDetailsColumnKeys, setOtherDetailsColumnKeys] = useState<
    string[][]
  >([[], []]);

  useEffect(() => {
    if (
      data &&
      data.getInsurerQuote?.llmResponseBlob &&
      data.getInsurerQuote.isValid
    ) {
      setJsonData(JSON.parse(data.getInsurerQuote.llmResponseBlob));
      //console.log(
      //   JSON.stringify(
      //     JSON.parse(data.getInsurerQuote.llmResponseBlob),
      //     null,
      //     2,
      //   ),
      // );
    } else if (data && data.getInsurerQuote && !data.getInsurerQuote.isValid) {
      setJsonData({});
    }
  }, [data]);

  useEffect(() => {
    const tempColumnKeys: string[][] = [[], []];
    const items = Object.keys(jsonData).filter(
      (key) => !hardcodedKeys.includes(key),
    );
    items.forEach((item, index) => {
      tempColumnKeys[index % 2].push(item);
    });
    setOtherDetailsColumnKeys(tempColumnKeys);
  }, [jsonData]);

  return (
    <>
      <Page title='Insurer Quote: View Details | Manager'>
        <Container maxWidth={'lg'} data-testid='quote-quote-details'>
          {hasViewQuoteDetailsPermission === false ||
          hasViewQuoteComparisonPermission !== true ? (
            <InsufficientPermissionsErrorPage deniedIdentifier='Insurer Quote Details page' />
          ) : loading ? (
            <LoadingScreen />
          ) : error ? (
            <ErrorView />
          ) : !uniqueID || !data ? null : (
            <>
              <HeaderBreadcrumbs
                heading='Insurer Quote Details'
                links={[
                  { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
                  { name: 'Quotes', href: PATH_DASHBOARD.quotes.root },
                  {
                    name: 'Quote',
                    href: `${PATH_DASHBOARD.quotes.root}/details/${uniqueID}`,
                  },
                  { name: 'Insurer Quote Details' },
                ]}
              />
              <Card sx={{ py: 5, px: 5 }}>
                <Typography variant='h3' color='primary'>
                  {insurerQuoteID}
                </Typography>
                <Typography variant='h4'>Quote Details</Typography>
                {data &&
                data.getInsurerQuote &&
                !data.getInsurerQuote.isValid ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant='h5' color='error.main'>
                      Quote is not processable.
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant='body1'>
                        Generally this occurs because the PDF does not contain
                        readable text/numbers. You can try to contact the issuer
                        for an alternate format and upload it.
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant='body1'>
                        If you believe this to be an error please send a message
                        to{' '}
                        <a
                          href={`mailto:support@calefy.ca?subject=Insurer Quote PDF summary generation error`}
                        >
                          support@calefy.ca
                        </a>
                        .
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={0.5}
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='stretch'
                      sx={{ my: 1 }}
                    >
                      {hardcodedKeys.map((key, index) => {
                        return !jsonData[key] ? null : (
                          <Grid
                            item
                            key={`${index}:${key}`}
                            xs={12}
                            md={6}
                            sx={{ flexGrow: 1 }}
                          >
                            <CollapsibleCard
                              cardHeaderTitle={key}
                              cardContentElements={
                                <Typography variant='body2'>
                                  {jsonData[key]}
                                </Typography>
                              }
                            />
                          </Grid>
                        );
                      })}
                      {/* limits need their own special card */}
                      <Grid item xs={12} md={12} sx={{ flexGrow: 1 }}>
                        <CollapsibleCard
                          cardHeaderTitle='Limits'
                          cardContentElements={<Limits jsonData={jsonData} />}
                        />
                      </Grid>
                    </Grid>
                    <Typography variant='h5' sx={{ my: 4 }}>
                      Other Details
                    </Typography>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      width='100%'
                    >
                      {otherDetailsColumnKeys.map((keys, columnIndex) => (
                        <Box width='50%' key={`col:${columnIndex}`}>
                          {keys.map((key, index) => {
                            return (
                              <Box key={`${index}:${key}`}>
                                <CollapsibleCard
                                  cardHeaderTitle={`${key}:`}
                                  cardContentElements={displayData(
                                    jsonData,
                                    key,
                                  ).map((line, index2) => {
                                    const splitLine = line.split(':', 2);
                                    return splitLine.length === 1 ? (
                                      <>
                                        <dt
                                          style={{
                                            paddingLeft: `${
                                              countLeadingWhitespace(line) * 20
                                            }px`,
                                            fontWeight: line.endsWith(':')
                                              ? 'bold'
                                              : 'normal',
                                          }}
                                          key={`dt${index}-${index2}`}
                                        >
                                          {line}
                                        </dt>
                                        <dd key={`dd${index}-${index2}`}> </dd>
                                      </>
                                    ) : (
                                      <>
                                        <dt
                                          style={{
                                            paddingLeft: `${
                                              countLeadingWhitespace(line) * 20
                                            }px`,
                                            fontWeight: line.endsWith(':')
                                              ? 'bold'
                                              : 'normal',
                                          }}
                                          key={`dt${index}-${index2}`}
                                        >
                                          {splitLine[0]}:{' '}
                                        </dt>
                                        <dd key={`dd${index}-${index2}`}>
                                          {splitLine[1]}
                                        </dd>
                                      </>
                                    );
                                  })}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
              </Card>
            </>
          )}
        </Container>
      </Page>
    </>
  );
}

interface CollapsibleCardProps {
  cardContentElements: React.ReactNode;
  cardHeaderTitle: string;
}

const CollapsibleCard = ({
  cardContentElements,
  cardHeaderTitle,
}: CollapsibleCardProps) => {
  const [open, setOpen] = useState(true);
  return (
    <Card sx={cardStyle}>
      <CardHeader
        title={cardHeaderTitle}
        sx={{ p: 2 }}
        action={
          <IconButton size='small' onClick={() => setOpen(!open)}>
            <Icon icon={open ? arrowIosUpwardFill : arrowIosDownwardFill} />
          </IconButton>
        }
      ></CardHeader>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <CardContent
          sx={{
            pt: 0,
            '&:last-child': {
              pb: '12px',
            },
          }}
        >
          <DLStyle>{cardContentElements}</DLStyle>{' '}
        </CardContent>
      </Collapse>
    </Card>
  );
};

function Limits({ jsonData }: { jsonData: QuoteDetails }) {
  const limits = getLimits(jsonData);
  return (
    <>
      {Object.keys(limits).map((key, index) => {
        return displayData(limits, key, true).map((line, index2) => {
          const splitLine = line.split(':', 2);
          return splitLine.length === 1 ? (
            <>
              <dt
                style={{
                  paddingLeft: `${countLeadingWhitespace(line) * 20}px`,
                  fontWeight: line.endsWith(':') ? 'bold' : 'normal',
                }}
                key={`dt${index}-${index2}`}
              >
                {line}
              </dt>
              <dd key={`dd${index}-${index2}`}> </dd>
            </>
          ) : (
            <>
              <dt
                style={{
                  paddingLeft: `${countLeadingWhitespace(line) * 20}px`,
                  fontWeight: line.endsWith(':') ? 'bold' : 'normal',
                }}
                key={`dt${index}-${index2}`}
              >
                {splitLine[0]}:{' '}
              </dt>
              <dd key={`dd${index}-${index2}`}>{splitLine[1]}</dd>
            </>
          );
        });
      })}
    </>
  );
}
