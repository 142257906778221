import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { Address } from '../types';

interface StructuredQuoteDataInput {
  id: string;
  businessLegalName?: string;
  businessTradeName?: string;
  businessName?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  contactAddress?: Address;
  revenue?: number;
  alreadyContactedBrokerName?: string;
}
export class StructuredQuoteData {
  id: string;
  businessLegalName?: string;
  businessTradeName?: string;
  businessName?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  contactAddress?: Address;
  revenue?: number;
  alreadyContactedBrokerName?: string;

  constructor({
    id,
    businessLegalName,
    businessTradeName,
    businessName,
    contactEmail,
    contactName,
    contactPhoneNumber,
    contactAddress,
    revenue,
    alreadyContactedBrokerName,
  }: StructuredQuoteDataInput) {
    this.id = id;
    this.businessLegalName = businessLegalName;
    this.businessTradeName = businessTradeName;
    this.businessName = businessName;
    this.contactEmail = contactEmail;
    this.contactName = contactName;
    this.contactPhoneNumber = contactPhoneNumber;
    this.contactAddress = contactAddress;
    this.revenue = revenue;
    this.alreadyContactedBrokerName = alreadyContactedBrokerName;
  }

  static generateFromBackendResponse(response: $TSFixMe) {
    return new StructuredQuoteData(response);
  }
}
