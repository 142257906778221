import { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

import { ImageCard, AddressDisplay } from './commonComponents';

import {
  FusProduct,
  FusProductsHighlights,
} from '../../../QuoteLoader/LocationDetails/classes';
import { NO_INFO_STRING } from '../../../QuoteLoader/LocationDetails/constants';

import { InfoChunk, DetailsCard } from './commonComponents';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface DisplayFusHighlightsProps {
  fusProduct: $TSFixMe;
}
const DisplayFusHighlights = ({ fusProduct }: DisplayFusHighlightsProps) => {
  const [highlights, setHighlights] = useState(
    new FusProductsHighlights(fusProduct),
  );

  useEffect(() => {
    setHighlights(new FusProductsHighlights(fusProduct));
  }, [fusProduct]);

  if (Object.values(highlights).every((elem) => elem === null)) {
    return NO_INFO_STRING;
  }

  return (
    <>
      <Grid container spacing={2}>
        {
          // @ts-expect-error
          highlights.PublicFireProtectionClassification ? (
            <InfoChunk
              key='PublicFireProtectionClassification'
              heading='Public Fire Protection Classification'
              information={
                // @ts-expect-error
                highlights.PublicFireProtectionClassification
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.RiskSCOR ? (
            <InfoChunk
              key='RiskSCOR'
              heading='Risk SCOR'
              information={
                // @ts-expect-error
                highlights.RiskSCOR
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.HydrantProtectedGrade ? (
            <InfoChunk
              key='HydrantProtectedGrade'
              heading='Hydrant Protection Grade'
              information={
                // @ts-expect-error
                highlights.HydrantProtectedGrade
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.FirehallProtectedGrade ? (
            <InfoChunk
              key='FirehallProtectedGrade'
              heading='Firehall Protection Grade'
              information={
                // @ts-expect-error
                highlights.FirehallProtectedGrade
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.AllOtherAreasGrade ? (
            <InfoChunk
              key='AllOtherAreasGrade'
              heading='All Other Areas Protection Grade'
              information={
                // @ts-expect-error
                highlights.AllOtherAreasGrade
              }
            />
          ) : null
        }
      </Grid>
    </>
  );
};

interface DisplayOptaFusDataProps {
  location: $TSFixMe;
  classes: object;
}
/**
 * Display the FUS details for a particular location
 * @param details - the details for the current location; may or may not include opta fus details
 * @param setLocationFusDetails - a function (optaFusData) => void which amends the current location with its fus details
 * @param classes - The theming classes
 */
const UnthemedDisplayOptaFusData = ({
  location,
  classes,
}: DisplayOptaFusDataProps) => {
  const [fusProduct, setFusProduct] = useState<null | FusProduct>(
    // @ts-expect-error
    new FusProduct(),
  );

  useEffect(() => {
    if (
      location &&
      location.details &&
      location.details.opta &&
      location.details.opta.optaFusData
    ) {
      setFusProduct(
        FusProduct.createFromJson(location.details.opta.optaFusData),
      );
    } else {
      setFusProduct(null);
    }
  }, [
    location,
    location.details,
    location.details.opta,
    location.details.opta.optaFusData,
  ]);

  return (
    <>
      <Typography
        variant='subtitle1'
        align='center'
        color='textSecondary'
        gutterBottom
      >
        FUS Data
      </Typography>
      {
        // @ts-expect-error
        fusProduct && fusProduct.Address ? (
          // @ts-expect-error
          <AddressDisplay>{fusProduct.Address.toString()}</AddressDisplay>
        ) : null
      }
      {/* 
      // @ts-expect-error */}
      <Box className={classes.highlights}>
        <Typography
          variant='subtitle1'
          style={{ textAlign: 'center' }}
          color='textSecondary'
          gutterBottom
        >
          Highlights
        </Typography>
        {/* 
      // @ts-expect-error */}
        <DisplayFusHighlights fusProduct={fusProduct} />
      </Box>

      {/* 
      // @ts-expect-error */}
      <Grid container xs={12} className={classes.rowContainer} spacing={1}>
        <Grid item xs={6}>
          <DetailsCard heading='Dwelling Grades'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.Dwelling
                ? // @ts-expect-error
                  fusProduct.Dwelling.displayAsJsx()
                : 'No Information'
            }
          </DetailsCard>
        </Grid>
        <Grid item xs={6}>
          <DetailsCard heading='Commercial Grades'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.Commercial
                ? // @ts-expect-error
                  fusProduct.Commercial.displayAsJsx()
                : 'No Information'
            }
          </DetailsCard>
        </Grid>
        {(fusProduct &&
          // @ts-expect-error
          fusProduct.Dwelling &&
          // @ts-expect-error
          fusProduct.Dwelling.SearchMessage) ||
        (fusProduct &&
          // @ts-expect-error
          fusProduct.Commercial &&
          // @ts-expect-error
          fusProduct.Commercial.SearchMessage) ? (
          <Grid item xs={12}>
            <DetailsCard heading='Additional Message'>
              {fusProduct &&
              // @ts-expect-error
              fusProduct.Dwelling &&
              // @ts-expect-error
              fusProduct.Dwelling.SearchMessage ? (
                // @ts-expect-error
                <p>{fusProduct.Dwelling.SearchMessage}</p>
              ) : fusProduct &&
                // @ts-expect-error
                fusProduct.Commercial &&
                // @ts-expect-error
                fusProduct.Commercial.SearchMessage ? (
                // @ts-expect-error
                <p>{fusProduct.Commercial.SearchMessage}</p>
              ) : null}
            </DetailsCard>
          </Grid>
        ) : null}
        <Grid item xs={4}>
          <DetailsCard heading='Responding Firehall'>
            {fusProduct &&
            // @ts-expect-error
            fusProduct.Dwelling &&
            // @ts-expect-error
            fusProduct.Dwelling.RespondingFirehall
              ? // @ts-expect-error
                fusProduct.Dwelling.RespondingFirehall.displayAsJsx()
              : fusProduct &&
                // @ts-expect-error
                fusProduct.Commercial &&
                // @ts-expect-error
                fusProduct.Commercial.RespondingFirehall
              ? // @ts-expect-error
                fusProduct.Commercial.RespondingFirehall.displayAsJsx()
              : NO_INFO_STRING}
          </DetailsCard>
        </Grid>
        <Grid item xs={4}>
          <DetailsCard heading='Earthquake Codes'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.EarthquakeCodes
                ? // @ts-expect-error
                  fusProduct.EarthquakeCodes.displayAsJsx()
                : NO_INFO_STRING
            }
          </DetailsCard>
        </Grid>
        <Grid item xs={4}>
          <DetailsCard heading='Hydrant Counts'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.HydrantCounts
                ? // @ts-expect-error
                  fusProduct.HydrantCounts.displayAsJsx()
                : NO_INFO_STRING
            }
          </DetailsCard>
        </Grid>
      </Grid>

      {/* 
      // @ts-expect-error */}
      <div className={classes.maps}>
        <DetailsCard
          heading='Maps'
          childrenWrapperStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {
            // @ts-expect-error
            fusProduct && fusProduct.FusMaps && fusProduct.FusMaps.FusMap ? (
              <Grid container spacing={2}>
                <>
                  {
                    // @ts-expect-error
                    fusProduct.FusMaps.FusMap.map((map) => (
                      <ImageCard
                        key={map.Business}
                        heading={map.Business}
                        url={map.FusContent.ContentURL}
                      />
                    ))
                  }
                </>
              </Grid>
            ) : (
              NO_INFO_STRING
            )
          }
        </DetailsCard>
      </div>
    </>
  );
};

export const DisplayOptaFusData = withStyles((theme) => ({
  ul: {
    margin: '0px',
    padding: '0px',
  },
  highlights: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2, 2, 2),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    border: '1px solid grey',
  },
  maps: {
    padding: theme.spacing(2, 0.5, 0, 0.5),
    pageBreakInside: 'avoid',
  },
  rowContainer: {
    margin: 0,
  },
  fusNotFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  fusNotFoundButton: {
    marginTop: theme.spacing(2),
  },
  loadingMarker: {
    marginTop: theme.spacing(2),
    transition: 'opacity 1s',
  },
  animated1: {
    animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
  },
  animated2: {
    animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
    animationDelay: '1s',
  },
  animated3: {
    animation: `$fadeInOut 3000ms ${theme.transitions.easing.easeInOut} infinite alternate`,
    animationDelay: '2s',
  },
  '@global': {
    '@keyframes fadeInOut': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  },
}))((props: $TSFixMe) => <UnthemedDisplayOptaFusData {...props} />);
