import theme from './CalefyTheme';
import logo from './assets/MainLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';

// Calefy Config
const settings = new WebsiteSettings({
  slug: 'calefy',
  host: 'secure.fuseinsurance.ca',
  companyName: 'Calefy Inc',
  hours: '8:30am - 5:30pm',
  phone: '1-844-4CA-LEFY (422-5339)',
  email: 'info@calefy.ca',
  chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo: logo,
  title: 'Calefy Inc: Insurance Technology Canada',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: true,
  contactInformationFirst: true,
  // personalInsurance: true,
});

export default settings;
