import { RouteComponentProps } from '@reach/router';

interface AdminPanelHomepageProps extends RouteComponentProps {}

export const AdminPanelHomepage = (_props: AdminPanelHomepageProps) => {
  return (
    <div data-testid='admin'>
      <h1>Admin Panel Homepage</h1>
      <p>
        At some point maybe having some features here would be nice! For now
        just navigate using the nav component above to get where you're going.
      </p>
    </div>
  );
};
