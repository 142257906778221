import { useEffect } from 'react';
import ProgressBar from 'react-top-loading-bar';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { generateProgress, capitalizeFirstLetter } from './utility';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Zoom, LinearProgress } from '@mui/material';
import { updateStepWizardState } from '../../../store/QuoteWizardState';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { ComponentMapping } from '../types';
import type { StoreState } from '../../../store';

const useNavStyle = makeStyles((theme: $TSFixMe) => ({
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(3)} 0`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navText: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  navLink: {
    flexGrow: 1,
    flexBasis: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    textAlign: 'center',
    '&:last-child $divider': {
      display: 'none',
    },
    '& p': {
      marginTop: theme.spacing(0.75),
      transition: `transform .2s`,
      '&:not($blocked)': {
        '&:hover': {
          transform: `scale(1.2)`,
        },
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  divider: {
    position: 'absolute',
    backgroundColor: theme.palette.text.disabled,
    width: `calc(100% - ${2 * 1.5}rem)`,
    transform: `translate(calc(50% + 1.5rem), ${1.5 / 2}rem)`,
    top: 0,
    height: '.15rem',
  },
  isCurrentStep: {
    color: theme.palette.primary.main,
  },
  hasBeenVisited: {
    color: theme.palette.text.secondary,
  },
  furthest: {
    color: theme.palette.text.secondary,
  },
  blocked: {
    color: theme.palette.text.disabled,
  },
  isLessThanCurrentStep: {
    color: theme.palette.primary.main,
  },
}));

interface NavProps {
  componentMapping: ComponentMapping;
  totalSteps?: number;
  currentStep?: number;
  goToStep?: (step: number) => void;
}
export const Nav = ({
  componentMapping,
  totalSteps,
  currentStep,
  goToStep,
}: NavProps) => {
  const dispatch = useDispatch();
  const classes = useNavStyle();
  const theme = useTheme();
  const furthestStepNumber = useSelector(
    (state: $TSFixMe) => state.quoteWizard.stepWizard.furthestStep,
  );
  const currentFormApi = useSelector(
    (state: $TSFixMe) => state.quoteWizard.currentFormApi,
  );
  const skipPolicyStep = useSelector(
    (state: StoreState) => state.quoteWizard.skipPolicySlection,
  );

  // on each step change, update the current step in the redux store
  useEffect(() => {
    dispatch(updateStepWizardState({ currentStep }));
  }, [currentStep, dispatch]);

  // reset the navigation when you get to the done page OR when you get to the policy page
  useEffect(() => {
    if (componentMapping && currentStep === componentMapping.done) {
      dispatch(updateStepWizardState({ furthestStep: 1 }));
    } else if (
      componentMapping &&
      currentStep === 2 &&
      Object.values(componentMapping).length === 5
    ) {
      dispatch(updateStepWizardState({ furthestStep: 2 }));
    }
  }, [currentStep, componentMapping, dispatch]);

  const displayComponentMapping: ComponentMapping = componentMapping;
  /*console.log({ displayComponentMapping }); */
  return (
    <>
      <ProgressBar
        color={theme.palette.primary.main}
        progress={generateProgress(currentStep, totalSteps)}
        height={4}
        loaderSpeed={1000}
        shadow={true}
      />
      {currentStep === totalSteps ? null : (
        <nav className={classes.navContainer} id='quoteWizard'>
          {Object.entries(displayComponentMapping)
            .sort((a, b) => {
              return a[1] - b[1];
            })
            .map(([name, _stepNumber]) => name)
            .map((step, index, allSteps) => {
              let className = null;
              let status = null;
              let value = 0;
              const stepIndex = componentMapping[step];
              const furthestStepIndex = Math.max(
                ...Object.values(componentMapping).filter(
                  (number) => number <= furthestStepNumber,
                ),
              );
              const earliestCurrentStep = Math.max(
                ...Object.values(componentMapping).filter(
                  // @ts-expect-error
                  (number) => number <= currentStep,
                ),
              );
              const stepAfterFurthestStepIndex = Math.min(
                ...Object.values(componentMapping).filter(
                  (index) => index > furthestStepIndex,
                ),
              );

              if (stepIndex === earliestCurrentStep) {
                className = classes.isCurrentStep;
                status = 'isCurrentStep';
              } else if (stepIndex === furthestStepIndex) {
                className = classes.furthest;
                status = 'furthest';
              } else if (stepIndex >= stepAfterFurthestStepIndex) {
                className =
                  window.location.hostname === 'localhost'
                    ? classes.furthest
                    : classes.blocked;
                status =
                  window.location.hostname === 'localhost'
                    ? 'furthest'
                    : 'blocked';
              } else if (stepIndex < furthestStepNumber) {
                className = classes.hasBeenVisited;
                status = 'hasBeenVisited';
                if (stepIndex < earliestCurrentStep) {
                  className = classes.isLessThanCurrentStep;
                  value = 100;
                } else {
                  value = 0;
                }
              }
              return index === allSteps.length - 1 ||
                (skipPolicyStep && step === 'policy') ? null : (
                <div
                  className={classes.navLink}
                  key={index}
                  onClick={() => {
                    if (currentStep === totalSteps) {
                      dispatch(updateStepWizardState({ furthestStep: 1 }));
                      goToStep && goToStep(1);
                    }
                    if (
                      stepIndex <= furthestStepNumber ||
                      window.location.hostname === 'localhost'
                    ) {
                      if (
                        currentStep !== undefined &&
                        currentStep < furthestStepNumber
                      ) {
                        if (currentFormApi) {
                          currentFormApi.validate();
                          const formState = currentFormApi.getState();

                          if (Object.keys(formState.errors).length === 0) {
                            goToStep && goToStep(stepIndex);
                          }
                        } else {
                          goToStep && goToStep(stepIndex);
                        }
                      } else if (currentStep === furthestStepNumber) {
                        goToStep && goToStep(stepIndex);
                      }
                    }
                  }}
                >
                  {className === classes.blocked ? (
                    <RadioButtonUncheckedIcon color='disabled' />
                  ) : (
                    <Zoom in={true} timeout={800}>
                      <CheckCircleIcon
                        color={
                          className === classes.isCurrentStep ||
                          stepIndex < earliestCurrentStep
                            ? 'primary'
                            : 'disabled'
                        }
                      />
                    </Zoom>
                  )}
                  <Typography
                    component='p'
                    variant='subtitle2'
                    data-status={status}
                    className={className ? className : undefined}
                  >
                    {capitalizeFirstLetter(step)}
                  </Typography>
                  <LinearProgress
                    className={classes.divider}
                    value={value}
                    variant='determinate'
                  />
                </div>
              );
            })}
        </nav>
      )}
    </>
  );
};
