// utility functions for all of the error handler components

import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// returns a string representing the form
export const displayForm = (formAnswers: $TSFixMe) => {
  let displayString = '';
  const displayLabel = 'Policy: ' + getLabel(formAnswers);
  displayString = displayLabel + '\n' + '-'.repeat(displayLabel.length) + '\n';
  formAnswers.answers.forEach((answer: $TSFixMe) => {
    displayString += answer ? displayQuestionAnswerPair(answer, 0) : '';
  });
  return displayString;
};

// returns a string representing the question, the answer, and the question / answer for any subAnswers
// level is the amount of indentation -> 0 is a top level question, 1 is a subquestion, 2 is a subquestion of a subquestion, &c.
const displayQuestionAnswerPair = (answer: $TSFixMe, level: number) => {
  let displayString = `${'\t'.repeat(level)}${answer.questionInstance.label}: ${
    answer.value
  }
  \n`;
  if (answer.subAnswers) {
    answer.subAnswers.forEach((subAnswer: $TSFixMe) => {
      displayString += displayQuestionAnswerPair(subAnswer, level + 1);
    });
  }
  return displayString;
};

// returns the current form label
/**
 * Returns the form label for a formAnswers object
 * @param {object} formWithAnswer - The answer object for a form. Shape: {
 *     form: Form,
 *     answers: [ Answer ]
 *     inProgress: bool
 *     complete: bool
 * }
 * @return {type} - description
 */
export const getLabel = (formWithAnswer: $TSFixMe) => {
  const { form } = formWithAnswer;

  let label;
  if (form.policy) {
    label = form.policy.displayName;
  } else {
    label = 'Company Information';
  }

  return label;
};

export const generateQuoteDisplayStringFromAnswers = (
  formAnswers: $TSFixMe,
  businessName: string,
) => {
  let displayString =
    businessName + '\n' + '-'.repeat(businessName.length) + '\n\n';

  let orderedAnswers = [];
  for (let formAnswer of Object.values(formAnswers)) {
    // @ts-expect-error
    if (formAnswer.form.policy) {
      orderedAnswers.push(formAnswer);
    } else {
      orderedAnswers.unshift(formAnswer);
    }
  }
  let answersString = '';
  orderedAnswers.forEach((answers) => {
    answersString += displayForm(answers);
  });
  displayString += answersString;

  return displayString;
};

export const copyTextToClipboard = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error copying text!', error);
      Bugsnag.notify(errorify(error));
    });
  }
};
