import { useState, useEffect } from 'react';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../../hooks';
import { Quote } from '../../../../../../Typescript/backend/classes';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useMutation } from '@apollo/client';
import { VAGO_FORCE_RE_RATE } from '../../../../../../queries';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material';
import { useUsingVagoSettings } from '../../../../../../WhiteLabelSettings/VagoAssure/settings';
import { SupportLink } from '../../../../../common/SupportLink';
import Bugsnag from '@bugsnag/browser';

interface VagoForceReRateButtonProps {
  quote: Quote | null;
}
export const VagoForceReRateButton = ({
  quote,
}: VagoForceReRateButtonProps) => {
  const onVago = useUsingVagoSettings();
  const hasAdminPermissions = useAllowByPermission('is:admin');
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  if (!onVago || !quote || hasAdminPermissions === false) {
    return null;
  }
  return (
    <>
      <Button onClick={() => setOpen(true)}>Force Re-Rate</Button>
      <VagoForceReRateDialog
        open={open}
        quote={quote}
        onCancel={() => setOpen(false)}
        onComplete={() => {
          enqueueSnackbar('Successfully re-rated quote!', {
            variant: 'success',
          });
          setTimeout(() => setOpen(false), 1000);
        }}
      />
    </>
  );
};

interface VagoForceReRateDialopProps {
  quote: Quote;
  open: boolean;
  onCancel: () => void;
  onComplete: () => void;
}
const VagoForceReRateDialog = ({
  open,
  quote,
  onCancel,
  onComplete,
}: VagoForceReRateDialopProps) => {
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [forceReRate, { loading, error, data }] = useMutation(
    VAGO_FORCE_RE_RATE,
    {
      refetchQueries: ['quoteInsurancePolicies', 'Quote'],
    },
  );
  const { token } = useAuth();
  useEffect(() => {
    if (data) {
      onComplete();
    }
  }, [data]);

  // reset the error dialog when that changes
  useEffect(() => {
    setErrorDialogOpen(!!error);
  }, [error]);

  // tell us when there's an error
  useEffect(() => {
    if (error) {
      Bugsnag.notify(error);
    }
  }, [error]);

  if (!token) {
    return null;
  }
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Force Re-Rate?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure that you want to force a re-rate of this application?
            This will delete all existing policies on the application. This will
            also affect all subsequent file downloads and bordereaux. Please be
            very sure that you want to do this.
          </Typography>
          {data ? (
            <SuccessTypography>Successfully re-rated!</SuccessTypography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={() => {
              forceReRate({
                variables: {
                  token,
                  uniqueId: quote.uniqueId,
                },
              });
            }}
          >
            Force Re-Rate
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <VagoForceReRateErrorDialog
        error={error}
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
          onCancel();
        }}
      />
    </>
  );
};

interface VagoForceReRateErrorDialogProps {
  open: boolean;
  onClose: () => void;
  error?: Error;
}
const VagoForceReRateErrorDialog = ({
  open,
  onClose,
  error,
}: VagoForceReRateErrorDialogProps) => {
  if (!error) {
    return null;
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Error When Re-Rating Application</DialogTitle>
      <DialogContent>
        <Typography>
          We're sorry, but something went wrong while trying to re-rate this
          item. Our team has been notified of the issue and will be looking into
          it shortly. We’ll reach out to you as soon as the problem is resolved.
          Thank you for your patience!
        </Typography>
        {error?.message ? (
          <>
            <Typography sx={{ mt: 1 }}>The received error was</Typography>
            <ErrorTypography sx={{ mb: 1 }}>{error.message}</ErrorTypography>
          </>
        ) : null}
        <Typography>
          If you believe this to be an error or want to get in touch, please
          contact us at{' '}
          <SupportLink subject='Error when re-rating application' />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const ErrorTypography = styled(Typography)(({ theme }) => {
  return { color: theme.palette.error.main };
});

const SuccessTypography = styled(Typography)(({ theme }) => {
  return { color: theme.palette.success.main };
});
