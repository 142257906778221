/**
 * This component needs to fire off a query that sets the status to RENEWAL_IN_PROGRESS, then bump to /resume/uuid
 */
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { MARK_AS_RENEWAL_IN_PROGRESS } from '../../../queries';
import { navigate } from '@reach/router';

import { baseHandleSpecificErrors } from '../QuoteResume/utility';
import { InvalidRenewalStatusError } from './ErrorComponents';
import { InvalidUUIDError } from '../QuoteResume/ErrorComponents';
import { validateUuid4 } from '@calefy-inc/utility';

// classes and types
import { RouteComponentProps } from '@reach/router';

//@ts-expect-error
const handleSpecificErrors = (error: $TSFixMe, uuid: string) => {
  if (error.message.match(/invalid status:/i)) {
    return <InvalidRenewalStatusError uuid={uuid} />;
  }
  const base = baseHandleSpecificErrors(error, uuid);
  if (base) {
    return base;
  }
  return undefined;
};

interface QuoteRenewalProps extends RouteComponentProps {
  uuid?: string;
}

export function QuoteRenewal({ uuid }: QuoteRenewalProps) {
  const [markAsRenewalInProgress, { loading, error, data }] = useMutation(
    MARK_AS_RENEWAL_IN_PROGRESS,
  );

  useEffect(() => {
    if (uuid && validateUuid4(uuid)) {
      markAsRenewalInProgress({ variables: { uuid } });
    }
  }, [markAsRenewalInProgress, uuid]);

  if (!uuid) {
    navigate(`/insurtech/quote/resume/${uuid}`);
  }

  if (loading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color='secondary' size='2rem' />
      </Backdrop>
    );
  }
  if (error) {
    const result = handleSpecificErrors(error, uuid as string);
    if (result) {
      return result;
    }
    navigate(`/insurtech/quote/resume/${uuid}`);
  }
  if (uuid && !validateUuid4(uuid)) {
    return <InvalidUUIDError uuid={uuid} />;
  }

  if (data && data.markAsRenewalInProgress) {
    navigate(`/insurtech/quote/resume/${uuid}`);
  }

  return null;
}
