import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';
import { ErrorView } from '../../../../common/ErrorView';

interface RateEditorErrorsProps {
  error: Error;
}
export const RateEditorErrors = ({ error }: RateEditorErrorsProps) => {
  if (error.message.match(/invalid permissions for/i)) {
    return <InsufficientPermissionsErrorPage />;
  }
  return <ErrorView />;
};
