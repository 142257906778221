import jwtDecode, { JwtPayload } from 'jwt-decode';
import Bugsnag from '@bugsnag/browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, delay } from 'redux-saga/effects';
import {
  CREATE_STATISTICAL_EVENT,
  CREATE_STATISTICAL_SESSION,
} from '../queries';
import type { $TSFixMe, UUID } from '@calefy-inc/utilityTypes';
import { SagaArgument } from './types';
import { errorify } from '../util';
import { CoreStatisticalEventEventTypeChoices } from '../gql/graphql';
import { QuoteWizardForm } from '../components/QuoteWizard/classes';

type StatisticalEventType = CoreStatisticalEventEventTypeChoices;

interface AnalyticsStoreState {
  sessionId: null | UUID;
  sessionLoading: boolean;
  [k: string]: $TSFixMe;
}

const defaultInitialState: AnalyticsStoreState = {
  sessionId: null,
  sessionLoading: false,
};

export const analyticsStore = createSlice({
  name: 'analyticsStore',
  initialState: defaultInitialState,
  reducers: {
    requestNewStatisticalSession: (
      _state,
      _action: PayloadAction<undefined>,
    ) => {
      //console.log('In requestNewStatisticalSession');
    },
    /**
     * Handle the case where we successfully created a new StatisticalSession
     */
    newStatisticalSessionSuccess: (state, action: PayloadAction<UUID>) => {
      state.sessionId = action.payload;
      //console.log(
      //   `Successfully created new StatisticalSession ${state.sessionId}`,
      // );
    },
    /**
     * Handle the case where we attempted to create a new StatisticalSession, but the process failed
     */
    newStatisticalSessionFailure: (state, action: PayloadAction<Error>) => {
      //console.log(`in newStatisticalSessionFailure with`, action.payload);
      state.sessionId = null;
      console.error(action.payload);
      Bugsnag.notify(
        new Error(
          `Error when attempting to create a new StatisticalSession: ${JSON.stringify(
            action.payload,
            null,
            4,
          )}`,
        ),
      );
    },
    /**
     * Once we consider the StatisticalSession to be done, clear the ID
     */
    clearStatisticalSession: (state, _action: PayloadAction<undefined>) => {
      //console.log(`in clearStatisticalSession`);
      state.sessionId = null;
    },
    setSessionLoading: (state, action: PayloadAction<boolean>) => {
      state.sessionLoading = action.payload;
    },
    /**
     * Request that a new StatisticalEvent be created on the backend. The actual processing is mostly handled by the createStatisticalEvent saga.
     * @param state - The state
     * @param action - Has a payload contianing the actual information. Payload:
     *   - data: a JSONString of whatever information is relevant to the StatisticalEvent
     *   - eventType: the type of event
     *   - userEmail: if there's a logged in user, that user's email
     *   - options: additional customization for the behaviour of the event
     *     - clearSession: should a clearStatisticalSession event be dispatched once the mutation has been submitted? Used for 'terminal' states where we consider the session to be finished.
     */
    requestNewStatisticalEvent: (
      _state,
      _action: PayloadAction<{
        data?: string;
        eventType: StatisticalEventType;
        userEmail?: string | null;
        options?: { clearSession?: boolean };
      }>,
    ) => {
      //console.log('In requestNewStatisticalEvent');
    },
    newStatisticalEventFailure: (_state, action: PayloadAction<Error>) => {
      //console.log('In newStatisticalEventFailure with', action.payload);
      Bugsnag.notify(action.payload);
    },
    newStatisticalEventSuccess: (_state, _action: PayloadAction<undefined>) => {
      //console.log('in newStatisticalEventSuccess');
    },
  },
  extraReducers: {},
});

/**
 * Utility Functions
 */

/**
 * Returns the email associated with the existing token, if it exists.
 */
function getUserEmail() {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return null;
    }
    const parsed = jwtDecode<JwtPayload>(accessToken);
    // const parsed = jwt.decode(accessToken);
    // @ts-expect-error
    const email = parsed['https://calefy.ca/email'];
    return email ?? null;
  } catch (e) {
    Bugsnag.notify(errorify(e));
    console.error(e);
    return null;
  }
}

/**
 * Thunks
 * these are functions which (usually) wrap a function which then returns a function which gets (dispatch, getState)
 * e.g. setMessage = (message: string) => (dispatch, getState) => dispatch(messageActionCreator(message))
 */

/**
 * Indicate that a new application has been started
 */
export const createApplicationBegunThunk =
  () => (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState();
    const userEmail = getUserEmail();
    const selectedBusiness = state.quoteWizard.selectedBusinessType;
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify({
          business_internal_name: selectedBusiness?.internalName,
        }),
        // eventType: 'APPLICATION_BEGUN',
        eventType: CoreStatisticalEventEventTypeChoices.ApplicationBegun,
        userEmail,
      }),
    );
  };

/**
 * Indicate that a new application has been completed, clearing the session
 */
export const createApplicationFinishedThunk =
  () => (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState();
    const userEmail = getUserEmail();
    const selectedBusiness = state.quoteWizard.selectedBusinessType;
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify({
          business_internal_name: selectedBusiness?.internalName,
        }),
        eventType: CoreStatisticalEventEventTypeChoices.ApplicationFinished,
        // eventType: 'APPLICATION_FINISHED',
        userEmail,
        options: { clearSession: true },
      }),
    );
  };

/**
 * Indicate that a new application has been saved, clearing the session
 */
export const createApplicationSavedThunk =
  () => (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState();
    const userEmail = getUserEmail();
    const selectedBusiness = state.quoteWizard.selectedBusinessType;
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify({
          business_internal_name: selectedBusiness?.internalName,
        }),
        eventType: CoreStatisticalEventEventTypeChoices.ApplicationSaved,
        // eventType: 'APPLICATION_SAVED',
        userEmail,
        options: { clearSession: true },
      }),
    );
  };

/**
 * Indicate that a previously saved application has been resumed
 */
export const createApplicationResumedThunk =
  () => (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState();
    const userEmail = getUserEmail();
    const selectedBusiness = state.quoteWizard.selectedBusinessType;
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify({
          business_internal_name: selectedBusiness?.internalName,
        }),
        eventType: CoreStatisticalEventEventTypeChoices.ApplicationResumed,
        // eventType: 'APPLICATION_RESUMED',
        userEmail,
      }),
    );
  };

/**
 * Store the current value of the business selection input for later analysis
 */
export const createBusinessSelectionInputThunk =
  (input: string) => (dispatch: $TSFixMe, _getState: $TSFixMe) => {
    if (!input) {
      return;
    }
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify({
          value: input,
        }),
        eventType: CoreStatisticalEventEventTypeChoices.BusinessSelectionInput,
        // eventType: 'BUSINESS_SELECTION_INPUT',
      }),
    );
  };

/**
 * Store what the user actually selected in the business selection input
 * @param value - The display name of the business selected
 */
export const createBusinessSelectionSelectedThunk =
  (value: string) => (dispatch: $TSFixMe, _getState: $TSFixMe) => {
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify({
          value,
        }),
        eventType:
          CoreStatisticalEventEventTypeChoices.BusinessSelectionSelected,
        // eventType: 'BUSINESS_SELECTION_SELECTED',
      }),
    );
  };

/**
 * Store the information about a newly loaded form
 */
export const createFormLoadedThunk =
  (form: QuoteWizardForm) => (dispatch: $TSFixMe, _getState: $TSFixMe) => {
    const { id, businessLine, policy } = form;
    const data = {
      id,
      business: businessLine,
      policy,
    };
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify(data, null, 4),
        eventType: CoreStatisticalEventEventTypeChoices.FormLoaded,
      }),
    );
  };

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<T>;
type PageType =
  | 'Business Selection'
  | 'Policy Selection'
  | 'Contact Info'
  | 'General Information'
  | 'Policy Form'
  | 'Review'
  | 'Done-Completed'
  | 'Done-Saved'
  | 'Done-ContactInfoOnly';

interface CreatePageLoadThunkDataType {
  page: PageType;
  pageNumber: number;
  totalPages: number;
  formType: string;
  topLevelQuestions: Array<string>;
  leafQuestions: Array<string>;
}
type CreatePageLoadThunkDataTypeInput = PartialBy<
  CreatePageLoadThunkDataType,
  'formType' | 'topLevelQuestions' | 'leafQuestions'
>;
export const createPageLoadThunk =
  (data: CreatePageLoadThunkDataTypeInput) =>
  (dispatch: $TSFixMe, _getState: $TSFixMe) => {
    const actualData = { topLevelQuestions: [], leafQuestions: [], ...data };
    dispatch(
      analyticsStore.actions.requestNewStatisticalEvent({
        data: JSON.stringify(actualData, null, 4),
        eventType: CoreStatisticalEventEventTypeChoices.PageLoad,
      }),
    );
  };

export const requestNewStatisticalSessionThunk =
  () => (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState();
    if (state.analyticsStore.sessionId) {
      //console.log(
      //   `In requestNewStatisticalSessionThunk - found existing session id ${state.analyticsStore.sessionId} - not requesting a new one`,
      // );
      return;
    } else if (state.analyticsStore.sessionLoading) {
      //console.log(
      //   `In requestNewStatisticalSessionThunk - already loading a statistical session - not requesting a new one`,
      // );
      return;
    }
    dispatch(analyticsStore.actions.requestNewStatisticalSession());
  };

/**
 * Analytics Sagas
 */
function* createStatisticalEvent(
  { client }: SagaArgument,
  action: ReturnType<
    typeof analyticsStore['actions']['requestNewStatisticalEvent']
  >,
) {
  let sessionId, sessionLoading;
  //console.log(`in createStatisticalEvent ${action.payload.eventType}`);
  const { data: eventData, eventType, userEmail, options } = action.payload;
  try {
    // @ts-expect-error
    sessionId = yield select((state) => state.analyticsStore.sessionId);
    // @ts-expect-error
    sessionLoading = yield select(
      (state) => state.analyticsStore.sessionLoading,
    );
    //console.log(
    //   `In createStatisticalEvent: found sessionId=${sessionId}, sessionLoading=${sessionLoading}`,
    // );

    if (!sessionId) {
      if (sessionLoading) {
        //console.log(
        //   'About to create new session and event simultaneously, but there is already a session being created - about to submit action after delay',
        // );
        yield delay(2000);
        yield put(action);
      }
      yield put(analyticsStore.actions.setSessionLoading(true));
    }

    //console.log(`In createStatisticalEvent - Got session id ${sessionId}`);
    // @ts-expect-error
    const quoteUuid = yield select(
      (state) => state.quoteWizard.currentQuoteUUID,
    );
    const { data, error } = yield call(client.mutate, {
      mutation: CREATE_STATISTICAL_EVENT,
      variables: {
        data: eventData,
        eventType,
        sessionId,
        userEmail,
        quoteUuid,
      },
    });
    if (error) {
      yield put(analyticsStore.actions.newStatisticalEventFailure(error));
    }
    if (data) {
      if (data?.createStatisticalEvent?.session?.sessionId !== sessionId) {
        //console.log(
        //   `In createStatisticalEvent: replacing ${sessionId} with ${data?.createStatisticalEvent?.session?.sessionId}`,
        // );
        yield put(
          analyticsStore.actions.newStatisticalEventSuccess(
            data.createStatisticalEvent.session.sessionId,
          ),
        );
      }
      yield put(analyticsStore.actions.newStatisticalEventSuccess());
      // if we are creating a new session at the same time, put that in the store
      //console.log({ sessionId, data });
      if (!sessionId && data?.createStatisticalEvent.session.sessionId) {
        yield put(
          analyticsStore.actions.newStatisticalSessionSuccess(
            data.createStatisticalEvent.session.sessionId,
          ),
        );
      }
    }
  } catch (e) {
    Bugsnag.notify(errorify(e));
    yield put(
      analyticsStore.actions.newStatisticalEventFailure(
        e instanceof Error ? e : new Error(JSON.stringify(e, null, 4)),
      ),
    );
  } finally {
    if (options?.clearSession) {
      //console.log('About to clear session after terminal statistical event');
      yield put(analyticsStore.actions.clearStatisticalSession());
    }
    if (!sessionId) {
      yield put(analyticsStore.actions.setSessionLoading(false));
    }
  }
}

function* createStatisticalSession(
  { client }: SagaArgument,
  _action: $TSFixMe,
) {
  //console.log('In createStatisticalSession with ', { action });

  // @ts-expect-error
  const existingSession = yield select(
    (store) => store.analyticsStore.sessionId,
  );
  if (existingSession) {
    //console.log(
    //   'About to create statistical session, but one already exists. Aborting.',
    // );
    return;
  }
  // @ts-expect-error
  const sessionLoading = yield select(
    (store) => store.analyticsStore.sessionLoading,
  );
  if (sessionLoading) {
    //console.log(
    //   'About to create statistical session, but one is already being loaded. Aborting.',
    // );
    return;
  }
  yield put(analyticsStore.actions.setSessionLoading(true));
  try {
    const { data, error } = yield call(client.mutate, {
      mutation: CREATE_STATISTICAL_SESSION,
      variables: {},
    });
    //console.log({ data, error });
    if (error) {
      yield put(analyticsStore.actions.newStatisticalSessionFailure(error));
    }
    if (data) {
      yield put(
        analyticsStore.actions.newStatisticalSessionSuccess(
          data.createStatisticalSession.session.sessionId,
        ),
      );
    }
  } catch (e) {
    Bugsnag.notify(errorify(e));
    yield put(
      analyticsStore.actions.newStatisticalSessionFailure(
        e instanceof Error ? e : new Error(JSON.stringify(e, null, 4)),
      ),
    );
  } finally {
    yield put(analyticsStore.actions.setSessionLoading(false));
  }
}

export function* analysticsStoreSaga({ client }: SagaArgument) {
  //console.log('Started analysticsStoreSaga');
  yield takeEvery(
    analyticsStore.actions.requestNewStatisticalSession.type,
    createStatisticalSession,
    { client },
  );
  yield takeEvery(
    analyticsStore.actions.requestNewStatisticalEvent.type,
    createStatisticalEvent,
    { client },
  );
}
