import { TextArea } from '@calefy-inc/informedMaterial';

import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps } from './types';

const ProgramBuilderComponent = () => {
  return <TextArea field='defaultValue' label='Default Value' />;
};

const quoteComponent = function ({
  questionInstance,
  classes,
  ...props
}: QuoteComponentProps) {
  return (
    <TextArea
      label={questionInstance.label}
      field={questionInstance.generateFieldName()}
      id={questionInstance.id}
      helperText={questionInstance.helpText}
      required={questionInstance.required}
      className={classes && classes.textarea ? classes.textarea : ''}
      classes={classes}
      {...props}
    />
  );
};

const IconComponent = () => {
  return <EditNoteRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'textarea',
    typeLabel: 'Text Area',
    quoteComponent,
    dataType: 'string',
    programBuilderComponent: ProgramBuilderComponent,
    icon: IconComponent,
  });
}

export { quoteComponent };
