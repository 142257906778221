// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  booking: getIcon('ic_booking'),
  banking: getIcon('ic_banking'),
};

interface SidebarConfigItem {
  title: string;
  path: string;
  icon: $TSFixMe;
  children?: Array<{
    title: string;
    path: string;
  }>;
}
interface SidebarConfigElement {
  subheader: string;
  items: Array<SidebarConfigItem>;
}
type SidebarConfig = Array<SidebarConfigElement>;
const sidebarConfig: SidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT QUOTES
      {
        title: 'applications',
        path: PATH_DASHBOARD.quotes.root,
        icon: ICONS.kanban,
      },

      // MANAGEMENT FORMS
      {
        title: 'forms',
        path: PATH_DASHBOARD.forms.root,
        icon: ICONS.booking,
      },

      // MANAGEMENT FORM MODIFIERS
      {
        title: 'form modifiers',
        path: PATH_DASHBOARD.formModifiers.root,
        icon: ICONS.chat,
      },

      //MANAGEMENT RATES
      {
        title: 'rates',
        path: PATH_DASHBOARD.rates.root,
        icon: ICONS.ecommerce,
      },

      // MANAGEMENT DATA VIEW
      {
        title: 'database',
        path: PATH_DASHBOARD.database.root,
        icon: ICONS.banking,
        children: [
          { title: 'business', path: PATH_DASHBOARD.database.business },
          { title: 'policy', path: PATH_DASHBOARD.database.policy },
          { title: 'questions', path: PATH_DASHBOARD.database.questions },
        ],
      },
    ],
  },
];

export default sidebarConfig;
