import { useEffect, useState } from 'react';
import { useField } from 'informed';
import { TextField, FormControl, FormLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface InformedTextFieldProps {
  field: $TSFixMe;
  label?: $TSFixMe;
  helperText?: $TSFixMe;
  defaultValue?: $TSFixMe;
  classes?: $TSFixMe;
  className?: $TSFixMe;
  validate?: $TSFixMe;
  required?: $TSFixMe;
  validateOnChange?: $TSFixMe;
  variant?: $TSFixMe;
  onBlur?: $TSFixMe;
  options: Array<string>;
  [k: string]: $TSFixMe;
}
export const InformedMaterialAutocomplete = (props: InformedTextFieldProps) => {
  const {
    helperText,
    defaultValue,
    // eslint-disable-next-line no-unused-vars
    classes = {},
    field,
    label,
    className,
    validate,
    required,
    // eslint-disable-next-line no-unused-vars
    validateOnChange = true,
    variant = 'standard',
    onBlur: passedInOnblur,
    options,
    freeSolo = true,
    autoHighlight = false,
    ...otherProps
  } = props;

  const labelLength = label ? label.length : 0;

  const { fieldState, fieldApi, render, ref, userProps, ...rest } = useField({
    ...props,
  });

  const { value } = fieldState;
  const { setValue, setTouched, setError } = fieldApi;
  const { onChange, onBlur } = userProps;

  const [inputValue, setInputValue] = useState<string>('');

  //set the value
  useEffect(() => {
    if (props.value !== undefined) {
      setValue(String(props.value));
    } else if (
      (value === undefined || value === '') &&
      defaultValue !== undefined
    ) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, props.value]);

  //set the error, if provided as a prop
  useEffect(() => {
    if (props.error === true) {
      setError(props.helperText ? props.helperText : 'Error');
    } else if (props.error === false) {
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  // if the field is in an error state, display text: fieldState.error (or helpText as a fallback). Otherwise, don't display anything
  // @ts-expect-error
  let actualHelperText;
  if (props.error || fieldState.error) {
    actualHelperText = fieldState.error ? fieldState.error : props.helperText;
  } else {
    actualHelperText = undefined;
  }

  return render(
    <>
      <FormControl
        variant={variant}
        className={className}
        error={!!fieldState.error}
        {...otherProps}
      >
        {labelLength >= 80 ? (
          <FormLabel htmlFor={field}>{label}</FormLabel>
        ) : null}
        <Autocomplete
          {...rest}
          id={field}
          value={value}
          inputValue={inputValue}
          ref={ref}
          disabled={props.disabled}
          //default to the prop; otherwise the formstate
          options={options}
          onChange={(e, newValue) => {
            // @ts-expect-error
            setValue(newValue);
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            setTouched(true);
            if (onBlur) {
              onBlur(e);
            }
          }}
          onInputChange={(_e, newInputValue) => {
            setInputValue(newInputValue);
            setValue(newInputValue);
          }}
          freeSolo={freeSolo}
          autoHighlight={autoHighlight}
          renderInput={(params) => (
            <TextField
              variant={variant}
              label={labelLength <= 79 ? label : null}
              name={field}
              error={
                props.error === undefined ? !!fieldState.error : props.error
              }
              // @ts-expect-error
              helperText={actualHelperText}
              {...params}
            />
          )}
        />
      </FormControl>
    </>,
  );
};
