import { graphql } from '../gql';

export const DELETE_FORM = graphql(`
  mutation deleteForm($formId: ID!, $token: String!) {
    deleteFinalForm(formId: $formId, token: $token) {
      ok
      formId
    }
  }
`);
