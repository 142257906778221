import { graphql } from '../gql';

export const ORGANIZATION_FINAL_FORMS_SKELETONS = graphql(`
  query organizationFinalFormsSkeletons($includeDefault: Boolean!) {
    organizationFinalForms(includeDefault: $includeDefault) {
      id
      businessLine {
        id
        displayName
      }
      policy {
        id
        displayName
      }
      organization {
        name
      }
    }
  }
`);
