import { useLazyQuery } from '@apollo/client';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ProspectsForm from './ProspectsForm';
import DisplayResults from './DisplayResults';

import { 
  // Authenticated,
  useAuth } from '@calefy-inc/authentication';
import { Authenticated } from '../Authentication/Authenticated';

import { QUERY_PROSPECTS } from './queries';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { RouteComponentProps } from '@reach/router';

interface ProspectsProps extends RouteComponentProps {
  client: $TSFixMe;
}
const Prospects = ({ client }: ProspectsProps) => {
  const { token } = useAuth();
  const [submitQuery, { called, error, data }] = useLazyQuery(QUERY_PROSPECTS, {
    client: client,
  });

  const handleSubmit = (values: $TSFixMe) => {
    //console.log('Form values: ', values);
    let { email, queries, municipalities, searchCircles } = values;
    if (municipalities && municipalities.length === 0) {
      municipalities = undefined;
    }

    //console.log('submitting query:', { token });
    submitQuery({
      variables: {
        token,
        email: email,
        queries: queries,
        searchCircles: searchCircles,
      },
    });
  };

  return (
    <div
      style={{
        padding: '20px 7.5%',
      }}
    >
      <Typography align='center' variant='h1' gutterBottom={true}>
        Prospects Tool
      </Typography>
      <p>Tips for Use</p>
      <ul>
        <li>
          Between each query, you <em>must</em> reload the page; otherwise
          something screwy happens with the queries (seems to truncate some and
          repeat others)
        </li>
        <li>
          Select many small regions rather than one large one. It seems to work
          best if you make each population centre its own circle.
        </li>
      </ul>
      <ProspectsForm client={client} onSubmit={handleSubmit} />
      {(data && data.prospects && data.prospects.error === true) || error ? (
        <>
          <p>Error! Something went wrong: </p>
          <pre>
            {JSON.stringify(error ? error : data.prospects.message, null, 4)}
          </pre>
        </>
      ) : null}
      {called && !data && !error ? (
        <Grid container spacing={2} mt={3}>
          <Grid item xs={2}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          </Grid>
          <Grid item xs={10} alignItems='center' justifyContent='center'>
            Query sent! It is now safe to close this page; the email will be
            sent regardless. You may stay on the page to view the results when
            the query is finished.
          </Grid>
        </Grid>
      ) : null}
      <DisplayResults
        results={
          data && data.prospects
            ? data.prospects.map((business: $TSFixMe) => ({
                name: business.name.trim(),
                phoneNumber: business.phoneNumber
                  ? business.phoneNumber.trim()
                  : '',
                address: business.formattedAddress.trim(),
                contacts:
                  business.contacts === 'null'
                    ? null
                    : JSON.parse(business.contacts),
              }))
            : undefined
        }
      />
    </div>
  );
};

const AuthenticatedProspects = (props: ProspectsProps) => {
  return (
    // @ts-expect-error
    <Authenticated>
      <Prospects {...props} />
    </Authenticated>
  );
};

export default withStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(6),
    margin: 'auto',
    width: '50rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  selectField: {
    marginTop: theme.spacing(0),
    marginBottom: 0,
    '& > label': {
      ...theme.typography.label,
      marginBottom: theme.spacing(2),
    },
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: 0,
  },
  instructionText: {
    ...theme.typography.caption,
    marginLeft: theme.spacing(6),
    marginBottom: 0,
  },
  buttonContainer: {
    alignSelf: 'flex-end',
    '& > button': {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  link: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1.5),
  },
}))(AuthenticatedProspects);
