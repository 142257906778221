import { graphql } from '../../gql';

export const SET_AUTHORIZATION_TOKEN_VALIDITY = graphql(`
  mutation setAuthorizationTokenValidity(
    $authorizationTokenId: ID!
    $validity: Boolean!
    $token: String!
  ) {
    setAuthorizationTokenValidity(
      authorizationTokenId: $authorizationTokenId
      validity: $validity
      token: $token
    ) {
      token {
        id
        valid
      }
    }
  }
`);
