import {useEffect, useRef} from "react"

/**
* Get the previous value of something in state. See https://blog.logrocket.com/accessing-previous-props-state-react-hooks/ for an explanation
* @param value - The variable whose previous value you want to access
* @return The previous value of the variable
* @example 
* const [count, setCount] = useState(0)
* const prevCount = usePrevious(count) // will now how the old value of count
*/
export const usePrevious = (value: any) => {
  const ref=useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
