import { graphql } from '../gql';

export const GET_ALL_QUOTES = graphql(`
  query GetAllQuotes($token: String!, $search: String) {
    allQuotes(token: $token, search: $search) {
      id
      uniqueID
      dateAdded
      status
      businessLine {
        id
        displayName
      }
      structuredData {
        businessLegalName
        businessName
        businessTradeName
      }
      vagoCommercialPackStructuredData {
        clientName
        transaction
        epicLookupCode
        cglLimit
        tiv
        cglPremium
        propertyPremium
        user
        aragBound
        avivaBound
        propertyBound
        liabilityBound
      }
      bindings {
        id
        label
        bound
        unboundReason
      }
    }
  }
`);
