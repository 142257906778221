import { graphql } from '../../gql';

export const GENERIC_BUSINESS_WORDCLOUD_INDIVIDUAL_QUOTES = graphql(`
  query genericBusinessWordcloudIndividualQuotes($token: String!) {
    genericBusinessWordcloudIndividualQuotes(token: $token) {
      quoteIdentifierName
      quoteUuid
      additionalInformation
      wordcloud {
        value
        frequency
      }
    }
  }
`);
