import gql from 'graphql-tag';

export const ALL_BUSINESS_SELECTOR_INPUTS = gql`
  query allBusinessSelectorInputs {
    allBusinessSelectorInputs {
      sessionId
      inputs
      businessSelected
      date
      associatedQuoteUuid
    }
  }
`;
