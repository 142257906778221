// @ts-nocheck
import { useEffect, useState, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { WHOAMI_ORGANIZATION, UPDATE_ORGANIZATION } from '../../queries';
import { useAuth } from '@calefy-inc/authentication';
import { Form, EmailInput } from '@calefy-inc/informedMaterial';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

// types and classes
import type { RouteComponentProps } from '@reach/router';
import { Organization } from '../../Typescript/backend/types';

interface OrganizationEditorProps extends RouteComponentProps {}
export const OrganizationEditor = (_props: OrganizationEditorProps) => {
  const { token } = useAuth();
  const [getOrganization, { called, loading, error: organizationError, data }] =
    useLazyQuery(WHOAMI_ORGANIZATION);
  const [
    updateOrganization,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useMutation(UPDATE_ORGANIZATION);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [quoteEmails, setQuoteEmails] = useState<Array<string>>([]);
  const [quoteFromEmail, setQuoteFromEmail] = useState<string>('');

  // load the data on load
  useEffect(() => {
    if (token) {
      getOrganization({ variables: { token } });
    }
  }, [token]);

  // set the organization when the data comes in
  useEffect(() => {
    if (data?.whoamiOrganization) {
      const org = {
        ...data.whoamiOrganization,
        quoteEmails: JSON.parse(data.whoamiOrganization.quoteEmails),
      };
      setOrganization(org);
    }
  }, [data]);

  // update the organization when updates come in
  useEffect(() => {
    if (updateData?.updateOrganization.organization) {
      setOrganization({
        ...updateData.updateOrganization.organization,
        quoteEmails: JSON.parse(
          updateData.updateOrganization.organization.quoteEmails,
        ),
      });
    }
  }, [updateData]);

  // whenever the organization changes, update the quoteEmails, &c.
  useEffect(() => {
    if (organization) {
      setQuoteFromEmail(organization.quoteFromEmail);
      setQuoteEmails(organization.quoteEmails);
    }
  }, [organization]);

  return (
    <div data-testid='organization-editor'>
      <h1 style={{ textAlign: 'center' }}>Organization Editor</h1>
      {organizationError ? (
        <>
          <p>Error when getting the organization:</p>
          <pre>{JSON.stringify(organizationError, null, 4)}</pre>
        </>
      ) : null}
      {updateError ? (
        <>
          <p>Error when updating the organization:</p>
          <pre>{JSON.stringify(updateError, null, 4)}</pre>
        </>
      ) : null}
      {!organization ? null : (
        <div
          style={{
            padding: '24px',
          }}
        >
          <h2>Information for {organization.name}</h2>
          <Form
            onSubmit={(values) => {
              setQuoteFromEmail(values.quoteFromEmail);
            }}
          >
            <Stack direction='row' alignContent='end' spacing='24px'>
              <EmailInput label="Quote 'From' Email" field='quoteFromEmail' />
              <Button type='submit' variant='outlined'>
                Change Quote 'From' Email
              </Button>
            </Stack>
          </Form>
          <br />
          <Form
            onSubmit={(values) => {
              setQuoteEmails((oldQuoteEmails) =>
                [...new Set([values.quoteEmails, ...oldQuoteEmails])].sort(
                  (a, b) => a.localeCompare(b),
                ),
              );
            }}
          >
            <Stack direction='row' alignContent='end' spacing='24px'>
              <EmailInput field='quoteEmails' label="Add Quote 'To' Email" />
              <Button type='submit' variant='outlined'>
                Add New Quote 'To' Email
              </Button>
            </Stack>
          </Form>
          <section>
            <h2>Summary of Changes</h2>
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '10rem' }}></th>
                  <th style={{ minWidth: '10rem' }} scope='col'>
                    Old
                  </th>
                  <th style={{ minWidth: '10rem' }} scope='col'>
                    New
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='row' style={{ textAlign: 'left' }}>
                    Quote 'From' Email
                  </th>
                  <td>
                    {organization.quoteFromEmail
                      ? organization.quoteFromEmail
                      : 'None'}
                  </td>
                  <td>
                    {quoteFromEmail ? (
                      <Chip
                        label={quoteFromEmail}
                        key={quoteFromEmail}
                        variant='outlined'
                        onDelete={() => {
                          setQuoteFromEmail('');
                        }}
                      >
                        quoteFromEmail
                      </Chip>
                    ) : (
                      'None'
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope='row' style={{ textAlign: 'left' }}>
                    Quote 'To' Emails
                  </th>
                  <td>
                    {organization.quoteEmails.length === 0
                      ? 'None'
                      : organization.quoteEmails.join(', ')}
                  </td>
                  <td>
                    {quoteEmails.length === 0
                      ? 'None'
                      : quoteEmails.map((email) => (
                          <Chip
                            key={email}
                            label={email}
                            variant='outlined'
                            onDelete={() => {
                              setQuoteEmails((oldQuoteMails) =>
                                oldQuoteMails.filter(
                                  (oldEmail) => oldEmail !== email,
                                ),
                              );
                            }}
                          />
                        ))}
                  </td>
                </tr>
              </tbody>
            </table>
            <LoadingButton
              variant='contained'
              loading={updateLoading}
              onClick={() => {
                updateOrganization({
                  variables: {
                    token,
                    id: organization.id,
                    quoteEmails,
                    quoteFromEmail: quoteFromEmail ? quoteFromEmail : null,
                  },
                });
              }}
            >
              Update Organization
            </LoadingButton>
            {updateError ? (
              <>
                <p>Error updating organization:</p>
                <pre>{JSON.stringify(updateError, null, 4)}</pre>
              </>
            ) : null}
          </section>
        </div>
      )}
    </div>
  );
};
