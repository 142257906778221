// @ts-nocheck
import type { RouteComponentProps } from '@reach/router';
import { InformedMaterialObligateInputToggleMultiple } from '../components/common/InformedMaterialFills';

import { Form } from 'informed';
import { useEffect, useState, useCallback, useMemo } from 'react';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface TestingProps extends RouteComponentProps {}
export const Testing = ({ classes }: TestingProps) => {
  return (
    <div>
      <button
        onClick={() => {
          throw new Error('BANG!');
        }}
      >
        Raise Exception
      </button>
    </div>
  );
};
