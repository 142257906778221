import { motion, AnimatePresence } from 'framer-motion';
// material
import { Dialog } from '@mui/material';
//
import { varFadeInUp } from './variants';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import type { ReactNode } from 'react';

// ----------------------------------------------------------------------

/* DialogAnimate.propTypes = { */
/*   open: PropTypes.bool.isRequired, */
/*   animate: PropTypes.object, */
/*   onClose: PropTypes.func, */
/*   children: PropTypes.node.isRequired, */
/* }; */

interface DialogAnimateProps {
  open: boolean;
  animate?: $TSFixMe;
  onClose: $TSFixMe; // function
  children: ReactNode;
  [k: string]: $TSFixMe;
}

export default function DialogAnimate({
  open = false,
  animate,
  onClose,
  children,
  ...other
}: DialogAnimateProps) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth='xs'
          open={open}
          onClose={onClose}
          // @ts-expect-error
          PaperComponent={motion.div}
          PaperProps={{
            sx: {
              borderRadius: 2,
              bgcolor: 'background.paper',
            },
            ...(animate || varFadeInUp),
          }}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
