import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';
import { useSettings } from '../../hooks';
import whiteWalnutLogo from './Commercial/assets/whiteLogo.svg';

export const WalnutPartnersFooter = () => {
  const settings = useSettings();
  return (
    <FooterContainer>
      <FooterInnerContainer>
        <TopInfoContainer>
          <Typography
            variant='body2'
            sx={{
              marginRight: 1,
              mb: 1,
            }}
          >
            Our brokers are ready to assist with your purchase
          </Typography>
          {settings.phone ? (
            <Button
              startIcon={<LocalPhoneIcon sx={{ color: 'white' }} />}
              component='a'
              href={`tel:${settings.phone}`}
              sx={{
                flexShrink: 0,
                color: 'white',
                borderRadius: 0,
                border: `solid 1px white`,
              }}
            >
              {settings.phone}
            </Button>
          ) : null}
        </TopInfoContainer>
        <FooterHr sx={{ mt: 2 }} />
        <LinksContainer>
          {[
            ['Licenses', 'https://www.gowalnut.com/licenses'],
            [
              'Broker Disclosures',
              'https://www.gowalnut.com/pnc-comp-disclosure',
            ],
            [
              'Privacy',
              settings.privacyLink
                ? settings.privacyLink
                : 'https://www.gowalnut.com/privacy',
            ],
            ['Terms of Use', 'https://www.gowalnut.com/terms-of-use'],
            [
              'Contact Us',
              ('mailto:' + (settings.email
                ? settings.email
                : 'contact@gowalnut.com')),
            ],
          ].map(([label, href]) => {
            return (
              <Link key={label} href={href}>
                {label}
              </Link>
            );
          })}
        </LinksContainer>
        <FooterHr />
        <PoweredByContainer>
          <Typography variant='body2'>Powered By</Typography>
          <WalnutImage src={whiteWalnutLogo} alt='Logo' />
        </PoweredByContainer>
        <Typography
          variant='body2'
          sx={{
            fontSize: '0.75rem',
            lineHeight: '1rem',
            mt: 1,
          }}
        >
          &copy;2024 Walnut Insurance Inc., Walnut Services Inc., Walnut
          Marketing Services Inc. All rights reserved.
        </Typography>
      </FooterInnerContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled('div')(({ theme }) => {
  return {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };
});
const FooterInnerContainer = styled('div')(({ theme }) => {
  return {
    margin: theme.spacing(4),
  };
});

const TopInfoContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  };
});

const FooterHr = styled('hr')(() => {
  return {
    color: 'white',
  };
});

const LinksContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(4),
    '& a': {
      color: 'white',
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  };
});

const PoweredByContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  };
});

const WalnutImage = styled('img')(({ theme }) => {
  return { width: theme.spacing(15) };
});
