import { useEffect, useState } from 'react';
import { Typography, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {
  connect,
  useDispatch,
  /* useSelector */
} from 'react-redux';
import { QuoteWizardState, Dispatch } from '../../../store';
import {
  setSaveButtonState,
  setAdditionalInformation,
} from '../../../store/QuoteWizardState';
// saving the form for later
// for error handling of the pdf generation and the submission process
import { CompleteQuoteSubmissionError } from '../ErrorHandling';
import { BackButton } from '../NavigationButtons';
/* import { QuestionAnswerDisplay } from './QuestionAnswerDisplay'; */
import { QuestionAnswerDisplay } from '../../common/QuestionAnswerDisplay';
import { hybridAnswers } from '../../util/Quote';
// Number formatter
import { getLabel } from './utility';
import { BounceButton } from '../../../Shared';
import { sortReviewForms } from './utility';

import { useIsManager, useWarnBeforeUnloadWithAnswers } from '../../../hooks';
import { useScrollToTopOnInitialMount } from '@calefy-inc/hooks';

import { Signature } from './Signature';

// classes and types
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { UnifiedCompletedForm } from '../../../Typescript/classes';
import { QuoteWizardAnsweredForm } from '../classes';
import { PolicySummary } from '../types';
import { StoreState } from '../../../store';
import { RouteComponentProps } from '@reach/router';
import { StepWizardProps } from 'react-step-wizard';
import { FormEditButton } from './FormEditButton';
import { useWalnutGoogleAnalytics } from '../../../hooks';
import {
  GAEventNames,
  EventActions,
} from '../../../hooks/useWalnutGoogleAnalytics';
import { createPageLoadThunk } from '../../../store/analyticsStore';

interface QuoteReviewDisplayProps
  extends ReturnType<typeof mergeProps>,
    RouteComponentProps,
    StepWizardProps {
  classes: GenericObject;
  backLink: $TSFixMe;
  componentMapping: $TSFixMe;
}

/**
 * Shows the user all of the questions and answers for all of the forms that they've filled out as part of the Quote submission process and allows them to submit them to the backend
 *
 * @param formsWithAnswers - The forms with accompanying user-entered answers
 * @param classes - the theming classes
 * @param onConfirmQuote - function fired off to submit the quote (see mapStateToProps and mappedDispatchToProps)
 * @param submittedQuoteForBusinessType - the actual quote which has been submitted; will be undefined until the quote is actually submitted. THIS SHOULD BE A BOOLEAN
 * @param currentQuoteUUID - The UUID of the current quote. Null -> the quote was initialized and completed all in one go, String: -> this quote has been resumed at some point
 * @param allRelevantForms - All of the forms which apply to this quote (not the ones for the policies that were not selected)
 * @param errorPresent - whether or not there is an error somewhere - used to halt submission
 */
export function QuoteReviewDisplay({
  formsWithAnswers,
  classes = {},
  onConfirmQuote,
  selectedBusinessType,
  submissionInProgress,
  submittedQuoteForBusinessType,
  allRelevantForms,
  errorPresent,
  currentStep,
  nextStep,
  previousStep,
  // @ts-expect-error
  componentMapping,
  // @ts-expect-error
  goToStep,
  totalSteps,
  isActive,
  additionalInformation,
  confirmationInProgress,
}: QuoteReviewDisplayProps) {
  // debugger;
  useWarnBeforeUnloadWithAnswers();
  useScrollToTopOnInitialMount();

  const dispatch = useDispatch();
  const { isManager } = useIsManager();
  const [okToContinue, setOkToContinue] = useState<boolean>(false); // whether we can advance to the "Thank you!" page
  const [okToSubmit, setOkToSubmit] = useState<boolean>(false); // whether we are ready to submit the quote
  const [waitingToSubmit, setWaitingToSubmit] = useState<boolean>(false); // have you pressed the submit button and are currently waiting for the pdf to generate or somesuch?

  // different amended versions of the forms
  const [formsWithAllQuestionsAnswered, setFormsWithAllQuestionsAnswered] =
    useState<Array<UnifiedCompletedForm> | null>(null); // forms with unanswered questions amended with default answers
  const [signatureData, setSignatureData] = useState<$TSFixMe>();
  const [signatureError, setSignatureError] = useState<null | Error>(null); // the Error caught by the Signature component

  // are there any errors in any of the forms
  const [errorsExist, setErrorsExist] = useState<boolean>(false);

  // is the submit button disabled
  const [submitButtonDisabled, setSubmitButtonDisabled] =
    useState<boolean>(true);
  /* const renewalInProgress = useSelector(getRenewalInProgress); */
  const googleAnalytics = useWalnutGoogleAnalytics();

  // send off the analytics call
  useEffect(() => {
    if (!isActive || !googleAnalytics) {
      return;
    }
    googleAnalytics.triggerEvent(
      GAEventNames.Review,
      EventActions.PageLoad,
      '',
    );
  }, [googleAnalytics, isActive]);

  // send the analytics call as soon as we load in
  useEffect(() => {
    const pageLoadData = {
      page: 'Review',
      pageNumber: currentStep,
      totalPages: totalSteps,
    };
    // @ts-expect-error
    dispatch(createPageLoadThunk(pageLoadData));
  }, []);

  // Set button state
  useEffect(() => {
    dispatch(
      setSaveButtonState({
        visible: true,
        reconcileState: () => {},
      }),
    );
  }, [dispatch, setSaveButtonState]);

  // amend the raw quote with default answers
  useEffect(() => {
    if (!formsWithAnswers || !allRelevantForms) {
      /*console.log('QuoteReview: no forms found to amend with default answers', { */
      /*   formsWithAnswers, */
      /*   allRelevantForms, */
      /* }); */
      return;
    }
    try {
      const amendedForms = hybridAnswers(formsWithAnswers, allRelevantForms);
      //console.log('Just generated formsWithAllQuestionsAnswered', {
      //   formsWithAnswers,
      //   allRelevantForms,
      //   amendedForms,
      // });
      setFormsWithAllQuestionsAnswered(amendedForms);
    } catch (e) {
      console.error('Error amending form with default answers:', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsWithAnswers, allRelevantForms]);

  // set OK to continue
  useEffect(() => {
    if (submittedQuoteForBusinessType) {
      // ok to navigate and fire off the event
      /*console.log('About to set okToContinue to true'); */
      setOkToContinue(true);
    } else {
      /*console.log('About to set okToContinue to false'); */
      setOkToContinue(false);
    }
  }, [submittedQuoteForBusinessType]);

  // begin the submission process if it is OK to do so
  useEffect(() => {
    const formsToSubmit = formsWithAllQuestionsAnswered || formsWithAnswers;
    if (okToSubmit) {
      onConfirmQuote(formsToSubmit, signatureData, additionalInformation);
      setWaitingToSubmit(false);
    }
  }, [
    okToSubmit,
    formsWithAllQuestionsAnswered,
    formsWithAnswers,
    onConfirmQuote,
    signatureData,
    additionalInformation,
  ]);

  // set whether it is OK to submit the current quote
  useEffect(() => {
    if (
      waitingToSubmit &&
      !errorsExist &&
      !errorPresent &&
      (signatureData || !!signatureError)
    ) {
      setOkToSubmit(true);
    } else {
      setOkToSubmit(false);
    }
  }, [
    waitingToSubmit,
    errorPresent,
    signatureData,
    signatureError,
    errorsExist,
  ]);

  // cancel the current submission if there is an error
  useEffect(() => {
    if (errorPresent || errorsExist) {
      setWaitingToSubmit(false);
    }
  }, [errorPresent, errorsExist]);

  // should the submit button be disabled
  useEffect(() => {
    const disabled =
      !!(submissionInProgress || submittedQuoteForBusinessType) ||
      errorsExist ||
      errorPresent ||
      waitingToSubmit ||
      !(signatureData || !!signatureError);

    /*console.log('QuoteReview: about to set submitButtonDisabled to', disabled, { */
    /*   submissionInProgress, */
    /*   submittedQuoteForBusinessType, */
    /*   errorsExist, */
    /*   waitingToSubmit, */
    /*   signatureData, */
    /*   signatureError, */
    /* }); */
    setSubmitButtonDisabled(disabled);
  }, [
    submissionInProgress,
    submittedQuoteForBusinessType,
    errorsExist,
    errorPresent,
    waitingToSubmit,
    signatureData,
    signatureError,
  ]);

  // determine whether any errors exist in the forms
  useEffect(() => {
    const formsToDisplay = formsWithAllQuestionsAnswered;
    if (formsToDisplay === null) {
      return;
    }
    const formErrorsExist = formsToDisplay.some(
      (form) => form.errors && form.errors.length > 0,
    );
    /*console.log('About to set errorsExist to', formErrorsExist); */
    setErrorsExist(formErrorsExist);
  }, [formsWithAllQuestionsAnswered, formsWithAnswers]);

  const formsToDisplay = formsWithAllQuestionsAnswered || [];
  // Use forms to render answers.
  const general = formsToDisplay.filter(
    (formAnswer) => formAnswer.form.policy === null,
  );

  const policies = formsToDisplay.filter(
    (formAnswer) => formAnswer.form.policy !== null,
  );

  if (okToContinue) {
    nextStep();
  }

  const business = selectedBusinessType
    ? selectedBusinessType.displayName
    : null;
  const size = formsToDisplay ? formsToDisplay.length : 0;

  /*console.log('QuoteReview submit button disabled:', submitButtonDisabled); */
  return (
    <>
      <>
        <div className={classes.header} key={0}>
          {/*
          // @ts-expect-error */}
          <Typography
            style={{ color: 'white' }}
            size='large'
            variant='h5'
            key={0}
          >
            {confirmationInProgress
              ? 'Please review the below information carefully, and submit when you are sure that it is correct.'
              : 'Application Details'}
          </Typography>
          <Typography style={{ color: 'white' }} variant='subtitle1' key={1}>
            Line: {business}
          </Typography>
        </div>

        <div className={classes.outerContainer} key={1}>
          {sortReviewForms([...general, ...policies]).map(
            (amendedFormWithAnswers, index) => {
              return (
                <>
                  <div className={classes.title}>
                    <Typography variant='h6' color='primary'>
                      {getLabel(amendedFormWithAnswers)}
                    </Typography>
                    {amendedFormWithAnswers.errors &&
                    amendedFormWithAnswers.errors.length > 0 ? (
                      <Typography color='error'>
                        {'This form has an error.'}
                      </Typography>
                    ) : null}
                    <FormEditButton
                      policy={amendedFormWithAnswers.form.policy}
                    />
                    {/* <Tooltip */}
                    {/*   title='Edit Answers' */}
                    {/*   enterDelay={100} */}
                    {/*   leaveDelay={100} */}
                    {/*   placement='right' */}
                    {/* > */}
                    {/*   {/1* @ts-expect-error *1/} */}
                    {/*   <IconButton */}
                    {/*     aria-label='Edit Answers' */}
                    {/*     onClick={() => { */}
                    {/*       let path = getLabel(amendedFormWithAnswers); */}
                    {/*       goToStep(componentMapping[path]); */}
                    {/*     }} */}
                    {/*     disabled={ */}
                    {/*       !( */}
                    {/*         componentMapping[ */}
                    {/*           getLabel(amendedFormWithAnswers) */}
                    {/*         ] >= 1 && */}
                    {/*         componentMapping[ */}
                    {/*           getLabel(amendedFormWithAnswers) */}
                    {/*         ] <= totalSteps */}
                    {/*       ) */}
                    {/*     } */}
                    {/*     color='primary' */}
                    {/*     variant='outlined' */}
                    {/*     size='small' */}
                    {/*   > */}
                    {/*     <EditIcon /> */}
                    {/*   </IconButton> */}
                    {/* </Tooltip> */}
                  </div>
                  <dl className={classes.answerContents}>
                    {amendedFormWithAnswers.answers.map(
                      (answer, index, answers) => {
                        return (
                          <QuestionAnswerDisplay
                            form={amendedFormWithAnswers}
                            topLevelAnswer={answer}
                            edit={true}
                            answer={answer}
                            answers={answers}
                            indent={0}
                            key={answer.apiName + index}
                          />
                        );
                      },
                    )}
                  </dl>
                  <div className={classes.divider}>
                    {index + 1 !== size || index + 1 === size ? (
                      <Divider variant='middle' />
                    ) : null}
                  </div>
                </>
              );
            },
          )}
          <div className={classes.additionalInformationContainer}>
            <TextField
              rows={5}
              value={additionalInformation}
              onChange={(e: $TSFixMe) =>
                dispatch(setAdditionalInformation(e.target.value))
              }
              id='additional-information'
              label='Additional Information or Comments'
              fullWidth
              multiline
            />
          </div>
          <div className={classes.signatureContainer}>
            <Signature
              handleImageData={(imageData: $TSFixMe) => {
                setSignatureData(imageData);
              }}
              onError={(error: Error) => {
                setSignatureError(error);
              }}
            />
          </div>
          {!!signatureError ? null : (
            <div className={classes.divider}>
              <Divider variant='middle' />
            </div>
          )}{' '}
          <div className={classes.buttonContainer}>
            <CompleteQuoteSubmissionError />
            <BounceButton
              variant='contained'
              color={!isManager ? 'secondary' : 'primary'}
              size='large'
              disabled={submitButtonDisabled}
              onClick={() => {
                setWaitingToSubmit(true);
              }}
            >
              {(waitingToSubmit || submissionInProgress) && !okToContinue ? (
                <>
                  Submitting...{' '}
                  <CircularProgress
                    className={classes.submittingSpinner}
                    size='1rem'
                  />
                </>
              ) : (
                `${
                  confirmationInProgress ? 'Confirm and ' : ''
                }Submit all forms`
              )}
            </BounceButton>
          </div>
          <div className={classes.navButtons}>
            <BackButton onClick={previousStep} type='button' />
          </div>
        </div>
        <div id='google-attribution'></div>
      </>
    </>
  );
}

/* Theming / Styling */
export const QuoteReviewDisplayWithStyles = withStyles((theme) => ({
  header: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    maxWidth: '60rem',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.grey[700],
  },
  outerContainer: {
    backgroundColor: '#DFE3E8',
    display: 'flex',
    margin: 'auto',
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
    padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)}`,
    maxWidth: '60rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  answerContents: {
    display: 'grid',
    gridTemplateColumns: '[question] 2fr [answer] 2fr',
    '& dt': {
      display: 'inline',
      ...theme.typography.body2,
    },
    '& dd': {
      display: 'inline-flex',
      flexDirection: 'row',
      ...theme.typography.body2,
      // justifyContent: 'flex-end',
      textAlign: 'left',
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
    },
    '& dd:after': {
      content: '" "',
      display: 'block',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(3),
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
  submittingSpinner: {
    marginLeft: theme.spacing(2),
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  additionalInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <QuoteReviewDisplay {...props} />);

/* Connect Layer */

function mapStateToProps(state: StoreState) {
  const answersByFormId = state.quoteWizard.formAnswers;
  const selectedBusinessType = state.quoteWizard.selectedBusinessType;

  const allSelectedPolicyIds = state.quoteWizard.selectedPolicies.map(
    (fullPolicy: PolicySummary) => fullPolicy.id,
  );
  const allRelevantForms = [
    state.quoteWizard.businessForm,
    ...state.quoteWizard.policyForms,
  ].filter(
    // @ts-expect-error
    (form) => !form?.policy || allSelectedPolicyIds.includes(form.policy.id),
  );

  const formsWithAnswers: Array<QuoteWizardAnsweredForm> = Object.values(
    answersByFormId,
  ).filter((answeredForm) => {
    const { form } = answeredForm;
    const selectedBusinessId = !selectedBusinessType
      ? undefined
      : 'id' in selectedBusinessType
      ? selectedBusinessType.id
      : selectedBusinessType?.original.id;
    // we want forms that are either a general information form or for a policy that has been selected, and the business line should either match the selected one or be from the Generic business type
    return (
      (!form.policy ||
        allSelectedPolicyIds.some((id) => form.policy?.id === id)) &&
      ((form.businessLine &&
        selectedBusinessType &&
        form.businessLine.id === selectedBusinessId) ||
        form.businessLine.internalName === 'generic')
    );
  });

  const additionalInformation = state.quoteWizard.additionalInformation;

  const submissionInProgress = formsWithAnswers.find(
    (formWithAnswer) => formWithAnswer.inProgress,
  );

  const submittedQuoteForBusinessType = state.quoteWizard.submittedQuotes.find(
    (quote) => {
      const selectedBusinessTypeId = !selectedBusinessType
        ? undefined
        : 'id' in selectedBusinessType
        ? selectedBusinessType.id
        : selectedBusinessType?.original.id;
      return (
        quote.businessLine.id === selectedBusinessTypeId &&
        quote.status !== 'CONTACT_INFO_ONLY'
      );
    },
  );

  const errorPresent = Object.values(state.quoteWizard.errors).some(
    (errorStatus) => errorStatus !== null,
  );

  return {
    formsWithAnswers,
    selectedBusinessType,
    currentQuoteUUID: state.quoteWizard.currentQuoteUUID,
    submissionInProgress,
    submittedQuoteForBusinessType,
    allRelevantForms,
    errorPresent,
    renewalInProgress: state.quoteWizard.renewalInProgress,
    additionalInformation,
    confirmationInProgress: state.quoteWizard.confirmationInProgress,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const { confirmAnswersForForms } = QuoteWizardState.actions;

  function onConfirmQuote({
    formsWithAnswers,
    businessType,
    status,
    currentQuoteUUID,
    signature,
    additionalInformation,
    renewalInProgress,
  }: $TSFixMe) {
    return dispatch(
      // @ts-expect-error
      confirmAnswersForForms({
        businessType,
        formsWithAnswers,
        status,
        currentQuoteUUID,
        signature,
        additionalInformation,
        renewalInProgress,
      }),
    );
  }

  return {
    onConfirmQuote,
  };
}

function mergeProps(
  mappedState: ReturnType<typeof mapStateToProps>,
  mappedDispatch: ReturnType<typeof mapDispatchToProps>,
  ownProps: $TSFixMe,
) {
  const onConfirmQuote = (
    amendedFormsWithAnswers: UnifiedCompletedForm,
    signature: $TSFixMe,
    additionalInformation: string,
  ) => {
    return mappedDispatch.onConfirmQuote({
      businessType: mappedState.selectedBusinessType,
      formsWithAnswers: amendedFormsWithAnswers,
      status: mappedState.renewalInProgress
        ? 'RENEWED'
        : mappedState.confirmationInProgress
        ? 'CONFIRMED'
        : 'COMPLETE',
      currentQuoteUUID: mappedState.currentQuoteUUID,
      signature,
      additionalInformation,
    });
  };

  return {
    ...mappedState,
    ...mappedDispatch,
    ...ownProps,
    onConfirmQuote,
  };
}

export const QuoteReviewConnectLayer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
);

export default QuoteReviewConnectLayer((mappedState: $TSFixMe) => (
  <QuoteReviewDisplayWithStyles {...mappedState} />
));
