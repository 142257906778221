import theme from './FuseInsuranceTheme';
import logo from './assets/MainLogo.png';
import mobileLogo from './assets/FuseMobileLogo.png';
import squareLogo from './assets/FuseSquareLogo.png';
import favicon from './assets/favicon.ico';
import { fuseBusinessFilter } from './businessFilter';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';

// Fuse Insurance Config
const settings = new WebsiteSettings({
  slug: 'fuseinsurance',
  host: 'secure.fuseinsurance.ca',
  companyName: 'Fuse Insurance Ltd',
  hours: '8:30am - 5:30pm',
  phone: '1-866-387-3873',
  email: 'support@fuseinsurance.ca',
  chatLink: 'https://www.facebook.com/FuseInsuranceCA',
  companyTheme: theme,
  logo: logo,
  title: 'Fuse Insurance: Business Insurance In Canada',
  favicon: favicon,
  mobileLogo: mobileLogo,
  squareLogo,
  sendEmailOnContactInformationCompleted: true,
  contactInformationFirst: true,
  businessFilter: fuseBusinessFilter,
});

export default settings;
