interface SignatureInput {
  id: string;
  signatureData: string;
  signatureTime: string;
}

/**
 * Represents all of the information about a customer's signature
 * @param id - The ID in the backend
 * @param signatureData - The base64 encoded string representing the image data
 * @param signatureTime - The time at which the signature was received by the backend
 */
export class Signature {
  id: string;
  signatureData: string;
  signatureTime: string;

  constructor({ id, signatureData, signatureTime }: SignatureInput) {
    this.id = id;
    this.signatureData = signatureData;
    this.signatureTime = signatureTime;
  }
  static generateFromBackendResponse(response: SignatureInput) {
    return new Signature(response);
  }
}
