import { useRef, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from '@reach/router';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import copyOutline from '@iconify/icons-eva/copy-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import alertTriangleOutline from '@iconify/icons-eva/alert-triangle-outline';
import { determineBaseURL } from '@calefy-inc/authentication';
import { GET_ALL_REFERRERS, CREATE_REFERRER } from '../../../../../../queries';
import { Form, FormApi } from 'informed';

// material
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import LoadingButton from '@mui/lab/LoadingButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import Popover from '@mui/material/Popover';
import AddLinkIcon from '@mui/icons-material/AddLink';
import BusinessIcon from '@mui/icons-material/Business';

import { TextField as InformedTextField } from '@calefy-inc/informedMaterial';

// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import { DialogAnimate } from '../../../animate';
import { BusinessType } from '../../../../../../Typescript';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { useLazyQuery, useMutation } from '@apollo/client';
import CopyClipboard from '../../../CopyClipboard';
import Bugsnag from '@bugsnag/js';
import { GenerateBusinessLinkWithPolicy } from './GenerateBusinessLinkWithPolicies';
import { GenerateIframeLink } from './GenerateIframeLink';
import { errorify } from '../../../../../../util';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../../hooks';

// ----------------------------------------------------------------------
/* 
  Important: do not remove &#8288 from the text of any menu items. It will prevent certain 
  letter from being used in the referral link modal 
*/

type BusinessMoreMenuProps = {
  business: BusinessType;
  handleDelete: () => void;
  isDeletable: BusinessType['isDeletable'];
};
export default function BusinessMoreMenu({
  business,
  handleDelete,
  isDeletable,
}: BusinessMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [
    generateBusinessLinkWithPolicyOpen,
    setGenerateBusinesslinkWithPolicyOpen,
  ] = useState<boolean>(false);
  const [generateIframeLinkOpen, setGenerateIframeLinkOpen] =
    useState<boolean>(false);
  const [copyError, setCopyError] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<$TSFixMe>(null);
  const { enqueueSnackbar } = useSnackbar();
  const hasCorrectPermissions = useAllowByPermission('edit:business');

  const pathToCopy =
    determineBaseURL() + `/insurtech/businessLink/${business.id}`;

  // handlers
  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const handleClickDelete = () => {
    setIsOpen(false);
    setDeleteOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteBusiness = () => {
    setDeleteOpen(false);
    handleDelete();
  };

  const copyBusinessLinkToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar('Link copied successfully!', { variant: 'success' });
      handleMenuClose();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error copying business link!', { variant: 'error' });
      setCopyError(true);
      Bugsnag.notify(errorify(e));
    }
  };

  return (
    <>
      <IconButton
        ref={ref}
        onClick={handleMenuOpen}
        size='large'
        aria-label={`${business.displayName} More Menu`}
      >
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        data-testid='business-more-menu'
      >
        <Tooltip
          title={
            hasCorrectPermissions
              ? 'Edit'
              : "You don't have permission to edit businesses"
          }
        >
          <span>
            <MenuItem
              disabled={!hasCorrectPermissions}
              component={RouterLink}
              to={`${PATH_DASHBOARD.database.business}/${business.id}/edit`}
              sx={{ color: 'text.secondary' }}
            >
              <Icon icon={editFill} width={20} height={20} />
              <Typography variant='body2' sx={{ ml: 2 }}>
                &#8288;Edit
              </Typography>
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        <MenuItem
          onClick={async (e) => {
            if (copyError) {
              return;
            }
            setAnchorEl(e.currentTarget);
            await copyBusinessLinkToClipboard(pathToCopy);
          }}
          sx={{ color: 'text.secondary' }}
        >
          <Icon icon={copyOutline} width={20} height={20} />
          <Typography variant='body2' sx={{ ml: 2 }}>
            &#8288;Copy Business Link
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => setGenerateBusinesslinkWithPolicyOpen(true)}
          sx={{ color: 'text.secondary' }}
        >
          <AddLinkIcon width='20' height='20' />
          <Typography variant='body2' sx={{ ml: 2 }}>
            &#8288;Business, Policy, & Producer Link
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => setGenerateIframeLinkOpen(true)}
          sx={{ color: 'text.secondary' }}
        >
          <BusinessIcon width='20' height='20' />
          <Typography variant='body2' sx={{ ml: 2 }}>
            &#8288;<code>iframe</code> Link
          </Typography>
        </MenuItem>
        <Divider />

        <ReferralLinkModal businessId={business.id} />

        <Divider />

        <Tooltip
          title={
            hasCorrectPermissions
              ? 'Delete business'
              : "You don't have permission to delete businesses"
          }
        >
          <span>
            <MenuItem
              onClick={handleClickDelete}
              sx={{ color: 'error.main' }}
              disabled={!isDeletable || !hasCorrectPermissions}
            >
              <Icon icon={trash2Outline} width={20} height={20} />
              <Typography variant='body2' sx={{ ml: 2 }}>
                &#8288;Delete
              </Typography>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
      <DialogAnimate open={deleteOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Delete Business</DialogTitle>
        <DialogContent>
          <Typography variant='body2' color='textSecondary'>
            This action is irreversible. Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleDeleteDialogClose}>
            Close
          </Button>
          <Button variant='contained' onClick={handleDeleteBusiness}>
            Delete
          </Button>
        </DialogActions>
      </DialogAnimate>
      <Popover
        open={copyError}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 50, horizontal: -50 }}
        onClose={() => {
          setCopyError(false);
          handleMenuClose();
        }}
      >
        <Typography
          sx={{ p: 2 }}
          variant='subtitle2'
          align='center'
          color='primary'
        >
          Error Copying Business Link
        </Typography>
        <Typography variant='subtitle2' color='textSecondary' sx={{ p: 2 }}>
          Please copy the link manually: <br />
          {pathToCopy}
        </Typography>
      </Popover>

      <GenerateBusinessLinkWithPolicy
        open={generateBusinessLinkWithPolicyOpen}
        setOpen={setGenerateBusinesslinkWithPolicyOpen}
        onClose={() => setGenerateBusinesslinkWithPolicyOpen(false)}
        business={business}
      />
      <GenerateIframeLink
        open={generateIframeLinkOpen}
        setOpen={setGenerateIframeLinkOpen}
        onClose={() => setGenerateIframeLinkOpen(false)}
        business={business}
      />
    </>
  );
}

// Types
type ReferralLinkModalProps = {
  businessId: BusinessType['id'];
};

type ReferrerType = {
  id: string;
  name: string;
  referralCode: string;
};

/**
 * Allows use to select a referrer and generate a link for that referrer
 * @param referralLinkOpen - boolean which determines if referral modal is open
 * @returns referral modal
 */
const ReferralLinkModal = ({ businessId }: ReferralLinkModalProps) => {
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const formApiRef = useRef<FormApi>();
  const [open, setOpen] = useState(false);
  const [referrers, setReferrers] = useState<ReferrerType[]>([]);
  const [selectedReferrerLink, setSelectedReferrerLink] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [
    getReferrers,
    { loading: referrersLoading, data: referrersData, error: referrersError },
  ] = useLazyQuery(GET_ALL_REFERRERS);

  const [
    createReferrer,
    { loading: createLoading, data: createData, error: createError },
  ] = useMutation(CREATE_REFERRER);

  useEffect(() => {
    if (open) {
      getReferrers({
        variables: {
          token: token,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // store referrer list to state
  useEffect(() => {
    if (referrersData && referrersData.allReferrers) {
      const options = referrersData.allReferrers.map(
        // @ts-expect-error
        (referrer: ReferrerType) => ({
          label: referrer.name,
          referralCode: referrer.referralCode,
        }),
      );
      // @ts-expect-error
      setReferrers(options);
    }
  }, [referrersData]);

  // if anything is loading, set the state to loading
  useEffect(() => {
    const anyLoading = [referrersLoading];
    setLoading(anyLoading.some((loading) => !!loading));
  }, [referrersLoading]);

  // if any error occurs, set error state
  useEffect(() => {
    const anyError = !!referrersError;
    setError(anyError);
  }, [referrersError]);

  // if referrer is successfully added on backend, update the selectable list of referrers
  // display success popup
  useEffect(() => {
    if (
      createData &&
      // @ts-expect-error
      createData.createReferrer.ok &&
      // @ts-expect-error
      createData.createReferrer.referrer
    ) {
      // @ts-expect-error
      const newReferrer = createData.createReferrer.referrer;

      setReferrers([
        ...referrers,
        //@ts-expect-error
        { label: newReferrer.name, referralCode: newReferrer.referralCode },
      ]);
      enqueueSnackbar('Referrer Added!', { variant: 'success' });
      formApiRef.current?.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData]);

  // Notify if create referrer query fails
  useEffect(() => {
    if (createError) {
      enqueueSnackbar('Failed!', { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createError]);

  if (loading) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          width: '100%',
          display: 'flex',
          height: '34px',
        }}
      >
        <CircularProgress color='primary' size='1.2rem' />
      </Box>
    );
  }

  if (error) {
    enqueueSnackbar('Unable to get referral link!', { variant: 'error' });
    Bugsnag.notify('Failed to get get referrers');

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Icon
          icon={alertTriangleOutline}
          width={20}
          height={20}
          color='#FF5E58'
        />
        <Typography variant='body2' color='error' sx={{ ml: 2 }}>
          Link Error
        </Typography>
      </Box>
    );
  }

  const handleReferralLinkModalOpen = () => {
    setOpen(true);
  };

  const handleReferralLinkModalClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: $TSFixMe) => {
    createReferrer({
      variables: {
        name: values.name,
        referralCode: values.referralCode,
        token,
      },
    });
  };

  const stopPropagationForTab = (event: $TSFixMe) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <>
      <MenuItem
        onClick={handleReferralLinkModalOpen}
        sx={{ color: 'text.secondary' }}
      >
        <Icon icon={peopleOutline} width={20} height={20} />
        <Typography variant='body2' sx={{ ml: 2 }}>
          &#8288;Referral Link
        </Typography>
      </MenuItem>
      <Modal
        open={open}
        onKeyDown={stopPropagationForTab}
        onClose={handleReferralLinkModalClose}
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            //height: 'vh',
            maxWidth: '60rem',
            width: '100%',
            borderRadius: '16px',
            bgcolor: '#FFFFFF',
            p: 4,
            position: 'relative',
            overflow: 'auto',
          }}
        >
          <Typography variant='h6' color='textSecondary'>
            Referral Link
          </Typography>

          <IconButton
            sx={{
              position: 'absolute',
              right: 32,
              top: 32,
              padding: 0,
            }}
            onClick={handleReferralLinkModalClose}
            size='large'
          >
            <ClearIcon />
          </IconButton>
          <Grid container spacing={3} sx={{ py: 3 }}>
            <Grid item xs={12} sm={8}>
              <Stack spacing={2}>
                <Autocomplete
                  options={referrers}
                  sx={{
                    '& input': {
                      fontSize: '0.9rem',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select an existing referrer...'
                    />
                  )}
                  //@ts-expect-error
                  onChange={(event, newValue) => {
                    if (newValue) {
                      const referralLink =
                        determineBaseURL() +
                        `/insurtech/businessLink/${businessId}/referral/${newValue.referralCode}`;
                      setSelectedReferrerLink(referralLink);
                    }
                    if (newValue === null) {
                      setSelectedReferrerLink('');
                    }
                  }}
                />
                <CopyClipboard value={selectedReferrerLink} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Form
                noValidate
                autoComplete='off'
                initialValues={{
                  name: '',
                  referralCode: '',
                }}
                getApi={(api) => {
                  //@ts-expect-error
                  formApiRef.current = api;
                }}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant='body1' color='textSecondary'>
                        Add a new referrer
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack direction={'column'} spacing={2}>
                        <InformedTextField
                          name='name'
                          field='name'
                          label='Referrer Name'
                          required
                        />
                        <InformedTextField
                          name='referralCode'
                          field='referralCode'
                          label='Referral Code'
                          required
                        />
                        <LoadingButton
                          loading={createLoading}
                          variant='contained'
                          type='submit'
                        >
                          Add Referrer
                        </LoadingButton>
                        {createError ? (
                          <Typography variant='caption' color={'error'}>
                            {createError.message}
                          </Typography>
                        ) : null}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
