import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

// types and classes
interface DataLossWarningProps {
  warningInfo: { open: boolean; message: string; isNavBack: boolean };
  onBack: () => void;
  onContinue: () => void;
}

/**
 * Displays a warning message when a nav action will cause loss of data
 * @param warningInfo - message and status to be displayed in the dialog box
 * @param onBack - function to call when the 'back' button is pressed
 * @param onContinue - function to call when the continue ("I'm Sure") button is pressed
 */
export const DataLossWarning = ({
  warningInfo,
  onBack,
  onContinue,
}: DataLossWarningProps) => {
  const { open, message } = warningInfo;

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby='business-selection-dialog-title'
        data-testid='changing-business-modal'
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='business-selection-dialog-description'>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onBack}>Back</Button>
          <Button onClick={onContinue} autoFocus>
            I'm Sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
