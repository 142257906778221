import { cloneDeep, isEqual } from 'lodash';

// classes and types
import type {
  $TSFixMe,
  GenericObject,
  ArrayElement,
} from '@calefy-inc/utilityTypes';
import { QuoteWizardAnswerInstance } from './QuoteWizardAnswerInstance';
import { UnifiedAnswerInstance } from '../../../Typescript/classes';
import { AnswerInstanceDetails } from '../../../Typescript/backend/classes';

export interface QuoteWizardQuestionInstanceInput {
  id: string;
  apiName: string;
  displayName: string;
  label: string;
  helpText: string | null;
  component: string;
  dataType: string;
  propsBlob?: GenericObject;
  required?: boolean;
  askOnRenewal?: boolean;
  prefillOnRenewal?: boolean;
  method?: string;
  subQuestions: Array<QuoteWizardQuestionInstance>;
  questionTemplate?: $TSFixMe;
  defaultValue?: string;
}
export class QuoteWizardQuestionInstance {
  id: string;
  apiName: string;
  displayName: string;
  label: string;
  helpText: string | null;
  component: string;
  dataType: string;
  propsBlob: GenericObject;
  required: boolean;
  askOnRenewal: boolean;
  prefillOnRenewal: boolean;
  method?: string;
  subQuestions: Array<QuoteWizardQuestionInstance>;
  questionTemplate?: $TSFixMe;
  fieldPrefix?: string;
  defaultValue: string;

  constructor(input: QuoteWizardQuestionInstanceInput) {
    //console.log(
    //   'Constructor called for QuoteWizardQuestionInstance with input',
    //   input,
    // );
    const {
      id,
      apiName,
      displayName,
      label,
      helpText,
      component,
      dataType,
      propsBlob,
      required,
      askOnRenewal,
      prefillOnRenewal,
      method,
      subQuestions,
      defaultValue,
    } = input;

    this.id = id;
    this.apiName = apiName;
    this.displayName = displayName;
    this.label = label;
    this.helpText = helpText;
    this.component = component;
    this.dataType = dataType;
    this.propsBlob = propsBlob ? propsBlob : {};
    this.required = required ? true : false;
    this.askOnRenewal = askOnRenewal ? true : false;
    this.prefillOnRenewal = prefillOnRenewal ? true : false;
    this.method = method;
    this.subQuestions = subQuestions ? subQuestions : [];
    this.defaultValue = defaultValue || '';

    //console.log('constructed QuoteWizardQuestionInstance', this, 'from', input);
  }

  /**
   * Generate the `field` for rendering with a RenderedQuestionInstance or other InformedMaterial component. This will automatically prefix it with the fieldPrefix and a full stop, if that exists
   */
  generateFieldName() {
    const generatedField = this.fieldPrefix
      ? `${this.fieldPrefix}.${this.apiName}`
      : this.apiName;
    //console.log('GENERATEFIELDNAME:', generatedField);
    return generatedField;
  }

  /**
   * Given the value, generate the answer instance.
   */
  toQuoteWizardAnswerInstance(
    value: unknown,
    subAnswers?: Array<QuoteWizardAnswerInstance>,
  ) {
    return new QuoteWizardAnswerInstance({
      questionInstance: this,
      value,
      subAnswers: subAnswers ?? [],
    });
  }

  /**
   * Creates a QuoteWizardQuestionInstance from this filled in with the default values
   */
  toDefaultQuoteWizardAnswerInstance(): QuoteWizardAnswerInstance {
    return this.toQuoteWizardAnswerInstance(
      this.generateDefaultValue(),
      this.subQuestions.map((subQuestion) =>
        subQuestion.toDefaultQuoteWizardAnswerInstance(),
      ),
    );
  }

  /**
   * Creates a UnifiedAnswerInstance from this question
   */
  toUnifiedAnswerInstance({
    value,
    subAnswers,
  }: {
    value?: UnifiedAnswerInstance['value'];
    subAnswers?: UnifiedAnswerInstance['subAnswers'];
  } = {}) {
    return new UnifiedAnswerInstance({
      apiName: this.apiName,
      displayName: this.displayName,
      label: this.label,
      helpText: this.helpText,
      component: this.component,
      propsBlob: cloneDeep(this.propsBlob),
      dataType: this.dataType,
      details: new AnswerInstanceDetails(),
      required: this.required,
      value: value,
      method: this.method,
      askOnRenewal: this.askOnRenewal,
      prefillOnRenewal: this.prefillOnRenewal,
      subAnswers: subAnswers ? subAnswers : [],
      defaultValue: this.defaultValue,
    });
  }
  /**
   * creates a UnifiedAnswerInstance from this QuoteWizardAnswerInstance filled in with the default values
   */
  toDefaultUnifiedAnswerInstance(): UnifiedAnswerInstance {
    return this.toUnifiedAnswerInstance({
      value: this.generateDefaultValue(),
      subAnswers: this.subQuestions.map((subQuestion) =>
        subQuestion.toDefaultUnifiedAnswerInstance(),
      ),
    });
  }

  /**
   * Generates the default answer value for this particular question (ensure that this is in sync with the one in UnifiedAnswerInstance)
   */
  generateDefaultValue() {
    if (this.component === 'checkbox') {
      return false;
    } else if (this.subQuestions.length > 0) {
      return 1;
    } else {
      return 'N/A';
    }
  }

  /**
   * Whether or not a given UnifiedAnswerInstance matches this question instance
   */
  matchAnswerInstance(answer: UnifiedAnswerInstance) {
    const attrsToMatch: Array<
      keyof UnifiedAnswerInstance & keyof QuoteWizardQuestionInstance
    > = [
      'apiName',
      'component',
      'displayName',
      'label',
      'helpText',
      'dataType',
      'required',
      'method',
    ];
    return attrsToMatch.every((attr) => {
      const result = this[attr] === answer[attr];
      return result;
    });
  }

  /**
   * Return a copy of the QuoteWizardQuestionInstance
   */
  copy() {
    //console.log('QuoteWizardQuestionInstance: in copy');
    const input = Object.entries(this).reduce((acc: $TSFixMe, [k, v]) => {
      if (k === 'subQuestions') {
        acc['subQuestions'] = v.map(
          (subQ: ArrayElement<QuoteWizardQuestionInstance['subQuestions']>) =>
            subQ.copy(),
        );
      } else {
        acc[k] = cloneDeep(v);
      }
      return acc;
    }, {});
    return new QuoteWizardQuestionInstance(input);
  }

  /**
   * Return a copy of this question with amendments
   */
  copyWithAmendments(amendments: Partial<QuoteWizardQuestionInstance> = {}) {
    return new QuoteWizardQuestionInstance({
      ...this.copy(),
      ...amendments,
    });
  }

  /**
   * Determine if the other QuoteWizardQuestionInstance is equal in a shallow way (no check on subquestions, some fields omitted). This is mostly to be used when resuming a quote.
   * @param other - the other QuoteWizardQuestionInstance to compare this one to
   * @param options - {
   *  fieldFilter: a function to be passed to filter the fields on which to compare
   * }
   */
  shallowEquals(
    other: QuoteWizardQuestionInstance,
    options: {
      fieldFilter?: (
        field: keyof QuoteWizardQuestionInstance,
        index: number,
        allFields: Array<keyof QuoteWizardQuestionInstance>,
      ) => boolean;
    } = {},
  ): boolean {
    // since we are usually doing this when resuming a quote, we delibertately exclude the id field
    const fieldsToCompare: Array<keyof QuoteWizardQuestionInstance> = [
      'apiName',
      'displayName',
      'label',
      'helpText',
      'component',
      'dataType',
      'propsBlob',
      'required',
      'askOnRenewal',
      'prefillOnRenewal',
      // @ts-expect-error
    ].filter(options.fieldFilter ? options.fieldFilter : () => true);
    return fieldsToCompare.every((field) => {
      const equal = isEqual(this[field], other[field]);
      if (equal) {
        return true;
      }
      //console.log(
      //   'QuoteWizardQuestionInstances',
      //   this,
      //   other,
      //   `are different becuse of field ${field}.`,
      //   this[field],
      //   'vs.',
      //   other[field],
      // );
      return false;
    });
  }

  getLeafQuestions(): Array<QuoteWizardQuestionInstance> {
    if (this.subQuestions.length === 0) {
      return [this];
    }
    const leaves = this.subQuestions.reduce(
      (acc: Array<QuoteWizardQuestionInstance>, q) => {
        return [...acc, ...q.getLeafQuestions()];
      },
      [],
    );
    return leaves;
  }

  static generateFromBackendResponse(response: $TSFixMe) {
    //console.log({ response });
    let propsBlob = {};
    if (typeof response.propsBlob === 'string') {
      propsBlob = JSON.parse(response.propsBlob);
    } else if (response.propsBlob && typeof response.propsBlob === 'object') {
      propsBlob = response.propsBlob;
    }
    return new QuoteWizardQuestionInstance({
      ...response,
      propsBlob,
      subQuestions: response.subQuestions
        ? response.subQuestions.map((subqResponse: $TSFixMe) =>
            QuoteWizardQuestionInstance.generateFromBackendResponse(
              subqResponse,
            ),
          )
        : [],
    });
  }
}
