import { useState, useEffect } from 'react';
import { Card, Grid, CardHeader, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

import { FusProduct, FusProductsHighlights } from './classes';

import { DetailsCard, InfoChunk } from './commonComponents';
import { OptaDataNotLoadedPage } from './OptaDataNotLoadedPage';

// types and classes
import { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';

interface DisplayFusHighlightsProps {
  fusProduct: $TSFixMe;
}
const DisplayFusHighlights = ({ fusProduct }: DisplayFusHighlightsProps) => {
  const [highlights, setHighlights] = useState(
    new FusProductsHighlights(fusProduct),
  );

  useEffect(() => {
    setHighlights(new FusProductsHighlights(fusProduct));
  }, [fusProduct]);

  if (Object.values(highlights).every((elem) => elem === null)) {
    return 'No information';
  }

  return (
    <>
      <Grid container spacing={2}>
        {
          // @ts-expect-error
          highlights.PublicFireProtectionClassification ? (
            <InfoChunk
              key='PublicFireProtectionClassification'
              heading='Public Fire Protection Classification'
              information={
                // @ts-expect-error
                highlights.PublicFireProtectionClassification
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.RiskSCOR ? (
            <InfoChunk
              key='RiskSCOR'
              heading='Risk SCOR'
              information={
                // @ts-expect-error
                highlights.RiskSCOR
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.HydrantProtectedGrade ? (
            <InfoChunk
              key='HydrantProtectedGrade'
              heading='Hydrant Protection Grade'
              information={
                // @ts-expect-error
                highlights.HydrantProtectedGrade
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.FirehallProtectedGrade ? (
            <InfoChunk
              key='FirehallProtectedGrade'
              heading='Firehall Protection Grade'
              information={
                // @ts-expect-error
                highlights.FirehallProtectedGrade
              }
            />
          ) : null
        }
        {
          // @ts-expect-error
          highlights.AllOtherAreasGrade ? (
            <InfoChunk
              key='AllOtherAreasGrade'
              heading='All Other Areas Protection Grade'
              information={
                // @ts-expect-error
                highlights.AllOtherAreasGrade
              }
            />
          ) : null
        }
      </Grid>
    </>
  );
};

interface DisplayFusDataProps {
  details: $TSFixMe;
  setLocationData: $TSFixMe;
  loadQuotePdf: $TSFixMe;
  isArchived?: $TSFixMe;
  classes?: GenericObject;
}
/**
 * Display the FUS details for a particular location, or a button to get the data
 * @param {object} details - the details for the current location; may or may not include opta fus details
 * @param {function} setLocationFusDetails - a function (optaFusData) => void which amends the current location with its fus details
 * @param {object} classes - The theming classes
 */
export const DisplayFusData = ({
  details,
  setLocationData,
  loadQuotePdf,
  isArchived,
}: DisplayFusDataProps) => {
  const [fusProduct, setFusProduct] = useState<null | FusProduct>(
    // @ts-expect-error
    new FusProduct(),
  );

  useEffect(() => {
    if (details && details.opta && details.opta.optaFusData) {
      setFusProduct(FusProduct.createFromJson(details.opta.optaFusData));
    } else {
      setFusProduct(null);
    }
  }, [details, details.opta, details.opta.optaFusData]);

  if (!details || !details.opta || !details.opta.optaFusData) {
    return (
      // @ts-expect-error
      <OptaDataNotLoadedPage
        details={details}
        setLocationData={setLocationData}
        loadQuotePdf={loadQuotePdf}
        isArchived={isArchived}
      />
    );
  }
  return (
    <>
      <Typography
        variant='h6'
        align='left'
        color='textSecondary'
        sx={{ mb: 2 }}
      >
        Fus Data
      </Typography>
      <Grid container spacing={3} sx={{ textAlign: 'left' }}>
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: 'background.neutral' }}>
            <CardHeader
              title='Highlights'
              titleTypographyProps={{
                variant: 'h6',
                align: 'center',
                color: 'textSecondary',
              }}
            />
            <CardContent>
              {/* 
                // @ts-expect-error */}
              <DisplayFusHighlights fusProduct={fusProduct} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <DetailsCard heading='Dwelling Grades'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.Dwelling
                ? // @ts-expect-error
                  fusProduct.Dwelling.displayAsJsx()
                : 'No Information'
            }
          </DetailsCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DetailsCard heading='Commercial Grades'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.Commercial
                ? // @ts-expect-error
                  fusProduct.Commercial.displayAsJsx()
                : 'No Information'
            }
          </DetailsCard>
        </Grid>
        {(fusProduct &&
          // @ts-expect-error
          fusProduct.Dwelling &&
          // @ts-expect-error
          fusProduct.Dwelling.SearchMessage) ||
        (fusProduct &&
          // @ts-expect-error
          fusProduct.Commercial &&
          // @ts-expect-error
          fusProduct.Commercial.SearchMessage) ? (
          <Grid item xs={12}>
            <DetailsCard heading='Additional Message'>
              {fusProduct &&
              // @ts-expect-error
              fusProduct.Dwelling &&
              // @ts-expect-error
              fusProduct.Dwelling.SearchMessage ? (
                // @ts-expect-error
                <p>{fusProduct.Dwelling.SearchMessage}</p>
              ) : fusProduct &&
                // @ts-expect-error
                fusProduct.Commercial &&
                // @ts-expect-error
                fusProduct.Commercial.SearchMessage ? (
                // @ts-expect-error
                <p>{fusProduct.Commercial.SearchMessage}</p>
              ) : null}
            </DetailsCard>
          </Grid>
        ) : null}
        <Grid item xs={12} lg={4}>
          <DetailsCard heading='Responding Firehall'>
            {fusProduct &&
            // @ts-expect-error
            fusProduct.Dwelling &&
            // @ts-expect-error
            fusProduct.Dwelling.RespondingFirehall
              ? // @ts-expect-error
                fusProduct.Dwelling.RespondingFirehall.displayAsJsx()
              : fusProduct &&
                // @ts-expect-error
                fusProduct.Commercial &&
                // @ts-expect-error
                fusProduct.Commercial.RespondingFirehall
              ? // @ts-expect-error
                fusProduct.Commercial.RespondingFirehall.displayAsJsx()
              : 'No Information'}
          </DetailsCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <DetailsCard heading='Earthquake Codes'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.EarthquakeCodes
                ? // @ts-expect-error
                  fusProduct.EarthquakeCodes.displayAsJsx()
                : 'No Information'
            }
          </DetailsCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <DetailsCard heading='Hydrant Counts'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.HydrantCounts
                ? // @ts-expect-error
                  fusProduct.HydrantCounts.displayAsJsx()
                : 'No Information'
            }
          </DetailsCard>
        </Grid>

        <Grid item xs={12}>
          <DetailsCard heading='Maps'>
            {
              // @ts-expect-error
              fusProduct && fusProduct.FusMaps ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {
                    // @ts-expect-error
                    fusProduct.FusMaps.displayAsJsx()
                  }
                </div>
              ) : (
                'No Information'
              )
            }
          </DetailsCard>
        </Grid>
      </Grid>
    </>
  );
};
