import { RouteComponentProps } from '@reach/router';
import { styled, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { INSURANCE_RATE_AUDITS, ALL_ORGANIZATIONS } from '../../queries';
import { Form, Select, Calendar } from '@calefy-inc/informedMaterial';
import { withFormStyles, WithFormStyles } from '../../util/withFormStyles';
import { useEffect } from 'react';
import { useAuth } from '@calefy-inc/authentication';
import Button from '@mui/material/Button';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import {
  InsuranceRateAuditsQuery,
  CoreInsuranceRateAuditActionChoices,
} from '../../gql/graphql';

type Audit = NonNullable<
  ArrayElement<InsuranceRateAuditsQuery['getRateTableAudit']>
>;

interface RateAuditTableProps extends RouteComponentProps, WithFormStyles {}

export const RateAuditTable = withFormStyles()(
  ({ classes }: RateAuditTableProps) => {
    const { token } = useAuth();
    // @ts-expect-error
    const [getAudits, { loading, error, data }] = useLazyQuery(
      INSURANCE_RATE_AUDITS,
    );
    const [
      getOrganizations,
      { error: organizationError, data: organizationData },
    ] = useLazyQuery(ALL_ORGANIZATIONS);

    useEffect(() => {
      if (token) {
        getOrganizations({ variables: { token } });
      }
    }, [token]);

    return (
      <RateAuditTableContainer>
        <h1>Rate Audit Table</h1>

        <Form
          onSubmit={
            // @ts-expect-error
            (values) => {
              getAudits({
                variables: {
                  token,
                  ...values,
                },
              });
            }
          }
          className={classes.form}
        >
          {organizationData ? (
            <Select
              field='organization'
              options={[
                { value: undefined, label: 'None' },
                ...(organizationData.allOrganizations
                  ? organizationData.allOrganizations.map((orgData) => ({
                      value: orgData?.name,
                      label: orgData?.name,
                    }))
                  : []),
              ]}
              label='Organization'
            />
          ) : null}
          <Calendar
            field='startDate'
            label='Start Date'
            className={classes.calendar}
          />
          <Calendar
            field='endDate'
            label='End Date'
            className={classes.calendar}
          />
          <Button type='submit'>Submit</Button>
        </Form>

        {organizationError ? (
          <ErrorP>{JSON.stringify(organizationError, null, 4)}</ErrorP>
        ) : null}
        {error ? <ErrorP>{JSON.stringify(error, null, 4)}</ErrorP> : null}

        {data ? (
          <>
            <button
              type='button'
              onClick={() => {
                const csv =
                  'data:text/csv;charset=utf-8,' +
                  'id,organization_name,actual_rate_id,old_vale,old_group,old_symbol,old_display_name,new_value,new_group,new_symbol,new_display_name,user_email,created_at,action,headers\n' +
                  (data?.getRateTableAudit
                    ? data.getRateTableAudit
                        .filter((audit) => !!audit)
                        .map((audit: Audit) =>
                          [
                            `"${audit.id}"`,
                            `"${audit?.organization?.name}"`,
                            `"${audit.actualRateId}"`,
                            `"${audit.oldValue}"`,
                            `"${audit.oldGroup}"`,
                            `"${audit.oldSymbol}"`,
                            `"${audit.oldDisplayName}"`,
                            `"${audit.newValue}"`,
                            `"${audit.newGroup}"`,
                            `"${audit.newSymbol}"`,
                            `"${audit.newDisplayName}"`,
                            `"${audit.userEmail}"`,
                            `"${audit.createdAt}"`,
                            `"${audit.action}"`,
                            `"${JSON.stringify(audit.headers)
                              .replace(/'/g, "''")
                              .replace(/"/g, '""')}"`,
                          ].join(','),
                        )
                        .join('\n')
                    : '');
                const uri = window.encodeURI(csv);
                window.open(uri);
              }}
            >
              Download
            </button>
            <TableContainer>
              <StyledTable>
                <thead>
                  <StyledTr>
                    {[
                      'Date Added',
                      'Email',
                      'Action',
                      'Rate ID',
                      'Difference',
                    ].map((col) => (
                      <th scope='col' key={col}>
                        {col}
                      </th>
                    ))}
                  </StyledTr>
                </thead>
                <tbody>
                  {data.getRateTableAudit
                    ?.filter((audit) => !!audit)
                    .map((audit) => (
                      // @ts-expect-error
                      <StyledTr key={audit.id}>
                        <td>
                          {new Intl.DateTimeFormat('en-CA', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          }).format(new Date(audit?.createdAt))}
                        </td>
                        <td>
                          {
                            // @ts-expect-error
                            audit.userEmail
                          }
                        </td>
                        <td>
                          {
                            // @ts-expect-error
                            audit.action
                          }
                        </td>
                        <td>
                          {
                            // @ts-expect-error
                            audit.actualRateId
                          }
                        </td>
                        <td>
                          <DifferenceDisplay audit={audit} />
                        </td>
                      </StyledTr>
                    ))}
                </tbody>
              </StyledTable>
            </TableContainer>
          </>
        ) : null}
      </RateAuditTableContainer>
    );
  },
);

const RateAuditTableContainer = styled('div')();
const ErrorP = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

//@ts-expect-error
const TableContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
const StyledTable = styled('table')(({ theme }) => {
  return {
    width: '100%',
    padding: theme.spacing(5),
  };
});
// @ts-expect-error
const StyledTr = styled('tr')(({ theme }) => {
  return {};
});

interface DifferenceDisplayProps {
  audit: Audit;
}
const DifferenceDisplay = ({ audit }: DifferenceDisplayProps) => {
  if (audit.action === CoreInsuranceRateAuditActionChoices.Create) {
    return (
      <VerticalArrangement>
        <span>Group: {audit.newGroup}</span>
        <span>Display Name: {audit.newDisplayName}</span>
        <span>
          Value:{' '}
          {audit.newSymbol === '$'
            ? `${audit.newSymbol}${audit.newValue}`
            : `${audit.newValue}${audit.newSymbol}`}
        </span>
      </VerticalArrangement>
    );
  } else if (audit.action === CoreInsuranceRateAuditActionChoices.Edit) {
    return (
      <VerticalArrangement>
        <span>
          Group: {audit.oldGroup} &rarr; {audit.newGroup}
        </span>
        <span>
          Display Name: {audit.oldDisplayName} &rarr; {audit.newDisplayName}
        </span>
        <span>
          Value:{' '}
          {audit.oldSymbol === '$'
            ? `${audit.oldSymbol}${audit.oldValue}`
            : `${audit.oldValue}${audit.oldSymbol}`}
          &rarr;
          {audit.newSymbol === '$'
            ? `${audit.newSymbol}${audit.newValue}`
            : `${audit.newValue}${audit.newSymbol}`}
        </span>
      </VerticalArrangement>
    );
  } else if (audit.action === CoreInsuranceRateAuditActionChoices.Delete) {
    <VerticalArrangement>
      <span>Group: {audit.oldGroup}</span>
      <span>Display Name: {audit.oldDisplayName}</span>
      <span>
        Value:{' '}
        {audit.oldSymbol === '$'
          ? `${audit.oldSymbol}${audit.oldValue}`
          : `${audit.oldValue}${audit.oldSymbol}`}
      </span>
    </VerticalArrangement>;
  }
  return null;
};

const VerticalArrangement = styled('span')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});
