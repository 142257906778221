import { useWarnBeforeUnload } from '@calefy-inc/hooks';
import { useSelector } from 'react-redux';
import { StoreState } from '../store';

const determineIfAnswersPresent = (state: StoreState) =>
  Object.keys(state.quoteWizard.formAnswers).length > 0;

export const useWarnBeforeUnloadWithAnswers = (warn: boolean = false) => {
  const answersPresent = useSelector(determineIfAnswersPresent);

  useWarnBeforeUnload(warn || answersPresent);
};
