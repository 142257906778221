import { graphql } from '../gql';

export const GET_OPTA_FUS_DATA = graphql(`
  mutation getOptaFusData($token: String!, $answer: ID!) {
    getOptaFusData(token: $token, answer: $answer) {
      ok
      optaFusData {
        ...OptaFusDataFields
      }
    }
  }
`);
