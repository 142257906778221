import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const ErrorView = () => {
  return (
    <div data-testid='ErrorView' style={{ textAlign: 'center' }}>
      <p style={{ fontSize: '60px', marginBottom: '10px' }}>Error</p>
      <p>
        <strong>Whoopsy Daisy!</strong> It looks like something went wrong
      </p>
      <Typography variant='body1'>
        <Link color='primary' href='/insurtech/quote'>
          Refresh
        </Link>{' '}
        page
      </Typography>
    </div>
  );
};
