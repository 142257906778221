import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import QuoteWizardState from '../../../store/QuoteWizardState';
import { useLocation, navigate } from '@reach/router';
import { WithStyles, createStyles } from '@mui/styles';

// for displaying the forms and copying the text
import {
  generateQuoteDisplayStringFromAnswers,
  copyTextToClipboard,
} from './utility';
import { Dispatch, StoreState } from '../../../store';
import { Theme } from '@mui/system';

interface BaseCompleteQuoteSubmissionErrorProps
  extends WithStyles<typeof styles>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const BaseCompleteQuoteSubmissionError = ({
  error,
  clearError,
  resetSelections,
  cancelAllSubmissions,
  formAnswers,
  businessName,
  classes,
}: BaseCompleteQuoteSubmissionErrorProps) => {
  const location = useLocation();

  const displayString = `${
    location.host
  }\n\n${generateQuoteDisplayStringFromAnswers(formAnswers, businessName)}`;

  const target = 'quote';
  const quoteLocation = location.href.slice(
    0,
    location.href.indexOf(target) + target.length,
  );

  const handleClose = () => {
    cancelAllSubmissions();
    clearError();
  };

  return (
    <Dialog open={!!error} onClose={handleClose}>
      <Typography variant='h5' className={classes.title}>
        Submission Error
      </Typography>
      <div className={classes.outerContainer}>
        <Typography variant='body2'>
          We're very sorry, but there was an error submitting your application.
        </Typography>
        <Typography variant='body2'>
          Luckily, we've managed to keep your answers. Please send the questions
          and responses to{' '}
          <a
            href={`mailto:support@calefy.ca?subject=Submission Error&body=${displayString.replace(
              /\n/g,
              '%0D%0A',
            )}`}
            target={'_blank'}
            rel='noopener noreferrer'
          >
            support@calefy.ca
          </a>
          . If that link doesn't work for you, please copy the following into
          your email:
        </Typography>
        <div className={classes.copyIconContainer}>
          <Tooltip title='Copy to Clipboard' aria-label='copy to clipboard'>
            <IconButton
              onClick={() => copyTextToClipboard(displayString)}
              size='large'
            >
              <FileCopyIcon className={classes.copyIcon} />
            </IconButton>
          </Tooltip>
        </div>
        <pre className={classes.answers}>{displayString}</pre>
        <Typography variant='body2'>
          Once again, our apologies for this inconvenience. We'll get back to
          you within 2 - 3 business days.
        </Typography>
        <Typography variant='body2'>
          Note that it may be possible to save the application for later. To
          attempt this, click the "Return to Review" button, and from there
          select "Save".
        </Typography>
        <DialogActions>
          <Button
            onClick={() => {
              resetSelections();
              navigate(quoteLocation);
            }}
          >
            Restart
          </Button>
          <Button variant='contained' onClick={handleClose}>
            Return to Review
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    error: state.quoteWizard.errors.completeQuoteSubmission,
    formAnswers: state.quoteWizard.formAnswers,
    businessName: state.quoteWizard.selectedBusinessType
      ? state.quoteWizard.selectedBusinessType.displayName
      : '',
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  const { updateErrors, resetSelections, cancelAllSubmissions } =
    QuoteWizardState.actions;

  return {
    clearError: () => dispatch(updateErrors({ completeQuoteSubmission: null })),
    resetSelections: () => dispatch(resetSelections(['questionsPerPage'])),
    cancelAllSubmissions: () => dispatch(cancelAllSubmissions()),
  };
};
const ConnectedCompleteQuoteSubmissionError = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseCompleteQuoteSubmissionError);

const styles = (theme: Theme) =>
  createStyles({
    title: {
      color: 'white',
      textAlign: 'center',
      backgroundColor: theme.palette.grey[700],
      padding: `${theme.spacing(2)} 0`,
    },
    outerContainer: {
      maxWidth: '600px',
      padding: theme.spacing(2),
    },
    answers: {
      maxHeight: '500px',
      overflowY: 'scroll',
    },
    copyIcon: {},
    downloadIcon: {
      color: theme.palette.primary.main,
    },
    copyIconContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });

export const CompleteQuoteSubmissionError = withStyles(styles)(
  ConnectedCompleteQuoteSubmissionError,
);
