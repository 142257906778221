import {
  generalInformationAnswers,
  propertyAnswers,
  glAnswers,
  cyberAnswers,
} from './data';

// types and classes
import type { FormAnswers, PartialPolicy } from './types';
// policies
const propertyPolicy: PartialPolicy = {
  internalName: 'property',
};
const glPolicy: PartialPolicy = {
  internalName: 'general_liability',
};
const cyberPolicy: PartialPolicy = {
  internalName: 'cyber',
};

export const cannedAnswers: Array<FormAnswers> = [
  { policy: null, answers: generalInformationAnswers },
  { policy: propertyPolicy, answers: propertyAnswers },
  { policy: glPolicy, answers: glAnswers },
  { policy: cyberPolicy, answers: cyberAnswers },
];
