import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface SummaryPageDataDisplayProps {
  title: string;
  content?: string | null;
}
export function SummaryPageDataDisplay({
  title,
  content,
}: SummaryPageDataDisplayProps) {
  if (!content) {
    return null;
  }

  return (
    <Grid item xs={6}>
      <Typography variant='body1' color='primary'>
        {title}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {content}
      </Typography>
    </Grid>
  );
}
