import {
  Card,
  Typography,
  Grid,
  Divider,
  CardHeader,
  CardContent,
} from '@mui/material';

import { generateColourStringInRedGreenFromProportion } from './utility';

// types and classes
import type { GenericObject, $TSFixMe } from '@calefy-inc/utilityTypes';
import type { PropsWithChildren, ReactNode } from 'react';

interface DetailsCardProps {
  heading: string | null;
  childrenWrapperStyle?: GenericObject;
}
/**
 * Display a heading and some additional information
 * @param {ReactNode} children - the children (additional information to display)
 * @param {string | null} heading - The heading of the card
 * @param {Object | null} childrenWrapperStyle - styles to apply to the children wrapper
 */
export const DetailsCard = ({
  children,
  heading,
}: PropsWithChildren<DetailsCardProps>) => {
  return (
    <Card
      sx={{
        p: 1,
        height: '100%',
        '& ul': {
          paddingLeft: '1em',
          listStyle: 'none',
        },
        '& li': {
          breakInside: 'avoid',
          py: 0.8,
        },
        '& li:before': {
          content: '"-"',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
      }}
    >
      {heading ? (
        <CardHeader
          title={heading}
          titleTypographyProps={{
            variant: 'h6',
            align: 'center',
            color: 'textSecondary',
          }}
        />
      ) : null}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

interface InfoChunkProps {
  heading: string;
  information: ReactNode;
  backgroundColor?: $TSFixMe;
}
export const InfoChunk = ({ heading, information }: InfoChunkProps) => {
  return (
    <Grid xs={12} md={4} item>
      <Typography
        variant='subtitle2'
        align='center'
        gutterBottom
        noWrap
        color='textSecondary'
      >
        {heading}
      </Typography>
      <Divider />
      <Typography
        variant='body2'
        align='center'
        sx={{
          mt: 2,
          '& ul': {
            paddingLeft: '1em',
            listStyle: 'none',
          },
          '& li': {
            py: 1,
          },
          '& li:before': {
            content: '"-"',
            display: 'inline-block',
            width: '1em',
            marginLeft: '-1em',
          },
        }}
      >
        {information}
      </Typography>
    </Grid>
  );
};

interface ProportionColourCircleProps {
  proportion: number;
}
/**
 * Returns a circle coloured from green - red based on the proportion passed (0: green, 0.5: yellow, 1: red)
 */
export const ProportionColourCircle = ({
  proportion,
}: ProportionColourCircleProps) => {
  return (
    <div
      style={{
        display: 'inline-block',
        borderRadius: '50%',
        height: '0.875em',
        width: '0.875em',
        margin: '0 0.5em',
        backgroundColor:
          generateColourStringInRedGreenFromProportion(proportion),
      }}
    ></div>
  );
};
