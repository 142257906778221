import { useState, useEffect } from 'react';
import { useParams } from '@reach/router';
import { useAllowByEmailDomain } from '../../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
import { InsufficientPermissionsErrorPage } from '../../../../common/ErrorHandling';
import { useLazyQuery } from '@apollo/client';
import { FINAL_FORM } from '../../../../../queries';
import { ProgramBuilderForm } from '../../../../FormManager/classes';
import LoadingScreen from '../../../components/LoadingScreen';
import { ErrorView } from '../../../../common/ErrorView';
import FormBuilder from '../../../../FormManager/FormBuilder';

export const EditFormByIdManager = () => {
  const { formId } = useParams();
  const { token } = useAuth();
  const hasCorrectDomain = useAllowByEmailDomain('calefy.ca');
  const [form, setForm] = useState<ProgramBuilderForm>();
  const [getForm, { loading, error }] = useLazyQuery(FINAL_FORM, {
    onCompleted: (data) => {
      if (data.finalForm) {
        setForm(ProgramBuilderForm.generateFromBackendResponse(data.finalForm));
      }
    },
  });

  useEffect(() => {
    if (token && hasCorrectDomain && formId) {
      getForm({
        variables: {
          token,
          id: formId,
        },
      });
    }
  }, [token, hasCorrectDomain, formId]);

  if (!hasCorrectDomain) {
    return <InsufficientPermissionsErrorPage />;
  }
  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorView />;
  }
  if (form) {
    return <FormBuilder form={form} />;
  }
  return null;
};
