import { graphql } from '../gql';

export const BUSINESS_COMPLETION_PERCENTAGES = graphql(`
  query businessCompletionPercentages {
    businessCompletionPercentages {
      businessDisplayName
      rawPercentage
      rawBegun
      rawCompleted
      maximumAPosteriori
      lowerCi
      upperCi
    }
  }
`);
