// Constants
export const A4Width: string = '210mm';
export const A4Height: string = '297mm';
export const Elevation: number = 0;

// Components
/**
 * @returns a page break
 */
export const Pagebreak = () => {
  return (
    <div
      style={{
        breakAfter: 'page',
      }}
    ></div>
  );
};
