import { useEffect } from 'react';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface SignatureErrorFallbackProps {
  error: $TSFixMe;
  onError: (error: $TSFixMe) => void;
}
/**
 * This is the fallback in case the signature component errors out; in that case, it should call the provided function to handle the error and otherwise just return nothing to avoid disrupting the customer's experience
 */
export const SignatureErrorFallback = ({
  error,
  onError,
}: SignatureErrorFallbackProps) => {
  useEffect(() => {
    if (onError) {
      onError(error);
    }
  }, [error, onError]);
  return null;
};
