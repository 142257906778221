import { Card, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TotalTodaySkeleton() {
  return (
    <Card>
      <Skeleton width='30%' sx={{ mt: 3, mx: 3 }} />

      <Skeleton variant='rectangular' height='500px' sx={{ m: 3 }} />
    </Card>
  );
}
