import { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoadingIconButton } from './LoadingIconButton';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import { useAuth, determineBaseURL } from '@calefy-inc/authentication';
import { GENERATE_QUOTE_CONFIRMATION_CORRESPONDENCE } from '../../../../../../queries';
import { copyTextToClipboard } from '@calefy-inc/utility';

// types and classes
import type { Quote } from '../../../../../../Typescript/backend/classes';

const goodStatuses: Array<Quote['status']> = [
  'CONFIRMED',
  'RENEWED',
  'COMPLETE',
  'CONFIRMATION_IN_PROGRESS',
];
interface GenerateQuoteConfirmationButtonProps {
  quote: Quote;
}

export const GenerateQuoteConfirmationButton = ({
  quote,
}: GenerateQuoteConfirmationButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [displayHash, setDisplayHash] = useState<string | null>(null); // the hash to display, in case there is an error copying the hash generated from the confirmation call to the clipboard

  const [
    generateQuoteConfirmationCorrespondence,
    {
      loading: confirmationLoading,
      error: confirmationError,
      data: confirmationData,
    },
  ] = useMutation(GENERATE_QUOTE_CONFIRMATION_CORRESPONDENCE);

  // determine whether the button should be disabled
  useEffect(() => {
    setDisabled(!goodStatuses.includes(quote.status));
  }, [quote]);

  // deal with the generateQuoteConfirmationCorrespondence data
  useEffect(() => {
    (async () => {
      if (confirmationData?.generateQuoteConfirmationCorrespondence?.ok) {
        await copyTextToClipboard(
          `${determineBaseURL()}/insurtech/quote/confirm/${
            confirmationData.generateQuoteConfirmationCorrespondence.hash
          }`,
          () =>
            enqueueSnackbar(`Successfully got confirmation link!`, {
              variant: 'success',
            }),
          (error) => {
            enqueueSnackbar(
              `Error copying confirmation link. Please manually copy from the toolbar. ${JSON.stringify(
                error,
                null,
                4,
              )}`,
              { variant: 'error' },
            );
            setDisplayHash(
              // @ts-expect-error
              confirmationData.generateQuoteConfirmationCorrespondence.hash,
            );
          },
        );
      }
    })();
  }, [confirmationData, enqueueSnackbar]);

  // deal with a generateQuoteConfirmationCorrespondence error
  useEffect(() => {
    if (confirmationError) {
      enqueueSnackbar(
        `Error getting correspondence hash! ${JSON.stringify(
          confirmationError,
        )}`,
        { variant: 'error' },
      );
    }
  }, [confirmationError, enqueueSnackbar]);

  if (quote.anyBound()) {
    return null;
  }
  if (!token) {
    return null;
  }
  return (
    <>
      <Tooltip
        title={
          disabled
            ? 'Only a completed application or one in the process of being confirmed can have a new link generated'
            : 'Generate link to confirm application information'
        }
      >
        <span>
          <LoadingIconButton
            loading={confirmationLoading}
            disabled={disabled}
            loadingPosition='center'
            aria-label='copy link to confirm application information'
            onClick={() => {
              generateQuoteConfirmationCorrespondence({
                variables: { uuid: quote.uniqueId, token },
              });
            }}
            data-hash={
              confirmationData?.generateQuoteConfirmationCorrespondence?.hash ||
              ''
            }
          >
            <AddLinkRoundedIcon />
          </LoadingIconButton>
        </span>
      </Tooltip>
      {displayHash ? (
        <span>
          Confirmation link:{' '}
          <pre>{`${determineBaseURL()}/quote/confirm/${displayHash}`}</pre>
        </span>
      ) : null}
    </>
  );
};
