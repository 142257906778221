import 'react-sortable-tree/style.css';
import { withStyles } from '@mui/styles';
import { formModifierStore } from '../../../../../../../../store';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useState, useEffect } from 'react';

import {
  SortableTreeWithoutDndContext as SortableTree,
  toggleExpandedForAll,
} from 'react-sortable-tree';
// import Theme from '../../../../../../../FormManager/FormBuilder/DragDrop/Theme/index';
import Theme from './Theme';
import QuestionsDatabase from './QuestionsDatabase';
import { shallowEquals } from '../../../../../../../../util/QuestionInstance';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// types and classes
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { ProgramBuilderQuestionInstance } from '../../../../../../../FormManager/classes';
import { FinalFormModifier } from '../../../classes';
import { DisplayQuestionsToDelete } from '../DisplayQuestionsToDelete';
import { transformQuestionListToTreeData } from '../../../../../../../util/FormManager/questionListTree';

// This type must be assigned to the tree via the `dndType` prop as well

const externalNodeType = 'databaseQuestion';

const DragDropFormBuilderWithStyles = withStyles((theme) => ({
  root: {
    maxWidth: '70rem',
  },

  container: {
    marginTop: theme.spacing(2),
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '27% auto 27%',
  },
  sortable: {
    paddingLeft: theme.spacing(3),
  },
}))(DragDropFormBuilder);

interface DragDropFormBuilderProps {
  form: FinalFormModifier;
  classes: GenericObject;
  updateForm: (form: FinalFormModifier, treeData: Array<$TSFixMe>) => void;
  quotePreview?: $TSFixMe;
  addPendingQuestionData: (path: $TSFixMe, node: $TSFixMe) => void;
  updateEditAll: (
    form: FinalFormModifier,
    question: ProgramBuilderQuestionInstance,
    editAllFlag: boolean,
  ) => void;
  requestedBusinessType?: $TSFixMe;
}

function DragDropFormBuilder({
  form,
  classes = {},
  updateForm,
  addPendingQuestionData,
  updateEditAll,
}: DragDropFormBuilderProps) {
  const [pendingTreeData, setPendingTreeData] = useState<$TSFixMe>();
  const [topLevelQuestion, setTopLevelQuestion] =
    useState<ProgramBuilderQuestionInstance | null>(null);
  const [userEditAllResponse, setUserEditAllResponse] = useState<
    boolean | undefined
  >();
  const [myTreeData, setMyTreeData] = useState<
    ProgramBuilderQuestionInstance[]
  >(transformQuestionListToTreeData(form.questionInstances));
  const [myTreeDataHasCollapsibleNodes, setMyTreeDataHasCollapsibleNodes] =
    useState<boolean>(
      myTreeData.some((question) => question.children.length != 0),
    );

  useEffect(() => {
    setMyTreeData(transformQuestionListToTreeData(form.questionInstances));
  }, [form]);

  useEffect(() => {
    setMyTreeDataHasCollapsibleNodes(
      myTreeData.some((question) => question.children.length != 0),
    );
  }, [myTreeData]);

  if (userEditAllResponse !== undefined && topLevelQuestion) {
    updateForm(form, pendingTreeData);
    updateEditAll(form, topLevelQuestion, userEditAllResponse);
    setUserEditAllResponse(undefined);
  }

  function requestUpdateForm(
    form: FinalFormModifier,
    treeData: $TSFixMe,
    setEditAllDialogueOpen: $TSFixMe,
    setPendingTreeData: $TSFixMe,
    setTopLevelQuestion: $TSFixMe,
  ) {
    const changed = findConcerningChangedQuestion(form, treeData);
    if (changed) {
      /*console.log('DragDropFormBuilder Found concerning changed question'); */
      setPendingTreeData(treeData);
      setTopLevelQuestion(changed);
      setEditAllDialogueOpen(true);
    } else {
      /*console.log( */
      /*   "DragDropFormBuilder Didn't find concerning changed question", */
      /* ); */
      updateForm(form, treeData);
    }
  }

  const canDrop = (props: $TSFixMe) => {
    /*console.log('DRAGDROPFORMBUILDER: canDrop props:', props); */
    const { nextParent } = props;
    // debugger;

    if (!nextParent) {
      return true;
    }
    if (nextParent.dataType === 'nested') {
      return true;
    }
    return false;
  };

  const toggleExpandTree = (expanded: boolean) => {
    const newTreeData: $TSFixMe[] = toggleExpandedForAll({
      treeData: myTreeData,
      expanded,
    });
    setMyTreeData(newTreeData);
  };

  return (
    <div className={classes.root}>
      <DndProvider backend={HTML5Backend}>
        <div className={classes.container}>
          <QuestionsDatabase
            recursiveTransform={transformQuestionListToTreeData}
          />
          <div className={classes.sortable}>
            <Stack
              mb={1}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent='flex-start'
              spacing={2}
            >
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => toggleExpandTree(true)}
                disabled={!myTreeDataHasCollapsibleNodes}
              >
                Expand All
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                onClick={() => toggleExpandTree(false)}
                disabled={!myTreeDataHasCollapsibleNodes}
              >
                Collapse All
              </Button>
            </Stack>
            <SortableTree
              // @ts-expect-error
              theme={Theme}
              treeData={myTreeData}
              onMoveNode={(...args) => {
                const { treeData } = args[0];
                requestUpdateForm(
                  form,
                  treeData,
                  () => {},
                  setPendingTreeData,
                  setTopLevelQuestion,
                );
              }}
              onChange={(treeData) => {
                // @ts-expect-error
                setMyTreeData(treeData);
              }}
              canDrop={canDrop}
              isVirtualized={false}
              dndType={externalNodeType}
              // @ts-expect-error
              generateNodeProps={({ node, path }) => {
                return {
                  buttons: [
                    addPendingQuestionData,
                    myTreeData,
                    requestUpdateForm,
                    form,
                  ],
                  setPendingTreeData,
                  setTopLevelQuestion,
                };
              }}
            />
          </div>
          <DisplayQuestionsToDelete form={form} />
        </div>
      </DndProvider>
    </div>
  );
}

function mapStateToProps(state: $TSFixMe) {
  const { requestedBusinessType } = state.programBuilder;

  return {
    requestedBusinessType,
  };
}

function deepEquals(
  question: ProgramBuilderQuestionInstance,
  comparisonQuestion: ProgramBuilderQuestionInstance,
) {
  if (!shallowEquals(question, comparisonQuestion)) {
    return false;
  }
  if (question.subQuestions) {
    if (!comparisonQuestion.subQuestions) {
      return false;
    } else {
      if (
        question.subQuestions.length !== comparisonQuestion.subQuestions.length
      ) {
        return false;
      }
      for (let i = 0; i < question.subQuestions.length; i++) {
        if (
          !deepEquals(
            question.subQuestions[i],
            comparisonQuestion.subQuestions[i],
          )
        ) {
          return false;
        }
      }
    }
  }
  return true;
}

function findConcerningChangedQuestion(
  form: FinalFormModifier,
  treeData: Array<$TSFixMe>,
): ProgramBuilderQuestionInstance | undefined {
  for (
    let i = 0;
    form.questionInstances && i < form.questionInstances.length;
    i++
  ) {
    let question = form.questionInstances[i];
    if (
      !question.ancillary.parentForms ||
      question.ancillary.parentForms.filter((my_form) => form.id !== my_form.id)
        .length < 1 ||
      question.options.editAll !== undefined
    ) {
      continue;
    }
    const matchingQuestion = treeData.find((ele: $TSFixMe) =>
      ele.id && question.id
        ? ele.id === question.id
        : ele.clientId && question.clientId
        ? ele.clientId === question.clientId
        : undefined,
    );
    if (matchingQuestion !== undefined) {
      matchingQuestion.subQuestions = [...matchingQuestion.children];
      if (!deepEquals(matchingQuestion, question)) {
        return matchingQuestion;
      }
    }
  }
  return undefined;
}

function mapDispatchToProps(dispatch: $TSFixMe) {
  function updateEditAll(
    form: FinalFormModifier,
    question: ProgramBuilderQuestionInstance,
    editAllFlag: boolean,
  ) {
    return dispatch(
      // @ts-expect-error
      formStore.actions.setEditAll({ form, question, editAllFlag }),
    );
  }
  function addPendingQuestionData(path: $TSFixMe, node: $TSFixMe) {
    // debugger;
    return dispatch(
      formModifierStore.actions.addPendingQuestion({ path, node }),
    );
  }

  function clearPendingQuestion() {
    return dispatch(formModifierStore.actions.clearPendingQuestion());
  }
  function updateForm(form: FinalFormModifier, treeData: $TSFixMe) {
    /*console.log('DragDropFormBuilder - entering updateForm with', { */
    /*   form, */
    /*   treeData, */
    /* }); */
    const recursivelyAddClientId = (list: Array<$TSFixMe>) =>
      list.map((ele: $TSFixMe) => {
        const newQuestionInstance = new ProgramBuilderQuestionInstance(ele);
        newQuestionInstance.children = recursivelyAddClientId(ele.children);
        return newQuestionInstance;
        /* return ele.children */
        /*   ? { */
        /*       ...ele, */
        /*       clientId: ele.clientId ? ele.clientId : createId(), */
        /*       children: recursivelyAddClientId(ele.children), */
        /*     } */
        /*   : { */
        /*       ...ele, */
        /*       clientId: ele.clientId ? ele.clientId : createId(), */
        /*     }; */
      });

    let newForm = form.copy();
    newForm.questionInstances = recursivelyAddClientId(treeData);

    /* let newForm = { ...form }; */
    /* newForm.questionInstances = recursivelyAddClientId(treeData); */

    /*console.log( */
    /*   'DragDropFormBuilder - updateForm - about to update with new form', */
    /*   newForm, */
    /* ); */

    return dispatch(formModifierStore.actions.updateTreeStructure(newForm));
  }

  return {
    updateEditAll,
    updateForm,
    addPendingQuestionData,
    clearPendingQuestion,
  };
}

export const DragDropConnect = connect(mapStateToProps, mapDispatchToProps);

export default DragDropConnect(DragDropFormBuilderWithStyles);
