import { graphql } from '../../gql';

export const ORGANIZATION_SPECIFIC_INSURANCE_RATES = graphql(`
  query organizationSpecificInsuranceRate($token: String!) {
    organizationSpecificInsuranceRates(token: $token) {
      id
      internalName
      displayName
      group
      symbol
      value
    }
  }
`);
