import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { FormIntegrityCheckerButton } from '../create/FormIntegrityCheckerButton';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  //@ts-expect-error
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    // @ts-expect-error
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

type TableToolbarPropsType = {
  filter: string;
  setFilter: (value: string) => any;
  isLoading: boolean;
};

/**
 *
 * @param {string} filter - String we want to use to filter the table
 * @param {function setFilter(filter:string)} setFilter - Sets the current filters value
 * @param {boolean} isLoading - Display a loading progress circle if any state change is in progress
 * @returns - Search bar
 */
export default function TableToolbar({
  filter,
  setFilter,
  isLoading,
}: TableToolbarPropsType) {
  return (
    <RootStyle>
      <SearchStyle
        aria-label='search forms'
        value={filter || ''}
        onChange={(event) => setFilter(event.target.value)}
        placeholder='Search forms...'
        startAdornment={
          <InputAdornment position='start'>
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: 'text.disabled' }}
            />
          </InputAdornment>
        }
      />

      {isLoading && <CircularProgress size='1.5rem' />}

      <Box sx={{ marginLeft: 'auto' }}>
        <FormIntegrityCheckerButton />
      </Box>
    </RootStyle>
  );
}
