import { graphql } from '../../gql';

export const EDIT_INSURANCE_RATE = graphql(`
  mutation editRate(
    $displayName: String!
    $internalName: String!
    $group: String!
    $rateId: ID!
    $symbol: String!
    $value: String!
    $token: String!
  ) {
    editRate(
      displayName: $displayName
      group: $group
      internalName: $internalName
      rateId: $rateId
      symbol: $symbol
      token: $token
      value: $value
    ) {
      rate {
        id
        internalName
        displayName
        group
        symbol
        value
      }
    }
  }
`);
