import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

interface SearchNotFoundProps {
  searchQuery: string;
  [k: string]: $TSFixMe;
}

export default function SearchNotFound({
  searchQuery = '',
  ...other
}: SearchNotFoundProps) {
  return (
    <Paper {...other}>
      {!searchQuery ? (
        <Typography gutterBottom align='center' variant='subtitle1'>
          No Applications to Display
        </Typography>
      ) : (
        <>
          <Typography gutterBottom align='center' variant='subtitle1'>
            Not found
          </Typography>
          <Typography variant='body2' align='center'>
            No results found for &nbsp;
            <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos
            or using complete words.
          </Typography>
        </>
      )}
    </Paper>
  );
}
