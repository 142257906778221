import { graphql } from '../gql';

export const questionsDatabase = graphql(`
  query questionsDatabase($query: String) {
    questionsDatabase(query: $query) {
      ...QuestionDatabaseFields
      parentForms {
        id
        businessLine {
          id
          displayName
        }
        policy {
          id
          displayName
        }
      }
      subQuestions {
        ...QuestionDatabaseFields
        subQuestions {
          ...QuestionDatabaseFields
          subQuestions {
            ...QuestionDatabaseFields
            subQuestions {
              ...QuestionDatabaseFields
              subQuestions {
                ...QuestionDatabaseFields
                subQuestions {
                  ...QuestionDatabaseFields
                  subQuestions {
                    ...QuestionDatabaseFields
                    subQuestions {
                      ...QuestionDatabaseFields
                      subQuestions {
                        ...QuestionDatabaseFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);
