import {
  TextField,
  Checkbox,
  YearPicker,
  YesNoToggle,
} from '@calefy-inc/informedMaterial';
// @ts-expect-error
import { FormState, Relevant, useFormState } from 'informed';

import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';

import { Component } from './classes';

// types
import { QuoteComponentProps, ProgramBuilderComponentProps } from './types';

// create programing builder com
function ProgramBuilderComponent({
  classes = {},
}: ProgramBuilderComponentProps) {
  const formState = useFormState();
  return (
    <>
      <TextField
        className={classes.textField}
        classes={classes}
        type='number'
        label='Years Before*'
        field='props_blob_yearsBefore'
        id='props_blob_yearsBefore'
        helperText='Enter range of previous years'
        required
        variant='standard'
      />
      <TextField
        className={classes.textField}
        classes={classes}
        type='number'
        label='Years After*'
        field='props_blob_yearsAfter'
        id='props_blob_yearsAfter'
        helperText='Enter range of years after'
        required
        variant='standard'
      />
      <Checkbox
        className={classes.checkbox}
        classes={classes}
        label='Include current year'
        field='props_blob_include'
        id='props_blob_include'
        helperText='Check to include the current year'
      />
      <YesNoToggle
        field='default_boolean'
        label='Would you like to set a default value?'
      />
      <Relevant
        when={(formState: FormState) => {
          return formState.values['default_boolean'] === 'yes';
        }}
      >
        <YearPicker
          field='defaultValue'
          label='Default Value'
          yearsBefore={formState.values['props_blob_yearsBefore'] || 0}
          yearsAfter={formState.values['props_blob_yearsAfter'] || 0}
          current={new Date().getFullYear()}
          include={formState.values['props_blob_include'] || false}
        />
      </Relevant>
    </>
  );
}

const quoteComponent = function ({
  questionInstance,
  classes = {},
  ...props
}: QuoteComponentProps) {
  const {
    yearsBefore = 0,
    yearsAfter = 0,
    include,
  } = questionInstance.propsBlob;
  const current = new Date().getFullYear();

  return (
    <>
      <YearPicker
        label={questionInstance.label}
        field={questionInstance.generateFieldName()}
        id={questionInstance.id}
        helperText={questionInstance.helpText}
        required={questionInstance.required}
        className={classes.yearPicker}
        classes={classes}
        yearsBefore={yearsBefore}
        yearsAfter={yearsAfter}
        current={current}
        include={include}
        {...props}
      />
    </>
  );
};

const IconComponent = () => {
  return <TimelineRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'yearPicker',
    typeLabel: 'Year Picker',
    quoteComponent,
    dataType: 'number',
    programBuilderComponent: ProgramBuilderComponent,
    icon: IconComponent,
  });
}

export { quoteComponent, ProgramBuilderComponent };
