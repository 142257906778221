import { Select } from '@calefy-inc/informedMaterial';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../store/QuoteWizardState';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language } from '../../../Typescript/classes';

interface LanguageSelectionProps {
  availableLanguages: Array<Language>;
}
/**
 * Select the language for the QuoteWizard - value will be read and stored in the store
 */
export function LanguageSelection({
  availableLanguages,
}: LanguageSelectionProps) {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    // @ts-expect-error
    (state) => state.quoteWizard.selectedLanguage,
  );

  if (availableLanguages.length === 0) {
    return null;
  }
  if (availableLanguages.length === 1) {
    if (!selectedLanguage.equals(availableLanguages[0])) {
      dispatch(actions.setLanguage(availableLanguages[0]));
    }
    return null;
  }
  return (
    <>
      <Select
        field='languageSelection'
        options={availableLanguages.map((language) => ({
          value: language.shortName,
          label: language.fullName,
        }))}
        label='Please select your preferred language'
        value={selectedLanguage.shortName}
        onChange={(e: $TSFixMe) => {
          const matchedLanguage = availableLanguages.find(
            (language) => language.shortName === e.value,
          );
          if (matchedLanguage) {
            dispatch(actions.setLanguage(matchedLanguage));
          }
        }}
        fullWidth
      />
    </>
  );
}
