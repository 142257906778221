import { useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useGroupBy,
  useExpanded,
  useFlexLayout,
} from 'react-table';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
// mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// components
import { TableToolbar } from '../list';
import TablePaginationActions from './TablePaginationActions';
import Scrollbar from '../../../Scrollbar';

// TODO: specify types
type EnhancedTableProps = {
  columns: $TSFixMe;
  data: $TSFixMe;
  getRowProps: $TSFixMe;
};

/**
 * A material ui table with additional features provided by react-table
 * @param {object[]} columns - List of columns and their specific settings
 * @param {object[]} data - List of forms for the table
 * @param {function} getRowProps - Passing specific row props or actions into table
 * @returns Table with columns and data rendered
 */
function EnhancedTable({ columns, data, getRowProps }: EnhancedTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        sortBy: [
          {
            id: 'business',
            desc: false,
          },
        ],
        groupBy: ['business'],
      },
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    // Our custom plugin to add the expander column
    (hooks) => {
      hooks.useControlledState.push(useControlledState);
      hooks.visibleColumns.push((columns, { instance }) => {
        if (!instance.state.groupBy.length) {
          return columns;
        }

        return [
          {
            id: 'expander', // Make sure it has an ID
            // Build our expander column
            width: 300,
            Header: ({ allColumns, state: { groupBy } }) => {
              return groupBy.map((columnId) => {
                const column = allColumns.find((d) => d.id === columnId);
                return (
                  <Chip
                    color={'primary'}
                    label={
                      <>
                        {column ? column.render('Header') : ''}
                        {column?.isSorted ? (
                          <TableSortLabel
                            active={column.isSorted}
                            // react-table has a unsorted state which is not treated here
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                            sx={{
                              '& .MuiTableSortLabel-icon': {
                                color: '#fff !important',
                              },
                            }}
                          />
                        ) : null}
                      </>
                    }
                    onDelete={column?.getGroupByToggleProps().onClick}
                    onClick={column?.getSortByToggleProps().onClick}
                    sx={{ mr: 1 }}
                  />
                );
              });
            },
            Cell: ({ row }) => {
              if (row.canExpand) {
                const groupedCell = row.allCells.find((d) => d.isGrouped);

                return (
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0.5}
                    {...row.getToggleRowExpandedProps({
                      style: {
                        // We can even use the row.depth property
                        // and paddingLeft to indicate the depth
                        // of the row
                        paddingLeft: `${row.depth * 2}rem`,
                      },
                    })}
                  >
                    <IconButton>
                      {row.isExpanded ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </IconButton>
                    {groupedCell ? groupedCell.render('Cell') : null}
                    <Typography color='textSecondary' variant='body2'>
                      ({row.subRows.length})
                    </Typography>
                  </Stack>
                );
              }

              return null;
            },
          },
          ...columns,
        ];
      });
    },
  );

  const handleChangePage = (newPage: number) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Card>
      <Scrollbar sx={{ minWidth: 800, minHeight: 400 }}>
        <TableContainer>
          <TableToolbar
            filter={state.globalFilter}
            setFilter={setGlobalFilter}
            // Todo: set up when being used with a loading state
            isLoading={false}
          />

          <Table size='small' {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      <Box component='span' {...column.getGroupByToggleProps()}>
                        {column.render('Header')}
                      </Box>

                      {column.id !== 'expander' ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has an unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          {...column.getSortByToggleProps()}
                        />
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                const originalRowData = row.isGrouped
                  ? row.values
                  : row.original;

                return (
                  <TableRow
                    hover
                    {...row.getRowProps(getRowProps(originalRowData))}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.isAggregated
                            ? // If the cell is aggregated, use the Aggregated
                              // renderer for cell
                              cell.render('Aggregated')
                            : cell.isPlaceholder
                            ? null // For cells with repeated values, render null
                            : // Otherwise, just render the regular cell
                              cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>

            <TableFooter>
              <TableRow>
                {/* @ts-expect-error */}
                <TablePagination
                  rowsPerPageOptions={[
                    25,
                    50,
                    { label: 'All', value: data.length },
                  ]}
                  colSpan={4}
                  count={pageCount}
                  rowsPerPage={state.pageSize}
                  page={state.pageIndex}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  labelDisplayedRows={({ to, count, page }) => {
                    return `${page + 1} of ${
                      count !== -1 ? count : `more than ${to}`
                    }`;
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

export default EnhancedTable;

function useControlledState(state: $TSFixMe) {
  return useMemo(() => {
    if (state.groupBy.length) {
      return {
        ...state,
        hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter(
          (d, i, all) => all.indexOf(d) === i,
        ),
      };
    }
    return state;
  }, [state]);
}
