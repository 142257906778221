import { useState, useEffect } from 'react';
import { Box, Link, List, ListItem, ListItemText } from '@mui/material';
import { generateBlobUrlFromBase64String } from '@calefy-inc/utility';
import {
  convertIsoTimeToReadable,
  dayNumberToString,
} from '../../../QuoteWizard/QuoteReview/utility';
import { CarouselThumbnail } from '../../../ManagementPanel/components/carousel';
import { $TSFixMe } from '@calefy-inc/utilityTypes';

interface DisplayGoogleDetailsProps {
  details: $TSFixMe;
}
const DisplayGoogleDetails = ({ details }: DisplayGoogleDetailsProps) => {
  const { streetAddress, city, province, postalCode } = details.general;
  const addressString = [streetAddress, city, province, postalCode]
    .filter((elem) => elem && elem !== 'N/A')
    .join(' ');

  //generate the link to Google Maps
  const googleMapsUrl = encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${addressString}`,
    //+ (placeId ? `&query_place_id=${placeId}` : ''),
  );

  let ratingString;
  if (details.google.rating) {
    ratingString =
      `${details.google.rating} / 5` +
      (details.google.numberOfRatings
        ? ` (${details.google.numberOfRatings} ratings)`
        : '');
  }

  return (
    <List dense>
      <ListItem sx={{ justifyContent: 'center' }} disableGutters>
        <Link
          variant='body2'
          href={googleMapsUrl}
          target='_blank'
          rel='noopener noreferrer'
        >
          View on Google Maps
        </Link>
      </ListItem>
      {
        // only display the rating and opening hours on lastname
        /secure\.lastnameinsurance\.ca/i.test(window.location.host) ? (
          <>
            <ListItem alignItems='flex-start' disableGutters>
              {ratingString ? (
                <ListItemText primary={`Rating: ${ratingString}`} />
              ) : null}
            </ListItem>

            {details.general.openingHours &&
            details.general.openingHours.length > 0 ? (
              <DisplayOpeningHours hours={details.general.openingHours} />
            ) : null}
          </>
        ) : null
      }
    </List>
  );
};

interface DisplayOpeningHoursProps {
  hours: $TSFixMe;
}
export const DisplayOpeningHours = ({ hours }: DisplayOpeningHoursProps) => {
  const convertOpeningHourToDisplayJsx = (
    dayNumber: $TSFixMe,
    hour: $TSFixMe,
  ) => {
    if (!hour) {
      return (
        <ListItem key={`${dayNumber}-${hour}-closed`} disableGutters>
          <ListItemText secondary={`${dayNumberToString(dayNumber)}: Closed`} />
        </ListItem>
      );
    } else if (!hour.close && hour.open === '00:00:00') {
      return (
        <ListItem key={`${dayNumber}-${hour}-allDay`} disableGutters>
          <ListItemText
            secondary={`${dayNumberToString(dayNumber)}: All Day`}
          />
        </ListItem>
      );
    }
    return (
      <ListItem key={`${dayNumber}-${hour}`} disableGutters>
        <ListItemText
          secondary={`${dayNumberToString(hour.day)}: ${
            hour.open ? convertIsoTimeToReadable(hour.open) : null
          } - ${hour.close ? convertIsoTimeToReadable(hour.close) : null}`}
        />
      </ListItem>
    );
  };
  if (
    hours.length === 1 &&
    hours[0].day === 7 &&
    !hours[0].close &&
    hours[0].open === '00:00:00'
  ) {
    return (
      <ListItem key='always open' alignItems='flex-start' disableGutters>
        <ListItemText secondary='Hours: Always Open' />
      </ListItem>
    );
  }
  return (
    <ListItem key='Opening Hours' alignItems='flex-start' disableGutters>
      <List dense>
        <ListItemText primary='Hours:' />
        {[1, 2, 3, 4, 5, 6, 7].map((dayNumber) => {
          const matchingOpenTime = hours.find(
            (elem: $TSFixMe) => elem.day === dayNumber,
          );
          return convertOpeningHourToDisplayJsx(dayNumber, matchingOpenTime);
        })}
      </List>
    </ListItem>
  );
};

interface DisplayImagesProps {
  details: $TSFixMe;
}
function DisplayImages({ details }: DisplayImagesProps) {
  const [imagesWithURLs, setImagesWithURLs] = useState<Array<$TSFixMe>>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<
    number | undefined
  >();
  const [carouselImages, setCarouselImages] = useState<Array<$TSFixMe>>([]);

  // to generate the image URLs
  useEffect(() => {
    (async () => {
      const images = details.images || [];
      const amendedImages = [];
      for (let image of images) {
        const imageURL = await generateBlobUrlFromBase64String(image.data);
        amendedImages.push({
          ...image,
          url: imageURL,
        });
      }
      setImagesWithURLs(amendedImages);
    })();
  }, [details]);

  // to sort the default image to display
  useEffect(() => {
    if (!currentImageIndex && imagesWithURLs.length > 0) {
      setCurrentImageIndex(0);
    }
  }, [imagesWithURLs, currentImageIndex]);

  useEffect(() => {
    if (imagesWithURLs) {
      setCarouselImages(
        imagesWithURLs.map((image, index) => {
          let description = '';

          if (image.shortDescription || image.description) {
            description = image.shortDescription
              ? image.shortDescription
              : image.description;
          }

          //console.log(image.url);

          return {
            id: index,
            title: '',
            image: image.url,
            description: description,
          };
        }),
      );
    }
  }, [imagesWithURLs]);

  return imagesWithURLs && imagesWithURLs.length > 0 ? (
    <div className='carousel-container' data-testid='carousel-container'>
      <CarouselThumbnail images={carouselImages} />
    </div>
  ) : null;
}

interface DisplayGeneralInformationProps {
  details: $TSFixMe;
}
export const DisplayGeneralInformation = ({
  details,
}: DisplayGeneralInformationProps) => {
  return (
    <Box sx={{ margin: 'auto', width: '34vw' }}>
      <DisplayImages details={details} />
      <DisplayGoogleDetails details={details} />
    </Box>
  );
};
