import { useCallback } from 'react';
import {
  useDispatch,
  // useSelector
} from 'react-redux';
import { UnifiedCompletedForm } from '../Typescript/classes';
import { confirmAnswersForForms } from '../store/QuoteWizardState';
import { BusinessType } from '../Typescript';

/**
 * Return a function to send off an application with contact information completed
 * (obviously not completed yet...)
 */
export const useSendContactInfoApplication = (
  // @ts-expect-error
  options?: Partial<Parameters<typeof confirmAnswersForForms>[0]>,
) => {
  const dispatch = useDispatch();

  const sendContactInfoApplication = useCallback(
    ({
      formsWithAnswers,
      additionalInformation,
      businessType,
    }: {
      formsWithAnswers: Array<UnifiedCompletedForm>;
      additionalInformation?: string;
      businessType?: BusinessType;
    }) => {
      dispatch(
        confirmAnswersForForms({
          formsWithAnswers,
          additionalInformation,
          status: 'CONTACT_INFO_ONLY',
          currentQuoteUUID: null,
          selectedPolicies: [],
          signature: null,
          renewalInProgress: false,
          businessType,
          storeExistingQuoteUuidOnComplete: false,
          // ...(options ? options : {}),
        }),
      );
    },
    [dispatch],
  );

  return sendContactInfoApplication;
};
