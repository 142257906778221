import { SetStateAction, Dispatch } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: { theme: $TSFixMe }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

type BusinessListToolbarProps = {
  filterName: string;
  onFilterName: (e: $TSFixMe) => void;
  isLoading: $TSFixMe;
  fuzzy: boolean;
  setFuzzy: Dispatch<SetStateAction<boolean>>;
};
export function QuestionsTableSearch({
  filterName,
  onFilterName,
  isLoading,
  fuzzy,
  setFuzzy,
}: BusinessListToolbarProps) {
  return (
    <RootStyle>
      {/* @ts-expect-error */}
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder='Search questions'
        aria-label='search questions'
        id='search-questions'
        startAdornment={
          <InputAdornment position='start'>
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: 'text.disabled' }}
            />
          </InputAdornment>
        }
      />

      {isLoading && <CircularProgress size='1.5rem' />}
      <FormControlLabel
        control={
          <Checkbox
            checked={fuzzy}
            onChange={(e) => setFuzzy(e.target.checked)}
          />
        }
        label='Use Fuzzy Search'
      />
    </RootStyle>
  );
}
