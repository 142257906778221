import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../hooks';
import { useMutation } from '@apollo/client';
import { DELETE_FORM } from '../../../queries';
import { useDispatch } from 'react-redux';
import { resetAnswers } from '../../../store/FormStore';
import { useSnackbar } from 'notistack';
import { useNavigate } from '@reach/router';

// types and classes
import { ProgramBuilderForm } from '../classes';
import { ApolloError } from '@apollo/client';

interface DeleteFormButtonProps {
  form?: ProgramBuilderForm;
}
export const DeleteFormButton = ({ form }: DeleteFormButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useAuth();
  const isAdmin = useAllowByPermission('is:admin');
  const [waitingToDelete, setWaitingToDelete] = useState<boolean>(false);
  const [okToDelete, setOkToDelete] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [deleteForm, { loading, error, data }] = useMutation(DELETE_FORM, {
    // @ts-expect-error
    update(cache, { data: { deleteFinalForm } }) {
      const cacheId = `FinalFormType:${deleteFinalForm.formId}`;
      cache.evict({ id: cacheId });
    },
  });

  // delete the form from the backend when requested
  useEffect(() => {
    if (form?.id && okToDelete) {
      //console.log('About to delete form from backend');
      deleteForm({
        variables: {
          formId: form.id,
          token,
        },
      });
      setWaitingToDelete(false);
      setOkToDelete(false);
    }
  }, [waitingToDelete, okToDelete, form]);

  // delete the form from the store when it is deleted from the backend
  useEffect(() => {
    if (data?.deleteFinalForm?.ok) {
      //console.log('About to reset answers');
      dispatch(resetAnswers());
      enqueueSnackbar('Successfully deleted form!', { variant: 'success' });
      setTimeout(() => {
        navigate('/insurtech/manager/forms');
      }, 3_000);
    }
  }, [data, dispatch, resetAnswers]);

  useEffect(() => {
    if (error) {
      setErrorDialogOpen(true);
      enqueueSnackbar('Error deleting form', { variant: 'error' });
      setWaitingToDelete(false);
      setOkToDelete(false);
    }
  }, [error]);

  return (
    <>
      <Tooltip
        title={
          !isAdmin
            ? 'Insufficient permissions to delete forms'
            : !form || !form.id
            ? 'Form does not yet exist'
            : 'Delete the form'
        }
      >
        <span>
          <LoadingButton
            variant='contained'
            color='error'
            onClick={() => setWaitingToDelete(true)}
            disabled={!isAdmin || !form || !form?.id}
            loading={loading}
            endIcon={<DeleteIcon />}
          >
            Delete Form
          </LoadingButton>
        </span>
      </Tooltip>
      <DeleteFormDialog
        open={waitingToDelete && !okToDelete}
        formName={form ? form.getDisplayName() : 'type to delete'}
        onConfirmation={() => {
          setOkToDelete(true);
        }}
        onCancel={() => {
          setWaitingToDelete(false);
        }}
      />
      <DeleteFormErrorDialog
        error={error}
        open={errorDialogOpen}
        setOpen={setErrorDialogOpen}
      />
    </>
  );
};

interface DeleteFormDialogProps {
  open: boolean;
  onConfirmation: () => void;
  onCancel: () => void;
  formName: string;
}
function DeleteFormDialog({
  open,
  onConfirmation,
  onCancel,
  formName,
}: DeleteFormDialogProps) {
  const [enteredValue, setEnteredValue] = useState<string>('');
  return (
    <Dialog open={open} data-testid='delete-form-modal'>
      <DialogTitle>Are You Sure?</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          To be sure that this is really the form that you want to delete,
          please type the name of the form:
        </Typography>
        <Typography gutterBottom variant='subtitle1'>
          {formName}
        </Typography>
        <TextField
          label='Form to Delete'
          id='form-to-delete'
          fullWidth
          value={enteredValue}
          onChange={(e) => {
            setEnteredValue(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button
          onClick={() => onConfirmation()}
          disabled={enteredValue !== formName}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface DeleteFormErrorDialogProps {
  error?: ApolloError;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
function DeleteFormErrorDialog({
  error,
  open,
  setOpen,
}: DeleteFormErrorDialogProps) {
  return (
    <Dialog open={open} data-testid='form-delete-error'>
      <DialogTitle>Error Deleting Form</DialogTitle>
      <DialogContent>
        <Typography>
          There was an error deleting the form. Please contact{' '}
          <a href='mailto:support@calefy.ca?subject=Error when attempting to delete form'>
            support@calefy.ca
          </a>{' '}
          with details about which form you were trying to delete and any other
          information you can think of.
        </Typography>
        {error ? (
          <>
            <Typography>The returned error was</Typography>
            <pre>{error.message}</pre>
          </>
        ) : null}
        <Typography>Our apologies for the inconvenience.</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => setOpen(false)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
