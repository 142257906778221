import { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { withStyles } from '@mui/styles';
import { Form } from '@calefy-inc/informedMaterial';
import { Icon } from '@iconify/react';
import copyFill from '@iconify/icons-eva/copy-fill';
import { styled } from '@mui/system';
import FormStore from '../../../../store/FormStore';
// @ts-expect-error
import { generateRelevanceComparison, formsAreTheSame } from './index';
import { useTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';

// for the query which grabs all of the final forms with the same policy
import { useLazyQuery } from '@apollo/client';
import {
  ORGANIZATION_FINAL_FORMS_SKELETONS,
  FINAL_FORM,
} from '../../../../queries';

// types and classes
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { Policy, BusinessType } from '../../../../Typescript';
import { ProgramBuilderForm } from '../../../FormManager/classes';
import { StoreState, Dispatch } from '../../../../store';

// for notifying us about any errors while not bothering the users with it
import Bugsnag from '@bugsnag/js';

// components
import { FormPreviewButton } from '../FormPreview';

const ModalStyle = styled('div')(({ theme }) => ({
  maxHeight: '400px',
  maxWidth: '700px',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px',
  padding: theme.spacing(3),
  position: 'relative',
  overflow: 'auto',
}));

type CurrentlyEditingForm = Pick<
  ProgramBuilderForm,
  'id' | 'businessLine' | 'policy' | 'new'
>;

// the bare minimum required to display a form - idea is to get the actual full form if / when the user selects this one to copy
type ProgramBuilderFormSkeleton = Pick<
  ProgramBuilderForm,
  'id' | 'businessLine' | 'policy' | 'organization'
>;

type UnthemedCopyFormDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  existingForms: StoreState['formStore']['forms'];
  additionalRelevantFormSkeletons: Array<ProgramBuilderFormSkeleton>;
  selectedBusinessType: StoreState['programBuilder']['requestedBusinessType'];
  currentlyEditingForm: CurrentlyEditingForm;
  updateExistingForm: (form: ProgramBuilderForm) => void;
  classes: $TSFixMe;
};
/**
 * Dialog for the user to select a form to copy over the existing one
 * @param {Form[]} existingForms - The forms already loaded into the FormStore
 */
const UnthemedCopyFormDialog = ({
  open,
  setOpen,
  existingForms,
  additionalRelevantFormSkeletons,
  // @ts-expect-error
  selectedBusinessType,
  currentlyEditingForm,
  updateExistingForm,
  // @ts-expect-error
  classes = {},
}: UnthemedCopyFormDialogProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formToCopy, setFormToCopy] = useState<ProgramBuilderForm | null>(null);
  const [requestedFormId, setRequestedFormId] = useState<
    ProgramBuilderForm['id'] | null
  >(null); // the id of the form we'd like to copy
  const [allRelevantForms, setAllRelevantForms] = useState<
    Array<ProgramBuilderForm | ProgramBuilderFormSkeleton>
  >([]);
  const [getFinalForm, { error, data }] = useLazyQuery(FINAL_FORM);
  const [policyId, setPolicyId] = useState<Policy['id'] | undefined>();
  const [businessId, setBusinessId] = useState<
    BusinessType['id'] | undefined
  >();
  const [okToNav, setOkToNav] = useState<boolean>(false);
  const findForm = useCallback(
    (state: StoreState) =>
      state.formStore.forms.find((form) =>
        form.businessLine.id === businessId && policyId === '0'
          ? form.policy === null
          : form.policy && form.policy.id === policyId,
      ),
    [policyId, businessId],
  );

  const desiredForm = useSelector(findForm);

  // sort by relevance
  // 1) By policy type
  // 2) Then by business type
  // 3) Within each of those, sort alphabetically
  // 4) (But general information at the beginning)
  const relevanceComparisonFunction = useCallback(
    () => generateRelevanceComparison(currentlyEditingForm),
    [currentlyEditingForm],
  );

  useEffect(() => {
    setAllRelevantForms(
      /*
      [
        ...existingForms,
        ...additionalRelevantFormSkeletons.filter(
          (formSkeleton) =>
            !existingForms.some((existingForm) =>
              formsAreTheSame(formSkeleton, existingForm),
            ),
        ),
      ]
       */
      additionalRelevantFormSkeletons
        /* .filter((form) => !formsAreTheSame(form, currentlyEditingForm))  I don't think we need to worry about this - if the form is exactly the same we wouldn't be able to copy over it */
        .filter((form) => form.id)
        // @ts-expect-error
        .sort(relevanceComparisonFunction),
    );
  }, [
    existingForms,
    additionalRelevantFormSkeletons,
    currentlyEditingForm,
    relevanceComparisonFunction,
  ]);

  // if the requested form has been loaded, it is OK to navigate
  useEffect(() => {
    if (desiredForm) {
      setOkToNav(true);
    } else {
      setOkToNav(false);
    }
  }, [desiredForm]);

  // If it's OK to navigate, then do it
  useEffect(() => {
    if (okToNav) {
      navigate(`/insurtech/manager/forms/edit/${businessId}/${policyId}`);
      setOkToNav(false);
    }
  }, [okToNav, setOkToNav, navigate]);

  useEffect(() => {
    if (requestedFormId === null || requestedFormId === undefined) {
      /*console.log('No requested form id - setFormToCopy to null'); */
      setFormToCopy(null);
      return;
    }
    // first look in the already-loaded forms
    const foundInStore = existingForms.filter(
      (form) => form.id === requestedFormId,
    );
    if (foundInStore.length === 0) {
      // get it from the backend
      setFormToCopy(null);
      getFinalForm({ variables: { id: requestedFormId } });
    } else if (foundInStore.length === 1) {
      setFormToCopy(foundInStore[0]);
    } else if (foundInStore.length > 1) {
      console.error(
        `Found multiple forms in the store with the same ID:`,
        foundInStore,
      );
      setFormToCopy(foundInStore[0]);
    }
  }, [requestedFormId, existingForms, getFinalForm]);

  // load the requested form into the store
  useEffect(() => {
    if (data?.finalForm) {
      const form = ProgramBuilderForm.generateFromBackendResponse(
        data.finalForm,
      );
      // dispatch(FormStore.actions.loadExistingForms({ loadedForms: [form] }));
      setFormToCopy(form);
    }
  }, [data, updateExistingForm, dispatch]);

  // handle any errors from getting the form
  useEffect(() => {
    if (error) {
      console.error(`Error getting form from the backend`, error);
      setRequestedFormId(null);
    }
  }, [error]);

  return (
    <Modal
      open={open}
      aria-labelledby='form-dialog-title'
      onClose={() => {
        setOpen(false);
        setFormToCopy(null);
      }}
      data-testid='copyExistingFormModal'
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <ModalStyle>
        <Typography
          variant='h6'
          id='form-dialog-title'
          color='textSecondary'
          align='center'
          sx={{
            mb: 3,
          }}
        >
          Copy Existing Form
        </Typography>

        <Grid
          container
          direction='row'
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
          sx={{ mb: 3 }}
        >
          <Grid item xs={12} sm={10}>
            <Form>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select a form to copy'
                    variant='outlined'
                  />
                )}
                options={allRelevantForms.map((form) => ({
                  value: form.id,
                  organization: form.organization.name,
                  label:
                    form.businessLine.displayName +
                    ' - ' +
                    (form.policy
                      ? form.policy.displayName
                      : 'General Information'),
                }))}
                onChange={(_event, value) => {
                  if (value) {
                    setRequestedFormId(value.value);
                  } else {
                    setRequestedFormId(null);
                  }
                }}
                sx={{
                  '& input': {
                    fontSize: '0.9rem',
                  },
                  '& .MuiAutocomplete-input': {
                    width: '100% !important',
                  },
                }}
                ListboxProps={{
                  // @ts-expect-error
                  sx: {
                    '& .MuiAutocomplete-option': {
                      fontSize: '0.9rem',
                      py: 2,
                    },
                  },
                }}
                renderOption={(props, option, _state) => {
                  return (
                    <li
                      {...props}
                      style={{
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(2),
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      {option.label}
                      <span
                        style={{
                          fontSize: '0.8rem',
                          color: grey[700],
                        }}
                      >
                        {option.organization}
                      </span>
                    </li>
                  );
                }}
              />
            </Form>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormPreviewButton
              formTitle={
                formToCopy
                  ? formToCopy.policy
                    ? formToCopy.policy.displayName
                    : 'General Information'
                  : ''
              }
              questionInstances={
                formToCopy && formToCopy.getAvailableLanguages().length > 0
                  ? formToCopy.questionInstances.map((q) =>
                      q.toQuoteWizardQuestionInstance(
                        formToCopy.getAvailableLanguages()[0],
                      ),
                    )
                  : []
              }
            />
          </Grid>
        </Grid>

        <Stack direction='row' spacing={1} justifyContent='flex-end'>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={!formToCopy}
            onClick={() => {
              if (!formToCopy) {
                return;
              }
              const copiedForm = formToCopy.copyWithAmendments({
                id: currentlyEditingForm['id'],
                clientId: currentlyEditingForm['id'],
                businessType: currentlyEditingForm['businessLine'],
                businessLine: currentlyEditingForm['businessLine'],
                policy: currentlyEditingForm['policy'],
                qualifyingQuestion: formToCopy.qualifyingQuestion
                  ? formToCopy.qualifyingQuestion.copyAsNew()
                  : null,
                name: '',
                removed: false,
                required: false,
                new: true,
              });

              dispatch(FormStore.actions.addForm({ form: copiedForm }));

              let newPolicyId = copiedForm.policy ? copiedForm.policy.id : '0';
              let newBusinessId = copiedForm.businessLine.id;

              setPolicyId(newPolicyId);
              setBusinessId(newBusinessId);
            }}
          >
            Copy
          </Button>
        </Stack>
      </ModalStyle>
    </Modal>
  );
};

/* Connect to Store */
const mapStateToDialogProps = (state: StoreState) => {
  return {
    existingForms: state.formStore.forms,
    selectedBusinessType: state.programBuilder.requestedBusinessType,
  };
};
const mapDispatchToDialogProps = (dispatch: Dispatch) => {
  const { updateExistingForm } = FormStore.actions;
  return {
    updateExistingForm: (form: ProgramBuilderForm) => {
      dispatch(updateExistingForm({ form }));
    },
  };
};
const ConnectedCopyFormDialog = connect(
  mapStateToDialogProps,
  mapDispatchToDialogProps,
)((props) => <UnthemedCopyFormDialog {...props} />);

/* Theming */
// @ts-expect-error
const CopyFormDialog = withStyles((theme) => ({}))((props: $TSFixMe) => (
  // @ts-expect-error
  <ConnectedCopyFormDialog {...props} />
));

interface BaseCopyExistingFormButtonProps {
  currentlyEditingForm: CurrentlyEditingForm;
  disabled: boolean;
  classes: GenericObject;
}
/* The button proper */
// currentlyEditingForm comes from the DragDropFormBuilder component - it's the one that we are currently working on
export const BaseCopyExistingFormButton = ({
  currentlyEditingForm,
  disabled,
  classes = {},
}: BaseCopyExistingFormButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [additionalRelevantFormSkeletons, setAdditionalRelevantFormSkeletons] =
    useState<Array<ProgramBuilderFormSkeleton>>([]);
  const [getRelevantForms, getRelevantFormsStatus] = useLazyQuery(
    ORGANIZATION_FINAL_FORMS_SKELETONS,
    {
      onCompleted: (data) => {
        handleRelevantFormData(data);
      },
    },
  );

  const handleRelevantFormData = (data: $TSFixMe) => {
    const relevantForms = data.organizationFinalForms;
    setAdditionalRelevantFormSkeletons(
      relevantForms.map(
        (form: ProgramBuilderFormSkeleton & { __typename: string }) => {
          const { __typename, ...actualForm } = form;
          return actualForm;
        },
      ),
    );
  };
  // get the relevant forms - namely, the ones from the same organization or the default ones
  useEffect(() => {
    getRelevantForms({
      variables: {
        includeDefault: true,
      },
    });
  }, [getRelevantForms]);

  if (getRelevantFormsStatus.error) {
    console.error(`error`, getRelevantFormsStatus.error);
    Bugsnag.notify(getRelevantFormsStatus.error);
  }

  return (
    <>
      <LoadingButton
        variant='contained'
        onClick={() => {
          setDialogOpen(true);
        }}
        disabled={additionalRelevantFormSkeletons.length === 0 || disabled}
        endIcon={
          getRelevantFormsStatus.error ? (
            <Tooltip
              title='Error loading additional relevant forms'
              aria-label='Error loading additional relevant forms'
            >
              <ErrorIcon className={classes.errorIcon} />
            </Tooltip>
          ) : (
            <Icon icon={copyFill} />
          )
        }
        loading={getRelevantFormsStatus.loading}
        loadingPosition='end'
      >
        Copy Form
      </LoadingButton>
      <CopyFormDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        currentlyEditingForm={currentlyEditingForm}
        additionalRelevantFormSkeletons={additionalRelevantFormSkeletons}
      />
    </>
  );
};

/* Theming */

export const CopyExistingFormButton = withStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.main,
  },
  loadingProgress: {
    marginLeft: theme.spacing(1),
  },
}))((props: $TSFixMe) => <BaseCopyExistingFormButton {...props} />);
