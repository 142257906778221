import { styled } from '@mui/material';
import { Link, RouteComponentProps, Router } from '@reach/router';
import { Analytics } from './Analytics';
import { OrganizationEditor } from './OrganizationEditor';
// import { Authenticated } from '@calefy-inc/authentication';
import { Authenticated } from '../Authentication/Authenticated';
import { InsufficientPermissionsErrorPage } from '../common/ErrorHandling';
import { AdminPanelHomepage } from './AdminPanelHomepage';
import { BusinessSelectionInputs } from './BusinessSelectionInputs';
import { AuthorizationTokens } from './AuthorizationTokens';
import { RateAuditTable } from './RateAuditTable';
import { VagoAdminActions } from './VaGoAdminActions';
import { GeographyReport } from './GeographyReport';

interface AdminPanelProps extends RouteComponentProps {}
const HOMEPAGE_PATH = '';
const ORGANIZATION_EDITOR_PATH = 'organizationeditor';
const ANALYTICS_PATH = 'analytics';
const BUSINESS_SELECTION_INPUTS_PATH = 'businessselectioninputs';
const AUTHORIZATION_TOKEN_PATH = 'tokens';
const RATES_AUDIT_PATH = 'ratesaudit;';
const VAGO_ADMIN_ACTIONS_PATH = 'vago';
const GEOGRAPHY_REPORT_PATH = 'geographyreport';
const navLinks: Array<{ path: string; name: string }> = [
  { path: HOMEPAGE_PATH, name: 'Home' },
  { path: ORGANIZATION_EDITOR_PATH, name: 'Organization Editor' },
  { path: ANALYTICS_PATH, name: 'Analytics' },
  { path: BUSINESS_SELECTION_INPUTS_PATH, name: 'Business Selection Inputs' },
  { path: AUTHORIZATION_TOKEN_PATH, name: 'Authorization Tokens' },
  { path: RATES_AUDIT_PATH, name: 'Rate Changes' },
  { path: VAGO_ADMIN_ACTIONS_PATH, name: 'VaGo' },
  { path: GEOGRAPHY_REPORT_PATH, name: 'Geography Report' },
];
export const AdminPanel = (_props: AdminPanelProps) => {
  return (
    // @ts-expect-error
    <Authenticated
      whitelist={['is:admin']}
      invalidPermissionsFallback={<InsufficientPermissionsErrorPage />}
    >
      <AdminPanelWrapper>
        <nav>
          <ul>
            {navLinks.map((linkInfo) => {
              return (
                <li>
                  <Link to={linkInfo.path}>{linkInfo.name}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <Router>
          <AdminPanelHomepage default />
          <OrganizationEditor path={ORGANIZATION_EDITOR_PATH} />
          <Analytics path={ANALYTICS_PATH} />
          <BusinessSelectionInputs path={BUSINESS_SELECTION_INPUTS_PATH} />
          <AuthorizationTokens path={AUTHORIZATION_TOKEN_PATH} />
          <RateAuditTable path={RATES_AUDIT_PATH} />
          <VagoAdminActions path={VAGO_ADMIN_ACTIONS_PATH} />
          <GeographyReport path={GEOGRAPHY_REPORT_PATH} />
        </Router>
      </AdminPanelWrapper>
    </Authenticated>
  );
};

const AdminPanelWrapper = styled('div')(({ theme }) => {
  return {
    padding: theme.spacing(2),
  };
});
