export const generateProgress = (currentStep, totalSteps) => {
  let progress = 0;

  if (totalSteps === 5 && (currentStep === 1 || currentStep === 2)) {
    progress = 0.25 * ((currentStep - 1) / (totalSteps - 2));
  } else {
    progress = (currentStep - 1) / (totalSteps - 2);
  }

  if (currentStep === totalSteps - 1) {
    return 99;
  }

  if (progress === 0) {
    progress = 0.001;
  }
  return progress * 100;
};

export const capitalizeFirstLetter = (words) => {
  if (typeof words !== 'string') {
    return;
  }

  const capitalized = words.charAt(0).toUpperCase() + words.slice(1);

  return capitalized;
};
