import { PhoneField } from '@calefy-inc/informedMaterial';

import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';

//classes and types
import { Component } from './classes';
import { QuoteComponentProps } from './types';

const ProgramBuilderComponent = () => {
  return <PhoneField field='defaultValue' label='Default Value' />;
};

// Is there anything we'd want to customize for a textfield? Probably lots. For now, let's just export a non-customizable textfield
const quoteComponent = function ({
  questionInstance,
  classes,
  ...props
}: QuoteComponentProps) {
  return (
    <>
      <PhoneField
        label={questionInstance.label}
        field={questionInstance.generateFieldName()}
        id={questionInstance.id}
        helperText={questionInstance.helpText}
        required={questionInstance.required}
        className={classes && classes.phonefield ? classes.phonefield : ''}
        classes={classes}
        {...props}
      />
    </>
  );
};

const IconComponent = () => {
  return <LocalPhoneRoundedIcon fontSize='small' color='primary' />;
};

export default function createComponentRegistry() {
  return new Component({
    type: 'phonefield',
    typeLabel: 'Phone Input',
    quoteComponent,
    programBuilderComponent: ProgramBuilderComponent,
    dataType: 'string',
    icon: IconComponent,
  });
}

export { quoteComponent };
