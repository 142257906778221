import Bugsnag from '@bugsnag/browser';
import { errorify } from './errorify';

export const stringToNumber = (s: string) => {
  const stripped = s.replace(/[^0-9-.]/g, '');

  try {
    const asNumber = Number(stripped);
    if (Number.isNaN(asNumber) || !Number.isFinite(asNumber)) {
      return null;
    }
    return asNumber;
  } catch (e) {
    Bugsnag.notify(errorify(e));
    return null;
  }
};
