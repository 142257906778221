/**
 * Converts the displayName in the data object to snake case, by
 * replacing all special characters, replacing spaces with undescores
 * and converting it to lowercase.
 *
 * @param {Object} name - data object to be converted
 * @returns displayName converted to snake case
 */
export const converter = (name) => {
  let newInternalName = name.replace(/[^a-zA-Z ]/g, '');
  let cleanInternalName = newInternalName.replace(/\s+/g, '_');
  let lower = cleanInternalName.toLowerCase();
  return lower;
};
