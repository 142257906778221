/**
 * Update the value of a slider, given the desired value and current sum
 * @param desiredValue - the desired value
 * @param currentValue - The current value of the slider
 * @param sum - The current total sum
 */
export function valueOrMax(
  desiredValue: number,
  currentValue: number | undefined,
  sum: number | undefined,
): number {
  desiredValue = Number.isNaN(desiredValue) ? 0 : desiredValue;
  currentValue =
    Number.isNaN(currentValue) || currentValue === undefined ? 0 : currentValue;
  sum = Number.isNaN(sum) || sum === undefined ? 0 : sum;
  const MAX = 100;
  if (desiredValue + (sum - currentValue) <= MAX) {
    return desiredValue;
  } else {
    const newValue = MAX - sum + currentValue;
    return newValue;
  }
}

export function copyAttributesFromObjects(
  obj: { [k: string]: any },
  attributes: Array<string | number>,
): { [k: string]: any } {
  return attributes.reduce((acc, attribute) => {
    // @ts-expect-error
    acc[attribute] = obj[attribute];
    return acc;
  }, {});
}

/**
 * Takes an array of strings and returns a readable, correctly formatted readable list. E.g. ['a', 'b', 'c'] -> "a, b, and c"
 */
export function formatAsReadableList(strings: Array<string>) {
  switch (strings.length) {
    case 0:
      return '';
    case 1:
      return strings[0];
    case 2:
      return `${strings[0]} and ${strings[1]}`;
    default:
      return strings.reduce((acc: string, str, index, allStrings) => {
        if (index === 0) {
          return str;
        }
        if (index === allStrings.length - 1) {
          return acc + `, and ${str}`;
        }
        return acc + `, ${str}`;
      }, '');
  }
}
