import { PropsWithChildren } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const ALREADY_BOUND_STRING = 'This has already been bound';

interface AlreadyBoundTooltipProps
  extends PropsWithChildren<{ bound?: boolean }> {}
export const AlreadyBoundTooltip = ({
  children,
  bound,
}: AlreadyBoundTooltipProps) => {
  return (
    <Tooltip title={bound ? ALREADY_BOUND_STRING : ''}>
      <StyledSpan sx={bound ? { color: grey[500], fontStyle: 'italic' } : {}}>
        {children || null}
      </StyledSpan>
    </Tooltip>
  );
};

const StyledSpan = styled('span')({});
