import { SetStateAction, Dispatch } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

export type QuestionsPerPageOptions = 25 | 50 | 100;

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {},
  selectedButton: {
    // @ts-expect-error
    color: theme.palette.primary.main,
  },
  unSelectedButton: {
    // @ts-expect-error
    color: theme.palette.text.secondary,
  },
}));
interface SelectQuestionsPerPageProps {
  questionsPerPage: QuestionsPerPageOptions;
  setQuestionsPerPage: Dispatch<SetStateAction<QuestionsPerPageOptions>>;
}
export const SelectQuestionsPerPage = ({
  questionsPerPage,
  setQuestionsPerPage,
}: SelectQuestionsPerPageProps) => {
  const classes = useStyles();
  const options: Array<{ value: QuestionsPerPageOptions; label: string }> = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];
  return (
    <div
      className={classes.outerContainer}
      data-testid='selectQuestionsPerPage'
    >
      <div className={classes.buttonContainer}>
        {options.map((option) => (
          <Button
            key={option.value}
            onClick={() => setQuestionsPerPage(option.value)}
            className={
              option.value === questionsPerPage
                ? classes.selectedButton
                : classes.unSelectedButton
            }
          >
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  );
};
