import { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

// for the full-screen loading page as everything happens
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { POLICY, BUSINESS_TYPE, SOME_FINAL_FORMS } from '../../queries';

import {
  selectBusinessType,
  setPolicies as setPoliciesAction,
  submitAnswersForForm,
} from '../../store/QuoteWizardState';

import { cannedAnswers } from './cannedAnswers';
import {
  generateAnswersFromQuestionsList,
  isAllowedDemoLocation,
} from './utility';

// types and classes
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import { BusinessType, Policy } from '../../Typescript';
import { Language } from '../../Typescript/classes';
import {
  ProgramBuilderForm,
  ProgramBuilderQuestionInstance,
} from '../FormManager/classes';
import { QuoteWizardForm } from '../QuoteWizard/classes';
import { RouteComponentProps } from '@reach/router';
import { StoreState } from '../../store';

const desiredBusinessTypeInternalName = 'florist_flower_shops';
const propertyInternalName = 'property';
const glInternalName = 'general_liability';
const cyberInternalName = 'cyber';

const demoLanguage = Language.english;
export const Demo = (_props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const [allForms, setAllForms] = useState<Array<$TSFixMe>>([]); // all of the *raw* forms for the business - will be sorted out later
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [readyToResume, setReadyToResume] = useState<boolean>(false);

  // need to be dumped into the store
  const [business, setBusiness] = useState<BusinessType | null>(null);
  const [policies, setPolicies] = useState<Array<Policy>>([]);
  const [businessForm, setBusinessForm] = useState<QuoteWizardForm | null>(
    null,
  );
  const [policyForms, setPolicyForms] = useState<Array<QuoteWizardForm>>([]);

  // Check the store - if all of these exist then we are done loading
  const policySelected = useSelector(
    (state: StoreState) => state.quoteWizard.selectedPolicies.length > 0,
  );
  const businessFormPresent = useSelector(
    (state: StoreState) =>
      state.quoteWizard.businessForm &&
      Object.keys(state.quoteWizard.businessForm).length > 0,
  );
  const policyFormsPresent = useSelector(
    (state: StoreState) => state.quoteWizard.policyForms.length > 0,
  );
  const answersPresent = useSelector(
    (state: StoreState) =>
      Object.keys(state.quoteWizard.formAnswers).length >= 1,
  );

  const {
    loading: businessLoading,
    error: businessError,
    data: businessData,
  } = useQuery(BUSINESS_TYPE, {
    variables: {
      internalName: desiredBusinessTypeInternalName,
    },
  });

  const [getPropertyPolicy, propertyStatus] = useLazyQuery(POLICY, {
    variables: {
      internalName: propertyInternalName,
    },
  });
  const [getGlPolicy, glStatus] = useLazyQuery(POLICY, {
    variables: {
      internalName: glInternalName,
    },
  });
  const [getCyberPolicy, cyberStatus] = useLazyQuery(POLICY, {
    variables: {
      internalName: cyberInternalName,
    },
  });

  /* const policyStatuses = [propertyStatus, glStatus, cyberStatus]; */
  const [policyStatuses, setPolicyStatuses] = useState([
    propertyStatus,
    glStatus,
    cyberStatus,
  ]);

  // keep the statuses in sync
  useEffect(() => {
    setPolicyStatuses([propertyStatus, glStatus, cyberStatus]);
  }, [propertyStatus, glStatus, cyberStatus]);

  const [getBusinessForms, getBusinessFormsStatus] =
    useLazyQuery(SOME_FINAL_FORMS);

  // check if we are manually passing in a whitelabel (this needs to be passed on to the quote)
  const paramWhitelabel = window.location.search.includes('whitelabel=')
    ? `?whitelabel=${new URLSearchParams(window.location.search).get(
        'whitelabel',
      )}`
    : '';

  // get the policies
  useEffect(() => {
    getPropertyPolicy();
    getGlPolicy();
    getCyberPolicy();
  }, [getPropertyPolicy, getGlPolicy, getCyberPolicy]);

  // set the business type as soon as we know it
  useEffect(() => {
    if (businessData && businessData.businessType) {
      // @ts-expect-error
      setBusiness(businessData.businessType);
    }
  }, [businessData]);

  // once we have the business, set it in the store and get all of the forms
  useEffect(() => {
    if (business) {
      dispatch(selectBusinessType({ selectedBusinessType: business }));
      getBusinessForms({
        variables: {
          businessId: business.id,
        },
      });
    }
  }, [business, dispatch, getBusinessForms]);

  // once we have all of the forms for the business, set them in state
  useEffect(() => {
    const { data } = getBusinessFormsStatus;
    if (data && data.someFinalForms && data.someFinalForms.length >= 2) {
      setAllForms(data.someFinalForms);
    }
    if (
      getBusinessFormsStatus.called &&
      data &&
      (!data.someFinalForms || data.someFinalForms.length < 2)
    ) {
      setError(true);
    }
  }, [getBusinessFormsStatus]);

  // get the full policy information from the backend, ignoring any with errors
  useEffect(() => {
    const resolvedPolicies = policyStatuses.filter(
      (status) => status.called && (status.data || status.error),
    );
    const allPoliciesResolved =
      resolvedPolicies.length === policyStatuses.length;

    if (allPoliciesResolved) {
      const policiesWithData = policyStatuses
        .filter((status) => status?.data?.policy)
        .map((status) => status.data?.policy);

      // @ts-expect-error
      setPolicies(policiesWithData);
    }
  }, [policyStatuses]);

  // once we have all of the forms and the policies, separate out the general information and policy forms
  useEffect(() => {
    if (policies.length > 0 && allForms.length > 0) {
      let newBusinessForm: QuoteWizardForm;
      const newPolicyForms: Array<QuoteWizardForm> = [];
      allForms.forEach((form: $TSFixMe) => {
        if (!form.policy) {
          newBusinessForm =
            ProgramBuilderForm.generateFromBackendResponse(
              form,
            ).toQuoteWizardForm(demoLanguage);
        } else {
          newPolicyForms.push(
            ProgramBuilderForm.generateFromBackendResponse(
              form,
            ).toQuoteWizardForm(demoLanguage),
          );
        }
      });

      // @ts-expect-error
      if (!newBusinessForm || newPolicyForms.length === 0) {
        setError(true);
        return;
      }
      setBusinessForm(newBusinessForm);
      setPolicyForms(newPolicyForms);
    }
  }, [allForms, policies]);

  // Once we have everything that we need, throw all of the forms into the store (except Cyber, where we don't want the policy to be selected at first)
  useEffect(() => {
    if (policies.length > 0 && businessForm && policyForms.length > 0) {
      const policiesToExclude = ['cyber', 'owned_automobile']; // internal name for policies to *not* initially have selected
      const args = {
        selectedPolicies: allForms
          .filter((form) => form.policy)
          .filter(
            (form) => !policiesToExclude.includes(form.policy?.internalName),
          )
          .map((policyForm) => ({
            ...policyForm.policy,
            required: policyForm.required,
            qualifyingQuestion: policyForm.qualifyingQuestion
              ? ProgramBuilderQuestionInstance.generateFromBackendResponse(
                  policyForm.qualifyingQuestion,
                )
              : null,
          })),
        businessForm: businessForm,
        policyForms: policyForms,
      };
      dispatch(setPoliciesAction(args));
    }
  }, [policies, businessForm, policyForms, dispatch, allForms]);

  // once the forms are loaded, load the answers
  useEffect(() => {
    if (
      policySelected &&
      businessFormPresent &&
      policyFormsPresent &&
      businessForm &&
      policyForms &&
      policyForms.length > 0
    ) {
      const loadedForms = [businessForm, ...policyForms].filter(
        (form) => !!form,
      );
      //console.log({ loadedForms });
      for (let form of loadedForms) {
        const matchedAnswersContainer = cannedAnswers.find(
          (answersContainer) =>
            (form.policy === null && answersContainer.policy === null) ||
            form.policy?.internalName === answersContainer.policy?.internalName,
        );
        if (!matchedAnswersContainer) {
          continue;
        }

        const answers = generateAnswersFromQuestionsList(
          matchedAnswersContainer.answers,
          form.questionInstances,
        );

        dispatch(
          submitAnswersForForm({
            form,
            answers,
          }),
        );
      }
    }
  }, [
    policySelected,
    businessFormPresent,
    policyFormsPresent,
    dispatch,
    allForms,
    businessForm,
    policyForms,
  ]);

  // once everything is in state, indicate that we are about to resume the quote
  useEffect(() => {
    if (
      policySelected &&
      businessFormPresent &&
      policyFormsPresent &&
      answersPresent
    ) {
      setReadyToResume(true);
    }
  }, [
    policySelected,
    businessFormPresent,
    policyFormsPresent,
    answersPresent,
    dispatch,
  ]);

  // if any of the errors are present, set the error flag
  useEffect(() => {
    const possibleErrors = [
      businessError,
      getBusinessFormsStatus.error,
      ...policyStatuses.map((status) => status.error),
    ];
    setError(possibleErrors.some((error) => !!error));
  }, [
    businessError,
    policyStatuses,
    getBusinessFormsStatus,
    getBusinessFormsStatus.error,
  ]);

  // if anything is loading, set that as the state
  useEffect(() => {
    const possibleLoading = [
      businessLoading,
      ...policyStatuses.map((status) => status.loading || !status.called),
      getBusinessFormsStatus.loading,
      !getBusinessFormsStatus.called,
    ];
    setLoading(possibleLoading.some((loading) => !!loading));
  }, [
    businessLoading,
    policyStatuses,
    getBusinessFormsStatus,
    getBusinessFormsStatus.loading,
    getBusinessFormsStatus.called,
  ]);

  if (readyToResume) {
    navigate(`/insurtech/quote${paramWhitelabel}`);
  }

  if (error) {
    navigate(`/insurtech/quote${paramWhitelabel}`);
  }

  if (!isAllowedDemoLocation()) {
    navigate(`/insurtech/quote${paramWhitelabel}`);
  }

  if (loading) {
    return (
      <>
        <Backdrop open={true}>
          <CircularProgress color='secondary' size='2rem' />
        </Backdrop>
      </>
    );
  }

  return null;
};
