import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from '@reach/router';
import { useLazyQuery } from '@apollo/client';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
// material
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import { useAllowByPermission } from '../../../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
// components
import Label from '../../../../components/Label';
// @ts-expect-error
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { QuoteListHead } from '../../../../components/_dashboard/quotes/list';
import { VagoQuoteListToolbar } from './VagoQuoteListToolbar';
import LoadingScreen from '../../../../components/LoadingScreen';
import { InsufficientPermissionsErrorPage } from '../../../../../common/ErrorHandling';
// utils
import { findLegalOrTradeNameFromStructuredData } from '../../../../utils/AnswerSearch';
import { downloadPdfFile } from '../../../../../common/QuoteLoader/utility';
import { generateColourFromQuoteStatus } from '../../utility';
import { format } from 'date-fns';
import { vagoQuoteListFormatMoney } from './utility';
// queries
import {
  RELAY_QUOTES,
  GET_QUOTE_PDF,
  GET_ALL_QUOTES,
} from '../../../../../../queries';
// error handler
import Bugsnag from '@bugsnag/js';
// pages
import ErrorPage from '../../../Page500';
import { useSnackbar } from 'notistack';
import { useUsingVagoSettings } from '../../../../../../hooks';
import { VagoFileDownloadMenuButton } from '../../../../../OrganizationSpecific/Vago';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// classes and types
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import {
  RelayQuotesQuery,
  VagoCommercialPackStructuredDataType,
} from '../../../../../../gql/graphql';

// ----------------------------------------------------------------------

// NB Id is the attribute on the filteredquotes to access the value; otherwise sorting doesn't work
const TABLE_HEAD = [
  { id: 'dateAdded', label: 'Date', alignRight: false },
  { id: 'clientName', label: 'Client Name', alignRight: false },
  { id: 'transaction', label: 'Transaction', alignRight: false },
  { id: 'epicLookupCode', label: 'Epic Lookup Code', alignRight: false },
  { id: 'business', label: 'Line', alignRight: false },
  { id: 'uniqueID', label: 'Unique ID', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'cglLimit', label: 'GL Limit', alignRight: false },
  { id: 'tiv', label: 'TIV', alignRight: false },
  { id: 'cglPremium', label: 'GL Premium', alignRight: false },
  { id: 'propertyPremium', label: 'Property Premium', alignRight: false },
  { id: 'aragBound', label: 'Bound (ARAG)', alignRight: false },
  { id: 'avivaBound', label: 'Bound (Aviva)', alignRight: false },
  { id: 'propertyBound', label: 'Bound (Property)', alignRight: false },
  { id: 'liabilityBound', label: 'Bound (Liability)', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: '', label: '' },
];

// ----------------------------------------------------------------------

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy: $TSFixMe) {
  return order === 'desc'
    ? (a: $TSFixMe, b: $TSFixMe) => descendingComparator(a, b, orderBy)
    : (a: $TSFixMe, b: $TSFixMe) => -descendingComparator(a, b, orderBy);
}

function applySortFilter<T>(array: Array<T>, comparator: $TSFixMe): Array<T> {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function VagoQuoteList() {
  const theme = useTheme();
  const { token } = useAuth();
  const hasCorrectPermissions = useAllowByPermission('view:quotes:table');
  const { enqueueSnackbar } = useSnackbar();
  const [quoteList, setQuoteList] = useState<
    Array<
      NonNullable<
        ArrayElement<NonNullable<RelayQuotesQuery['relayQuotes']>['edges']>
      >['node']
    >
  >([]);
  const [filteredQuotes, setFilteredQuotes] = useState<
    Array<ArrayElement<typeof quoteList> & VagoCommercialPackStructuredDataType>
  >([]); // NB this is what is displayed
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<$TSFixMe>('dateAdded');
  const [filterName, setFilterName] = useState<string>(''); // the string to search for
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [endCursor, setEndcursor] = useState<$TSFixMe>();
  const [hasNextPage, setHasNextPage] = useState<$TSFixMe>();
  const [allFetchedQuotes, setAllFetchedQuotes] = useState<Array<$TSFixMe>>([]);
  const [timeOut, setTimeOut] = useState<$TSFixMe>();
  const [hiddenQueryLoading, setHiddenQueryLoading] = useState<$TSFixMe>();
  const [generatingPdf, setGeneratingPdf] = useState<$TSFixMe>();
  const [showActionId, setShowActionId] = useState<$TSFixMe>(false);
  const [emptyRows, setEmptyRows] = useState<number>(0); // not 100% sure what this is
  const onVago = useUsingVagoSettings();

  // Relay quotes query
  const [getQuotes, { loading, data, error }] = useLazyQuery(RELAY_QUOTES);

  // Search db query
  const [
    getSearchedQuotes,
    { data: searchedData, loading: searchedLoading, error: searchedError },
  ] = useLazyQuery(GET_ALL_QUOTES);

  // Load pdf query
  const [
    loadQuotePdf,
    { data: pdfData, loading: pdfLoading, error: pdfError },
  ] = useLazyQuery(GET_QUOTE_PDF);

  useEffect(() => {
    if (token && hasCorrectPermissions) {
      getQuotes({
        variables: {
          token: token,
        },
      });
    }
  }, [token, hasCorrectPermissions, getQuotes]);

  // search whenever the search term changes
  useEffect(() => {
    clearTimeout(timeOut);
    if (filterName) {
      setTimeOut(
        setTimeout(() => {
          searchQuery(filterName);
        }, 500),
      );
    }

    if (!filterName) {
      setQuoteList(allFetchedQuotes);
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName]);

  useEffect(() => {
    if (pdfData && pdfData.quotePdf?.pdfString) {
      const quotePdf = pdfData.quotePdf;
      downloadPdfFile(
        // @ts-expect-error
        quotePdf.pdfString,
        `${quotePdf.businessName || 'New Application'}.pdf`,
      );
      enqueueSnackbar('Download Complete', { variant: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfData]);

  useEffect(() => {
    if (pdfError) {
      enqueueSnackbar('Download Failed', { variant: 'error' });
      Bugsnag.notify(
        `Pdf download failed: ${JSON.stringify(pdfError, null, 4)}`,
      );
    } else if (pdfData && !pdfData.quotePdf?.pdfString) {
      enqueueSnackbar('Download Failed - Unable to Generate PDF', {
        variant: 'error',
      });
      Bugsnag.notify(
        `Pdf download failed: ${JSON.stringify(pdfData, null, 4)}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfError, pdfData]);

  useEffect(() => {
    if (searchedData) {
      // @ts-expect-error
      const quotesWithCompanyName = searchedData.allQuotes.map(
        (quote: $TSFixMe) => ({
          ...quote,
          business: quote.businessLine.displayName,
          company:
            quote.businessLine.displayName === 'Personal'
              ? quote.structuredData.companyName
              : findLegalOrTradeNameFromStructuredData(
                  quote.structuredData,
                  quote.businessLine.displayName,
                ),
        }),
      );
      setQuoteList(quotesWithCompanyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedData]);

  useEffect(() => {
    if (
      hasNextPage &&
      rowsPerPage * (page + 2) >= quoteList.length &&
      !searchedData
    ) {
      getQuotes({
        variables: {
          first: 100,
          cursor: endCursor,
          token,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteList, rowsPerPage, page]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      const quotesWithCompanyName = data.relayQuotes.edges.map(
        (quote: $TSFixMe) => ({
          ...quote.node,
          business: quote.node.businessLine.displayName,
          company: findLegalOrTradeNameFromStructuredData(
            quote.node.structuredData,
            quote.node.businessLine.displayName,
          ),
        }),
      );

      setQuoteList([...quoteList, ...quotesWithCompanyName]);
      setAllFetchedQuotes([...quoteList, ...quotesWithCompanyName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      setEndcursor(data.relayQuotes.pageInfo.endCursor);
      // @ts-expect-error
      setHasNextPage(data.relayQuotes.pageInfo.hasNextPage);
    }
  }, [data]);

  useEffect(() => {
    const anyLoading = loading || searchedLoading;
    if (anyLoading && quoteList.length > 0) {
      setHiddenQueryLoading(true);
    } else {
      setHiddenQueryLoading(false);
    }

    if (pdfLoading) {
      setGeneratingPdf(true);
    } else {
      setGeneratingPdf(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchedLoading, pdfLoading]);

  // set the filtered quotes when we have the selectors, &c.
  useEffect(() => {
    const filteredQuotes = applySortFilter(
      quoteList.map((quote) => {
        const { vagoCommercialPackStructuredData } = quote;
        return {
          ...quote,
          ...vagoCommercialPackStructuredData,
          user: vagoCommercialPackStructuredData?.user || '',
        };
      }),
      getComparator(order, orderBy),
    );

    setFilteredQuotes(filteredQuotes);
  }, [quoteList, order, orderBy]);

  // set empty rows whenever the page (or whatever) changes
  useEffect(() => {
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quoteList.length) : 0;
    setEmptyRows(emptyRows);
  }, [page, rowsPerPage, quoteList]);

  // Check current status of queries
  if ((loading && quoteList.length === 0) || !token) {
    return <LoadingScreen />;
  }

  if (error || pdfError || searchedError) {
    const anyError = error || pdfError || searchedError;
    console.error('Error:', anyError);
    // @ts-expect-error
    Bugsnag.notify(anyError);
    return <ErrorPage />;
  }

  const handleChangePage = (_event: $TSFixMe, newPage: $TSFixMe) => {
    setPage(newPage);
  };

  const handleRequestSort = (_event: $TSFixMe, property: $TSFixMe) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: $TSFixMe) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: $TSFixMe) => {
    setFilterName(event.target.value);
  };

  const searchQuery = (searchState: $TSFixMe) => {
    if (searchState) {
      getSearchedQuotes({
        variables: { token, search: searchState },
      });

      setPage(0);
    }
  };

  return (
    <Container maxWidth='lg' data-testid='quotes'>
      {!!token && hasCorrectPermissions === false ? (
        <InsufficientPermissionsErrorPage deniedIdentifier='Quotes Table' />
      ) : (
        <>
          <HeaderBreadcrumbs
            heading='Application List'
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard },
              { name: 'Quotes' },
            ]}
            action={
              <Button
                variant='contained'
                component={RouterLink}
                to={'/insurtech/manager/quotes/create'}
                startIcon={<Icon icon={plusFill} />}
              >
                New Application
              </Button>
            }
          />
          <Card>
            <VagoQuoteListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              isLoading={hiddenQueryLoading}
              generatingPdf={generatingPdf}
            />

            <TableContainer
              sx={{ minWidth: 800, minHeight: 400, overflowX: 'scroll' }}
            >
              <Table>
                <QuoteListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={quoteList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredQuotes
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        uniqueID,
                        friendlyId,
                        status,
                        dateAdded,
                        business,
                        clientName,
                        transaction,
                        epicLookupCode,
                        cglLimit,
                        tiv,
                        cglPremium,
                        propertyPremium,
                        user,
                        aragBound,
                        avivaBound,
                        propertyBound,
                        liabilityBound,
                      } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          onMouseEnter={() => {
                            setShowActionId(row.id);
                          }}
                          onMouseLeave={() => setShowActionId(-1)}
                        >
                          {[
                            format(new Date(dateAdded), 'MMMM dd yyyy, p'),
                            clientName,
                            transaction,
                            epicLookupCode,
                            business,
                            friendlyId || uniqueID,
                            <Label
                              variant={
                                theme.palette.mode === 'light'
                                  ? 'ghost'
                                  : 'filled'
                              }
                              color={generateColourFromQuoteStatus(status)}
                            >
                              {sentenceCase(status)}
                            </Label>,
                            ...[cglLimit, tiv, cglPremium, propertyPremium].map(
                              (amount) => vagoQuoteListFormatMoney(amount),
                            ),
                            ...[
                              aragBound,
                              avivaBound,
                              propertyBound,
                              liabilityBound,
                            ].map((bound) => <BoundDisplay bound={bound} />),
                            user,
                          ].map((elem: $TSFixMe) =>
                            // @ts-expect-error
                            // prettier-ignore
                            <TableCell component={RouterLink}
                                align='left'
                                to={`/insurtech/manager/quotes/details/${uniqueID}`}
                                sx={{ textDecoration: 'none' }}
                              >
                                {elem}
                              </TableCell>,
                          )}

                          <TableCell align='right'>
                            {row.id === showActionId ? (
                              onVago ? (
                                <VagoFileDownloadMenuButton
                                  quote={{ ...row, uniqueId: uniqueID }}
                                />
                              ) : (
                                <IconButton
                                  sx={{ color: 'text.secondary' }}
                                  onClick={() => {
                                    if (token) {
                                      loadQuotePdf({
                                        variables: {
                                          token,
                                          uniqueID,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <DownloadRoundedIcon />
                                </IconButton>
                              )
                            ) : (
                              <IconButton sx={{ visibility: 'hidden' }}>
                                <DownloadRoundedIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={TABLE_HEAD.length} />
                    </TableRow>
                  )}
                </TableBody>
                {filteredQuotes.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align='center'
                        colSpan={TABLE_HEAD.length}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={quoteList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}

interface BoundDisplayProps {
  bound: boolean;
}
const BoundDisplay = ({ bound }: BoundDisplayProps) => {
  return (
    <BoundDisplayContainer>
      {bound ? 'Yes' : 'No'}{' '}
      {bound ? (
        <CheckIcon sx={{ color: 'success.main' }} />
      ) : (
        <CloseIcon sx={{ color: 'error.main' }} />
      )}
    </BoundDisplayContainer>
  );
};

const BoundDisplayContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  };
});
