import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import ReactApexChart from 'react-apexcharts';
import { getIcon } from '../../../utils/percentIcon';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
// hooks
import { useAuth } from '@calefy-inc/authentication';
import { useAllowByPermission } from '../../../../../hooks';
import { useLazyQuery } from '@apollo/client';
import { useSettings } from '../../../../../hooks';
// utils
import { fNumber, fPercent } from '../../../utils/formatNumber';
// queries
import { DASHBOARD_TOTAL_QUOTES_TODAY } from '../../../../../queries';
// Error
import Bugsnag from '@bugsnag/js';
import TotalTodaySkeleton from './skeletons/TotalTodaySkeleton';
import { handleDashboardError } from './handleError';
import { InsufficientPermissionsErrorComponent } from './ErrorComponent';
// Mock
import { TotalQuotesTodayMock } from './Mock';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

export default function AppTotalQuotesToday() {
  const theme = useTheme();
  const { token } = useAuth();
  const hasDashboardPermissions = useAllowByPermission(
    'view:dashboard:queries',
  );
  const { slug } = useSettings();
  const [getData, { data, loading, error }] = useLazyQuery(
    DASHBOARD_TOTAL_QUOTES_TODAY,
    {
      variables: { token },
    },
  );
  const [dailyQuotes, setDailyQuotes] = useState<number[]>([]);
  const [percent, setPercent] = useState<number>();
  const [quotesToday, setQuotesToday] = useState<number>();
  const [okToDisplay, setOkToDisplay] = useState<boolean>(false);

  // get the data on initial load (if relevant)
  useEffect(() => {
    if (
      hasDashboardPermissions === true &&
      token &&
      slug !== 'lastnameinsurance'
    ) {
      getData({
        variables: { token },
      });
    }
  }, [token, hasDashboardPermissions, slug]);

  useEffect(() => {
    if (slug === 'lastnameinsurance') {
      setDailyQuotes(TotalQuotesTodayMock.dailyQuotes);
      setPercent(TotalQuotesTodayMock.percent);
      setQuotesToday(TotalQuotesTodayMock.quotesToday);
      setOkToDisplay(true);
    } else if (data && data.dailyQuotesData) {
      // @ts-expect-error
      setDailyQuotes(data.dailyQuotesData.dailyQuotes);
      // @ts-expect-error
      setPercent(data.dailyQuotesData.weeklyQuotePercentDiff);
      // @ts-expect-error
      setQuotesToday(data.dailyQuotesData.quotesToday);
      setOkToDisplay(true);
    } else {
      setOkToDisplay(false);
    }
  }, [data, slug]);

  if (hasDashboardPermissions !== true) {
    //console.log('About to return InsufficientPermissionsErrorComponent');
    return <InsufficientPermissionsErrorComponent />;
  }
  if (
    (loading || !okToDisplay || hasDashboardPermissions === undefined) &&
    !error
  )
    return <TotalTodaySkeleton />;
  if (error) {
    console.error(error);
    Bugsnag.notify(`Could not load Total Quotes Today component, ${error}`);
    return handleDashboardError(error);
  }

  const CHART_DATA = [{ data: dailyQuotes }];

  const chartOptions = {
    colors: [theme.palette.primary.main],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    labels: ['1', '2', '3', '4', '5', '6', '7', '8'],
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (_seriesName: string) => `Daily Applications`,
        },
      },
      marker: { show: false },
    },
  };

  return (
    <Card
      sx={{ display: 'flex', alignItems: 'center', p: 3 }}
      data-testid='app-total-quotes-today'
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant='subtitle2'>Total Applications Today</Typography>

        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{ mt: 2, mb: 1 }}
        >
          {percent !== null && percent !== undefined ? (
            <>
              <IconWrapperStyle
                sx={{
                  ...(percent < 0 && {
                    color: 'error.main',
                    bgcolor: alpha(theme.palette.error.main, 0.16),
                  }),
                  ...(percent === 0 && {
                    color: theme.palette.grey[500],
                    bgcolor: alpha(theme.palette.grey[400], 0.16),
                  }),
                }}
              >
                <Icon width={16} height={16} icon={getIcon(percent)} />
              </IconWrapperStyle>
              <Typography component='span' variant='subtitle2'>
                {percent > 0 && '+'}
                {fPercent(percent)}
              </Typography>
              <Typography component='span' variant='caption'>
                Last 7 days
              </Typography>
            </>
          ) : (
            <Icon width={24} height={24} icon={getIcon(percent)} />
          )}
        </Stack>

        <Typography variant='h3'>{fNumber(quotesToday)}</Typography>
      </Box>

      <ReactApexChart
        type='bar'
        series={CHART_DATA}
        //@ts-expect-error
        options={chartOptions}
        width={60}
        height={36}
      />
    </Card>
  );
}
