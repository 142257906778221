import { graphql } from '../../gql';

export const QUOTE_INSURANCE_POLICIES = graphql(`
  query quoteInsurancePolicies($token: String!, $uniqueId: String!) {
    quoteInsurancePolicies(token: $token, uniqueId: $uniqueId) {
      id
      description
      policyEffectiveDate
      policyExpiryDate
      coverages {
        id
        name
        perils {
          ...PerilFields
        }
        coveredLocations {
          id
          description
          address
          city
          province
          postal
        }
      }
    }
  }
`);
