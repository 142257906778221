import { graphql } from '../gql';

export const SOME_FINAL_FORMS_BARE_MINIMUM = graphql(`
  query minimalSomeFinalForms($policyId: ID, $businessId: ID) {
    someFinalForms(policy: $policyId, business: $businessId) {
      id
      businessLine {
        id
        displayName
      }
      policy {
        id
        displayName
      }
    }
  }
`);
