export const cyberAnswers = [
  {
    questionInstance: {
      apiName: 'cyber_pii_records_count',
      displayName: 'Cyber - PII Records Count',
      label:
        "Approx. how many records (PII's) are retained within your computer network?",
      helpText: null,
      component: 'textfield',
      dataType: 'string',
      propsBlob: {},
      required: true,
      subQuestions: [],
    },
    value: '100000',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'backup_data',
      displayName: 'Backup Data',
      label:
        'Do you or your outsourcer backup your data at least once per week and store it in an offsite location?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'yes',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'antivirus_and_firewall',
      displayName: 'Antivirus and Firewall',
      label:
        'Are anti-virus and firewalls in place and updated at least quarterly?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'no',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'password_protected',
      displayName: 'Password Protected',
      label:
        'Are all portable media, including phones, tablets and USB storage devices password protected?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'yes',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'two_factor_authentication',
      displayName: 'Two Factor Authentication',
      label:
        'Is the remote access to your network and data at least two factor authenticated?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'no',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'cyber_business_impacts',
      displayName: 'Cyber Business Impacts',
      label:
        'In the event you suffered downtime or material impairment to your systems, how long would it take for your business operations to be significantly impacted?',
      helpText: null,
      component: 'InputToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'Immediately', label: 'Immediately' },
          { value: '6', label: '6 Hours' },
          { value: '12', label: '12 Hours' },
          { value: '24', label: '24 Hours' },
          { value: '48', label: '48 Hours' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'Immediately',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'password_changes',
      displayName: 'Password Changes',
      label:
        'Are passwords changed at least quarterly for online banking and fund transfer platforms?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'yes',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'customer_changes_verification',
      displayName: 'Customer Changes Verification',
      label:
        'Are changes to supplier/customer details independently verified with a known contact for authenticity?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'no',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'fund_transfers',
      displayName: 'Fund Transfers',
      label:
        'Are at least two members of staff required to authorise fund transfers or cheques above $10,000 or issuance of instructions for the disbursement of assets, funds or investments?',
      helpText: null,
      component: 'YesNoToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: 'yes',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: '3_year_claim_history',
      displayName: '3 year claim history',
      label: '...',
      helpText: null,
      component: 'logic',
      dataType: 'nested',
      propsBlob: { operator: 'equals', condition: 'yes' },
      required: false,
      subQuestions: [
        {
          apiName: 'claim_check_penalty',
          displayName: 'Claim Check Penalty',
          label:
            'Have you had in the last three years any claim, loss, penalty or fine that would be covered by this policy, or are you aware of any circumstances which could give rise to such a claim?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ],
          },
          required: true,
          subQuestions: [],
          fieldPrefix: '3_year_claim_history[0]',
        },
        {
          apiName: 'please_explain',
          displayName: 'Please Explain',
          label: 'Please explain.',
          helpText: null,
          component: 'textarea',
          dataType: 'string',
          propsBlob: {},
          required: true,
          subQuestions: [],
          fieldPrefix: '3_year_claim_history[0]',
        },
      ],
    },
    value: 1,
    subAnswers: [
      {
        questionInstance: {
          apiName: 'claim_check_penalty',
          displayName: 'Claim Check Penalty',
          label:
            'Have you had in the last three years any claim, loss, penalty or fine that would be covered by this policy, or are you aware of any circumstances which could give rise to such a claim?',
          helpText: null,
          component: 'YesNoToggle',
          dataType: 'string',
          propsBlob: {
            options: [
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ],
          },
          required: true,
          subQuestions: [],
          fieldPrefix: '3_year_claim_history[0]',
        },
        value: 'no',
        subAnswers: [],
        details: {
          lat: null,
          lng: null,
          google: {},
          opta: {},
          openingHours: [],
          images: [],
        },
      },
    ],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
  {
    questionInstance: {
      apiName: 'first_party_cyber',
      displayName: 'First party cyber',
      label: 'How much cyber coverage would you like?',
      helpText: null,
      component: 'InputToggle',
      dataType: 'string',
      propsBlob: {
        options: [
          { value: '1000000', label: '$1,000,000' },
          { value: '2000000', label: '$2,000,000' },
          { value: '3000000', label: '$3,000,000' },
          { value: '4000000', label: '$4,000,000' },
          { value: '5000000', label: '$5,000,000' },
        ],
      },
      required: true,
      subQuestions: [],
    },
    value: '5000000',
    subAnswers: [],
    details: {
      lat: null,
      lng: null,
      google: {},
      opta: {},
      openingHours: [],
      images: [],
    },
  },
];
