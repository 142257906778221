import { graphql } from '../../gql';
export const CREATE_INSURER_QUOTE_COMPARISON = graphql(`
  mutation CreateInsurerQuoteComparison(
    $insurerQuoteIds: [String!]!
    $forceRegen: Boolean
  ) {
    createInsurerQuoteComparison(
      insurerQuoteIds: $insurerQuoteIds
      forceRegen: $forceRegen
    ) {
      insurerQuoteComparison {
        id
        insurerQuotes {
          insurerQuoteIdentifier
        }
        llmResponseBlob
        uuid
      }
      ok
      new
    }
  }
`);
