import ApolloClient from 'apollo-client';
import {
  makeExecutableSchema,
  addMockFunctionsToSchema,
  MockList,
} from 'graphql-tools';
import { SchemaLink } from 'apollo-link-schema';
import { InMemoryCache } from 'apollo-cache-inmemory';
import faker from 'faker';
import { merge } from 'lodash';
import { loader } from 'graphql.macro';
import stringHash from 'string-hash';
import businessList from './business_list.json';

const rawSchema = loader('../schema.graphql');

faker.seed(1);

function _pickRandomElement(array) {
  return array[_pickRandomNumberBetween(0, array.length - 1)];
}

function _pickRandomNumberBetween(start, end) {
  return start + (faker.random.number() % (end + 1 - start));
}

function _pickRandomSubSet(array, { minimumNumber } = {}) {
  const numberOfElements = _pickRandomNumberBetween(
    isNaN(minimumNumber) ? 0 : minimumNumber,
    array.length,
  );

  return faker.helpers.shuffle(array).slice(0, numberOfElements);
}

export function createDefaultMocks(mockOverrides) {
  const { businessTypes, industryTypes } = createBusinessAndIndustryMocks();
  const policyCategories = createPolicyMocks();
  const questionGroups = createQuestionGroupMocks({
    industryTypes,
    businessTypes,
    policyCategories,
  });
  const forms = createFinalFormMocks({
    businessTypes,
    policyCategories,
    questionGroups,
  });

  const mocks = {
    ID: () => faker.random.uuid(),
    ...createQuestionGroupMockQuery({ questionGroups }),
    Query: () => ({
      ...policiesQueryMock({ policyCategories }),
      ...businessandIndustryTypesQueryMock({ businessTypes, industryTypes }),
      ..._createFinalFormsQuery({ forms }),
    }),
    Mutation: () => ({
      ...formPublishingMutationMocks({
        forms,
        businessTypes,
        policyCategories,
        questionGroups,
      }),
      ...quotePublishingMocks(),
    }),
  };

  return merge(mocks, mockOverrides || {});
}

const allowableDataTypes = ['string', 'number', 'boolean', 'date', 'json'];
const allowableComponents = ['textfield', 'checkbox', 'slider', 'yesnotoggle'];

export function createLocalMockClient({ mocks = createDefaultMocks() } = {}) {
  const schema = makeExecutableSchema({ typeDefs: rawSchema });
  // Add mocks, modifies schema in place
  addMockFunctionsToSchema({
    schema,
    mocks,
    preserveResolvers: true,
  });

  const client = new ApolloClient({
    link: new SchemaLink({ schema }),
    cache: new InMemoryCache({
      dataIdFromObject: (obj) => {
        return obj.id;
      },
    }),
  });

  return client;
}

function quotePublishingMocks() {
  return {
    createQuote: (parent, variables) => {
      faker.seed(
        stringHash(
          variables.answerInstances
            .map(({ questionInstanceId }) => questionInstanceId)
            .join(),
        ),
      );

      return {
        ok: true,
        quote: {
          id: faker.random.uuid(),
          businessLine: {
            id: variables.businessLineId,
          },
          answerInstances: variables.answerInstances.map((answerInstance) => ({
            ...answerInstance,
            id: faker.random.uuid(),
          })),
        },
      };
    },
  };
}

function formPublishingMutationMocks({
  forms,
  businessTypes,
  policyCategories,
  questionGroups,
}) {
  const flattenedListOfQuestionTemplates = questionGroups.reduce(
    (acc, questionGroup) => [...acc, ...questionGroup.questionTemplates],
    [],
  );

  return {
    createFinalForm: (parent, variables) => {
      const { questionInstances, businessLineId, policyId } = variables;

      // create a seed based on all of the names joined together
      faker.seed(stringHash(questionInstances.map(({ name }) => name).join()));

      const businessType = businessTypes.find(
        (businessType) => businessType.id === businessLineId,
      );

      const policy =
        policyId && policyCategories.find((policy) => policy.id === policyId);

      return {
        ok: true,
        finalForm: {
          id: faker.random.uuid(),
          questionInstances: questionInstances.map((questionInstance) => {
            const questionTemplate =
              questionInstance.questionTemplateId &&
              flattenedListOfQuestionTemplates.find(
                ({ id }) => id === questionInstance.questionTemplateId,
              );

            return {
              ...questionInstance,
              id: faker.random.uuid(),
              questionTemplate,
            };
          }),
          businessLine: businessType,
          policy: policy,
        },
      };
    },

    updateFinalForm: (parent, variables) => {
      const { questionInstances, businessLineId, policyId } = variables;

      // create a seed based on all of the names joined together
      faker.seed(stringHash(questionInstances.map(({ name }) => name).join()));

      const businessType = businessTypes.find(
        (businessType) => businessType.id === businessLineId,
      );

      const policy =
        policyId && policyCategories.find((policy) => policy.id === policyId);

      return {
        ok: true,
        finalForm: {
          id: faker.random.uuid(),
          questionInstances: questionInstances.map((questionInstance) => {
            const questionTemplate =
              questionInstance.questionTemplateId &&
              flattenedListOfQuestionTemplates.find(
                ({ id }) => id === questionInstance.questionTemplateId,
              );

            return {
              ...questionInstance,
              id: faker.random.uuid(),
              questionTemplate,
            };
          }),
          businessLine: businessType,
          policy: policy,
        },
      };
    },
  };
}

function _createFinalFormsQuery({ forms }) {
  return {
    allFinalForms: (parent, args, context, info) => {
      return forms;
    },
    someFinalForms: (parent, variables, context, info) => {
      const { business } = variables;
      return forms.filter((form) => form.businessLine.id === business);
    },
  };
}

function createQuestionGroupMockQuery({ questionGroups }) {
  return {
    QuestionGroupType: (parent, args, context, info) => {
      if (info.parentType.name === 'PolicyType') {
        return questionGroups.find((questionGroup) => {
          const [policy] = questionGroup.policySet;
          if (policy) {
            return policy.id === parent.id;
          }
          return undefined;
        });
      } else if (info.parentType.name === 'BusinessTypeType') {
        return questionGroups.find((questionGroup) => {
          const [business] = questionGroup.businesstypeSet;
          if (business) {
            return business.id === parent.id;
          }
          return undefined;
        });
      } else if (info.parentType.name === 'IndustryType') {
        return questionGroups.find((questionGroup) => {
          const [industry] = questionGroup.industrySet;
          if (industry) {
            return industry.id === parent.id;
          }
          return undefined;
        });
      }
    },
  };
}

function createQuestionGroupMock({ businessType, industryType, policyType }) {
  const parent = businessType || industryType || policyType;

  // This line will take the parent model (policy, industry, business type), and turn its id into an integer hash, which will then be given to faker in order to create a stable randomization fortests. As long as the parent ids are stable, then the question groups randomization should be stable as well
  faker.seed(stringHash(parent.id));

  // Let's make the label dependent on the parent type so that it's more identifiable in the application
  let businesstypeSet = [];
  let industrySet = [];
  let policySet = [];
  if (businessType) {
    businesstypeSet.push(businessType);
  } else if (industryType) {
    industrySet.push(industryType);
  } else if (policyType) {
    policySet.push(policyType);
  }

  const questionGroup = {
    id: faker.random.uuid(),
    name: parent.displayName || faker.lorem.word(),
    policySet,
    businesstypeSet,
    industrySet,
    questionTemplates: [],
  };

  for (let i = 0; i < _pickRandomNumberBetween(1, 8); i++) {
    questionGroup.questionTemplates.push({
      id: faker.random.uuid(),
      name: faker.company.bsBuzz(),
      label: `${faker.company.catchPhrase()} ${parent.displayName}?`,
      helpText: faker.lorem.words(8 + (faker.random.number() % 20)),
      dataType: _pickRandomElement(allowableDataTypes),
      component: _pickRandomElement(allowableComponents),
      propsBlob: null,
    });
  }

  return questionGroup;
}

export function createQuestionGroupMocks({
  businessTypes,
  industryTypes,
  policyCategories,
}) {
  let questionGroups = [];

  for (const businessType of businessTypes) {
    questionGroups.push(createQuestionGroupMock({ businessType }));
  }

  for (const industryType of industryTypes) {
    questionGroups.push(createQuestionGroupMock({ industryType }));
  }

  for (const policyType of policyCategories) {
    questionGroups.push(createQuestionGroupMock({ policyType }));
  }

  return questionGroups;
}

function policiesQueryMock({ policyCategories }) {
  return {
    allPolicies: () => policyCategories,
    policy: (parent, variables) => {
      const matchingPolicy = policyCategories.find(
        ({ id }) => id === variables.id,
      );

      return {
        ...matchingPolicy,
        questiongroupSet: () => new MockList(1),
      };
    },
  };
}

function createPolicyMocks() {
  faker.seed(1);
  const POLICIES = ['Cyber', 'Volcano', 'Flood', 'Fire', 'Liability'];

  return POLICIES.map((policyName) => ({
    id: faker.random.uuid(),
    displayName: policyName,
  }));
}

function createQuestionInstances({ form, questionTemplates }) {
  return questionTemplates.map((questionTemplate) => {
    const { name, label, helpText, component, propsBlob, dataType } =
      questionTemplate;

    return {
      clientId: questionTemplate.id,
      questionTemplate: {
        id: questionTemplate.id,
        name: questionTemplate.name,
      },
      form: {
        id: form.id,
        name: form.name,
      },
      name,
      label,
      helpText,
      component,
      propsBlob,
      dataType,
    };
  });
}

function getQuestionGroupsFromBusiness({ businessType, questionGroups }) {
  const correspondingBusinessQuestionGroup = questionGroups.find(
    (questionGroup) => {
      const [business] = questionGroup.businesstypeSet;
      if (business) {
        return business.id === businessType.id;
      }
      return undefined;
    },
  );

  const correspondingIndustryQuestionGroup = questionGroups.find(
    (questionGroup) => {
      const [industry] = questionGroup.industrySet;
      if (industry) {
        return industry.id === businessType.industry.id;
      }
      return undefined;
    },
  );

  return [
    ...correspondingBusinessQuestionGroup.questionTemplates,
    ...correspondingIndustryQuestionGroup.questionTemplates,
  ];
}

function getQuestionGroupFormPolicy({ policyType, questionGroups }) {
  const correspondingPolicyQuestionGroup = questionGroups.find(
    (questionGroup) => {
      if (questionGroup.policySet.length) {
        const policy = questionGroup.policySet[0];
        return policy.id === policyType.id;
      }
      return undefined;
    },
  );

  return correspondingPolicyQuestionGroup.questionTemplates;
}

function createFinalFormMocks({
  businessTypes,
  policyCategories,
  questionGroups,
}) {
  let forms = [];

  const nonGenericBusinessTypes = businessTypes.filter(
    ({ internalName }) => internalName !== 'generic',
  );
  const businessTypesWithForms = _pickRandomSubSet(nonGenericBusinessTypes, {
    minimumNumber: 1,
  });

  for (const businessTypeWithForm of businessTypesWithForms) {
    faker.seed(stringHash(`form-${businessTypeWithForm.id}`));

    const form = {
      id: faker.random.uuid(),
      industry: businessTypeWithForm.industry,
      businessLine: businessTypeWithForm,
      policy: null,
    };

    const questionTemplates = getQuestionGroupsFromBusiness({
      businessType: businessTypeWithForm,
      questionGroups,
    });

    form.questionInstances = createQuestionInstances({
      questionTemplates: _pickRandomSubSet(questionTemplates, {
        minimumNumber: 1,
      }),
      form,
    });

    //TODO we need to randomly create some custom questions as well

    forms.push(form);

    let policyTypesWithForms = _pickRandomSubSet(policyCategories);

    for (const policyTypeWithForm of policyTypesWithForms) {
      const policyQuestionTemplates = getQuestionGroupFormPolicy({
        policyType: policyTypeWithForm,
        questionGroups,
      });

      forms.push({
        id: faker.random.uuid(),
        businessLine: businessTypeWithForm,
        policy: policyTypeWithForm,
        questionInstances: createQuestionInstances({
          questionTemplates: _pickRandomSubSet(policyQuestionTemplates, {
            minimumNumber: 1,
          }),
          form,
        }),
      });
    }
  }

  const genericBusinessType = businessTypes.find(
    ({ internalName }) => internalName === 'generic',
  );
  const genericBusinessForm = {
    id: faker.random.uuid(),
    industry: genericBusinessType.industry,
    businessLine: genericBusinessType,
    policy: null,
  };

  forms.push(genericBusinessForm);

  const genericQuestionTemplates = getQuestionGroupsFromBusiness({
    businessType: genericBusinessType,
    questionGroups,
  });

  genericBusinessForm.questionInstances = createQuestionInstances({
    questionTemplates: genericQuestionTemplates,
    form: genericBusinessForm,
  });

  for (const policyTypeWithForm of policyCategories) {
    const policyQuestionTemplates = getQuestionGroupFormPolicy({
      policyType: policyTypeWithForm,
      questionGroups,
    });

    forms.push({
      id: faker.random.uuid(),
      businessLine: genericBusinessType,
      policy: policyTypeWithForm,
      questionInstances: createQuestionInstances({
        questionTemplates: policyQuestionTemplates,
        form: genericBusinessForm,
      }),
    });
  }

  return forms;
}

export function createBusinessAndIndustryMocks() {
  faker.seed(2);

  const INDUSTRY_AND_BUSINESS_TYPES = businessList.reduce(
    (industryDictionary, business) => {
      const industry = industryDictionary[business.industry] || [];

      return {
        ...industryDictionary,
        [business.industry]: [...industry, business],
      };
    },
    {},
  );

  const { businessTypes, industryTypes } = Object.entries(
    INDUSTRY_AND_BUSINESS_TYPES,
  ).reduce(
    (acc, [industryName, childBusinessTypeNames]) => {
      const industry = {
        id: faker.random.uuid(),
        displayName: industryName,
      };

      const childBusinessTypes = childBusinessTypeNames.map(
        ({ internal_name, display_name }) => ({
          id: faker.random.uuid(),
          internalName: internal_name,
          displayName: display_name,
          industry,
        }),
      );

      const businessTypes = [...acc.businessTypes, ...childBusinessTypes];

      const industryTypes = [...acc.industryTypes, industry];

      return {
        businessTypes,
        industryTypes,
      };
    },
    {
      businessTypes: [],
      industryTypes: [],
    },
  );

  const genericIndustry = {
    id: faker.random.uuid(),
    displayName: 'generic',
  };

  businessTypes.push({
    id: faker.random.uuid(),
    displayName: 'Generic Company',
    internalName: 'generic',
    industry: genericIndustry,
  });

  industryTypes.push(genericIndustry);

  return { businessTypes, industryTypes };
}

function businessandIndustryTypesQueryMock({ businessTypes, industryTypes }) {
  return {
    industry: (parent, variables) => {
      const foundIndustry = industryTypes.find(({ id }) => id === variables.id);

      if (foundIndustry) {
        return {
          ...foundIndustry,
          questiongroupSet: () => new MockList(1),
        };
      }
    },
    businessType: (parent, variables) => {
      const matchingBusinessType = businessTypes.find(
        ({ id }) => id === variables.id,
      );

      if (matchingBusinessType) {
        const matchingParentIndustry = industryTypes.find(
          ({ id }) => id === matchingBusinessType.industry.id,
        );

        return {
          ...matchingBusinessType,
          questiongroupSet: () => new MockList(1),
          industry: matchingParentIndustry
            ? {
                ...matchingParentIndustry,
                questiongroupSet: () => new MockList(1),
              }
            : null,
        };
      }
    },
    allBusinessTypes: () => {
      // flatten entries into one list of business Type objects. See schema
      return businessTypes.map((businessType) => ({
        ...businessType,
        questiongroupSet: () => new MockList(1),
      }));
    },
    allIndustries: () => {
      return industryTypes.map((industry) => ({
        ...industry,
        questiongroupSet: () => new MockList(1),
      }));
    },
  };
}
