// types
import type { FormValue } from 'informed';
import {
  Language,
  ILanguageAwareString,
  LanguageAwareString,
} from '../../../../Typescript/classes';

// types and classes
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';

/**
 * Takes an object and converts the props_blob properties, leaving the rest untouched. So, {a: 1, props_blob_b: 2} => {a: 1, propsBlob: {b: 2}}
 */
function processPropsBlobProperties(obj: GenericObject) {
  const propsBlobRegex = /^props_blob_(.+)/;
  const result = Object.entries(obj).reduce(
    (acc: $TSFixMe, [k, v]) => {
      if (!v) {
        return acc;
      }
      const propsBlobMatch = propsBlobRegex.exec(k);
      if (propsBlobMatch) {
        acc.propsBlob[propsBlobMatch[1]] = v;
      } else {
        acc[k] = v;
      }
      return acc;
    },
    { propsBlob: {} },
  );
  return result;
}

export function processLanguageAwareProperties(
  obj: GenericObject,
  languages: Array<Language>,
) {
  //console.log('processLanguageAwareProperties: processing', obj);
  const languageMatchRegex = new RegExp(
    `(${languages.map((language) => language.shortName).join('|')})_(.+)`,
  );
  const result = Object.entries(obj).reduce((acc: $TSFixMe, [k, v]) => {
    //console.log(
    //   `processLanguageAwareProperties: processing ${JSON.stringify(
    //     k,
    //   )}, ${JSON.stringify(v)}`,
    // );
    if (!v) {
      //console.log(
      //   'processLanguageAwareProperties: no value, so about to return',
      // );
      return acc;
    }
    const languageMatch = languageMatchRegex.exec(k);
    //console.log(
    //   'processLanguageAwareProperties: languageMatch:',
    //   languageMatch,
    // );
    if (languageMatch) {
      const language = languages.find(
        (language) => language.shortName === languageMatch[1],
      );
      if (!language) {
        throw new Error(
          `Unable to find matching language with short name ${
            languageMatch[1]
          }: ${JSON.stringify(languages, null, 4)}`,
        );
      }
      const newKey = languageMatch[2];
      //console.log('processLanguageAwareProperties: created new key', newKey);
      if (!acc[newKey]) {
        acc[newKey] = [];
      }
      // @ts-expect-error
      acc[newKey].push(new LanguageAwareString(v, language));
    } else {
      //console.log('processLanguageAwareProperties: setting acc', k, v);
      acc[k] = v;
    }
    return acc;
  }, {});

  //console.log(
  //   'processLanguageAwareProperties: about to return',
  //   result,
  //   'from',
  //   obj,
  // );

  return result;
}

interface IApportionFormValuesReturn {
  propsBlob: GenericObject;
  [k: string]: $TSFixMe;
}
/**
 * Takes the values from an informed form and processes them into the language-aware fields, propsBlob fields, and the ones that go directly onto the questionInstance
 */
export function apportionFormValues(
  formValues: FormValue,
  languages: Array<Language>,
): IApportionFormValuesReturn {
  // first the language aware properties
  //console.log('apportionFormValues: formValues:', formValues);
  let transformed = processLanguageAwareProperties(formValues, languages);
  transformed = processPropsBlobProperties(transformed);
  // this is necessary for the case where in the propsBlob, there are language-aware properties
  transformed.propsBlob = processLanguageAwareProperties(
    transformed.propsBlob,
    languages,
  );

  // basically checking if this is an input toggle
  // NB This is gross - probably this logic needs to be moved into a subclass of ProgramBuilderQuestionInstance specific to the InputToggle so that we can intelligently enforce the shape of the propsBlob
  if (transformed.propsBlob.options) {
    //console.log(
    //   'apportionFormValues: about to create language-aware options from',
    //   transformed.propsBlob.options,
    // );
    transformed.propsBlob.options = transformed.propsBlob.options.map(
      (option: $TSFixMe) => {
        //console.log('apportionFormValues: about to process option', option);
        const result = processLanguageAwareProperties(option, languages);
        //console.log(
        //   `apportionFormValues option: ${JSON.stringify(
        //     option,
        //     null,
        //     4,
        //   )} -> ${JSON.stringify(result, null, 4)}`,
        // );
        return result;
      },
    );
  }

  //console.log('Done apportioning values. transformed:', transformed);
  return transformed;
  // const propsBlob: { [k: string]: unknown } = {};
  // const untransformedLanguageAwareProperties: {
  //   [k: string]: string;
  // } = {};
  // const rest: { [k: string]: unknown } = {};

  // const propsBlobRegex = /^props_blob_(.+)/;
  // const languageMatchRegex = new RegExp(
  //   `(${languages.map((language) => language.shortName).join('|')})_(.+)`,
  // );

  // Object.entries(formValues).forEach(([key, value]) => {
  //   // guard against values which are empty - as can happen when the user backspaces out a form, for example
  //   if (!value) {
  //     return;
  //   }
  //   const propsBlobMatch = propsBlobRegex.exec(key);
  //   const languageMatch = languageMatchRegex.exec(key);
  //   if (propsBlobMatch) {
  //     const actualKey = propsBlobMatch[1];
  //     propsBlob[actualKey] = value;
  //   } else if (languageMatch) {
  //     if (typeof value === 'string') {
  //       untransformedLanguageAwareProperties[key] = value;
  //     }
  //   } else {
  //     rest[key] = value;
  //   }
  // });

  // const languageAwareProperties: { [k: string]: Array<ILanguageAwareString> } =
  //   transformLanguageAwareProperties(
  //     untransformedLanguageAwareProperties,
  //     languages,
  //   );
}

export function filterPropProperties(formState: $TSFixMe) {
  return Object.entries(formState).reduce((acc, [key, value]) => {
    const propsBlobRegex = /^props_blob_(.+)/;

    if (key.match(propsBlobRegex)) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

export function groupPropProperties(formValues: $TSFixMe) {
  // debugger;
  const propsBlob = Object.entries(formValues).reduce((acc, [key, value]) => {
    const propsBlobRegex = /^props_blob_(.+)/;

    const regexMatch = propsBlobRegex.exec(key);

    if (!regexMatch) {
      return acc;
    }

    const capturedPropertyName = regexMatch[1];

    // This will strip the props_blob prefix from the key, and store it in this new obj
    return {
      ...acc,
      [capturedPropertyName]: value,
    };
  }, {});
  // debugger;
  // return JSON.stringify(propsBlob);
  return propsBlob;
}

/**
 * Take an object of language aware fields, and transform all fields of the form <languageString>_<desiredName> -> <desiredName>: [{value: form value, language: Language matching the code}]
 */
export function transformLanguageAwareProperties(
  values: {
    [k: string]: $TSFixMe;
  },
  languages: Language[],
): { [k: string]: ILanguageAwareString[] } {
  const languageMatchRegex = new RegExp(
    `(${languages.map((language) => language.shortName).join('|')})_(.+)`,
  );
  // @ts-expect-error
  return Object.entries(values).reduce(
    // @ts-expect-error
    (transformedProperties: { [k: string]: ILanguageAwareString }, entry) => {
      const [key, value] = entry;
      if (typeof value !== 'string') {
        return transformedProperties;
      }
      const match = languageMatchRegex.exec(key);
      if (match) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, languageCode, desiredName] = match;
        const matchingLanguage = languages.find(
          (language) => language.shortName === languageCode,
        );
        const transformedValue = {
          value,
          language: matchingLanguage,
        };
        return {
          ...transformedProperties,
          [desiredName]: transformedProperties[desiredName]
            ? // @ts-expect-error
              [...transformedProperties[desiredName], transformedValue]
            : [transformedValue],
        };
      } else {
        return transformedProperties;
      }
    },
    {},
  );
}
