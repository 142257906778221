import theme from './MillerTheme';
import logo from './assets/MainLogo.png';
import mobileLogo from './assets/MillerMobileLogo.png';
import squareLogo from './assets/MillerSquareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';

// Calefy Config
const settings = new WebsiteSettings({
  slug: 'millerinsurance',
  host: '',
  companyName: 'Miller Insurance',
  hours: '8:30am - 5:30pm',
  phone: '1-844-4CA-LEFY (422-5339)',
  email: 'info@calefy.ca',
  chatLink: 'https://www.facebook.com/CalefyInc',
  companyTheme: theme,
  logo: logo,
  title: 'Miller Insurance - Personal and Business - Hanover ON',
  favicon: favicon,
  mobileLogo: mobileLogo,
  squareLogo,
});

export default settings;
