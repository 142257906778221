import { useState, useEffect, useRef, useMemo } from 'react';
import { Autocomplete } from '@calefy-inc/informedMaterial';
import MaterialTextField from '@mui/material/TextField';
import throttle from 'lodash/throttle';
import { useGoogle } from '@calefy-inc/hooks';
import { useTheme } from '@mui/material/styles';

// types
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import Bugsnag from '@bugsnag/js';
import { errorify } from '../../../util';

/**
 * Given a returned address object from Google Maps, extract the long name of the component that matches the particular address part that we're looking for (e.g. street name, city, &c.)
 * @param {object} component - The Google Maps address object
 * @param {string} type - The type descriptor matching the address component that we're looking for
 * @return {string | undefined} - Either the relevant long name of the component or undefined (if it doesn't exist)
 */
const extractNameWithType = (components: $TSFixMe, type: $TSFixMe) => {
  if (!components || !type) {
    return;
  }
  const relevantComponent = components.find((component: $TSFixMe) =>
    component['types'].includes(type),
  );
  return relevantComponent && relevantComponent['long_name'];
};

/**
 * Given a place object containing the place_id, fire off a query to Google Maps for details and use those to fill in other relevant fields in the form
 * @param {object} place - The place object from Google Maps containing the place_id
 */
export const fillInAddress = (
  place: $TSFixMe,
  placesService: $TSFixMe,
  fillFields: $TSFixMe,
  formApi: $TSFixMe,
  sessionToken: $TSFixMe,
  setValue: $TSFixMe,
) => {
  if (!place || !place.place_id || !placesService.current) {
    return;
  }

  placesService.current.getDetails(
    {
      placeId: place.place_id,
      fields: ['address_components'],
      sessionToken: sessionToken,
    },
    (response: $TSFixMe) => {
      if (!response) {
        return;
      }
      const components = response['address_components'];
      let streetNumber, streetName;
      streetNumber = extractNameWithType(components, 'street_number') || '';
      streetName = extractNameWithType(components, 'route') || '';
      const streetAddress = `${streetNumber} ${streetName}`.trim();
      let city, province, postalCode;

      city =
        extractNameWithType(components, 'locality') ||
        extractNameWithType(components, 'sublocality_level_1') ||
        '';
      province =
        extractNameWithType(components, 'administrative_area_level_1') || '';
      postalCode = extractNameWithType(components, 'postal_code') || '';

      //fill in the form values, if the requisite field exists
      const formValues = {};
      if (fillFields['address']) {
        // @ts-expect-error
        formValues[fillFields['address']] = streetAddress;
        setValue(streetAddress);
      }
      if (fillFields['city']) {
        // @ts-expect-error
        formValues[fillFields['city']] = city;
      }
      if (fillFields['province']) {
        // @ts-expect-error
        formValues[fillFields['province']] = province;
      }
      if (fillFields['postalCode']) {
        // @ts-expect-error
        formValues[fillFields['postalCode']] = postalCode;
      }
      //console.log('Setting form values', formValues);
      formApi.setValue(fillFields['address'], streetAddress);
      formApi.setTouched(fillFields['address'], true);

      formApi.setValue(fillFields['postalCode'], postalCode);
      formApi.setTouched(fillFields['postalCode'], true);

      formApi.setValue(fillFields['city'], city);
      formApi.setTouched(fillFields['city'], true);

      formApi.setValue(fillFields['province'], province);
      formApi.setTouched(fillFields['province'], true);
    },
  );
};

const autocompleteService = { current: null };
const placesService = { current: null };

type InformedAutocompleteGoogleLocationProps = {
  field: $TSFixMe;
  formApi: $TSFixMe;
  label: $TSFixMe;
  fillFields: $TSFixMe;
};
/**
 * Allows the user to type in an address and queries Google to automatically complete the address and use that information to fill out the other related fields.
 *
 * @param {string} field - The Informed field for the InformedAutocomplete component which forms the basis of this component
 * @param {object} formApi - The API for the form this component is a part of. Used to set the values of other related fields once the different address components have been received.
 * @param {string} label - The label for the underlying InformedAutocomplete component
 * @param {object: {address, city, province, postalCode}} fillFields - Mapping from the address components that Google gives us to the `field` of the Informed field that will be filled in with that value
 */
export const InformedAutocompleteGoogleLocation = ({
  field,
  formApi,
  label,
  fillFields,
}: InformedAutocompleteGoogleLocationProps) => {
  const { google } = useGoogle();
  const theme = useTheme();
  const [value, setValue] = useState<$TSFixMe>();
  const [options, setOptions] = useState<Array<$TSFixMe>>([]);

  const [searchCircle, setSearchCircle] = useState(); // location to bias the results to; this can be set to a default location
  const [sessionToken, setSessionToken] = useState(); //holds the session token to group together the details call with the autocomplete calls; this is mostly a billing feature
  const loaded = useRef(false);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        try {
          // @ts-expect-error
          autocompleteService.current.getPlacePredictions(request, callback);
        } catch (e) {
          console.error('Error in autocomplete', e);
          Bugsnag.notify(errorify(e));
        }
      }, 200),
    [],
  );

  loaded.current = !!google;

  // update the autocomplete suggestions whenever the value changes
  useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      google &&
      google.maps &&
      google.maps.places &&
      google.maps.places.AutocompleteService
    ) {
      try {
        autocompleteService.current =
          new google.maps.places.AutocompleteService();
      } catch (e) {
        Bugsnag.notify(errorify(e));
        console.error(`Error creating autocompleteService: ${e}`);
      }
    }
    if (!autocompleteService.current) {
      return;
    }

    if (value === undefined || value === null || value === '') {
      return;
    }
    try {
      fetch(
        {
          input: value,
          // @ts-expect-error
          bounds: searchCircle ? searchCircle.getBounds() : undefined,
          sessionToken: sessionToken,
        },
        (results: $TSFixMe) => {
          if (active) {
            let newOptions: Array<$TSFixMe> = [];
            if (value) {
              newOptions = [value];
            }
            if (results) {
              newOptions = [...results];
            }
            setOptions(newOptions);
          }
        },
      );
    } catch (e) {
      Bugsnag.notify(errorify(e));
    }
  }, [value, fetch, sessionToken, google, searchCircle]);

  // bias the user's results to their current location, if possible
  const centreSearchResults = () => {
    try {
      if (!google || !google.maps || !google.maps.Circle) {
        return;
      }
      //they've already granted permission and set a search circle
      if (searchCircle) {
        return;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const circle = new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy,
            });
            setSearchCircle(circle);
          },
          (_error: $TSFixMe) => {
            setSearchCircle(
              new google.maps.Circle({
                center: {
                  lat: 51,
                  lng: -114,
                },
                radius: 10000,
              }),
            );
          },
        );
      } else {
        setSearchCircle(
          new google.maps.Circle({
            center: {
              lat: 51,
              lng: -114,
            },
            radius: 10000,
          }),
        );
      }
    } catch (e) {
      console.error(`Error centring search results: ${e}`);
      Bugsnag.notify(errorify(e));
    }
  };
  useEffect(() => {
    centreSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google]);

  // get the places service (for details search) if possible
  useEffect(() => {
    if (
      google &&
      google.maps &&
      google.maps.places &&
      google.maps.places.PlacesService
    ) {
      placesService.current = new google.maps.places.PlacesService(
        document.getElementById('attributions'),
      );
    }
  }, [google]);

  return (
    <div style={{ marginBottom: theme.spacing(2) }}>
      <div id='attributions'></div>
      <Autocomplete
        field={field}
        freeSolo={true}
        getOptionLabel={(option: $TSFixMe) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x: $TSFixMe) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onFocus={() => {
          centreSearchResults();

          //generate a new session token
          if (google) {
            const newSessionToken =
              new google.maps.places.AutocompleteSessionToken();
            setSessionToken(newSessionToken);
          }
        }}
        onChange={(
          _event: $TSFixMe,
          newValue: $TSFixMe,
          ..._otherParams: Array<$TSFixMe>
        ) => {
          setOptions(newValue ? [newValue, ...options] : options);
          let interimStreetAddress;
          if (!newValue) {
            interimStreetAddress = '';
          } else if (typeof newValue === 'string') {
            interimStreetAddress = newValue;
          } else if (typeof newValue === 'object' && newValue.description) {
            interimStreetAddress = newValue.description.includes(',')
              ? newValue.description.slice(0, newValue.description.indexOf(','))
              : newValue.description;
            fillInAddress(
              newValue,
              placesService,
              fillFields,
              formApi,
              sessionToken,
              setValue,
            );
          } else {
            interimStreetAddress = '';
          }
          setValue(interimStreetAddress);
        }}
        onInputChange={(_event: $TSFixMe, newInputValue: $TSFixMe) => {
          setValue(newInputValue);
        }}
        renderInput={(params: $TSFixMe) => {
          //now harmonize the value parameter across the like 6 things that require it
          params.InputProps.value = value;
          params.inputProps.autocomplete = 'nope';
          return (
            <MaterialTextField
              {...params}
              label={label || ''}
              fullWidth
              variant='standard'
            />
          );
        }}
      />
    </div>
  );
};

export default InformedAutocompleteGoogleLocation;
